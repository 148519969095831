import * as React from 'react';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';
import { isEqual } from 'lodash';

import { getDevicesList, IDevicesData, exportDevicesListAction, IDevicesListData } from '../../../../services/core/devices';
import { InitialTableState, useTableReducer } from '../../../../components/data-table/lib';
import { convertFilterToQuery } from '../../../../components/filters/filterLib';
import { convertQueryStringToFilter } from '../../../../components/filters/devicesFilterLib';
import { DevicesFilter, DevicesListFilter } from './devices-filter';
import { DevicesEditRecord } from './devices-edit-record';
import { useCurrentUserContext } from '../../../../context/currentUser';
import { useUserSettingsContext } from '../../../../context/userSettings';
import { useWorldAction } from '../../../../lib/useWorldAction';
import { useProductContext } from '../../../../context/product';
import { DeleteDevices } from './devices-delete';
import { List } from '../../../../components/list/list';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { useDevicesListShadow } from './useDevicesListShadow';
import { useDevicesListColumns } from './useDevicesListColumns';
import { useDevicesListQueryString } from './useDevicesListQueryString';
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { useEditDeviceReducer } from './editDeviceLib';

import './devices-list.css';
import useNormalizedHeaderButtons from '../../../../components/controls/normalizedHeaderButtons';

export const tableName = 'devicesList';
export const defaultPageSize = 20;

export function DevicesList() {
  const ns = 'translation';
  const { t } = useTranslation(ns);
  const { worldSettings } = useWorldSettingsContext();
  const { tablePageSizes } = useUserSettingsContext();
  const { product } = useProductContext();
  const { isAdministrator, isManager, isEditor, homeLocationId } = useCurrentUserContext();

  const [showFilterSelection, setShowFilterSelection] = useState(false);
  const [showEditRecord, setShowEditRecord] = useState(false);
  const [showDeleteDevices, setShowDeleteDevices] = useState(false);

  const { addShadow } = useDevicesListShadow();
  const { search: queryString } = useLocation();
  const { prevDevicesListQueryString, devicesListQueryString } = useDevicesListQueryString();
  const columns = useDevicesListColumns();

  const canEditAndDelete = isAdministrator || isManager || isEditor;

  const hiddenFilters = useMemo(() => {
    const filters: DevicesListFilter[] = [];
    homeLocationId && filters.push('homeLocations');
    return filters;
  }, [homeLocationId]);

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 8, direction: 'desc', field: 'updated' },
    filter: convertQueryStringToFilter(queryString, t, worldSettings, hiddenFilters)
  };
  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<IDevicesData>(tableName, initialTableState);
  const [
    { offset, limit, sort, search, filter, data, checkedRows },
    { onFilter, onDataChange }
  ] = [tableReducerProperties, tableReducerFunctions];

  const [
    { assetTag, homeLocation, group, saving, errors },
    { onNewDevice, onFieldUpdate, onSave }
  ] = useEditDeviceReducer(data[checkedRows[0]]);

  useEffect(() => {
    const newFilter = convertQueryStringToFilter(devicesListQueryString, t, worldSettings, hiddenFilters);
    if (prevDevicesListQueryString !== devicesListQueryString && !isEqual(filter, newFilter)) {
      onFilter(newFilter);
    }
  }, [onFilter, devicesListQueryString, t, prevDevicesListQueryString, filter, worldSettings, hiddenFilters]);

  const exportDevicesList = useWorldAction(exportDevicesListAction);
  const onExportClicked = async (): Promise<string> => {
    return exportDevicesList({ limit: 10000, offset: 0, sort: { field: sort.field, order: sort.direction }, filter: convertFilterToQuery(filter), search });
  };

  const handleDeviceChange = (device: IDevicesData) => {
    const newData = [...data];
    newData[checkedRows[0]] = device;
    onDataChange(newData);
  };

  const devicesListTranslationItems = {
    statusText: {
      key: 'DEVICE',
      ns: 'translation'
    },
    tableTitle: {
      key: 'DEVICES',
      ns: 'translation'
    }
  };

  const additionalHeaderButtons = useNormalizedHeaderButtons({
    checkedRows,
    delete: !canEditAndDelete ? undefined : {
      onClick: () => setShowDeleteDevices(true),
      className: 'delete-devices-button'
    },
    edit: !canEditAndDelete ? undefined : {
      onClick: () => setShowEditRecord(true),
      className: 'edit-record-button'
    },
    export: {
      exportFunction: onExportClicked,
      filename: 'devicesList.csv'
    }
  });

  const checkedRowIds: string[] = checkedRows.map((e) => data[e]?.id);

  const deviceListFetcher = useCallback(() => {
    return getDevicesList({ limit, offset, sort: { field: sort.field, order: sort.direction }, search, filter: convertFilterToQuery(filter) });
  }, [offset, limit, sort, search, filter]);

  return (
    <div>
      <div className='core-devices-list'>
        <DevicesFilter
          showPopup={showFilterSelection}
          handleFiltersUpdated={(newFilter) => { onFilter(newFilter); setShowFilterSelection(false); }}
          handleCancel={() => setShowFilterSelection(false)}
          initialFilters={filter}
          hiddenFilters={hiddenFilters}
        />
        {showEditRecord && <DevicesEditRecord
          showPopup={showEditRecord}
          handleDeviceChange={handleDeviceChange}
          handleClose={() => setShowEditRecord(false)}
          device={data[checkedRows[0]]}
          onNewDevice={onNewDevice}
          onFieldUpdate={onFieldUpdate}
          onSave={onSave}
          assetTag={assetTag}
          homeLocation={homeLocation}
          group={group}
          saving={saving}
          errors={errors}
        />}
        <DeleteDevices
          showPopup={showDeleteDevices}
          handleClose={() => setShowDeleteDevices(false)}
          deviceIds={checkedRowIds}
          onDevicesDeleted={tableReducerFunctions.onLoading}
        />
        <List<IDevicesData, IDevicesListData, 'totalCount', 'list', 'id'>
          dataId='core_devices_list'
          dataTableEnabled={true}
          customHeader={false}
          countDataPath='totalCount'
          listDataPath='list'
          hasCheckboxes={canEditAndDelete}
          hasFilters={true}
          hasSearch
          translationItems={devicesListTranslationItems}
          columns={columns}
          fetcher={deviceListFetcher}
          onDataLoaded={addShadow}
          tableReducerFunctions={tableReducerFunctions}
          tableReducerProperties={tableReducerProperties}
          setShowFilterSelection={setShowFilterSelection}
          useRequestHook={useWorldRequest}
          options={{ rowIdDataPath: 'id', rowNameDataPath: 'serialNumber' }}
          additionalHeaderButtons={additionalHeaderButtons}
        />
      </div>
    </div>
  );
}
