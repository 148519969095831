import * as React from 'react';
import { isNil, isString } from 'lodash';
import { useTranslation } from "@lib/useTypedTranslation";


import { DEFAULT_VALUE, getFormatNumberToLocale } from '../../../../components/data-table/dataFormatters';
import { ITableColumn } from '../../../../components/data-table/types';
import { useCurrentUserContext } from '../../../../context/currentUser';
import { useWorldRoutes } from '../../../../routes/parts/allWorldRoutes';
import { HomeLocationAndGroupData, HomeLocationAndGroupDataType } from '../../../../services/core/overview';
import { CoreEstateOverviewColumn } from './core-estate-overview';
import { ReactComponent as MajorAlarm } from '../../../../components/icons/majorAlarmIcon.svg';
import { ReactComponent as CriticalAlarm } from '../../../../components/icons/criticalAlarmIcon.svg';
import { useLanguageAndLocaleContext } from '../../../../context/languageAndLocale';
import { AlarmStatus, getAlarmNameFromAlarmStatus } from '../../../../services/config/alertConstants';
import { getAlarmStatusField } from '../../../../services/config/config';
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { Link } from 'react-router-dom';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { encode } from '../../../../lib/base64';

export enum AlertLevel {
  Affected = 'affected',
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green'
}

const ns = 'translation';
const ns2 = 'estateOverview';
const ns3 = 'editRecord';

const getTypeFilter = (rowData: HomeLocationAndGroupData, userHomeLocationId: string) => {
  const { homeLocationAndGroup } = rowData;
  if (rowData.type === HomeLocationAndGroupDataType.HomeLocation) {
    /* when a user has a homeLocationId set, we do not allow
    the user to filter the devices list by home location */
    if (userHomeLocationId) { return ''; }

    const { homeLocationId } = rowData;
    const base64HomeLocationId = encode(homeLocationId);
    const base64HomeLocation = encode(homeLocationAndGroup);
    // if homelocation name is noHomeLocation, we use the null character to represent the filter
    const urlValue = homeLocationAndGroup === 'noHomeLocation' ? '\0' : `${base64HomeLocationId}|${base64HomeLocation}`;
    return `homeLocations=${encodeURIComponent(urlValue)}`;
  }
  // if group is noGroup, we use the null character to represent the filter
  const urlValue = homeLocationAndGroup === 'noGroup' ? '\0' : homeLocationAndGroup;
  return `groups=${encodeURIComponent(urlValue)}`;
};

export const getCoreFilterLink = (linkToDevices: string, userHomeLocationId: string, filterId: AlarmStatus, formatter: (value: any) => string, status?: AlertLevel) => {
  return (value: number | string | null, rowData: HomeLocationAndGroupData, t: TTypedTFunction) => {
    if (!rowData) { return; }

    if (value === 0) {
      return <span>0</span>;
    }

    const isDefaultAlert = filterId === 'deviceStatus';
    let statusFilter: string;
    const typeFilter = getTypeFilter(rowData, userHomeLocationId);
    const filterApplied = status || typeFilter;
    const prefix = filterApplied ? `${linkToDevices}?` : linkToDevices;
    const ampersand = (status || !isDefaultAlert) && typeFilter ? '&' : '';
    if (isDefaultAlert) {
      statusFilter = !status
        ? ''
        : status === AlertLevel.Affected
          ? `status=red,yellow`
          : `status=${status}`;
    } else {
      statusFilter = !status
        ? `alertStatus=${filterId}-red,${filterId}-yellow,${filterId}-green`
        : status === AlertLevel.Affected
          ? `alertStatus=${filterId}-red,${filterId}-yellow`
          : `alertStatus=${filterId}-${status}`;
    }
    const to = `${prefix}${typeFilter}${ampersand}${statusFilter}`;
    let classVariant = '';
    let formattedValue: string;
    if (isString(value) && ['noHomeLocation', 'noGroup'].includes(value)) {
      formattedValue = value === 'noHomeLocation' ? t('NO_HOME_LOCATION', { ns: ns3 }) : t('NO_GROUP', { ns: ns2 });
      classVariant = 'estate-overview__table-link--italic';
    } else if (formatter) {
      formattedValue = formatter(value);
    }
    return (
      <Link to={to} className={`estate-overview__table-link ${classVariant}`}>
        {formattedValue || value}
      </Link>
    );
  };
};

export function useCoreEstateOverviewListColumns(filterId: AlarmStatus) {
  const { t } = useTranslation([ns, ns2]);
  const linkToDevices = useWorldRoutes().core.devicesList;
  const currentUser = useCurrentUserContext();
  const { worldSettings } = useWorldSettingsContext();
  const { locale } = useLanguageAndLocaleContext();
  const formatNumberToLocale = getFormatNumberToLocale(locale);

  const addFilterLinks = (column: CoreEstateOverviewColumn): ITableColumn => {
    if ('processor' in column) {
      return column;
    }
    let processor: any;
    if (column.id === 'homeLocationAndGroup') {
      processor = getCoreFilterLink(linkToDevices, currentUser.homeLocationId, filterId, undefined, column.linkStatus);
    } else {
      processor = getCoreFilterLink(linkToDevices, currentUser.homeLocationId, filterId, formatNumberToLocale, column.linkStatus);
    }
    const columnWithProcessor = {
      ...column,
      processor
    };
    delete columnWithProcessor.linkStatus;
    return columnWithProcessor;
  };

  function percentageProcessor(value: number) {
    let classVariant = '';
    let icon = null;
    const alarmName = getAlarmNameFromAlarmStatus(filterId);
    const enabled = worldSettings[getAlarmStatusField(alarmName, 'Enabled')];
    if (enabled) {
      const thresholdRed = worldSettings[getAlarmStatusField(alarmName, 'Red')];
      const thresholdYellow = worldSettings[getAlarmStatusField(alarmName, 'Yellow')];
      if (value > thresholdRed) {
        classVariant = 'highlight-red';
        icon = <CriticalAlarm data-id="critical-alarm-icon" />;
      } else if (value > thresholdYellow) {
        classVariant = 'highlight-yellow';
        icon = <MajorAlarm data-id="major-alarm-icon" />;
      }
    }
    return isNil(value) ? <span>0%</span> : <span className={classVariant}>{icon}{value.toFixed(0)}%</span>;
  }

  function typeProcessor(value: HomeLocationAndGroupDataType) {
    switch (value) {
    case HomeLocationAndGroupDataType.HomeLocation: return t('HOME_LOCATION', { ns });
    case HomeLocationAndGroupDataType.Group: return t('GROUP', { ns });
    default: return DEFAULT_VALUE;
    }
  }

  const columns: CoreEstateOverviewColumn[] = [
    { id: 'homeLocationAndGroup', title: t('HOME_LOCATION_GROUP', { ns: ns2 }), dataPath: 'homeLocationAndGroup', sortable: true, textAlign: 'left', width: '16rem' },
    { id: 'type', title: t('TYPE', { ns: ns2 }), dataPath: 'type', processor: typeProcessor, sortable: true, textAlign: 'left', width: '9rem' },
    { id: 'alertingPercentage', title: t('PERCENTAGE_AFFECTED', { ns: ns2 }), dataPath: 'alertingPercentage', processor: percentageProcessor, sortable: true, textAlign: 'right', width: '11rem' },
    { id: 'devicesAffected', title: t('DEVICES_AFFECTED', { ns: ns2 }), dataPath: 'devicesAffected', linkStatus: AlertLevel.Affected, sortable: true, textAlign: 'right', width: '10rem' },
    { id: 'problemDevices', title: t('PROBLEM', { ns }), dataPath: 'problemDevices', linkStatus: AlertLevel.Red, sortable: true, textAlign: 'right', width: '8rem' },
    { id: 'warningDevices', title: t('WARNING', { ns }), dataPath: 'warningDevices', linkStatus: AlertLevel.Yellow, sortable: true, textAlign: 'right', width: '8rem' },
    { id: 'goodDevices', title: t('DEVICES_UNAFFECTED', { ns: ns2 }), dataPath: 'goodDevices', linkStatus: AlertLevel.Green, sortable: true, textAlign: 'right', width: '11rem' },
    { id: 'totalDevices', title: t('TOTAL_DEVICES', { ns: ns2 }), dataPath: 'totalDevices', sortable: true, textAlign: 'right', width: '10rem' }
  ].map(addFilterLinks);

  return columns;
}
