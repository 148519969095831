import * as React from 'react';

import { useLocation } from 'react-router-dom';
import { getWorlds, World } from '../services/worlds/worlds';
import { useCallback } from 'react';
import { useNonWorldRequest } from '../lib/useNonWorldRequest';
import { useErrorHandler } from '@lib/useErrorHandler';
import { WorldSelection } from '../containers/multipleWorlds/worldSelection';
import { AppLoading } from '../components/loading/appLoading';

function NoWorldsFetched() {
  const fetchWorlds = useCallback(() => getWorlds(), []);
  const { error: worldsError, data: worlds, loading } = useNonWorldRequest<World[]>(fetchWorlds, { onError: false, initialLoading: true });
  useErrorHandler(worldsError);

  if (loading) { return <AppLoading />; }

  return <WorldSelection worlds={worlds} />;
}

export function WorldSelectionRoute() {
  const { state } = useLocation<{ worlds: World[] }>();

  if (!state?.worlds) {
    return <NoWorldsFetched />;
  }

  return <WorldSelection worlds={state.worlds} />;
}
