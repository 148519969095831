import { useTranslation } from "@lib/useTypedTranslation";
import { DEFAULT_VALUE, formatDateLastUpdated, formatValue } from "../../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../../components/data-table/types";
import { Link } from "react-router-dom";
import { IDeviceProcessesListItemDisplay } from "../../../../services/core/processes";
import { useWorldRoutes } from "../../../../routes/parts/allWorldRoutes";
import { encode } from "@lib/base64";
import { useWorldSettingsContext } from "../../../../context/worldSettings";
import { rearrangeList } from "@lib/rearrangeList";
import { useMemo } from "react";

const formatLinkToDevice = (linkText: string, rowData: IDeviceProcessesListItemDisplay, path: string) => {
  if (!linkText) { return DEFAULT_VALUE; }
  return <Link to={`${path}?id=${encodeURIComponent(encode(rowData.deviceInfo.id))}`}>{formatValue(linkText)}</Link>;
};


export const useProcessDeviceListColumns = () => {
  const { t } = useTranslation(['translation', 'deviceProcesses']);
  const routes = useWorldRoutes();
  const { worldSettings: { deviceIdentifier } } = useWorldSettingsContext();

  return useMemo(() => {
    const linkProcessor = (linkText: string, rowData: IDeviceProcessesListItemDisplay) => {
      return formatLinkToDevice(linkText, rowData, routes.core.deviceOverview);
    };
    function getHomeLocationName(value: string, rowData: IDeviceProcessesListItemDisplay) {
      return rowData.deviceInfo?.homeLocation ? formatValue(rowData.deviceInfo.homeLocation.name) : "-";
    }

    const columns: ITableColumn[] = [
      { id: 'serialNumber', title: t('SERIAL_NUMBER', { ns: 'translation' }), dataPath: 'deviceInfo.serialNumber', sortable: true, processor: formatValue, width: '11rem' },
      { id: 'imei', title: t('IMEI', { ns: 'translation' }), dataPath: 'deviceInfo.imei', sortable: true, processor: formatValue, width: '6rem' },
      { id: 'assetTag', title: t("ASSET_TAG", { ns: 'translation' }), dataPath: 'deviceInfo.assetTag', sortable: true, processor: formatValue, width: '7rem' },
      { id: 'deviceName', title: t("DEVICE_NAME", { ns: 'translation' }), dataPath: 'deviceInfo.deviceName', sortable: true, processor: formatValue, width: '10rem' },
      { id: 'groups', title: t('GROUPS', { ns: 'translation' }), dataPath: 'deviceInfo.group', sortable: false, processor: formatValue, width: '6rem' },
      { id: 'homeLocation', title: t('HOME_LOCATION', { ns: 'translation' }), dataPath: 'deviceInfo.homeLocationId', sortable: true, processor: getHomeLocationName, width: '10rem' },
      { id: 'lastUsed', title: t('LAST_USED', { ns: 'translation' }), dataPath: 'lastUsed', sortable: true, processor: formatDateLastUpdated, width: '7rem' },
      { id: 'productVersion', title: t('PRODUCT_VERSION', { ns: 'deviceProcesses' }), dataPath: 'productVersion', sortable: true, processor: formatValue, width: '10rem' },
      { id: 'fileVersion', title: t('FILE_VERSION', { ns: 'deviceProcesses' }), dataPath: 'fileVersion', sortable: true, processor: formatValue, width: '10rem' },
    ];

    const updatedColumns: ITableColumn[] = rearrangeList(columns, deviceIdentifier);
    updatedColumns[0].processor = linkProcessor;

    return updatedColumns;
  }, [deviceIdentifier, routes.core.deviceOverview, t]);
};
