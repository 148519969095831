import * as React from 'react';
import styled from 'styled-components';
import { ChipButton } from '../../components/controls/chipButton';
import { useTranslation } from '@lib/useTypedTranslation';

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  & > *:not(:last-child) {
    margin-right: 0.75rem;
  }

  & .chip-button {
    margin-bottom: 5px;
  }
`;

export function SystemFilter(props: { setSystemFilter: (filter?: { system: boolean }) => void, systemFilter?: { system: boolean }}) {
  const { t } = useTranslation(['coreApplications', 'filters']);
  const { setSystemFilter, systemFilter } = props;
  return (
    <FiltersContainer>
      <div>{t('FILTER_BY', { ns: 'filters' })}</div>
      <ChipButton
        onClick={() => setSystemFilter(undefined)}
        text={t('ALL_APPLICATIONS', { ns: 'coreApplications' })}
        active={!systemFilter}
      />
      <ChipButton
        onClick={() => setSystemFilter({ system: false })}
        text={t('USER_APPLICATIONS', { ns: 'coreApplications' })}
        active={systemFilter?.system === false}
      />
      <ChipButton
        onClick={() => setSystemFilter({ system: true })}
        text={t('SYSTEM_APPLICATIONS', { ns: 'coreApplications' })}
        active={systemFilter?.system === true}
      />
    </FiltersContainer>
  );
}
