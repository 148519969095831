import React, { useEffect, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { deleteUsers, IUserData } from '../../../services/users/users';
import { DeleteDialogue } from '../../../components/delete/deleteDialogue';
import { useWait } from '../../../lib/wait';

export interface IProps {
  showPopup: boolean,
  users: IUserData[],
  handleClose: () => void,
  onUsersDeleted: () => void
}

export function DeleteUsers(props: IProps) {
  const { t } = useTranslation('deleteUsers');
  const { showPopup, handleClose, users } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [checkedLength, setCheckedLength] = useState(null);
  const { wait } = useWait();

  async function handleDelete() {
    setIsDeleting(true);
    let success = false;
    try {
      await deleteUsers(users.map(user => user.id));
      await wait(5000); // 5 second delay to allow for ES sync
      setIsDeleting(false);
      setError(false);
      success = true;
    } catch (error) {
      setIsDeleting(false);
      setError(true);
    } finally {
      props.onUsersDeleted();
    }
    return success;
  }

  const onClose = () => {
    setIsDeleting(false);
    setError(false);
    handleClose();
  };

  useEffect(() => {
    if (users.length) {
      setCheckedLength(users.length);
    }
  }, [users.length]);
  const errorText = t('ERROR_CUSTOM_DELETE', { ns: 'deleteDialogue', item: t('USER_other', { ns: 'userManagement' }) });

  const hasMultiple = checkedLength > 1;

  return (
    <DeleteDialogue
      show={showPopup}
      subheader={t('SUBHEADER', { count: checkedLength })}
      warning={t('MESSAGE', { count: checkedLength })}
      isDeleting={isDeleting}
      error={error && errorText}
      checkboxRequired={hasMultiple}
      handleDelete={handleDelete}
      handleClose={onClose}
      count={checkedLength}
    />
  );
}
