import * as React from 'react';
import { useCallback } from 'react';
import { InitialTableState, useTableReducer } from '../../../../components/data-table/lib';
import { List } from '../../../../components/list/list';
import { IProcess, ProcessesListAndCount, exportProcessesListAction, getProcessesList } from '../../../../services/core/processes';
import { useUserSettingsContext } from '../../../../context/userSettings';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { useProcessesListColumns } from './useProcessesListColumns';
import { useWorldAction } from '../../../../lib/useWorldAction';
import useNormalizedHeaderButtons from '../../../../components/controls/normalizedHeaderButtons';
import { useTranslation } from "@lib/useTypedTranslation";

const tableName = 'processesList';
const defaultPageSize = 20;

export function ProcessesList() {
  const { t } = useTranslation('coreProcesses');
  const { tablePageSizes } = useUserSettingsContext();
  const columns = useProcessesListColumns();

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 7, direction: 'desc', field: 'lastUsed' }
  };
  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<IProcess>(tableName, initialTableState);
  const { offset, limit, sort, search } = tableReducerProperties;

  const exportProcessesList = useWorldAction(exportProcessesListAction);
  const onExportClicked = async (): Promise<string> => {
    return exportProcessesList({ sort: { field: sort.field, order: sort.direction }, search });
  };

  const translationItems = {
    statusText: {
      key: 'PROCESS',
      ns: 'coreProcesses'
    },
    tableTitle: {
      key: 'PROCESSES_TITLE',
      ns: 'coreProcesses'
    }
  };

  const additionalHeaderButtons = useNormalizedHeaderButtons({
    export: {
      exportFunction: onExportClicked,
      filename: 'processesList.csv'
    }
  });

  const listFetcher = useCallback(() => {
    return getProcessesList({ limit, offset, sort: { field: sort.field, order: sort.direction }, search });
  }, [offset, limit, sort, search]);

  return (
    <div>
      <div className='core-processes-list'>
        <List<IProcess, ProcessesListAndCount, 'totalCount', 'list', 'id'>
          dataId='core-processes-list'
          customHeader={false}
          additionalHeaderButtons={additionalHeaderButtons}
          countDataPath='totalCount'
          listDataPath='list'
          hasCheckboxes={false}
          hasFilters={false}
          hasSearch
          translationItems={translationItems}
          columns={columns}
          fetcher={listFetcher}
          tableReducerFunctions={tableReducerFunctions}
          tableReducerProperties={tableReducerProperties}
          useRequestHook={useWorldRequest}
          options={{ rowIdDataPath: 'id' }}
          subHeader={t('SUBHEADER')}
        />
      </div>
    </div>
  );
}
