import { get, replace, isNil } from 'lodash';

const DEFAULT_VALUE = '-';

export const getStatusIconType = (status: string, iconCategories: { [key: string]: string }): any => {
  if (status === '-' || isNil(status)) {
    return iconCategories['green'];
  }

  if (status === 'yellow' && !iconCategories['yellow']) {
    return iconCategories['orange'];
  }

  return iconCategories[status];
};

export const getValue = (props: any, path: string, suffix?: string) => {
  let value = get(props, path, null);

  if (value !== null) {
    value = `${value}${suffix || ''}`;
  }

  return value || DEFAULT_VALUE;
};

export const insertSpacesForCommas = (groups: string) => {
  return replace(groups, new RegExp(', ?', 'g'), ', ');
};
