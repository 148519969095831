import { DurationFormatter } from "@amcharts/amcharts4/core";
import { TTypedTFunction } from "@lib/useTypedTranslation";
import moment from "moment";

export enum ChartTimePeriod {
  last30Days = 'last30Days',
  last7Days = 'last7Days',
  last48Hours = 'last48Hours'
}

export function isTimePeriod30Days(chartTimePeriod: ChartTimePeriod): boolean {
  return chartTimePeriod === ChartTimePeriod.last30Days;
}

export const formatDurationData = (t: TTypedTFunction) => (data: number) => {
  const usageHours = Math.floor(data / 60 / 60);
  const usageMinutes = (data / 60) % 60;
  const hoursText = t('HOUR', { count: usageHours, ns: 'timeState' });
  const minutesText = t('MINUTE', { count: usageMinutes, ns: 'timeState' });
  return new DurationFormatter().format(data, usageHours > 0 ? `h '${hoursText}' m '${minutesText}'` : `m '${minutesText}'`);
};

export const formatTooltipDate = (chartTimePeriod: ChartTimePeriod) => (date: string) => {
  return isTimePeriod30Days(chartTimePeriod) ?
    moment(parseInt(date)).format('ll')
    :
    moment.utc(parseInt(date)).format('LLLL LT');
};
