import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@lib/useTypedTranslation';

import { NavLink } from 'react-router-dom';
import { World } from '../../services/worlds/worlds';
import { Greetings } from '../../components/authentication/greetings';
import { useNonWorldRoutes } from '../../routes';
import Card from '../../components/card/card';
import { Theme } from '../app/themes';

const CardContainer = styled.div`
  display: flex;
  justify-content: center;

  &.card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const WorldSelectionCard = styled(Card)`
  flex-basis: 36%;
  margin-top: 1rem;
  text-align: center;

  @media(max-width: 1280px) {
    flex-basis: 50%;
  }
  @media(max-width: 500px) {
    flex-basis: 90%;
  }
`;

const WorldSelectionTitle = styled.div`
  ${({ theme }: { theme: Theme }) => `
    font-weight: ${theme.font.weights.bold};
    font-size: ${theme.font.sizes.sixteenPixels};
  `}
  margin-bottom: 1.5rem;
  margin-top: 1rem;
`;

const WorldCardContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const WorldCard = styled(NavLink)`
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  flex-basis: 80%;
  ${({ theme }: { theme: Theme }) => `
    font-weight: ${theme.font.weights.semiBold};
    font-size: ${theme.font.sizes.sixteenPixels};
  `}

  &:hover {
    text-decoration: none;
    background-color: var(--color-component-grey);
    color: var(--color-component-text-dark);
    transition: .3s;
  }

  @media(max-width: 1280px) {
    padding: 1rem 1rem;
    flex-basis: 100%;
  }
  @media(max-width: 500px) {
    padding: 1rem 0.5rem;
  }
`;
WorldCard.displayName = 'WorldCard';

const WorldIcon = styled.i`
  margin-right: 10px;
  align-self: center;

  @media(max-width: 375px) {
    display: none;
  }
`;

const WorldText = styled.div`
  color: #333;
  text-decoration: none;
`;

export function WorldSelection(props: { worlds: World[] }) {
  const { worlds } = props;
  const routes = useNonWorldRoutes();
  const { t } = useTranslation('worldSelection');

  return (
    <Greetings title={t('TITLE')} worldAware={false} logoLink={routes.worldSelection}>
      <CardContainer>
        <WorldSelectionCard data-id="world-selection-card">
          <WorldSelectionTitle data-id="select-a-world">{t('SELECT_A_WORLD')}</WorldSelectionTitle>
          {worlds.map((world, i) => {
            return (
              <WorldCardContainer key={world.id + i}>
                <WorldCard data-id="link-to-world" to={`/${world.id}/`}>
                  <WorldText data-id="world-name"><WorldIcon className="fas fa-globe-americas" />{world.name}</WorldText>
                </WorldCard>
              </WorldCardContainer>
            );
          })}
        </WorldSelectionCard>
      </CardContainer>
    </Greetings>
  );
}
