import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { Access } from './parts/access';
import { StandardPage } from './parts/standardPage';
import { HomeLocationManagement } from '../containers/homeLocations';

export function HomeLocationManagementRoute() {
  const { t } = useTranslation('homeLocations');

  return (
    <Access title={t('HOME_LOCATION_MANAGEMENT')} role="manager">
      <StandardPage title={t('HOME_LOCATION_MANAGEMENT')}>
        <HomeLocationManagement />
      </StandardPage>
    </Access>
  );
}
