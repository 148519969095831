import { TTypedTFunction } from "@lib/useTypedTranslation";
import { Filter } from "../../../components/timePeriodAndFilterPicker/useFilterPicker";
import { ITranslationKeys } from "components/i18n/keys";

export const getTitle = (params: { filter: Filter, t: TTypedTFunction<"coreApplications" | "translation">, translationKey: string, version?: string, sharedUserId?: string }) => {
  const { filter, t, translationKey, version, sharedUserId } = params;
  let filterTranslation = t('ALL_DEVICES', { context: 'lowercase', ns: 'coreApplications' });
  if (filter.group?.value) {
    filterTranslation = t('GROUP_FILTER', { name: filter.group?.value, ns: 'coreApplications' });
  } else if (filter.homeLocation?.value.name) {
    filterTranslation = t('HOME_LOCATION_FILTER', { name: filter.homeLocation?.value.name, ns: 'coreApplications' });
  } else if (filter.zone?.value.name) {
    filterTranslation = t('ZONE_FILTER', { name: filter.zone?.value.name, ns: 'coreApplications' });
  } else if (filter.group?.value === null) {
    filterTranslation = t('NO_GROUP_FILTER', { ns: 'coreApplications' });
  } else if (filter.homeLocation?.value.name === null) {
    filterTranslation = t('NO_HOME_LOCATION_FILTER', { ns: 'coreApplications' });
  } else if (translationKey === 'APPLICATION_DEVICES') {
    filterTranslation = t('ALL_DEVICES', { ns: 'coreApplications' });
  }

  if (translationKey === 'APPLICATION_DEVICES') {
    filterTranslation = filterTranslation[0].toUpperCase() + filterTranslation.substring(1);
  }
  return t(
    `SECTION_TITLE_${translationKey}` as keyof ITranslationKeys['coreApplications'],
    {
      filter: filterTranslation,
      version,
      context: version ?
        'withVersion'
        :
        '',
      ns: 'coreApplications',
      sharedUserId,
      versionFilter: t('VERSION_FILTER', { "version": version, ns: 'coreApplications' }) });
};
