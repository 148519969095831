import classNames from 'classnames';
import * as React from 'react';

import { Button, IButtonProps } from './button';

import './chipButton.css';
import { Chip } from './chip';

export interface Props extends IButtonProps {
  active?: boolean,
  dropdownOpen?: boolean,
  count?: number,
  icon?: JSX.Element,
  dataId?: string,
  text?: string
}

export const ChipButton = (props: Props) => {
  const { active, dropdownOpen, text, icon, className, count, ...buttonProps } = props;
  const buttonClass = classNames(
    className,
    'chip-button',
    {
      'chip-button-active': active
    }
  );

  return (
    <Button
      {...buttonProps}
      className={buttonClass}
      text={
        <Chip
          count={count}
          icon={icon}
          active={active}
          dropdownOpen={dropdownOpen} >
          {text}
        </Chip>
      }
    />
  );
};
