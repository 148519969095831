import * as React from 'react';
import moment from 'moment';
import { isNil, isEmpty, get, toNumber } from 'lodash';
import { useState, useMemo, useContext, useCallback } from 'react';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { useTranslation } from '@lib/useTypedTranslation';
import { useLocation } from 'react-router';

import Card from '../../../components/card/card';
import { NoSelectionOverlay } from '../../../components/card/noSelectionOverlay';
import { RoundedLabel } from '../../../components/labels/roundedLabel';
import { TabPanel } from '../../../components/tabs/tab-panel';
import { TabPanelHeader } from '../../../components/tabs/tab-panel-header';
import { Tab } from '../../../components/tabs/tab';
import { DeviceDetailsInformation } from './deviceDetailsInformation';
import { AverageDischargeChart } from '../../../components/chart/averageDischargeChart';
import { getLast30DayDeviceDischarge, getLast30DaysDateParams } from '../../../services/core/devicePerformance';
import { DeviceInfoContext } from '../../core/device';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { useFeatureTogglesContext } from '../../../context/featureToggles';
import { useProductsContext } from '../../../context/products';
import { productNames } from '../../app/productsWrapper';

import './styles.css';
import './legacyDetailsCard.css';

export interface IProps {
  id: string,
  data: any,
  isLoading?: boolean
}

export function DeviceDetailsCard(props: IProps) {
  const ns = 'translation';
  const ns1 = 'performance';
  const ns2 = 'batteryEssentials';
  const ns3 = 'batteryEssentialsDeviceView';
  const { t } = useTranslation([ns, ns1, ns2, ns3]);
  const { timeZoneOffset } = useContext(DeviceInfoContext);
  const product = useProductsContext();
  const [chartDataLoading, setChartDataLoading] = useState(false);

  const lastUpdatedTime = props.data?.updated && `${t('UPDATED', { ns })} ${moment(toNumber(props.data.updated)).calendar()}`;
  const title = t('AVERAGE_HOURLY_DISCHARGE_PAST_30DAYS', { ns: ns1 });
  const statusLabel = getStatusLabel(props.data, t);
  const deviceid = getDeviceId(props.data);
  const productTitle = product[productNames.batteryEssentials].title;
  const header = t('NO_SELECTION_HEADER', { title: productTitle, ns: ns2 }) + " - " + t('DEVICE_other', { ns });

  const dates = useMemo(() => {
    return getLast30DaysDateParams(moment.utc(), timeZoneOffset);
  }, [timeZoneOffset]);

  const params = useMemo(() => {
    return getRequestParams(props.id, dates);
  }, [props.id, dates]);

  const chartDataFetcher = useCallback(() => {
    if (!isEmpty(props.data) && props.data !== '-') {
      setChartDataLoading(true);
      return getLast30DayDeviceDischarge(params);
    }
  }, [props.data, params]);

  const {
    loading: requestLoading,
    data: chartData
  } = useWorldRequest(chartDataFetcher, { initialData: [] });

  const location = useLocation();
  const parameters = new URLSearchParams(location.search);
  const deviceNotFound = (props.data === '-' || isEmpty(props.data)) && parameters.has('id') && props.isLoading === false;

  return (
    <div className="details-card battery-device" data-id={get(props.data, 'id')}>
      <NoSelectionOverlay
        show={(props.id === '-' || deviceNotFound) && props.isLoading === false}
        icon={deviceNotFound ? null : "fas fa-mobile-alt"}
        header={deviceNotFound ? t('DEVICE_NOT_FOUND', { ns: 'translation' }) : header}
        noSelectionText={deviceNotFound ? t('DEVICE_NOT_FOUND_BODY', { ns: ns3 }) : [t('NO_SELECTION_BODY', { ns: ns3 })]}
        opaque={true}
      />
      <Card noPadding={true}>
        <TabPanel>
          <TabPanelHeader>
            <div className="details-tab-header">
              <span className="selection-header">
                {t("DEVICE_STATUS_HEADER", { ns })} {deviceid}
              </span>
              {statusLabel}
            </div>
          </TabPanelHeader>
          <Tab id="Information" title={t("INFORMATION", { ns })}>
            <DeviceDetailsInformation
              key={'details' + props.id}
              id={props.id}
              data={props.data}
            />
          </Tab>
          <Tab id='DischargeChart' title={t("DEVICE_DISCHARGE", { ns: ns3 })}>
            <div className="discharge_chart">
              <div className="chart-title">{title} - <span className='title_updated'>{props.data?.updated ? lastUpdatedTime : ''}</span></div>
              <AverageDischargeChart data={chartData} setLoading={setChartDataLoading} loading={chartDataLoading || requestLoading} />
            </div>
          </Tab>
        </TabPanel>
      </Card>
    </div>
  );
}

function getStatusLabel(data: any, t: TTypedTFunction) {
  const ns = 'translation';
  const ns1 = 'batteryEssentialsDeviceView';
  if (data?.averageDischargeStatus === 'yellow') {
    return <RoundedLabel title={t("HIGH_DISCHARGE", { ns: ns1 })} icon='fas fa-exclamation-triangle' type='yellow' />;
  }

  return <RoundedLabel title={t("NO_PROBLEMS", { ns })} icon='fas fa-check' type='green' />;
}

function getDeviceId(data: any) {
  const serial = get(data, 'serialNumber');
  return `${isNil(serial) ? '' : `(${serial})`}`;
}

interface IRequestParams {
  deviceId: string,
  to: number,
  from: number
}

function getRequestParams(deviceId: string, dates: { from: number, to: number }): IRequestParams {
  return { deviceId, ...dates };
}
