import classNames from "classnames";
import * as React from 'react';
import { VIEW } from '../../../services/shared/common';
import { get } from 'lodash';
import { AppContext } from '../../../containers/battery/index';
import { getStatusIconType, getValue, insertSpacesForCommas } from './tableRowUtils';
import '../../shared/battery/style.css';

export interface ITableRow {
  id?: string,
  manufacturer?: string,
  model?: string,
  serialNumber?: string,
  imei?: string,
  group?: string,
  smartBatteries?: any,
  status: VIEW,
  subRows: number,
  rowNum: number,
  iconCategories: { [key: string]: string },
  averageDischargeStatus?: string
}

interface IState {
  expanded: boolean
}

export default class BatteryTableRow extends React.Component<ITableRow, IState> {

  private DEFAULT_VALUE = '-';

  constructor(props: ITableRow) {
    super(props);
    this.state = {
      expanded: false
    };
  }

  public render() {
    return (
      <AppContext.Consumer>{thecontext =>
        (<tbody className="eTableBody">
          <tr id={`batteryTableRow${this.props.rowNum}Battery0`} data-id={this.props.id} className={classNames({
            tableRow: true,
            'selectedRow': thecontext.getSelectedRow(getValue(this.props, 'id')),
            'shadeClassLight': (this.props.rowNum % 2) === 1,
            'shadeClassDark': (this.props.rowNum % 2) === 0
          })} onClick={() => {
            thecontext.setSelectedBatteryIndex(1);
            thecontext.setSelectedDevice(getValue(this.props, 'id'));
            thecontext.setSelectedView(this.props.status);
          }} >
            <td data-key="deviceManufacturer" rowSpan={this.calcRowSpan()}>{getValue(this.props, 'manufacturer')}</td>
            <td data-key="deviceModel" rowSpan={this.calcRowSpan()}>{getValue(this.props, 'model')}</td>
            <td data-key="deviceSerialNumber" rowSpan={this.calcRowSpan()}>{getValue(this.props, 'serialNumber')}</td>
            <td data-key="deviceImei" rowSpan={this.calcRowSpan()}>{getValue(this.props, 'imei')}</td>
            <td data-key="deviceGroup" rowSpan={this.calcRowSpan()}>{insertSpacesForCommas(getValue(this.props, 'group'))}</td>
            <td data-key="deviceAverageDischarge" rowSpan={this.calcRowSpan()}>{getValue(this.props, 'averageDischarge', '%')}{this.getAverageDischargeStatusIcon()}</td>
            {this.getFirstBattery(thecontext)}
          </tr>
          { (this.props.subRows > 1 && this.state.expanded) && this.getOtherBatteries(thecontext) }
        </tbody>)
      }</AppContext.Consumer>
    );
  }

  private getAverageDischargeStatusIcon = () => {
    const statusIcon = <i className = { `fa fa-exclamation-triangle circle_orange device-warning-icon` }/>;
    return this.props.averageDischargeStatus === VIEW.orange || this.props.averageDischargeStatus === VIEW.yellow ? statusIcon : null;
  };

  private getStatusIcon = (thecontext: any, index: number) => {
    const batteryStatus = this.getSmartBatteryValue(thecontext.batteryStatus, index);
    return batteryStatus !== this.DEFAULT_VALUE ? getStatusIconType(batteryStatus, this.props.iconCategories) : getStatusIconType(this.props.status, this.props.iconCategories);
  };

  private calcRowSpan = (): number => {
    return this.state.expanded ? this.props.subRows : 1;
  };

  private getSmartBatteryValue = (path: string, index?: number, suffix?: string) => {
    let value = get(this.props, 'smartBatteries', null);
    if (value !== null && value.length > 0) {
      value = get(value[index || 0], path, null);
    }

    if (value !== null) {
      value = `${value}${suffix || ''}`;
    }

    return value || this.DEFAULT_VALUE;
  };

  private getFirstBattery = (thecontext: any) => {
    const chevClass = this.state.expanded ? "fa fa-chevron-down" : "fa fa-chevron-right";
    return (<React.Fragment>
      <td className="textCenter divider" onClick={() => this.setState({ expanded: !this.state.expanded })}>{this.props.subRows > 1 ? <i id={`chevron${this.props.rowNum}`} className={chevClass} /> : null} {this.props.subRows > 1 ? '1/' + this.props.subRows : '1'} </td>
      <td data-key="partNumber">{this.getSmartBatteryValue('partNumber', 0)}</td>
      <td data-key="serialNumber">{this.getSmartBatteryValue('serialNumber', 0)}</td>
      <td data-key="status" className="textCenter"><i className={this.getStatusIcon(thecontext, 0)} /></td>
    </React.Fragment>);
  };

  private getOtherBatteries = (thecontext: any) => {
    const smartBatteries = [];
    for (let i = 1; i < this.props.smartBatteries.length; i++) {
      smartBatteries.push((<React.Fragment key={`batteryTableRow${this.props.rowNum}Battery${i}`}>
        <tr id={`batteryTableRow${this.props.rowNum}Battery${i}`} className={classNames({
          tableRow: true,
          'selectedRow': thecontext.getSelectedRow(getValue(this.props, 'id')),
          'shadeClassLight': (this.props.rowNum % 2) === 1,
          'shadeClassDark': (this.props.rowNum % 2) === 0
        })} onClick={() => {
          thecontext.setSelectedBatteryIndex(2);
          thecontext.setSelectedDevice(getValue(this.props, 'id'));
          thecontext.setSelectedView(this.props.status);
        }} >
          <td className="textCenter">{i + 1}/{this.props.smartBatteries.length}</td>
          <td data-key="partNumber">{this.getSmartBatteryValue('partNumber', i)}</td>
          <td data-key="serialNumber">{this.getSmartBatteryValue('serialNumber', i)}</td>
          <td data-key="status" className="textCenter"><i className={this.getStatusIcon(thecontext, i)} /></td>
        </tr>
      </React.Fragment>));
    }

    return smartBatteries;
  };
}
