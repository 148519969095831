import moment from "moment";
import { TTypedTFunction } from "./useTypedTranslation";

export function getAge(t: TTypedTFunction, ageMoment: moment.Moment) {
  if (!ageMoment.isValid()) return undefined;

  const mfdMoment = moment(ageMoment, "YYYY-MM-DD");
  const nowMoment = moment.utc();

  const years = nowMoment.diff(mfdMoment, 'years');
  const months = nowMoment.diff(mfdMoment, 'months') - (years * 12);

  if (years > 0) {
    const yearsPart = t('YEAR', { count: years, ns: 'timeState' });

    if (months > 0) {
      const monthPart = t('MONTH', { count: months, ns: 'timeState' });
      return `${years} ${yearsPart}, ${months} ${monthPart}`;
    } else {
      return `${years} ${yearsPart}`;
    }
  }

  if (months > 0) {
    const monthPart = t('MONTH', { count: months, ns: 'timeState' });
    return `${months} ${monthPart}`;
  }

  if (months === 0) {
    return t('LESS_THAN_A_MONTH', { ns: 'timeState' });
  }

  return undefined;
}
