import React from 'react';

import { Spinner } from './loadingSpinner';

export const AppLoading = () => {
  return (
    <div style={{ margin: '50vh auto', textAlign: 'center' }}>
      <Spinner classes="initial-loading-spinner" />
    </div>
  );
};
