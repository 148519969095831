import { request, RequestInitWithRetry } from '../../lib/request';
export interface IWhatsNew {
  id: number,
  date: string,
  title: string,
  link: string,
  text: string
}

export interface IWhatsNewParams {
  branding: string
 }

export const getWhatsNew = (params: IWhatsNewParams) => {
  return async (options: RequestInitWithRetry): Promise<IWhatsNew[] | null> => {
    const input = {
      path: '/api/whatsnew/list',
      qs: { branding: params.branding },
    };
    return request(input, options);
  };
};
