import * as React from 'react';
import { useReducer, useRef } from 'react';

import { truncateToPlaces, roundToPlaces } from '../../lib/dataConversion';

export interface IntegerProps {
  disabled: boolean,
  errorText: string,
  value: number,
  onChange: (value: number) => void,
  validationRule: (value: number) => boolean,
  dataKey?: string
}

// When permitting float input we process the value as a string to avoid rounding while the user types
// The rendering location is responsible for converting the string appropriately.
export interface FloatProps extends Omit<IntegerProps, 'value' | 'onChange' | 'validationRule'> {
  value: string,
  onChange: (value: string) => void,
  validationRule: (value: string) => boolean,
  decimalPlaces: number
}

export function CustomValidatedInput(props: IntegerProps | FloatProps) {
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);
  const ref = useRef<HTMLInputElement>(null);

  // this seemingly redundant ternary is required for type checking
  const isValid = 'decimalPlaces' in props ? props.validationRule(props.value) : props.validationRule(props.value);

  if (ref.current) {
    if (!isValid) {
      ref.current.setCustomValidity(props.errorText);
    } else {
      ref.current.setCustomValidity('');
    }
  }
  return (
    <input
      ref={ref}
      data-key={props.dataKey}
      disabled={props.disabled}
      data-id="custom-validated-input"
      // when receiving a float value we round it to best represent the value
      value={'decimalPlaces' in props && props.value ? roundToPlaces(Number(props.value), props.decimalPlaces) : props.value }
      step={'decimalPlaces' in props ? `0.${'0'.repeat(props.decimalPlaces - 1)}1` : '1'}
      title={isValid ? '' : props.errorText}
      type="number"
      required={true}
      onChange={(event) => {
        'decimalPlaces' in props
          // when accepting a float value input we truncate it as accuracy greater than dp should be ignored
          ? props.onChange(truncateToPlaces(event.target.value, props.decimalPlaces))
          : props.onChange(parseInt(event.target.value));
        forceUpdate();
      }} />
  );
}
