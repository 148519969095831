import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { DEFAULT_VALUE } from '../data-table/dataFormatters';
import { Theme } from '../../containers/app/themes';

export interface IProps {
  title: string,
  data: DetailsItem[],
  className?: string,
  defaultValue?: string
}

export interface DetailsItem {
  title: string | JSX.Element,
  value: string | JSX.Element | JSX.Element[]
}

/* istanbul ignore next */
const Header = styled.div`
  font-weight: 700;
  margin-bottom: 0.75rem;
  margin-top: 1.5rem;
  font-size: ${({ theme }: { theme: Theme }) => theme.font.sizes.eighteenPixels};
`;
Header.displayName = 'Header';

const Details = styled.div`
  display: table;
  width: 100%;
`;

const Item = styled.div`
  display: table-row;
  flex-flow: row;

  &:nth-child(1) {
    padding-left: 0.625rem;
  }

  &:last-child {
    padding-right: 1.4em;
  }
`;

const ItemElement = styled.div`
  display: table-cell;
  padding: 0.5rem;
  max-width: 4rem;
`;

const ItemTitle = styled(ItemElement) <{ hasTitle: boolean, hasValue: boolean }>`
  font-weight: 600;
  ${({ hasTitle }) => hasTitle ? '' : 'max-width: auto; text-align: center;'}
  ${({ hasValue }) => hasValue ? '' : 'font-style: italic; font-weight: 400;'}
`;
ItemTitle.displayName = 'ItemTitle';

export function DetailsCard(props: IProps) {
  const defaultValue = props.defaultValue || DEFAULT_VALUE;
  const items = map(props.data, (item, index) => {
    const { title, value } = item;
    return <Item key={index}>
      <ItemTitle data-id="details-card-item-title" hasValue={Boolean(value)} hasTitle={Boolean(title)}>{title || defaultValue}</ItemTitle>
      <ItemElement data-id="details-card-item-value">{title && value}</ItemElement>
    </Item>;
  });

  return <div className={props.className}>
    <Header data-id="details-card-header">
      {props.title}
    </Header>
    <Details>
      {items}
    </Details>
  </div>;
}
