import React, { useState, useContext, useReducer, Reducer } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { defaults, pickBy, isBoolean } from 'lodash';

import { Button } from '../../../../../components/controls/button';
import { DatePicker } from '../../../../../components/datePicker/datePicker';
import { Last24HoursReport } from './last24HoursReport';
import { Last30DaysReport } from './last30DaysReport';
import { SelectedDateReport } from './selectedDateReport';
import { StateSwitch } from '../../../../../routes/parts/stateSwitch';
import { editEventsReducer, EditEventsAction } from './events/edit-events';
import { DeviceEventFilters, DeviceChartEventsFilterState, DeviceTableEventsFilterState, DeviceNetworkChartEventsFilterState } from './events/deviceEventsTypes';
import { DeviceEventName, deviceEventNames } from '../../../../../services/core/eventsTypes';
import { omitShallow } from '../../../../../lib/omitShallow';
import { DeviceInfoContext } from '../../index';
import { ChartName, NetworkChartName, chartNamesArray, networkChartNamesArray } from './charts/lib/chartNames';
import { ChartEventFilters, NetworkChartEventFilters, TableEventFilters } from '../../../../../services/config/config';
import { TableName, tableNamesArray } from '../../../../../components/data-table/lib';
import { UserSettingsContextValue, useUserSettingsContext } from '../../../../../context/userSettings';
import { FeatureTogglesContextValue, useFeatureTogglesContext } from '../../../../../context/featureToggles';

import './index.css';
import { initialNetworkEventFilters } from '../network/selectedDateChart';
import { ChipButton } from '@components/controls/chipButton';
import styled from 'styled-components';
import { Chip } from '@components/controls/chip';

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0px;
  margin-top: 30px;

  &:not(:last-child) {
    margin-right: 1.5rem;
  }

  & > *:not(:last-child) {
    margin-right: 0.75rem;
  }
`;

export const isValidDate = ((current: Moment) => {
  const today = moment();
  return current.isBefore(today) && current.isAfter(moment().subtract(90, 'day'));
});

export const renderDatePicker = (
  props: any,
  recordSelectedDate: number | Moment,
  setDate: (date: string | Moment) => void,
  isReportSelected: (reportName: string) => boolean
) => {
  const adjustDate = (amount: number) => {
    const newDate = moment(recordSelectedDate).add(amount, "days");
    if (amount > 0 && newDate.isAfter(moment(), 'day')) return;
    setDate(newDate);
  };

  return (
    <Chip active={isReportSelected('selectedDate')}>
      <div className="chip-date-picker-container">
        <Button type="button" className="core-performance_left-chevron-button" iconStyle="fas fa-chevron-left" onClick={() => adjustDate(-1) } />
        <div className="core-performance-container-divider" />
        <input
          {...props} />
        <Button type="button" className="core-performance_calendar-reset" iconStyle="fas fa-undo-alt" onClick={() => setDate(moment())} />
        <div className="core-performance-container-divider" />
        <Button type="button" className="core-performance_right-chevron-button" iconStyle="fas fa-chevron-right" onClick={() => adjustDate(1)} />
      </div>
    </Chip>
  );
};

const reports = ['last24Hours', 'last30Days', 'selectedDate'];

const defaultEventFilters: DeviceEventFilters = {
  applicationInstalled: false,
  applicationUninstalled: false,
  applicationUpdated: false,
  batteryChanged: false,
  deviceOnPower: false,
  deviceOffPower: false,
  deviceLowPower: false,
  deviceRebooted: false,
  deviceDropped: false,
  timeError: false,
  timeRecovery: false,
  networkChanged: false,
  networkAvailable: false,
  networkLost: false,
  bearerChanged: false,
  simChanged: false,
  airplaneModeOff: false,
  airplaneModeOn: false,
  mobileRadioOff: false,
  mobileRadioOn: false
};

const defaultTableEventFilters = Object.keys(defaultEventFilters).reduce((reduced, key) => ({ ...reduced, [key]: true }), {} as DeviceEventFilters);

export const initialEventFiltersDeviceUsage: DeviceEventFilters = {
  ...defaultEventFilters,
  applicationInstalled: true,
  applicationUpdated: true
};

export const initialEventFiltersDataUsage: DeviceEventFilters = {
  ...initialEventFiltersDeviceUsage
};

export const initialEventFiltersDeviceChargeLevel: DeviceEventFilters = {
  ...defaultEventFilters,
  batteryChanged: true,
  deviceOnPower: true,
  deviceOffPower: true,
  deviceLowPower: true,
};

export const initialEventFiltersAverageDischarge: DeviceEventFilters = {
  ...defaultEventFilters,
  applicationInstalled: true,
  applicationUninstalled: true,
  applicationUpdated: true
};

export const initialEventFiltersEventsList: DeviceEventFilters = {
  ...defaultTableEventFilters
};

const allInitialFilters: any = {
  dataUsage24Hours: initialEventFiltersDataUsage,
  deviceUsage24Hours: initialEventFiltersDeviceUsage,
  dataUsage30Days: initialEventFiltersDataUsage,
  deviceUsage30Days: initialEventFiltersDeviceUsage,
  deviceChargeLevel: initialEventFiltersDeviceChargeLevel,
  averageDischarge30Days: initialEventFiltersAverageDischarge,
  eventsList24Hours: initialEventFiltersEventsList,
  eventsList30Days: initialEventFiltersEventsList,
  deviceNetwork: initialNetworkEventFilters
};

export type ChartOrTableFiltersState<T> = T extends ChartEventFilters ? DeviceChartEventsFilterState : DeviceTableEventsFilterState;

export function getFiltersState(featureToggles: FeatureTogglesContextValue, platformType: string, arrayName: readonly ChartName[] | readonly NetworkChartName[], eventFilters: ChartEventFilters | NetworkChartEventFilters): DeviceChartEventsFilterState | DeviceNetworkChartEventsFilterState;
export function getFiltersState(featureToggles: FeatureTogglesContextValue, platformType: string, arrayName: readonly TableName[], eventFilters: TableEventFilters): DeviceTableEventsFilterState;
export function getFiltersState(featureToggles: FeatureTogglesContextValue, platformType: string, arrayName: any, eventFilters: any): any {
  return arrayName.reduce((filters: any, name: any) => {
    const cleanedUserEventFilters = pickBy(eventFilters[name], (enabled, event) => isBoolean(enabled) && deviceEventNames.includes(event as any));
    const mergedEventFilters = defaults(cleanedUserEventFilters, allInitialFilters[name]);
    const eventsToRemove: DeviceEventName[] = [];
    if (!featureToggles.getDropsEnabledOnDevice(platformType)) {
      eventsToRemove.push('deviceDropped');
    }
    const filteredEvents = omitShallow<DeviceEventFilters>(mergedEventFilters, eventsToRemove);

    filters[name] = {
      appliedEventFilters: filteredEvents,
      currentEventFilters: filteredEvents
    };
    return filters;
  }, {} as ChartOrTableFiltersState<typeof eventFilters>);
}

export function getInitialFilters(userSettings: UserSettingsContextValue, featureToggles: FeatureTogglesContextValue, platformType: string, currentContainer: 'chart'): DeviceChartEventsFilterState;
export function getInitialFilters(userSettings: UserSettingsContextValue, featureToggles: FeatureTogglesContextValue, platformType: string, currentContainer: 'chart', isNetworkChart?: boolean): DeviceNetworkChartEventsFilterState;
export function getInitialFilters(userSettings: UserSettingsContextValue, featureToggles: FeatureTogglesContextValue, platformType: string, currentContainer: 'table'): DeviceTableEventsFilterState;
export function getInitialFilters(userSettings: UserSettingsContextValue, featureToggles: FeatureTogglesContextValue, platformType: string, currentContainer: 'chart' | 'table', isNetworkChart?: boolean): DeviceChartEventsFilterState | DeviceTableEventsFilterState | DeviceNetworkChartEventsFilterState {
  const key = currentContainer === 'chart' ? 'chartEventFilters' : 'tableEventFilters';
  const savedFilters: any = userSettings[key] || {};
  if (currentContainer === 'chart') {
    return getFiltersState(featureToggles, platformType, isNetworkChart ? networkChartNamesArray : chartNamesArray, savedFilters);
  } else {
    return getFiltersState(featureToggles, platformType, tableNamesArray, savedFilters);
  }
}

export function DevicePerformance() {
  const featureToggles = useFeatureTogglesContext();
  const userSettings = useUserSettingsContext();
  const { platformType } = useContext(DeviceInfoContext);
  const { t } = useTranslation(['performance', 'translation']);
  const [selectedReportIndex, setSelectedReportIndex] = useState(reports.indexOf('last24Hours'));
  const [recordSelectedDate, setRecordSelectedDate] = useState<number>();
  const initialChartFilters = getInitialFilters(userSettings, featureToggles, platformType, 'chart');
  const initialTableFilters = getInitialFilters(userSettings, featureToggles, platformType, 'table');
  const [chartEventFilters, dispatchChartEventFilters] = useReducer<Reducer<DeviceChartEventsFilterState, EditEventsAction<ChartName>>>(
    editEventsReducer,
    initialChartFilters
  );

  const [tableEventFilters, dispatchTableEventFilters] = useReducer<Reducer<DeviceTableEventsFilterState, EditEventsAction<TableName>>>(
    editEventsReducer,
    initialTableFilters
  );

  const {
    deviceUsage30Days,
    dataUsage30Days,
    averageDischarge30Days,
    deviceUsage24Hours,
    dataUsage24Hours,
    deviceChargeLevel
  } = chartEventFilters;

  const {
    eventsList24Hours,
    eventsList30Days
  } = tableEventFilters;

  const eventProps24Hours = {
    eventFiltersDeviceUsage: deviceUsage24Hours,
    eventFiltersDataUsage: dataUsage24Hours,
    eventFiltersDeviceChargeLevel: deviceChargeLevel,
    eventFiltersEventsList: eventsList24Hours,
    dispatchChartEventFilters,
    dispatchTableEventFilters
  };

  const eventProps30Day = {
    eventFiltersDeviceUsage: deviceUsage30Days,
    eventFiltersDataUsage: dataUsage30Days,
    eventFiltersAverageDischarge: averageDischarge30Days,
    eventFiltersEventsList: eventsList30Days,
    dispatchChartEventFilters,
    dispatchTableEventFilters,
    setDate
  };

  const isReportSelected = (reportName: string) => selectedReportIndex === reports.indexOf(reportName);

  const last24HoursSelected = isReportSelected('last24Hours');
  const selectedDateSelected = isReportSelected('selectedDate');

  function setDate(date: string | Moment) {
    const newDate = typeof date === 'string' ? parseInt(date) : date.valueOf();
    setRecordSelectedDate(newDate);
    setSelectedReportIndex(reports.indexOf('selectedDate'));
  }

  return (
    <div className="core-device_performance core-device_content">
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="performance-header">{t('PERFORMANCE', { ns: 'translation' })}</div>
          <div className="performance-subHeader">
            {t('DEVICE_PERFORMANCE_DESCRIPTION', { ns: 'performance' })}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="row justify-content-center">
            <div className="col-auto">
              <div className="row">
                <FiltersContainer>
                  <ChipButton
                    onClick={() => setSelectedReportIndex(reports.indexOf('last24Hours'))}
                    text={t('LAST_24HOURS_TEXT', { ns: 'performance' })}
                    active={isReportSelected('last24Hours')}
                  />
                  <ChipButton
                    onClick={() => setSelectedReportIndex(reports.indexOf('last30Days'))}
                    text={t('LAST_30DAYS_TEXT', { ns: 'performance' })}
                    active={isReportSelected('last30Days')}
                  />
                  <DatePicker
                    dateFormat={`LLLL 'YY`}
                    timeFormat={false}
                    className="core-date-picker_input"
                    initialValue={t('SELECTED_DATE', { ns: 'performance' })}
                    isValidDate={isValidDate}
                    onChange={setDate}
                    value={recordSelectedDate}
                    renderInput={(props) => renderDatePicker(props, recordSelectedDate, setDate, isReportSelected)}
                  />
                </FiltersContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <StateSwitch selectedIndex={selectedReportIndex}>
        <Last24HoursReport {...eventProps24Hours} visible={last24HoursSelected} />
        <Last30DaysReport {...eventProps30Day} />
        <SelectedDateReport {...eventProps24Hours} visible={selectedDateSelected} selectedDate={recordSelectedDate} />
      </StateSwitch>
    </div>
  );
}
