import classNames from 'classnames';

import { FieldConfig } from './formsLib';

import './formField.css';

export interface FieldProps<FieldNames extends readonly string[], ErrorKeys extends string> {
  fieldName: string,
  config: FieldConfig<FieldNames, ErrorKeys>,
  value: string,
  error?: ErrorKeys,
  disabled?: boolean,
  onChange: (fieldName: string, value: string) => void,
  onValidate: (fieldName: string) => void
}

export function FormField<FieldNames extends readonly string[], ErrorKeys extends string>(props: FieldProps<FieldNames, ErrorKeys>): JSX.Element {
  const { fieldName, config, value, error, disabled, onChange, onValidate } = props;

  const { classes, required, readOnly, inputProps, renderInput, name, afterInputText, validationErrors } = config;

  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) { return; }
    const { value } = event.target;
    onChange(fieldName, value);
  };

  const blur = (_: React.FocusEvent<HTMLInputElement>) => {
    onValidate(fieldName);
  };

  const id = `field-${fieldName}`;
  const fieldClasses = classNames(
    'form-field',
    `form-field--field-${fieldName}`,
    classes,
    {
      'form-field--required': required,
      'form-field--error': Boolean(error),
      'form-field--read-only': readOnly,
      'form-field--disabled': disabled
    }
  );

  const getInput = () => {
    if (renderInput) {
      return renderInput(props);
    }
    if (readOnly) {
      return <span className="form-field__input form-field__input--read-only" data-id={`${id}__input`}>{value}</span>;
    }
    return (
      <input
        type="text" // override via inputProps
        {...inputProps}
        id={id}
        data-id={`${id}__input`}
        className="form-field__input"
        name={fieldName}
        onChange={change}
        onBlur={blur}
        disabled={disabled}
        required={required}
        value={value || ''} // default to an empty string to that input understands it is a controlled component
      />
    );
  };

  const errorText = validationErrors?.[error];

  return (
    <div className={fieldClasses}>
      <label className="form-field__label" data-id={`${id}__label`} htmlFor={id}>{name}:</label>
      <div className="form-field__input-wrapper">
        {getInput()}
        {afterInputText &&
          <span className="form-field__after-input" data-id={`${id}__after-input`}>{afterInputText}</span>
        }
        {errorText &&
          <span className="form-field__error" data-id={`${id}__error`}>{errorText}</span>
        }
      </div>
    </div>
  );
}
