import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import { NoSelectionOverlay } from '../card/noSelectionOverlay';
import { AmChart, coreTheme } from '../chart/amChart';
import { addHoverAnimation } from './onHoverAnimation';
import { ExportButton } from '../controls/exportButton';
import { addLegend, addLink } from './lib';
import { Theme } from '../../containers/app/themes';
import { getStackedColumnsDateChartAxisTooltipAdapter, getStackedColumnsDateChartTooltipAdapter } from './tooltips';

import { addEventOverlaysToColumnChart } from '../../containers/core/device/compositions/performance/charts/lib/eventOverlays';
import { unitOfTime } from 'moment';
import { animateBar } from './animateBar';
import './columnDateChart.css';
import { isEqual } from 'lodash';


/* istanbul ignore next */
const Container = styled.div<{ bottomBorder: boolean }>`
  position: relative;
  margin-top: 1.5rem;
  ${({ bottomBorder }) => bottomBorder && `border-bottom: 3px solid #edf1f3;`}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
`;

const Title = styled.h1`
  font-weight: ${({ theme }: { theme: Theme }) => theme.font.weights.bold};
  font-size: ${({ theme }: { theme: Theme }) => theme.font.sizes.sixteenPixels};
  width: 65%;
  margin-bottom: 0;
`;

const ChartContainer = styled.div`
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 14rem;
`;

const NoDataOverlay = styled(NoSelectionOverlay)`
  padding: 0;
`;

const Buttons = styled.div`
  margin-right: 0.625rem;
  display: flex;

  & button {
    margin-bottom: 5px;
  }
`;

type LinePattern = Pick<
  am4core.LinePattern,
  "width" | "height" | "strokeWidth" | "stroke" | "rotation"
>;

export interface StackedColumnsDateChartSeries<Data extends { date: string }> {
  visible: boolean,
  dataKey: keyof Omit<Data, 'date'>,
  description: string,
  colour: string,
  animate?: (column: any) => boolean,
  onBarClick?: (event: any) => void,
  onBarDblClick?: (event: any) => void,
  linePattern?: LinePattern
}

export interface dateAxisEndDuration {
  amount: string,
  unit : unitOfTime.DurationConstructor
}

export interface IProps<Data extends { date: string }> {
  data: Data[] | null,
  className?: string,
  series: StackedColumnsDateChartSeries<Data>[],
  title: string,
  days: number,
  cypressId: string,
  totalDescription?: string,
  bottomBorder?: boolean,
  onMount?: (chart: am4charts.XYChart) => am4charts.XYChart,
  buttons?: {
    Component: (props: any) => JSX.Element,
    props: any
  }[],
  export?: {
    onExportClicked: () => Promise<string>,
    filename: string,
    csv: boolean
  },
  formatData?: (dataForSeries: any, allData?: any) => string,
  drawYAxis?: (chart: am4charts.XYChart, axis: am4charts.ValueAxis | am4charts.DurationAxis, enabledSeries?: any[]) => void,
  amchartsYAxisNumberFormat?: string,
  yAxisType?: 'value' | 'duration',
  xAxisType?: 'category' | 'date',
  link?: string,
  hasLegend: boolean,
  onlyShowCurrentSeriesInTooltip?: boolean,
  drawXAxis: (chart: am4charts.XYChart, xAxis: am4charts.CategoryAxis | am4charts.DateAxis) => void,
  formatTooltipDate?: (date: string) => string,
  columnWidth?: number,
  tooltipDateKey?: keyof Data,
  hasNoSelectionOverlay?: boolean,
  getTooltipAdapter?: ({ seriesName }: { seriesName: string }) => (_: any, target: any) => string,
  useAxisTooltip?: boolean,
  getAxisTooltipAdapter?: () => (_: any, target: any) => string,
  eventOverlay?: boolean,
  dateAxisEndDuration?: dateAxisEndDuration
}

export function StackedColumnsDateChart<Data extends { date: string }>(props: IProps<Data>) {
  const [chartReady, setChartReady] = useState(false);
  const chartRef = useRef(null);
  const ns = 'translation';
  const { t } = useTranslation(['performance', 'editEvents', 'translation', 'deviceApplications', ns, 'timeState']);
  const { series: allSeries, drawYAxis, drawXAxis } = props;

  useEffect(() => {
    allSeries.forEach((seriesData) => {
      const series = chartRef.current.map.getKey(seriesData.dataKey);
      if (seriesData.visible) {
        series.show();
        series.hiddenInLegend = false;
      } else {
        series.hide();
        series.hiddenInLegend = true;
      }

      if (seriesData.animate) {
        series.columns.each((column: am4charts.Column) => {
          if (seriesData.animate(column)) {
            animateBar(column);
          }
        });
      }

      if (!props.useAxisTooltip) {
        series.columns.template.dummyData.allSeries = allSeries;
      }
    });
    drawYAxis && drawYAxis(chartRef.current, chartRef.current?.dummyData?.yAxis, allSeries.filter(series => series.visible).map(series => series.dataKey) as string[]);

    const xAxisData = chartRef.current?.dummyData?.xAxis.dummyData;
    if (xAxisData) {
      xAxisData.allSeries = allSeries;
    }
  }, [allSeries, drawYAxis, props.data, props.useAxisTooltip, chartRef.current?.dummyData?.yAxis]);

  return (
    <Container className={props.className || ''} bottomBorder={props.bottomBorder} data-id={`${props.cypressId}${chartReady ? '--chart-ready' : ''}`}>
      <Header>
        {props.hasNoSelectionOverlay !== false && <NoDataOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns })} show={Boolean(!props.data || props.data.length === 0)} />}
        <Title>{props.title}</Title>
        {(props.buttons || props.export) && <Buttons>
          {props.buttons?.map((button, index) => {
            return <button.Component key={`list-header-button-${index}`} {...button.props} />;
          })}
          {props.export && <ExportButton exportFunction={props.export.onExportClicked} filename={props.export.filename} />}
        </Buttons>}
      </Header>
      <ChartContainer>
        <AmChart
          tag={props.cypressId}
          chartProvider={createChart}
          dataProvider={() => props.data}
          onUpdate={props?.eventOverlay ? onDataUpdated : null}
          onMount={onMount}
          onDataValidated={() => setChartReady(true)}
          link={props.data}
          cypressId={props.cypressId}
        />
      </ChartContainer>
    </Container>
  );

  function createChart(id: string) {
    am4core.useTheme(coreTheme);

    const chart = am4core.create(id, am4charts.XYChart);
    chart.paddingTop = props.eventOverlay ? 40 : 20;
    chart.minHeight = 220;

    function createSeries(field: keyof Data, name: string, colour: string, xAxis: any, onBarClick?: (event: any) => void, onBarDblClick?: (event: any) => void, linePattern?: LinePattern) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.id = field as unknown as string;
      series.name = name;
      series.dataFields.valueY = field as unknown as string;
      series.dataFields[`${props.xAxisType === 'date' ? 'dateX' : 'categoryX'}`] = 'date';
      series.sequencedInterpolation = true;
      series.stacked = true;

      series.columns.template.fill = am4core.color(colour);
      series.columns.template.stroke = am4core.color(colour);
      series.columns.template.width = am4core.percent(props.columnWidth || 90);
      series.columns.template.tooltipHTML = '';
      /* istanbul ignore next */
      onBarClick && series.columns.template.events.on("hit", ev => {
        onBarClick(ev);
      });
      /* istanbul ignore next */
      if (onBarClick) {
        series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      }
      /* istanbul ignore next */
      onBarDblClick && series.columns.template.events.on("doublehit", ev => {
        onBarDblClick(ev);
      });

      if (props.link) {
        addLink(series, props.link);
      }
      if (!props.useAxisTooltip) {
        series.columns.template.adapter.add("tooltipHTML", props.getTooltipAdapter ? props.getTooltipAdapter({ seriesName: field as unknown as string }) : getStackedColumnsDateChartTooltipAdapter({
          seriesName: field as unknown as string,
          totalDescription: props.totalDescription,
          formatData: props.formatData,
          amchartsNumberFormat: props.amchartsYAxisNumberFormat,
          onlyShowCurrentSeries: props.onlyShowCurrentSeriesInTooltip,
          dateKey: props.tooltipDateKey,
          formatTooltipDate: props.formatTooltipDate
        }));
        series.columns.template.dummyData = {
          allSeries: props.series
        };
      }

      series.dummyData = {
        colour: am4core.color(colour)
      };

      if (props.eventOverlay) {
        addEventOverlaysToColumnChart(series, chart, xAxis, t, null, props.dateAxisEndDuration);
      }
      addHoverAnimation(series, chart);

      if (linePattern) {
        series.columns.template.adapter.add("fill", () => {
          var pattern = new am4core.LinePattern();

          pattern.width = linePattern.width;
          pattern.height = linePattern.height;
          pattern.strokeWidth = linePattern.strokeWidth;
          pattern.stroke = linePattern.stroke;
          pattern.rotation = linePattern.rotation;

          return pattern;
        });

        series.columns.template.strokeWidth = 0;
      }

      return series;
    }

    const xAxis = chart.xAxes.push(props.xAxisType === 'date' ? new am4charts.DateAxis() : new am4charts.CategoryAxis()) as am4charts.CategoryAxis;
    if (props.xAxisType === 'date') {
      (xAxis as unknown as am4charts.DateAxis).dataFields.date = 'date';
    } else {
      xAxis.dataFields.category = 'date';
    }
    if (!props.useAxisTooltip) {
      xAxis.cursorTooltipEnabled = false;
    }
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 50;

    if (props.useAxisTooltip) {

      xAxis.dummyData = { allSeries };

      xAxis.adapter.add('getTooltipText', props.getAxisTooltipAdapter ? props.getAxisTooltipAdapter() : getStackedColumnsDateChartAxisTooltipAdapter({
        totalDescription: props.totalDescription,
        formatData: props.formatData,
        amchartsNumberFormat: props.amchartsYAxisNumberFormat,
        dateKey: props.tooltipDateKey,
        formatTooltipDate: props.formatTooltipDate
      }));
      xAxis.dummyData.allSeries = allSeries;

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.disabled = true;
      // https://github.com/amcharts/amcharts4/issues/2203#issuecomment-595259897
      chart.cursor.maxTooltipDistance = -1;
      chart.cursor.behavior = 'none';
    }

    let yAxis: any;
    if (props.yAxisType === 'duration') {
      const durationAxis = chart.yAxes.push(new am4charts.DurationAxis());
      durationAxis.renderer.grid.template.disabled = true;

      yAxis = durationAxis;
    } else {
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.min = 0;
      valueAxis.renderer.minGridDistance = 30;
      valueAxis.calculateTotals = true;
      valueAxis.contentAlign = 'right';
      yAxis = valueAxis;
    }

    if (props.useAxisTooltip) {
      yAxis.tooltip.disabled = true;
    }

    props.series.forEach((series) => {
      createSeries(series.dataKey, series.description, series.colour, xAxis, series.onBarClick, series.onBarDblClick, series.linePattern);
    });

    chart.zoomOutButton.disabled = true;
    chart.dummyData = {
      yAxis,
      xAxis
    };

    if (props.hasLegend) {
      addLegend(chart);
    }

    chartRef.current = chart;
    return chart;
  }

  /* istanbul ignore next */
  function onDataUpdated(chart: am4charts.XYChart, chartData: any[], prevProps?: any, currentProps?: any) {
    const currentPropsEvents = extractEventsFromData(currentProps.link);
    const previousPropsEvents = extractEventsFromData(prevProps.link);
    if (!isEqual(currentPropsEvents, previousPropsEvents)) {
      addEventOverlaysToColumnChart(chart.series.values[0] as am4charts.ColumnSeries, chart, chart.xAxes.values[0] as am4charts.CategoryAxis, t, null, props.dateAxisEndDuration);
    }
    drawXAxis(chart, chart.xAxes.values[0] as am4charts.CategoryAxis);
    return chart;
  }

  function onMount(chart: am4charts.XYChart) {
    props.drawXAxis(chart, chart.xAxes.values[0] as am4charts.CategoryAxis);
    props.onMount && props.onMount(chart);
    return chart;
  }

  function extractEventsFromData(data: any) : any[] {
    const events = data.filter((data: any) => data.events);
    return events;
  }
}
