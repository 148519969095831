import { useMemo } from "react";

export const useIsExternalUser = (email: string) => {
  const result = useMemo(() => {
    const isExternalUser = !/^.*@(b2msolutions|b2m-solutions|simulator\.amazonses)\.com$/.test(email);
    return {
      isExternalUser
    };
  }, [email]);
  return result;
};
