import React, { useContext, useMemo } from 'react';
import { World } from '../services/worlds/worlds';

export interface WorldsContextValue {
  worlds: World[]
}

export const WorldsContext = React.createContext<WorldsContextValue>(undefined);

interface WorldsProviderProps {
  worlds: World[],
  children: React.ReactNode
}

export const WorldsProvider = ({ children, worlds }: WorldsProviderProps) => {
  const contextValue: WorldsContextValue = useMemo(() => ({
    worlds
  }), [worlds]);

  return (
    <WorldsContext.Provider value={contextValue}>
      {children}
    </WorldsContext.Provider>
  );
};

export function useWorldsContext() {
  const context = useContext(WorldsContext);
  if (context === undefined) {
    throw new Error('useWorldsContext must be used within a WorldsProvider');
  }
  return context;
}
