import { useEffect } from "react";


export const useInterval = (callback: () => void, delay: number | null) => {
  useEffect(() => {
    if (delay !== null) {
      callback(); // Execute the callback once before starting the interval.

      const id = setInterval(() => {
        callback();
      }, delay);

      return () => clearInterval(id);
    }
  }, [callback, delay]);
};
