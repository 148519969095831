import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { Card } from '../../../components/card/card';

import "./coreMaps.css";
import { TabPanel } from '../../../components/tabs/tab-panel';
import { TabPanelHeader } from '../../../components/tabs/tab-panel-header';
import { getMapIdFromTabTitle, tabsMap } from './tabs';
import { LinkTab } from '@components/tabs/link-tab';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';

export const CoreMapsPage: React.FC<{ mapId: string }> = ({ mapId }) => {
  const { t } = useTranslation();
  const { t: t2 } = useTranslation('maps');
  const basePath = useWorldRoutes().core.maps;

  const tabs = tabsMap
    .map(item => {
      const TabContent = item.tab;
      const mapTabParam = getMapIdFromTabTitle(item.title).toLowerCase().replaceAll('_', '-');
      return <LinkTab title={t2(item.title)} key={item.title} link={`${basePath}/${mapTabParam}`}>
        <TabContent />
      </LinkTab>;
    });

  const defaultTabIndex = React.useMemo(() => {
    return tabsMap.findIndex(tab => {
      const tabMapId = getMapIdFromTabTitle(tab.title);

      return tabMapId === mapId;
    });
  }, [mapId]);

  return (
    <div className="core-maps-container">
      <Card className="core-maps" noPadding>
        <TabPanel defaultTabIndex={defaultTabIndex}>
          <TabPanelHeader>
            <div className="core-maps_page-header" data-id="page-header">
              <div className="title">
                {t('MAPS')}
              </div>
            </div>
          </TabPanelHeader>
          {tabs}
        </TabPanel>
      </Card>
    </div>
  );
};
