import React, { useContext } from 'react';
import { UserAuth } from '../components/authentication/userAuth';

export const CurrentUserContext = React.createContext<UserAuth>(undefined);

interface CurrentUserProviderProps {
  value: UserAuth,
  children: React.ReactNode
}

export const CurrentUserProvider = ({ children, value }: CurrentUserProviderProps) => {
  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export function useCurrentUserContext() {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error('useCurrentUserContext must be used within a CurrentUserProvider');
  }
  return context;
}
