import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { AuthState, ChallengeName, UI_AUTH_CHANNEL, AUTH_STATE_CHANGE_EVENT } from '@aws-amplify/ui-components';
import { isString } from 'lodash';

import { devLogger } from '../../lib/logger';

// copied from @aws-amplify/ui-components constants since not exported
export const AUTHENTICATOR_AUTHSTATE = 'amplify-authenticator-authState';

// copied from @aws-amplify/ui-components helpers since not exported
export interface ToastError {
  code: string,
  name: string,
  message: string
}

// set in our cognito user pool settings
export const minPasswordLength = 8;

// from https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html
export const cognitoSpecialCharacters = [
  '=', '+', '^', '$', '*', '.', '[', ']',
  '{', '}', '(', ')', '?', '"', '!', '@',
  '#', '%', '&', '/', '\\', ',', '>', '<',
  "'", ':', ';', '|', '_', '~', '`', '-'
];

export const supportedAuthStates = [AuthState.SignIn, AuthState.ForgotPassword, AuthState.ResetPassword] as const;
export type SupportedAuthState = typeof supportedAuthStates[number];

export const isSupportedAuthState = (authState: AuthState): authState is SupportedAuthState => {
  return supportedAuthStates.includes(authState as any);
};

export const defaultErrorMessage = 'Sorry, something went wrong.';
export const badEmailOrPasswordMessage = 'Incorrect email address or password.';
export const invalidPasswordMessage = (
  `Password was not valid. Please try again using at least ${minPasswordLength} characters,` +
  ' no spaces, and at least one each of the following characters: lowercase, uppercase, special and number.'
);

// Auth.signIn calls through to the InitiateAuth cognito action
// q.v. https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_InitiateAuth.html
const signInErrorMap = {
  UserNotFoundException: badEmailOrPasswordMessage, // unknown email
  NotAuthorizedException: badEmailOrPasswordMessage, // incorrect password
};

// Auth.forgotPassword calls through to the ForgotPassword cognito action
// q.v https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ForgotPassword.html
// Auth.forgotPasswordConfirm calls through to the ConfirmForgotPassword cognito action
// q.v. https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_ConfirmForgotPassword.html
const forgotPasswordErrorMap = {
  // we do not handle UserNotFoundException in order not to expose valid email addresses
  CodeMismatchException: 'Code is not valid.',
  ExpiredCodeException: 'Code has expired, please request a new code.',
  CodeDeliveryFailureException: 'Code could not be sent, please try again.',
  LimitExceededException: 'Attempt limit exceeded, please try again after some time.',
  InvalidPasswordException: invalidPasswordMessage
};

// Auth.completeNewPassword calls through to the RespondToAuthChallenge cognito action
// q.v. https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_RespondToAuthChallenge.html
const resetPasswordErrorMap = {
  // we should not need to handle NotAuthorizedException etc. since we only use this state post sign in
  InvalidPasswordException: invalidPasswordMessage
};

export const errorMaps: { [k in SupportedAuthState]: Record<string, string> } = {
  [AuthState.SignIn]: signInErrorMap,
  [AuthState.ForgotPassword]: forgotPasswordErrorMap,
  [AuthState.ResetPassword]: resetPasswordErrorMap,
};

export const getErrorMessage = (error: ToastError, authState: SupportedAuthState) => {
  const errorMap = errorMaps[authState];
  return errorMap[error.code] || defaultErrorMessage;
};

/**
 * Dispatches hub messages re authState and user data for the UI
 *
 * based on dispatchAuthStateChangeEvent from @aws-amplify/ui-components helpers
 * q.v. https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-components/src/common/helpers.ts
 */
export const dispatchAuthStateChangeEvent = (nextAuthState: AuthState, data?: any) => {
  Hub.dispatch(UI_AUTH_CHANNEL, {
    event: AUTH_STATE_CHANGE_EVENT,
    message: nextAuthState,
    data,
  });
};

export const dependencies = {
  dispatchAuthStateChangeEvent
};

/**
 * Signs user in and handles expected exceptions and challenges
 *
 * based on handleSignIn from @aws-amplify/ui-components, but handles fewer cases.
 * q.v. https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-components/src/common/auth-helpers.ts
 */
export const handleSignIn = async (username: string, password: string) => {
  try {
    const user = await Auth.signIn(username, password);
    if (user.challengeName === ChallengeName.NewPasswordRequired) {
      return dependencies.dispatchAuthStateChangeEvent(AuthState.ResetPassword, user);
    } else {
      return dependencies.dispatchAuthStateChangeEvent(AuthState.SignedIn, user);
    }
  } catch (error) {
    if (error.code === 'PasswordResetRequiredException') {
      return dependencies.dispatchAuthStateChangeEvent(AuthState.ForgotPassword, { username, authErrorCode: 'PasswordResetRequiredException' });
    }
    throw error;
  }
};

export const externalSignedInEvents = ['cognitoHostedUI', 'signIn'];
export const externalSignInEvents = ['cognitoHostedUI_failure', 'parsingUrl_failure', 'signOut', 'customGreetingSignOut'];

/**
 * Handles hub events dispatched by Amplify OAuth on the 'auth' channel
 *
 * based on handleExternalAuthEvent in the @aws-amplify/ui-components Authenticator
 * q.v. https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-components/src/components/amplify-authenticator/amplify-authenticator.tsx
 */
export const handleExternalAuthEvent = ({ payload }: any) => {
  if (externalSignedInEvents.includes(payload.event)) {
    dependencies.dispatchAuthStateChangeEvent(AuthState.SignedIn, payload.data);
  } else if (externalSignInEvents.includes(payload.event)) {
    dependencies.dispatchAuthStateChangeEvent(AuthState.SignIn);
  }
};

/**
 * Checks if user is already authenticated on mount
 *
 * based on checkUser in the @aws-amplify/ui-components Authenticator
 * q.v. https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-components/src/components/amplify-authenticator/amplify-authenticator.tsx
 */
export const checkUser = async (setUserChecked: (checked: boolean) => void) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    dependencies.dispatchAuthStateChangeEvent(AuthState.SignedIn, currentUser);
  } catch (error) {
    let cachedAuthState = null;
    try {
      cachedAuthState = window.localStorage.getItem(AUTHENTICATOR_AUTHSTATE);
    } catch (error) {
      devLogger.warn('Failed to get the auth state from local storage', error);
    }
    try {
      if (cachedAuthState === AuthState.SignedIn) {
        await Auth.signOut();
      }
    } catch (error) {
      devLogger.warn('Failed to sign out', error);
    } finally {
      dependencies.dispatchAuthStateChangeEvent(AuthState.SignIn);
    }
  } finally {
    setUserChecked(true);
  }
};

/** Validates password against our requirements and constructs an error message if invalid */
export const validatePassword = (password: string, passwordConfirm: string): string | undefined => {
  const escapedSpecialChars = cognitoSpecialCharacters.map(c => `\\${c}`);
  type RulesList = [(a: string, b?: string) => boolean, string][];
  const wordRules: RulesList = [
    [(a: string, b: string) => a === b, 'does not match the confirmation'],
    [(a: string) => a.length >= minPasswordLength, `is less than ${minPasswordLength} characters long`],
    [(a: string) => /^\S*$/.test(a), 'contains spaces']
  ];
  const characterRules: RulesList = [
    [(a: string) => /[0-9]/.test(a), 'number'],
    [(a: string) => /[a-z]/.test(a), 'lowercase'],
    [(a: string) => /[A-Z]/.test(a), 'uppercase'],
    [(a: string) => new RegExp(`[${escapedSpecialChars}]`).test(a), 'special'],
  ];
  const checkRules = (rules: RulesList) => {
    return rules.map(([test, message]) => test(password, passwordConfirm) || message).filter((m => isString(m)));
  };
  const finalAnd = (text: string): string => {
    return text.replace(/, ([^,]*)$/, ' and $1');
  };
  const wordRuleMessages = checkRules(wordRules);
  const characterRuleMessages = checkRules(characterRules);
  if (!wordRuleMessages.length && !characterRuleMessages.length) { return; }
  const prefix = 'Password ';
  const characterRuleText = !characterRuleMessages.length ? ''
    : `does not contain at least one ${finalAnd(characterRuleMessages.join(', '))} character`;
  const wordRuleText = characterRuleText
    ? wordRuleMessages.join(', ') // no final 'and' required if we also have character messages
    : finalAnd(wordRuleMessages.join(', '));
  const conjunction = characterRuleText && wordRuleText ? ' and ' : '';
  return `${prefix}${wordRuleText}${conjunction}${characterRuleText}.`;
};
