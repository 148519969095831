import * as React from "react";

import './button.css';

export interface IButtonProps {
  dataId?: string,
  id?: string,
  value?: string,
  iconStyle?: string,
  text?: string | JSX.Element,
  iconBeforeText?: boolean,
  className?: string,
  type?: 'submit' | 'reset' | 'button',
  disabled?: boolean,
  autoFocus?: boolean,
  onClick?: (value?: string) => void,
  'data-toggle'?: string
}

export function Button(props: IButtonProps) {
  const onClick = props.onClick ? () => { props.onClick(props.value); } : undefined;
  const beforeIcon = props.iconBeforeText && props.iconStyle ? <i data-id='button-icon' className={'before ' + props.iconStyle} /> : '';
  const afterIcon = !props.iconBeforeText && props.iconStyle ? <i data-id='button-icon' className={'after ' + props.iconStyle} /> : '';
  const className = props.className ? 'react-button ' + props.className : 'react-button';
  const spacing = (icon: React.ReactNode) => {
    return props.text && icon ? <React.Fragment>&nbsp;</React.Fragment> : null;
  };
  const displayText = beforeIcon || afterIcon ? <span className="d-none d-lg-inline">{spacing(beforeIcon)}<span>{props.text}</span>{spacing(afterIcon)}</span> : props.text;

  return (
    <button
      data-id={props.dataId}
      id={props.id}
      className={className}
      type={props.type}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      data-toggle={props['data-toggle']}
      onClick={onClick}>

      {beforeIcon}{displayText}{afterIcon}

    </button>);
}

Button.defaultProps = { type: "button", disabled: false };
