import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { AuthState } from '@aws-amplify/ui-components';

import { useAuthContext } from '../../context/auth';
import { dispatchAuthStateChangeEvent, getErrorMessage, validatePassword } from './utils';
import Card from '../../components/card/card';
import PasswordPolicy from './passwordPolicy';
import { devLogger } from '../../lib/logger';

import './style.css';

export const RequireNewPassword = () => {
  const { user } = useAuthContext();
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [pending, setPending] = useState(false);
  const [passwordTrim, passwordConfirmTrim] = [password.trim(), passwordConfirm.trim()];

  const passwordsSet = passwordTrim && passwordConfirmTrim;
  const canChangePassword = user && passwordsSet && !pending;

  if (!user) {
    devLogger.error('Cannot require new password if user not set');
    dispatchAuthStateChangeEvent(AuthState.SignIn);
    return null;
  }

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };
  };

  const handleError = (err: any) => {
    setError(getErrorMessage(err, AuthState.ResetPassword));
    setPending(false);
  };

  const changePassword = async () => {
    error && setError('');
    const passwordInvalidMessage = validatePassword(password, passwordConfirm);
    if (passwordInvalidMessage) {
      setError(passwordInvalidMessage);
      return;
    }
    if (canChangePassword) {
      setPending(true);
      try {
        const data = await Auth.completeNewPassword(user, password);
        devLogger.log('user password changed', data);
        return dispatchAuthStateChangeEvent(AuthState.SignedIn, user);
      } catch (e) {
        handleError(e);
      }
    }
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      changePassword();
    }
  };

  return (
    <div className="row justify-content-center">
      <div className="center_container">
        <Card>
          <h5 className="alignCenter formHeader">Choose a Password</h5>
          <div className="alignCenter formSubHeader">Please enter your password</div>
          <div className="row justify-content-center">
            <div className="alignCenter formText col-8">
              <PasswordPolicy/>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="form-group has-float-label col-8">
              <label htmlFor="password">Password:</label>
              <div className="input-group password">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fas fa-lock"/></span>
                </div>
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  name="password"
                  onKeyPress={handleEnter}
                  value={password}
                  onChange={handleInputChange(setPassword)}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="form-group has-float-label col-8">
              <label htmlFor="confirmPassword">Confirm password:</label>
              <div className="input-group confirmPassword">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fas fa-lock"/></span>
                </div>
                <input
                  id="confirmPassword"
                  type="password"
                  className="form-control"
                  name="passwordMatch"
                  onKeyPress={handleEnter}
                  value={passwordConfirm}
                  onChange={handleInputChange(setPasswordConfirm)}
                />
              </div>
            </div>
          </div>
          <div className="btnAlign">
            {error && <div id="authError">{error}</div>}
            <button
              id="submitPassword"
              className="btn col-8"
              type="button"
              disabled={!canChangePassword}
              onClick={changePassword}
            >Confirm</button>
          </div>
        </Card>
        <div className="card2">
          <Card>
            <div className="alignCenter">
              <span
                id="backToSignInBtn"
                className="redirectBtn"
                onClick={() => dispatchAuthStateChangeEvent(AuthState.SignIn)}
              >Return to Sign In</span>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
