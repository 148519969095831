import React, { useCallback } from 'react';

import { Card } from '../../components/card/card';
import { Loading } from '../../components/loading/loading';
import { IAgreements, getAgreements } from '../../services/agreements/agreements';
import { useWorldRequest } from '../../lib/useWorldRequest';

interface IProps {
  agreement: keyof IAgreements
}

export const Agreement = (props: IProps) => {
  const { agreement } = props;

  const agreementsFetcher = useCallback(() => {
    return getAgreements({ [agreement]: true });
  }, [agreement]);
  const { loading, data } = useWorldRequest(agreementsFetcher);

  if (loading || !data) {
    return <Loading isLoading={true} transparentOverlay={false} />;
  }

  return (
    <Card className="card--narrow card--centered card--rounded card--newlines">
      {data[agreement].text}
    </Card>
  );
};
