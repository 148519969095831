import { isNumber } from 'lodash';
import React, { Reducer, useCallback, useContext, useMemo, useReducer, useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';

import { DatePicker } from '../../../../../components/datePicker/datePicker';
import { Button } from '../../../../../components/controls/button';
import { ChipButton } from '../../../../../components/controls/chipButton';
import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { DeviceSignalStrengthData, exportDeviceSignalStrength, getDeviceSignalStrength, SignalStrengthRange } from '../../../../../services/core/deviceNetwork';
import { DeviceInfoContext } from '../..';
import { useTranslation } from '@lib/useTypedTranslation';
import { Loading } from '../../../../../components/loading/loading';
import { SignalLevelFilter } from '../../../../../components/filters/signalLevelFilter';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { DetailsMap } from '../../../../../components/detailsMap/detailsMap';
import { NoSelectionOverlay } from '../../../../../components/card/noSelectionOverlay';
import { useTimeout } from '../../../../../lib/useTimeout';
import { ITranslationKeys } from 'components/i18n/keys';
import { ExportButton } from '@components/controls/exportButton';
import { useWorldAction } from '@lib/useWorldAction';
import { useToggleList } from '@lib/useToggleList';

import { SelectedDateChart } from './selectedDateChart';
import { DeviceEvent } from 'services/core/eventsTypes';

export type SignalStrengthChartData = {
  date: string,
  local: number,
  '0'?: number,
  '1'?: number,
  '2'?: number,
  '3'?: number,
  '4'?: number,
  '5'?: number,
  level?: number,
  greyedOut?: number,
  radioOff?: number,
  noService?: number,
  networkEvents?: number,
  noDataAvailable?: boolean,
  events?: DeviceEvent[],
  tooltipDate?: number,
  original?: DeviceSignalStrengthData
};

export const renderDatePicker = (props: any, openCalendar: Function, closeCalendar: Function) => {
  return (
    <DatePickerRenderContainer>
      <DatePickerInput {...props} />
      <div onClick={
        /* istanbul ignore next */
        () => openCalendar()
      }>
        <DatePickerButton
          type="button"
          iconStyle="far fa-calendar-times"
          dataId='open-calendar'
        />
      </div>
    </DatePickerRenderContainer>
  );
};

export type SignalStrengthConfiguration = SignalStrengthRange & {
  colour: string, translationKey: string
};

export const mapDataToChartGetter = (bearerTypes: string[], level: number) => (d: DeviceSignalStrengthData): SignalStrengthChartData => {
  const base = {
    date: moment.utc(d.date).format('HH:mm'),
    local: d.local || d.date,
    level: 0,
    original: d
  };

  if (d.status === "radioOff") {
    return {
      ...base,
      radioOff: 5.5
    };
  }

  if (d.status === "outOfService" || d.status === "noService") {
    return {
      ...base,
      noService: 5.5
    };
  }

  if (!isNumber(d.level) || !bearerTypes?.includes(d.bearerType)) {
    return {
      ...base,
      noDataAvailable: true
    };
  }

  if (d.level > level) {
    return {
      ...base,
      level: d.level,
      greyedOut: d.level + 0.5
    };
  }

  return {
    ...base,
    level: d.level,
    [`${d.level}`]: d.level + 0.5
  };
};

export function getLevelToDescriptionMapping(numberOfBars: number, t: TTypedTFunction): { [key: string]: string } {
  return numberOfBars === 4 ?
    { '0': t('VERY_POOR'), '1': t('POOR'), '2': t('MODERATE'), '3': t('GOOD'), '4': t('GREAT') } :
    { '0': t('VERY_POOR'), '1': t('POOR'), '2': t('MODERATE'), '3': t('FAIR'), '4': t('GOOD'), '5': t('GREAT') };
}

function getTooltipTranslations(d: SignalStrengthChartData, t: TTypedTFunction, numberOfBars: number) {
  const levelToDescriptionMapping = getLevelToDescriptionMapping(numberOfBars, t);

  const signalPart = d.noService ? t('NO_SERVICE') : `${levelToDescriptionMapping[d.level]} (${t('BAR_COUNT', { count: d.level })})`;

  const signal = `${t('SIGNAL')}: ${signalPart}`;
  const latLong = `${t('LAT/LONG')}: ${isNumber(d.original.latitude) && isNumber(d.original.longitude) ? `${d.original.latitude}, ${d.original.longitude}` : '-'}`;
  const operator = `${t('OPERATOR')}: ${d.original.operatorName ? d.original.operatorName : '-'}`;
  const network = `${t('NETWORK')}: ${d.original.bearerType ? t(d.original.bearerType.toUpperCase()) : '-'}`;
  const operatorCode = d.original.operatorCode ? ` (${d.original.operatorCode})` : '';

  return { signal, latLong, operator, network, operatorCode };
}

export function getTooltipAdapter(params: { numberOfBars: number, t: TTypedTFunction }) {
  return (_: any, target: any) => {
    const { t, numberOfBars } = params;
    const data = target.tooltipDataItem?.dataContext as SignalStrengthChartData;

    const date = moment.utc(data.local).format('HH:mm:ss');

    if (data.noDataAvailable) {
      return `[font-weight: 600]${date}[/]
      ${t('NO_DATA_AVAILABLE', { ns: 'translation' })}`;
    }

    if (data.radioOff) {
      return `[font-weight: 600]${date}[/]
      ${t('RADIO_OFF', { ns: 'translation' })}`;
    }

    const { signal, latLong, operator, network, operatorCode } = getTooltipTranslations(data, t, numberOfBars);

    const tooltip = `[font-weight: 600]${date}[/]
    ${signal}
    ${latLong}
    ${!data.noService ? `${operator}${operatorCode}` : ''}
    ${!data.noService ? `${network}` : ''}
    `;

    return tooltip;
  };
}

export function MapTooltipLabel(props: { data: SignalStrengthChartData, numberOfBars: number }) {
  const { data, numberOfBars } = props;
  const { t } = useTranslation('deviceNetwork');

  const { signal, latLong, operator, network, operatorCode } = getTooltipTranslations(data, t, numberOfBars);

  return <div>
    <TooltipDate data-id='tooltip-date'>{moment.utc(data.local).format('HH:mm:ss')}</TooltipDate>
    <div>{signal}</div>
    <div>{latLong}</div>
    {!data.noService && <div>{operator + operatorCode}</div>}
    {!data.noService && <div>{network}</div>}
  </div>;
}

export function DeviceNetwork(props: { selected?: boolean }) {
  const { id: deviceId, platformType, manufacturer } = useContext(DeviceInfoContext);
  const { list: bearerTypes, toggleItem } = useToggleList(['2g', '3g', '4g', '5g'], false);

  const numberOfBars = (platformType === 'android' && manufacturer?.toLowerCase() !== 'zebra technologies') ? 4 : 5;

  const barColours = useMemo(() => {
    const fourBarColours = [
      'rgb(238, 238, 238)',
      'rgb(0, 0, 0)',
      'rgb(220,5,12)',
      'rgb(241,147,45)',
      'rgb(202,224,171)',
      'rgb(78,178,101)'
    ];
    const fiveBarColours = [
      'rgb(238, 238, 238)',
      'rgb(0, 0, 0)',
      'rgb(220,5,12)',
      'rgb(241,147,45)',
      'rgb(247,240,86)',
      'rgb(202,224,171)',
      'rgb(78,178,101)'
    ];
    return numberOfBars === 4 ? fourBarColours : fiveBarColours;
  }, [numberOfBars]);
  const [level, setLevel] = useState(barColours.length - 1);
  const [selectedDate, setSelectedDate] = useState<number>();
  const [activeBar, setActiveBar] = useState<{ local: number }>();
  const [activeMapMarker, setActiveMapMarker] = useState<{ local: number, zoomTo: boolean }>();
  const { t } = useTranslation(['deviceNetwork', 'translation']);

  const dates = useMemo(() => ({
    from: moment.utc(selectedDate).startOf('day').valueOf(),
    to: moment.utc(selectedDate).endOf('day').valueOf()
  }), [selectedDate]);

  const fetcher = useCallback(() => {
    const params = {
      deviceId,
      to: dates.to,
      numberOfBars: barColours.length - 2
    };
    return getDeviceSignalStrength(params);
  }, [dates.to, deviceId, barColours]);
  const { data, loading } = useWorldRequest(fetcher, { initialLoading: true });
  const { addTimeout } = useTimeout();

  function setDate(date: string | Moment) {
    /* ignored else condition as it is not possible to reproduce
      date as a string, this would mean the date would be
      invalid https://www.npmjs.com/package/react-datetime */
    /* istanbul ignore else */
    if (moment.isMoment(date)) {
      setSelectedDate(date.valueOf());
    }
  }

  const exportDeviceSignalStrengthList = useWorldAction(exportDeviceSignalStrength);
  const onExportClicked = async (): Promise<string> => {
    return exportDeviceSignalStrengthList({ deviceId, to: dates.to });
  };

  const noDataAvailable = !data?.filter(d => d.bearerType || d.status || isNumber(d.level)).length;

  const chartData = data?.map(mapDataToChartGetter(bearerTypes, level));

  /* istanbul ignore next */ /* react leaflet elements not supported in jsdom, testing of map done in cypress */
  const mapData = chartData?.filter(d => !d.noDataAvailable && !d.radioOff && d.original.position).map(d => {
    let styleClass: string;

    if (d.greyedOut) {
      styleClass = 'greyed-out';
    } else if (d.noService) {
      styleClass = `no-service`;
    } else {
      styleClass = `total-bars-${numberOfBars} bars-${d.level}`;
    }

    return {
      position: d.original.position,
      radius: { size: 20, colour: d.greyedOut ? '#9e9e9d' : barColours[d.level + 1] },
      styleClass: styleClass,
      showOnlyCircleOnZoom: true,
      showLabelOnHover: true,
      label: <MapTooltipLabel data={d} numberOfBars={numberOfBars} />,
      animateOnClick: true,
      disabled: Boolean(d.greyedOut),
      onClick: () => {
        setActiveBar({ local: d.local });

        addTimeout(() => {
          setActiveBar(s => d.local === s?.local ? undefined : s);
        }, 2000);
      },
      selected: d.local === activeMapMarker?.local,
      zoomTo: activeMapMarker?.zoomTo
    };
  }) || [];

  return (
    <>
      <SelectorRow>
        <ButtonRow>
          <DatePickerContainer>
            <DatePicker
              dateFormat={`LLLL 'YY`}
              timeFormat={false}
              initialValue={moment()}
              isValidDate={(current: Moment) => current.isBefore(moment()) && current.isAfter(moment().subtract(90, 'day'))}
              onChange={setDate}
              value={selectedDate}
              renderInput={renderDatePicker}
            />
          </DatePickerContainer>
          <StyledSignalLevelFilter
            bars={barColours.slice(2).map((colour, i) => ({ colour, level: i + 1 }))}
            onChange={setLevel}
            disabled={loading || !data?.find(data => data.bearerType)}
          />
          {
            ['2g', '3g', '4g', '5g'].map(bearerType => {
              const isDisabled = loading || noDataAvailable;
              return <FilterButton
                disabled={isDisabled}
                onClick={() => toggleItem(bearerType)}
                active={bearerTypes?.includes(bearerType) && !isDisabled}
                key={bearerType}
                text={t(bearerType.toUpperCase() as keyof ITranslationKeys['deviceNetwork'], { ns: 'deviceNetwork' })}
              />;
            })
          }
        </ButtonRow>
        <ExportButtonContainer>
          <ExportButton exportFunction={onExportClicked} filename={`DeviceSignalStrength_.${moment.utc(selectedDate).format(`LLLL 'YY`)}.csv`} disabled={loading || noDataAvailable}/>
        </ExportButtonContainer>
      </SelectorRow>
      <DataLoading isLoading={loading} transparentOverlay={false}>
        <MapAndChartContainer>
          <NoDataOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns: 'translation' })} show={noDataAvailable} />
          {/* key added for re-mount of leaflet map when tab goes from being hidden to visible - leaflet map breaks if it has been hidden */}
          <DetailsMap
            id={deviceId}
            key={`map-${props.selected}`}
            visible={props.selected}
            zoomScale={30}
            items={mapData}
          />
          <SelectedDateChart
            selectedDate={selectedDate}
            barColours={barColours} setActiveMapMarker={setActiveMapMarker} activeBar={activeBar} numberOfBars={numberOfBars}
            data={chartData} noSignalDataAvailable={noDataAvailable} />
        </MapAndChartContainer>
      </DataLoading>
    </>
  );
}

const DatePickerContainer = styled.div`
  margin-top: 6px;
  margin-left: 10px;
  max-width: 162px;
  font-weight: 600;

  & .rdtOpen .rdtPicker {
    left: 0;
  }
`;

const ExportButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
`;

const DatePickerRenderContainer = styled.div`
  display: flex;
  max-width: 162px;
`;

const DatePickerInput = styled.input`
  margin-top: 0.571em;
  margin-left: 0.5em;

  &.form-control {
    cursor: pointer;
    flex: 0 0 120px;
    background-color: #fff;
    border: 1px solid #c2d1e0;
    padding: 0.35rem 0.35rem 0.35rem 0.6rem;
    border-radius: 3px 0 0 3px;
    font-size: 1em;
  }
`;

const DatePickerButton = styled(Button)`
  color: #0072af;
  background-color: #fff;
  border-color: #0072af;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  border: 1px solid #0072af;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  flex: 0 0 auto;
  box-shadow: 0 2px 4px 0 rgba(176, 176, 176, 0.5);

  &::after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f0d7';  /* caret-down */
    font-size: 12px;
    margin-left: 4px;
    line-height: 1.9rem;
  }

  & > i {
    font-size: 1rem;
    line-height: 1.8rem;
    margin-left: 2px;
  }

  &:hover, &:active {
    color: #fff;
    background-color: #0072af;
    transition: 0.2s;
  }
`;

const MapAndChartContainer = styled.div`
  margin-top: 20px;
  position: relative;
  height: 100%;
  user-select: none;

  .leaflet-container {
    height: 50vh;
    border-radius: 5px;
  }
`;

const NoDataOverlay = styled(NoSelectionOverlay)`
  padding: 0;

  & .noSelectionOverlayInner {
    border-radius: 0;
  }
`;

const ButtonRow = styled.div`
  margin-top: 10px;
  margin-left: 30px;
  height: 40px;
  width: 350px;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const SelectorRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
`;

const FilterButton = styled(ChipButton)`
  margin-top: 6px;
  margin-left: 10px;
  opacity: 1;

  :focus {
    outline: none;
    box-shadow: none;
  }

  &.chip-button-active {
    cursor: pointer;
  }
`;

const DataLoading = styled(Loading)`
  height: 600px;
`;

const StyledSignalLevelFilter = styled(SignalLevelFilter)`
  margin-left: 140px;
  margin-right: 20px;
  position: relative;
`;

const TooltipDate = styled.div`
  font-weight: 600;
`;
