import React, { useContext } from 'react';

import './style.css';

import TableRow from '../../components/table/tableRow/batteryTableRow';
import { Estate, IEstateCategory } from '../../components/chart/estate';
import DeviceDetails from '../../components/battery/detailsOuter';
import { getGroups, IGroups } from '../../services/config/config';
import { IFilterResult } from '../../components/filters/filterSelectionControl';
import { ListCard } from '../../components/listCard/listCard';
import { IListCategory } from '../../components/listCard/list';
import { List } from '../../services/shared/battery/list';
import { ISortResult, VIEW } from '../../services/shared/common';
import { DataLoading } from '../../components/loading/dataLoading';
import { isEmpty, get, isNil } from 'lodash';
import { useEffect, useState, useMemo } from 'react';
import { RequestInitWithRetry } from '../../lib/request';
import { useWorldRequest } from '../../lib/useWorldRequest';
import { useWorldAction } from '../../lib/useWorldAction';

/* istanbul ignore next */
export const useAppContext = () => useContext(AppContext);

export const AppContext = React.createContext({
  setSelectedDevice: null,
  getSelectedDevice: null,
  setSelectedView: null,
  getSelectedView: null,
  getSelectedRow: null,
  setSelectedBatteryIndex: null,
  getSelectedBatteryIndex: null,
  batteryStatus: null
});

interface IProps {
  view: VIEW,
  config: any
}

const FIELDS = [
  'Manufacturer',
  'Model',
  'Serial Number',
  'IMEI',
  'Groups',
  'Average Discharge',
  'Battery',
  'Battery PN',
  'Battery SN',
  'Status'
];

export const BatteryContainer = (props: IProps) => {
  const list = new List(props.config.productName);
  const [view, setView] = useState(VIEW.red);
  const [deviceView, setDeviceView] = useState('');
  const [availableFilterGroups, setAvailableFilterGroups] = useState<string[]>([]);
  const [selectedId, setSelectedId] = useState<string>('-');
  const [batteryNumber, setBatteryNumber] = useState<number>(1);

  useEffect(() => {
    if (!isNil(props.view)) {
      setView(props.view);
    }
  }, [props.view]);

  const contextValue = useMemo(() => {
    return {
      id: selectedId,
      batteryId: '-',
      deviceView: deviceView,
      availableFilterGroups: availableFilterGroups,
      batteryNumber: batteryNumber,
      setSelectedDevice: (newId: string) => setSelectedId(newId),
      getSelectedDevice: () => selectedId,
      setSelectedView: (view: VIEW) => setDeviceView(view),
      getSelectedView: () => deviceView,
      getSelectedRow: (id: string) => id === selectedId ? true : false,
      getSelectedBatteryIndex: () => batteryNumber,
      setSelectedBatteryIndex: (index: number) => setBatteryNumber(index),
      batteryStatus: props.config.batteryStatus
    };
  }, [availableFilterGroups, batteryNumber, deviceView, props.config.batteryStatus, selectedId]);

  const [groupsDataFetcher, onDeviceGroupsFetched] = useMemo(() => {
    return [
      () => (options: RequestInitWithRetry) => getGroups()(options),
      (groupsRaw :IGroups) => {
        const groups = get(groupsRaw, 'groups.nodes', []).map((o: any) => o.name);
        setAvailableFilterGroups(groups);
      }
    ];
  }, []);

  useWorldRequest(groupsDataFetcher, { onSuccess: onDeviceGroupsFetched });
  const doGetList = useWorldAction(list.get);
  const doExportList = useWorldAction(list.export);
  const doGetBatteryEstateData = useWorldAction(list.getBatteryEstateData);

  async function getList(statusType: string, search: string, limit: number, offset: number, filters: IFilterResult, sort: ISortResult): Promise<any[]> {
    return doGetList({ statusType, search, limit, offset, sort, filters: { groups: filters ? filters.groups : undefined, underWarranty: !isEmpty(get(filters, 'underWarranty')) } });
  }

  async function exportList(statusType: string, search: string, limit: number, offset: number, filters: IFilterResult): Promise<any[]> {
    return doExportList({ statusType, search, limit, offset, filters: { groups: filters ? filters.groups : undefined, underWarranty: !isEmpty(get(filters, 'underWarranty')) } });
  }

  async function getAllCount(): Promise<any> {
    return await doGetBatteryEstateData({ underWarranty: false }, undefined);
  }

  async function getCount(statusType: string, search: string, filters: IFilterResult): Promise<number> {
    const data = await doGetBatteryEstateData({ groups: filters ? filters.groups : undefined, underWarranty: !isEmpty(get(filters, 'underWarranty')) }, search);
    return data[statusType];
  }

  function getCategories() {
    const categories: IListCategory[] = [
      {
        id: VIEW.red, title: 'Replace', icon: 'fa fa-times-circle circle_red', buildStatusMessage: getStatusMessage("in need of battery", "replacement"), link: `${props.config.link}/replace`
      },
      {
        id: VIEW.orange, title: 'Warning', icon: 'fa fa-exclamation-triangle circle_orange', buildStatusMessage: getStatusMessage("with battery", "warnings"), link: `${props.config.link}/warning`
      },
      {
        id: VIEW.green, title: 'Good', icon: 'fa fa-check circle_green', buildStatusMessage: getStatusMessage("with", "good", "battery health"), link: `${props.config.link}/good`
      }
    ];
    return categories;
  }

  function getEstateCategories() {
    const estateCategories: IEstateCategory[] = [
      { id: VIEW.red, label: 'Replace', colour: "#DC7F7F", icon: '\uf057', path: props.config.link + "/replace" },
      { id: VIEW.orange, label: 'Warning', colour: "#FBCD76", icon: '\uf071', path: props.config.link + "/warning" },
      { id: VIEW.green, label: 'Good', colour: "#3FA67D", icon: '\uf00c', path: props.config.link + "/good" }];
    return estateCategories;
  }

  function getStatusMessage(startText: string, boldStatus: string, endText?: string): (count: number) => JSX.Element {
    return (count: number): JSX.Element => {
      return <span>{count !== 1 ? "devices" : "device"} {startText} <b>{boldStatus}</b>{endText ? ` ${endText}` : ''}</span>;
    };
  }

  return (
    <AppContext.Provider value={contextValue}>
      <div>
        <div className="row equal batteryEssentials_estate">
          <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <DataLoading loadData={getAllCount}>
              <Estate
                title={'Battery Estate Overview'}
                toolLabelSngl={'Battery'}
                toolLabelPlrl={'Batteries'}
                data={null}
                categories={getEstateCategories()} />
            </DataLoading>
          </div>
          <div className="col mt-sm-2 mt-2 mt-lg-0">
            <DeviceDetails productName={props.config.productName} />
          </div>
        </div>
        <div className="row batteryEssentialsTable_container">
          <div className="col-md-12">
            <ListCard
              categories={getCategories()}
              classes={['dataTable', 'batteryTable']}
              currentCategoryId={view}
              filterNameI18nKey={'devices'}  // NB This should be an i18n key, but this page is legacy, and not otherwise translated
              filterGroups={[
                { id: "groups", filterDisplayName: "Groups Filter", valueDisplayName: "Groups", availableValues: availableFilterGroups },
                { id: "underWarranty", filterDisplayName: "Warranty Filter", valueDisplayName: "Warranty Filter", availableValues: ["Under Warranty"], icon: "fas fa-shield-alt" }
              ]}
              fields={FIELDS}
              getList={getList}
              exportList={exportList}
              getCount={getCount}
              rowComponent={TableRow}
              sortableColumns={['Manufacturer', 'Model', 'Average Discharge']}
              centeredColumns={['Battery', 'Status']}
            />
          </div>
        </div>
      </div>
    </AppContext.Provider>
  );
};
