import * as React from 'react';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { get, isNull } from 'lodash';

import { InitialTableState, useTableReducer } from '../../../../components/data-table/lib';
import { List } from '../../../../components/list/list';
import { IAnomaly, IAnomalyListAndCount, getAnomaliesList, getDeviceCount } from '../../../../services/core/anomalies';
import { useUserSettingsContext } from '../../../../context/userSettings';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { useAnomaliesListColumns } from './useAnomaliesListColumns';
import { useWorldRoutes } from '../../../../routes/parts/allWorldRoutes';
import { AnomaliesFilter } from './anomalies-filter';
import { convertFilterToQuery } from '../../../../components/filters/filterLib';
import styled from 'styled-components';
import { useTranslation } from '@lib/useTypedTranslation';
import { useWorldSettingsContext } from '../../../../context/worldSettings';

const tableName = 'anomaliesList';
const defaultPageSize = 20;

const StyledList = styled.div`
  .filter-status-block {
    margin-left: 2.95em !important
  }
`;

const InfoIcon = styled.i`
  color: #7490b9;
  margin-right: 5px;
`;

const Info = styled.div`
  margin: 1.5rem 2.5rem 0.75rem;
  font-weight: 600;
`;

export function AnomaliesList() {
  const { tablePageSizes } = useUserSettingsContext();
  const routes = useWorldRoutes();
  const history = useHistory();
  const { t } = useTranslation('anomaly');
  const [showFilterSelection, setShowFilterSelection] = useState(false);
  const { smartGroupingHour } = useWorldSettingsContext().worldSettings;
  const anomaliesEnabled = !isNull(smartGroupingHour);

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 6, direction: 'desc', field: 'lastDetected' },
    filter: {}
  };
  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<IAnomaly>(tableName, initialTableState);
  const [
    { offset, limit, sort, data, selectedRow, filter },
    { onFilter }
  ] = [tableReducerProperties, tableReducerFunctions];
  const columns = useAnomaliesListColumns();

  const translationItems = {
    statusText: {
      key: 'RESULT',
      ns: 'anomaly'
    },
    tableTitle: {
      key: 'ANOMALIES',
      ns: 'anomaly'
    }
  };

  const {
    loading: deviceCountLoading,
    data: deviceCount
  } = useWorldRequest(getDeviceCount, { initialLoading: true });

  const listFetcher = useCallback(() => {
    return getAnomaliesList({ limit, offset, sort: { field: sort.field, order: sort.direction }, filter: convertFilterToQuery(filter) });
  }, [offset, limit, sort, filter]);
  const path = routes.core.anomalyHistory;

  function linkToAnomaly(selectedRow: number) {
    const id = get(data[selectedRow], "id");
    history.push(`${path}?anomalyId=${id}`);
  }

  return (
    <div className='anomalies-list'>
      <StyledList>
        <AnomaliesFilter
          showPopup={showFilterSelection}
          handleFiltersUpdated={(newFilter) => { onFilter(newFilter); setShowFilterSelection(false); }}
          handleCancel={() => setShowFilterSelection(false)}
          initialFilters={filter}
        />
        {!anomaliesEnabled && <Info><InfoIcon className="fa fa-info-circle" />{t(`ANOMALY_DETECTION_NOT_SUPPORTED_disabled`)}</Info>}
        {anomaliesEnabled && !deviceCountLoading && deviceCount < 50 && <Info><InfoIcon className="fa fa-info-circle" />{t(`ANOMALY_DETECTION_NOT_SUPPORTED`)}</Info>}
        <List<IAnomaly, IAnomalyListAndCount, 'totalCount', 'list', 'id'>
          dataId='core-anomalies-list'
          loading={deviceCountLoading}
          customHeader={false}
          countDataPath='totalCount'
          listDataPath='list'
          hasCheckboxes={false}
          hasFilters={true}
          hasSearch={false}
          setShowFilterSelection={setShowFilterSelection}
          translationItems={translationItems}
          columns={columns}
          fetcher={listFetcher}
          tableReducerFunctions={tableReducerFunctions}
          tableReducerProperties={tableReducerProperties}
          useRequestHook={useWorldRequest}
          options={{ rowIdDataPath: 'id' }}
          onRowSelected={ linkToAnomaly }
          selectedRow={selectedRow}
          highlight
        />
      </StyledList>
    </div>
  );
}
