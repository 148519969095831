import styled from "styled-components";

const Required = styled.div`
  display: inline;
  &::after {
    content: '*';
    display: inline-block;
    color: var(--color-red);
    margin-left: 0.25rem;
    font-weight: var(--font-weight-normal);
  }
`;
Required.displayName = 'Required';

export const RequiredIcon = () => {
  return <Required data-id="required-icon" />;
};
