import { get } from 'lodash';
import * as React from 'react';
import { useState, useRef } from 'react';
import styled from 'styled-components';
import { ButtonColours } from '../../containers/app/themes';
import { Button } from '../buttons/button';
import { useTranslation } from '@lib/useTypedTranslation';
import { IButtonProps } from './button';
import { fontWeight, componentColour } from '../../containers/app/globalStyle';

const StyledSearchContainer = styled.span`
  margin-right: 0.3125rem;
  position: relative;
`;

StyledSearchContainer.displayName = 'StyledSearchContainer';


const StyledForm = styled.form`
  display: inline;
`;

StyledForm.displayName = 'StyledForm';

interface StyledInputProps {
  placeholder?: string,
  className?: string,
  disabled?: boolean,
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const StyledInput = styled.input<StyledInputProps>`
  border: 1px solid ${componentColour.darkGrey};
  background-color: ${componentColour.white};
  width: 15rem;
  height: 2.125rem;
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 3px 0 0 3px;
  line-height: 30px;
  vertical-align: top;
  color: #333;
  margin-left: 0.875rem;
  padding-left: 0.875rem;
  padding-right: 28px;
  &::placeholder {
    color: #939495;
  }
  &:focus {
    &::placeholder {
      opacity: 0;
    }
    outline-color: #0072af;
  }
`;

StyledInput.displayName = 'StyledInput';

const StyledClearSpan = styled.span`
  color: #7b95bc;
  cursor: pointer;
  right: 3.125rem;
  position: absolute;
  top: 0.625rem;
`;

StyledClearSpan.displayName = 'StyledClearSpan';

const StyledSearchButton = styled(Button)`
  color: ${componentColour.darkText};
  background-color: ${componentColour.lightGrey};
  border: 1px solid ${componentColour.darkGrey};
  font-size: var(--font-size-14px);
  font-weight: ${fontWeight.semiBold};
  text-align: center;
  padding: 0.375rem 0.75rem;
  border-radius: 0 3px 3px 0;
  white-space: nowrap;
  line-height: 1.25rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-left: 0;
  height: 2.125rem;
  &:hover {
    background-color: ${componentColour.grey};
    transition: 0.3s;
  }
`;

StyledSearchButton.displayName = 'StyledSearchButton';

interface Props {
  dataId?: string,
  onClicked?: (i: string) => void,
  inputProps?: StyledInputProps,
  buttonProps?: IButtonProps,
  className?: string,
  appliedSearch?: string,
  onSearch?: (text: string) => void
}

export function Search(props: Props) {
  const { inputProps, buttonProps } = props;
  const [value, setValue] = useState(props.appliedSearch ?? '');
  const searchBoxRef = useRef(null);
  const [isSubmitted, setSubmitted] = useState(false);
  const { t } = useTranslation();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value);
    props.onSearch && props.onSearch(event.target.value);
  }

  function handleClear() {
    /* resets search if a search is already applied (initialValue)
      to the list when the search box is rendered
      or if a search has been applied since rendering (submitted) */
    if (isSubmitted || props.appliedSearch) {
      props.onClicked && props.onClicked('');
      props.onSearch && props.onSearch('');
      setSubmitted(false);
    }
    setValue('');
    searchBoxRef.current.focus();
  }
  return (
    <StyledSearchContainer data-id={`${props.dataId}-search_container`} className={props.className}>
      <StyledForm>
        <StyledInput
          aria-label="enter search string"
          data-id={`${props.dataId}-search_input`}
          placeholder={inputProps?.placeholder ? inputProps.placeholder : t('SEARCH')}
          type="text"
          value={value}
          onChange={handleChange}
          onKeyPress={(event) => onKeypress(event, value)}
          disabled={inputProps?.disabled ? inputProps.disabled : false}
          ref={searchBoxRef} />
        {value && <StyledClearSpan className="fa fa-times" onClick={handleClear} data-id={`${props.dataId}-search_clear`} />}
        {props.onClicked && <StyledSearchButton
          ariaLabel="execute list search"
          dataId={`${props.dataId}-search_button`}
          colour={ButtonColours.grey}
          id={`${props.dataId}-search_button`}
          value={value}
          iconStyle={'fas fa-search'}
          disabled={buttonProps?.disabled ? buttonProps.disabled : false}
          autoFocus={get(buttonProps, 'autoFocus')}
          onClick={() => onSubmit(value)}
          data-toggle={get(buttonProps, ['data-toggle'])}></StyledSearchButton>}
      </StyledForm>
    </StyledSearchContainer>
  );

  function onSubmit(value: string) {
    setSubmitted(true);
    props.onClicked && props.onClicked(value);
  }

  function onKeypress(event: React.KeyboardEvent<HTMLInputElement>, value: string) {
    if (event.keyCode === 13 || event.key === 'Enter' || event.which === 13) {
      event.preventDefault();
      onSubmit(value);
    }
  }
}
