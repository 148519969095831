import { useState } from "react";

export function useToggleList(inputList: string[], requireSelection: boolean) {
  const [list, setList] = useState(inputList);

  const toggleItem = (item: string) => {
    const newList = [...list];
    const index = newList.indexOf(item);
    if (index !== -1) {
      // If item is found, remove it from the array
      newList.splice(index, 1);
    } else {
      // If item is not found, add it to the array
      newList.push(item);
    }
    if (!requireSelection || newList.length > 0) {
      setList(newList);
    }
  };

  return { list, toggleItem };
}
