import React, { useState } from 'react';
import Select, { components } from 'react-select';

import classNames from 'classnames';
import { DEFAULT_VALUE } from '../data-table/dataFormatters';
import { Option } from './optionPickerLib';

import './optionPicker.css';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, child =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

export interface Props<T = any> {
  options: Option<T>[],
  onSelect: (selected: Option<T>) => void,
  current: Option<T> | undefined,
  selectText?: string,
  getSuggestionClasses?: (suggestion: Option<T>) => string | string[],
  disabled?: boolean,
  isSearchable?: true,
  id?: string,
  className?: string,
  inactive?: boolean
}

export const OptionPicker = <T,>(props: Props<T>) => {
  const {
    options,
    current,
    disabled,
    isSearchable,
    className,
    onSelect,
    getSuggestionClasses,
    id = 'optionPicker',
    inactive,
    selectText = DEFAULT_VALUE,
  } = props;
  const [dropDownOpen, setDropdownOpen] = useState(false);

  const renderOption = (option: Option<T>) => {
    const className = classNames('autosuggest__suggestion', getSuggestionClasses && getSuggestionClasses(option), (option.value as any)?.id === null && 'autosuggest__suggestion--italic');
    return <span className={className}>{option?.name}</span>;
  };

  const classes = classNames('option-picker', className);

  return (
    <div className={classes} data-id={id}>
      <Select
        components={{ ValueContainer: CustomValueContainer }}
        id={id}
        isDisabled={disabled}
        value={inactive ? null : current}
        classNamePrefix='option-picker'
        className={inactive ? 'option-picker--inactive' : 'option-picker--active'}
        unstyled

        menuIsOpen={dropDownOpen}
        onMenuClose={() => setDropdownOpen(false)}
        onMenuOpen={() => setDropdownOpen(true)}

        options={options}
        formatOptionLabel={renderOption}
        onChange={onSelect}

        isSearchable={isSearchable || false}
        placeholder={selectText}
        filterOption={(option, inputValue) => option.data.name?.toLowerCase().includes(inputValue.toLowerCase())}
      />
    </div>
  );
};
