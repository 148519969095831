/* eslint no-console: 0 */

import { isDevelopment } from './env';

export class Logger {
  private _condition?: () => boolean;

  constructor(condition?: () => boolean) {
    this._condition = condition;
  }

  get condition() {
    return this._condition;
  }

  private checkCondition() {
    return !this._condition || this._condition();
  }

  error(...args: any) {
    this.checkCondition() && console.error(...args);
  }

  log(...args: any) {
    this.checkCondition() && console.log(...args);
  }

  warn(...args: any) {
    this.checkCondition() && console.warn(...args);
  }
}

export const logger = new Logger();
export const devLogger = new Logger(isDevelopment);

export default logger;
