import React, { useCallback, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Card from '../../../components/card/card';
import { InvalidPage } from '../../../components/errors/invalidPage';
import { LinkTab } from '../../../components/tabs/link-tab';
import { TabPanel } from '../../../components/tabs/tab-panel';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { Theme } from '../../app/themes';
import { ProcessPerformance } from './processPerformance';
import { ProcessDeviceListTab } from './processDeviceListTab';
import { Loading } from '../../../components/loading/loading';
import { useFilterPicker } from '../../../components/timePeriodAndFilterPicker/useFilterPicker';
import { useTimePeriodPicker } from '../../../components/timePeriodAndFilterPicker/useTimePeriodPicker';
import { Option } from '../../../components/controls/optionPickerLib';
import { getUserTimeZone } from '@lib/timeZone';
import { TimePeriodAndFilterPicker } from '@components/timePeriodAndFilterPicker/timePeriodAndFilterPicker';
import { getProcessVersions } from '../../../services/core/processes';
import { TabPanelHeader } from '@components/tabs/tab-panel-header';
import { InfoPopover } from '@components/tooltip/infoPopover';
import { getProcessVersionName, parseProcessVersion } from './lib';

const Tooltip = styled(InfoPopover)`
  max-width: 200px;
`;

const Header = styled.div`
  padding-top: 1.5rem;
  padding-left: 2.5rem;
  padding-bottom: 0.75rem;
  font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.twelvePixels};
`;

const Breadcrumb = styled.div`
  margin-top: 5px;
  color: #707070;
`;

const Title = styled.h1`
  font-weight: ${({ theme }: { theme: Theme}) => theme.font.weights.bold};
  font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.twentyPixels};
`;

const LinkToProcesses = styled(Link)`
  padding-right: 0.5em;
  color: ${({ theme }: { theme: Theme}) => theme.links.color};
`;

const ProcessName = styled.div`
  display: inline-block;
  padding-left: 0.5em;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
`;

const TimePeriodAndFilterSelectionContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d7e2eb;
  justify-content: center;
  align-items: baseline;
`;

const TimePeriodAndFilterSelection = styled(TimePeriodAndFilterPicker)`
  padding-right: 0;
` as typeof TimePeriodAndFilterPicker;


interface Props {
  processName: string,
  productName: string,
  tab: string
}

export function CoreProcess(props: Props) {
  const { processName, productName, tab } = props;
  const routes = useWorldRoutes();
  const queryString = `?processName=${encodeURIComponent(processName)}&productName=${encodeURIComponent(productName)}`;
  const { t } = useTranslation('coreProcesses');


  const allVersionsOption: Option<string> = React.useMemo(() => ({ name: t('ALL_PROCESS_VERSIONS'), value: undefined }), [t]);

  const [version, setVersion] = useState<Option<string>>(allVersionsOption);
  const processVersion = React.useMemo(() => parseProcessVersion(version.value), [version]);

  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();

  const {
    timePeriod,
    timePeriodOptions,
    handleTimePeriodSelect
  } = useTimePeriodPicker({ timePeriods: [30, 60, 90] });

  const versionsFetcher = useCallback(() => {
    const params = {
      processName: processName,
      productName: productName,
      days: timePeriod.value,
      timeZone: getUserTimeZone()
    };
    return getProcessVersions(params);
  }, [processName, productName, timePeriod.value]);

  const { data: versions, loading: versionsLoading } = useWorldRequest(versionsFetcher, { initialLoading: true });

  const versionPickerOptions = React.useMemo(() => {
    const processVersionNames: string[] = versions?.map(getProcessVersionName) ?? [];

    return [allVersionsOption, ...processVersionNames.map(version => ({ name: version, value: version }))];
  }, [versions, allVersionsOption]);

  React.useEffect(() => {
    if (version && versionPickerOptions.find(option => option.value === version.value) === undefined) {
      setVersion(allVersionsOption);
    }
  }, [allVersionsOption, versionPickerOptions, version]);


  const performanceTab = (
    <LinkTab id="performance" key="performance" link={`performance${queryString}`} title={t('PERFORMANCE')}>
      <ProcessPerformance
        key='process-performance'
        processName={processName}
        productName={productName}
        filter={filter}
        timePeriod={timePeriod}
        processVersion={processVersion}
        versionsLoading={versionsLoading}
      />
    </LinkTab>
  );

  const deviceListTab = (
    <LinkTab id="devices" key="devices" link={`devices${queryString}`} title={t('DEVICE_LIST')}>
      <ProcessDeviceListTab
        processName={processName}
        productName={productName}
        filter={filter}
        timePeriod={timePeriod}
        processVersion={processVersion}
      />
    </LinkTab>
  );

  const tabNames = ['performance', 'devices'];
  const tabIndex = tabNames.includes(tab) ? tabNames.indexOf(tab) : 0;
  const tabs = [performanceTab, deviceListTab];

  if (optionsLoading || (versions === null && versionsLoading)) {
    return <LoadingContainer><Loading isLoading={true} transparentOverlay={false} /></LoadingContainer>;
  }

  if (versions === null && !versionsLoading) {
    return <InvalidPage
      message={t('PROCESS_NOT_FOUND', { ns: 'redirect' })}
      redirect={{
        message: t('REDIRECT_TO_PROCESSES', { ns: 'redirect' }),
        path: routes.core.processesList
      }}
    />;
  }

  return (
    <div className={'core-process' /* this class is used to render the tooltip for the processDevicesList */} data-id="core-process">
      <Card noPadding={true}>
        <TimePeriodAndFilterSelectionContainer>
          <TimePeriodAndFilterSelection<string>
            key={`filters-${versionsLoading}`}
            filter={filter}
            {...filterPickerProps}
            timePeriod={timePeriod}
            timePeriodOptions={timePeriodOptions}
            handleTimePeriodSelect={handleTimePeriodSelect}
            zonePickerVisible={filterPickerProps.zonePickerVisible}
            additionalFilters={[{
              id: 'versionPicker',
              options: versionPickerOptions,
              current: version,
              handleFilterSelect: selected => {
                setVersion(selected);
              },
              active: true,
              selectText: t('ALL_PROCESS_VERSIONS', { ns: 'coreProcesses' }),
              title: t('VERSION', { ns: 'translation' }) + ':'
            }]}
          />
          <Tooltip labelText="about the versions filter">{t('VERSION_TOOLTIP', { ns: 'coreProcesses' })}</Tooltip>
        </TimePeriodAndFilterSelectionContainer>
        <TabPanel defaultTabIndex={tabIndex} key="core-processes">
          <TabPanelHeader>
            <Header className="process-header">
              <Title>
                {processName} ({productName})
              </Title>
              <Breadcrumb>
                <LinkToProcesses to={routes.core.processesList}>{t('PROCESSES', { ns: 'translation' })}</LinkToProcesses>
                <i className="fas fa-chevron-right" />
                <ProcessName>{processName} ({productName})</ProcessName>
              </Breadcrumb>
            </Header>
          </TabPanelHeader>
          {tabs}
        </TabPanel>
      </Card>
    </div>
  );
}
