import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { UserRole, userRoles } from '../../../components/authentication/userAuth';

import './roleSelection.css';

interface Props {
  role: UserRole,
  setRole: (role: UserRole) => void
}

export function getRoleKey(role: UserRole) {
  switch (role) {
  case 'administrator':
    return 'ADMIN';
  case 'manager':
    return 'MANAGER';
  case 'editor':
    return 'EDITOR';
  case 'viewer':
    return 'VIEWER';
  /* istanbul ignore next */
  default:
    /* eslint-disable-next-line no-case-declarations */
    const exhaustiveCheck: never = role;
  }
}

export function RoleSelection(props: Props) {
  const { setRole } = props;
  const { t } = useTranslation('userManagement');

  return <div className="role-selection">
    {userRoles.map((role) =>
      <div key={role} className="role-selection__row">
        <input
          id={`${role}-role-selection__input`}
          name="role-selection"
          checked={role === props.role}
          type="radio"
          className="role-selection__input"
          onChange={() => { setRole(role); }} />
        <label htmlFor={`${role}-role-selection__input`}>{t(getRoleKey(role))}</label>
      </div>
    )}
  </div>;
}
