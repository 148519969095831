import * as React from 'react';
import moment from 'moment';
import { toNumber, map, split } from 'lodash';
import { useTranslation } from '@lib/useTypedTranslation';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import { AmChart, defaultTheme } from '../../chart/amChart';

function getDayTitle(value: string) {
  return moment(value, 'YYYY-MM-DD').format('L');
}

function formatDay(value: string): string {
  const day = split(value, '-', 3).pop();
  const localeData = moment.localeData();
  return localeData.ordinal(parseInt(day));
}

interface IProps {
  dataset: number[], //discharge value
  days: string[], //dates in format YYYY-MM-DD
  updated: string //last data utc as string
}

export function DetailsGraph(props: IProps) {
  const ns = 'performance';
  const ns2 = 'batteryEssentialsDeviceView';
  const { t } = useTranslation(['translation', ns, ns2]);
  const lastUpdatedTime = `(${t('UPDATED', { ns: 'translation' })} ${moment(toNumber(props.updated)).calendar()})`;

  return (
    <div className={`devices discharge_container`}>
      <h1>{t('DEVICE_HOURLY_DISCHARGE_STATEMENT', { ns: ns2 })}</h1>
      <div className="discharge_chart">
        <AmChart tag='avg-device-discharge-chart' dataProvider={getChartData} chartProvider={createChart} link={props.dataset} />
      </div>
      <div className="lastDays">{t('LAST_30DAYS_TEXT', { ns })} {props.updated ? lastUpdatedTime : ''}</div>
    </div>
  );

  /* istanbul ignore next */
  function createChart(id: string) {
    am4core.useTheme(defaultTheme);
    am4core.useTheme(am4themes_animated);
    let chart = am4core.create(id, am4charts.XYChart);
    chart.fontSize = '12px';

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "dayNth";
    xAxis.renderer.minGridDistance = 80;
    xAxis.renderer.grid.template.strokeOpacity = 0;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.dataFields.data = "value";
    yAxis.min = 0;
    yAxis.numberFormatter.numberFormat = "#'%'";

    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.categoryX = "dayNth";
    series.dataFields.valueY = "value";
    series.connect = false;
    series.stroke = am4core.color('#1aa0d1');
    let bullet = series.bullets.push(new am4charts.CircleBullet());
    bullet.stroke = new am4core.InterfaceColorSet().getFor("background");
    bullet.strokeWidth = 2;
    bullet.circle.radius = 4;
    bullet.fill = am4core.color('#1aa0d1');
    bullet.tooltipText = `[bold]{day}[/]\n${t('AVERAGE_DISCHARGE', { ns: ns2 })} {valueY}`;

    let emptySeries = chart.series.push(new am4charts.LineSeries());
    emptySeries.dataFields.categoryX = "dayNth";
    emptySeries.dataFields.valueY = "value";
    emptySeries.connect = true;
    emptySeries.stroke = am4core.color('#DCDCDC');
    emptySeries.strokeDasharray = '5,2';
    emptySeries.zIndex = -1;

    return chart;
  }

  function getChartData(): any[] {
    return map(props.days, (item, index) => ({
      dayRaw: item,
      day: getDayTitle(item),
      dayNth: formatDay(item),
      value: props.dataset[index]
    }));
  }
}
