import { useTranslation } from '@lib/useTypedTranslation';

import { Spinner } from '../loading/loadingSpinner';
import { FormActionButtons, ProgressiveActionType } from '../buttons/formActionButtons';

import './formControls.css';
import styled from 'styled-components';
import React from 'react';

export interface FormControlsProps {
  submitResult?: {
    message?: React.ReactNode,
    status?: 'loading' | 'success' | 'error'
  },
  message?: React.ReactNode,
  cancelDisabled?: boolean,
  submitDisabled?: boolean,
  count?: number,
  mode: 'save' | 'delete' | 'review' | 'apply',
  onCancel: () => void,
  onSubmit: () => void
}

const ns = 'translation';
const ns2 = 'formControls';

const StyledControls = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 0.498rem;
  justify-content: space-between;
`;

const Status = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row-reverse;
  justify-content: right;
  align-items: center;
  
  @media (max-width: 992px) { 
    height: 1.993rem;
  }
`;

const MessageWrapper = styled.div`
  font-size: 0.846rem;
  vertical-align: middle;
  margin-left: 1.993rem;
  margin-right: 0.149rem;
  padding: 0.199rem 2.99rem;
  text-align: center;
  border-radius: 5px;

  @media (max-width: 992px) { 
    margin-right: 0.996rem;
  }

  .success {
    color: #298d3a;
    background-color: #298d3a22;
    transition: opacity 3s ease-in-out;
    opacity: 0;
  }

  .error {
    color: #c03b3b;
    background-color: #c03b3b22;
    transition: opacity 3s ease-in-out;
    opacity: 0;
  }
`;

export function FormControls(props: FormControlsProps) {
  const { t } = useTranslation(ns);
  const { cancelDisabled, submitDisabled, submitResult, message, onSubmit, onCancel, mode, count } = props;

  const resultStatus = submitResult?.status;
  const resultMessage = submitResult?.message;
  const loading = resultStatus === 'loading';

  return (
    <StyledControls className="form-controls">
      {message && <span data-id="formControlsMessage" className="form-controls__message">{message}</span>}
      <Status className="form-controls__status">
        {loading && <Spinner dataId="formControlsSpinner" classes="form-controls__saving" text={t('IN_PROGRESS', { ns })} />}
        <MessageWrapper className={"messageWrapper " + resultStatus}>{resultMessage}</MessageWrapper>

      </Status>
      <FormActionButtons
        progressiveButton={{
          type: mode === 'delete' ? ProgressiveActionType.destructive : ProgressiveActionType.safe,
          dataId: 'formControlsSave',
          text: t(mode.toUpperCase() as "SAVE" | "DELETE" | "REVIEW" | "APPLY", { ns: ns2, count }),
          disabled: submitDisabled || loading,
          onClick: onSubmit
        }}
        neutralButton={{
          dataId: 'formControlsCancel',
          text: t('CANCEL', { ns, count }),
          disabled: cancelDisabled || loading,
          onClick: onCancel
        }}
      />
    </StyledControls>
  );
}
