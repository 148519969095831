import React, { useState } from 'react';

import { IHomeLocationDevicesCount, moveHomeLocationsAction } from '../../services/bulkActions/homeLocationChange';
import { FormControls } from '../../components/forms/formControls';
import { ModalPopup } from '../../components/controls/modalPopup';
import { MoveProgressModal } from './moveProgessModal';
import { defaultGetNullHomeLocationName } from '../../components/controls/optionPickerLib';
import { useWorldAction } from '../../lib/useWorldAction';

import './homeLocationChange.css';
import { useTranslation } from '@lib/useTypedTranslation';
import { Trans, TransInterpolation } from '@components/i18n/typedTrans';

interface MoveProps {
  from: IHomeLocationDevicesCount,
  to: IHomeLocationDevicesCount,
  showPopup: boolean,
  handleClose: () => void
}

const ns = 'bulkActions';
export function MoveHomeLocationModal(props: MoveProps) {
  const { t } = useTranslation(ns);
  const [showStatus, setShowStatus] = useState<boolean>(false);
  const [isMoving, setIsMoving] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const moveHomeLocations = useWorldAction(moveHomeLocationsAction);

  function handleClose() {
    setShowStatus(false);
    props.handleClose();
  }

  async function handleConfirm() {
    setShowStatus(true);
    setIsMoving(true);
    setMessage('');
    try {
      await moveHomeLocations({ fromHomeLocation: props.from, toHomeLocation: props.to });
      setIsMoving(false);
      setMessage(t('MOVE_SUCCESS_TEXT', { ns, location: props.to.homeLocation?.name || 'No home location' }));
    } catch (e) {
      setIsMoving(false);
      setMessage(t('MOVE_ERROR_TEXT', { ns }));
    }
  }

  if (showStatus) {
    return (<MoveProgressModal isMoving={isMoving} message={message} handleClose={handleClose} />);
  }

  const footer = (
    <FormControls
      onCancel={props.handleClose}
      onSubmit={handleConfirm}
      mode='apply'
    />
  );

  const fromLocation = props.from.homeLocation?.name || defaultGetNullHomeLocationName(t);
  const toLocation = props.to.homeLocation?.name || defaultGetNullHomeLocationName(t);

  const modalChildren = <Trans ns="bulkActions" i18nKey='MOVE_CONFIRMATION_TEXT' count={props.from.devicesCount}>
    The home location of all <b>{props.from.devicesCount} devices</b>
    in {{ from: fromLocation } as TransInterpolation}
    will be changed to {{ to: toLocation } as TransInterpolation}
  </Trans>;

  return (<ModalPopup
    show={props.showPopup}
    handleClose={props.handleClose}
    header={t('CONFIRMATION_QUESTION', { ns: 'deleteDialogue' })}
    classname="move-home-location__confirmation-modal"
    children={modalChildren}
    footer={footer}
  ></ModalPopup>);
}
