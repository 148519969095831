import { useTranslation } from "@lib/useTypedTranslation";

import { Link } from 'react-router-dom';
import { isNil } from 'lodash';
import styled from "styled-components";

import { IDevicesData } from "../../../../services/core/devices";
import { formatDataUsageToGB, formatDateLastUpdated, formatValue, formatPercentage } from "../../../../components/data-table/dataFormatters";
import { encode } from "../../../../lib/base64";
import { AlertsPanel } from "../../../../components/alertsPanel/alerts-panel";
import { ITableColumn } from "../../../../components/data-table/types";
import { useWorldRoutes } from "../../../../routes/parts/allWorldRoutes";
import { Tooltip } from "../../../../components/tooltip/tooltip";
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { rearrangeList } from '../../../../lib/rearrangeList';
import { useMemo } from "react";

const StyledAlertsPanel = styled(AlertsPanel)`
 & .enhanced-warning-icon {
  background-size: 1.7rem 0.7rem;
 }
`;
StyledAlertsPanel.displayName = 'AlertsPanel';

const getDeviceIdLink = (value: any, rowData: IDevicesData, path: string) => {
  if (isNil(rowData)) {
    return formatValue(null);
  }
  return <Link to={`${path}?id=${encodeURIComponent(encode(rowData.id))}`} className="core-devices-list_link">{formatValue(value)}</Link>;
};

export const showDeviceStatusTooltip = (status: string, rowData: IDevicesData, path: string) => {
  const iconYellow = <i data-id='warning-status-icon' className={`fa fa-exclamation-triangle circle_orange enhanced-warning-icon`} />;
  const iconRed = <i data-id='problem-status-icon' className={`fa fa-times-circle circle_red`} />;
  const iconGreen = <i data-id='good-status-icon' className={`fa fa-check circle_green`} />;
  if (status === 'yellow' || status === 'orange') {
    return <Tooltip popoverPortalSelector={'.core-devices-list'} popperContent={<StyledAlertsPanel data={rowData} withTitle={false} />} placement='left' offset={9}><Link to={`${path}?id=${encodeURIComponent(encode(rowData.id))}`}>{iconYellow}</Link></Tooltip>;
  } else if (status === 'red') {
    return <Tooltip popoverPortalSelector={'.core-devices-list'} popperContent={<AlertsPanel data={rowData} withTitle={false} />} placement='left' offset={9}><Link to={`${path}?id=${encodeURIComponent(encode(rowData.id))}`}>{iconRed}</Link></Tooltip>;
  } else {
    return <Link to={`${path}?id=${encodeURIComponent(encode(rowData.id))}`}>{iconGreen}</Link>;
  }
};

export const useDevicesListColumns = () => {
  const ns = 'translation';
  const { t } = useTranslation(ns);
  const { worldSettings } = useWorldSettingsContext();
  const routes = useWorldRoutes();

  const columns = useMemo(() => {
    const mobileTitleWithTooltip = <>
      {t('MOBILE_DATA')}
      <Tooltip key='mobile-tooltip' popoverPortalSelector={'.core-devices-list'} placement={'bottom'} popperContent={<div className="core-devices_list-data_usage-popover_text">{t('MOBILE_DATA_TOOLTIP_TEXT')}</div>}>
        <i aria-label='about mobile data' className="core-devices_list-data_usage-icon fa fa-question-circle" />
      </Tooltip>
    </>;
    const wifiTitleWithTooltip = <>
      {t('WIFI_DATA')}
      <Tooltip key='wifi-tooltip' popoverPortalSelector={'.core-devices-list'} placement={'bottom'} popperContent={<div className="core-devices_list-data_usage-popover_text">{t('WIFI_DATA_TOOLTIP_TEXT')}</div>}>
        <i aria-label='about wifi data' className="core-devices_list-data_usage-icon fa fa-question-circle" />
      </Tooltip>
    </>;

    function getDeviceStatusTooltipProcessor(value: any, rowData: IDevicesData) {
      return showDeviceStatusTooltip(value, rowData, routes.core.deviceOverview);
    }
    function getDeviceIdLinkProcessor(value: any, rowData: IDevicesData) {
      return getDeviceIdLink(value, rowData, routes.core.deviceOverview);
    }
    function getHomeLocationName(value: string, rowData: IDevicesData) {
      return rowData.homeLocation ? formatValue(rowData.homeLocation.name) : "-";
    }

    const columns: ITableColumn[] = [
      { id: 'serialNumber', title: t('SERIAL_NUMBER'), dataPath: 'serialNumber', sortable: true, width: '12rem' },
      { id: 'assetTag', title: t('ASSET_TAG'), dataPath: 'assetTag', sortable: true, processor: formatValue, width: '10rem', textAlign: 'left' },
      { id: 'imei', title: t('IMEI'), dataPath: 'imei', sortable: true, processor: formatValue, width: '10rem' },
      { id: 'deviceName', title: t('DEVICE_NAME'), dataPath: 'deviceName', sortable: true, processor: formatValue, textAlign: 'left', width: '10rem' },
      { id: 'manufacturer', title: t('MANUFACTURER'), dataPath: 'manufacturer', sortable: true, processor: formatValue, width: '14rem' },
      { id: 'model', title: t('MODEL'), dataPath: 'model', sortable: true, processor: formatValue, width: '9rem' },
      { id: 'group', title: t('GROUPS'), dataPath: 'group', processor: formatValue, width: '12rem' },
      { id: 'homeLocation', title: t('HOME_LOCATION'), dataPath: 'homeLocation.id', sortable: true, processor: getHomeLocationName, width: '10rem' },
      { id: 'updated', title: t('LAST_SEEN'), dataPath: 'updated', processor: formatDateLastUpdated, sortable: true, width: '9rem' },
      { id: 'mobileDataUsage', title: mobileTitleWithTooltip, dataPath: 'mobileDataUsage', sortable: true, processor: formatDataUsageToGB, textAlign: 'right', width: '9rem' },
      { id: 'wifiDataUsage', title: wifiTitleWithTooltip, dataPath: 'wifiDataUsage', sortable: true, processor: formatDataUsageToGB, textAlign: 'right', width: '9rem' },
      { id: 'percentageUsed', title: t('STORAGE_USED'), dataPath: 'storage.percentageUsed', sortable: true, processor: formatPercentage, textAlign: 'right', width: '12rem' },
      { id: 'averageDischarge', title: t('AVERAGE_DISCHARGE'), dataPath: 'averageDischarge', sortable: true, processor: formatPercentage, textAlign: 'right', width: '12rem' },
      { id: 'firmware', title: t('FIRMWARE_VERSION'), dataPath: 'firmware.version', sortable: true, processor: formatValue, textAlign: 'left', width: '16rem' },
      { id: 'operatingSystem', title: t('OS_VERSION'), dataPath: 'operatingSystem.buildVersion', sortable: true, processor: formatValue, textAlign: 'left', width: '10rem' },
      { id: 'biosVersion', title: t('BIOS_VERSION'), dataPath: 'bios.version', sortable: true, processor: formatValue, textAlign: 'left', width: '10rem' },
      { id: 'clientVersion', title: t('CLIENT_VERSION'), dataPath: 'clientVersion', sortable: true, processor: formatValue, textAlign: 'left', width: '10rem' },
      { id: 'extensionVersion', title: t('EXTENSION_VERSION_DEVICE'), dataPath: 'extensionVersion', sortable: true, processor: formatValue, textAlign: 'left', width: '10rem' },
      { id: 'deviceStatus', title: t('STATUS'), dataPath: 'deviceStatus', processor: getDeviceStatusTooltipProcessor, textAlign: 'center', width: '6rem' }
    ];
    const deviceIdentifier = worldSettings.deviceIdentifier;

    const updatedColumn: ITableColumn[] = rearrangeList(columns, deviceIdentifier);
    updatedColumn[0].processor = getDeviceIdLinkProcessor;

    return updatedColumn;
  }, [worldSettings, routes.core.deviceOverview, t]);
  return columns;
};
