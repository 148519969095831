import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { WorldSettings } from '../../../../services/config/config';

import { AverageDischargeBatterySettings } from './averageDischargeBatterySettings';

export interface IProps {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  timeStamp: number,
  onDataChanged: (data: WorldSettings) => void
}

export function AverageDischargeSettings(props: IProps) {
  const { t } = useTranslation('settings');
  const [alertTimeStamp, setAlertTimeStamp] = useState<number>(props.timeStamp);

  if (props.timeStamp > alertTimeStamp) {
    setAlertTimeStamp(props.timeStamp);
  }

  const onDataChanged = (data: number) => {
    props.onDataChanged({ ...props.worldSettings, averageDischargeYellow: data });
  };

  function onAlertToggled(data: boolean, initialSettings: { warning: number }) {
    setAlertTimeStamp(moment.utc().valueOf());
    props.onDataChanged({
      ...props.worldSettings,
      averageDischargeEnabled: data,
      averageDischargeYellow: initialSettings.warning
    });
  }

  return (
    <AverageDischargeBatterySettings
      key={alertTimeStamp}
      title={t('AVG_DISCHARGE_TITLE')}
      message={t('AVG_DISCHARGE_MESSAGE')}
      popoverText={t('AVG_DISCHARGE_POPOVER')}
      errorText={t('AVG_DISCHARGE_ERROR')}
      className="averageDischarge"
      warning={props.worldSettings.averageDischargeYellow}
      dataUpdated={onDataChanged}
      disabled={props.submitInProgress}
      alertEnabled={props.worldSettings.averageDischargeEnabled}
      alertToggled={onAlertToggled}
    />
  );
}
