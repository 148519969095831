import { useTranslation } from "@lib/useTypedTranslation";


import { formatDateLastUpdated, formatTimeLength, formatValue } from "../../../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../../../components/data-table/types";

export const useDeviceProcessesListColumns = () => {
  const { t } = useTranslation();

  const columns: ITableColumn[] = [
    { id: 'processName', title: t('PROCESS_NAME', { ns: 'deviceProcesses' }), dataPath: 'processName', sortable: true, processor: formatValue },
    { id: 'productName', title: t('PRODUCT_NAME', { ns: 'deviceProcesses' }), dataPath: 'productName', sortable: true, processor: formatValue },
    { id: 'productVersion', title: t('PRODUCT_VERSION', { ns: 'deviceProcesses' }), dataPath: 'productVersion', processor: formatValue },
    { id: 'fileVersion', title: t('FILE_VERSION', { ns: 'deviceProcesses' }), dataPath: 'fileVersion', processor: formatValue },
    { id: 'lastUsed', title: t('LAST_USED'), dataPath: 'lastUsed', sortable: true, processor: formatDateLastUpdated },
    { id: 'avgDailyUsage', title: t('AVERAGE_DAILY_USAGE'), dataPath: 'averageUserActivity', sortable: true, processor: formatTimeLength },
    { id: 'avgDailyCpuUsage', title: t('AVERAGE_DAILY_CPU_USAGE'), dataPath: 'averageCpuActivity', sortable: true, processor: formatTimeLength }
  ];
  return columns;
};
