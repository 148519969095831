import { useTranslation } from "@lib/useTypedTranslation";


import { formatValue } from "../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../components/data-table/types";

export const useZonesListColumns = () => {
  const { t } = useTranslation(['zones', 'translation']);

  const columns: ITableColumn[] = [
    { id: 'name', title: t('NAME', { ns: 'translation' }), dataPath: 'name', sortable: true, processor: formatValue, width: '9rem' },
    { id: 'groups1', title: t('GROUPS', { ns: 'translation' }), dataPath: 'groups1', sortable: false, processor: formatValue, width: '9rem' },
    { id: 'groups2', title: t('AND_GROUPS', { ns: 'zones' }), dataPath: 'groups2', sortable: false, processor: formatValue, width: '9rem' },
    { id: 'homeLocations', title: t('HOME_LOCATION_other', { ns: 'translation' }), dataPath: 'homeLocations', sortable: false, processor: formatValue, width: '9rem' }
  ];

  return columns;
};
