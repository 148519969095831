const alertFieldRoots = [
  'averageDischarge',
  'deviceLocationAccessPoint',
  'deviceLocationDistance',
  'deviceLocationTime',
  'deviceUserExperienceDrop',
  'deviceUserExperienceLowPower',
  'deviceUserExperienceReboot',
  'deviceUtilisationIdle',
  'deviceUtilisationOutOfContact',
  'deviceTimeError'
] as const;
type AlertFieldRoot = typeof alertFieldRoots[number];


export const alertStatuses = [
  'averageDischargeStatus',
  'deviceLocationAccessPointStatus',
  'deviceLocationDistanceStatus',
  'deviceLocationTimeStatus',
  'deviceTimeErrorStatus',
  'deviceUserExperienceDropStatus',
  'deviceUserExperienceLowPowerStatus',
  'deviceUserExperienceRebootStatus',
  'deviceUtilisationIdleStatus',
  'deviceUtilisationOutOfContactStatus'
] as const;
export type AlertStatus = typeof alertStatuses[number];

export const alarmStatuses = ['deviceStatus', ...alertStatuses] as const;
export type AlarmStatus = typeof alarmStatuses[number];
export type AlarmName = `${typeof alarmStatuses[number]}Alarm`;
export type AlarmThresholds = 'Red' | 'Yellow';
export type AlarmEnabled = 'Enabled';
export type AlarmStatusEnabledFieldNames = `${AlarmName}${AlarmEnabled}`;
export type AlarmStatusThresholdFieldNames = `${AlarmName}${AlarmThresholds}`;

export const getAlarmNameFromAlarmStatus = (alert: AlarmStatus): AlarmName => `${alert}Alarm` as AlarmName;

export type AlarmStatusEnabledSettings = {
  [k in AlarmStatusEnabledFieldNames]?: boolean
};

export type AlarmStatusThresholdSettings = {
  [k in AlarmStatusThresholdFieldNames]?: number
};

export type AlertEnabled = 'Enabled';
export type AlertStatusEnabledFieldName = `deviceUserExperienceDropStatus${AlertEnabled}` | `${Exclude<AlertFieldRoot, 'deviceTimeError' | 'deviceUserExperienceDrop'>}${AlertEnabled}`;

export type AlertStatusEnabledSettings = {
  [k in AlertStatusEnabledFieldName]?: boolean
};
