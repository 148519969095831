import React, { useContext, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { get, groupBy, isEqual, pick } from 'lodash';

import { AmChart, coreTheme } from '../../../../../../components/chart/amChart';
import { IDeviceDataUsage } from '../../../../../../services/core/devicePerformance';
import { NoSelectionOverlay } from '../../../../../../components/card/noSelectionOverlay';
import { formatDateAsOrdinal } from '../../../../../../lib/ordinal';
import { addHoverAnimation } from '../../../../../../components/chart/onHoverAnimation';
import { IDeviceEventFilterState, deviceEventFilterGroupNames } from '../events/deviceEventsTypes';
import { DeviceEvent } from '../../../../../../services/core/eventsTypes';
import { EditEvents, EditEventsAction, useEditEventsDispatch } from '../events/edit-events';
import { getDeviceEventFilterGroups } from '../events/deviceEvents';
import { addEventOverlaysToColumnChart } from './lib/eventOverlays';
import { EventsShown } from '../events-shown';
import { DeviceInfoContext } from '../../../index';
import { chartNames, ChartName } from './lib/chartNames';
import { highlightWeekends } from './lib/weekendsHighlight';
import { useFeatureTogglesContext } from '../../../../../../context/featureToggles';
import { drawYAxis } from '../../../../../../lib/dataUsageChart';

import './charts.css';
import '../events/deviceEvents.css';

interface IProps {
  data: IDeviceDataUsage[],
  events: DeviceEvent[],
  eventFilters: IDeviceEventFilterState,
  dispatchEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  setDate: (date: string) => void,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function formatTooltipDate(date: string) {
  return moment(parseInt(date)).format('ll');
}

export function DataUsage30Days(props: IProps) {
  const { eventFilters, dispatchEventFilters, onMount } = props;
  const featureToggles = useFeatureTogglesContext();
  const { platformType } = useContext(DeviceInfoContext);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [chartReady, setChartReady] = useState(false);
  const ns = 'translation';
  const { t } = useTranslation(['performance', ns]);

  const {
    handleUpdate, handleApply, handleApplyAll, handleCancel
  } = useEditEventsDispatch(
    dispatchEventFilters, chartNames.dataUsage30Days, setLoadingTrue
  );

  const title = t('DEVICE_DATA_USAGE_PAST_30DAYS', { ns: 'performance' });
  const data = getData();

  function setLoadingTrue() {
    setChartReady(false);
    setChartDataLoading(true);
  }

  function onReady() {
    if (!chartDataLoading) {
      setChartReady(true);
    }
  }

  return (
    <div className={`core-device_performance-30-day-data-usage ${chartReady ? 'chart-ready' : ''}`}>
      <div className="chart-header">
        <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns })} show={props.data.length === 0} />
        <div className="chart-title">{title}</div>
        <div className="chart-edit-events">
          <EventsShown eventFilters={eventFilters.appliedEventFilters} />
          <EditEvents
            subheader={`‘${title}’`}
            eventGroupNames={deviceEventFilterGroupNames}
            eventFilterGroups={getDeviceEventFilterGroups(platformType, featureToggles)}
            eventFilterState={eventFilters.currentEventFilters}
            handleUpdate={handleUpdate}
            handleApply={handleApply}
            handleCancel={handleCancel}
            handleApplyAll={handleApplyAll}
          />
        </div>
      </div>
      <div className="chart-and-legend-container">
        <div className="chart-container">
          <AmChart
            tag="performance-30-day-data-usage-chart"
            chartProvider={createChart}
            dataProvider={() => { setChartDataLoading(false); return data; }}
            onUpdate={onDataUpdated}
            link={pick(props, 'data', 'events')}
            onDataValidated={onReady}
            onMount={onMount}
            cypressId="last30DaysDataUsage"
          />
        </div>
        <div id="data-usage-30-day-chart-legend" />
      </div>
    </div>
  );

  /* istanbul ignore next */
  function createChart(this: any, id: string) {
    am4core.useTheme(coreTheme);

    const chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);
    chart.data = props.data;
    chart.paddingTop = 40;
    chart.minHeight = 240;

    function createSeries(field: string, name: string, colour: string) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'date';
      series.sequencedInterpolation = true;

      series.stacked = true;

      series.columns.template.fill = am4core.color(colour);
      series.columns.template.stroke = am4core.color(colour);
      series.columns.template.width = am4core.percent(90);
      series.columns.template.tooltipHTML = '';

      series.columns.template.adapter.add("tooltipHTML", (_, target) => {
        const data: any = target.tooltipDataItem.dataContext;
        const date = formatTooltipDate(data.date);
        return `<div class="tooltip-container_data-usage" data-date="${data.date}" data-seriesName="${field}"><span class="data-usage-30-day-tooltip-title">${date}</span>
        </br><span class="data-usage-30-day-tooltip-text"><div class="chart-key chart-key--data-mobile"></div>&nbsp;${t('MOBILE_DATA')}: {mobile.formatNumber('#.00b')}</span>
        </br><span class="data-usage-30-day-tooltip-text"><div class="chart-key chart-key--data-wifi"></div>&nbsp;${t('WIFI_DATA')}: {wifi.formatNumber('#.00b')}</span>
        </br><span class="data-usage-30-day-tooltip-text total">&nbsp;${t('TOTAL_DATA')}: {valueY.total.formatNumber('#.00b')}</span></div>`;
      });

      series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      series.columns.template.events.on("hit", (event: any) => {
        const date = event.target.dataItem.dataContext.date;
        props.setDate(date.toString());
      });

      series.dummyData = {
        colour: am4core.color(colour)
      };

      addHoverAnimation(series, chart);
      return series;
    }

    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'date';
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.minGridDistance = 50;
    //format axis here instead of in getData() to prevent duplicate categories e.g(5th)
    dateAxis.renderer.labels.template.adapter.add("textOutput", function (text) {
      if (text) {
        const dateAsNumber = parseInt(text);
        return moment(dateAsNumber).startOf('day').isSame(moment().startOf('day')) ? 'Today' : formatDateAsOrdinal(dateAsNumber);
      }
    });

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.calculateTotals = true;
    valueAxis.contentAlign = 'right';
    drawYAxis(props.data, valueAxis);

    const series1 = createSeries('mobile', t('MOBILE_DATA_LINES', { ns: 'performance' }), '#49a1a9');
    createSeries('wifi', t('WIFI_DATA_LINES', { ns: 'performance' }), '#2c678c');
    addEventOverlaysToColumnChart(series1, chart, dateAxis, t);
    //create axis ranges for weekends
    highlightWeekends(dateAxis);
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontWeight = '400';

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;

    markerTemplate.disposeChildren();
    const legendItem = markerTemplate.createChild(am4core.Rectangle);
    legendItem.fill = am4core.color('#49a1a9');
    legendItem.width = 13;
    legendItem.height = 13;

    legendItem.adapter.add("fill", function (_, target) {
      if (target.dataItem && target.dataItem.dataContext && (target.dataItem.dataContext as any).dummyData) {
        return (target.dataItem.dataContext as any).dummyData.colour;
      }
    });

    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

    return chart;
  }

  /* istanbul ignore next */
  function onDataUpdated(chart: am4charts.XYChart, chartData: any[], prevProps?: any, currentProps?: any) {
    if (!isEqual(get(currentProps, 'link.events'), get(prevProps, 'link.events'))) {
      addEventOverlaysToColumnChart(chart.series.values[0] as am4charts.ColumnSeries, chart, chart.xAxes.values[0] as am4charts.CategoryAxis, t);
      highlightWeekends(chart.xAxes.values[0] as am4charts.CategoryAxis);
    }
    return chart;
  }

  function getData() {
    if (!props.data.length) {
      return [];
    }
    const eventsByDay = groupBy(props.events, (event) => moment.utc(event.local).startOf('day').valueOf());
    return props.data.map(({ date, wifi, mobile }) => {
      return {
        date,
        wifi,
        mobile,
        events: get(eventsByDay, date, [])
      };
    });
  }
}
