import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment, { Moment } from 'moment';
import { get, isEqual } from 'lodash';

import {
  getLast24HourDeviceUsage,
  getLast24HourDeviceDataUsage,
  getLast24HourDeviceChargeLevel,
  getLast24HourPerformanceOverview,
  getLast24HourDeviceEventsList
} from '../../../../../services/core/devicePerformance';
import { DeviceUsage24Hours } from './charts/24HourDeviceUsage';
import { Loading } from '../../../../../components/loading/loading';
import { DataUsage24Hours } from './charts/24HourDataUsage';
import { DeviceChargeLevel } from './charts/deviceChargeLevel';
import { DevicePerformanceOverview } from './devicePerformanceOverview';
import { usePrevious } from '../../../../../lib/usePrevious';
import { IDeviceEventFilterState } from './events/deviceEventsTypes';
import { EditEventsAction, getActiveEventFilters } from './events/edit-events';
import { getPerformanceOverviewEvents, filterDeviceEvents, getDeviceEvents } from './events/deviceEvents';
import { PerformanceEvents, PerformanceView } from './performance-events';
import { DeviceInfoContext } from '../../index';
import { ChartName } from './charts/lib/chartNames';
import { TableName } from '../../../../../components/data-table/lib';
import { RequestInitWithRetry } from '../../../../../lib/request';
import { useAlignCharts } from '../../../../../lib/useAlignCharts';
import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { DeviceEvent } from '../../../../../services/core/eventsTypes';
import { useFeatureTogglesContext } from '../../../../../context/featureToggles';

import './reports.css';

interface IProps {
  eventFiltersDeviceUsage: IDeviceEventFilterState,
  eventFiltersDataUsage: IDeviceEventFilterState,
  eventFiltersDeviceChargeLevel: IDeviceEventFilterState,
  eventFiltersEventsList: IDeviceEventFilterState,
  dispatchChartEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  dispatchTableEventFilters: React.Dispatch<EditEventsAction<TableName>>,
  visible: boolean
}

interface IRequestParams {
  deviceId: string,
  to: number
}

function getRequestParams(deviceId: string, now: Moment): IRequestParams {
  const to = moment.utc(now).endOf('hour').valueOf();
  return { deviceId, to };
}

export function Last24HoursReport(props: IProps) {
  const {
    visible,
    eventFiltersDeviceUsage,
    eventFiltersDataUsage,
    eventFiltersDeviceChargeLevel,
    eventFiltersEventsList,
    dispatchChartEventFilters,
    dispatchTableEventFilters
  } = props;
  const featureToggles = useFeatureTogglesContext();
  const { platformType, id: deviceId } = useContext(DeviceInfoContext);

  const { t } = useTranslation('performance');

  const [allEvents, setAllEvents] = useState([]);
  const [eventsFetched, setEventsFetched] = useState(false);
  const [deviceUsageEvents, setDeviceUsageEvents] = useState([]);
  const prevEventFiltersDeviceUsage = usePrevious(eventFiltersDeviceUsage);
  const [dataUsageEvents, setDataUsageEvents] = useState([]);
  const prevEventFiltersDataUsage = usePrevious(eventFiltersDataUsage);
  const [deviceChargeLevelEvents, setDeviceChargeLevelEvents] = useState([]);
  const prevEventFiltersDeviceChargeLevel = usePrevious(eventFiltersDeviceChargeLevel);

  const { addChart, clearCharts } = useAlignCharts(3);

  const dropsAreEnabled = featureToggles.getDropsEnabledOnDevice(platformType);

  const performanceOverviewEvents = useMemo(() => {
    return getPerformanceOverviewEvents(dropsAreEnabled, true);
  }, [dropsAreEnabled]);

  const deviceEventNames = useMemo(() => {
    return Object.values(getDeviceEvents(platformType, featureToggles)).map(event => event.name);
  }, [platformType, featureToggles]);

  const dataFetcher = useCallback(() => {
    if (deviceId) {
      clearCharts();
      const params = getRequestParams(deviceId, moment.utc());
      return (options: RequestInitWithRetry) => {
        return Promise.all([
          getLast24HourDeviceDataUsage(params)(options),
          getLast24HourDeviceUsage(params)(options),
          getLast24HourDeviceChargeLevel(params)(options)
        ]);
      };
    }
  }, [clearCharts, deviceId]);

  const {
    loading: dataLoading,
    data: [deviceDataUsage, deviceUsageData, batteryLevels]
  } = useWorldRequest(dataFetcher, { initialLoading: true, initialData: [[], [], []] });

  const onEventsFetched = useCallback((events: DeviceEvent[]) => {
    setAllEvents(events);
    setEventsFetched(true);
  }, []);

  const eventsDataFetcher = useCallback(() => {
    if (deviceId) {
      const params = getRequestParams(deviceId, moment.utc());
      return getLast24HourDeviceEventsList({ ...params, events: deviceEventNames });
    }
  }, [deviceId, deviceEventNames]);

  const {
    loading: eventsLoading
  } = useWorldRequest(eventsDataFetcher, { initialLoading: false, initialData: [], onSuccess: onEventsFetched });

  const overviewDataFetcher = useCallback(() => {
    if (deviceId) {
      const params = {
        ...getRequestParams(deviceId, moment.utc()),
        events: performanceOverviewEvents
      };
      return getLast24HourPerformanceOverview(params);
    }
  }, [deviceId, performanceOverviewEvents]);

  const {
    data: overviewData
  } = useWorldRequest(overviewDataFetcher, { initialData: undefined });

  const activeEventsDeviceUsage = useMemo(() => getActiveEventFilters(eventFiltersDeviceUsage.appliedEventFilters), [eventFiltersDeviceUsage.appliedEventFilters]);
  const activeEventsDataUsage = useMemo(() => getActiveEventFilters(eventFiltersDataUsage.appliedEventFilters), [eventFiltersDataUsage.appliedEventFilters]);
  const activeEventsDeviceChargeLevel = useMemo(() => getActiveEventFilters(eventFiltersDeviceChargeLevel.appliedEventFilters), [eventFiltersDeviceChargeLevel.appliedEventFilters]);

  useEffect(() => {
    const [deviceUsageEvents, dataUsageEvents, chargeLevelEvents] = filterDeviceEvents(
      allEvents, activeEventsDeviceUsage, activeEventsDataUsage, activeEventsDeviceChargeLevel
    );
    setDeviceUsageEvents(deviceUsageEvents);
    setDataUsageEvents(dataUsageEvents);
    setDeviceChargeLevelEvents(chargeLevelEvents);
  }, [activeEventsDataUsage, activeEventsDeviceChargeLevel, activeEventsDeviceUsage, allEvents]);

  useEffect(() => {
    const deviceUsageFiltersChanged = !isEqual(eventFiltersDeviceUsage.appliedEventFilters, get(prevEventFiltersDeviceUsage, 'appliedEventFilters'));
    const dataUsageFiltersChanged = !isEqual(eventFiltersDataUsage.appliedEventFilters, get(prevEventFiltersDataUsage, 'appliedEventFilters'));
    const deviceChargeLevelFiltersChanged = !isEqual(eventFiltersDeviceChargeLevel.appliedEventFilters, get(prevEventFiltersDeviceChargeLevel, 'appliedEventFilters'));
    const anyFiltersChanged = deviceUsageFiltersChanged || dataUsageFiltersChanged || deviceChargeLevelFiltersChanged;

    if (eventsFetched && anyFiltersChanged) {
      const [deviceUsageEvents, dataUsageEvents, chargeLevelEvents] = filterDeviceEvents(
        allEvents, activeEventsDeviceUsage, activeEventsDataUsage, activeEventsDeviceChargeLevel
      );
      deviceUsageFiltersChanged && setDeviceUsageEvents(deviceUsageEvents);
      dataUsageFiltersChanged && setDataUsageEvents(dataUsageEvents);
      deviceChargeLevelFiltersChanged && setDeviceChargeLevelEvents(chargeLevelEvents);
    }
  }, [
    allEvents, eventFiltersDataUsage.appliedEventFilters, eventFiltersDeviceChargeLevel.appliedEventFilters,
    eventFiltersDeviceUsage.appliedEventFilters, eventsFetched, prevEventFiltersDataUsage, prevEventFiltersDeviceChargeLevel,
    prevEventFiltersDeviceUsage, activeEventsDeviceUsage, activeEventsDataUsage, activeEventsDeviceChargeLevel,
  ]);

  const classname = '24-hour';

  return (
    <div className="core-device_performance-24-hour-report">
      <DevicePerformanceOverview
        header={t('LAST_24HOURS_TEXT')}
        data={overviewData}
        dropsAreEnabled={dropsAreEnabled}
      />
      <div className="last24HourCharts_container">
        <Loading isLoading={dataLoading || eventsLoading} transparentOverlay={false}>
          <DeviceUsage24Hours
            classname={classname}
            data={deviceUsageData}
            events={deviceUsageEvents}
            eventFilters={eventFiltersDeviceUsage}
            dispatchEventFilters={dispatchChartEventFilters}
            visible={visible}
            onMount={addChart}
          />
          <DataUsage24Hours
            classname={classname}
            data={deviceDataUsage}
            events={dataUsageEvents}
            eventFilters={eventFiltersDataUsage}
            dispatchEventFilters={dispatchChartEventFilters}
            visible={visible}
            onMount={addChart}
          />
          <DeviceChargeLevel
            classname={classname}
            data={batteryLevels}
            events={deviceChargeLevelEvents}
            eventFilters={eventFiltersDeviceChargeLevel}
            dispatchEventFilters={dispatchChartEventFilters}
            visible={visible}
            onMount={addChart}
          />
        </Loading>
      </div>
      <PerformanceEvents eventFilters={eventFiltersEventsList} dispatchEventFilters={dispatchTableEventFilters} currentView={PerformanceView.last24Hours} />
    </div>
  );
}
