import React from 'react';
import { reduce, map, isString, filter as filterBy } from 'lodash';

export interface IFilterResult {
  [k: string]: IFilterOption[]
}

export interface IFlatFilter {
  [k: string]: string[]
}

export type FilterOption = {
  id: string,
  value?: string,
  nullFilter?: boolean, // special case filter to pass null as its value, rather than its id
  additionalCols?: { [colId: string]: React.ReactNode }, // values to populate rows of additional cols
  statusValue?: string // optional override for display in filter status
};

// used to group filters within their column
export type FilterGroup = {
  id: string,
  name: string, // translated display name for group
  filterIds: string[] // ids of filters to include in the group
};

export type IFilterOption = FilterOption | string;

export interface FilterSettings {
  [filterId: string]: string
}
export interface GroupFilterSettings {
  [groupId: string]: FilterSettings
}

export type FilterSort = (filter: IFilterOption) => number | string;

export type GroupSort = (group: FilterGroup) => number | string;

export interface GroupFilterSort {
  [groupId: string]: FilterSort
}

export function getFilterOptionDisplay(option: IFilterOption) {
  if (isString(option)) {
    return option;
  }
  if (option.value) {
    return option.value;
  }
  return option.id;
}

export function getFilterOptionId(option: IFilterOption) {
  if (isString(option)) {
    return option;
  }
  return option.id;
}

export function convertFilterToDisplay(filter: IFilterResult) {
  return reduce(filter, (acc, values, key) => {
    acc[key] = map(values, (filterValue) => getFilterOptionDisplay(filterValue));
    return acc;
  }, {} as IFlatFilter);
}

export function convertFilterToIds(filter: IFilterResult) {
  return reduce(filter, (acc, values, key) => {
    acc[key] = map(values, (filterValue) => getFilterOptionId(filterValue));
    return acc;
  }, {} as IFlatFilter);
}

export function convertFilterToQuery(filter: IFilterResult) {
  return reduce(filter, (acc, values, key) => {
    acc[key] = map(values, (filterValue) => {
      if (isString(filterValue)) {
        return filterValue;
      }
      if (filterValue.nullFilter) {
        return null;
      }
      return filterValue.id;
    });
    return acc;
  }, {} as IFlatFilter);
}

export const defaultFilterSort = (filter: IFilterOption) => {
  if (isString(filter)) {
    return filter;
  }
  if (filter.nullFilter) {
    return String.fromCharCode(65535);  // max utf-16 code unit
  }
  return filter.value;
};

export const defaultFilterGroupSort = (group: FilterGroup) => {
  return group.name;
};

export function removeFilterItem(filter: IFilterResult, item: { group: string, id: string }): IFilterResult {
  if (!filter[item.group]) { return filter; }

  const updatedGroup = filterBy(filter[item.group], (filterOption) => {
    return getFilterOptionId(filterOption) !== item.id;
  });

  return { ...filter, ...{ [item.group]: updatedGroup } };
}

export function updateFilterSelection(availableFilter: IFilterResult, selectedIds: { [group: string]: string[] }): IFilterResult {
  return reduce(selectedIds, (acc, ids, group) => {
    acc[group] = filterBy(availableFilter[group], (filterOption) => {
      return ids.includes(getFilterOptionId(filterOption));
    });
    return acc;
  }, {} as IFilterResult);
}
