import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';

export type AccessDeniedTranslationSuffix = 'PAGE' | 'PRODUCT' | 'WORLD';
interface Props {
  translationKeySuffix: AccessDeniedTranslationSuffix,
  redirectLink: string,
  redirectLinkMessage: string
}

export function AccessDenied(props: Props) {
  const { t } = useTranslation('accessDenied');
  return (
    <div id="access-denied-message" style={{ textAlign: 'center', 'paddingTop': '22em' }}>
      <p><b>{t(`ACCESS_DENIED_TO_${props.translationKeySuffix}`)}</b></p>
      <p>{t('CONTACT_ADMIN_MESSAGE')}</p>
      <Link to={props.redirectLink}>{props.redirectLinkMessage}</Link>
    </div>
  );
}
