import * as React from "react";
import { OverlayTrigger, Popover as PopoverBootsrap } from 'react-bootstrap';
import { Placement } from "react-bootstrap/esm/types";

import './popover.css';

export interface IProps {
  id: string,
  target: JSX.Element,
  content: JSX.Element,
  placement?: Placement,
  className: string,
  isInsideTable: boolean
}

export class Popover extends React.Component<IProps, {}> {
  public render() {
    const popover = (
      <PopoverBootsrap id={this.props.id} className={`${this.props.className} show`}>
        {this.props.content}
      </PopoverBootsrap>
    );

    return (
      <OverlayTrigger
        placement={this.props.placement || "top"}
        delay={250}
        overlay={popover}
      >
        {this.props.isInsideTable ? <td className="textCenter">{this.props.target}</td> : <div className="textCenter">{this.props.target}</div>}
      </OverlayTrigger>);
  }
}
