import { get } from "lodash";
import { EitherOrUnexpected, EitherResponse, expectedFailureFrom, expectedSuccess, isSuccessResponse, unexpectedFailureFrom } from "../../lib/either";
import { request, RequestInitWithRetry } from "../../lib/request";
import { ISortResult } from "../shared/common";

export type ZonesListParams = {
  limit: number,
  offset: number,
  sort?: ISortResult,
  search?: string
};

export interface Zone {
  id: string,
  name: string,
  groups1: string,
  groups2: string,
  homeLocations: string
}

export type ZonesList = Zone[];

export interface ZonesListAndCount {
  list: ZonesList,
  totalCount: number
}

export function getZonesList(params: ZonesListParams) {
  return async (options: RequestInitWithRetry): Promise<ZonesListAndCount> => {
    const input = {
      path: `/api/zones/list`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        sort: get(params, 'sort'),
        search: get(params, 'search')
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export interface ZoneData {
  id: string,
  name: string,
  groups1: string[],
  groups2: string[],
  homeLocationIds: string[]
}

export interface CreateZoneResponse {
  success: boolean,
  zone: {
    id: string,
    name: string,
    groups1: string[],
    groups2: string[],
    homeLocationIds: string[]
  }
}
export type CreateZoneData = Omit<ZoneData, 'id'>;

export function createZonesAction(options: RequestInitWithRetry) {
  return async (data: CreateZoneData): Promise<CreateZoneResponse> => {
    const input = {
      path: `/api/zones/createZone`,
      method: 'POST',
      body: { data }
    };
    return request(input, options);
  };
}

export type EditZoneData = Omit<ZoneData, 'name'>;

type MutationResponse = {
  success: boolean
};

export function editZoneAction(options: RequestInitWithRetry) {
  return async (data: EditZoneData): Promise<MutationResponse> => {
    const input = {
      path: `/api/zones/update`,
      method: 'POST',
      body: { data }
    };
    return request(input, options);
  };
}

export enum ZoneDeleteFailureReason {
  BLOCKED = 'BLOCKED'
}

export type ZoneDeleteFailureData = {
  hasUsers: string[]
};

export function deleteZonesAction(options: RequestInitWithRetry) {
  return async (ids: string[]): Promise<EitherOrUnexpected<MutationResponse, ZoneDeleteFailureReason, ZoneDeleteFailureData>> => {
    const input = {
      path: `/api/zones/delete`,
      method: 'POST',
      body: { ids }
    };
    try {
      const response = await request<EitherResponse<MutationResponse, undefined, ZoneDeleteFailureData>>(input, options);
      if (isSuccessResponse(response)) {
        return expectedSuccess(response.data);
      } else {
        return expectedFailureFrom(ZoneDeleteFailureReason.BLOCKED, response.data);
      }
    } catch (e) {
      return unexpectedFailureFrom(e);
    }
  };
}
