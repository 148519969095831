import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';

import Card from '../../components/card/card';
import './style.css';

import { HydratedLandingProductData } from '../../containers/landing/index';
import { ProductData } from '../../containers/app/productsWrapper';

const Licensed = () => {
  const { t } = useTranslation();
  return <div className="landing-item-license landing-item-license__true"><i className="fas fa-check-circle" />&nbsp;{t('LICENSED')}</div>;
};

const UnLicensed = () => {
  const { t } = useTranslation();
  return <div className="landing-item-license landing-item-license__false"><i className="fas fa-plus-circle" />&nbsp;{t('PURCHASE')}</div>;
};

const ItemWrapper = (props: { link: string, active: boolean, children: React.ReactNode }) => {
  if (!props.active) {
    return <React.Fragment>{props.children}</React.Fragment>;
  }

  const externalLink = new RegExp('^https?://').test(props.link);
  return externalLink ?
    (<a aria-label="link to external resource" href={props.link} target="_blank" rel="noopener noreferrer">{props.children}</a>) :
    (<Link aria-label="link to internal resource" to={props.link}>{props.children}</Link>);
};

interface Props extends
  Omit<ProductData, 'features'>,
  Omit<HydratedLandingProductData, 'alwaysVisible'>
{
}

const item = (props: Props) => {
  const { enabled, title, subtitle, img, link } = props;
  return (
    <div className={`landing-item__container ${enabled ? 'active__item' : ''}`}>
      <ItemWrapper link={link} active={enabled}>
        <Card className="h-100">
          <div>
            {enabled ? <Licensed /> : <UnLicensed />}
            <div className="landing-item__title"><h2>{title}</h2></div>
            <div className="landing-item__image"><img alt={`link to ${title}`} src={img} /></div>
            <div className="landing-item__subtitle">{subtitle}</div>
          </div>
        </Card>
      </ItemWrapper>
    </div>
  );
};

export default item;
