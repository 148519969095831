import { FormState } from './formsLib';
import { FormField } from './formField';

import './formFieldList.css';

export interface FormFieldsProps<FieldNames extends readonly string[], ErrorKeys extends string> {
  fieldNames: readonly FieldNames[number][],
  fieldsConfig: FormState<FieldNames, ErrorKeys>['fieldsConfig'],
  values: FormState<FieldNames, ErrorKeys>['values'],
  errors: FormState<FieldNames, ErrorKeys>['errors'],
  disabled: boolean,
  onChange: (fieldName: FieldNames[number], value: string) => void,
  onValidate: (fieldName: FieldNames[number]) => void
}

export function FormFieldList<FieldNames extends readonly string[], ErrorKeys extends string>(props: FormFieldsProps<FieldNames, ErrorKeys>): JSX.Element {
  const { fieldNames, fieldsConfig, values, errors, disabled: allDisabled, onChange, onValidate } = props;

  if (fieldNames.length !== [...new Set(fieldNames)].length) {
    throw new Error('field names must be unique');
  }

  const fields = fieldNames.map((fieldName) => {
    const key = `field-${fieldName}`;
    const config = fieldsConfig[fieldName];
    const error = errors?.[fieldName];
    const value = values?.[fieldName];
    const disabled = allDisabled || config.disabled;
    return (
      <FormField
        key={key}
        fieldName={fieldName}
        value={value}
        config={config}
        error={error}
        disabled={disabled}
        onChange={onChange}
        onValidate={onValidate}
      />
    );
  });

  return (
    <div className="form-field-list">
      {fields}
    </div>
  );
}
