import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export type Redirect = {
  message: string,
  path: string
};

type Props = {
  message: string,
  messageLine2?: string,
  redirect?: {
    message: string,
    path: string
  }
};

const InvalidPageContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
`;

const Message = styled.div`
  font-weight: 800;
  margin: 5px 0;
`;

const MessageLine2 = styled.div`
  margin: 5px 0;
  font-weight: 400;
`;

const RedirectLink = styled(Link)`
  color: #07c;

  &:hover {
    text-decoration-line: underline;
    cursor: pointer;
  }
`;

export const InvalidPage = (props: Props) => {
  const { message, messageLine2, redirect } = props;

  return (
    <InvalidPageContainer data-id="invalid-page">
      {message && <Message data-id="message">{message}</Message>}
      {messageLine2 && <MessageLine2 data-id="message-line-2">{messageLine2}</MessageLine2>}
      {redirect &&
      <RedirectLink data-id="redirect-link" to={redirect.path}>
        {redirect.message}
      </RedirectLink>}
    </InvalidPageContainer>
  );
};
