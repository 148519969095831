import React, { useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { last, map, chain, toNumber } from 'lodash';
import moment from 'moment';

import { Slider } from '../../../components/controls/slider';
import { AmChart, defaultTheme } from '../../../components/chart/amChart';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

interface IProps {
  id: string,
  data: { batteryLevels?: IBatteryLevelData[] }
}

export enum SliderOptions {
  day = 1,
  twodays = 2,
  week = 7,
}

const SliderOptionsDisplay = {
  [SliderOptions.day]: 'HOURS_24' as const,
  [SliderOptions.twodays]: 'HOURS_48' as const,
  [SliderOptions.week]: 'DAYS_7' as const,
};

interface IBatteryLevelData {
  utc: string,
  level: number,
  device: { serialNumber: string }
}

function getDisplayData(data: IBatteryLevelData[], from: number): IBatteryLevelData[] {
  return chain(data).orderBy('utc').filter((item) => { return (+item.utc) >= from; }).value();
}

function getSliceTime(option: SliderOptions) {
  const now = moment.utc().valueOf();
  switch (option) {
  case SliderOptions.twodays: return now - (2 * 24 * 60 * 60 * 1000);
  case SliderOptions.week: return now - (7 * 24 * 60 * 60 * 1000);
  default: return now - (24 * 60 * 60 * 1000);
  }
}

export function getLabelDateFormat(timestamp: number): string {
  return moment(timestamp).format('l LT');
}

export function BatteryChargeLevel(props: IProps) {
  const { t } = useTranslation(['translation', 'timeState', 'batteryEssentials']);

  const [sliderOption, setSliderOption] = useState(SliderOptions.day);

  const data = getData();
  //find last updated time
  const lastUpdatedBattery = last(data);
  const lastUpdatedUtc = lastUpdatedBattery && (+((lastUpdatedBattery) as any).utc);
  const lastBatteryUpdatedTime = lastUpdatedUtc && `(${t('UPDATED', { ns: 'translation' })} ${moment(lastUpdatedUtc).calendar()})`;
  const sliderRanges = [
    { value: SliderOptions.day, display: t(SliderOptionsDisplay[SliderOptions.day], { ns: 'timeState' }) },
    { value: SliderOptions.twodays, display: t(SliderOptionsDisplay[SliderOptions.twodays], { ns: 'timeState' }) },
    { value: SliderOptions.week, display: t(SliderOptionsDisplay[SliderOptions.week], { ns: 'timeState' }) }
  ];
  let timePeriod = t('NOUN_AGREEMENT_HOURS', { ns: 'translation', value: 24 });
  if (sliderOption === SliderOptions.week) {
    timePeriod = t('NOUN_AGREEMENT_DAYS', { ns: 'translation', value: 7 });
  } else if (sliderOption === SliderOptions.twodays) {
    timePeriod = t('NOUN_AGREEMENT_HOURS', { ns: 'translation', value: 48 });
  }

  return (
    <div className="row details_chargeLevel h-100">
      <div className="col-md-8 col-sm-12 col-12 h-100 chart-column">
        <div className="chart-title">
          <span>
            {t('CHARGE_LEVEL_STATEMENT', { ns: 'batteryEssentials', timePeriod })}
          </span>
        </div>
        <AmChart
          tag='battery-level-chart'
          chartProvider={createChart}
          dataProvider={() => data}
          link={`${lastUpdatedUtc}:${sliderOption}`}
        />
        <div className="chart-footer">
          <span>
            {t('LAST_TIME_SEEN_STATEMENT', { ns: 'translation', timePeriod })} {lastBatteryUpdatedTime}
          </span>
        </div>
      </div>
      <div className="col-md-4 col-sm-12 col-12">
        <span className="slider-title">{t('TIME_PERIOD', { ns: 'batteryEssentials' })}</span>
        <Slider
          options={sliderRanges}
          type="blue"
          onSliderChangeComplete={(value) => setSliderOption(value as SliderOptions)}
        />
      </div>
    </div>
  );

  /* istanbul ignore next */
  function createChart(id: string) {
    am4core.useTheme(defaultTheme);

    let chart = am4core.create(id, am4charts.XYChart);

    let xAxis = chart.xAxes.push(new am4charts.DateAxis());
    xAxis.dataFields.data = "utc";
    xAxis.renderer.minGridDistance = 50;
    xAxis.groupData = true;
    xAxis.groupCount = 10000;
    xAxis.cursorTooltipEnabled = false;
    xAxis.maxZoomFactor = 1;
    xAxis.renderer.grid.template.strokeWidth = 0;
    xAxis.baseInterval = { timeUnit: 'minute', count: 5 };

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.dataFields.data = "level";
    yAxis.min = 0;
    yAxis.max = 100;
    yAxis.renderer.minGridDistance = 25;
    yAxis.numberFormatter.numberFormat = "#'%'";
    yAxis.cursorTooltipEnabled = false;
    yAxis.maxZoomFactor = 1;

    let series = chart.series.push(new am4charts.LineSeries());
    series.xAxis = xAxis;
    series.yAxis = yAxis;
    series.dataFields.dateX = "utc";
    series.dataFields.valueY = "level";

    series.connect = false;
    series.autoGapCount = 4;
    series.stroke = am4core.color('#5ECAEA');
    series.fillOpacity = 0.3;
    series.adapter.add('tooltipText', (_, target) => {
      let tooltip = target.tooltipDataItem as any;
      let data = tooltip.dataContext as IBatteryLevelData;

      let device;
      if (data && data.device) {
        device = `\n${t('DEVICE', { ns: 'translation' })} SN: ${data.device}`;
      }
      const date = getLabelDateFormat(tooltip.dateX);
      return `[bold]${date}[/]\n{valueY}${device ? device : ''}`;
    });

    series.tooltip.pointerOrientation = "vertical";
    series.tooltip.background.fillOpacity = 0.5;

    //https://www.amcharts.com/docs/v4/concepts/axes/axis-tooltips/
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = xAxis;
    chart.cursor.behavior = 'none';
    chart.zoomOutButton.disabled = true;

    //grid with 25 step increment
    function createGrid(value: number) {
      let range = yAxis.axisRanges.create();
      range.value = value;
      range.label.text = "{value}";
    }
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;
    createGrid(0);
    createGrid(25);
    createGrid(50);
    createGrid(75);
    createGrid(100);

    return chart;
  }

  function getData() {
    //cut data with time limit selected on the slider
    const displayfrom = getSliceTime(sliderOption);
    const data = (props.data && props.data.batteryLevels) ? props.data.batteryLevels : [];
    const filteredData = getDisplayData(data, displayfrom);

    const chartData = map(filteredData, (item) => ({
      utc: toNumber(item.utc),
      level: item.level,
      device: item.device ? item.device.serialNumber : undefined
    }));

    return chartData;
  }
}
