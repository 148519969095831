import * as React from "react";
import { Trans as RI18NTrans } from "react-i18next";
import { ITranslationKeys } from "./keys";
import { DefaultNamespace } from "i18next";

/** type cast to avoid objects as react children error
 * https://github.com/i18next/react-i18next/issues/1483#issuecomment-1268455602
 */
export type TransInterpolation = any;

type TProps<
  TKey extends keyof ITranslationKeys[TNamespace],
  TNamespace extends keyof ITranslationKeys = DefaultNamespace,
> = {
  i18nKey: TKey,
  ns: TNamespace | TNamespace[],
  [key: string]: any
};

export const Trans = <
  TKey extends keyof ITranslationKeys[TNamespace],
  TNamespace extends keyof ITranslationKeys
>(props: React.PropsWithChildren<TProps<TKey, TNamespace>>) => {
  return <RI18NTrans {...props as any}>{props.children || props.i18nKey}</RI18NTrans>;
};
