import { AgreementsIds } from "../services/agreements/agreements";
import { AgreementTypes } from "./agreements";

export function getAgreementsToAccept(agreements: AgreementsIds) {
  const {
    requiredEULA,
    acceptedEULA,
    requiredDPA,
    acceptedDPA,
  } = agreements;
  const agreementsToAccept = [];
  const eulaNeedsAccepting = Boolean(requiredEULA) && requiredEULA !== acceptedEULA;
  const dpaNeedsAccepting = Boolean(requiredDPA) && requiredDPA !== acceptedDPA;
  eulaNeedsAccepting && agreementsToAccept.push(AgreementTypes.eula);
  dpaNeedsAccepting && agreementsToAccept.push(AgreementTypes.dpa);

  return agreementsToAccept;
}
