import { useCallback, useMemo, useReducer } from 'react';
import { tuple } from '../../../lib/typeUtils';
import { Zone } from '../../../services/zones/zones';

const groupTabIds = ['groups1', 'groups2'];
const tabIds = [...groupTabIds, 'homelocations'] as const;

export type TabOptionStatus = {
  [k: string]: boolean
};

export type TabId = typeof tabIds[number];
type GroupTabId = typeof groupTabIds[number];

export type TabItems = {
  [k in TabId]: TabOptionStatus
};

export type DisabledOptions = {
  [k in GroupTabId]: string
};

export type TabSearch = {
  [k in TabId]: string
};

export type TabIdWithItems = { id: TabId, items: string[] };

export const optionItems = (tabItems: string[], checked: boolean = false): TabOptionStatus => tabItems.reduce((items: TabOptionStatus, key: string) => (
  {
    ...items,
    [key]: checked
  }), {});

export interface zoneData {
  name: string,
  groups1: string[],
  groups2: string[],
  homeLocations: string[]
}

export type ZoneAction =
  | { type: 'onOptionsSelected', name: string, checked: boolean, options: TabIdWithItems }
  | { type: 'onSearch', tab: string, search: string }
  | { type: 'onUpdate', initialCheckedItems: Zone};

export interface ZoneState {
  zone: zoneData,
  selectedOptions: TabItems,
  disabledGroupsOptions: DisabledOptions[],
  searchOptions: TabSearch
}

export function zoneReducerInit(zone: zoneData): ZoneState {
  return {
    zone,
    selectedOptions: { 'groups1': optionItems(zone.groups1), 'groups2': optionItems(zone.groups2), 'homelocations': optionItems(zone.homeLocations) },
    disabledGroupsOptions: [],
    searchOptions: { 'groups1': '', 'groups2': '', 'homelocations': '' }
  };
}

const updateSelectedOptions = (name: string, checked: boolean, options: TabIdWithItems, selectedOptions: any, disabledGroupsOptions: any) => {
  let newSelectedOptions: TabItems = { ...selectedOptions };
  let newDisabledOptions: any = [...disabledGroupsOptions];
  Object.keys(selectedOptions).forEach((key: string) => {
    if (key === options.id) {
      if (key === 'groups1') {
        newSelectedOptions['groups1'] = {
          ...selectedOptions['groups1'],
          [name]: checked
        };
        newDisabledOptions = [
          ...disabledGroupsOptions,
          {
            'groups2': name
          }];
      } else if (key === 'groups2') {
        newSelectedOptions['groups2'] = {
          ...selectedOptions['groups2'],
          [name]: checked
        };
        newDisabledOptions = [
          ...disabledGroupsOptions,
          {
            'groups1': name
          }];
      } else {
        newSelectedOptions[key as TabId] = {
          ...selectedOptions['homelocations'],
          [name]: checked
        };
      }
    }
  });
  return {
    newSelectedOptions,
    newDisabledOptions
  };
};

function getOptionsBySearch(searchOptions: any, tab: string, search: string) {
  searchOptions = {
    ...searchOptions,
    [tab]: search
  };
  return searchOptions;
}

function getCheckedItems(items: Zone, selectedOptions: any, disabledGroupsOptions: any) {
  const groups1Items = items['groups1'].split(', ');
  const groups2Items = items['groups2'].split(', ');
  const initialGroups2Disabled = groups1Items.map((item: string) => { return { 'groups2': item }; });
  const initialGroups1Disabled = groups2Items.map((item: string) => { return { 'groups1': item }; });
  let updatedSelectedOptions: TabItems = { ...selectedOptions };
  updatedSelectedOptions = {
    ...selectedOptions,
    'groups1': optionItems(groups1Items, true),
    'groups2': optionItems(groups2Items, true),
    'homelocations': optionItems(items['homeLocations'].split(', '), true)
  };

  const groupDisabledItems = [...disabledGroupsOptions, ...initialGroups1Disabled, ...initialGroups2Disabled];
  return {
    updatedSelectedOptions,
    groupDisabledItems
  };
}

export const zoneReducer = (state: ZoneState, action: ZoneAction): any => {
  if (action.type === 'onOptionsSelected') {
    const { name, checked, options } = action;
    const { selectedOptions, disabledGroupsOptions } = state;
    const updated = updateSelectedOptions(name, checked, options, selectedOptions, disabledGroupsOptions);
    return { ...state, selectedOptions: updated.newSelectedOptions, disabledGroupsOptions: updated.newDisabledOptions };
  }
  if (action.type === 'onSearch') {
    const { tab, search } = action;
    const { searchOptions } = state;
    const updatedOptionsBySearch = getOptionsBySearch(searchOptions, search, tab);
    return { ...state, searchOptions: updatedOptionsBySearch };
  }
  /* istanbul ignore else*/
  if (action.type === 'onUpdate') {
    const { initialCheckedItems } = action;
    const { selectedOptions, disabledGroupsOptions } = state;
    const checkedItems = getCheckedItems(initialCheckedItems, selectedOptions, disabledGroupsOptions);
    return { ...state, selectedOptions: checkedItems.updatedSelectedOptions, disabledGroupsOptions: checkedItems.groupDisabledItems };
  }
  /* istanbul ignore next */
  return state;
};

export const useZoneReducer = (zone: zoneData) => {
  const reducer = useCallback(zoneReducer, []);
  const [data, dispatch] = useReducer(reducer, zone, zoneReducerInit);
  const dispatchers = useMemo(() => {
    const onOptionsSelected = (name: string, checked: boolean, options: TabIdWithItems) => {
      return dispatch({ type: 'onOptionsSelected', name, checked, options });
    };
    const onSearch = (tab: string, search: string) => {
      return dispatch({ type: 'onSearch', tab, search });
    };
    const onUpdate = (initialCheckedItems: Zone) => {
      return dispatch({ type: 'onUpdate', initialCheckedItems });
    };
    return {
      onOptionsSelected,
      onSearch,
      onUpdate
    };
  }, [dispatch]);
  return tuple(data, dispatchers);
};
