import * as React from 'react';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { Card } from '../../../components/card/card';
import { AlertsOverview } from './compositions/alerts-overview';
import { AlertCount, getDeviceCount, getAlertCount, IDeviceCount } from '../../../services/core/overview';
import { Estate } from './compositions/estate';
import { CoreEstateOverviewTable } from './compositions/core-estate-overview-table';
import { Loading } from '../../../components/loading/loading';
import { RequestInitWithRetry } from '../../../lib/request';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';

import './coreOverview.css';
import { useTranslation } from '@lib/useTypedTranslation';
import { Trans, TransInterpolation } from '@components/i18n/typedTrans';

export function CoreOverview() {
  const [deviceCount, setDeviceCount] = useState<IDeviceCount>();
  const [alerts, setAlerts] = useState<AlertCount>();
  const { t } = useTranslation();
  const total = deviceCount ? deviceCount.all : 0;
  const totalWithAlerts = deviceCount ? deviceCount.red + deviceCount.yellow : 0;
  const routes = useWorldRoutes();
  const linkToDevices = routes.core.devicesList;

  const [countDataFetcher, onCountsSuccess] = useMemo(() => {
    return [
      () => (options: RequestInitWithRetry) => Promise.all([
        getDeviceCount()(options),
        getAlertCount()(options)
      ]),
      ([deviceCount, alerts]: [IDeviceCount, AlertCount]) => {
        setDeviceCount(deviceCount);
        setAlerts(alerts);
      }
    ];
  }, []);

  const {
    loading: dataLoading,
  } = useWorldRequest(countDataFetcher, { initialLoading: true, onSuccess: onCountsSuccess });

  const message = <div className="core-overview-message">
    <div className="estate-message-header">{t("ESTATE_OVERVIEW_TABLE_TITLE")}</div>
    <div className="estate-message-body">
      <div className="estate-message-text">
        <Trans ns="translation" i18nKey="ESTATE_ALERT_COUNT_SIMPLE" count={totalWithAlerts}><strong>{{ totalWithAlerts } as TransInterpolation}</strong> of your <span className="total">{{ total } as TransInterpolation}</span> devices are <span className="currently-alerting-message">alerting.</span></Trans>
      </div>
      <Link to={linkToDevices} className="estate-message-link">{t('VIEW_DEVICES')}</Link>
    </div>
  </div>;

  const estateAlertBreakdown = <CoreEstateOverviewTable alerts={alerts}/>;

  return (
    <div className="core-overview_container">
      <div className="chart-and-header-container">
        <Card noPadding={true}>
          <div className='core-overview_container__estate-and-alerts'>
            <div className="estate-container">
              <Loading isLoading={dataLoading} transparentOverlay={false}>
                <Estate
                  data={deviceCount}
                  linkToDevices={linkToDevices}
                  message={message} />
              </Loading>
            </div>
            <div className="alerts-container">
              <AlertsOverview alerts={alerts} dataLoading={dataLoading} />
            </div>
          </div>
        </Card>
      </div>
      <div className="core-overview_table-container">
        <Card noPadding={true}>
          {estateAlertBreakdown}
        </Card>
      </div>
    </div>
  );
}
