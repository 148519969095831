import * as React from 'react';

interface IHeaderProps {
  title?: React.ReactNode,
  subtitle?: React.ReactNode
}

const Header = (props: IHeaderProps) => {
  return (
    <div className="header">
      <div className="header_text">
        {props.title}
      </div>
      { props.subtitle && <div className="header_subtitle_text">
        {props.subtitle}
      </div>}
    </div>);
};

export default Header;
