import * as React from "react";
import { chain, includes, pull, union } from 'lodash';
import { CheckBox, ICheckboxValue } from "../controls/checkbox";

export interface IFilterGroupProps {
  id: string,
  filterName: JSX.Element | string,
  availableValues: string[],
  selectedValues: string[],
  onChange: (id: string, selectedValues: string[]) => void,
  icon?: string
}

export class FilterGroup extends React.Component<IFilterGroupProps> {
  constructor(props: IFilterGroupProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  public handleChange(event: ICheckboxValue) {
    this.handleFilterItemChange(event.value, event.checked);
  }

  public handleFilterItemChange(key: string, isSelected: boolean) {
    let modifiedValues;
    if (isSelected) {
      modifiedValues = union(this.props.selectedValues, [key]);
    } else {
      modifiedValues = pull(this.props.selectedValues, key);
    }

    this.setState({ modifiedValues });
    this.props.onChange(this.props.id, modifiedValues);
  }

  public render() {
    const filterBlocks = chain(this.props.availableValues).sortBy().map(filter => {
      const selected = includes(this.props.selectedValues, filter);
      return <div key={`filter_block_${this.props.id}_${filter}`} className="filter-box">
        <CheckBox className="fancy-checkbox" name={'filter_' + this.props.id} value={filter} checked={selected} onChange={this.handleChange} icon={this.props.icon} />
      </div>;
    }).value();

    return (
      <div className="filter-selection-group col-md-4">
        <h3>{this.props.filterName}</h3>
        <div className="filter-block">
          {filterBlocks}
        </div>
      </div>);
  }
}
