import React, { useState } from 'react';
import styled from 'styled-components';
import { Search } from '../controls/search';

export enum SearchType {
  startOfText = 'startOfText',
  wholeText = 'wholeText'
}

interface Props {
  items: { element: any, text: string }[],
  searchType: SearchType,
  className?: string,
  searchTranslation?: string,
  onSearch?: (searchText: string) => void,
  initialSearch?: string,
  dataId?: string
}

const ListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SearchBox = styled(Search)`
  & input {
    margin-left: 0;
    border-radius: 4px;
    width: 10rem;
    margin-bottom: 1rem;
    padding-right: 28px;
  }

  & span {
    right: 0.7rem;
  }
`;

export function SearchableList(props: Props) {
  const [search, setSearch] = useState(props.initialSearch || '');

  function handleSearchChange(text: string) {
    props.onSearch && props.onSearch(text.toLowerCase());
    setSearch(text.toLowerCase());
  }

  function applySearchFilter(itemText: string) {
    if (props.searchType === SearchType.startOfText) {
      return itemText.toLowerCase().startsWith(search);
    }
    return itemText.toLowerCase().includes(search);
  }

  return (
    <div className={`${props.className || ''}`}>
      <SearchBox
        onSearch={handleSearchChange}
        appliedSearch={search}
        inputProps={{ placeholder: props.searchTranslation }}
        dataId={props.dataId}
      />
      <ListContainer>
        {props.items.filter(i => applySearchFilter(i.text)).map(i => <React.Fragment key={i.text}>{i.element}</React.Fragment>)}
      </ListContainer>
    </div>
  );
}
