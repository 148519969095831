import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import moment from "moment";

export function getWeekendStart(date: number): number {
  const isSaturday = moment(date).isoWeekday() === 6;
  if (!isSaturday) {
    const daysToSaturday = 6 - moment(date).isoWeekday();
    return moment.utc(date).add(daysToSaturday, "days").valueOf();
  }
  return date;
}

export function getWeekends(): [number, number][] {
  let weekends: [number, number][] = [];
  const end = moment.utc().valueOf();
  const startOfXAxis = moment.utc(end).subtract(29, "days").startOf('day').valueOf();
  let start = startOfXAxis;
  while (start <= end) {
    //get weekend start and end dates
    let dateWeekendStart = getWeekendStart(start);
    let axisWeekendStart = Math.max(startOfXAxis, dateWeekendStart);
    let axisWeekendEnd = Math.min(end, moment.utc(dateWeekendStart).add(1, "day").valueOf());
    start = moment.utc(dateWeekendStart).add(7, "days").valueOf();
    weekends.push([axisWeekendStart, axisWeekendEnd]);
  }
  return weekends;
}

/* istanbul ignore next */
export function highlightWeekends(categoryAxis: am4charts.CategoryAxis) {
  //create axis ranges for weekends
  const weekends = getWeekends();
  for (const [start, end] of weekends) {
    let range = categoryAxis.axisRanges.create();
    range.category = start.toString();
    range.endCategory = end.toString();
    range.axisFill.fill = am4core.color("#d3d3d3");
    range.label.disabled = true;
    range.axisFill.fillOpacity = 0.2;
    range.grid.strokeOpacity = 0;
  }
}
