import React, { useCallback, useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import classNames from 'classnames';
import styled from 'styled-components';

import { ITableColumn } from '../../../../components/data-table/types';
import { getFilterOptionDisplay, getFilterOptionId } from '../../../../components/filters/filterLib';
import { InitialTableState, useTableReducer } from '../../../../components/data-table/lib';
import { HomeLocationAndGroupData, getHomeLocationAndGroupOverview, AlertCount, HomeLocationAndGroupDataType, IHomeLocationAndGroupListData } from '../../../../services/core/overview';
import { AlertFilters } from './alertFilters';
import { Search } from '../../../../components/controls/search';
import { useUserSettingsContext } from '../../../../context/userSettings';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { AlarmStatus } from '../../../../services/config/alertConstants';
import { List } from '../../../../components/list/list';
import { AlertLevel, useCoreEstateOverviewListColumns } from './useCoreEstateOverviewListColumns';
import './core-estate-overview.css';

const StyledSearch = styled(Search)`
  & input {
    width: 23rem;
  }

  & span {
    top: 0.2rem;
  }
`;

StyledSearch.displayName = 'StyledSearch';

const StyledSubtitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
`;

StyledSubtitle.displayName = 'StyledSubtitle';

const StyledEmptyTable = styled.div`
  font-size: 0.875rem;
  padding: 50px 0 100px 0;
  text-align: center;
  font-style: italic;
`;

interface IProps {
  alerts: AlertCount,
  filtersShown: boolean
}

export const homeLocationAndGroupTableName = 'homeLocationAndGroupOverview';
export const defaultPageSize = 20;

export interface CoreEstateOverviewColumn extends ITableColumn {
  linkStatus?: AlertLevel
}

const ns = 'translation' as const;
const ns2 = 'estateOverview' as const;
const ns3 = 'editRecord' as const;
const ns4 = 'filters' as const;

export function CoreEstateOverview(props: IProps) {
  const { filtersShown, alerts } = props;
  const { t } = useTranslation([ns, ns2, ns3, ns4]);
  const { tablePageSizes } = useUserSettingsContext();

  const homeLocationInitialTableState: InitialTableState = {
    filter: { status: [{ id: "deviceStatus", value: t('DEFAULT_FILTER', { ns: ns2 }) }] },
    initialRows: 4,
    sort: { column: 2, direction: 'desc', field: 'alertingPercentage' },
    limit: tablePageSizes?.[homeLocationAndGroupTableName] || defaultPageSize,
  };

  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<HomeLocationAndGroupData>(homeLocationAndGroupTableName, homeLocationInitialTableState);

  const [{ data, offset, limit, sort, isLoading: dataIsLoading, filter, search }, {
    onLoading,
    onFilter
  }] = [tableReducerProperties, tableReducerFunctions];

  const filterId = useMemo(() => {
    const optionFilter = getFilterOptionId(filter.status[0]) as AlarmStatus;
    return optionFilter;
  }, [filter]);

  const homeLocationAndGroupOverviewFetcher = useCallback(() => {
    return getHomeLocationAndGroupOverview({
      filter: filterId,
      limit,
      offset,
      search,
      sort: { field: sort.field, order: sort.direction }
    });
  }, [filterId, limit, offset, search, sort.field, sort.direction]);

  const columns: CoreEstateOverviewColumn[] = useCoreEstateOverviewListColumns(filterId);

  const homeLocationsAndGroupsListTranslationItems = {
    statusText: {
      key: 'HOME_LOCATIONS_AND_GROUPS',
      ns: 'estateOverview'
    }
  };

  const homeLocationAndGroupClassNames = classNames('core-estate-overview__table', 'core-estate-overview__table--home-location-and-group', { 'data-ready': !dataIsLoading });
  const tableCreator = () => {
    const text = getFilterOptionDisplay(filter.status[0]);
    return <>
      <div className={homeLocationAndGroupClassNames}>
        {filtersShown && <AlertFilters
          data={data}
          onFilter={(filter) => { onLoading(); onFilter(filter); }}
          currentFilterId={filterId}
          alerts={alerts}
          showAllFilters={Boolean(search)}
        />}
        <List<HomeLocationAndGroupData, IHomeLocationAndGroupListData, 'totalCount', 'list', 'homeLocationAndGroup'>
          dataId="core-estate-overview__table"
          customHeader={false}
          subHeader={<StyledSubtitle data-id="core-estate-overview__filters--subtitle">{t('SUBTITLE', { text, ns: ns2 })}</StyledSubtitle>}
          dataTableEnabled={Boolean(data.length || search)}
          noDataTable={<StyledEmptyTable data-id="core-estate-overview__no-alerts">
            {t('NO_ALERTS', { ns: ns2 })}
          </StyledEmptyTable>}
          countDataPath='totalCount'
          listDataPath='list'
          hasCheckboxes={false}
          hasFilters={false}
          hasSearch
          translationItems={homeLocationsAndGroupsListTranslationItems}
          columns={columns}
          fetcher={homeLocationAndGroupOverviewFetcher}
          tableReducerFunctions={tableReducerFunctions}
          tableReducerProperties={tableReducerProperties}
          useRequestHook={useWorldRequest}
          t={t}
          toggleSort
          options={{ getKey: (item: { [key: string]: any }) => `${item?.homeLocationAndGroup}-${item?.type}`, rowIdDataPath: 'homeLocationAndGroup' }}
        />
      </div>
    </>;
  };

  return (
    <div className="core-estate-overview">
      <div className="core-estate-overview__filters">
        <div data-id="core-estate-overview__filters--table">{tableCreator()}</div>
      </div>
    </div>
  );
}
