import React, { useContext, useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { useTableReducer, InitialTableState } from '../../../../../components/data-table/lib';
import { List } from '../../../../../components/list/list';
import {
  IApplicationsData,
  IApplicationListData,
  getDeviceApplicationsList,
  IApplicationUsageData,
  getDeviceApplicationsOverview,
  IDeviceApplicationsOverviewData,
  getDeviceApplicationUsage,
  getDeviceApplicationEvents,
  IApplicationDataUsage,
  getDeviceApplicationDataUsage
} from '../../../../../services/core/deviceApplications';
import { DeviceInfoContext } from '../../index';
import { useUserSettingsContext } from '../../../../../context/userSettings';
import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { useDeviceApplicationsListColumns } from './useDeviceApplicationsListColumns';
import { useTranslation } from '@lib/useTypedTranslation';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { isUndefined } from 'lodash';
import { IApplicationEvent } from '../../../../../services/core/eventsTypes';
import { ApplicationUsage30Days, Series } from './30DayApplicationUsage';
import { valueof } from '../../../../../lib/typeUtils';
import { formatValue } from '../../../../../components/data-table/dataFormatters';
import Spinner from '../../../../../components/loading/loadingSpinner';
import { OverviewDashboard } from '../../../../../components/overview/overview';
import { Loading } from '../../../../../components/loading/loading';
import { RequestInitWithRetry } from '../../../../../lib/request';
import { SystemFilter } from '../../../../../components/systemFilter/systemFilter';
import { NoSelectionOverlay } from '../../../../../components/card/noSelectionOverlay';
import { getRelativeDailyDeviceDateParams, getRelativeHourlyDeviceDateParams } from '../../../../../lib/dateParams';
import './device-applications.css';
import { ChartTimePeriod } from '../../deviceFormat';


const getTitle = (params: { t: TTypedTFunction, translationKey: string, count?: number }) => {
  const { t, translationKey, count } = params;
  const text = t(`${translationKey}`, { count });
  return `${text} (${count})`;
};

export const tableName = 'deviceApplications';
export const defaultPageSize = 20;

export function DeviceApplications() {
  const { id: deviceId, timeZoneOffset } = useContext(DeviceInfoContext);
  const { tablePageSizes } = useUserSettingsContext();
  const { t } = useTranslation();
  const [systemFilter, setSystemFilter] = React.useState<{ system: boolean }>(undefined);
  const [chartApplicationId, setChartApplicationId] = useState<string>();
  const [last30DaysApplicationsUsage, setLast30DaysApplicationsUsage] = useState<IApplicationUsageData>();
  const [last30DaysApplicationEvents, setLast30DaysApplicationEvents] = useState<IApplicationEvent[]>();
  const [chartTimePeriod, setChartTimePeriod] = useState(ChartTimePeriod.last30Days);
  const [fetchedChartTimePeriod, setFetchedChartTimePeriod] = useState(ChartTimePeriod.last30Days);
  const [last30DaysApplicationDataUsage, setLast30DaysApplicationDataUsage] = useState<IApplicationDataUsage[]>();
  const [applicationPackageName, setapplicationPackageName] = useState<string>();
  const [series, setSeries] = useState(Series.usage);

  const columns = useDeviceApplicationsListColumns();

  const appOverviewDataFetcher = useCallback(() => {
    return getDeviceApplicationsOverview(deviceId);
  }, [deviceId]);
  const { data: appOverviewData } = useWorldRequest(appOverviewDataFetcher);

  const initialTableState: InitialTableState<IApplicationsData> = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 4, direction: 'desc', field: 'lastUsed' },
  };

  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<IApplicationsData>(tableName, initialTableState);
  const { data, total, offset, limit, sort, search, selectedRow, isLoading } = tableReducerProperties;
  const { onLoading, onRowSelected, onClearRowSelection } = tableReducerFunctions;

  const translationItems = {
    statusText: {
      key: 'DEVICE_APPLICATION_TOTAL',
      ns: 'deviceApplications'
    },
    tableTitle: {
      text: getTitle({ t, translationKey: 'deviceApplications:APPLICATION_ON_DEVICE', count: total })
    }
  };

  const selectedRowData = data[selectedRow];
  const applicationId = selectedRowData?.id;
  const applicationName = selectedRowData?.name;
  const sharedUserId = selectedRowData?.sharedUserId;
  const packageName = selectedRowData?.packageName;

  const onDataFetched = useCallback((data: [IApplicationUsageData, IApplicationEvent[], IApplicationDataUsage[]]) => {
    setLast30DaysApplicationsUsage(data[0] as IApplicationUsageData);
    setLast30DaysApplicationEvents(data[1] as IApplicationEvent[]);
    setLast30DaysApplicationDataUsage(data[2] as IApplicationDataUsage[]);
    setChartApplicationId(applicationId);
    setFetchedChartTimePeriod(chartTimePeriod);
    setapplicationPackageName(packageName);
  }, [applicationId, chartTimePeriod, packageName]);

  const dataFetcher = useCallback(() => {
    if (applicationId && (chartApplicationId !== applicationId || chartTimePeriod !== fetchedChartTimePeriod || packageName !== applicationPackageName)) {
      const datesForAll = chartTimePeriod === ChartTimePeriod.last30Days ?
        getRelativeDailyDeviceDateParams(moment.utc(), timeZoneOffset, 30) :
        getRelativeHourlyDeviceDateParams(moment.utc(), timeZoneOffset, chartTimePeriod === ChartTimePeriod.last48Hours ? 2 : 7);
      return (options: RequestInitWithRetry) => {
        return Promise.all([
          getDeviceApplicationUsage({ applicationId, ...datesForAll, bucketByHour: chartTimePeriod !== ChartTimePeriod.last30Days })(options),
          getDeviceApplicationEvents({ deviceId, applicationName, ...datesForAll, chartTimePeriod })(options),
          getDeviceApplicationDataUsage({ deviceId, packageName, sharedUserId, ...datesForAll, chartTimePeriod })(options)
        ]);
      };
    }
  }, [applicationId, chartApplicationId, chartTimePeriod, fetchedChartTimePeriod, packageName, applicationPackageName, timeZoneOffset, deviceId, applicationName, sharedUserId]);

  const { loading: isDashboardLoading } = useWorldRequest(dataFetcher, { onSuccess: onDataFetched });

  useEffect(() => {
    if (chartApplicationId && isUndefined(selectedRow) && !isLoading) {
      const applicationIndex = data.findIndex(application => application.id === chartApplicationId);
      if (applicationIndex !== -1) {
        onRowSelected(applicationIndex);
      }
    }
  }, [onRowSelected, data, chartApplicationId, selectedRow, isLoading]);

  const clearChartData = () => {
    setLast30DaysApplicationsUsage(undefined);
    setLast30DaysApplicationEvents(undefined);
    setLast30DaysApplicationDataUsage(undefined);
    setChartTimePeriod(ChartTimePeriod.last30Days);
    setFetchedChartTimePeriod(ChartTimePeriod.last30Days);
    setChartApplicationId(undefined);
    setapplicationPackageName(undefined);
  };

  const appListDataFetcher = useCallback(() => {
    return getDeviceApplicationsList({ deviceId, limit, offset, sort: { field: sort.field, order: sort.direction }, search, systemFilter });
  }, [deviceId, offset, limit, sort.field, sort.direction, search, systemFilter]);

  const handleRowSelected = (rowIndex: number) => {
    if (rowIndex === selectedRow) {
      // close chart if row deselected
      clearChartData();
    }
    onRowSelected(rowIndex);
  };

  return (
    <div className="core_device_applications_composition">
      <div className="inner_card">
        <Loading isLoading={isDashboardLoading} transparentOverlay={false}>
          <Dashboard overviewData={appOverviewData} chartData={last30DaysApplicationsUsage} applicationEventsData={last30DaysApplicationEvents} applicationDataUsage={last30DaysApplicationDataUsage} />
        </Loading>
      </div>
      <div>
        <List<IApplicationsData, IApplicationListData, 'totalCount', 'list', 'id'>
          dataId='core-device-applications-list'
          customHeader={false}
          countDataPath='totalCount'
          listDataPath='list'
          hasCheckboxes={false}
          hasFilters={false}
          hasSearch
          translationItems={translationItems}
          loading={isLoading}
          selectedRow={selectedRow}
          onRowSelected={handleRowSelected}
          columns={columns}
          fetcher={appListDataFetcher}
          tableReducerFunctions={tableReducerFunctions}
          tableReducerProperties={tableReducerProperties}
          useRequestHook={useWorldRequest}
          options={{ rowIdDataPath: 'id' }}
          additionalHeaderItems={[{
            Component: SystemFilter,
            props: {
              systemFilter,
              setSystemFilter: (value?: { system: boolean }) => { onLoading(); setSystemFilter(value); }
            }
          }]}
        />
      </div>
    </div>
  );

  function Dashboard(props: { overviewData: IDeviceApplicationsOverviewData, chartData: IApplicationUsageData, applicationEventsData: IApplicationEvent[], applicationDataUsage: IApplicationDataUsage[] }) {
    const { overviewData, chartData, applicationEventsData, applicationDataUsage } = props;
    const { t } = useTranslation('deviceApplications');

    const closeChart = () => {
      onClearRowSelection();
      clearChartData();
    };

    if (chartData) {
      return (
        <>
          <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns: "translation" })} show={chartData.applicationUsageData.length === 0 && applicationDataUsage.length === 0} />
          <div className="core-device-content_fixture">
            <ApplicationUsage30Days
              data={chartData}
              handleClose={closeChart}
              chartTimePeriod={chartTimePeriod}
              setChartTimePeriod={setChartTimePeriod}
              events={applicationEventsData}
              dataUsage={applicationDataUsage}
              series={series}
              setSeries={setSeries}
              sharedUserId={sharedUserId}
            />
          </div>
        </>
      );
    }

    const applicationCount = overviewData ? overviewData.applicationCount : 0;
    const installedCount = overviewData ? overviewData.installedCount : 0;
    const updatedCount = overviewData ? overviewData.updatedCount : 0;
    const usedCount = overviewData ? overviewData.usedCount : 0;
    const usedDayCount = overviewData ? overviewData.usedDay : 0;

    const formatIfLoaded = (value: valueof<IDeviceApplicationsOverviewData>) => {
      return overviewData ? formatValue(value) : <Spinner text='' />;
    };

    const labelsArray = [
      { label: t('APPLICATION_ON_DEVICE_OVERVIEW', { count: applicationCount }), value: formatIfLoaded(overviewData?.applicationCount) },
      { label: t('INSTALLED_APPLICATIONS', { count: installedCount }), value: formatIfLoaded(overviewData?.installedCount) },
      { label: t('UPDATED_APPLICATIONS', { count: updatedCount }), value: formatIfLoaded(overviewData?.updatedCount) },
      { label: t('USED_APPLICATIONS', { count: usedCount }), value: formatIfLoaded(overviewData?.usedCount) },
      { label: t('USED_TODAY', { count: usedDayCount }), value: formatIfLoaded(overviewData?.usedDay) }
    ];
    return (
      <>
        <div className={`core-device_overview core-device_content ${overviewData ? 'data-loaded' : ''}`}>{t('OVERVIEW_TITLE', { ns: 'deviceApplications' })}</div>
        <div className="core-device_content applications-overview-content_subtitle">{t('OVERVIEW_SUBTITLE', { ns: 'deviceApplications' })}</div>
        <div className="core-device-content_fixture"><OverviewDashboard items={labelsArray} /></div>
      </>
    );
  }
}
