import React, { useCallback, useContext } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import Card from '../../../components/card/card';
import { Filter } from '../../../components/timePeriodAndFilterPicker/useFilterPicker';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { TimePeriodPicker } from '../../../components/timePeriodAndFilterPicker/useTimePeriodPicker';
import { ApplicationInfoContext } from './coreApplication';
import { useWorldAction } from '../../../lib/useWorldAction';
import { List } from '../../../components/list/list';
import { Dispatchers, InitialTableState, ITableState } from '../../../components/data-table/lib';
import { useApplicationDevicesListColumns } from './useApplicationDevicesListColumns';
import { ApplicationDevicesList, ApplicationDevicesListItem, exportApplicationDevicesListAction, getApplicationDevicesList } from '../../../services/core/application';
import { TablePageSizes } from '../../../services/config/config';
import { getTitle } from './filterTitle';
import useNormalizedHeaderButtons from '../../../components/controls/normalizedHeaderButtons';

export const tableName = 'applicationDevicesList';
export const defaultPageSize = 20;

export interface ApplicationDevicesProps {
  timePeriod: TimePeriodPicker['timePeriod'],
  filter: Filter,
  version?: string,
  tableReducerProperties: ITableState<ApplicationDevicesListItem>,
  tableReducerFunctions: Dispatchers<ApplicationDevicesListItem>
}

export const getInitialTableState = (params: {tablePageSizes: TablePageSizes}): InitialTableState => ({
  limit: params.tablePageSizes?.[tableName] || defaultPageSize,
  sort: { column: 5, direction: 'desc', field: 'lastUsed' }
});

export function ApplicationDevices(props: ApplicationDevicesProps) {
  const { timePeriod, filter, version, tableReducerProperties, tableReducerFunctions } = props;
  const { t } = useTranslation(['coreApplications', 'translation']);
  const columns = useApplicationDevicesListColumns();
  const { id: applicationId } = useContext(ApplicationInfoContext);
  const exportList = useWorldAction(exportApplicationDevicesListAction);
  const onExportClicked = async (): Promise<string> => {
    const params = {
      id: applicationId,
      days: timePeriod.value,
      homeLocationId: filter.homeLocation?.value.id,
      group: filter.group?.value,
      zoneId: filter.zone?.value.id,
      version,
      search,
      sort: { field: sort.field, order: sort.direction }
    };
    return exportList(params);
  };

  const { limit, offset, search, sort } = tableReducerProperties;
  const fetcher = useCallback(() => {
    const params = {
      id: applicationId,
      days: timePeriod.value,
      homeLocationId: filter.homeLocation?.value.id,
      group: filter.group?.value,
      zoneId: filter.zone?.value.id,
      version,
      search,
      limit,
      offset,
      sort: { field: sort.field, order: sort.direction }
    };
    return getApplicationDevicesList(params);
  }, [filter, timePeriod, applicationId, version, limit, offset, search, sort]);

  const additionalHeaderButtons = useNormalizedHeaderButtons({
    export: {
      exportFunction: onExportClicked,
      filename: 'applicationDevices.csv'
    }
  });

  return (
    <div>
      <Card noPadding={true}>
        <List<ApplicationDevicesListItem, ApplicationDevicesList, 'totalCount', 'list'>
          dataId='device-applications-list'
          hasCheckboxes={false}
          hasFilters={false}
          hasSearch
          tableReducerProperties={tableReducerProperties}
          tableReducerFunctions={tableReducerFunctions}
          columns={columns}
          fetcher={fetcher}
          customHeader={false}
          listDataPath='list'
          countDataPath='totalCount'
          options={{ getKey: (item) => `${item.deviceInfo.id}` }}
          translationItems={{
            tableTitle: {
              text: getTitle({ filter, t, translationKey: 'APPLICATION_DEVICES', version: version })
            },
            statusText: {
              key: 'RESULT',
              ns: 'translation'
            },
          }}
          useRequestHook={useWorldRequest}
          additionalHeaderButtons={additionalHeaderButtons}
          initialSearchValue={search}
        />
      </Card>
    </div>
  );
}
