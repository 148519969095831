import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import Card from '../../components/card/card';
import { EulaAndDpaLinks } from './compositions/eulaAndDpaLinks';
import Header from '../../components/section/header';
import Section from '../../components/section/section';
import { useAgreementsContext } from '../../context/agreements';
import { useCurrentWorldContext } from '../../context/currentWorld';
import { ElemezToken } from './compositions/elemezToken';

export const Enrollment = () => {
  const { t } = useTranslation('enrollment');
  const { acceptedEULA, acceptedDPA } = useAgreementsContext();
  const { deviceToken } = useCurrentWorldContext();

  return (
    <div id="enrollment_container" className="row justify-content-center">
      <div style={{ width: '60em' }}>
        <Card>
          <Header title={t(`SECTION_TITLE`)} subtitle={t(`SECTION_SUBTITLE`)} />
          <Section>
            <ElemezToken worldToken={deviceToken} />
            <EulaAndDpaLinks acceptedEULA={acceptedEULA} acceptedDPA={acceptedDPA} />
          </Section>
        </Card>
      </div>
    </div>
  );
};

export default Enrollment;
