import { TOptions } from 'i18next';
import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { Popover } from '../../../components/controls/popover';
import { CustomValidatedInput } from '../../../components/customValidatedInput/customValidatedInput';
import { AlertActive } from './alertActive';

import './thresholdSettings.css';
import { ITranslationKeys } from 'components/i18n/keys';

export interface IntegerProps {
  popoverText: React.ReactNode,
  title: React.ReactNode,
  warning: number,
  problem: number,
  message: React.ReactNode,
  unit: {
    key: string,
    options?: { [key: string]: string | number}
  },
  disabled: boolean,
  className: string,
  maxValue: number,
  alertEnabled: boolean,
  dataUpdated: (data: any, field?: string) => void,
  alertToggled: (data: boolean, initialSettings: { warning: any, problem: any }) => void
}

// When permitting float input we process the value as a string to avoid rounding while the user types
export interface FloatProps extends Omit<IntegerProps, 'warning' | 'problem' | 'dataUpdated'> {
  warning: string,
  problem: string,
  dataUpdated: (timePeriod: string, data?: any, field?: string) => void,
  decimalPlaces: number
}

export function ThresholdSettings(props: IntegerProps | FloatProps) {
  const { t } = useTranslation('settings');
  const [initialWarning] = React.useState<typeof props['warning']>(props.warning);
  const [initialProblem] = React.useState<typeof props['problem']>(props.problem);

  const minWarningValue = 'decimalPlaces' in props ? 1 / (10 ** props.decimalPlaces) : 1;
  const minProblemValue = minWarningValue * 2; // for error text only
  const maxWarningValue = props.maxValue - ('decimalPlaces' in props ? 1 / (10 ** props.decimalPlaces) : 1);

  const checkInputValidity = (value: string): boolean => {
    const matcher = /(\d*)\.(\d*)|(\d*)/;
    if (value.indexOf('.') === -1) {
      return matcher.test(value);
    } else {
      const [_, fraction] = value.split('.');
      return fraction.length <= 3 && matcher.test(value);
    }
  };

  const checkWarningValidity = (value: typeof props['warning']): boolean => {
    if (props.warning !== initialWarning) {
      return value as number >= minWarningValue
        && Number(value) < Number(props.problem)
        && value as number <= maxWarningValue
        && checkInputValidity(String(value));
    }
    return true;
  };

  const checkProblemValidity = (value: typeof props['problem']): boolean => {
    if (props.problem !== initialProblem) {
      return Number(value) > Number(props.warning)
      && value as number <= props.maxValue
      && checkInputValidity(String(value));
    }
    return true;
  };

  function onAlertToggled(value: boolean) {
    props.alertToggled(value, { warning: initialWarning, problem: initialProblem });
  }

  const warningInputProps = {
    validationRule: checkWarningValidity,
    errorText: t('THRESHOLD_WARNING_ERROR', { ns: 'settings', min: minWarningValue, max: maxWarningValue }),
    disabled: props.disabled || !props.alertEnabled
  };
  const warningInput = 'decimalPlaces' in props
    ? <CustomValidatedInput
      value={props.warning}
      onChange={(value: string) => props.dataUpdated(value, 'warning')}
      decimalPlaces={props.decimalPlaces}
      {...warningInputProps}
    />
    : <CustomValidatedInput
      value={props.warning}
      onChange={(value: number) => props.dataUpdated(value, 'warning')}
      {...warningInputProps}
    />;

  const problemInputProps = {
    validationRule: checkProblemValidity,
    errorText: t('THRESHOLD_PROBLEM_ERROR', { ns: 'settings', min: minProblemValue, max: props.maxValue }),
    disabled: props.disabled || !props.alertEnabled
  };
  const problemInput = 'decimalPlaces' in props
    ? <CustomValidatedInput
      value={props.problem}
      onChange={(value: string) => props.dataUpdated(value, 'problem')}
      decimalPlaces={props.decimalPlaces}
      {...problemInputProps}
    />
    : <CustomValidatedInput
      value={props.problem}
      onChange={(value: number) => props.dataUpdated(value, 'problem')}
      {...problemInputProps}
    />;

  return (
    <div className={props.className}>
      <div className="display-thresholds-header">
        <div>
          <h6>{props.title}</h6>
          <Popover
            placement="right"
            id={`popoverDisplayThresholds`}
            className="popoverThresholds"
            target={<i className="threshold-icon fa fa-question-circle" />}
            content={<div className="infoPopover">{props.popoverText}</div>}
            isInsideTable={false}
          />
        </div>
        <AlertActive checked={props.alertEnabled} name={props.className} onChange={onAlertToggled} />
      </div>
      <div className="display-thresholds-row row">
        <div className="col">
          <div className="display-thresholds-section settings warning">
            <span className="status-header warning"><i className="fa fa-exclamation-triangle circle_orange enhanced-warning-icon" /> {t('THRESHOLD_WARNING', { ns: 'settings' })}</span>
            <span className="display-settings-message">{props.message}</span>
            {warningInput}
            <span>{t(props.unit.key as any, { count: Number(props.warning), ...props.unit.options })}</span>
          </div>
          <div className="display-thresholds-section settings problem">
            <span className="status-header problem"><i className="fa fa-times-circle circle_red" /> {t('THRESHOLD_PROBLEM', { ns: 'settings' })}</span>
            <span className="display-settings-message">{props.message}</span>
            {problemInput}
            <span>{t(props.unit.key as keyof ITranslationKeys['settings'], { count: Number(props.problem), ...props.unit.options })}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThresholdSettings;
