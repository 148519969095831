import React, { useState, useEffect } from 'react';
import moment from 'moment';

import i18n from '../../i18n';
import { languagesMap, languages } from './utils';
import { useLanguageAndLocaleContext } from '../../context/languageAndLocale';

interface IProps {
  children?: React.ReactNode
}

const validLocales = languages.map((l) => l.locale);

export const LanguageWrapper = (props: IProps) => {
  const [key, setKey] = useState('');
  const { children } = props;
  const { language, locale } = useLanguageAndLocaleContext();

  useEffect(() => {
    const updateLanguage = async () => {
      if (i18n.language !== language && languagesMap.has(language)) {
        await i18n.changeLanguage(language);
      }

      if (moment.locale() !== locale && validLocales.includes(locale)) {
        // set global moment locale
        moment.locale(locale);
      }
      const currentKey = `${language}_${locale}`;
      if (currentKey !== key) {
        setKey(currentKey);
      }
    };
    updateLanguage();
  }, [language, locale, key]);

  // children are not rendered until key has been set,
  // i.e. until i18n language and moment locale have been set, if required
  if (!key) {
    return null;
  }

  // we change this fragment's key on locale/language change to
  // trigger a remount of the application, in order to e.g.
  // - recreate moment objects with the new locale
  // - re-render charts with the new language
  return <React.Fragment key={key}>{children}</React.Fragment>;
};
