import React, { useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import * as am4charts from '@amcharts/amcharts4/charts';
import { capitalize, isNil } from 'lodash';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Card from '../../../components/card/card';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { Theme } from '../../app/themes';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { getAnomalyDetails, IAnomalyDetails } from '../../../services/core/anomalyDetails';
import { Loading } from '../../../components/loading/loading';
import { StackedOverview } from '../../../components/overview/stackedOverview';
import { NoSelectionOverlay } from '../../../components/card/noSelectionOverlay';
import Spinner from '../../../components/loading/loadingSpinner';
import { formatDateDetected, formatDateLastUpdated, formatTitle } from '../../../components/data-table/dataFormatters';
import { StackedColumnsDateChart } from '../../../components/chart/stackedColumnsDateChart';
import { AnomalyDeviceCountParams, exportAnomalyDeviceChartAction, getAnomalyDeviceCount } from '../../../services/core/anomalyDeviceCount';
import { getUserTimeZone } from '../../../lib/timeZone';
import { useWorldAction } from '../../../lib/useWorldAction';
import { roundToPlaces } from '../../../lib/dataConversion';
import { highlightBeforeFirstDetected } from './highlightBeforeFirstDetected';
import { AlertStatus } from '../../../services/config/alertConstants';
import { InfoPopover } from '../../../components/tooltip/infoPopover';
import { drawXAxisLabelsToToday } from '../../../components/chart/lib';
import { formatTooltipDay } from '../../../components/chart/tooltips';
import { ITranslationKeys } from 'components/i18n/keys';

interface IProps {
  anomalyId: string
}

interface IAnomalyProps {
  anomaly: IAnomalyDetails
}

const Header = styled.div`
  padding-top: 1.5rem;
  padding-left: 2.5rem;
  padding-bottom: 0.75rem;
  font-size: ${({ theme }: { theme: Theme }) => theme.font.sizes.twelvePixels};
`;

const Title = styled.h1`
  font-weight: ${({ theme }: { theme: Theme }) => theme.font.weights.bold};
  font-size: ${({ theme }: { theme: Theme }) => theme.font.sizes.twentyPixels};
`;

const Breadcrumb = styled.div`
  margin-top: 5px;
  color: #707070;
`;

const LinkToAnomalies = styled(Link)`
  padding-right: 0.5em;
  color: ${({ theme }: { theme: Theme }) => theme.links.color};
`;

const OverviewLoading = styled(Loading)`
  height: 200px;
`;

const Overview = styled(StackedOverview)`
  margin: 0.5rem 0;
  & div:nth-of-type(1), div:nth-of-type(2), div:nth-of-type(3)  {
    justify-content: normal;
  }
  & dd {
    padding-left: 0.5rem;
  }
`;

const Section = styled.div<{ bottomBorder?: boolean, topBorder?: boolean }>`
  margin: 1.5rem 2.5rem;
  position: relative;
  ${({ topBorder, bottomBorder }) => topBorder && bottomBorder && `
    margin: 1.5rem 0;
  `}
  ${({ topBorder }) => topBorder && `border-top: 3px solid #edf1f3;`}
  ${({ bottomBorder }) => bottomBorder && `border-bottom: 3px solid #edf1f3;`}
`;

const AnomalyName = styled.div`
  display: inline-block;
  padding-left: 0.5em;
`;

const NoDataOverlay = styled(NoSelectionOverlay)`
  padding: 0;
`;

const formatItemTitle = (title: string) => title + ':';

const StyledDetails = styled.div`
  display: block;
  text-align: left;
`;

StyledDetails.displayName = 'StyledDetails';

const StyledInformation = styled.div`
  padding-bottom: 0.8rem;
   && :first-child {
    padding-right: 0.25rem;
  }
`;

const FinalStatement = styled.div`
  margin-top: 0.5rem;
`;

const Popover = styled(InfoPopover)`
    max-width: 10rem;
`;

StyledInformation.displayName = 'StyledInformation';

export function getAlert(alertType: AlertStatus): keyof ITranslationKeys['anomaly'] {
  let text: keyof ITranslationKeys['anomaly'];
  switch (alertType) {
  case "averageDischargeStatus":
    text = "AVERAGE_DISCHARGE_STATUS";
    break;
  case "deviceLocationAccessPointStatus":
    text = "DEVICE_LOCATION_ACCESS_POINT_STATUS";
    break;
  case "deviceLocationDistanceStatus":
    text = "DEVICE_LOCATION_DISTANCE_STATUS";
    break;
  case "deviceLocationTimeStatus":
    text = "DEVICE_LOCATION_TIME_STATUS";
    break;
  case "deviceTimeErrorStatus":
    text = "DEVICE_TIME_ERROR_STATUS";
    break;
  case "deviceUserExperienceDropStatus":
    text = "DEVICE_USER_EXPERIENCE_DROP_STATUS";
    break;
  case "deviceUserExperienceRebootStatus":
    text = "DEVICE_USER_EXPERIENCE_REBOOT_STATUS";
    break;
  case "deviceUserExperienceLowPowerStatus":
    text = "DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS";
    break;
  case "deviceUtilisationIdleStatus":
    text = "DEVICE_UTILISATION_IDLE_STATUS";
    break;
  case "deviceUtilisationOutOfContactStatus":
    text = "DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS";
    break;
  default:
    text = "DEFAULT_ALERT";
  }
  return text;
}

function cleanPropertyValue(value: string): string {
  if (value === 'true') {
    return '';
  }
  return value;
}

function formatDateValue(timeAgo: string | JSX.Element, date: number) {
  if (!date) return formatDateDetected(date as null);
  const formattedDate = moment.utc(date).format('L');
  return <>{formattedDate} ({timeAgo})</>;
}

function renderProperties(properties: IAnomalyDetails['properties'], t: TTypedTFunction): JSX.Element | null {
  if (!properties) return null;
  const propertiesLeftToAddNumber = properties.length - 1;
  const value = cleanPropertyValue(properties[0].value);
  const firstProperty = `${cleanPropertyName(properties[0].name)}${value ? ` ${value}` : ''}`;
  if (properties.length > 1) {
    return <span><b>{`${t('PROPERTY_other')}:`}</b>{addMoreProperties(firstProperty, propertiesLeftToAddNumber, properties)}</span>;
  } else {
    return <span><b>{`${t('PROPERTY')}:`}</b> {firstProperty}</span>;
  }
}

function cleanPropertyName(propertyName: string): string {
  const prefixMatch = propertyName.match(/^(appversion_|group_|app_)/)?.[0];
  const property = prefixMatch ? propertyName.replace(prefixMatch, prefixMatch.replace('_', ' ')) : propertyName.replace(/[._]/g, ' ');
  return capitalize(property);
}

function addMoreProperties(propertyCompiler: string, propertiesLeftToAddNumber: number, properties: IAnomalyDetails['properties']): string {
  while (propertiesLeftToAddNumber > 0) {
    const value = cleanPropertyValue(properties[propertiesLeftToAddNumber].value);
    propertyCompiler += ` and ${cleanPropertyName(properties[propertiesLeftToAddNumber].name)}${value ? ` ${value}` : ''}`;
    propertiesLeftToAddNumber--;
  }
  return propertyCompiler;
}

function FormattedAnomalyDetails(props: IAnomalyProps): JSX.Element {
  const { anomaly } = props;
  const { t } = useTranslation('anomaly');
  const alert = t(getAlert(`${anomaly?.alertType}`));

  const devicesAlertingRatio = anomaly?.totalAlertingCount !== 0 ? roundToPlaces(anomaly?.population / anomaly?.totalAlertingCount, 2) : 0;
  const devicesAlertingWithPropertyRatio = anomaly?.groupAlertingCount !== 0 ? roundToPlaces(anomaly?.population * anomaly?.shareOfPopulation / anomaly?.groupAlertingCount, 2) : 0;
  const multiplier = devicesAlertingRatio && devicesAlertingWithPropertyRatio ? roundToPlaces(devicesAlertingRatio / devicesAlertingWithPropertyRatio, 1) : null;
  const active = anomaly?.alertType === "deviceUtilisationOutOfContactStatus" ? "" : t('ACTIVE');
  const alerting = devicesAlertingRatio === 0 ? '0' : devicesAlertingRatio === 1 ? t('ALL') : t('ONE_IN_EVERY_STATEMENT', { ratio: devicesAlertingRatio });
  const alertingWithProperties = devicesAlertingWithPropertyRatio === 0 ? '0' : devicesAlertingWithPropertyRatio === 1 ? t('ALL') : t('ONE_IN_EVERY_STATEMENT', { ratio: devicesAlertingWithPropertyRatio });
  const hasAlerting = t('HAS', { count: devicesAlertingRatio === 0 ? 0 : devicesAlertingRatio === 1 ? 2 : 1 });
  const hasAlertingWithProperties = t('HAS', { count: devicesAlertingWithPropertyRatio === 0 ? 0 : devicesAlertingWithPropertyRatio === 1 ? 2 : 1 });

  let multiplierStatement;
  if (anomaly?.detected && multiplier) {
    multiplierStatement = <FinalStatement>{t('MORE_THAN_EXPECTED_STATEMENT', { multiplier, count: anomaly?.properties.length })}</FinalStatement>;
  }
  return (
    <StyledDetails>
      <StyledInformation data-id="last-detected"><b>{`${t('LAST_DETECTED')}:`}<Popover labelText={t('LAST_DETECTED_TOOLTIP_LABEL')}>{t('LAST_DETECTED_TOOLTIP')}</Popover></b>
        {formatDateValue(formatDateLastUpdated(anomaly?.lastDetected.toString()), anomaly?.lastDetected)}
      </StyledInformation>
      <StyledInformation data-id="last-affected"><b>{`${t('LAST_AFFECTED')}:`}<Popover labelText={t('LAST_AFFECTED_TOOLTIP_LABEL')}>{t('LAST_AFFECTED_TOOLTIP')}</Popover></b> {anomaly?.lastAffected}</StyledInformation>
      <StyledInformation data-id="alert-type"><b>{`${t('ALERT_TYPE')}:`}</b> {`${alert}`}</StyledInformation>
      <StyledInformation>{renderProperties(anomaly?.properties, t)}</StyledInformation>
      <div>{alerting} {t('DEVICES_WITH_ALERT_TYPE_STATEMENT', { active, has: hasAlerting })}</div>
      <div>{alertingWithProperties} {t('DEVICES_WITH_PROPERTY_AND_ALERT_TYPE_STATEMENT', { active, has: hasAlertingWithProperties, count: anomaly?.properties.length })}</div>
      {multiplierStatement}
    </StyledDetails>
  );
}

export function AnomalyHistory(props: IProps) {
  const { anomalyId } = props;
  const routes = useWorldRoutes();
  const timeZone = getUserTimeZone();
  const { t } = useTranslation('anomaly');

  const onExportClicked = async (action: (params: AnomalyDeviceCountParams) => Promise<any>): Promise<string> => {
    const params = {
      anomalyId: anomalyId,
      timeZone
    };
    return action(params);
  };

  const exportAnomalyDeviceCountData = useWorldAction(exportAnomalyDeviceChartAction);

  const anomalyDetailsFetcher = useCallback(() => {
    return getAnomalyDetails({ anomalyId: anomalyId });
  }, [anomalyId]);

  const anomalyDeviceCountFetcher = useCallback(() => {
    return getAnomalyDeviceCount({ anomalyId: anomalyId, timeZone: timeZone });
  }, [anomalyId, timeZone]);

  const { data: anomaly, loading } = useWorldRequest(anomalyDetailsFetcher, { initialLoading: true });
  const { data: anomalyResults, loading: chartLoading } = useWorldRequest(anomalyDeviceCountFetcher, { initialLoading: true });

  function doMountChart(chart: am4charts.XYChart) {
    anomaly?.firstDetected && highlightBeforeFirstDetected(chart.xAxes.values[0] as am4charts.CategoryAxis, anomaly?.firstDetected, timeZone);
    return chart;
  }

  return (
    <div data-id="core-anomaly">
      <Card noPadding={true}>
        <Header>
          <Title>
            {t('ANOMALY_HISTORY')}
          </Title>
          <Breadcrumb>
            <LinkToAnomalies to={routes.core.anomalies} aria-label="Link to anomalies list">{t('ANOMALIES')}</LinkToAnomalies>
            <i className="fas fa-chevron-right" />
            <AnomalyName>{t('HISTORY')}</AnomalyName>
          </Breadcrumb>
        </Header>
        <OverviewLoading isLoading={loading || chartLoading} transparentOverlay={false}>
          <Section>
            <NoDataOverlay show={!anomaly} noSelectionText={t('NO_DATA_AVAILABLE', { ns: 'translation' })} />
            <FormattedAnomalyDetails anomaly={anomaly} />
            <Section topBorder={true} bottomBorder={true}>
              <Overview columns={[
                [{
                  dataId: 'firstDetected',
                  label: <>{formatItemTitle(t('FIRST_DETECTED'))}<Popover labelText={t('FIRST_DETECTED_TOOLTIP_LABEL')} placement='right'>{t('FIRST_DETECTED_TOOLTIP')}</Popover></>,
                  value: loading ? <Spinner text='' /> : formatDateValue(formatDateLastUpdated(anomaly?.firstDetected?.toString()), anomaly?.firstDetected)
                }],
                [{
                  dataId: 'highestAffected',
                  label: <>{formatItemTitle(t('HIGHEST_AFFECTED'))}<Popover labelText={t('HIGHEST_AFFECTED_TOOLTIP_LABEL')}>{t('HIGHEST_AFFECTED_TOOLTIP')}</Popover></>,
                  value: loading ? <Spinner text='' /> : anomaly?.highestAffectedCount
                }],
                [{
                  dataId: 'totalAffected',
                  label: <>{formatItemTitle(t('TOTAL_AFFECTED'))}<Popover labelText={t('TOTAL_AFFECTED_TOOLTIP_LABEL')}>{t('TOTAL_AFFECTED_TOOLTIP')}</Popover></>,
                  value: loading ? <Spinner text='' /> : anomaly?.totalAffectedCount
                }],
                [{
                  dataId: 'occurrences',
                  label: <>{formatItemTitle(t('OCCURRENCES'))}<Popover labelText={t('OCCURRENCES_TOOLTIP_LABEL')}>{t('OCCURRENCES_TOOLTIP')}</Popover></>,
                  value: loading ? <Spinner text='' /> : anomaly?.detectedCount
                }]
              ]} />
            </Section>
          </Section>
          <Section>
            <StackedColumnsDateChart
              bottomBorder={false}
              series={[{
                visible: true,
                dataKey: 'significant',
                description: t('DEVICES_AFFECTED_ANOMALY_RAISED', { ns: 'anomaly' }),
                colour: '#990099'
              },
              {
                visible: true,
                dataKey: 'insignificant',
                description: t('DEVICES_AFFECTED_ANOMALY_NOT_RAISED', { ns: 'anomaly' }),
                colour: '#4CA4AE'
              }
              ]}
              cypressId="anomaly-devices-affected-chart"
              data={anomalyResults}
              days={90}
              title={t('ANOMALY_DEVICE_CHART_TITLE')}
              onMount={doMountChart}
              amchartsYAxisNumberFormat="#"
              link={`#${routes.core.anomalyDevices}?anomalyId=${anomaly?.anomalyId}`}
              export={{
                filename: `${formatTitle(t('ANOMALY_DEVICE_CHART_TITLE'))}.${moment.utc().toISOString()}.csv`,
                onExportClicked: () => onExportClicked(exportAnomalyDeviceCountData),
                csv: true
              }}
              drawXAxis={(chart, xAxis) => drawXAxisLabelsToToday(chart, xAxis as am4charts.CategoryAxis, 29, t)}
              formatTooltipDate={formatTooltipDay}
              hasLegend={true}
              onlyShowCurrentSeriesInTooltip={true}
              useAxisTooltip
            />
          </Section>
        </OverviewLoading>
      </Card>
    </div>
  );
}
