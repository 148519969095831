import { DataPoint } from "../../components/intensityMaps/shared/types";
import { getTimePeriodParams } from "../../lib/dateParams";
import { RequestInitWithRetry, request } from "../../lib/request";

export interface IZeroSignalLevelParams {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  boundingLatLong: string,
  geotilePrecision?: number,
  timePeriod: number,
  operatorName?: string
}

export interface IZeroSignalDataPoint {
  geotile?: string,
  totalCount: number | null,
  zeroSignalCount: number | null,
  zeroSignalPercentage: number | null
}

export type ZeroSignalLevelApiResponse = IZeroSignalDataPoint[];

export type ZeroSignalLevelResponse = Array<DataPoint & { totalCount: number, zeroSignalCount: number }>;

export function getZeroSignalLevelIntensity({ timePeriod, ...params }: IZeroSignalLevelParams) {
  return async (options: RequestInitWithRetry): Promise<ZeroSignalLevelResponse> => {
    const { from, to } = getTimePeriodParams(timePeriod);

    const input = {
      path: `/api/core/maps/zeroSignalLevelIntensity`,
      body: {
        ...params,
        from,
        to
      }
    };

    const output = await request<ZeroSignalLevelApiResponse>(input, options);

    return output.map(dataPoint => {
      const { zeroSignalPercentage: percentage, ...rest } = dataPoint;
      return { percentage, ...rest };
    });
  };
}

export interface ISignalLevelGeoBoundsParams {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string
}

export interface IGeoBounds {
  top: number,
  left: number,
  bottom: number,
  right: number
}

export type ISignalLevelGeoBoundsResponse = {
  geoBounds: IGeoBounds
} | null;

export function getSignalLevelGeoBounds(params: ISignalLevelGeoBoundsParams) {
  return async (options: RequestInitWithRetry): Promise<ISignalLevelGeoBoundsResponse> => {
    const input = {
      path: `/api/core/maps/signalLevelGeoBounds`,
      body: {
        ...params
      }
    };

    return request<ISignalLevelGeoBoundsResponse>(input, options);
  };
}

export interface INoServiceParams {
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  boundingLatLong: string,
  geotilePrecision?: number,
  timePeriod: number,
  operatorName?: string
}

interface INoServiceDataPoint {
  geotile?: string,
  totalCount: number,
  noServiceCount: number | null,
  noServicePercentage: number | null
}

export type NoServiceApiResponse = INoServiceDataPoint[];

export type NoServiceResponse = Array<DataPoint & { totalCount: number, noServiceCount: number }>;

export function getNoServiceIntensity({ timePeriod, ...params }: INoServiceParams) {
  return async (options: RequestInitWithRetry): Promise<NoServiceResponse> => {
    const { from, to } = getTimePeriodParams(timePeriod);

    const input = {
      path: `/api/core/maps/noServiceIntensity`,
      body: {
        ...params,
        from,
        to
      }
    };

    const output = await request<NoServiceApiResponse>(input, options);

    return output.map(dataPoint => {
      const { noServicePercentage: percentage, ...rest } = dataPoint;
      return { percentage, ...rest };
    });
  };
}

export interface ICoverageParams {
  boundingLatLong: string,
  geotilePrecision: number,
  timePeriod: number,
  groups?: string[],
  homeLocations?: string[],
  deviceId?: string,
  zoneId?: string,
  operatorName?: string,
  bearerType: string
}


interface ICoverageDataPoint {
  geotile?: string,
  totalCount: number,
  coverageCount: number | null,
  coveragePercentage: number | null
}

export type CoverageApiResponse = ICoverageDataPoint[];

export type CoverageResponse = Array<DataPoint & { totalCount: number, coverageCount: number }>;

export function getCoverageIntensity({ timePeriod, ...params }: ICoverageParams) {
  return async (options: RequestInitWithRetry): Promise<CoverageResponse> => {
    const { from, to } = getTimePeriodParams(timePeriod);

    const input = {
      path: `/api/core/maps/coverageIntensity`,
      body: {
        ...params,
        from,
        to
      }
    };

    const output = await request<CoverageApiResponse>(input, options);

    return output.map(dataPoint => {
      const { coveragePercentage: percentage, ...rest } = dataPoint;
      return { percentage, ...rest };
    });
  };
}

export interface ISimProvidersParams {
  deviceId?: string,
  timePeriod: number
}

export type SimProvidersResponse = string[];

export function getSimProviders({ timePeriod, ...params }: ISimProvidersParams) {
  return async (options: RequestInitWithRetry): Promise<SimProvidersResponse> => {
    const { from, to } = getTimePeriodParams(timePeriod);

    const input = {
      path: `/api/core/maps/simProviders`,
      body: {
        from,
        to,
        ...params,
      }
    };

    const output = await request<SimProvidersResponse>(input, options);

    return output;
  };
}
