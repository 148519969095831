import * as React from 'react';
import { ITabComponentType } from './tab-panel';

export class TabPanelHeader extends React.Component<React.PropsWithChildren> {
  //used for component identification
  public static Tag = ITabComponentType.Header;

  public render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
