import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AddWorld } from './addWorld';
import { Greetings } from '../../components/authentication/greetings';
import { useNonWorldRoutes } from '../../routes';

export function UniverseAdmin() {
  const routes = useNonWorldRoutes();
  return (
    <Greetings title={'Worlds'} worldAware={false} logoLink={routes.worldSelection}>
      <Switch>
        <Route path={`${routes.universeAdmin.worlds.add}`}>
          <AddWorld />
        </Route>
        <Route path={`${routes.universeAdmin.worlds.basePath}`}>
          <Redirect to={`${routes.universeAdmin.worlds.add}`} />
        </Route>
      </Switch>
    </Greetings>
  );
}
