import { useState } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "../../../../lib/usePrevious";

export function useDevicesListQueryString() {
  const { pathname, search: queryString } = useLocation();
  // saved querystring, not changed when query string changes while on a different tab
  const [devicesListQueryString, setDevicesListQueryString] = useState('');

  if (pathname.match(/\/devices$/) && devicesListQueryString !== queryString) {
    setDevicesListQueryString(queryString);
  }

  const prevDevicesListQueryString = usePrevious(devicesListQueryString);

  return { prevDevicesListQueryString, devicesListQueryString };
}
