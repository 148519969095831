import * as am4charts from "@amcharts/amcharts4/charts";

/* istanbul ignore next */
export function addHoverAnimation(series: am4charts.ColumnSeries, chart: am4charts.XYChart) {
  function over() {
    chart.series.each(function (series: any) {
      series.columns.each(function (column: any) {
        if (column.isHover) {
          column.setState('fade');
        }
      });
    });
  }

  function out() {
    chart.series.each(function (series: any) {
      series.columns.each(function (column: any) {
        column.setState('default');
      });
    });
  }

  series.events.on("over", over);
  series.events.on("out", out);

  const onHover = series.columns.template.states.create("fade");
  onHover.properties.fillOpacity = 0.6;
  onHover.properties.strokeOpacity = 0.6;
}
