import * as React from 'react';
import moment from 'moment';
import { get } from 'lodash';

import { metresToLongDistance, getDistanceTranslationContext } from '../../lib/distanceConversion';
import { WorldSettings } from '../../services/config/config';
import { AlarmStatus } from '../../services/config/alertConstants';

import { Trans, TransInterpolation } from '@components/i18n/typedTrans';
import { TTypedTFunction } from '@lib/useTypedTranslation';

export enum ALERT {
  red = 'red',
  yellow = 'yellow',
  green = 'green'
}

export interface AlertsData {
  accessPoint: {
    ssid: string,
    bssid: string,
    updated: string,
    homeLocationId: string,
    homeLocation: {
      name: string
    }
  },
  homeLocation: {
    id: string,
    name: string,
    distanceYellow: number,
    distanceRed: number
  },
  deviceStatus: ALERT,
  deviceLocationStatus: ALERT,
  deviceLocationDistanceStatus: ALERT,
  deviceLocationTimeStatus: ALERT,
  deviceLocationAccessPointStatus: ALERT,
  deviceUtilisationStatus: ALERT,
  deviceUtilisationIdleStatus: ALERT,
  deviceUtilisationOutOfContactStatus: ALERT,
  deviceUserExperienceStatus: ALERT,
  deviceUserExperienceRebootStatus: ALERT,
  deviceUserExperienceLowPowerStatus: ALERT,
  deviceUserExperienceDropStatus: ALERT,
  averageDischargeStatus: ALERT,
  deviceTimeErrorStatus: ALERT,
  averageDischarge: number,
  deviceEventCounts: {
    rebootCount: number,
    lowPowerCount: number,
    dropCount: number
  },
  updated: string
}

export interface IMessage { status: AlarmStatus, message: JSX.Element }

export function getMessages(data: AlertsData, worldSettings: WorldSettings, t: TTypedTFunction): IMessage[] {
  const statusCheck = (status: string) => {
    return (!status || status === ALERT.green);
  };

  const statusAlert = (status: string) => {
    return status === ALERT.red || status === ALERT.yellow;
  };

  const createMessage = (status: AlarmStatus, message: JSX.Element) => {
    return { status, message };
  };

  const getLocationMessages = (data: AlertsData, t: TTypedTFunction) => {
    const messages: IMessage[] = [];
    if (statusAlert(data.deviceLocationDistanceStatus)) {
      const metres = data.deviceLocationDistanceStatus === ALERT.red
        ? data.homeLocation?.distanceRed ?? worldSettings.deviceLocationDistanceRed
        : data.homeLocation?.distanceYellow ?? worldSettings.deviceLocationDistanceYellow;
      const threshold = metresToLongDistance(metres, worldSettings.useMetricDistances);
      const context = getDistanceTranslationContext(worldSettings.useMetricDistances);
      messages.push(createMessage('deviceLocationDistanceStatus', <Trans ns="alerts" i18nKey={"DEVICE_LOCATION_DISTANCE_STATUS" as "DEVICE_LOCATION_DISTANCE_STATUS_metric" | "DEVICE_LOCATION_DISTANCE_STATUS_imperial"} context={context}>This device is over <strong>{{ threshold } as TransInterpolation} [[DISTANCE]]</strong> away from its home location</Trans>));
    }
    if (statusAlert(data.deviceLocationTimeStatus)) {
      const threshold = data.deviceLocationTimeStatus === ALERT.red ? worldSettings.deviceLocationTimeRed : worldSettings.deviceLocationTimeYellow;
      messages.push(createMessage('deviceLocationTimeStatus', <Trans ns="alerts" i18nKey="DEVICE_LOCATION_TIME_STATUS" threshold={threshold}>This device has been away from its home location for over <strong>{{ threshold } as TransInterpolation} hours</strong></Trans>));
    }
    if (statusAlert(data.deviceLocationAccessPointStatus)) {
      messages.push(createMessage('deviceLocationAccessPointStatus', createLocationAccessPointMessage(data.deviceLocationAccessPointStatus, data.accessPoint, data.homeLocation, t)));
    }
    return messages;
  };

  const getUtilisationMessages = (data: AlertsData) => {
    const messages: IMessage[] = [];
    if (statusAlert(data.deviceUtilisationOutOfContactStatus)) {
      messages.push(createMessage('deviceUtilisationOutOfContactStatus', createUtilisationOutOfContactAlertMessage(data.updated)));
    }
    if (statusAlert(data.deviceUtilisationIdleStatus)) {
      const configDaysIdle = data.deviceUtilisationIdleStatus === ALERT.red ? worldSettings.deviceUtilisationIdleRed : worldSettings.deviceUtilisationIdleYellow;
      messages.push(createMessage('deviceUtilisationIdleStatus', createUtilisationIdleAlertMessage(configDaysIdle)));
    }
    return messages;
  };

  const getUserExperienceMessages = (data: AlertsData) => {
    const messages: IMessage[] = [];
    if (statusAlert(data.deviceUserExperienceDropStatus)) {
      messages.push(createMessage('deviceUserExperienceDropStatus', createDropAlertMessage(data, worldSettings)));
    }
    if (statusAlert(data.deviceUserExperienceRebootStatus)) {
      messages.push(createMessage('deviceUserExperienceRebootStatus', createRebootAlertMessage(data, worldSettings)));
    }
    if (statusAlert(data.deviceUserExperienceLowPowerStatus)) {
      messages.push(createMessage('deviceUserExperienceLowPowerStatus', createLowPowerAlertMessage(data, worldSettings)));
    }
    return messages;
  };

  const getNonGroupedMessages = (data: AlertsData) => {
    const messages = [];
    if (statusAlert(data.deviceTimeErrorStatus)) {
      messages.push(createMessage('deviceTimeErrorStatus', <Trans i18nKey="TIME_ERROR_STATUS" ns="alerts">time error status</Trans>));
    }
    if (statusAlert(data.averageDischargeStatus)) {
      const discharge = data.averageDischarge;
      messages.push(createMessage('averageDischargeStatus', <Trans i18nKey="AVG_DISCHARGE_STATUS" ns="alerts" discharge={discharge}>avg <strong>{{ discharge } as TransInterpolation}</strong> status</Trans>));
    }
    return messages;
  };

  if (statusCheck(data.deviceStatus)) {
    return [createMessage('deviceStatus', <Trans i18nKey="NO_ALERTS" ns="alerts">No issues currently detected on this device</Trans>)];
  }
  return [
    ...getNonGroupedMessages(data),
    ...getLocationMessages(data, t),
    ...getUtilisationMessages(data),
    ...getUserExperienceMessages(data),
  ];
}

const createUtilisationOutOfContactAlertMessage = (updated: string) => {
  const lastUpdated = moment(parseInt(updated, 10)).format("L");
  const daysSpentOutOfContact = moment.utc().diff(moment(parseInt(updated, 10)), 'days');
  if (daysSpentOutOfContact === 0) {
    const hoursSpentOutOfContact = moment.utc().diff(moment(parseInt(updated, 10)), 'hours');
    if (hoursSpentOutOfContact === 0) {
      return <Trans ns="alerts" i18nKey="DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_ZERO_STATUS">This device has been <strong>out of contact</strong> for less than an hour</Trans>;
    }
    return <Trans ns="alerts" i18nKey="DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_STATUS" count={hoursSpentOutOfContact}>This device has been <strong>out of contact</strong> for {{ hoursSpentOutOfContact } as TransInterpolation} hour</Trans>;
  }
  return <Trans ns="alerts" i18nKey="DEVICE_UTILISATION_OUT_OF_CONTACT_DAY_STATUS" lastUpdated={lastUpdated} count={daysSpentOutOfContact}>This device has been <strong>out of contact</strong> since {{ lastUpdated } as TransInterpolation} (<strong>{{ daysSpentOutOfContact } as TransInterpolation} day</strong>)</Trans>;
};

const createUtilisationIdleAlertMessage = (configDays: number) => {
  return <Trans ns="alerts" i18nKey="DEVICE_IDLE_DAY_STATUS" count={configDays}>This device has been <strong>idle</strong> for <strong>{{ configDays } as TransInterpolation}</strong> or more <strong>days</strong></Trans>;
};

const getUserExperiencePeriod = (thresholdPeriod: number) => {
  switch (thresholdPeriod) {
  case 1:
    return <strong>24 <Trans ns="alerts" i18nKey="HOURS">hours</Trans></strong>;
  case 2:
    return <strong>48 <Trans ns="alerts" i18nKey="HOURS">hours</Trans></strong>;
  default:
    return <strong><Trans ns="alerts" i18nKey="DAYS" count={thresholdPeriod}>{{ thresholdPeriod } as TransInterpolation} days</Trans></strong>;
  }
};

const createRebootAlertMessage = (data: AlertsData, worldSettings: WorldSettings) => {
  const thresholdPeriod = get(worldSettings, 'deviceUserExperienceRebootPeriod');
  const deviceEvent = data.deviceEventCounts.rebootCount;
  const timePeriod = getUserExperiencePeriod(thresholdPeriod);
  return <span><Trans ns="alerts" i18nKey="DEVICE_REBOOT_STATUS" count={deviceEvent}>This device has been <strong>rebooted {{ deviceEvent } as TransInterpolation} time</strong> during the past </Trans>{timePeriod}</span>;
};

const createDropAlertMessage = (data: AlertsData, worldSettings: WorldSettings) => {
  const thresholdPeriod = get(worldSettings, 'deviceUserExperienceDropStatusPeriod');
  const deviceEvent = data.deviceEventCounts.dropCount;
  const timePeriod = getUserExperiencePeriod(thresholdPeriod);
  return <span><Trans ns="alerts" i18nKey="DEVICE_DROP_STATUS" count={deviceEvent}>This device has been <strong>dropped {{ deviceEvent } as TransInterpolation} time</strong> during the past </Trans>{timePeriod}</span>;
};

const createLowPowerAlertMessage = (data: AlertsData, worldSettings: WorldSettings) => {
  const thresholdPeriod = get(worldSettings, 'deviceUserExperienceLowPowerPeriod');
  const deviceEvent = data.deviceEventCounts.lowPowerCount;
  const timePeriod = getUserExperiencePeriod(thresholdPeriod);
  return <span><Trans ns="alerts" i18nKey="DEVICE_LOW_POWER_STATUS" count={deviceEvent}>This device has had <strong>{{ deviceEvent } as TransInterpolation} low power event</strong> during the past </Trans>{timePeriod}</span>;
};

const createLocationAccessPointMessage = (status: ALERT, data: { ssid: string, bssid: string, updated: string, homeLocation: { name: string } }, homeLocation: { name: string }, t: TTypedTFunction) => {
  const { ssid, bssid } = data;
  const accessPointHomeLocationName = data.homeLocation?.name;
  const homeLocationName = homeLocation?.name || t('LOCATION_UNKNOWN', { ns: 'deviceLocation' });
  switch (status) {
  case ALERT.red:
    return (
      <span>
        {t('THIS_DEVICE_IS', { ns: 'alerts' })}
        <strong> {t('ON_AN_UNKNOWN_NETWORK', { ns: 'alerts' })} </strong>
        {`(${ssid} + ${bssid})`}
      </span>
    );
  case ALERT.yellow:
    return (
      <span>
        {t('THIS_DEVICE_IS', { ns: 'alerts' })}
        <strong> {t('ON_A_KNOWN_NETWORK', { ns: 'alerts' })} </strong>
        {`(${ssid}:${bssid}`}
        {data.homeLocation ?
          <>
            {` `}
            {t('AT_ACCESS_POINT_LOCATION_NAME', { ns: 'alerts' })}
            {` ${accessPointHomeLocationName}) `}
          </>
          : ') ' }
        {t('NOT_BELONGING_TO_HOME_LOCATION', { ns: 'alerts' })}
        {` (${homeLocationName})`}
      </span>
    );
  }
};
