import * as React from 'react';
import './table.css';

const ETableHeader: React.FC<React.PropsWithChildren> = (props) => (
  <div className="eTableHeader oemBg">
    {props.children}
  </div>
);

export default ETableHeader;
