import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { isNil, isEmpty } from 'lodash';

import { Button } from '../buttons/button';
import { ButtonColours } from '../../containers/app/themes';

export interface IProps {
  exportFunction: () => Promise<string>,
  filename: string,
  disabled?: boolean
}

export function ExportButton(props: IProps) {
  const { t } = useTranslation();
  const onExportClicked = async (): Promise<void> => {
    const data = await props.exportFunction();
    const blobData = isNil(data) ? "" : data;
    const blob = new Blob([blobData], { type: "text/comma-separated-values" });
    downloadBlob(props.filename, blob);
  };

  const downloadBlob = (filename: string, blob: Blob) => {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = createLink(blobURL, filename);
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  };

  const createLink = (blobURL: string, filename: string) => {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute('download', filename);
    if (isEmpty(tempLink.download)) {
      tempLink.setAttribute('target', '_blank');
    }
    return tempLink;
  };

  return <Button
    dataId="download-button"
    colour={ButtonColours.grey}
    onClick={onExportClicked}
    text={t('EXPORT')}
    iconBeforeText={true}
    iconStyle="fas fa-download"
    disabled={props?.disabled}
  />;
}
