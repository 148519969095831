import { ISortResult } from "../shared/common";
import { get } from 'lodash';
import { request, RequestInitWithRetry } from "../../lib/request";
import { IApplicationEvent } from "./eventsTypes";
import { ChartTimePeriod } from "containers/core/device/deviceFormat";

export interface IDeviceApplicationEventParams {
  deviceId: string,
  applicationName: string,
  to: number,
  from: number,
  chartTimePeriod?: ChartTimePeriod
}

export interface IDeviceApplicationsOverviewData {
  applicationCount: number,
  installedCount: number,
  updatedCount: number,
  usedCount: number,
  usedDay: number
}

export interface IDeviceApplicationDataParams {
  deviceId: string,
  packageName: string,
  to: number,
  from: number,
  chartTimePeriod?: ChartTimePeriod,
  sharedUserId?: string
}

export interface IApplicationsData {
  id: string,
  name: string,
  sharedUserId: string,
  version: string,
  size: number,
  installed: string,
  updated: string,
  lastUsed: string,
  averageUserActivity: number,
  mobileDataUsage: number,
  wifiDataUsage: number,
  system: boolean,
  packageName: string
}

export interface IListOptions {
  deviceId: string,
  limit: number,
  offset: number,
  sort?: ISortResult,
  search?: string,
  systemFilter?: {
    system: boolean
  }
}

export interface IApplicationListData {
  list: IApplicationsData[],
  totalCount: number
}

export interface IApplicationUsageParams {
  applicationId: string,
  to: number,
  from: number,
  bucketByHour?: boolean
}

interface IApplicationUsage {
  date: number,
  userActivity: number
}

export interface IApplicationUsageData {
  applicationUsageData: IApplicationUsage[],
  applicationName: string
}

export interface IApplicationDataUsage {
  date: number,
  mobile: number,
  wifi: number
}

export const getDeviceApplicationsOverview = (deviceId: string) => {
  return (options: RequestInitWithRetry): Promise<IDeviceApplicationsOverviewData> => {
    const input = {
      path: `/api/core/deviceApplications/overview`,
      qs: { deviceId: deviceId },
      body: {},
      method: 'post'
    };
    return request(input, options);
  };
};

export function getDeviceApplicationsList(options: IListOptions) {
  return async (requestOptions: RequestInitWithRetry): Promise<IApplicationListData> => {
    const input = {
      path: `/api/core/deviceApplications/list`,
      qs: { deviceId: options.deviceId, limit: options.limit, offset: options.offset },
      body: {
        sort: get(options, 'sort'),
        search: get(options, 'search'),
        systemFilter: options.systemFilter
      },
      method: 'post'
    };
    return request(input, requestOptions);
  };
}

export const getDeviceApplicationUsage = (params: IApplicationUsageParams) => {
  return (requestOptions: RequestInitWithRetry): Promise<IApplicationUsageData> => {
    const input = {
      path: '/api/core/deviceApplications/applicationUsage',
      qs: { applicationId: params.applicationId, to: params.to, from: params.from, bucketByHour: params.bucketByHour },
      method: 'post'
    };
    return request(input, requestOptions);
  };
};

export const getDeviceApplicationEvents = (params: IDeviceApplicationEventParams) => {
  return (options: RequestInitWithRetry): Promise<IApplicationEvent[]> => {
    const input = {
      path: `/api/core/deviceApplications/applicationEvents`,
      qs: { deviceId: params.deviceId, applicationName: params.applicationName, to: params.to, from: params.from, chartTimePeriod: params.chartTimePeriod },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getDeviceApplicationDataUsage = (params: IDeviceApplicationDataParams) => {
  return (options: RequestInitWithRetry): Promise<IApplicationDataUsage[]> => {
    const input = {
      path: `/api/core/deviceApplications/applicationDataUsage`,
      qs: { deviceId: params.deviceId },
      body: {
        from: params.from,
        to: params.to,
        packageName: params.packageName,
        sharedUserId: params.sharedUserId,
        chartTimePeriod: params.chartTimePeriod
      },
      method: 'post'
    };
    return request(input, options);
  };
};
