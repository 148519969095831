import * as React from 'react';
import { Map, Marker, TileLayer, Circle, ZoomControl, MarkerProps, CircleProps, Tooltip } from 'react-leaflet';
import { divIcon, latLng, latLngBounds } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { split, flatMap, map, isEqual } from 'lodash';
import Control from 'react-leaflet-control';

import { NoSelectionOverlay } from '../card/noSelectionOverlay';

import './detailsMap.css';

import strokeSvg from './pins/stroke.svg';

export interface IRadius {
  size: number,
  colour: string
}

export interface IIcon {
  style: string,
  size: any
}

interface IState {
  clickedItemId: string
}

interface IProps {
  id: string,
  overlayText?: string,
  items: IMapItem[],
  visible: boolean,
  zoomScale?: number
}

export interface IMapItem {
  position: string,
  icon?: IIcon,
  radius: IRadius,
  label?: string | JSX.Element,
  styleClass?: string,
  showOnlyCircleOnZoom?: boolean,
  showLabelOnHover?: boolean,
  animateOnClick?: boolean,
  disabled?: boolean,
  onClick?: (item: IMapItem) => void,
  selected?: boolean,
  zoomTo?: boolean
}

export class DetailsMap extends React.Component<IProps, IState> {

  private locationRef: any;

  private timeoutId: any;

  constructor(props: any) {
    super(props);
    this.locationRef = React.createRef();
    this.createPosition = this.createPosition.bind(this);
    this.getMapBounds = this.getMapBounds.bind(this);
    this.buildMarker = this.buildMarker.bind(this);
    this.zoomlevelschange = this.zoomlevelschange.bind(this);
    this.getItemsZoom = this.getItemsZoom.bind(this);
    this.state = { clickedItemId: undefined };
  }

  public componentDidMount() {
    if (this.props.visible && this.props.items?.length) {
      this.locationRef.current.leafletElement.fitBounds(this.getMapBounds(), { padding: [20, 20] });
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    this.updateMap();
    if (prevProps.items?.length !== this.props.items?.length) {
      this.locationRef.current.leafletElement.fitBounds(this.getMapBounds(), { padding: [20, 20] });
    }
    const clickedItemIndex = this.props.items.findIndex((item) => item.selected);

    const clickedItemId = clickedItemIndex === -1 ? undefined : clickedItemIndex.toString();
    if (clickedItemId !== this.state.clickedItemId) {
      this.setState({ clickedItemId });
    }

    const zoomItemIndex = this.props.items.findIndex((item) => item.selected && item.zoomTo);
    if (zoomItemIndex !== -1) {
      const item = this.props.items[zoomItemIndex];
      this.zoomToItem(item);
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  public updateMap = () => {
    this.locationRef.current.leafletElement.invalidateSize();
  };

  public createPosition = (position: string): [number, number] => {
    const defaultPosition: any = [0, 0];
    return position ? split(position, ',', 2).map(Number) : defaultPosition;
  };

  public getMapCenter() {
    return this.getMapBounds().getCenter();
  }

  public getMapBounds() {
    const items = this.props.items;

    const getBoundLatLngs = (item: string, scale: number) => {
      const position = this.createPosition(item);
      return [latLng(position[0] + scale, position[1] + scale), latLng(position[0], position[1]), latLng(position[0] - scale, position[1] - scale)];
    };

    if (items) {
      if (items.length > 1) {
        const data = flatMap(items, i => {
          return getBoundLatLngs(i.position, 0.0001);
        });

        return latLngBounds(data);
      } else if (items.length > 0) {
        return latLngBounds(getBoundLatLngs(items[0].position, 0.1));
      }
    }

    return latLngBounds([latLng(-1, -10), latLng(1, 10)]);
  }

  public zoomlevelschange(event: any) {
    this.forceUpdate();
  }

  public getMapZoom() {
    return (this.locationRef.current && this.locationRef.current.leafletElement.getZoom()) || 11;
  }

  public getItemsZoom() {
    const mapBounds = this.getMapBounds();
    const distance = Math.sqrt(Math.pow(mapBounds.getNorthWest().lng - mapBounds.getSouthEast().lng, 2) + Math.pow(mapBounds.getNorthWest().lat - mapBounds.getSouthEast().lat, 2));
    return this.getPointLevel(distance * 1.2);
  }

  public getAdjustedZoom(zoom: number, adjust: number) {
    return Math.min(19, Math.max(0, zoom + adjust));
  }

  public shouldDrawCircle(item: IMapItem) {
    const dist = item.radius.size / 111111;
    const radiusLevel = this.getAdjustedZoom(this.getPointLevel(dist), -3);
    return this.getMapZoom() >= radiusLevel;
  }

  public zoomToMarker(ref: any, pos: [number, number], scale: number) {
    ref.current.leafletElement.fitBounds([[pos[0] + scale, pos[1] + scale], [pos[0] - scale, pos[1] - scale]]);
  }

  public zoomToItem(item: IMapItem) {
    const dist = item.radius.size / 111111;
    const position = this.createPosition(item.position);
    this.zoomToMarker(this.locationRef, position, (this.props.zoomScale || 2) * dist);
  }

  public getPointLevel(radius: number) {
    if (radius < 0.0005) { return 19; }
    if (radius < 0.001) { return 18; }
    if (radius < 0.003) { return 17; }
    if (radius < 0.005) { return 16; }
    if (radius < 0.011) { return 15; }
    if (radius < 0.022) { return 14; }
    if (radius < 0.044) { return 13; }
    if (radius < 0.088) { return 12; }
    if (radius < 0.176) { return 11; }
    if (radius < 0.352) { return 10; }
    if (radius < 0.703) { return 9; }
    if (radius < 1.406) { return 8; }
    if (radius < 2.813) { return 7; }
    if (radius < 5.625) { return 6; }
    if (radius < 11.25) { return 5; }
    if (radius < 22.5) { return 4; }
    return 0;
  }

  public render() {
    const markers = map(this.props.items, this.buildMarker);
    const knownLocation = (this.props.items && this.props.items.length > 0) ? false : true;
    const controls = (this.props.items && this.props.items.length > 0) ? (
      <React.Fragment>
        <ZoomControl position='topright' />
        <Control position="topright">
          <i className="mapResetButton fas fa-undo-alt" onClick={() => this.locationRef.current.leafletElement.fitBounds(this.getMapBounds(), { padding: [20, 20] })} />
        </Control>
      </React.Fragment>) : "";
    return (
      <React.Fragment key={this.props.id}>

        {this.props.overlayText && <NoSelectionOverlay noSelectionText={this.props.overlayText} show={knownLocation} />}

        <div className="leaflet-container">
          <Map ref={this.locationRef}
            zoom={this.getItemsZoom()}
            center={this.getMapCenter()}
            onZoomend={this.zoomlevelschange}
            zoomControl={false}
          >
            {controls}
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            {markers}
          </Map>
          <svg xmlns="w3.org/2000/svg" version="1.1">
            <defs>
              <pattern id="pattern-stroke" patternUnits="userSpaceOnUse" width="7" height="7" patternTransform="rotate(45)">
                <rect x="0" y="0" width="7" height="7" fill="#ffffff" stroke="none" strokeWidth="3" />
                <line x1="0" y="0" x2="0" y2="7" stroke="#000000" strokeWidth="3" />
              </pattern>
            </defs>
          </svg>
        </div>
      </React.Fragment>);
  }

  private buildMarker(mapItem: IMapItem, i: number) {
    const id = i.toString();
    const shouldDrawCircle = this.shouldDrawCircle(mapItem);

    const position = this.createPosition(mapItem.position);
    const getBuiltIcon = () => {
      return shouldDrawCircle ?
        divIcon({ iconSize: mapItem.icon?.size, html: renderToStaticMarkup(<i className={mapItem.icon?.style} />) }) :
        divIcon({
          iconSize: [28, 40],
          iconAnchor: [14, 36],
          html: renderToStaticMarkup(<div className={'map-icon ' + mapItem.styleClass + `${this.state.clickedItemId === id ? ' animated-marker' : ''}`}>{mapItem.icon && <i className={mapItem.icon?.style} />}</div>)
        });
    };

    const builtLabel = mapItem.label ? <Tooltip>{mapItem.label}</Tooltip> : "";
    const builtCircle = shouldDrawCircle ? <Circle<{ id: string } & CircleProps>
      onclick={() => {
        if (mapItem.disabled) return;
        mapItem.onClick && mapItem.onClick(mapItem);
      }}
      onmouseover={(event) => { mapItem.showLabelOnHover && this.state.clickedItemId !== id && event.target.openPopup(); }}
      onmouseout={(event) => { mapItem.showLabelOnHover && event.target.closePopup(); }}
      id={'map_marker_circle_' + id}
      className={mapItem.styleClass}
      center={position}
      weight={2}
      opacity={0.6}
      color={mapItem.radius.colour}
      fillColor={mapItem.radius.colour}
      radius={mapItem.radius.size || 1}>{builtLabel}</Circle> : "";

    return <React.Fragment key={"map_marker_" + id + this.state.clickedItemId}>
      {!mapItem.showOnlyCircleOnZoom || !shouldDrawCircle ? <Marker<{ id: string } & MarkerProps>
        id={'map_marker_' + id}
        position={position}
        icon={getBuiltIcon()}
        onclick={() => {
          if (mapItem.disabled) return;
          mapItem.onClick && mapItem.onClick(mapItem);
          clearTimeout(this.timeoutId);
          if (!this.shouldDrawCircle(mapItem)) {
            this.timeoutId = setTimeout(() => this.zoomToItem(mapItem), 100);
          }
        }}
        onmouseover={(event) => { mapItem.showLabelOnHover && this.state.clickedItemId !== id && event.target.openPopup(); }}
        onmouseout={(event) => { mapItem.showLabelOnHover && event.target.closePopup(); }}
      >
        {builtLabel}
        {builtCircle}
      </Marker> : builtCircle}
    </React.Fragment>;
  }
}
