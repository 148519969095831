import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

interface Props {
  classes?: string | string[],
  text?: string,
  dataId?: string,
  className?: string
}

export const Spinner = (props: Props) => {
  const { t } = useTranslation('translation');
  const classes = [].concat(props.classes).join(' ');
  const text = props.text === undefined ? <>&nbsp; {t('LOADING')}</> : props.text;
  return (
    <div className={`${classes} ${props.className}`} data-id={props.dataId || 'loadingSpinner'}>
      <i className="fas fa-spinner fa-spin" />
      {text}
    </div>
  );
};

export default Spinner;
