import * as React from 'react';
import { useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { isNumber } from 'lodash';

import { Loading } from '../loading/loading';
import { DataTable } from '../data-table/dataTable';
import { Dispatchers, ITableState } from '../data-table/lib';
import { PaginationBar } from '../data-table/pagination';
import { Search } from '../controls/search';
import { StatusText } from '../table/statusText';
import { FilterStatus, convertFilterToStatus } from '../filters/filterStatus';
import { removeFilterItem } from '../filters/filterLib';
import { devicesFilterClasses } from '../filters/devicesFilterLib';
import { ITableColumn } from '../data-table/types';
import { PageHeader } from '../../layout/body/pageHeader';
import { ButtonColours } from '../../containers/app/themes';
import { Button } from '../buttons/button';
import { RequestInitWithRetry } from '../../lib/request';
import { UseRequestOptions, UseRequestReturn } from '../../lib/useRequest';
import { ITranslationKeys } from 'components/i18n/keys';

const LoadingContainer = styled(Loading)`
  height: 20vh;
`;

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

type Translation = {
  key: string,
  ns: string
};

type PreTranslated = {
  text: string
};

type TranslationKeys = Translation | PreTranslated;

export type AdditionalItems = {
  Component: (props: any) => JSX.Element,
  props: any
}[];

export type ListProps<TableData, ListData, CountKey, ListKey, IdKey> = {
  dataId: string,
  hasCheckboxes: boolean,
  hasFilters: boolean,
  hasSearch: boolean,
  tableReducerProperties: ITableState<TableData>,
  tableReducerFunctions: Dispatchers<TableData>,
  translationItems: {
    statusText: TranslationKeys,
    tableTitle?: TranslationKeys
  },
  columns: ITableColumn[],
  fetcher: () => (options: RequestInitWithRetry) => Promise<ListData>,
  onListFetched?: (data: ListData) => void,
  useRequestHook: (
    getFetcher: () => (options: RequestInitWithRetry) => Promise<ListData>,
    options: UseRequestOptions<ListData>,
    worldId?: string | false
  ) => UseRequestReturn<ListData>,
  customHeader: boolean,
  loading?: boolean,
  dataTableEnabled?: boolean,
  noDataTable?: React.ReactNode,
  subHeader?: React.ReactNode,
  countDataPath?: CountKey,
  total?: number,
  listDataPath?: ListKey,
  initialData?: ListData,
  onDataLoaded?: () => void,
  setShowFilterSelection?: (showFilter: boolean) => void,
  options?: {
    rowIdDataPath?: IdKey,
    getKey?: (data: TableData) => string,
    rowNameDataPath?: string
  },
  toggleSort?: boolean,
  t?: TTypedTFunction,
  additionalHeaderItems?: AdditionalItems,
  additionalHeaderButtons?: AdditionalItems,
  selectedRowIndex?: number,
  onRowSelected?: (rowIndex: number) => void,
  selectedRow?: number,
  initialSearchValue?: string,
  highlight?: boolean,
  listNotVisible?: boolean,
  additionalHeaderItemsVisible?: boolean
};

export function List<
  TableData,
  ListData,
  CountKey extends keyof ListData | void = void,
  ListKey extends keyof ListData | void = void,
  IdKey extends keyof TableData | void = void
>(props: ListProps<TableData, ListData, CountKey, ListKey, IdKey>) {
  const tableTitle = props.translationItems.tableTitle;
  const status = props.translationItems.statusText;
  const { additionalHeaderItemsVisible = true } = props;
  const [stateListNotVisible, setStateListNotVisible] = React.useState(false);
  React.useEffect(() => {
    setStateListNotVisible(props.listNotVisible);
  }, [props.listNotVisible]);
  function isTranslated(term: TranslationKeys): term is PreTranslated {
    return (term as PreTranslated)?.text !== undefined;
  }
  function isTranslation(term: TranslationKeys): term is Translation {
    return (term as Translation)?.key !== undefined;
  }
  const { initialData, useRequestHook, fetcher, onDataLoaded: propsOnDataLoaded, countDataPath, listDataPath } = props;

  const nameSpaces = isTranslation(status) ? [status?.ns] : [];

  if (isTranslation(tableTitle) && tableTitle?.ns) {
    nameSpaces.push(tableTitle?.ns);
  }

  const { t } = useTranslation(nameSpaces as any as (keyof ITranslationKeys)[]);

  const [
    { offset, limit, total, sort, dataRequestId, search, filter, data, isLoading, checkedRows },
    { onSort, onSearch, onFilter, onPageChange, onPageSizeChange, onDataLoaded, onRowChecked, onCheckAll }
  ] = [props.tableReducerProperties, props.tableReducerFunctions];

  const onListDataFetched = useCallback((result: any) => {
    onDataLoaded(listDataPath ? result[listDataPath] : result, props.total ? undefined : result[countDataPath], dataRequestId);
    propsOnDataLoaded && propsOnDataLoaded();
  }, [onDataLoaded, propsOnDataLoaded, countDataPath, dataRequestId, listDataPath, props.total]);

  useRequestHook(fetcher, { initialData: initialData, onSuccess: props.onListFetched || onListDataFetched });
  const totalCount = isNumber(props.total) ? props.total : total;

  return (
    <div>
      <div data-id={props.dataId}>
        {!props.customHeader && <div data-id={"header"}>
          <Header noBorder={true} header={isTranslated(tableTitle) ? tableTitle.text : t<any, any>(tableTitle?.key, { ns: tableTitle?.ns })} subHeader={props.subHeader}>
            <HeaderControls data-id={"header_controls"}>
              {additionalHeaderItemsVisible && props.additionalHeaderItems?.map((item, index) => {
                return <item.Component key={`list-header-button-${index}`} {...item.props} />;
              })}
              {props.hasSearch && <Search
                appliedSearch={props.initialSearchValue}
                dataId={props.dataId}
                inputProps={{ placeholder: t('SEARCH', { ns: 'translation' }) }}
                onClicked={onSearch}
              />}
              {props.hasFilters && <Button
                colour={ButtonColours.grey}
                dataId="header-control-filter-button"
                onClick={() => props.setShowFilterSelection(true)}
                text={t('FILTER', { ns: 'translation' })}
                iconBeforeText={true}
                iconStyle="fas fa-sliders-h"
              />}
              {props.additionalHeaderButtons?.map((button, index) => {
                return <button.Component key={`list-header-button-${index}`} {...button.props} />;
              })}
            </HeaderControls>
          </Header>
        </div>}
        {props.hasFilters && <FilterStatus
          selectedFilters={convertFilterToStatus(filter)}
          filterClasses={devicesFilterClasses}
          onFilterRemoved={(id, item) => onFilter(removeFilterItem(filter, { group: item.group, id }))}
          onClearFilters={() => onFilter({})}
          onEditFilters={() => props.setShowFilterSelection(true)}
        />}
        {!stateListNotVisible && <div className="table_part">
          <LoadingContainer isLoading={props.loading || isLoading} transparentOverlay={false} hideChildren={true}>
            {props.dataTableEnabled !== false ? <DataTable<TableData, IdKey>
              data={data}
              sortDirection={sort?.direction}
              sortedColumnIndex={sort?.column}
              options={props.options}
              checkedRows={checkedRows}
              onSort={onSort}
              onCheck={props.hasCheckboxes && onRowChecked}
              onCheckAll={props.hasCheckboxes && onCheckAll}
              columns={props.columns}
              t={props.t}
              toggleSort={props.toggleSort}
              onRowSelected={props.onRowSelected}
              selectedRow={props.selectedRow}
              highlight={props.highlight}
              isLoading={props.loading || isLoading}
            /> : props.noDataTable}
            {!(props.loading || isLoading) && <PaginationBar
              offset={offset}
              limit={limit}
              total={totalCount}
              onNewPage={onPageChange}
              onNewLimit={onPageSizeChange}
            >
              <StatusText
                searchString={search}
                total={totalCount}
                offset={offset}
                limit={limit}
                item={isTranslated(status) ? status.text.toLowerCase() : t<any, any>(status.key, { ns: status.ns, count: totalCount }).toLowerCase()}
              />
            </PaginationBar>}
          </LoadingContainer>
        </div>}
      </div >
    </div>
  );
}

const Header = styled(PageHeader)`
  flex-wrap: wrap;
`;
