import { intersection } from "lodash";
import { ContextProductsData } from "../../context/products";
import { Feature, productNames } from "../app/productsWrapper";
import { BatterySettingsLicense } from "./compositions/battery/batterySettings";
import { SettingName, settingNames } from "./settingNames";

export const dependencies = {
  getBatterySettingsLicense
};

export function getEnabledSettings(products: ContextProductsData): SettingName[] {
  const enabledSettings: SettingName[] = [];
  if (dependencies.getBatterySettingsLicense(products) !== BatterySettingsLicense.none) {
    enabledSettings.push(settingNames.battery);
  }

  if (products[productNames.coreV2].enabled) {
    enabledSettings.push(
      settingNames.alarms,
      settingNames.location,
      settingNames.userExperience,
      settingNames.utilisation,
      settingNames.devices,
      settingNames.platform
    );
  }

  if (products[productNames.coreV2].enabled) {
    enabledSettings.push(
      settingNames.anomalies
    );
  }

  return enabledSettings;
}

export function getBatterySettingsLicense(products: ContextProductsData): BatterySettingsLicense {
  const enabledBatteryFeatures = products[productNames.batteryEssentials].enabledFeatures;

  const batteryEnabled = intersection([
    Feature.batteryEssentials,
    Feature.panasonicSmartBatteryMonitor
  ], enabledBatteryFeatures).length === 1;

  const deviceEnabled = intersection([
    Feature.batteryEssentialsDevices,
    Feature.panasonicSmartBatteryMonitorDevices
  ], enabledBatteryFeatures).length === 1;

  if (batteryEnabled && deviceEnabled) {
    return BatterySettingsLicense.batteryAndDevices;
  }

  if (batteryEnabled) {
    return BatterySettingsLicense.onlyBattery;
  }

  if (deviceEnabled) {
    return BatterySettingsLicense.onlyDevices;
  }

  return BatterySettingsLicense.none;
}
