import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { mapValues, pick } from 'lodash';

import { SettingsTable } from '../../../../components/settings/settings-table';
import { BatteryTypeSettings } from '../../../../services/config/config';

import '../settings.css';

export interface IProps {
  batteryTypeSettings: BatteryTypeSettings[],
  submitInProgress: boolean,
  timeStamp: number,
  alertEnabled: boolean,
  onDataChanged: (data: BatteryTypeSettings[]) => void
}

export function CycleCountSettings(props: IProps) {
  const { t } = useTranslation('settings');

  const onDataChanged = (data: any[]) => {
    const batteryTypeSettings = data.map(obj => {
      return mapValues(pick(obj, ['batteryType', 'cycleCountYellow', 'cycleCountRed']), value => isNaN(value) ? value : parseInt(value));
    });
    props.onDataChanged(batteryTypeSettings);
  };

  return (
    <div className="cycle-count">
      <div className='title-header'>{t('BATTERY_CYCLE_COUNT_TITLE')}</div>
      <div className="settings-group">
        <SettingsTable
          key={props.timeStamp}
          data={addFieldsToBatteryTypeSettings(props.batteryTypeSettings)}
          dataUpdated={onDataChanged}
          disabled={props.submitInProgress || !props.alertEnabled}
          type='settings'
          tableId='CycleCountThresholds'
          thresholdPrecedence='higher'
          definition={{
            dataPaths: ['manufacturer', 'partNumber', 'cycleCountYellow', 'cycleCountRed'],
            columns: [
              {
                type: 'text',
                header: <span className="threshold_header manufacturer">{t('BATTERY_CYCLE_COUNT_MANUFACTURER')}</span>
              },
              {
                type: 'text',
                header: <span className="threshold_header part-number">{t('BATTERY_CYCLE_COUNT_PART')}</span>
              },
              {
                type: 'warning',
                limit: { lower: 1, higher: 9998 },
                header: <span className="threshold_header warning"><i className="fa fa-exclamation-triangle circle_orange enhanced-warning-icon" />{t('BATTERY_WARNING_HEADER')}</span>,
                errorMessage: t('BATTERY_CYCLE_COUNT_WARNING_ERROR')
              },
              {
                type: 'replace',
                limit: { lower: 2, higher: 9999 },
                header: <span className="threshold_header problem"><i className="fa fa-times-circle circle_red" />{t('BATTERY_REPLACE_HEADER')}</span>,
                errorMessage: t('BATTERY_CYCLE_COUNT_REPLACE_ERROR')
              },
            ]
          }}
        />
      </div>
    </div>
  );
}

export function addFieldsToBatteryTypeSettings(data: BatteryTypeSettings[]) {
  return data.map(object => {
    const batteryType = object.batteryType.split(":");
    return { ...object, manufacturer: batteryType[0], partNumber: batteryType[1] };
  });
}
