import * as React from 'react';
import { useCallback } from 'react';
import { InitialTableState, useTableReducer } from '../../../../components/data-table/lib';
import { List, AdditionalItems } from '../../../../components/list/list';
import { Application, ApplicationsList as ApplicationsListData, ApplicationsListAndCount, exportApplicationsListAction, getApplicationsList } from '../../../../services/core/applications';
import { useUserSettingsContext } from '../../../../context/userSettings';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { useApplicationsListColumns } from './useApplicationsListColumns';
import { useWorldAction } from '../../../../lib/useWorldAction';
import { ExportButton } from '../../../../components/controls/exportButton';
import { SystemFilter } from '../../../../components/systemFilter/systemFilter';
import useNormalizedHeaderButtons from '../../../../components/controls/normalizedHeaderButtons';

const tableName = 'applicationsList';
const defaultPageSize = 20;

export function ApplicationsList() {
  const [systemFilter, setSystemFilter] = React.useState<{ system: boolean }>(undefined);
  const { tablePageSizes } = useUserSettingsContext();

  const columns = useApplicationsListColumns();

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 6, direction: 'desc', field: 'lastUsed' }
  };
  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<Application>(tableName, initialTableState);
  const { offset, limit, sort, search } = tableReducerProperties;
  const { onLoading } = tableReducerFunctions;

  const exportApplicationsList = useWorldAction(exportApplicationsListAction);
  const onExportClicked = async (): Promise<string> => {
    return exportApplicationsList({ sort: { field: sort.field, order: sort.direction }, search, systemFilter });
  };

  const translationItems = {
    statusText: {
      key: 'APPLICATION',
      ns: 'applications'
    },
    tableTitle: {
      key: 'APPLICATIONS',
      ns: 'translation'
    }
  };

  const additionalHeaderButtons = useNormalizedHeaderButtons({
    export: {
      exportFunction: onExportClicked,
      filename: 'applicationsList.csv'
    }
  });

  const listFetcher = useCallback(() => {
    return getApplicationsList({ limit, offset, sort: { field: sort.field, order: sort.direction }, search, systemFilter });
  }, [offset, limit, sort, search, systemFilter]);

  return (
    <div>
      <div className='core-application-list'>
        <List<Application, ApplicationsListAndCount, 'totalCount', 'list', 'id'>
          dataId='core-applications-list'
          customHeader={false}
          additionalHeaderButtons={additionalHeaderButtons}
          countDataPath='totalCount'
          listDataPath='list'
          hasCheckboxes={false}
          hasFilters={false}
          hasSearch
          translationItems={translationItems}
          columns={columns}
          fetcher={listFetcher}
          tableReducerFunctions={tableReducerFunctions}
          tableReducerProperties={tableReducerProperties}
          useRequestHook={useWorldRequest}
          options={{ rowIdDataPath: 'id' }}
          additionalHeaderItems={[{
            Component: SystemFilter,
            props: {
              systemFilter,
              setSystemFilter: (value?: { system: boolean }) => { onLoading(); setSystemFilter(value); }
            }
          }]}
        />
      </div>
    </div>
  );
}
