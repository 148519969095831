import * as React from 'react';

import './style.css';

interface ISettingsSectionProps {
  title?: string
}

const Section: React.FC<React.PropsWithChildren<ISettingsSectionProps>> = (props) => {
  return (
    <div className="section_container">
      <div className="section_title">
        {props.title}
        <hr className="section_line" />
      </div>
      <div className="section_body">
        {props.children}
      </div>
    </div>
  );
};

export default Section;
