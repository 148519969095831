import { request, RequestInitWithRetry } from '../../../lib/request';
import { ISortResult, VIEW } from '../common';
import { IFilterResult } from '../../../components/filters/filterSelectionControl';
import { get } from 'lodash';

export interface IDevice {
  id: string,
  imei?: string,
  serialNumber?: string,
  model: string,
  manufacturer: string,
  updated?: string,
  assetTag?: string,
  averageDischargeStatus: string,
  smartBatteries: {
    id: string,
    partNumber: string,
    serialNumber: string,
    capacityFactor: number,
    cycleCount: number,
    batteryStatus: string
  }[]
}

export interface IDeviceParams {
  statusType: string,
  limit: number,
  offset: number,
  groups?: string[],
  sort?: ISortResult,
  search?: string,
  pinnedId?: string
}

export interface IDeviceWithDischargeStatus {
  id: string,
  manufacturer: string,
  model: string,
  imei?: string,
  serialNumber?: string,
  assetTag?: string,
  updated?: string,
  group?: string[],
  averageDischarge?: number,
  averageDischargeStatus?: string
}

export interface IDevicesWithCount {
  yellow: number,
  green: number,
  all: number
}

export class BatteryDevicesService {
  public getList = (options: RequestInitWithRetry) => async (params: IDeviceParams): Promise<IDeviceWithDischargeStatus[]> => {
    const input = {
      path: `/api/batteryEssentials/getDevicesWithDischargeStatus`,
      qs: { statusType: params.statusType === VIEW.all ? undefined : params.statusType, limit: params.limit, offset: params.offset, pinnedId: get(params, 'pinnedId') },
      body: {
        groups: get(params, 'groups'),
        sort: get(params, 'sort'),
        search: get(params, 'search')
      },
      method: 'post'
    };

    const result = await request(input, options);
    return result.devices.nodes;
  };

  public exportList = (options: RequestInitWithRetry) => async (params: IDeviceParams): Promise<any> => {
    const input = {
      path: `/api/batteryEssentials/exportDevicesWithDischargeStatus`,
      qs: { statusType: params.statusType === VIEW.all ? undefined : params.statusType, limit: params.limit, offset: params.offset },
      body: {
        groups: get(params, 'groups'),
        search: get(params, 'search')
      },
      method: 'post'
    };

    return await request(input, options);
  };

  public getDeviceEstateData = (options: RequestInitWithRetry) => async (search?: string, filters?: IFilterResult): Promise<IDevicesWithCount> => {
    const input = {
      path: `/api/batteryEssentials/getDevicesWithDischargeCount`,
      body: {
        groups: get(filters, 'groups'),
        search: search || undefined
      },
      method: 'post'
    };

    return await request(input, options);
  };

  public getDeviceDetails = (options: RequestInitWithRetry) => async (deviceId: string): Promise<IDevice> => {
    const input = {
      path: `/api/batteryEssentials/getDeviceWithDischargeStatus`,
      qs: { deviceId },
      method: 'get'
    };

    return await request(input, options);
  };
}
