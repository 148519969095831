import styled from 'styled-components';
import React, { useContext } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4charts from '@amcharts/amcharts4/charts';
import { range } from 'lodash';

import { IProcessUsageData } from '../../../../../services/core/deviceProcesses';
import { CloseButton } from '../../../../../components/controls/closeButton';
import { createYAxis } from '../../../../../lib/usageChartYAxis';
import { StackedColumnsDateChart } from '../../../../../components/chart/stackedColumnsDateChart';
import { ChipButton } from '../../../../../components/controls/chipButton';
import { DeviceInfoContext } from '../../index';
import { IDeviceInfo } from '../../../../../services/core/devices';
import { highlightDays } from '../../../../../components/chart/highlightDays';

import './30DayProcessUsage.css';
import { ChartTimePeriod, formatDurationData, formatTooltipDate } from '../../deviceFormat';

interface IProps {
  data: IProcessUsageData,
  handleClose: () => void,
  chartTimePeriod: ChartTimePeriod,
  setChartTimePeriod: (chartTimePeriod: ChartTimePeriod) => void,
  setSeries: (series: Series) => void,
  series: Series
}

export enum Series {
  usage = 'usage',
  cpu = 'cpu'
}

const days = {
  [ChartTimePeriod.last30Days]: 30,
  [ChartTimePeriod.last7Days]: 7,
  [ChartTimePeriod.last48Hours]: 2
};

export function ProcessUsage30Days(props: IProps) {
  const timeZoneOffset = useContext(DeviceInfoContext).timeZoneOffset || 0;
  const { t } = useTranslation(['translation', 'performance', 'deviceProcesses', 'timeState']);
  const timePeriodTranslation = props.chartTimePeriod === ChartTimePeriod.last48Hours ? t('OVER_THE_PAST_X_HOURS', { count: 48, ns: 'timeState' }) : t('OVER_THE_PAST_X_DAYS', { count: days[props.chartTimePeriod], ns: 'timeState' });
  const dailyOrHourlyTranslationContext = props.chartTimePeriod === ChartTimePeriod.last30Days ? 'daily' : 'hourly';
  return (
    <div className="process-usage-chart">
      <CloseButton handleClose={props.handleClose} className='process-usage-chart-close' />
      <div className="core-device_process-30-day-process-usage">
        <StackedColumnsDateChart<any>
          data={props.data.processUsageData.map((data) => ({ ...data, userActivity: data.userActivity || 0, cpuActivity: data.cpuActivity || 0, date: data.date }))}
          buttons={[
            {
              Component: Button,
              props: {
                active: props.chartTimePeriod === ChartTimePeriod.last30Days,
                text: t('DAY_COUNT', { ns: 'timeState', count: 30 }),
                onClick: () => { props.setChartTimePeriod(ChartTimePeriod.last30Days); }
              }
            },
            {
              Component: Button,
              props: {
                active: props.chartTimePeriod === ChartTimePeriod.last7Days,
                text: t('DAYS_7', { ns: 'timeState' }),
                onClick: () => { props.setChartTimePeriod(ChartTimePeriod.last7Days); }
              }
            },
            {
              Component: Button,
              props: {
                active: props.chartTimePeriod === ChartTimePeriod.last48Hours,
                text: t('HOURS_48', { ns: 'timeState' }),
                onClick: () => { props.setChartTimePeriod(ChartTimePeriod.last48Hours); }
              }
            },
            {
              Component: Button,
              props: {
                active: props.series === Series.usage,
                text: t('USAGE', { ns: 'deviceProcesses' }),
                onClick: () => { props.setSeries(Series.usage); }
              }
            },
            {
              Component: Button,
              props: {
                active: props.series === Series.cpu,
                text: t('CPU', { ns: 'translation' }),
                onClick: () => { props.setSeries(Series.cpu); }
              }
            }
          ]}
          days={30}
          series={[{
            visible: props.series === Series.usage,
            dataKey: 'userActivity',
            description: t('IN_USE', { ns: 'performance' }),
            colour: '#4AA4BA'
          },
          {
            visible: props.series === Series.cpu,
            dataKey: 'cpuActivity',
            description: t('CPU_USAGE', { ns: 'deviceProcesses' }),
            colour: '#4AA4BA'
          }]}
          formatData={formatDurationData(t)}
          title={`${props.data.processName} - ${props.series === Series.usage ?
            t(`USAGE_TITLE` as 'USAGE_TITLE_daily' | 'USAGE_TITLE_hourly', { ns: 'deviceProcesses', timePeriod: timePeriodTranslation, context: dailyOrHourlyTranslationContext })
            :
            t(`CPU_USAGE_TITLE` as 'CPU_USAGE_TITLE_daily' | 'CPU_USAGE_TITLE_hourly', { ns: 'deviceProcesses', timePeriod: timePeriodTranslation, context: dailyOrHourlyTranslationContext })
          }`}
          cypressId="process-usage-chart"
          yAxisType='duration'
          drawYAxis={(chart, yAxis) => {
            const dataKey = props.series === Series.usage ? 'userActivity' : 'cpuActivity';
            const maxUserActivitySeconds = Math.max(...chart.data.map((data) => data[dataKey]));
            const maxUserActivityHours = maxUserActivitySeconds / 60 / 60;
            (yAxis as am4charts.DurationAxis).baseUnit = "second";

            createYAxis(yAxis as am4charts.DurationAxis, maxUserActivityHours, t);

            yAxis.renderer.labels.template.disabled = true;
          }}
          hasLegend={true}
          formatTooltipDate={formatTooltipDate(props.chartTimePeriod)}
          xAxisType='date'
          drawXAxis={(_, xAxis) => {
            (xAxis as am4charts.DateAxis).dateFormatter.utc = true;
            xAxis.renderer.labels.template.disabled = true;

            calculateXAxisLabels(days[props.chartTimePeriod], xAxis as am4charts.DateAxis, timeZoneOffset);
          }}
          hasNoSelectionOverlay={false}
          useAxisTooltip
        />
      </div>
    </div>
  );
}

export function calculateXAxisLabels(days: number, xAxis: am4charts.DateAxis, timeZoneOffset: IDeviceInfo['timeZoneOffset']) {
  if (days === 30) {
    (xAxis as unknown as am4charts.DateAxis).calculateTotals = false;
    (xAxis as unknown as am4charts.DateAxis).baseInterval = {
      "timeUnit": "day",
      "count": 1
    };

    const now = moment.utc().add(timeZoneOffset, 'minutes').valueOf();
    const start = moment.utc(now).subtract(29, 'days').startOf('day').valueOf();
    for (const days of range(0, 29, 2)) {
      const range = (xAxis as unknown as am4charts.DateAxis).axisRanges.create();

      (xAxis as unknown as am4charts.DateAxis).min = start;
      (xAxis as unknown as am4charts.DateAxis).max = moment.utc(now).endOf('day').valueOf();

      const date = moment.utc(start).add(days, 'days');
      range.date = new Date(date.valueOf());
      range.label = new am4charts.AxisLabel();
      range.label.dx = -5;
      range.label.text = date.format('Do');
      range.grid.disabled = true;
    }

  } else {

    (xAxis as unknown as am4charts.DateAxis).baseInterval = {
      "timeUnit": "hour",
      "count": 1
    };
    const ranges = [];

    const to = moment.utc().add(timeZoneOffset, 'minutes').endOf('hour').valueOf() + 1;
    const start = moment.utc(to).subtract((24 * days), 'hours').valueOf();
    (xAxis as unknown as am4charts.DateAxis).min = start;
    (xAxis as unknown as am4charts.DateAxis).max = to;

    let hoursIncrement = 0;

    while (moment.utc(start).add(hoursIncrement, 'hours').isSameOrBefore(moment.utc(to))) {
      const range = (xAxis as unknown as am4charts.DateAxis).axisRanges.create();

      const date = moment.utc(start).add(hoursIncrement, 'hours');
      range.date = new Date(date.valueOf());
      range.label = new am4charts.AxisLabel();
      days === 2 ? range.label.dx = -15 : range.label.dx = -20;
      range.label.text = date.format('LT');
      range.grid.disabled = true;

      ranges.push(range);

      const increment = days === 7 ? 6 : 2;
      hoursIncrement += increment;
    }

    highlightDays(xAxis, start, to);
  }
}

const Button = styled(ChipButton)`
  z-index: 1000; /* visible over no selection overlay */
  margin-right: 0.75rem;

  &:nth-child(3) {
    margin-right: 3rem;
  }
`;
