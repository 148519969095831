import * as React from 'react';

import { Popover } from '../../../../components/controls/popover';
import { CustomValidatedInput } from '../../../../components/customValidatedInput/customValidatedInput';
import { Trans } from '../../../../components/i18n/typedTrans';
import { AlertActive } from '../alertActive';

import './averageDischargeBatterySettings.css';

export interface IProps {
  popoverText: string,
  title: string,
  warning: number,
  message: string,
  errorText: string,
  disabled: boolean,
  className: string,
  alertEnabled: boolean,
  dataUpdated: (timePeriod: number, data?: number, field?: string) => void,
  alertToggled: (data: boolean, initialSettings: { warning: number }) => void
}

export interface IState {
  warning: number
}

export class AverageDischargeBatterySettings extends React.Component<IProps, IState> {
  public constructor(props: any) {
    super(props);
    this.state = {
      warning: props.warning
    };
    this.checkValidity = this.checkValidity.bind(this);
    this.onAlertToggled = this.onAlertToggled.bind(this);
  }

  public render() {
    return (
      <div className={this.props.className}>
        <div className="battery-threshold-header">
          <div>
            <h6>{this.props.title}</h6>
            <Popover
              placement="right"
              id={`popoverDisplayThresholds`}
              className="popoverThresholds"
              target={<i className="threshold-icon fa fa-question-circle" />}
              content={<div className="infoPopover">{this.props.popoverText}</div>}
              isInsideTable={false}
            />
          </div>
          <AlertActive checked={this.props.alertEnabled} name={this.props.className} onChange={this.onAlertToggled} />
        </div>
        <div className="settings-group">
          <table id="BatteryAverageDischargeThresholds" className="table settings-table-component settings">
            <thead>
              <tr>
                <th className="rowTitle"></th>
                <th>
                  <span className="status-header warning battery">
                    <i className="fa fa-exclamation-triangle circle_orange enhanced-warning-icon" /> <Trans i18nKey="THRESHOLD_WARNING" ns="settings" />
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="rowTitle message">{this.props.message}</td>
                <td className="warning">
                  <CustomValidatedInput
                    value={this.props.warning}
                    validationRule={this.checkValidity}
                    errorText={this.props.errorText}
                    disabled={this.props.disabled || !this.props.alertEnabled}
                    onChange={(value: number) => this.props.dataUpdated(value)} />%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  checkValidity(value: number): boolean {
    if (this.props.warning !== this.state.warning) {
      return (value >= 1) && (value < 99);
    } else return true;
  }

  onAlertToggled(value: boolean) {
    this.props.alertToggled(value, { warning: this.state.warning });
  }
}
