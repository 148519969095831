/* istanbul ignore file */
/* react-leaflet incompatible with testing library, tested in cypress! */

import * as React from "react";
import { LayerGroup, Map } from "react-leaflet";
import { getGeotilePrecisionFromMapZoom, getGeotileGridMapData, ColorVariant } from "./utils";
import { Tile } from "./tile";
import { DataPoint } from "./types";

export type TooltipContentFC<TData extends DataPoint> = React.FC<{ dataPoint: TData, discrete: boolean }>;

type OverlayLayerProps<TData extends DataPoint> = {
  colorVariant: ColorVariant,
  data: TData[],
  gridShown: boolean,
  tooltip: boolean,
  discrete: boolean,
  mapRef: React.MutableRefObject<Map>,
  TooltipContent: TooltipContentFC<TData>
};

export const OverlayLayer = <TData extends DataPoint>(props: OverlayLayerProps<TData>) => {
  const [selected, setSelected] = React.useState<string>();

  if (props.data) {
    const bounds = props.mapRef.current?.leafletElement?.getBounds();
    const mapZoom = props.mapRef.current?.leafletElement?.getZoom();

    if (bounds && mapZoom) {
      const getDisplayData = () => {
        if (!props.gridShown) {
          return props.data;
        }

        const { _southWest, _northEast } = bounds as any;

        const geotilePrecision = getGeotilePrecisionFromMapZoom(mapZoom);
        return getGeotileGridMapData(props.data, _southWest, _northEast, geotilePrecision);
      };

      const displayData = getDisplayData();

      return <LayerGroup>
        {displayData.map(dataPoint => <Tile
          colorVariant={props.colorVariant}
          dataPoint={dataPoint}
          deselect={() => { setSelected(undefined); }}
          select={() => { setSelected(dataPoint.geotile); }}
          selected={dataPoint.geotile === selected}
          discrete={props.discrete}
          tooltip={props.tooltip}
          tooltipContent={dataPoint.percentage !== undefined && <props.TooltipContent dataPoint={dataPoint as TData} discrete={props.discrete} />}
          key={dataPoint.geotile}
        />)}
      </LayerGroup>;
    }
  }
  return <></>;
};
