import * as React from 'react';
import styled from 'styled-components';
import { NavLink, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';

import { Access } from './parts/access';
import { getBatterySettingsLicense, getEnabledSettings } from '../containers/settings/enabledSettings';
import { StandardPage } from './parts/standardPage';
import { Card } from '../components/card/card';
import Header from '../components/section/header';
import Section from '../components/section/section';
import { AlarmsSettings } from '../containers/settings/alarmsSettings';
import { AlertsSettings } from '../containers/settings/alertsSettings';
import { settingNames } from '../containers/settings/settingNames';
import { BatterySettingsLicense } from '../containers/settings/compositions/battery/batterySettings';
import { AccessDeniedToPage } from '../containers/access-denied/accessDeniedToPage';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { useProductsContext } from '../context/products';
import { AnomalySettingsPage } from '../containers/settings/anomalySettingsPage';
import { DevicesSettings } from '../containers/settings/devicesSettings';
import { PlatformSettings } from '../containers/settings/platformSettings';

const StyledCard = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 10rem;
  flex-basis: 10rem;
`;

const TabLink = styled(NavLink)`
  color: #333;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;

  &:focus {
    outline: solid 2px #333;
    outline-offset: -2px;
  }

  &:hover {
    text-decoration: none;
    color: #333;
  }

  &.active {
    background-color: #f2f2f2;
  }
`;

interface PageProps {
  alarmsEnabled: boolean,
  alertsEnabled: boolean,
  anomalyDetectionEnabled: boolean,
  devicesEnabled: boolean,
  platformEnabled: boolean,
  children: React.ReactNode
}

const ns = 'settings';
const ns1 = 'translation';

const SettingsPage = (props: PageProps) => {
  const { t } = useTranslation(ns);
  const routes = useWorldRoutes();
  return (
    <Card>
      <Header title={t('MAIN_TITLE')} />
      <Section>
        <StyledCard>
          <StyledLinks>
            {props.alarmsEnabled && <TabLink to={routes.settings.alarms} className="alarms-tab">{t('TAB_ALARMS')}</TabLink>}
            {props.alertsEnabled && <TabLink to={routes.settings.alerts} className="alerts-tab">{t('TAB_ALERTS')}</TabLink>}
            {props.anomalyDetectionEnabled && <TabLink to={routes.settings.anomalies} className="anomalies-tab">{t('ANOMALIES', { ns: ns1 })}</TabLink>}
            {props.devicesEnabled && <TabLink to={routes.settings.devices} className="devices-tab">{t('DEVICES', { ns: ns1 })}</TabLink>}
            {props.platformEnabled && <TabLink to={routes.settings.platform} className="platform-tab">{t('PLATFORM', { ns: ns })}</TabLink>}
          </StyledLinks>
          {props.children}
        </StyledCard>
      </Section>
    </Card>
  );
};

export function SettingsRoutes() {
  const { t } = useTranslation(ns1);
  const routes = useWorldRoutes();
  const basePath = useRouteMatch().path;
  const products = useProductsContext();

  const enabledSettings = getEnabledSettings(products);
  const batterySettingsLicense = getBatterySettingsLicense(products);
  const locationSettingsLicense = enabledSettings.includes(settingNames.location);
  const userExperienceSettingsLicense = enabledSettings.includes(settingNames.userExperience);
  const utilisationSettingsLicense = enabledSettings.includes(settingNames.utilisation);

  const alarmsSettingsEnabled = enabledSettings.includes(settingNames.alarms);
  const devicesSettingsEnabled = enabledSettings.includes(settingNames.devices);
  const anomalyDetectionSettingsEnabled = enabledSettings.includes(settingNames.anomalies);
  const platformSettingsEnabled = enabledSettings.includes(settingNames.platform);
  const alertsSettingsEnabled = batterySettingsLicense !== BatterySettingsLicense.none || locationSettingsLicense || userExperienceSettingsLicense || utilisationSettingsLicense;

  if (getEnabledSettings(products).length) {
    return (
      <Access title={t('SETTINGS')} role="manager">
        <StandardPage title={t('SETTINGS')}>
          <div className="container settings_container">
            <Switch>
              <Route exact path={`${basePath}/alarms`}>
                {alarmsSettingsEnabled ? <SettingsPage children={<AlarmsSettings />} alarmsEnabled={alarmsSettingsEnabled} devicesEnabled={devicesSettingsEnabled} alertsEnabled={alertsSettingsEnabled} anomalyDetectionEnabled={anomalyDetectionSettingsEnabled} platformEnabled={platformSettingsEnabled}/> : <AccessDeniedToPage />}
              </Route>
              <Route exact path={`${basePath}/alerts`}>
                {alertsSettingsEnabled ? <SettingsPage children={<AlertsSettings />} alarmsEnabled={alarmsSettingsEnabled} devicesEnabled={devicesSettingsEnabled} alertsEnabled={alertsSettingsEnabled} anomalyDetectionEnabled={anomalyDetectionSettingsEnabled} platformEnabled={platformSettingsEnabled}/> : <AccessDeniedToPage />}
              </Route>
              <Route exact path={`${basePath}/anomalies`}>
                {anomalyDetectionSettingsEnabled ? <SettingsPage children={<AnomalySettingsPage />} devicesEnabled={devicesSettingsEnabled} alarmsEnabled={alarmsSettingsEnabled} alertsEnabled={alertsSettingsEnabled} anomalyDetectionEnabled={anomalyDetectionSettingsEnabled} platformEnabled={platformSettingsEnabled}/> : <AccessDeniedToPage />}
              </Route>
              <Route exact path={`${basePath}/devices`}>
                {devicesSettingsEnabled ? <SettingsPage children={<DevicesSettings />} devicesEnabled={devicesSettingsEnabled} alarmsEnabled={alarmsSettingsEnabled} alertsEnabled={alertsSettingsEnabled} anomalyDetectionEnabled={anomalyDetectionSettingsEnabled} platformEnabled={platformSettingsEnabled}/> : <AccessDeniedToPage />}
              </Route>
              <Route exact path={`${basePath}/platform`}>
                {platformSettingsEnabled ? <SettingsPage children={<PlatformSettings />} platformEnabled={platformSettingsEnabled} devicesEnabled={devicesSettingsEnabled} alarmsEnabled={alarmsSettingsEnabled} alertsEnabled={alertsSettingsEnabled} anomalyDetectionEnabled={anomalyDetectionSettingsEnabled} /> : <AccessDeniedToPage />}
              </Route>
              <Route path={basePath}>
                {alarmsSettingsEnabled ? <Redirect to={routes.settings.alarms} /> : <Redirect to={routes.settings.alerts} />}
              </Route>
            </Switch>
          </div >
        </StandardPage>
      </Access>
    );
  }

  return (
    <StandardPage title={t('SETTINGS')}>
      <AccessDeniedToPage />
    </StandardPage>
  );
}
