import React, { useContext, useMemo } from 'react';
import { Product } from '../containers/app/productsWrapper';

export interface ProductContextValue {
  product: Product
}

export const ProductContext = React.createContext<ProductContextValue>(undefined);

interface ProductProviderProps {
  product: Product,
  children: React.ReactNode
}

export const ProductProvider = ({ children, product }: ProductProviderProps) => {
  const contextValue: ProductContextValue = useMemo(() => ({
    product
  }), [product]);

  return (
    <ProductContext.Provider value={contextValue}>
      {children}
    </ProductContext.Provider>
  );
};

export function useProductContext() {
  const context = useContext(ProductContext);
  if (context === undefined) {
    throw new Error('useProductContext must be used within a ProductProvider');
  }
  return context;
}
