import * as React from 'react';

import { IHomeLocation } from '../../../services/config/config';
import { Option, nullHomeLocation, Group, nullZone, NullZone, Zone } from '../../../components/controls/optionPickerLib';
import { useEffect, useState } from 'react';
import { World } from '../../../services/worlds/worlds';
import { useTranslation } from '@lib/useTypedTranslation';
import { UserRole } from '../../../components/authentication/userAuth';
import { useWait } from '../../../lib/wait';
import { BuildUserData } from './buildUser';
import { isEmailValid } from '../../../lib/emailValidation';
import { CustomViewType } from './useCustomViewOptions';

export type Props<RequestParams> = {
  handleSave: () => void,
  handleCancel: () => void,
  initialUser: {
    id?: string,
    email: string,
    role: UserRole,
    homeLocation: IHomeLocation | null,
    group: Group,
    zone: Zone | NullZone,
    worldIds: World['id'][] | null
  },
  worlds: World[],
  getSaveDisabled: (user: BuildUserData, worlds: World[]) => boolean,
  saveRequest: (params: RequestParams) => Promise<{success: boolean}>,
  getRequestParams: (user: BuildUserData) => RequestParams
};

export type UseBuildUser = {
  error: string,
  email: string,
  role: UserRole,
  homeLocation: IHomeLocation,
  worldIds: World['id'][],
  emailValidationError: string,
  saving: boolean,
  onSave: () => void,
  onClose: () => void,
  onEmailChange: (event: { target: HTMLInputElement }) => void,
  onBlur: (event: { target: HTMLInputElement }) => void,
  onRoleChange: (role: UserRole) => void,
  onHomeLocationChange: (selected: Option<IHomeLocation>) => void,
  onGroupChange: (selected: Option<Group>) => void,
  onZoneChange: (selected: Option<Zone>) => void,
  onWorldIdsChange: (worldIds: string[]) => void,
  onCustomViewTypeChange: (customViewType: CustomViewType) => void,
  customViewType: CustomViewType,
  group: Group,
  zone: Zone
};

export function useBuildUser<RequestParams>(props: Props<RequestParams>): UseBuildUser {
  const ns = 'userManagement';
  const ns2 = 'forms';
  const { t } = useTranslation([ns, ns2]);
  const { wait } = useWait();

  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [emailValidationError, setEmailValidationError] = useState('');
  const [role, setRole] = useState<UserRole>(props.initialUser.role);
  const [email, setEmail] = useState<string>(props.initialUser.email);
  const [homeLocation, setHomeLocation] = useState<IHomeLocation>(props.initialUser.homeLocation);
  const [group, setGroup] = useState<Group>(props.initialUser.group);
  const [zone, setZone] = useState<Zone>(props.initialUser.zone);
  const [worldIds, setWorldIds] = useState(props.initialUser.worldIds);
  const [customViewType, setCustomViewType] = useState(props.initialUser.zone?.id ? CustomViewType.zone : CustomViewType.groupHomeLocation);

  async function onSave() {
    const customViewEnabled = role !== 'administrator' && role !== 'manager';
    const user = {
      id: props.initialUser.id,
      email,
      role,
      homeLocationId: customViewEnabled && homeLocation?.id ? homeLocation.id : null,
      zoneId: customViewEnabled && zone?.id ? zone.id : null,
      group: customViewEnabled ? group : null,
      worldIds: role === 'administrator' ? null : worldIds
    };
    if (props.getSaveDisabled(user, props.worlds) || emailValidationError || saving) { return; }
    setSaving(true);
    try {
      const params = props.getRequestParams(user);
      await props.saveRequest(params);
      await wait(5000); // 5 second delay to allow for ES sync
      onClose();
      props.handleSave();
    } catch (err) {
      if (err.message.includes('User already exists')) {
        setError(t('EMAIL_EXISTS_ERROR', { ns: 'userManagement' }));
      } else if (err.message.includes('User not found')) {
        setError(t('USER_NOT_FOUND', { ns: 'userManagement' }));
      } else {
        setError(t('SOMETHING_WRONG_RETRY', { ns: 'error' }));
      }
      setSaving(false);
    }
  }

  function onClose() {
    setEmail(props.initialUser.email);
    setRole(props.initialUser.role);
    setWorldIds(props.initialUser.worldIds);
    setError('');
    setEmailValidationError('');
    setHomeLocation(props.initialUser.homeLocation);
    setGroup(props.initialUser.group);
    setZone(props.initialUser.zone);
    setSaving(false);
    props.handleCancel();
  }

  function onBlur(event: { target: HTMLInputElement }) {
    const { value } = event.target;
    if (value) {
      if (!isEmailValid(value)) {
        setEmailValidationError(`${t('INVALID_EMAIL_ERROR', { ns: 'userManagement' })}`);
      } else {
        setEmailValidationError('');
      }
    }
  }

  const onRoleChange = (role: UserRole) => {
    if (role === 'administrator') {
      setWorldIds(props.worlds.map(world => world.id));
    }
    setRole(role);
  };

  const onHomeLocationChange = (selected: Option<IHomeLocation>): void => {
    setHomeLocation(selected.value);
    setZone(nullZone);
  };

  const onGroupChange = (selected: Option<Group>): void => {
    setGroup(selected.value);
    setZone(nullZone);
  };

  const onZoneChange = (selected: Option<Zone>): void => {
    setZone(selected.value);
    setGroup(null);
    setHomeLocation(nullHomeLocation);
  };

  const onCustomViewTypeChange = (customViewType: CustomViewType): void => {
    if (customViewType === CustomViewType.groupHomeLocation) {
      setZone(nullZone);
    }
    if (customViewType === CustomViewType.zone) {
      setGroup(null);
      setHomeLocation(nullHomeLocation);
    }
    setCustomViewType(customViewType);
  };

  function onEmailChange(event: { target: HTMLInputElement }) {
    const { value } = event.target;
    setEmail(value);
    if (!value) {
      setEmailValidationError('');
    }
  }

  const onWorldIdsChange = (worldIds: string[]) => {
    setWorldIds(worldIds);
    setHomeLocation(nullHomeLocation);
    setZone(nullZone);
    setGroup(null);
  };

  useEffect(() => {
    if (props.worlds && worldIds === null) {
      setWorldIds(props.worlds.map((world: World) => world.id));
    }
  }, [props.worlds, worldIds]);

  return {
    error,
    email,
    role,
    homeLocation,
    worldIds,
    emailValidationError,
    saving,
    customViewType,
    group,
    zone,
    onGroupChange,
    onZoneChange,
    onSave,
    onClose,
    onEmailChange,
    onBlur,
    onRoleChange,
    onHomeLocationChange,
    onWorldIdsChange,
    onCustomViewTypeChange
  };
}
