import * as React from 'react';
import { MainBody } from '../../layout/body/Body';
import { Greetings } from '../../components/authentication/greetings';

interface Props {
  title?: string,
  children?: React.ReactNode
}

export const StandardPage = (props: Props) => {
  const { title, children } = props;
  return (
    <Greetings worldAware={true} title={title}>
      <MainBody>
        {children}
      </MainBody>
    </Greetings>
  );
};
