import * as React from 'react';
import { ZeroSignalDataPoint, ZeroSignalTooltipContent } from './zeroSignal';
import { NoServiceDataPoint, NoServiceTooltipContent } from './noService';
import { IntensityMap } from './shared/intensityMap';
import { getNoServiceIntensity, getZeroSignalLevelIntensity } from '../../services/core/maps';

export enum MapData {
  poorNoSignal = 'VERY_POOR_NO_SERVICE',
  noService = 'NO_SERVICE',
}

type DeviceCoverageProps = {
  deviceId: string,
  mapData: MapData,
  sharedKey: string,
  timePeriod: number
};

export const DeviceCoverageMap: React.FC<DeviceCoverageProps> = props => {
  const sharedProps = { deviceId: props.deviceId, key: props.sharedKey };

  if (props.mapData === MapData.noService) {
    return <IntensityMap<NoServiceDataPoint>
      fetchMapData={(boundingLatLong: string, geotilePrecision: number) => {
        return getNoServiceIntensity({
          boundingLatLong,
          geotilePrecision,
          deviceId: props.deviceId,
          timePeriod: props.timePeriod,
        });
      }}
      TooltipContent={NoServiceTooltipContent}
      {...sharedProps}
    />;
  } else {
    return <IntensityMap<ZeroSignalDataPoint>
      fetchMapData={(boundingLatLong: string, geotilePrecision: number) => {
        return getZeroSignalLevelIntensity({
          boundingLatLong,
          geotilePrecision,
          deviceId: props.deviceId,
          timePeriod: props.timePeriod
        });
      }}
      TooltipContent={ZeroSignalTooltipContent}
      deviceId={props.deviceId}
      {...sharedProps}
    />;
  }
};
