import React, { useMemo } from 'react';
import { BatteryTypeSettingsProvider } from '../../context/batteryTypeSettings';
import { BatteryTypeSettings } from '../../services/config/config';

interface Props {
  batteryTypeSettings: BatteryTypeSettings[],
  children: React.ReactNode
}
export function BatteryTypeSettingsWrapper(props: Props) {
  const { children, batteryTypeSettings } = props;
  const value = useMemo(() => {
    return [...batteryTypeSettings];
  }, [batteryTypeSettings]);

  return (
    <BatteryTypeSettingsProvider initialState={value}>
      {children}
    </BatteryTypeSettingsProvider>
  );
}
