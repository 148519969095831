import { ISortResult } from "../shared/common";
import { get } from "lodash";
import { request, RequestInitWithRetry } from "../../lib/request";

export interface IProcessesOverviewData {
  processCount: number,
  usedMonthCount: number,
  used14DaysCount: number,
  usedDayCount: number
}

export const getProcessesOverview = (deviceId: string) => {
  return (options: RequestInitWithRetry): Promise<IProcessesOverviewData> => {
    const input = {
      path: `/api/core/deviceProcesses/overview`,
      qs: { deviceId: deviceId },
      body: {},
      method: 'post'
    };
    return request(input, options);
  };
};

export interface IProcessData {
  id: string,
  processName: string,
  fileVersion: string,
  productName: string,
  productVersion: string,
  lastUsed: string,
  averageUserActivity: number,
  averageCpuActivity: number
}

export interface IListOptions {
  deviceId: string,
  limit: number,
  offset: number,
  sort?: ISortResult,
  search?: string
}

export interface IProcessListData {
  list: IProcessData[],
  totalCount: number
}

export interface IProcessUsageParams {
  processId: string,
  to: number,
  from: number,
  bucketByHour: boolean
}

interface IProcessUsage {
  date: number,
  userActivity: number | null,
  cpuActivity: number | null
}

export interface IProcessUsageData {
  processUsageData: IProcessUsage[],
  processName: string
}

export function getProcessesList(options: IListOptions) {
  return async (requestOptions: RequestInitWithRetry): Promise<IProcessListData> => {
    const input = {
      path: `/api/core/deviceProcesses/list`,
      qs: { deviceId: options.deviceId, limit: options.limit, offset: options.offset },
      body: {
        sort: get(options, 'sort'),
        search: get(options, 'search')
      },
      method: 'post'
    };
    return request(input, requestOptions);
  };
}

export const getProcessUsage = (params: IProcessUsageParams) => {
  return (options: RequestInitWithRetry): Promise<IProcessUsageData> => {
    const input = {
      path: '/api/core/deviceProcesses/processUsage',
      qs: {
        processId: params.processId,
        to: params.to,
        from: params.from,
        bucketByHour: params.bucketByHour
      },
      method: 'post'
    };
    return request(input, options);
  };
};
