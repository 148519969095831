import * as React from 'react';
import { useCallback } from 'react';
import { InitialTableState, useTableReducer } from '../../../components/data-table/lib';
import { List } from '../../../components/list/list';
import { useUserSettingsContext } from '../../../context/userSettings';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { ZonesListAndCount, getZonesList, Zone } from '../../../services/zones/zones';
import { useZonesListColumns } from './useZonesListColumns';
import useNormalizedHeaderButtons from '../../../components/controls/normalizedHeaderButtons';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { useHistory } from 'react-router-dom';
import { DeleteZones } from './deleteZone';
import { at } from 'lodash';

const tableName = 'zonesList';
const defaultPageSize = 20;

export function ZonesList() {
  const { tablePageSizes } = useUserSettingsContext();

  const columns = useZonesListColumns();

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 0, direction: 'asc', field: 'name' }
  };
  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<Zone>(tableName, initialTableState);
  const { offset, limit, sort, search, checkedRows, data } = tableReducerProperties;

  const translationItems = {
    statusText: {
      key: 'RESULT',
      ns: 'translation'
    },
    tableTitle: {
      key: 'ZONES',
      ns: 'zones'
    }
  };

  const basePath = useWorldRoutes().zones;
  const history = useHistory();

  const [showDeleteZone, setShowDeleteZone] = React.useState(false);

  const additionalHeaderButtons = useNormalizedHeaderButtons({
    checkedRows,
    create: {
      dataId: "zone-creation-btn",
      onClick: () => { history.push(`${basePath.new}`); }
    },
    edit: {
      dataId: "zone-update-btn",
      onClick: () => history.push({
        pathname: `${basePath.edit}`,
        state: data[checkedRows[0]]
      })
    },
    delete: {
      dataId: "delete-zone-button",
      onClick: () => { setShowDeleteZone(true); }
    }
  });

  const listFetcher = useCallback(() => {
    return getZonesList({ limit, offset, sort: { field: sort.field, order: sort.direction }, search });
  }, [offset, limit, sort, search]);

  return (
    <div>
      <List<Zone, ZonesListAndCount, 'totalCount', 'list', 'id'>
        dataId='manage-zones-list'
        customHeader={false}
        countDataPath='totalCount'
        listDataPath='list'
        additionalHeaderButtons={additionalHeaderButtons}
        hasCheckboxes={true}
        hasFilters={false}
        hasSearch
        translationItems={translationItems}
        columns={columns}
        fetcher={listFetcher}
        tableReducerFunctions={tableReducerFunctions}
        tableReducerProperties={tableReducerProperties}
        useRequestHook={useWorldRequest}
        options={{ rowIdDataPath: 'id', rowNameDataPath: 'name' }}
      />
      {showDeleteZone && (
        <DeleteZones
          showPopup={showDeleteZone}
          zones={at(data, checkedRows)}
          onClose={() => setShowDeleteZone(false)}
          onDelete={tableReducerFunctions.onLoading}
        />
      )}
    </div>
  );
}
