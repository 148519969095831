import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { ModalPopup } from '../../components/controls/modalPopup';
import { HomeLocationData, updateHomeLocationAction, UpdateHomeLocationData } from '../../services/homeLocations/homeLocations';
import { useFormReducer, translateFieldsConfig, extractFieldValues, prepareFieldValues } from '../../components/forms/formsLib';
import { fieldNames, FieldNames, ErrorCode, nsList, thresholdFieldNames, ThresholdFieldNames, ThresholdErrorCode, getRawUpdateFieldsConfig } from './editLib';
import { FormFieldList } from '../../components/forms/formFieldList';
import { InfoPopover } from '../../components/tooltip/infoPopover';
import { FormControls } from '../../components/forms/formControls';
import { ThresholdSelection } from './thresholdSelection';
import { useThresholdSelection } from './thresholdSelectionLib';
import { useWorldAction } from '../../lib/useWorldAction';
import { useWorldSettingsContext } from '../../context/worldSettings';
import { useWait } from '../../lib/wait';

import './editHomeLocation.css';

export interface Props {
  homeLocation: HomeLocationData,
  showPopup: boolean,
  onClose: () => void,
  onSave: (updated: HomeLocationData) => void
}

const [ns, _, ns3, ns4] = nsList;

export function UpdateHomeLocation(props: Props): JSX.Element {
  const { t } = useTranslation(nsList);
  const { worldSettings } = useWorldSettingsContext();
  const { showPopup, onClose, homeLocation, onSave } = props;
  const [error, setError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { wait } = useWait();

  const updateHomeLocation = useWorldAction(updateHomeLocationAction);

  const fieldsConfig = useMemo(() => translateFieldsConfig(getRawUpdateFieldsConfig(worldSettings.useMetricDistances), t), [t, worldSettings.useMetricDistances]);

  const [
    { errors, values, isValid, isComplete, isDirty },
    { onChange, onValidate, onReset, onClear, onNew }
  ] = useFormReducer<FieldNames, ErrorCode>({ fieldsConfig, initialValues: extractFieldValues(fieldNames, homeLocation, fieldsConfig) });

  const [
    { thresholdsErrors, thresholdsValues, thresholdsValid, thresholdsComplete, thresholdsDirty },
    { thresholdsOnChange, thresholdsOnValidate, thresholdsOnNew, thresholdsOnClear, thresholdsOnReset },
    { thresholdFieldsConfig, thresholdFieldsConfigRef },
    { useGlobalThresholds, setUseGlobalThresholds }
  ] = useThresholdSelection(t, homeLocation);

  const thresholdsDisabled = homeLocation?.distanceRed && homeLocation?.distanceYellow && useGlobalThresholds;
  const thresholdsShouldBeDirty = !thresholdsDisabled && !thresholdsDirty;
  const disabled = !isValid || !isComplete || !thresholdsValid || !thresholdsComplete || (!isDirty && thresholdsShouldBeDirty);

  useEffect(() => {
    if (homeLocation) {
      onNew(extractFieldValues(fieldNames, homeLocation, fieldsConfig));
      thresholdsOnNew(extractFieldValues<ThresholdFieldNames, ThresholdErrorCode>(thresholdFieldNames, homeLocation, thresholdFieldsConfigRef.current));
    } else {
      onClear();
      thresholdsOnClear();
    }
  }, [onNew, onClear, thresholdsOnNew, thresholdsOnClear, fieldsConfig, thresholdFieldsConfigRef, homeLocation]);

  async function handleSave() {
    setError(undefined);
    setIsSubmitting(true);
    const homeLocationData = prepareFieldValues<FieldNames, ErrorCode, Omit<UpdateHomeLocationData, 'distanceRed' | 'distanceYellow'>>(fieldNames, values, fieldsConfig);
    const thresholdsData = thresholdsDisabled
      ? { distanceYellow: null, distanceRed: null }
      : prepareFieldValues<ThresholdFieldNames, ThresholdErrorCode, Pick<UpdateHomeLocationData, 'distanceRed' | 'distanceYellow'>>(thresholdFieldNames, thresholdsValues, thresholdFieldsConfig);
    const updateParams = {
      ...homeLocationData,
      ...thresholdsData
    };
    try {
      await updateHomeLocation(homeLocation.id, updateParams);
      const updatedHomeLocation = {
        ...homeLocation,
        ...updateParams,
        position: `${homeLocationData.latitude}, ${homeLocationData.longitude}`
      };
      // 5 second delay to allow replication of new home location to ES
      await wait(5000);
      onSave(updatedHomeLocation);
      onClose();
    } catch (err) {
      setError(t('SOMETHING_WRONG', { ns: ns3 }));
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleCancel() {
    onReset();
    thresholdsOnReset();
    onClose();
    setError(undefined);
  }

  const tooltip = (
    <InfoPopover labelText='about edit'>
      {t('UPDATE_TITLE_TOOLTIP', { ns })}
    </InfoPopover>
  );
  const header = <>{t('UPDATE_TITLE', { ns })}{tooltip}</>;

  const footer = (
    <FormControls
      submitDisabled={disabled}
      submitResult={{
        status: isSubmitting ? 'loading' : error ? 'error' : null,
        message: error
      }}
      onCancel={handleCancel}
      onSubmit={handleSave}
      message={<span className="edit-home-location__footer-message">
        {t('REQUIRED_FIELDS', { ns: ns4 })}
      </span>}
      mode='save'
    />
  );

  return (
    <div className="edit-home-location edit-home-location--update">
      <ModalPopup
        show={showPopup}
        handleClose={handleCancel}
        header={header}
        classname="edit-home-location__modal"
        footer={footer}
      >
        <div className="edit-home-location__body edit-home-location__body--update">
          <div className="edit-home-location__header">
            {t('DETAILS_TITLE', { ns })}
          </div>
          <FormFieldList<FieldNames, ErrorCode>
            fieldNames={fieldNames}
            fieldsConfig={fieldsConfig}
            onChange={onChange}
            onValidate={onValidate}
            values={values}
            errors={errors}
            disabled={isSubmitting}
          />
          <ThresholdSelection
            global={useGlobalThresholds}
            onSelectGlobal={setUseGlobalThresholds}
            fieldsConfig={thresholdFieldsConfig}
            onChange={thresholdsOnChange}
            onValidate={thresholdsOnValidate}
            values={thresholdsValues}
            errors={thresholdsErrors}
            isSubmitting={isSubmitting}
          />
        </div>
      </ModalPopup>
    </div>
  );
}
