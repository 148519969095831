import * as React from 'react';

import { RequestInitWithRetry } from './request';
import { UseRequestOptions, UseRequestReturn, useRequest } from './useRequest';
import { useCurrentWorldContext } from '../context/currentWorld';

/**
 * Makes a world aware api request via useRequest
 */
export const useWorldRequest = <T = any>(
  getFetcher: () => (options: RequestInitWithRetry) => Promise<T>,
  options: UseRequestOptions<T> = {}
): UseRequestReturn<T> => {
  const { id: worldId } = useCurrentWorldContext();
  return useRequest(getFetcher, options, worldId);
};
