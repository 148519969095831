import * as React from 'react';
import Control from 'react-leaflet-control';

import { LatLngBounds, Map as LeafletMap, latLng, latLngBounds } from 'leaflet';
import { Map, MapProps, TileLayer } from 'react-leaflet';
import { useTranslation } from '@lib/useTypedTranslation';

import { CheckBox } from '../../controls/checkbox';
import { DropdownMenu } from '../../controls/dropdownMenu';
import { Dropdown } from 'react-bootstrap';
import { Legend } from './legend';

import { ISignalLevelGeoBoundsResponse, getSignalLevelGeoBounds } from '../../../services/core/maps';
import { useDebounce } from '../../../lib/useDebounce';
import { getGeotilePrecisionFromMapZoom, getGeotileMapBounds, MIN_LATITUDE, MAX_LATITUDE, MIN_LONGITUDE, MAX_LONGITUDE, ColorVariant } from './utils';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { DataPoint } from './types';
import { RequestInitWithRetry } from '../../../lib/request';
import { OverlayLayer, TooltipContentFC } from './overlayLayer';

import { UseRequestReturn } from '../../../lib/useRequest';

import './intensityMap.css';
import { NoSelectionOverlay } from '../../card/noSelectionOverlay';
import { useLongPress } from '../../../lib/useLongPress';

/** a factor multiplied by the original bounds to determine the additional padded area */
const BOUNDS_FACTOR_PADDING = 0.25;
const ZOOM_TIMEOUT_MS = 50;

export const CheckboxDropdownItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Dropdown.Item as='label' data-id="checkbox-dropdown-item" onClick={e => { e.stopPropagation(); }}>
    {children}
  </Dropdown.Item>;
};

interface IntensityMapProps<TData extends DataPoint> {
  fetchMapData: (boundingLatLong: string, geotilePrecision: number) => (options: RequestInitWithRetry) => Promise<TData[]>,
  TooltipContent: TooltipContentFC<TData>,
  groups?: string[],
  homeLocations?: string[],
  zoneId?: string,
  deviceId?: string,
  fixMaxBounds?: boolean,
  colorVariant?: ColorVariant
}

export const useInitialBoundsSet = (
  initialBoundsRequest: UseRequestReturn<ISignalLevelGeoBoundsResponse>,
  mapRef: React.MutableRefObject<Map<MapProps, LeafletMap>>,
) => {
  const [initialBoundsMinZoom, setInitialBoundsMinZoom] = React.useState(null);

  const setInitialBounds = React.useCallback(() => {
    if (initialBoundsRequest.data) {
      const { geoBounds: { bottom, left, top, right } } = initialBoundsRequest.data;

      const apiBoundsSw = latLng(Math.max(bottom, MIN_LATITUDE), Math.max(left, MIN_LONGITUDE));
      const apiBoundsNe = latLng(Math.min(top, MAX_LATITUDE), Math.min(right, MAX_LONGITUDE));

      mapRef.current.leafletElement.setMinZoom(0);

      const apiBounds = latLngBounds(apiBoundsSw, apiBoundsNe);
      const apiBoundsZoom = mapRef.current.leafletElement.getBoundsZoom(apiBounds);

      const currentPrecision = getGeotilePrecisionFromMapZoom(apiBoundsZoom);
      const clientBounds = getGeotileMapBounds(apiBoundsSw, apiBoundsNe, currentPrecision);

      const paddedBounds = clientBounds.pad(BOUNDS_FACTOR_PADDING);
      mapRef.current.leafletElement.setMaxBounds(paddedBounds);

      const paddedBoundsZoom = mapRef.current.leafletElement.getBoundsZoom(paddedBounds);
      mapRef.current.leafletElement.setMinZoom(Math.max(paddedBoundsZoom - 1, 0));

      mapRef.current.leafletElement.fitBounds(paddedBounds);

      setInitialBoundsMinZoom(paddedBoundsZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBoundsRequest.data]);

  React.useEffect(() => {
    setInitialBounds();
  }, [setInitialBounds]);

  React.useEffect(() => {
    if (initialBoundsMinZoom) {
      mapRef.current.leafletElement.setMinZoom(initialBoundsMinZoom);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialBoundsMinZoom]);

  return {
    resetInitialBounds: () => {
      setInitialBoundsMinZoom(null);
      setInitialBounds();
    },
    mapTilesShown: typeof initialBoundsMinZoom === 'number'
  };
};

export const useZoom = (mapRef: React.MutableRefObject<Map<MapProps, LeafletMap>>, zoomIncrement: number) => {
  const smoothZoomIn = useLongPress(() => {
    mapRef.current?.leafletElement.zoomIn(zoomIncrement);
  }, ZOOM_TIMEOUT_MS);

  const smoothZoomOut = useLongPress(() => {
    mapRef.current?.leafletElement.zoomOut(zoomIncrement);
  }, ZOOM_TIMEOUT_MS);

  const zoomInDisabled = mapRef.current?.leafletElement?.getZoom() === mapRef.current?.leafletElement?.getMaxZoom();
  const zoomOutDisabled = mapRef.current?.leafletElement?.getZoom() === mapRef.current?.leafletElement?.getMinZoom();

  return {
    smoothZoomIn,
    smoothZoomOut,
    zoomInDisabled,
    zoomOutDisabled
  };
};

const fixMapBoundsGetter = () => getSignalLevelGeoBounds({});

export const IntensityMap = <TData extends DataPoint>({
  groups,
  homeLocations,
  zoneId,
  deviceId,
  fetchMapData,
  TooltipContent,
  colorVariant = 'negative',
  fixMaxBounds = false,
}: IntensityMapProps<TData>) => {
  const mapRef = React.useRef<Map>();
  const { t } = useTranslation('translation');
  const { t: t2 } = useTranslation('maps');

  // CONFIG STATE
  const [grayscale, setGrayscale] = React.useState(true);
  const [gridShown, setGridShown] = React.useState(true);
  const [tooltip, setTooltip] = React.useState(false);
  const [discrete, setDiscrete] = React.useState(false);
  const [legend, setLegend] = React.useState(true);

  // INTERACTION STATE
  const [bounds, setBounds] = React.useState<LatLngBounds>();
  const [geotilePrecision, setGeotilePrecision] = React.useState<number>();

  //#region API
  const { addDebounceEvent, isDebouncing } = useDebounce(500);

  // Initial Bounds
  const fetchInitialBoundsRequest = React.useCallback(
    () => getSignalLevelGeoBounds({
      groups: groups,
      homeLocations: homeLocations,
      zoneId: zoneId,
      deviceId: deviceId
    }),
    [groups, homeLocations, zoneId, deviceId]
  );

  const getFetch = React.useMemo(() => {
    return fixMaxBounds ?
      fixMapBoundsGetter
      :
      fetchInitialBoundsRequest;
  }, [fixMaxBounds, fetchInitialBoundsRequest]);

  const initialBoundsRequest = useWorldRequest(getFetch);

  const { resetInitialBounds, mapTilesShown } = useInitialBoundsSet(
    initialBoundsRequest,
    mapRef,
  );

  // Map Data
  const fetchMapDataRequest = React.useCallback(() => {
    // no fetcher returned if no bounds or precision
    if (!bounds || !geotilePrecision) {
      return undefined;
    }

    const boundsSw = bounds.getSouthWest();
    const boundsNe = bounds.getNorthEast();

    const mapBounds = getGeotileMapBounds(boundsSw, boundsNe, geotilePrecision);
    const boundingLatLong = `${mapBounds.getSouth()},${mapBounds.getWest()}:${mapBounds.getNorth()},${mapBounds.getEast()}`;

    return fetchMapData(boundingLatLong, geotilePrecision);
  }, [bounds, geotilePrecision, fetchMapData]);

  const mapDataRequest = useWorldRequest(fetchMapDataRequest);

  // triggers fresh map data fetching via useWorldRequest
  const prepareMapDataRequest = React.useCallback(() => {
    if (initialBoundsRequest.data) {
      const bounds = mapRef.current?.leafletElement?.getBounds();
      const zoom = mapRef.current?.leafletElement?.getZoom();
      const geotilePrecision = getGeotilePrecisionFromMapZoom(zoom);

      setBounds(bounds);
      setGeotilePrecision(geotilePrecision);
    }
  }, [initialBoundsRequest.data]);

  const handleChange = React.useCallback(() => {
    addDebounceEvent(prepareMapDataRequest);
  }, [addDebounceEvent, prepareMapDataRequest]);

  //#endregion

  const isLoading = isDebouncing || mapDataRequest.loading || initialBoundsRequest.loading;

  const { smoothZoomIn, smoothZoomOut, zoomInDisabled, zoomOutDisabled } = useZoom(mapRef, 1);

  return <React.Fragment>
    <div className="intensity-map" data-id="intensity-map" data-grayscale={grayscale}>
      {/* cannot be with other leaflet controls: https://github.com/LiveBy/react-leaflet-control/issues/16 */}
      <div className="mapZoomButtonContainer">
        <i
          className="mapZoomButton fas fa-plus"
          onPointerDown={!zoomInDisabled ? smoothZoomIn.pointerDown : undefined}
          onPointerUp={!zoomInDisabled ? smoothZoomIn.pointerUp : undefined}
          aria-disabled={zoomInDisabled}
          aria-label="Zoom in"
          title="Zoom in"
        />
        <i
          className="mapZoomButton fas fa-minus"
          onPointerDown={!zoomInDisabled ? smoothZoomOut.pointerDown : undefined}
          onPointerUp={!zoomInDisabled ? smoothZoomOut.pointerUp : undefined}
          aria-disabled={zoomOutDisabled}
          aria-label="Zoom out"
          title="Zoom out"
        />
      </div>
      <NoSelectionOverlay
        show={!isLoading && (initialBoundsRequest.data === null)}
        noSelectionText={t('NO_DATA_AVAILABLE')}
      />
      <Map
        ref={ref => { if (ref) mapRef.current = ref; }}
        zoomControl={false}
        bounds={[[-90, -180], [90, 180]]}
        onmoveend={handleChange}
        onzoomend={handleChange}
        onresize={resetInitialBounds}
        maxZoom={19}
        zoomSnap={1}
      >
        <OverlayLayer
          colorVariant={colorVariant}
          data={mapDataRequest.data}
          discrete={discrete}
          gridShown={gridShown}
          mapRef={mapRef}
          tooltip={tooltip}
          TooltipContent={TooltipContent}
        />
        <Control position="topright">
          <i className="mapResetButton fas fa-undo-alt" onClick={resetInitialBounds} />
        </Control>
        <Control position="bottomright">
          {legend && <Legend colorVariant={colorVariant} discrete={discrete} />}
        </Control>
        <Control position="topleft">
          <DropdownMenu buttonContent={<i className="fas fa-cog" />} data-id='map-config'>
            <CheckboxDropdownItem>
              <CheckBox
                checked={gridShown}
                onChange={() => { setGridShown(!gridShown); }}
                name={t2('CONFIG_GRID_SHOWN')}
              />
              {t2('CONFIG_GRID_SHOWN')}
            </CheckboxDropdownItem>
            <CheckboxDropdownItem>
              <CheckBox
                checked={grayscale}
                onChange={() => { setGrayscale(!grayscale); }}
                name={t2('CONFIG_GRAYSCALE')}
              />
              {t2('CONFIG_GRAYSCALE')}
            </CheckboxDropdownItem>
            <CheckboxDropdownItem>
              <CheckBox
                checked={legend}
                onChange={() => {
                  setLegend(!legend);
                }}
                name={t2('CONFIG_LEGEND')}
              />
              {t2('CONFIG_LEGEND')}
            </CheckboxDropdownItem>
            <CheckboxDropdownItem>
              <CheckBox
                checked={tooltip}
                onChange={() => {
                  setTooltip(!tooltip);
                }}
                name={t2('CONFIG_TOOLTIP')}
              />
              {t2('CONFIG_TOOLTIP')}
            </CheckboxDropdownItem>
            <CheckboxDropdownItem>
              <CheckBox
                checked={discrete}
                onChange={() => {
                  setDiscrete(!discrete);
                }}
                name={t2('CONFIG_DISCRETE')}
              />
              {t2('CONFIG_DISCRETE')}
            </CheckboxDropdownItem>
          </DropdownMenu>
        </Control>
        {isLoading && <Control position="bottomleft">
          <div className='loading-indicator'>
            <i className="fas fa-spinner fa-spin" />
            {t('LOADING')}
          </div>
        </Control>}
        {mapTilesShown && <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          noWrap
        />}
      </Map>
    </div>
  </React.Fragment>;
};
