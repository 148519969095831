import * as React from 'react';
import styled from 'styled-components';
import { NavLink, Route, Switch } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';

import { Access } from './parts/access';
import { StandardPage } from './parts/standardPage';
import { Card } from '../components/card/card';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { ZonesList } from '../containers/zones/compositions/zonesList';
import { CreateZone } from '../containers/zones/compositions/createZone';
import { EditZone } from '../containers/zones/compositions/editZone';

const StyledLinks = styled.div`
  display: flex;
  flex-direction: column;
`;

interface PageProps {
  children: React.ReactNode
}

const ns = 'zones';

const ZonesPage = (props: PageProps) => {
  const routes = useWorldRoutes();
  return (
    <Card>
      <StyledLinks>
        {<NavLink to={`${routes.zones.list}`} className="zones-list" />}
        {<NavLink to={`${routes.zones.new}`} className="create-zone-page" />}
      </StyledLinks>
      {props.children}
    </Card>
  );
};
export function ZoneManagementRoute() {
  const { t } = useTranslation(ns);
  const basePath = useWorldRoutes().zones;

  return (
    <Access title={t('MANAGE_ZONES')} role="manager">
      <StandardPage title={t('MANAGE_ZONES')}>
        <div className="zones_container">
          <Switch>
            <Route exact path={`${basePath.list}`}>
              <ZonesPage children={<ZonesList />} />
            </Route>
            <Route exact path={`${basePath.new}`}>
              <ZonesPage children={<CreateZone />} />
            </Route>
            <Route exact path={`${basePath.edit}`}>
              <ZonesPage children={<EditZone />} />
            </Route>
          </Switch>
        </div >
      </StandardPage>
    </Access>
  );
}
