import * as React from 'react';

import { UsageSettings } from './usage';
import { OutOfContactSettings } from './outOfContact';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export interface IProps {
  worldSettings: WorldSettings,
  onDataChanged: (data: WorldSettings) => void,
  submitInProgress: boolean,
  dataTimestamp: number
}

export function UtilisationSettings(props: IProps) {
  return <div className="threshold-settings-composition utilisation">
    <UsageSettings key='usage' timeStamp={props.dataTimestamp} onDataChanged={props.onDataChanged} worldSettings={props.worldSettings} submitInProgress={props.submitInProgress} />
    <OutOfContactSettings key='outOfContact' timeStamp={props.dataTimestamp} onDataChanged={props.onDataChanged} worldSettings={props.worldSettings} submitInProgress={props.submitInProgress} />
  </div>;
}
