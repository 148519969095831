import React from 'react';
import styled from 'styled-components';

import { Theme, themes } from '../../containers/app/themes';

interface StyleProps {
  onClick?: () => void
}

const StyledBaseLogo = styled.span<StyleProps>`
  ${({ theme }: { theme: Theme }) => theme.name === themes.computacenter.name ? `margin-left: 40px` : `margin-left: 30px`};
  display: inline-block;
  ${({ theme }: { theme: Theme }) => `background-image: url(${theme.headers.logo})`};
  ${({ onClick }) => `cursor: ${onClick ? 'pointer' : 'inherit'}`};
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  @media (max-width: 1300px) {
    ${({ theme }: { theme: Theme }) => theme.name === themes.computacenter.name ? `margin-left: 35px` : `margin-left: 10px`};
  };
  @media (max-width: 400px) {
    width: 125px;
    background-size: 125px 26px;
  };
`;

StyledBaseLogo.displayName = 'Logo';

export interface Props {
  onClick?: () => void,
  className?: string
}
export function BaseLogo(props: Props) {
  const { onClick, className } = props;
  return (
    <StyledBaseLogo onClick={onClick} className={className} data-id='logo' />
  );
}
