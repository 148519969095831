import * as React from "react";
import { forEach, find, map, omit, assign, cloneDeep } from "lodash";
import { Row } from "react-bootstrap";

import { FilterGroup } from "./filterGroup";
import { FilterStatus, IFilterStatus } from "./filterStatus";
import { FormControls } from "../forms/formControls";

import './filter.css';
import { Trans } from "@components/i18n/typedTrans";

export interface IProps {
  filterGroups: IFilterGroup[],
  currentFilter?: IFilterResult,
  onFilterChange: (result: IFilterResult) => void
}

export interface IFilterGroup {
  id: string,
  filterDisplayName: JSX.Element | string,
  valueDisplayName: string,
  availableValues: string[],
  icon?: string
}

export interface IFilterResult {
  [k: string]: string[]
}

export interface IState {
  modified: IFilterResult
}

export class FilterSelectionControl extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { modified: props.currentFilter ? cloneDeep(props.currentFilter) : {} };
  }

  public render() {
    const filterGroups = map(this.props.filterGroups, (filterGroup) => {
      return <React.Fragment key={'filter_control_group_' + filterGroup.id}>
        <FilterGroup id={filterGroup.id} filterName={filterGroup.filterDisplayName} icon={filterGroup.icon} availableValues={filterGroup.availableValues} selectedValues={this.state.modified[filterGroup.id] || []} onChange={this.handleChange} />
      </React.Fragment>;
    });

    return (
      <div className="eTable--container filter-selection-control">
        <div className="table-responsive">
          <Row className="filter-selection-control-groups filter-row">
            {filterGroups}
          </Row>
        </div>
        <div className="filter-footer">
          <div className="row">
            <div className="p-2">
              <Trans i18nKey='FILTERS' ns="translation">Filters</Trans>:<FilterStatus selectedFilters={this.getStatusFilters(this.state.modified)} onFilterRemoved={this.onFilterRemoved} />
            </div>
            <div className="filter-apply-block p-2 ml-auto" >
              <FormControls
                mode='apply'
                onCancel={this.onCancel}
                onSubmit={this.onApply}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  private onApply = () => {
    this.props.onFilterChange(this.state.modified);
  };

  private onCancel = () => {
    this.props.onFilterChange(this.props.currentFilter);
  };

  private onFilterRemoved = (removedFilterGroup: string) => {
    const newState = { modified: omit(this.state.modified, removedFilterGroup) };
    this.setState(newState);
  };

  private getStatusFilters(state: IFilterResult): IFilterStatus[] {
    const filterGroups: IFilterStatus[] = [];
    forEach(state, (selectedItems: string[], groupId: string) => {
      if (selectedItems && selectedItems.length > 0) {
        const filterGroup = find(this.props.filterGroups, { id: groupId });
        const displayState = selectedItems.length > 1 ? `${selectedItems.length} ${filterGroup.valueDisplayName}` : selectedItems[0];
        filterGroups.push({ id: groupId, display: displayState });
      }
    });

    return filterGroups;
  }

  private handleChange = (id: string, selectedValues: string[]) => {
    const newState = { modified: assign({}, this.state.modified, { [id]: selectedValues }) };
    this.setState(newState);
  };
}
