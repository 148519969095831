import React, { useCallback, useMemo } from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';

import { TTypedTFunction, useTranslation } from '@lib/useTypedTranslation';
import Card from '../../../components/card/card';
import { Option } from '../../../components/controls/optionPickerLib';
import { Filter } from '@components/timePeriodAndFilterPicker/useFilterPicker';
import styled from 'styled-components';
import { useAlignCharts } from '@lib/useAlignCharts';
import { useWorldAction } from '@lib/useWorldAction';
import { getUserTimeZone } from '@lib/timeZone';
import { IProcessVersion, IProcessesTrendsParams, exportProcessesCPUActivityAction, exportProcessesUserActivityAction, exportProcessesUtilizationTrendsAction, getProcessesUsageTrends, getProcessesUtilizationTrends } from '../../../services/core/processes';
import moment from 'moment';
import { RequestInitWithRetry } from '@lib/request';
import { useWorldRequest } from '@lib/useWorldRequest';
import { formatTitle } from '@components/data-table/dataFormatters';
import { getTitle } from './filterTitle';
import { Loading } from '@components/loading/loading';
import { StackedColumnsDateChart, IProps as IStackedColumnsDateChartProps } from '@components/chart/stackedColumnsDateChart';
import { ExportButton } from '@components/controls/exportButton';
import { formatTooltipDay } from '@components/chart/tooltips';
import { drawXAxisLabelsToToday } from '@components/chart/lib';
import { createYAxis } from '../../../lib/usageChartYAxis';
import { getProcessVersionName } from './lib';

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
`;

const Section = styled.div<{ bottomBorder?: boolean }>`
  padding-bottom: 0.625rem;
  margin: 1.5rem 2.5rem 0;
  position: relative;
`;

export interface Props {
  processName: string,
  productName: string,
  filter: Filter,
  processVersion?: IProcessVersion,
  timePeriod: Option<number>,
  versionsLoading: boolean
}

export const formatMinutes = (seconds: number) => `${Math.floor((seconds / 60))}`;
export const formatCount = (count: number) => `${count}`;

export const getDrawYAxis = <Data extends { date: string }>(dataKey: string, t: TTypedTFunction): IStackedColumnsDateChartProps<Data>['drawYAxis'] => {
  return (chart, yAxis) => {
    const maxActivitySeconds = Math.max(...chart.data.map((data) => data[dataKey]));
    const maxActivityHours = maxActivitySeconds / 60 / 60;
    (yAxis as am4charts.DurationAxis).baseUnit = "second";

    createYAxis(yAxis as am4charts.DurationAxis, maxActivityHours, t);

    yAxis.renderer.labels.template.disabled = true;
  };
};

export const ProcessPerformance: React.FC<Props> = (props) => {
  const { t } = useTranslation('coreProcesses');

  const timeZone = getUserTimeZone();

  const { addChart, clearCharts } = useAlignCharts(3);

  //#region utilization

  const processesUtilizationTrendsFetcher = useCallback(() => {
    clearCharts();
    const params = {
      processName: props.processName,
      productName: props.productName,
      days: props.timePeriod.value,
      homeLocationId: props.filter.homeLocation?.value.id,
      group: props.filter.group?.value,
      zoneId: props.filter.zone?.value.id,
      fileVersion: props.processVersion?.fileVersion,
      productVersion: props.processVersion?.productVersion,
      timeZone,
    };
    return (options: RequestInitWithRetry) => getProcessesUtilizationTrends(params)(options);
  }, [clearCharts, props.processName, props.productName, props.timePeriod, props.filter, props.processVersion?.fileVersion, props.processVersion?.productVersion, timeZone]);

  const { data: processesUtilizationTrends, loading: utilizationLoading } = useWorldRequest(processesUtilizationTrendsFetcher, { initialLoading: true });

  const onUtilizationExportClicked = async (action: (params: IProcessesTrendsParams) => Promise<any>): Promise<string> => {
    const params = {
      processName: props.processName,
      productName: props.productName,
      days: props.timePeriod.value,
      homeLocationId: props.filter.homeLocation?.value.id,
      group: props.filter.group?.value,
      zoneId: props.filter.zone?.value.id,
      fileVersion: props.processVersion?.fileVersion,
      productVersion: props.processVersion?.productVersion,
      timeZone,
    };
    return action(params);
  };
  const exportDeviceUsageData = useWorldAction(exportProcessesUtilizationTrendsAction);

  const processVersionName = props.processVersion && getProcessVersionName(props.processVersion);

  const utilizationTitle = getTitle({
    filter: props.filter,
    t,
    translationKey: 'SECTION_TITLE_UTILIZATION',
    version: processVersionName,
  });

  //#endregion

  //#region usage

  const processesUsageTrendsFetcher = useCallback(() => {
    clearCharts();
    const params = {
      processName: props.processName,
      productName: props.productName,
      days: props.timePeriod.value,
      homeLocationId: props.filter.homeLocation?.value.id,
      group: props.filter.group?.value,
      zoneId: props.filter.zone?.value.id,
      fileVersion: props.processVersion?.fileVersion,
      productVersion: props.processVersion?.productVersion,
      timeZone,
    };
    return (options: RequestInitWithRetry) => getProcessesUsageTrends(params)(options);
  }, [clearCharts, props.processName, props.productName, props.timePeriod.value, props.filter, props.processVersion?.fileVersion, props.processVersion?.productVersion, timeZone]);

  const { data: processesUsageTrends, loading: usageLoading } = useWorldRequest(processesUsageTrendsFetcher, { initialLoading: true });

  const [processesUserActivity, processesCPUActivity] = React.useMemo(() => {
    return (processesUsageTrends ?? []).reduce((acc, { date, userActivity, cpuActivity }) => {
      return [
        [...acc[0], {
          date,
          userActivity,
        }],
        [...acc[1], {
          date,
          cpuActivity,
        }]
      ];
    }, [[], []]);
  }, [processesUsageTrends]);

  const onUserActivityExportClicked = async (action: (params: IProcessesTrendsParams) => Promise<any>): Promise<string> => {
    const params = {
      processName: props.processName,
      productName: props.productName,
      days: props.timePeriod.value,
      homeLocationId: props.filter.homeLocation?.value.id,
      group: props.filter.group?.value,
      zoneId: props.filter.zone?.value.id,
      fileVersion: props.processVersion?.fileVersion,
      productVersion: props.processVersion?.productVersion,
      timeZone,
    };
    return action(params);
  };
  const exportUserActivityData = useWorldAction(exportProcessesUserActivityAction);

  const userActivityTitle = getTitle({
    filter: props.filter,
    t,
    translationKey: 'SECTION_TITLE_USAGE',
    version: processVersionName,
  });

  const onCPUActivityExportClicked = async (action: (params: IProcessesTrendsParams) => Promise<any>): Promise<string> => {
    const params = {
      processName: props.processName,
      productName: props.productName,
      days: props.timePeriod.value,
      homeLocationId: props.filter.homeLocation?.value.id,
      group: props.filter.group?.value,
      zoneId: props.filter.zone?.value.id,
      fileVersion: props.processVersion?.fileVersion,
      productVersion: props.processVersion?.productVersion,
      timeZone,
    };
    return action(params);
  };
  const exportCPUActivityData = useWorldAction(exportProcessesCPUActivityAction);

  const cpuActivityTitle = getTitle({
    filter: props.filter,
    t,
    translationKey: 'SECTION_TITLE_CPU_USAGE',
    version: processVersionName,
  });

  //#endregion

  const loading = props.versionsLoading || utilizationLoading || usageLoading;

  return (
    <div>
      <Card noPadding={true}>
        {loading ?
          <LoadingContainer><Loading isLoading transparentOverlay={false} /></LoadingContainer>
          :
          <Section>
            <StackedColumnsDateChart
              cypressId="process-utilization-chart"
              data={processesUtilizationTrends}
              formatData={formatCount}
              days={props.timePeriod.value}
              drawXAxis={(chart, xAxis) => drawXAxisLabelsToToday(chart, xAxis as am4charts.CategoryAxis, props.timePeriod.value, t)}
              formatTooltipDate={formatTooltipDay}
              series={[{
                dataKey: 'deviceCount',
                description: t('DEVICES_USED', { ns: 'coreProcesses' }),
                colour: "#4A6A89",
                visible: true
              }]}
              title={utilizationTitle}
              onMount={addChart}
              bottomBorder
              buttons={[{
                Component: ExportButton,
                props: {
                  filename: `${formatTitle(utilizationTitle)}.${moment.utc().toISOString()}.csv`,
                  exportFunction: () => onUtilizationExportClicked(exportDeviceUsageData)
                }
              }]}
              hasLegend={true}
              useAxisTooltip
            />
            <StackedColumnsDateChart
              cypressId="process-user-activity-chart"
              data={processesUserActivity}
              formatData={formatMinutes}
              days={props.timePeriod.value}
              drawXAxis={(chart, xAxis) => drawXAxisLabelsToToday(chart, xAxis as am4charts.CategoryAxis, props.timePeriod.value, t)}
              yAxisType='duration'
              drawYAxis={getDrawYAxis('userActivity', t)}
              formatTooltipDate={formatTooltipDay}
              series={[{
                dataKey: 'userActivity',
                description: t('MINUTES_USED', { ns: 'coreProcesses' }),
                colour: "#0fa336",
                visible: true
              }]}
              title={userActivityTitle}
              onMount={addChart}
              bottomBorder
              buttons={[{
                Component: ExportButton,
                props: {
                  filename: `${formatTitle(userActivityTitle)}.${moment.utc().toISOString()}.csv`,
                  exportFunction: () => onUserActivityExportClicked(exportUserActivityData)
                }
              }]}
              hasLegend={true}
              useAxisTooltip
            />
            <StackedColumnsDateChart
              cypressId="process-cpu-activity-chart"
              data={processesCPUActivity}
              formatData={formatMinutes}
              days={props.timePeriod.value}
              drawXAxis={(chart, xAxis) => drawXAxisLabelsToToday(chart, xAxis as am4charts.CategoryAxis, props.timePeriod.value, t)}
              yAxisType='duration'
              drawYAxis={getDrawYAxis('cpuActivity', t)}
              formatTooltipDate={formatTooltipDay}
              series={[{
                dataKey: 'cpuActivity',
                description: t('CPU_MINUTES_USED', { ns: 'coreProcesses' }),
                colour: "#49a1a9",
                visible: true
              }]}
              title={cpuActivityTitle}
              onMount={addChart}
              buttons={[{
                Component: ExportButton,
                props: {
                  filename: `${formatTitle(cpuActivityTitle)}.${moment.utc().toISOString()}.csv`,
                  exportFunction: () => onCPUActivityExportClicked(exportCPUActivityData)
                }
              }]}
              hasLegend={true}
              useAxisTooltip
            />
          </Section>
        }
      </Card>
    </div>
  );
};
