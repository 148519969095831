import * as React from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from '@lib/useTypedTranslation';
import { map, omit, find, get, partial } from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import Card from '../../components/card/card';
import { AmChart, defaultTheme } from './amChart';
import { useLanguageAndLocaleContext } from '../../context/languageAndLocale';

import '../shared/battery/style.css';
import './estate.css';

export interface IEstateCategory {
  id: string,
  label: string,
  colour: string,
  icon: string,
  path: string
}

interface IProps {
  title?: string | JSX.Element,
  data: { [key: string]: number, all: number },
  categories: IEstateCategory[],
  toolLabelSngl: string,
  toolLabelPlrl: string,
  htmlIcons?: boolean
}

export function Estate(props: IProps) {
  const total = get(props.data, 'all', 0);
  const title = props.title;

  const history = useHistory();
  const { t } = useTranslation('translation');
  const { locale } = useLanguageAndLocaleContext();

  return (
    <div className="estate">
      <Card>
        <div className="estate_container">
          <h1>{title || t('ESTATE_OVERVIEW_TABLE_TITLE')}</h1>
          <div className="row total" style={{ paddingBottom: '1em' }}>
            <div className="col-md-12">
              {t('ESTATE_OVERVIEW_SUBTITLE', { count: total, label: props.toolLabelPlrl })}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="estate_chart">
              <AmChart
                tag="estate-chart"
                chartProvider={partial(constructChart, history.push, props)}
                dataProvider={() => getChartData(props, locale)}
                link={props.data}
                cypressId="essentialsEstate"
              />
            </div>
          </div>
        </div>
      </Card>
    </div>);

}

/* istanbul ignore next */
function constructChart(navigateTo: (path: string) => void, props: IProps, id: string) {
  am4core.useTheme(defaultTheme);
  am4core.useTheme(am4themes_animated);
  let chart = am4core.create(id, am4charts.XYChart);

  let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  xAxis.dataFields.category = "label";
  xAxis.renderer.grid.template.location = 0;
  xAxis.renderer.grid.template.strokeWidth = 0;
  xAxis.renderer.minGridDistance = 30;
  xAxis.renderer.labels.template.textAlign = "middle";

  if (props.htmlIcons) {
    xAxis.renderer.labels.template.adapter.add("html", function (html, target) {
      return `<div><div>{category}</div><i class="{icon}"></i><div>{localisedRaw}</div></div>`;
    });
  } else {
    xAxis.renderer.labels.template.adapter.add("text", function (text, target) {
      return `[bold {colour}]{icon}[/]\n${text}\n{localisedRaw}`;
    });
  }

  let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
  yAxis.dataFields.data = "value";
  yAxis.numberFormatter = new am4core.NumberFormatter();
  yAxis.numberFormatter.numberFormat = "#'%'";
  yAxis.max = 100;
  yAxis.min = 0;

  let series = chart.series.push(new am4charts.ColumnSeries());
  series.dataFields.categoryX = "label";
  series.dataFields.valueY = "value";
  series.columns.template.tooltipText = "[bold]{categoryX} {valueY}%[/]\n{info}";
  series.columns.template.width = am4core.percent(40);
  series.columns.template.column.cornerRadiusTopLeft = 2;
  series.columns.template.column.cornerRadiusTopRight = 2;

  let category = props.categories;
  series.columns.template.events.on("hit", ev => {
    navigateTo(getCategoryPath(ev, category));
  });

  xAxis.renderer.labels.template.events.on("hit", ev => {
    navigateTo(getCategoryPath(ev, category));
  });

  series.columns.template.adapter.add("fill", function (fill, target) {
    return am4core.color((target.dataItem.dataContext as any).colour);
  });
  series.columns.template.adapter.add("stroke", function (stroke, target) {
    return am4core.color((target.dataItem.dataContext as any).colour);
  });

  function createGrid(value: number) {
    let range = yAxis.axisRanges.create();
    range.value = value;
    range.label.text = "{value}";
  }
  yAxis.renderer.grid.template.disabled = true;
  yAxis.renderer.labels.template.disabled = true;
  createGrid(0);
  createGrid(25);
  createGrid(50);
  createGrid(75);
  createGrid(100);

  return chart;
}

function getChartData(props: IProps, locale: string) {
  const result = map(omit(props.data, 'all'), (item, key) => {
    const category = find(props.categories, (item) => item.id === key);
    return {
      label: category.label,
      icon: category.icon,
      value: Math.round(item * 100 / props.data.all),
      raw: item,
      localisedRaw: item.toLocaleString(locale),
      info: getInfo(item, props.toolLabelSngl, props.toolLabelPlrl, locale),
      colour: category.colour
    };
  });
  return result;
}

function getInfo(value: number, toolLabelSngl: string, toolLabelPlrl: string, locale: string) {
  return `${value.toLocaleString(locale)} ${value === 1 ? toolLabelSngl : toolLabelPlrl}`;
}

export function getCategoryPath(event: any, category: IEstateCategory[]) {
  const currentCategory = find(category, (item) => { return item.label === get(event, 'target.dataItem.dataContext.label', null); });
  return `${currentCategory.path}`;
}
