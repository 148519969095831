import * as React from 'react';

import { RebootSettings } from './reboots';
import { LowPowerEventSettings } from './lowPowerEvents';
import { DropSettings } from './drops';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export interface IProps {
  worldSettings: WorldSettings,
  onDataChanged: (data: WorldSettings) => void,
  submitInProgress: boolean,
  dataTimestamp: number
}

export function UserExperienceSettings(props: IProps) {
  const settings = [
    <RebootSettings key="reboot" timeStamp={props.dataTimestamp} onDataChanged={props.onDataChanged} worldSettings={props.worldSettings} submitInProgress={props.submitInProgress} />,
    <LowPowerEventSettings key="low-power" timeStamp={props.dataTimestamp} onDataChanged={props.onDataChanged} worldSettings={props.worldSettings} submitInProgress={props.submitInProgress} />,
    <DropSettings key='drop' timeStamp={props.dataTimestamp} onDataChanged={props.onDataChanged} worldSettings={props.worldSettings} submitInProgress={props.submitInProgress} />
  ];


  return <div className="threshold-settings-composition user-experience">
    {settings}
  </div>;
}
