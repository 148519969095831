import { Option } from "@components/controls/optionPickerLib";
import { TTypedTFunction } from "@lib/useTypedTranslation";
import { IProcessVersion } from "services/core/processes";

export const getAllVersionsOption = (t: TTypedTFunction<'coreProcesses' | 'translation'>): Option<string> => ({
  name: t('ALL_PROCESS_VERSIONS', { ns: 'coreProcesses' }),
  value: undefined
});

export const getProcessVersionName = (version: IProcessVersion) => {
  return `${version.productVersion} / ${version.fileVersion}`;
};

export const parseProcessVersion = (name?: string): IProcessVersion | undefined => {
  if (!name) {
    return undefined;
  }
  const [productVersion, fileVersion] = name.split(' / ');
  return { productVersion, fileVersion };
};
