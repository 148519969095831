import { throttle } from "lodash";
import { useEffect, useMemo } from "react";

function addShadow() {
  const dataTable = document.querySelector(".core-devices-list .table") as HTMLTableElement;
  if (!dataTable) {
    return false;
  }
  const headerCells = dataTable.rows[0].cells;
  const finalScrollingRect = headerCells[headerCells.length - 2].getBoundingClientRect();
  const stickyRect = headerCells[headerCells.length - 1].getBoundingClientRect();

  const isHidden = finalScrollingRect.left === 0 && finalScrollingRect.right === 0;

  // n.b. stickyRect.left never becoming greater than finalScrollingRect.right has been observed so we allow a small margin for error
  if (!isHidden && (finalScrollingRect.right - stickyRect.left) < 5) {
    dataTable.classList.add("floating-status");
  } else {
    dataTable.classList.remove("floating-status");
  }
}

export function useDevicesListShadow() {
  const throttledAddShadow = useMemo(() => throttle(addShadow, 100), []);

  useEffect(() => {
    window.addEventListener('scroll', throttledAddShadow, true);
    window.addEventListener('resize', throttledAddShadow, true);
    return () => {
      window.removeEventListener('scroll', throttledAddShadow, true);
      window.removeEventListener('resize', throttledAddShadow, true);
    };
  }, [throttledAddShadow]);

  return { addShadow: throttledAddShadow };
}
