import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AuthState } from '@aws-amplify/ui-components';

import Header from '../../layout/header/Header';
import { useAuthContext } from '../../context/auth';
import { SignIn } from './signIn';
import { ForgotPassword } from './forgotPassword';
import { RequireNewPassword } from './requireNewPassword';
import { dispatchAuthStateChangeEvent, isSupportedAuthState, SupportedAuthState } from './utils';
import { valueof } from '../../lib/typeUtils';

export const titles: { [k in SupportedAuthState]: string } = {
  [AuthState.SignIn]: 'Sign In',
  [AuthState.ForgotPassword]: 'Password Reset',
  [AuthState.ResetPassword]: 'New Password'
};

export const components: { [k in SupportedAuthState]: () => JSX.Element } = {
  [AuthState.SignIn]: SignIn,
  [AuthState.ForgotPassword]: ForgotPassword,
  [AuthState.ResetPassword]: RequireNewPassword
};

const getCurrent = (authState: AuthState): [valueof<typeof titles>, valueof<typeof components>] => {
  if (isSupportedAuthState(authState)) {
    return [titles[authState], components[authState]];
  }
  throw new Error(`authState ${authState} is not supported`);
};

export const Authenticator = () => {
  const { authState } = useAuthContext();

  const goToSignIn = () => {
    if (authState !== AuthState.SignIn) {
      dispatchAuthStateChangeEvent(AuthState.SignIn);
    }
  };

  const [title, Component] = getCurrent(authState);
  return <>
    <Helmet><title>{title}</title></Helmet>
    <Header onLogoClick={goToSignIn} />
    <Component />
  </>;
};
