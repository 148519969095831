import { request, RequestInitWithRetry } from '../../lib/request';
import { DeviceEventName } from './eventsTypes';

export interface IPerformanceChartParams {
  days: number,
  timeZone: string,
  homeLocationId?: string | null,
  group?: string | null,
  zoneId?: string | null
}

export interface IDevicesUtilization {
  date: string,
  deviceCount: number
}

export interface IDevicesDataUsage {
  date: string,
  mobile: number,
  wifi: number
}

export interface IDeviceEventCount {
  date: string,
  count: number
}

export interface IDevicePerformanceEventsParams extends IPerformanceChartParams {
  name: DeviceEventName
}

export function getCorePerformanceDevicesUtilization(params: IPerformanceChartParams) {
  return async (options: RequestInitWithRetry): Promise<IDevicesUtilization[]> => {
    const input = {
      path: '/api/core/performance/devicesUtilization',
      body: params
    };
    return request(input, options);
  };
}

export function exportDevicesUtilizationChartAction(options: RequestInitWithRetry) {
  return async function (params: IPerformanceChartParams) {
    const input = {
      path: `/api/core/performance/exportDevicesUtilization`,
      body: params,
    };
    return request(input, options);
  };
}

export function getCorePerformanceDataUsage(params: IPerformanceChartParams) {
  return async (options: RequestInitWithRetry): Promise<IDevicesDataUsage[]> => {
    const input = {
      path: '/api/core/performance/dataUsage',
      body: params,
      method: 'post'
    };
    return request(input, options);
  };
}

export function exportDeviceDataUsageChartAction(options: RequestInitWithRetry) {
  return async function (params: IPerformanceChartParams) {
    const input = {
      path: `/api/core/performance/exportDataUsage`,
      body: params,
    };
    return request(input, options);
  };
}

export function getCorePerformanceEvent(params: IDevicePerformanceEventsParams) {
  return async (options: RequestInitWithRetry): Promise<IDeviceEventCount[]> => {
    const input = {
      path: '/api/core/performance/deviceEvents',
      body: params,
      method: 'post'
    };
    return request(input, options);
  };
}

export function exportDeviceEventChartAction(options: RequestInitWithRetry) {
  return async function (params: IDevicePerformanceEventsParams) {
    const input = {
      path: `/api/core/performance/exportDeviceEvents`,
      body: params,
    };
    return request(input, options);
  };
}
