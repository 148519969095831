import React, { useEffect, useRef } from 'react';

export type IAddTimeout = (handler: (...args: any[]) => void, ms: number) => NodeJS.Timeout;

/** a hook to cleanup timeouts when component unmounts, preventing errors if they
 * resolve afterwards and attempt to mutate component state */
export const useTimeout = () => {
  const timeoutIdsRef = useRef<NodeJS.Timeout[]>([]);
  const [hasActiveTimeouts, setHasActiveTimeouts] = React.useState(false);

  const addTimeout: IAddTimeout = (handler, ms) => {
    setHasActiveTimeouts(true);

    let timeout: NodeJS.Timeout;

    timeout = setTimeout(() => {
      handler();

      const newTimeoutIds = [...timeoutIdsRef.current];
      const timeoutIndex = newTimeoutIds.indexOf(timeout);

      newTimeoutIds.splice(timeoutIndex, 1);

      timeoutIdsRef.current = newTimeoutIds;

      if (timeoutIdsRef.current.length === 0) {
        setHasActiveTimeouts(false);
      }
    }, ms);

    timeoutIdsRef.current = [...timeoutIdsRef.current, timeout];

    return timeout;
  };

  useEffect(() => {
    return () => {
      setHasActiveTimeouts(false);
      timeoutIdsRef.current.forEach(timeoutId => clearTimeout(timeoutId));
      timeoutIdsRef.current = [];
    };
  }, []);

  return { addTimeout, timeoutIdsRef, hasActiveTimeouts };
};
