import * as moment from 'moment';
import { Moment } from 'moment';

import { request } from '../../lib/request';
import { DeviceEventName, DeviceEvent } from './eventsTypes';
import { ISortResult } from '../shared/common';
import { WithOptional } from '../../lib/typeUtils';
import { RequestInitWithRetry } from '../../lib/request';

export interface IDeviceUsage {
  date: string,
  used: number
}

export interface IDevicePerformanceParams {
  deviceId: string,
  to: number
}

export interface IDevicePerformanceOverviewParams extends IDevicePerformanceParams {
  events: DeviceEventName[]
}

export interface ILast30DaysDevicePerformanceParams extends IDevicePerformanceParams {
  from: number
}

// `to` and `from` not required, but permitted in args to allow 30 day param to have universal shape
export type Last30DaysAverageDischargeParams = WithOptional<ILast30DaysDevicePerformanceParams, 'to' | 'from'>;

export interface ILast30DaysDevicePerformanceOverviewParams extends IDevicePerformanceOverviewParams {
  from: number
}

export interface IDeviceEventParams {
  limit?: number,
  offset?: number,
  sort?: ISortResult,
  deviceId: string,
  to: number,
  events: DeviceEventName[]
}

export interface ILast30DaysDeviceEventParams extends IDeviceEventParams {
  from: number
}

export interface IDeviceEventsCount {
  count: number
}

export interface IDeviceDataUsage {
  date: string,
  mobile: number,
  wifi: number
}

export interface IDeviceDischarge {
  date: number,
  level: number
}

export interface IBatteryLevel {
  level: number,
  local: string,
  smartBattery: {
    serialNumber: string,
    id: string
  }
}

export interface IPerformanceOverviewData {
  wifiDataUsed: number,
  mobileDataUsed: number,
  dropCount: number,
  rebootCount: number,
  lowBatteryCount: number,
  batteryChangedCount: number,
  timeErrorCount: number,
  smartBatteries: {
    id: string,
    batteryStatus: string,
    serialNumber: string
  }[],
  updated: string
}

/** Return `from` and `to` params for 30 day queries in device local time */
export const getLast30DaysDateParams = (now: Moment, timeZoneOffset: number | null) => {
  const localNow = moment.utc(now).add(timeZoneOffset || 0, 'minutes');
  return {
    from: moment.utc(localNow).startOf('day').subtract(29, 'days').valueOf(),
    to: moment.utc(localNow).endOf('hour').valueOf()
  };
};

export const getLast24HourDeviceUsage = (params: IDevicePerformanceParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceUsage[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceUsage/last24Hours',
      qs: { deviceId: params.deviceId, to: params.to },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast30DayDeviceUsage = (params: ILast30DaysDevicePerformanceParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceUsage[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceUsage/last30Days',
      qs: { deviceId: params.deviceId, to: params.to, from: params.from },
      body: {
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast24HourDeviceDataUsage = (params: IDevicePerformanceParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceDataUsage[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceDataUsage/last24Hours',
      qs: { deviceId: params.deviceId, to: params.to },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast30DayDeviceDataUsage = (params: ILast30DaysDevicePerformanceParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceDataUsage[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceDataUsage/last30Days',
      qs: { deviceId: params.deviceId, to: params.to, from: params.from },
      body: {
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getSelectedDateDeviceUsage = (params: IDevicePerformanceParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceUsage[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceUsage/selectedDate',
      qs: { deviceId: params.deviceId, to: params.to },
      body: {
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getSelectedDateDeviceDataUsage = (params: IDevicePerformanceParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceDataUsage[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceDataUsage/selectedDate',
      qs: { deviceId: params.deviceId, to: params.to },
      body: {
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast30DayDeviceDischarge = (params: Last30DaysAverageDischargeParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceDischarge[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceChargeLevel/last30Days',
      qs: { deviceId: params.deviceId, to: params.to, from: params.from },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getSelectedDateDeviceChargeLevel = (params: IDevicePerformanceParams) => {
  return (options: RequestInitWithRetry): Promise<IBatteryLevel[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceChargeLevel/selectedDate',
      qs: { deviceId: params.deviceId, to: params.to },
      body: {
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast24HourDeviceChargeLevel = (params: IDevicePerformanceParams) => {
  return (options: RequestInitWithRetry): Promise<IBatteryLevel[]> => {
    const input = {
      path: '/api/core/devicePerformance/deviceChargeLevel/last24Hours',
      qs: { deviceId: params.deviceId, to: params.to },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast24HourDeviceEventsList = (params: IDeviceEventParams) => {
  return async (options: RequestInitWithRetry): Promise<DeviceEvent[]> => {
    if (!params.events.length) {
      return [] as any;
    }
    const input = {
      path: `/api/core/devicePerformance/deviceEventsList/last24Hours`,
      qs: { deviceId: params.deviceId, to: params.to, limit: params.limit, offset: params.offset },
      body: {
        sort: params.sort,
        events: params.events,
      },
      method: 'post'
    };
    return await request(input, options);
  };
};

export const getLast30DayDeviceEventsList = (params: ILast30DaysDeviceEventParams) => {
  return (options: RequestInitWithRetry): Promise<DeviceEvent[]> => {
    if (!params.events.length) {
      return [] as any;
    }
    const input = {
      path: `/api/core/devicePerformance/deviceEventsList/last30Days`,
      qs: { deviceId: params.deviceId, to: params.to, from: params.from, limit: params.limit, offset: params.offset },
      body: {
        sort: params.sort,
        events: params.events,
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getSelectedDateDeviceEventsList = (params: IDeviceEventParams) => {
  return (options: RequestInitWithRetry): Promise<DeviceEvent[]> => {
    if (!params.events.length) {
      return [] as any;
    }
    const input = {
      path: `/api/core/devicePerformance/deviceEventsList/selectedDate`,
      qs: { deviceId: params.deviceId, to: params.to, limit: params.limit, offset: params.offset },
      body: {
        sort: params.sort,
        events: params.events,
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast24HoursDeviceEventsCount = (params: IDeviceEventParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceEventsCount> => {
    if (!params.events.length) {
      return { count: 0 } as any;
    }
    const input = {
      path: `/api/core/devicePerformance/deviceEventsCount/last24Hours`,
      qs: { deviceId: params.deviceId, to: params.to },
      body: {
        events: params.events,
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast30DaysDeviceEventsCount = (params: ILast30DaysDeviceEventParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceEventsCount> => {
    if (!params.events.length) {
      return { count: 0 } as any;
    }
    const input = {
      path: `/api/core/devicePerformance/deviceEventsCount/last30Days`,
      qs: { deviceId: params.deviceId, to: params.to, from: params.from },
      body: {
        events: params.events,
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getSelectedDateDeviceEventsCount = (params: IDeviceEventParams) => {
  return (options: RequestInitWithRetry): Promise<IDeviceEventsCount> => {
    if (!params.events.length) {
      return { count: 0 } as any;
    }
    const input = {
      path: `/api/core/devicePerformance/deviceEventsCount/selectedDate`,
      qs: { deviceId: params.deviceId, to: params.to },
      body: {
        events: params.events,
      },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast24HourPerformanceOverview = (params: IDevicePerformanceOverviewParams) => {
  return (options: RequestInitWithRetry): Promise<IPerformanceOverviewData> => {
    const input = {
      path: `/api/core/devicePerformance/overview/last24Hours`,
      qs: { deviceId: params.deviceId, to: params.to },
      body: { events: params.events },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getLast30DayPerformanceOverview = (params: ILast30DaysDevicePerformanceOverviewParams) => {
  return (options: RequestInitWithRetry): Promise<IPerformanceOverviewData> => {
    const input = {
      path: `/api/core/devicePerformance/overview/last30Days`,
      qs: { deviceId: params.deviceId, to: params.to, from: params.from },
      body: { events: params.events },
      method: 'post'
    };
    return request(input, options);
  };
};

export const getSelectedDatePerformanceOverview = (params: IDevicePerformanceOverviewParams) => {
  return (options: RequestInitWithRetry): Promise<IPerformanceOverviewData> => {
    const input = {
      path: `/api/core/devicePerformance/overview/selectedDate`,
      qs: { deviceId: params.deviceId, to: params.to },
      body: { events: params.events },
      method: 'post'
    };
    return request(input, options);
  };
};
