// TODO remove dependency on Buffer, or add Buffer npm package.
// This is a node global, only available to us since some of our dependencies
// depend on a package that adds Buffer to the global scope.

export const encode = (id: string) => {
  return Buffer.from(id).toString('base64');
};

export const decode = (base64: string) => {
  return Buffer.from(base64, 'base64').toString();
};
