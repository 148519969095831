import { ISortResult } from "../shared/common";
import { get } from 'lodash';
import { request } from "../../lib/request";
import { AlertsData } from '../../components/alertsPanel/alertMessages';
import { RequestInitWithRetry } from '../../lib/request';
import { AtLeastOne } from '../../lib/typeUtils';

export interface IDevicesData extends AlertsData {
  id: string,
  manufacturer: string,
  model: string,
  serialNumber: string,
  imei: string,
  assetTag: string,
  group: string[],
  updated: string,
  mobileDataUsage: number,
  wifiDataUsage: number,
  operatingSystem: IOperatingSystem,
  bios: IBios,
  firmware: IFirmware,
  deviceName: string,
  clientVersion: string,
  extensionVersion: string,
  storage: IStorage
}

export interface IStorage {
  percentageUsed: number
}

export interface IOperatingSystem {
  buildVersion: string
}

export interface IBios {
  version: string
}

export interface IFirmware {
  version: string
}

export interface IListFilter {
  manufacturers?: string[],
  models?: string[],
  groups?: Array<string | null>,
  homeLocations?: string[],
  status?: string[],
  alertStatus?: string[]
}

export interface IListOptions {
  limit: number,
  offset: number,
  search?: string,
  sort?: ISortResult,
  filter?: IListFilter
}

export interface IDevicesListData {
  list: IDevicesData[],
  totalCount: number
}

export interface IDevicesListCount {
  count: number
}

export interface IDeviceLocationData {
  id: string,
  location: {
    accuracy: number,
    source: string,
    position: string,
    latitude: number,
    longitude: number,
    time: string,
    utc: string
  },
  homeLocation: {
    id: string,
    name: string,
    latitude: number,
    longitude: number,
    position: string,
    radius: number
  },
  timeZone: {
    name: string
  }
}

export interface IDeviceInfo {
  id: string,
  platformType: string,
  manufacturer: string,
  model: string,
  serialNumber: string,
  assetTag: string,
  imei: string,
  timeZoneOffset: number | null // device local offset from UTC in minutes
}

export interface IAssetTagParams {
  id: string,
  assetTag: string
}

export interface IDeleteParams {
  ids: string[]
}

export interface IDeleteResponse {
  [id: string]: {
    success: boolean
  }
}

export interface IHomeLocationParams {
  id: string,
  homeLocationId: string
}

export interface IGroupParams {
  id: string,
  groups: string[]
}

interface Identifiers {
  id?: string,
  serialNumber?: string,
  assetTag?: string,
  imei?: string
}
export type DeviceIdentifiers = AtLeastOne<Identifiers>;

const getFilterParams = (filter: IListFilter = {}) => {
  const alertStatuses = (filter.alertStatus || []).reduce((acc, id) => {
    const [status, filter] = id.split('-');
    acc[status] = [...(acc[status] || []), filter];
    return acc;
  }, {} as { [s: string]: string[] });
  return {
    manufacturers: filter.manufacturers || [],
    models: filter.models || [],
    groups: filter.groups || [],
    homeLocations: filter.homeLocations || [],
    status: filter.status || [],
    alertStatuses
  };
};

export function getDevicesList(params: IListOptions) {
  return async (options: RequestInitWithRetry): Promise<IDevicesListData> => {
    const input = {
      path: `/api/core/devices/list`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        sort: get(params, 'sort'),
        search: get(params, 'search'),
        ...getFilterParams(params.filter)
      }
    };
    return request(input, options);
  };
}

export function exportDevicesListAction(requestOptions: RequestInitWithRetry) {
  return async (options: IListOptions): Promise<string> => {
    const input = {
      path: `/api/core/devices/exportList`,
      qs: { limit: options.limit, offset: options.offset },
      body: {
        sort: get(options, 'sort'),
        search: get(options, 'search'),
        ...getFilterParams(options.filter)
      },
      method: 'post'
    };
    return request(input, requestOptions);
  };
}

export function getDeviceListCountAction(options: RequestInitWithRetry) {
  return async (filter: IListFilter): Promise<IDevicesListCount> => {
    const input = {
      path: `/api/core/devices/count`,
      body: getFilterParams(filter),
      method: 'post'
    };
    return request(input, options);
  };
}

export function getDeviceLocation(deviceId: string) {
  return async (options: RequestInitWithRetry): Promise<IDeviceLocationData> => {
    const input = {
      path: `/api/core/device/location`,
      qs: { deviceId },
      method: 'get'
    };
    return request(input, options);
  };
}

export function getDeviceIds(identifiers: DeviceIdentifiers) {
  return async (options: RequestInitWithRetry): Promise<string[]> => {
    const input = {
      path: `/api/core/device/ids`,
      qs: { ...identifiers },
    };
    return request(input, options);
  };
}

export function getDeviceInfo(deviceId: string) {
  return async (options: RequestInitWithRetry): Promise<IDeviceInfo> => {
    const input = {
      path: `/api/core/device/info`,
      qs: { deviceId },
    };
    return request(input, options);
  };
}

export function deleteDevicesAction(options: RequestInitWithRetry) {
  return async (params: IDeleteParams): Promise<IDeleteResponse> => {
    const input = {
      path: '/api/core/devices/delete',
      method: 'POST',
      body: params
    };
    return request(input, options);
  };
}

export function setAssetTagAction(options: RequestInitWithRetry) {
  return async (params: IAssetTagParams): Promise<{ success: boolean }> => {
    const input = {
      path: '/api/core/device/setDeviceAssetTag',
      method: 'POST',
      body: params
    };
    return request(input, options);
  };
}

export function setHomeLocationAction(options: RequestInitWithRetry) {
  return async (params: IHomeLocationParams): Promise<{ success: boolean }> => {
    const input = {
      path: '/api/core/device/setDeviceHomeLocation',
      method: 'POST',
      body: params
    };
    return request(input, options);
  };
}

export function setGroupsAction(options: RequestInitWithRetry) {
  return async (params: IGroupParams): Promise<{ success: boolean }> => {
    const input = {
      path: '/api/core/device/setDeviceGroups',
      method: 'POST',
      body: params
    };
    return request(input, options);
  };
}

export interface Sim {
  serialNumber: string,
  operatorCode: string,
  operatorName: string
}

export interface IDeviceDetailsCore extends AlertsData {
  id: string,
  platformType: string,
  imei: string,
  manufacturer: string,
  model: string,
  serialNumber: string,
  assetTag: string,
  clientVersion: string,
  extensionVersion?: string,
  group: string[],
  firstSeen: string,
  operatingSystem: {
    buildVersion: string
  },
  firmware: {
    version: string
  },
  timeZone: {
    name: string
  },
  accessPoint: {
    ssid: string,
    bssid: string,
    updated: string,
    homeLocationId: string,
    homeLocation: {
      name: string
    }
  },
  deviceName: string,
  totalPhysicalMemory: number,
  processor: {
    name: string
  },
  storage: [{
    available: number,
    used: number,
    total: number
  }],
  smartBatteries: {
    id: string,
    serialNumber: string,
    batteryStatus: string
  }[],
  sims: Sim[],
  bios: {
    version: string
  }
}

export function getDeviceDetails(deviceId: string) {
  return async (options: RequestInitWithRetry): Promise<IDeviceDetailsCore> => {
    const input = {
      path: `/api/core/device/details`,
      qs: { deviceId },
      method: 'get'
    };
    return await request(input, options);
  };
}
