import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

function setChartLegendPercentage(chart: am4charts.XYChart, chartSizePercent: number) {
  chart.height = am4core.percent(chartSizePercent);
  chart.legend.height = am4core.percent(100 - chartSizePercent);
}

export function adjustChartAndLegendRatio(chart: am4charts.XYChart, defaultScreenPercentage: number, smallerScreenPercentage: number) {
  if (window.innerWidth <= 818) {
    setChartLegendPercentage(chart, smallerScreenPercentage);
  } else {
    setChartLegendPercentage(chart, defaultScreenPercentage);
  }
  if (window.innerWidth <= 650) {
    chart.legend.labels.template.truncate = true;
    chart.legend.labels.template.adapter.add("maxWidth", function () { return 100; });
  } else {
    chart.legend.labels.template.truncate = false;
    chart.legend.labels.template.adapter.add("maxWidth", function () { return 150; });
  }
}
