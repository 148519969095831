import { cloneDeep, get, identity, isEmpty, isEqual, isNil, isUndefined, mapValues, pickBy, set } from 'lodash';
import * as React from 'react';
import { ReactNode } from 'react';

import { tuple } from '../../lib/typeUtils';
import { FieldProps } from './formField';
import { TTypedTFunction } from '@lib/useTypedTranslation';

export type InputProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  | 'id'
  | 'className'
  | 'name'
  | 'onChange'
  | 'onBlur'
  | 'disabled'
  | 'required'
  | 'value'
>;

export type RawValidationErrors<ErrorKeys extends string> = Partial<Record<ErrorKeys, (t: TTypedTFunction) => string>>;
export type ValidationErrors<ErrorKeys extends string> = Partial<Record<ErrorKeys, string>>;
export interface FieldConfig<FieldNames extends readonly string[], ErrorKeys extends string> {
  name: ReactNode,
  required?: boolean,
  readOnly?: boolean,
  disabled?: boolean,
  validate?: (value: string, values?: FieldValues<FieldNames>) => ErrorKeys,
  onChange?: (value: string) => string, // mutate value on input, e.g. limit length
  validationErrors?: ValidationErrors<ErrorKeys>,
  validateOnChange?: boolean,
  alsoValidate?: FieldNames[number][],
  classes?: string | string[],
  inputProps?: InputProps,
  afterInputText?: string,
  renderInput?: (props: FieldProps<FieldNames, ErrorKeys>) => JSX.Element,
  extractValue?: (value: any) => string, // convert initial data value to input string value
  prepareValue?: (value: string) => any, // prepare input string value before saving
  dataPath?: string // can be used to assist conversion between field values and data object
}

export interface RawFormField<FieldNames extends readonly string[], ErrorKeys extends string> extends Omit<FieldConfig<FieldNames, ErrorKeys>, 'name' | 'afterInputText' | 'validationErrors'> {
  name: (t: TTypedTFunction) => ReactNode,
  afterInputText?: (t: TTypedTFunction) => string,
  validationErrors?: RawValidationErrors<ErrorKeys>
}

export type FormAction<FieldNames extends readonly string[], ErrorKeys extends string> =
  | { type: 'onClear' }
  | { type: 'onReset' }
  | { type: 'onNew', initialValues: FormState<FieldNames, ErrorKeys>['initialValues'] }
  | { type: 'onUpdateConfig', fieldsConfig: FormState<FieldNames, ErrorKeys>['fieldsConfig'] }
  | { type: 'onChange', fieldName: FieldNames[number], value: string }
  | { type: 'onValidate', fieldName: FieldNames[number] };

export type FieldsConfig<FieldNames extends readonly string[], ErrorKeys extends string> = {
  [fieldName in FieldNames[number]]: FieldConfig<FieldNames, ErrorKeys>
};

export type RawFieldsConfig<FieldNames extends readonly string[], ErrorKeys extends string> = {
  [fieldName in FieldNames[number]]: RawFormField<FieldNames, ErrorKeys>
};

export type FieldValues<FieldNames extends readonly string[]> = Partial<{
  [fieldName in FieldNames[number]]: string
}>;

export type FieldErrors<FieldNames extends readonly string[], ErrorKeys extends string> = Partial<{
  [fieldName in FieldNames[number]]: ErrorKeys
}>;

/** convert raw config to one containing translated strings */
export const translateFieldsConfig = <FieldNames extends readonly string[], ErrorKeys extends string>(rawConfig: RawFieldsConfig<FieldNames, ErrorKeys>, t: TTypedTFunction): FieldsConfig<FieldNames, ErrorKeys> => {
  const config = cloneDeep(rawConfig) as any;
  Object.entries<RawFormField<FieldNames, ErrorKeys>>(rawConfig).forEach(([key, value]) => {
    config[key] = {
      ...value,
      name: value.name(t)
    };
    if (config[key].afterInputText) {
      config[key].afterInputText = config[key].afterInputText(t);
    }
    if (config[key].validationErrors) {
      config[key].validationErrors = mapValues(config[key].validationErrors, val => val(t));
    }
  });
  return config as FieldsConfig<FieldNames, ErrorKeys>;
};

/** convert data object to field values using dataPath config property */
export const extractFieldValues = <FieldNames extends readonly string[], ErrorKeys extends string>(fieldNames: FieldNames, data: any, fieldsConfig: FieldsConfig<FieldNames, ErrorKeys>): FieldValues<FieldNames> => {
  return fieldNames.reduce((values, fieldName) => {
    const { extractValue = String, dataPath = fieldName } = fieldsConfig[fieldName as FieldNames[number]];
    const value = get(data, dataPath);
    if (!isNil(value)) {
      values[fieldName] = extractValue(value);
    }
    return values;
  }, {} as any) as FieldValues<FieldNames>;
};

/** convert field values to data object using dataPath config property */
export const prepareFieldValues = <
  FieldNames extends readonly string[],
  ErrorKeys extends string,
  Data = any
>(fieldNames: FieldNames, values: FieldValues<FieldNames>, fieldsConfig: FieldsConfig<FieldNames, ErrorKeys>): Data => {
  return fieldNames.reduce((data, fieldName) => {
    const value = values[fieldName as FieldNames[number]];
    const { prepareValue = identity, dataPath = fieldName, readOnly, disabled } = fieldsConfig[fieldName as FieldNames[number]];
    if (readOnly || disabled) {
      return data;
    }
    if (value === '') {
      set(data, dataPath, null);
    } else if (!isUndefined(value)) {
      set(data, dataPath, prepareValue(value));
    }
    return data;
  }, {} as any) as Data;
};

export interface FormState<FieldNames extends readonly string[], ErrorKeys extends string> {
  fieldsConfig: FieldsConfig<FieldNames, ErrorKeys>,
  initialValues: FieldValues<FieldNames>,
  values: FieldValues<FieldNames>,
  errors: FieldErrors<FieldNames, ErrorKeys>
}

export interface ComputedFormState<FieldNames extends readonly string[], ErrorKeys extends string> extends FormState<FieldNames, ErrorKeys> {
  readonly isDirty: boolean,
  readonly isValid: boolean,
  readonly isComplete: boolean
}

export interface InitialFormState<FieldNames extends readonly string[], ErrorKeys extends string> {
  fieldsConfig: FieldsConfig<FieldNames, ErrorKeys>,
  initialValues?: FieldValues<FieldNames>
}

export function formReducerInit<FieldNames extends readonly string[], ErrorKeys extends string>({ initialValues = {}, fieldsConfig }: InitialFormState<FieldNames, ErrorKeys>): FormState<FieldNames, ErrorKeys> {
  return {
    fieldsConfig,
    initialValues: initialValues,
    values: initialValues,
    errors: {}
  };
}

const validateField = <FieldNames extends readonly string[], ErrorKeys extends string>(
  fieldName: FieldNames[number],
  state: FormState<FieldNames, ErrorKeys>
): FormState<FieldNames, ErrorKeys> => {
  let fieldError;
  const value = state.values[fieldName];
  const { validate, disabled } = state.fieldsConfig[fieldName];
  if (value && !disabled) {
    fieldError = validate && validate(value, state.values);
  }
  const updatedErrors = pickBy<FormState<FieldNames, ErrorKeys>['errors']>(({ ...state.errors, [fieldName]: fieldError }), identity);
  return { ...state, errors: updatedErrors };
};


const validateFields = <FieldNames extends readonly string[], ErrorKeys extends string>(
  fieldNames: FieldNames[number][],
  state: FormState<FieldNames, ErrorKeys>
): FormState<FieldNames, ErrorKeys> => {
  return fieldNames.reduce((updatedState, fieldName) => validateField(fieldName, updatedState), state);
};

const validateAllFields = <FieldNames extends readonly string[], ErrorKeys extends string>(
  state: FormState<FieldNames, ErrorKeys>
): FormState<FieldNames, ErrorKeys> => {
  return validateFields([...new Set([...Object.keys(state.values), ...Object.keys(state.errors)])], state);
};

const validate = <FieldNames extends readonly string[], ErrorKeys extends string>(
  fieldName: FieldNames[number],
  state: FormState<FieldNames, ErrorKeys>
): FormState<FieldNames, ErrorKeys> => {
  const { alsoValidate } = state.fieldsConfig[fieldName];
  const updatedState = validateField(fieldName, state);
  if (alsoValidate) {
    // alsoValidate is intentionally not a recursive process, each field should describe its own relationships completely
    return validateFields(alsoValidate, updatedState);
  }
  return updatedState;
};

export const getFormReducer = <FieldNames extends readonly string[], ErrorKeys extends string>() => {
  return (state: FormState<FieldNames, ErrorKeys>, action: FormAction<FieldNames, ErrorKeys>): FormState<FieldNames, ErrorKeys> => {
    if (action.type === 'onClear') {
      return { ...state, errors: {}, initialValues: {}, values: {} };
    }
    if (action.type === 'onReset') {
      return { ...state, errors: {}, values: state.initialValues };
    }
    if (action.type === 'onNew') {
      const { initialValues } = action;
      return { ...state, errors: {}, initialValues: initialValues, values: initialValues };
    }
    if (action.type === 'onUpdateConfig') {
      const { fieldsConfig } = action;
      const updatedState: FormState<FieldNames, ErrorKeys> = { ...state, fieldsConfig };
      return validateAllFields(updatedState);
    }
    if (action.type === 'onChange') {
      const { fieldName, value } = action;
      const { onChange = identity, readOnly, validateOnChange } = state.fieldsConfig[fieldName];
      if (readOnly) { return state; }
      const newValue = onChange(value);
      const updatedValues: FormState<FieldNames, ErrorKeys>['values'] = { ...state.values, [fieldName]: newValue };
      const updatedState: FormState<FieldNames, ErrorKeys> = { ...state, values: updatedValues };
      if (validateOnChange) {
        return validate<FieldNames, ErrorKeys>(fieldName, updatedState);
      }
      return updatedState;
    }
    if (action.type === 'onValidate') {
      const { fieldName } = action;
      return validate<FieldNames, ErrorKeys>(fieldName, state);
    }
    return state;
  };
};

export const useFormReducer = <FieldNames extends readonly string[], ErrorKeys extends string>(initialState: InitialFormState<FieldNames, ErrorKeys>) => {
  const reducer = React.useMemo(() => dependencies.getFormReducer<FieldNames, ErrorKeys>(), []);
  const [data, dispatch] = React.useReducer(reducer, initialState, formReducerInit);
  const dispatchers = React.useMemo(() => {
    const onClear = () => {
      return dispatch({ type: 'onClear' });
    };
    const onReset = () => {
      return dispatch({ type: 'onReset' });
    };
    const onNew = (initialValues: FormState<FieldNames, ErrorKeys>['initialValues']) => {
      return dispatch({ type: 'onNew', initialValues });
    };
    const onUpdateConfig = (fieldsConfig: FormState<FieldNames, ErrorKeys>['fieldsConfig']) => {
      return dispatch({ type: 'onUpdateConfig', fieldsConfig });
    };
    const onChange = (fieldName: FieldNames[number], value: string) => {
      return dispatch({ type: 'onChange', fieldName, value });
    };
    const onValidate = (fieldName: FieldNames[number]) => {
      return dispatch({ type: 'onValidate', fieldName });
    };
    return {
      onClear,
      onReset,
      onNew,
      onUpdateConfig,
      onChange,
      onValidate
    };
  }, [dispatch]);
  const computedData: ComputedFormState<FieldNames, ErrorKeys> = React.useMemo(() => {
    return {
      ...data,
      isDirty: !isEqual(data.initialValues, data.values),
      isValid: isEmpty(data.errors),
      isComplete: !Object.entries<typeof data.fieldsConfig[FieldNames[number]]>(data.fieldsConfig).some(([fieldName, { required }]) => {
        return required && !data?.values[fieldName as keyof typeof data.fieldsConfig]?.trim();
      })
    };
  }, [data]);
  return tuple(computedData, dispatchers);
};

export const dependencies = {
  getFormReducer
};
