import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { AuthState } from '@aws-amplify/ui-components';

import Card from '../card/card';
import { useAuthContext } from '../../context/auth';
import { handleSignIn, dispatchAuthStateChangeEvent, getErrorMessage } from './utils';
import { devLogger } from '../../lib/logger';


import './style.css';

import logInImg from '../../layout/imgs/logIn.png';

const intentionalSpace = ' '; // i.e. this space is required for layout

export const SignIn = () => {
  const { authConfig } = useAuthContext();
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [pending, setPending] = useState(false);
  const [usernameTrim, passwordTrim] = [username.trim(), password.trim()];

  const provider = authConfig?.userAuth?.oauth?.provider;
  const canSignIn = usernameTrim && passwordTrim && !pending;
  const canSignInFederated = provider && !pending;

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };
  };

  const handleError = (err: any) => {
    setError(getErrorMessage(err, AuthState.SignIn));
    setPending(false);
  };

  const signIn = async () => {
    if (canSignIn) {
      setPending(true);
      try {
        await handleSignIn(usernameTrim.toLowerCase(), password);
        devLogger.log('user signed in',);
      } catch (e) {
        handleError(e);
      }
    }
  };

  const federatedSignIn = async () => {
    if (canSignInFederated) {
      setPending(true);
      try {
        // We're redirecting to an external UI, so don't attempt to check for authenticated user post federatedSignIn
        await Auth.federatedSignIn({ provider });
        devLogger.log('user signed in federated');
      } catch (e) {
        handleError(e);
      }
    }
  };

  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      signIn();
    }
  };

  const singleSignOn = provider && (
    <div className="btnAlign">
      <button
        id="singleSignOnBtn"
        className="btn col-8"
        type="button"
        disabled={!canSignInFederated}
        onClick={federatedSignIn}
      >Single Sign On</button>
    </div>
  );

  return (
    <div className="row justify-content-center">
      <div className="center_container">
        <Card>
          <div className="signInImg">
            <img alt="chart logo" src={logInImg} width="90" height="75" />
          </div>
          <div className="alignCenter formSubHeader">Welcome, Please Log In</div>
          <div className="row justify-content-center">
            <div className="form-group col-8">
              <label htmlFor="username">Email address:</label>
              <div className="input-group username">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fas fa-user-circle" /></span>
                </div>
                <input
                  id="username"
                  type="text"
                  className="form-control"
                  name="username"
                  onKeyPress={handleEnter}
                  value={username}
                  onChange={handleInputChange(setUsername)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="form-group col-8">
              <label htmlFor="password">Password:</label>
              <div className="input-group password">
                <div className="input-group-prepend">
                  <span className="input-group-text"><i className="fas fa-lock" /></span>
                </div>
                <input
                  id="password"
                  type="password"
                  className="form-control"
                  name="password"
                  onKeyPress={handleEnter}
                  value={password}
                  onChange={handleInputChange(setPassword)}
                  required
                />
              </div>
            </div>
          </div>
          <div className="btnAlign">
            {error && <div id="authError">{error}</div>}
            <button
              id="signInBtn"
              className="btn col-8"
              type="button"
              disabled={!canSignIn}
              onClick={signIn}
            >Sign In</button>
          </div>
          {singleSignOn}
        </Card>
        <div className="card2">
          <Card>
            <div className="alignCenter">
              <span>Lost your password?{intentionalSpace}</span>
              <span
                id="forgotPasswordBtn"
                className="redirectBtn"
                onClick={() => dispatchAuthStateChangeEvent(AuthState.ForgotPassword)}
              >Click here</span>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
