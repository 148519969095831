import React from 'react';
import './noSelectionOverlay.css';

export interface IProps {
  show: boolean,
  noSelectionText: string | string[],
  icon?: string,
  header?: string,
  opaque?: boolean,
  className?: string
}

export function NoSelectionOverlay(props: IProps) {
  const header = props.header && <div className="noSelectionHeader">{props.header}</div>;
  const icon = props.icon && <i data-id="no-selection-overlay-icon" className={props.icon} />;
  const messages = Array.isArray(props.noSelectionText) ? props.noSelectionText : [props.noSelectionText];
  const noSelectionText = messages.map((message, index) => {
    const key = "NoSelectionOverlayText" + index;
    return <div key={key} data-id="no-selection-overlay-message" className="noSelectionMessage">{message}</div>;
  });
  return <div data-id="no-selection-overlay" className={`noSelectionOverlay ${props.className ?? ''}`} style={{ display: props.show ? 'block' : 'none' }}>
    <div className={`noSelectionOverlayInner ${props.opaque ? "noSelectionOpaqueBackground" : ""}`}>
      {icon}
      {header}
      <div className={`${props.header || props.icon ? "noSelectionAdditionalText" : "noSelectionText"}`}>
        {noSelectionText}
      </div>
    </div>
  </div>;
}
