import { useTranslation } from '@lib/useTypedTranslation';
import classnames from 'classnames';

import { thresholdFieldNames, ThresholdFieldNames, ThresholdErrorCode, nsList } from './editLib';
import { FormState } from '../../components/forms/formsLib';
import { InfoPopover } from '../../components/tooltip/infoPopover';
import { FormFieldList } from '../../components/forms/formFieldList';

import './thresholdSelection.css';

export interface ThresholdSelectionProps {
  onSelectGlobal: (global: boolean) => void,
  global: boolean,
  fieldsConfig: FormState<ThresholdFieldNames, ThresholdErrorCode>['fieldsConfig'],
  onChange: (fieldName: ThresholdFieldNames[number], value: string) => void,
  onValidate: (fieldName: ThresholdFieldNames[number]) => void,
  values?: FormState<ThresholdFieldNames, ThresholdErrorCode>['values'],
  errors?: FormState<ThresholdFieldNames, ThresholdErrorCode>['errors'],
  isSubmitting: boolean
}

const [ns] = nsList;

export function ThresholdSelection(props: ThresholdSelectionProps): JSX.Element {
  const { t } = useTranslation(ns);
  const {
    global,
    onSelectGlobal,
    fieldsConfig,
    onChange,
    onValidate,
    values,
    errors,
    isSubmitting
  } = props;

  const tooltip = (
    <InfoPopover labelText='about distance alert thresholds'>
      {t('THRESHOLDS_TOOLTIP', { ns })}
    </InfoPopover>
  );
  const header = <div className="threshold-selection__header">{t('THRESHOLDS_TITLE', { ns })}{tooltip}</div>;

  return (
    <div className="threshold-selection">
      {header}
      <div className="threshold-selection__selectors">
        <div className="threshold-selection__selector">
          <input
            id="globalThresholds"
            checked={global}
            type="radio"
            className="threshold-selection__selector-input"
            onChange={() => onSelectGlobal(true)}
          />
          <label
            htmlFor="globalThresholds"
            className="threshold-selection__selector-label"
          >
            {t('GLOBAL_THRESHOLDS', { ns })}
          </label>
        </div>
        <div className="threshold-selection__selector">
          <input
            id="customThresholds"
            checked={!global}
            type="radio"
            className="threshold-selection__selector-input"
            onChange={() => onSelectGlobal(false)}
          />
          <label
            htmlFor="customThresholds"
            className="threshold-selection__selector-label"
          >
            {t('CUSTOM_THRESHOLDS', { ns })}
          </label>
        </div>
      </div>
      <div className={classnames('threshold-selection__thresholds', { 'threshold-selection__thresholds--disabled': global })}>
        <FormFieldList<ThresholdFieldNames, ThresholdErrorCode>
          fieldNames={thresholdFieldNames}
          fieldsConfig={fieldsConfig}
          onChange={onChange}
          onValidate={onValidate}
          values={values}
          errors={errors}
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
}
