import React from 'react';

import Card from '../../../components/card/card';
import { DevicesList } from './compositions/devices-list';

export function CoreDevices() {
  return (
    <div className="core-devices-list-container core-devices-card_sizing">
      <Card noPadding={true}>
        <div className="core-devices">
          <div className="core-devices_list">
            <DevicesList />
          </div>
        </div>
      </Card>
    </div>
  );
}
