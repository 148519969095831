import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { ModalPopup } from '../../../../components/controls/modalPopup';
import { FilterSelection, IFilterGroup } from '../../../../components/filters/filterSelection';
import { Loading } from '../../../../components/loading/loading';
import { IFilterResult } from '../../../../components/filters/filterLib';
import { FormActionButtons, ProgressiveActionType } from '../../../../components/buttons/formActionButtons';
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { getTranslatedAlertStatusFilterValues } from '../../../../components/filters/anomaliesFilterLib';

import './anomalies-filter.css';
import { FormControls } from '../../../../components/forms/formControls';

export interface IProps {
  showPopup: boolean,
  initialFilters: IFilterResult,
  handleFiltersUpdated: (filters: IFilterResult) => void,
  handleCancel: () => void
}

interface ModalFooterProps {
  handleFiltersUpdated: (filters: IFilterResult) => void,
  handleCancel: () => void,
  selectedFilter: IFilterResult
}

function ModalFooter(props: ModalFooterProps) {
  return <FormControls
    onCancel={props.handleCancel}
    onSubmit={() => props.handleFiltersUpdated(props.selectedFilter)}
    mode='apply'
  />;
}

function getInitialAvailableFilters(): IFilterResult {
  return { alertStatuses: [] };
}

export function AnomaliesFilter(props: IProps) {
  const {
    showPopup,
    initialFilters,
    handleFiltersUpdated,
    handleCancel
  } = props;
  const { t } = useTranslation(['translation', 'filters', 'anomaly']);

  const [selectedFilter, setSelectedFilter] = useState<IFilterResult>({});
  const [{ availableFilters, filtersLoaded }, setAvailableFilters] = useState({
    availableFilters: getInitialAvailableFilters(),
    filtersLoaded: false
  });

  const { worldSettings } = useWorldSettingsContext();

  const translatedAlertStatusValues = useMemo(() => {
    return getTranslatedAlertStatusFilterValues(t, worldSettings);
  }, [worldSettings, t]);

  useEffect(() => {
    const availableFilters: IFilterResult = {
      alertStatus: translatedAlertStatusValues
    };
    setAvailableFilters({ availableFilters, filtersLoaded: true });
  }, [translatedAlertStatusValues]);

  useEffect(() => {
    setSelectedFilter(initialFilters);
  }, [initialFilters]);

  const alertFilterGroupsTab: IFilterGroup[] = [
    {
      id: 'alertStatus',
      filterDisplayName: t('ALERT_TYPES_FILTER', { ns: 'filters' })
    }
  ];

  const tabs = [
    { id: 'anomaliesFilterTab', name: t('FILTER_MODAL_TAB_2', { ns: 'translation' }), filterGroups: alertFilterGroupsTab }
  ];

  function handleClose() {
    setSelectedFilter(initialFilters);
    handleCancel();
  }

  return (
    <ModalPopup
      show={showPopup}
      handleClose={handleClose}
      header={t('ANOMALY_FILTER_MODAL_HEADER', { ns: 'anomaly' })}
      subheader={t('ANOMALY_FILTER_MODAL_SUBHEADER', { ns: 'anomaly' })}
      classname="core-anomalies_list_filters"
      footer={<ModalFooter
        handleFiltersUpdated={handleFiltersUpdated}
        handleCancel={handleClose}
        selectedFilter={selectedFilter}
      />}
    >
      <Loading
        isLoading={!filtersLoaded}
        transparentOverlay={false}>
        <FilterSelection
          currentFilter={selectedFilter}
          availableFilters={availableFilters}
          onFilterChange={(result) => setSelectedFilter(result)}
          tabs={tabs}
        />
      </Loading>
    </ModalPopup>
  );
}
