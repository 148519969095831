interface Language {
  lng: string,
  locale: string,
  code: string,
  languageName: string,
  countryName: string
}

export const languages: Language[] = [
  // N.B. we use en-uk for the UK code rather than en-gb (the ISO country code) for product reasons
  { lng: 'en', locale: 'en-gb', code: 'EN-UK', languageName: 'English', countryName: 'United Kingdom' },
  { lng: 'en-US', locale: 'en', code: 'EN-US', languageName: 'English', countryName: 'United States' },
  { lng: 'fr', locale: 'fr', code: 'FR', languageName: 'Français', countryName: 'France' },
  { lng: 'fr-CA', locale: 'fr-ca', code: 'CA', languageName: 'Français', countryName: 'Canada' },
  { lng: 'es', locale: 'es', code: 'ES', languageName: 'Español', countryName: 'Spain' }
];

export const languagesMap: Map<Language['lng'], Language> = new Map(languages.map(l => [l.lng, l]));
