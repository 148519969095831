import { useTimeout } from "./useTimeout";

export const wait = async (timeout: number) => new Promise(r => setTimeout(r, timeout));

/** use this hook in function components to ensure wait is cleaned up if component unmounts */
export const useWait = (): { wait: (timeout: number) => Promise<unknown> } => {
  const { addTimeout } = useTimeout();

  const wait = async (timeout: number) => new Promise(r => addTimeout(r, timeout));

  return { wait };
};
