import { request, RequestInitWithRetry } from "../../lib/request";

export interface IHomeLocationInfo {
  id: string,
  name: string
}

export interface IHomeLocationDevicesCount {
  homeLocation: IHomeLocationInfo,
  devicesCount: number
}

export interface IHomeLocationParams {
  fromHomeLocation: IHomeLocationDevicesCount,
  toHomeLocation: IHomeLocationDevicesCount
}

export interface IMoveResponse {
  [id: string]: {
    success: boolean
  }
}

export const getHomeLocationWithDevicesCount = () => {
  return async (options: RequestInitWithRetry): Promise<IHomeLocationDevicesCount[]> => {
    const params = {
      path: '/api/bulkActions/getHomeLocationWithDevicesCount',
      method: 'GET'
    };
    return request(params, options);
  };
};

export const moveHomeLocationsAction = (options: RequestInitWithRetry) => {
  return async (params: IHomeLocationParams): Promise<IMoveResponse> => {
    const input = {
      path: '/api/bulkActions/moveHomeLocations',
      method: 'POST',
      body: params
    };
    return request(input, options);
  };
};
