import React, { useContext, useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import styled from 'styled-components';

import { DetailsMap, IMapItem } from "../../../../components/detailsMap/detailsMap";
import { getDeviceLocation, IDeviceLocationData } from "../../../../services/core/devices";
import { Loading } from "../../../../components/loading/loading";
import { DeviceInfoContext } from '..';
import { ReactComponent as RadiusRed } from './radiusRed.svg';
import { ReactComponent as RadiusBlue } from './radiusBlue.svg';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { Tooltip } from '../../../../components/tooltip/tooltip';
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { metresToYards } from '../../../../lib/distanceConversion';

const StyledCoreDeviceLocation = styled.div`
  font-weight: 600;
  .loading-container {
    height: 40rem;
  }
  hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid #d7e2eb;
  }
`;

StyledCoreDeviceLocation.displayName = "CoreDeviceLocation";

const StyledHeadersContainer = styled.span`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 1.8rem;
`;

StyledHeadersContainer.displayName = "HeadersContainer";

const StyledHeader = styled.span`
  font-weight: 700;
  font-size: 1.286em;
  margin-left: 2.5rem;
  padding-bottom: 0.5rem;
`;

StyledHeader.displayName = "LocationHeader";

const StyledSubHeader = styled.span`
  font-weight: 600;
  margin-left: 3rem;
  padding-top: 0.5rem;
`;

StyledSubHeader.displayName = "LocationSubHeader";

const StyledLatLongDetailsContainer = styled.span`
  font-size: var(--font-size-15px);
  font-weight: 700;
  margin-right: 2.5rem;
  & span {
    margin-left: 1rem;
  }
`;

StyledLatLongDetailsContainer.displayName = "LocationLatLongDetailsContainer";

const StyledLatLongInfo = styled.span`
&& {
  font-weight: 400;
  margin-left: 0.5rem;
}
`;

StyledLatLongInfo.displayName = "LocationLatLongInfo";

const StyledSubHeaderSeparator = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledSubHeaderText = styled.span`
  font-weight: 400;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
`;

StyledSubHeaderText.displayName = "LocationSubHeaderText";

const StyledSubHeaderTooltipText = styled.div`
  font-weight: 400;
  width: 300px;
`;

StyledSubHeaderTooltipText.displayName = "LocationSubHeaderTooltipText";

const StyledSubHeaderTooltipIcon = styled.i`
  color: #5a82a8;
  margin: 0 0.5rem;
`;

StyledSubHeaderTooltipIcon.displayName = "LocationSubHeaderTooltipIcon";

const StyledMapKeyContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledMapKey = styled.div`
  background-color: #f3f7fb;
  margin: 1.8em 5em 0.7em 5em;
  padding: 0.625rem 1rem;
  box-sizing: border-box;
  border-radius: 8px;
  border-left: 1px solid #d7e2eb;
  border-right: 1px solid #d7e2eb;
  border-bottom: 2px solid #d7e2eb;
`;

StyledMapKey.displayName = "MapKey";

const StyledMapKeyTitle = styled.div`
  font-weight: 600;
  @media (max-width: 576px) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.5rem;
  }
`;

StyledMapKeyTitle.displayName = "MapKeyTitle";

const StyledMapKeyItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

StyledMapKeyItems.displayName = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`;

StyledMapKeyItems.displayName = "MapKeyItems";

const StyledMapKeyItem = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    border-left: 1px solid #c2d1e0;
    margin-left: 10px;
    padding-left: 10px;
  }

  @media (max-width: 576px) {
    flex-basis: 100%;
    padding-bottom: 0.25rem;
    margin-left: 10px;
    padding-left: 10px;

    &:not(:first-child) {
      border-left: none;
    }
  }
`;

StyledMapKeyItem.displayName = "MapKeyItem";

const StyledMapKeyItemText = styled.div`
  margin-left: 10px;
`;

StyledMapKeyItemText.displayName = "MapKeyItemText";

const StyledMapKeyItemIcon = styled.i`
  color: white;
`;

StyledMapKeyItemIcon.displayName = "MapKeyItemIcon";

const StyledDeviceLocationRedRadius = styled.div`
  width: 2rem;
  height: 2rem;
`;

StyledDeviceLocationRedRadius.displayName = "DeviceLocationRedRadius";

const StyledDeviceLocationBlueRadius = styled.div`
  width: 2rem;
  height: 2rem;
`;

StyledDeviceLocationBlueRadius.displayName = "DeviceLocationBlueRadius";

const StyledMapCoreLocale = styled.div`
  margin: 0.5rem;
  .leaflet-container {
    height: 60vh;
    border-radius: 5px;
  }
  .noSelectionOverlay {
    padding: 0;
  }
  .noSelectionText {
    top: 30vh;
  }
`;

StyledMapCoreLocale.displayName = "MapCoreLocale";

interface IProps {
  selected?: boolean
}

function getMapItems(data: IDeviceLocationData | null): IMapItem[] {
  const items: IMapItem[] = [];
  if (data?.location) {
    items.push({
      icon: { style: 'fas fa-mobile-alt', size: [12, 20] },
      position: data.location.position,
      radius: { size: data.location.accuracy, colour: '#D86E6E' },
      styleClass: 'icon-pin_device'
    });
  }
  if (data?.homeLocation) {
    items.push({
      icon: { style: 'fas fa-home', size: [20, 20] },
      position: data.homeLocation.position,
      radius: { size: data.homeLocation.radius, colour: '#6EA0D8' },
      label: data.homeLocation.name,
      styleClass: 'icon-pin_home-location'
    });
  }
  return items;
}


export function DeviceLocation(props: IProps) {
  const { id: deviceId, timeZoneOffset } = useContext(DeviceInfoContext);
  const { t } = useTranslation('deviceLocation');
  const deviceLocationFetcher = useCallback(() => getDeviceLocation(deviceId), [deviceId]);
  const { data, loading } = useWorldRequest(deviceLocationFetcher, { initialLoading: true });
  const { worldSettings } = useWorldSettingsContext();
  const metric = worldSettings?.useMetricDistances;
  const unit = metric ? t('SHORT_metric_other', { ns: 'distance' }) : t('SHORT_imperial_other', { ns: 'distance' });

  const timeZoneName = data?.timeZone?.name;
  const latitude = data?.location?.latitude;
  const longitude = data?.location?.longitude;
  const accuracy = data?.location?.accuracy ? data.location.accuracy : undefined;
  const accuracyConversion = metric ? accuracy : metresToYards(accuracy);
  const source = data?.location?.source;

  function locationTimeFormat(locationTime: string): string {
    if (locationTime) {
      const time = moment.utc(parseInt(locationTime))
        .add(timeZoneOffset ?? 0, 'minutes')
        .format(`LLL [${t('AT', { ns: 'performance' })}] HH:mm`);
      return `${time} (${timeZoneName || 'UTC'})`;
    } else {
      return '-';
    }
  }

  return (
    <StyledCoreDeviceLocation data-id="device-location_core-device-location">
      <Loading isLoading={loading} transparentOverlay={false}>
        <div className="map-description">
          <div className="map-headers">
            <StyledHeadersContainer>
              <StyledHeader data-id="device-location-header">{t('DEVICE_LOCATION')}</StyledHeader>
            </StyledHeadersContainer>
            <StyledSubHeaderSeparator>
              <StyledSubHeader data-id="device-location-updated-subheader">
                {`${t('LOCATION_UPDATED', { ns: 'translation' })}:`}<Tooltip placement={'bottom'} popperContent={<StyledSubHeaderTooltipText className="device-location-updated-popover_text">{t('LOCATION_UPDATED_TOOLTIP', { ns: 'translation' })}</StyledSubHeaderTooltipText>}>
                  <StyledSubHeaderTooltipIcon className="fa fa-question-circle" /></Tooltip> <StyledSubHeaderText>{locationTimeFormat(data?.location?.utc)}</StyledSubHeaderText>
              </StyledSubHeader>
              {(data?.location?.latitude && data?.location?.longitude) &&
                <StyledLatLongDetailsContainer data-id="device-location_lat-long_container">
                  <span>{`${t('LATITUDE', { ns: 'homeLocations' })}:`}<StyledLatLongInfo data-id="device-location_latitude-value">{latitude}</StyledLatLongInfo></span>
                  <span>{`${t('LONGITUDE', { ns: 'homeLocations' })}:`}<StyledLatLongInfo data-id="device-location_longitude-value">{longitude}</StyledLatLongInfo></span>
                </StyledLatLongDetailsContainer>
              }
            </StyledSubHeaderSeparator>
            <StyledSubHeaderSeparator>
              <StyledSubHeader data-id="device-location-lock-subheader">
                {`${t('LAST_LOCATION_LOCK', { ns: 'translation' })}:`}<Tooltip placement={'bottom'} popperContent={<StyledSubHeaderTooltipText className="device-location-lock-popover_text">{t('LAST_LOCATION_LOCK_TOOLTIP', { ns: 'translation' })}</StyledSubHeaderTooltipText>}>
                  <StyledSubHeaderTooltipIcon className="fa fa-question-circle" /></Tooltip> <StyledSubHeaderText>{locationTimeFormat(data?.location?.time)}</StyledSubHeaderText>
              </StyledSubHeader>
              {(data?.location?.latitude && data?.location?.longitude && data?.location?.accuracy && data?.location?.source) &&
                <StyledLatLongDetailsContainer data-id="device-location_lat-long_container">
                  <span>{`${t('ACCURACY')}:`}<StyledLatLongInfo data-id="device-location_accuracy-value">{`${Math.floor(accuracyConversion).toString()} ${unit}`}</StyledLatLongInfo></span>
                  <span>{`${t('SOURCE')}:`}<StyledLatLongInfo data-id="device-location_source-value">{source}</StyledLatLongInfo></span>
                </StyledLatLongDetailsContainer>
              }
            </StyledSubHeaderSeparator>
          </div>
        </div>
        <StyledMapKeyContainer>
          <StyledMapKey data-id="device-location_map-key">
            <StyledMapKeyItems>
              <StyledMapKeyItem data-id="device-location_map-key-item">
                <div className="map-icon icon-pin_device"><StyledMapKeyItemIcon className="fas fa-mobile-alt"></StyledMapKeyItemIcon></div>
                <StyledMapKeyItemText>{t('THIS_DEVICE')}</StyledMapKeyItemText>
              </StyledMapKeyItem>
              <StyledMapKeyItem data-id="device-location_map-key-item">
                <div className="map-icon icon-pin_home-location"><StyledMapKeyItemIcon className="fas fa-home"></StyledMapKeyItemIcon></div>
                <StyledMapKeyItemText>{t('HOME_LOCATION', { ns: 'translation' })}</StyledMapKeyItemText>
              </StyledMapKeyItem>
              <StyledMapKeyItem data-id="device-location_map-key-item">
                <StyledDeviceLocationRedRadius data-id="device-location_map-key_red-radius"><RadiusRed /></StyledDeviceLocationRedRadius>
                <StyledMapKeyItemText>{t('DEVICE_ACCURACY_RADIUS')}</StyledMapKeyItemText>
              </StyledMapKeyItem>
              <StyledMapKeyItem data-id="device-location_map-key-item">
                <StyledDeviceLocationBlueRadius data-id="device-location_map-key_blue-radius"><RadiusBlue /></StyledDeviceLocationBlueRadius>
                <StyledMapKeyItemText>{t('HOME_LOCATION_RADIUS')}</StyledMapKeyItemText>
              </StyledMapKeyItem>
            </StyledMapKeyItems>
          </StyledMapKey>
        </StyledMapKeyContainer>
        <StyledMapCoreLocale data-id="device-location_map-core-locale">
          {/* key added for re-mount of leaflet map when tab goes from being hidden to visible - leaflet map breaks if it has been hidden */}
          <DetailsMap
            id={deviceId}
            key={`map-${props.selected}`}
            items={getMapItems(data)}
            overlayText={t('LOCATION_UNKNOWN')}
            visible={props.selected}
          />
        </StyledMapCoreLocale>
      </Loading>
    </StyledCoreDeviceLocation>
  );
}
