import React, { useState, useEffect } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { DeleteDialogue } from '../../components/delete/deleteDialogue';
import { deleteSmartBatteries } from '../../services/shared/battery/list';
import { useWorldAction } from '../../lib/useWorldAction';
import { useWait } from '../../lib/wait';

export interface IProps {
  showPopup: boolean,
  batteryIds: string[],
  handleClose: () => void,
  onBatteriesDeleted: () => void
}

export function DeleteSmartBatteries(props: IProps) {
  const { t } = useTranslation('deleteSmartBatteries');
  const { showPopup, handleClose, batteryIds } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [checkedLength, setCheckedLength] = useState(null);
  const doDeleteSmartBatteries = useWorldAction(deleteSmartBatteries);

  const x = useWait();
  const wait = x.wait;

  useEffect(() => {
    if (batteryIds.length) {
      setCheckedLength(batteryIds.length);
    }
  }, [batteryIds.length]);

  async function handleDelete() {
    setIsDeleting(true);
    let success = false;
    try {
      await doDeleteSmartBatteries({ ids: props.batteryIds });
      //Max time for deleting batteries in es when deleted flag set in dynamodb is 3 seconds so adding wait time before reloading list
      await wait(3000);
      setIsDeleting(false);
      setError(false);
      success = true;
    } catch (err) {
      setIsDeleting(false);
      setError(true);
    } finally {
      props.onBatteriesDeleted();
    }
    return success;
  }

  const onClose = () => {
    setIsDeleting(false);
    setError(false);
    handleClose();
  };

  const errorText = t('ERROR_CUSTOM_DELETE', { ns: 'deleteDialogue', item: t('BATTERY_other', { ns: 'translation' }) });

  return (
    <DeleteDialogue
      show={showPopup}
      subheader={t('SUBHEADER', { count: checkedLength })}
      warning={t('MESSAGE', { count: checkedLength })}
      isDeleting={isDeleting}
      error={error && errorText}
      checkboxRequired={checkedLength > 1}
      handleDelete={handleDelete}
      handleClose={onClose}
      count={checkedLength}
    />
  );
}
