import * as React from 'react';
import moment from 'moment';
import { useTranslation } from '@lib/useTypedTranslation';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import styled from 'styled-components';

import { DetailsItem } from '../../../components/details/detailsItem';
import { LinkButton } from '../../../components/controls/linkButton';
import { MiniHeader } from '../../../components/details/miniHeader';
import { RoundedLabel } from '../../../components/labels/roundedLabel';
import { encode } from '../../../lib/base64';
import { ViewInCoreButton } from '../../../components/controls/viewInCoreButton';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { useCurrentWorldContext } from '../../../context/currentWorld';
import { Feature } from '../../app/productsWrapper';
import { LastKnownBatteryColumn } from './lastKnownBatteryColumn';

interface IProps {
  id: string,
  data: any
}

export interface IViewModel {
  assetTag: string,
  imei: string,
  lastSeen: string,
  manufacturer: string,
  model: string,
  serialNumber: string
}

export interface IBatteryModel {
  id: string,
  batteryPartNumber: string,
  batterySerialNumber: string,
  capacityFactor?: string,
  cycleCount: number,
  statusLabel: React.FunctionComponentElement<{}>
}

const ViewInCore = styled(ViewInCoreButton)`
  display: inline-block;
`;
ViewInCore.displayName = 'ViewInCore';

export function DeviceDetailsInformation(props: IProps) {
  const { data, id } = props;
  const { features } = useCurrentWorldContext();
  const ns = 'batteryEssentials';
  const ns2 = 'batteryEssentialsDeviceView';
  const { t } = useTranslation(['translation', ns, ns2]);

  const viewModel: IViewModel = {
    assetTag: data.assetTag,
    imei: data.imei,
    lastSeen: data.updated ? moment(Number.parseInt(data.updated)).calendar() : undefined,
    manufacturer: data.manufacturer,
    model: data.model,
    serialNumber: data.serialNumber,
  };

  const areMultipleBatteries = data.smartBatteries?.length > 1;

  const showViewInBatteries = (
    features.includes(Feature.batteryEssentials) ||
    features.includes(Feature.panasonicSmartBatteryMonitor)
  );

  return (
    <div className="row details_information h-100">
      <div className={`${areMultipleBatteries ? "col-4" : "col-8"} d-flex flex-column`}>
        <MiniHeader title={t('DEVICE_INFORMATION', { ns: ns2 })} icon="fas fa-info-circle" />
        <DetailsItem data={viewModel} name={t('MANUFACTURER', { ns: 'translation' })} path='manufacturer' />
        <DetailsItem data={viewModel} name={t('MODEL', { ns: 'translation' })} path='model' />
        <DetailsItem data={viewModel} name={t('SERIAL_NUMBER', { ns: 'translation' })} path='serialNumber' />
        <DetailsItem data={viewModel} name='IMEI' path='imei' />
        <DetailsItem data={viewModel} name={t('ASSET_TAG', { ns: 'translation' })} path='assetTag' />
        <DetailsItem data={viewModel} name={t('LAST_SEEN', { ns: 'translation' })} path='lastSeen' />
        <ViewInCore deviceId={id} className='mt-auto align-self-center' />
      </div>
      {(data.smartBatteries as [] ?? [{}]).map((_, index) => {
        return <LastKnownBatteryColumn
          areMultipleBatteries={areMultipleBatteries}
          index={index}
          showViewInBatteries={showViewInBatteries}
          data={data}
          key={index}
        />;
      })}
    </div>
  );
}
