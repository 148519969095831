import React, { useContext, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { get, groupBy, isEqual, pick } from 'lodash';

import { AmChart, coreTheme } from '../../../../../../components/chart/amChart';
import { IDeviceUsage } from '../../../../../../services/core/devicePerformance';
import { NoSelectionOverlay } from '../../../../../../components/card/noSelectionOverlay';
import { formatDateAsOrdinal } from '../../../../../../lib/ordinal';
import { addHoverAnimation } from '../../../../../../components/chart/onHoverAnimation';
import { IDeviceEventFilterState, deviceEventFilterGroupNames } from '../events/deviceEventsTypes';
import { DeviceEvent } from '../../../../../../services/core/eventsTypes';
import { EditEvents, EditEventsAction, useEditEventsDispatch } from '../events/edit-events';
import { getDeviceEventFilterGroups } from '../events/deviceEvents';
import { addEventOverlaysToColumnChart } from './lib/eventOverlays';
import { EventsShown } from '../events-shown';
import { DeviceInfoContext } from '../../../index';
import { chartNames, ChartName } from './lib/chartNames';
import { highlightWeekends } from './lib/weekendsHighlight';
import { createYAxis } from '../../../../../../lib/usageChartYAxis';
import { useFeatureTogglesContext } from '../../../../../../context/featureToggles';

import './charts.css';
import '../events/deviceEvents.css';

interface CoreProps {
  data: IDeviceUsage[],
  events: DeviceEvent[],
  eventFilters: IDeviceEventFilterState,
  dispatchEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  setDate: (date: string) => void,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function formatTooltipDate(date: string) {
  return moment(parseInt(date)).format('ll');
}

export function DeviceUsage30Days(props: CoreProps): JSX.Element;
export function DeviceUsage30Days(props: CoreProps) {
  const featureToggles = useFeatureTogglesContext();
  const { platformType } = useContext(DeviceInfoContext);
  const { t } = useTranslation(['performance', 'editEvents', 'translation']);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [chartReady, setChartReady] = useState(false);

  const { handleUpdate, handleApply, handleApplyAll, handleCancel } = useEditEventsDispatch(
    (props as CoreProps).dispatchEventFilters, chartNames.deviceUsage30Days, setLoadingTrue
  );

  const title = t('DEVICE_UTILISATION_PAST_30_DAYS', { ns: 'performance' });

  let editEvents: JSX.Element;

  function setLoadingTrue() {
    setChartReady(false);
    setChartDataLoading(true);
  }

  function onReady() {
    if (!chartDataLoading) {
      setChartReady(true);
    }
  }

  const data = getData();

  return (
    <div className={`core-device_performance-30-day-device-usage ${chartReady ? 'chart-ready' : ''}`}>
      <div className="chart-header">
        <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns: "translation" })} show={props.data.length === 0} />
        <div className="chart-title">{title}</div>
        <div className="chart-edit-events">
          <EventsShown eventFilters={(props as CoreProps).eventFilters.appliedEventFilters} />
          <EditEvents
            subheader={`‘${title}’`}
            eventGroupNames={deviceEventFilterGroupNames}
            eventFilterGroups={getDeviceEventFilterGroups(platformType, featureToggles)}
            eventFilterState={(props as CoreProps).eventFilters.currentEventFilters}
            handleUpdate={handleUpdate}
            handleApply={handleApply}
            handleCancel={handleCancel}
            handleApplyAll={handleApplyAll}
          />
        </div>
      </div>
      <div className="chart-and-legend-container">
        <div className="chart-container">
          <AmChart
            tag="performance-30-day-device-usage-chart"
            chartProvider={createChart}
            dataProvider={() => { setChartDataLoading(false); return data; }}
            onUpdate={onDataUpdated}
            link={pick(props, 'data', 'events')}
            onDataValidated={onReady}
            onMount={props.onMount}
            cypressId="last30DaysDeviceUsage"
          />
        </div>
        <div id="device-usage-30-day-chart-legend" />
      </div>
    </div>
  );

  /* istanbul ignore next */
  function createChart(this: any, id: string) {
    am4core.useTheme(coreTheme);

    const chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);
    chart.data = props.data;
    chart.paddingTop = 40;
    chart.minHeight = 240;

    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'date';
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.minGridDistance = 50;
    //format axis here instead of in getData() to prevent duplicate categories e.g(5th)
    dateAxis.renderer.labels.template.adapter.add('textOutput', function (text, target) {
      if (text) {
        const dateAsNumber = parseInt(text);
        return moment(dateAsNumber).startOf('day').isSame(moment().startOf('day')) ? t('TODAY', { ns: 'timeState' }) : formatDateAsOrdinal(dateAsNumber);
      }
    });

    const yAxis = chart.yAxes.push(new am4charts.DurationAxis());
    const maxDeviceUsage = Math.max(...props.data.map((data) => data.used));
    const maxDeviceUsageHours = maxDeviceUsage / 60 / 60;
    yAxis.baseUnit = "second";
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;
    yAxis.contentAlign = 'right';
    createYAxis(yAxis, maxDeviceUsageHours, t, true);

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.fill = am4core.color('#0a5');
    series.columns.template.stroke = am4core.color('#0a5');
    series.columns.template.width = am4core.percent(90);
    series.yAxis = yAxis;
    series.dataFields.valueY = 'used';
    series.dataFields.categoryX = 'date';
    series.columns.template.tooltipHTML = '';

    series.columns.template.adapter.add('tooltipHTML', (_, target) => {
      const data: any = target.tooltipDataItem.dataContext;
      const date = formatTooltipDate(data.date);
      const userActivityMinutes = Math.floor((data.used / 60));
      const minutesText = t('MINUTE', { ns: 'timeState', count: userActivityMinutes });
      if (userActivityMinutes < 1) {
        return `<span class="device-usage-30-day-tooltip-title">${date}</span></br><span class="device-usage-30-day-tooltip-text"><div class="key-green"></div>&nbsp;${t('LESS_THAN_ONE_MINUTE_USAGE', { ns: 'performance' })}</span>`;
      } else {
        return `<span class="device-usage-30-day-tooltip-title">${date}</span></br><span class="device-usage-30-day-tooltip-text"><div class="key-green"></div>&nbsp;${t('IN_USE', { ns: 'performance' })}: {valueY.formatDuration("m '${minutesText}'")}</span>`;
      }
    });

    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    series.columns.template.events.on("hit", (event: any) => {
      const date = event.target.dataItem.dataContext.date;
      props.setDate(date.toString());
    });

    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.text = t('IN_USE', { ns: 'performance' });
    chart.legend.labels.template.fontWeight = '400';

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;
    chart.legend.useDefaultMarker = true;

    markerTemplate.disposeChildren();
    const legendItem = markerTemplate.createChild(am4core.Rectangle);
    legendItem.fill = am4core.color('#0a5');
    legendItem.width = 13;
    legendItem.height = 13;

    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;


    addHoverAnimation(series, chart);
    addEventOverlaysToColumnChart(series, chart, dateAxis, t);
    //create axis ranges for weekends
    highlightWeekends(dateAxis);
    return chart;
  }

  /* istanbul ignore next */
  function onDataUpdated(chart: am4charts.XYChart, chartData: any[], prevProps?: any, currentProps?: any) {
    if (!isEqual(get(currentProps, 'link.events'), get(prevProps, 'link.events'))) {
      addEventOverlaysToColumnChart(chart.series.values[0] as am4charts.ColumnSeries, chart, chart.xAxes.values[0] as am4charts.CategoryAxis, t);
      highlightWeekends(chart.xAxes.values[0] as am4charts.CategoryAxis);
    }
    return chart;
  }

  function getData() {
    if (!props.data.length) {
      return [];
    }
    const eventsByDay = groupBy(props.events, (event) => moment.utc(event.local).startOf('day').valueOf());
    return props.data.map(({ date, used }) => {
      return {
        date,
        used: used || 0,
        events: get(eventsByDay, date, [])
      };
    });
  }
}
