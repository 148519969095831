import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import styled from 'styled-components';
import * as am4charts from '@amcharts/amcharts4/charts';

import Card from '../../../components/card/card';
import { Loading } from '../../../components/loading/loading';
import { getUserTimeZone } from '../../../lib/timeZone';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { DataUsage, exportApplicationDeviceUsageAction, getApplicationDeviceUsage, getApplicationPerformanceOverview, ApplicationPerformanceParams, exportApplicationMinutesUsageAction, exportApplicationDataUsageAction, getApplicationVersions } from '../../../services/core/application';
import { Theme } from '../../app/themes';
import { TimePeriodAndFilterPicker } from '../../../components/timePeriodAndFilterPicker/timePeriodAndFilterPicker';
import { StackedOverview } from '../../../components/overview/stackedOverview';
import Spinner from '../../../components/loading/loadingSpinner';
import { NoSelectionOverlay } from '../../../components/card/noSelectionOverlay';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { bytesToGigabytes, bytesToMegabytes, formatBytesToGigabytesFixed, roundTo2PlacesFixed } from '../../../lib/dataConversion';
import { isEqual, isNil, isUndefined } from 'lodash';
import { useLanguageAndLocaleContext } from '../../../context/languageAndLocale';
import { ApplicationInfoContext } from './coreApplication';
import { RequestInitWithRetry } from '../../../lib/request';
import { useAlignCharts } from '../../../lib/useAlignCharts';
import { StackedColumnsDateChart } from '../../../components/chart/stackedColumnsDateChart';
import { useDataUsageSeriesSwitch } from '../../../components/chart/useDataUsageSeriesSwitch';
import { formatTitle } from '../../../components/data-table/dataFormatters';
import { useWorldAction } from '../../../lib/useWorldAction';
import { Option } from '../../../components/controls/optionPickerLib';
import { TimePeriodAndFilterPickerProps } from '../../../components/timePeriodAndFilterPicker/timePeriodAndFilterPicker';
import { getTitle } from './filterTitle';
import { InfoPopover } from '../../../components/tooltip/infoPopover';
import { drawYAxis } from '../../../lib/dataUsageChart';
import { drawXAxisLabelsToToday } from '../../../components/chart/lib';
import { formatTooltipDay } from '../../../components/chart/tooltips';

const TimePeriodAndFilterSelectionContainer = styled.div`
  margin-top: 1rem;
  border-bottom: 1px solid #d7e2eb;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d7e2eb;
  justify-content: center;
  align-items: baseline;
`;

const TimePeriodAndFilterSelection = styled(TimePeriodAndFilterPicker)`
  padding-right: 0;
` as typeof TimePeriodAndFilterPicker;

const Tooltip = styled(InfoPopover)`
  max-width: 200px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
`;

const OverviewLoading = styled(Loading)`
  height: 200px;
`;

const Overview = styled(StackedOverview)<{ narrow?: boolean }>`
  ${({ narrow }: { narrow?: boolean }) => {
    if (narrow) {
      return `
        width: 60%;
        @media (max-width: 1000px) {
          width: 100%;
        }
      `;
    } else {
      return `
        width: 90%;
      `;
    }
  }}
  margin: 24px auto;
`;

const Section = styled.div<{ bottomBorder?: boolean }>`
  padding-bottom: 0.625rem;
  margin: 1.5rem 2.5rem 0;
  position: relative;
  ${({ bottomBorder }) => bottomBorder && `border-bottom: 3px solid #edf1f3;`}
`;

const SectionTitle = styled.div`
  font-weight: ${({ theme }: { theme: Theme}) => theme.font.weights.bold};
  font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.sixteenPixels};
`;

const NoDataOverlay = styled(NoSelectionOverlay)`
  padding: 0;
`;

const InfoIcon = styled.i`
  color: #7490b9;
  margin-right: 5px;
`;

const SharedIdInfoTextContainer = styled.div`
  width: 60%;
  margin: 0px auto;
`;

const SharedIdInfoText = styled.div`
  font-style: italic;
`;

const SharedIdSection = styled(Section)`
  padding-bottom: 30px;
` as typeof Section;


const formatItemTitle = (title: string) => title + ':';

const formatDataUsed = (dataUsed: number | undefined, t: TTypedTFunction<'coreApplications' | 'translation'>) => {
  const oneGigabyte = 1073741824;
  if (!isUndefined(dataUsed)) {
    if (dataUsed >= oneGigabyte) {
      return `${roundTo2PlacesFixed(bytesToGigabytes(dataUsed))} ${t('GB', { ns: 'translation' })}`;
    }
    return `${roundTo2PlacesFixed(bytesToMegabytes(dataUsed))} ${t('MB', { ns: 'translation' })}`;
  }
  return '-';
};

const formatNumber = (value: number, locale: string) => !isNil(value) ? value.toLocaleString(locale) : '-';

type DataUsageChartData = Omit<DataUsage, 'wifi' | 'mobile'> & {
  wifi: string,
  mobile: string
};

export interface Props extends TimePeriodAndFilterPickerProps {
  optionsLoading: boolean,
  version: Option<string>,
  setVersion: (version: Option<string>) => void
 }

export const getAllVersionsOption = (t: TTypedTFunction<'coreApplications' | 'translation'>): Option<string> => ({
  name: t('ALL_APPLICATION_VERSIONS', { ns: 'coreApplications' }),
  value: undefined
});

export function ApplicationPerformance(props: Props) {
  const { t } = useTranslation(['coreApplications', 'translation']);
  const {
    timePeriod,
    timePeriodOptions,
    handleTimePeriodSelect,
    filter,
    optionsLoading,
    version,
    setVersion,
    ...filterPickerProps
  } = props;
  const timeZone = getUserTimeZone();
  const { locale } = useLanguageAndLocaleContext();
  const { id: applicationId, sharedUserId } = useContext(ApplicationInfoContext);
  const { addChart, clearCharts } = useAlignCharts(3);

  const onExportClicked = async (action: (params: ApplicationPerformanceParams) => Promise<any>): Promise<string> => {
    const params = {
      id: applicationId,
      days: timePeriod.value,
      homeLocationId: filter.homeLocation?.value.id,
      group: filter.group?.value,
      zoneId: filter.zone?.value.id,
      timeZone,
      version: version.value,
      sharedUserId
    };
    return action(params);
  };
  const exportDeviceUsageData = useWorldAction(exportApplicationDeviceUsageAction);
  const exportMinutesUsageData = useWorldAction(exportApplicationMinutesUsageAction);
  const exportDataUsageData = useWorldAction(exportApplicationDataUsageAction);

  const {
    buttons: dataUsageSwitchButtons,
    mobileSeriesVisible,
    wifiSeriesVisible
  } = useDataUsageSeriesSwitch();

  const versionsFetcher = useCallback(() => {
    const params = {
      id: applicationId,
      days: timePeriod.value,
      timeZone
    };
    return getApplicationVersions(params);
  }, [timeZone, timePeriod, applicationId]);

  const applicationPerformanceOverviewFetcher = useCallback(() => {
    clearCharts();
    const params = {
      id: applicationId,
      days: timePeriod.value,
      homeLocationId: filter.homeLocation?.value.id,
      group: filter.group?.value,
      zoneId: filter.zone?.value.id,
      timeZone,
      version: version.value,
      sharedUserId
    };
    return (options: RequestInitWithRetry) => Promise.all([
      getApplicationPerformanceOverview(params)(options),
      getApplicationDeviceUsage(params)(options)
    ]);
  }, [timeZone, filter, timePeriod, applicationId, clearCharts, version, sharedUserId]);

  const { data: versions, loading: versionsLoading } = useWorldRequest(versionsFetcher, { initialLoading: true });
  const { data, loading } = useWorldRequest(applicationPerformanceOverviewFetcher, { initialLoading: true });
  if (optionsLoading || versionsLoading) {
    return <LoadingContainer><Loading isLoading={true} transparentOverlay={false} /></LoadingContainer>;
  }
  const handleVersionSelect = (selected: Option<string>) => {
    if (isEqual(selected, version)) { return; }
    setVersion(selected);
  };

  const dataUsageColumns = [[
    { label: formatItemTitle(t('MOBILE_DATA_USED')), value: loading ? <Spinner text='' /> : formatDataUsed(data?.[0]?.mobileDataUsage, t), dataId: 'mobileDataUsed' },
    { label: formatItemTitle(t('WIFI_DATA_USED')), value: loading ? <Spinner text='' /> : formatDataUsed(data?.[0]?.wifiDataUsage, t), dataId: 'wifiDataUsed' }
  ]];
  const nonDataUsageColumns = [[
    { label: formatItemTitle(t('TOTAL_MINUTES_USAGE', { ns: 'coreApplications' })), value: loading ? <Spinner text='' /> : formatNumber(data?.[0]?.totalMinutesUsage, locale), dataId: 'totalMinutesUsage' },
    { label: formatItemTitle(t('AVERAGE_MINUTES_USED', { ns: 'coreApplications' })), value: loading ? <Spinner text='' /> : formatNumber(data?.[0]?.dailyAverageMinutesUsage, locale), dataId: 'averageMinutesUsed' }
  ],
  [
    { label: formatItemTitle(t('CURRENT_INSTALLED_COUNT', { ns: 'coreApplications' })), value: loading ? <Spinner text='' /> : formatNumber(data?.[0]?.installedCount, locale), dataId: 'currentInstalledCount' },
    { label: formatItemTitle(t('CURRENT_VERSION_COUNT', { ns: 'coreApplications' })), value: loading ? <Spinner text='' /> : formatNumber(data?.[0]?.versionCount, locale), dataId: 'currentVersionCount' }
  ]];
  const overviewColumns = sharedUserId ? nonDataUsageColumns : dataUsageColumns.concat(nonDataUsageColumns);

  return (
    <div>
      <Card noPadding={true}>
        <TimePeriodAndFilterSelectionContainer>
          <TimePeriodAndFilterSelection<string>
            filter={filter}
            {...filterPickerProps}
            timePeriod={timePeriod}
            timePeriodOptions={timePeriodOptions}
            handleTimePeriodSelect={handleTimePeriodSelect}
            zonePickerVisible={filterPickerProps.zonePickerVisible}
            additionalFilters={[{
              id: 'versionPicker',
              options: [getAllVersionsOption(t), ...versions.map(version => ({ name: version, value: version }))],
              current: version,
              handleFilterSelect: handleVersionSelect,
              active: true,
              selectText: t('ALL_APPLICATION_VERSIONS', { ns: 'coreApplications' }),
              title: t('APP_VERSION', { ns: 'coreApplications', context: 'filter' })
            }]}
          />
          <Tooltip labelText="about the versions filter">{t('VERSIONS_TOOLTIP', { ns: 'coreApplications' })}</Tooltip>
        </TimePeriodAndFilterSelectionContainer>
        <OverviewLoading isLoading={loading} transparentOverlay={false}>
          <Section bottomBorder={true}>
            <NoDataOverlay show={data?.[0] === null} noSelectionText={t('NO_DATA_AVAILABLE', { ns: 'translation' })} />
            <SectionTitle>{getTitle({ filter, t, translationKey: 'OVERVIEW', version: version.value })}</SectionTitle>
            <Overview columns={overviewColumns} narrow={Boolean(sharedUserId)} />
          </Section>
          {sharedUserId && <SharedIdSection bottomBorder={true}>
            <NoDataOverlay show={Boolean(!data?.[0]?.dailyDataUsage || data?.[0]?.dailyDataUsage.length === 0)} noSelectionText={t('NO_DATA_AVAILABLE', { ns: 'translation' })} />
            <SectionTitle>{getTitle({ filter, t, translationKey: 'SHARED_USER_ID', version: version.value, sharedUserId })}</SectionTitle>
            <Overview columns={[[dataUsageColumns[0][0]], [dataUsageColumns[0][1]]]} narrow={true} />
            <SharedIdInfoTextContainer>
              <SharedIdInfoText>
                <InfoIcon className="fa fa-info-circle" />{t('SHARED_USER_ID_INFO', { ns: 'coreApplications', sharedUserId })}
              </SharedIdInfoText>
            </SharedIdInfoTextContainer>
          </SharedIdSection>}
          <Section>
            <StackedColumnsDateChart
              bottomBorder={true}
              cypressId="application-device-usage-chart"
              data={data?.[1]}
              series={[{
                visible: true,
                dataKey: 'deviceCount',
                description: t('DEVICES_USED', { ns: 'coreApplications' }),
                colour: '#4A6A89'
              }]}
              title={getTitle({ filter, t, translationKey: 'DEVICE_USAGE', version: version.value })}
              onMount={addChart}
              days={timePeriod.value}
              export={{
                filename: `${formatTitle(getTitle({ filter, t, translationKey: 'DEVICE_USAGE', version: version.value }))}.${moment.utc().toISOString()}.csv`,
                onExportClicked: () => onExportClicked(exportDeviceUsageData),
                csv: true
              }}
              useAxisTooltip={true}
              hasLegend={true}
              amchartsYAxisNumberFormat= '#'
              formatTooltipDate={formatTooltipDay}
              drawXAxis={(chart, xAxis) => drawXAxisLabelsToToday(chart, xAxis as am4charts.CategoryAxis, timePeriod.value, t)}
            />
            <StackedColumnsDateChart
              bottomBorder={true}
              cypressId="application-minutes-usage-chart"
              data={data?.[0]?.dailyMinutesUsage}
              series={[{
                visible: true,
                dataKey: 'userActivity',
                description: t('MINUTES_USED', { ns: 'coreApplications' }),
                colour: '#0fa336'
              }]}
              title={getTitle({ filter, t, translationKey: 'MINUTES_USAGE', version: version.value })}
              onMount={addChart}
              days={timePeriod.value}
              export={{
                filename: `${formatTitle(getTitle({ filter, t, translationKey: 'MINUTES_USAGE', version: version.value }))}.${moment.utc().toISOString()}.csv`,
                onExportClicked: () => onExportClicked(exportMinutesUsageData),
                csv: true
              }}
              useAxisTooltip={true}
              hasLegend={true}
              amchartsYAxisNumberFormat= '#'
              formatTooltipDate={formatTooltipDay}
              drawXAxis={(chart, xAxis) => drawXAxisLabelsToToday(chart, xAxis as am4charts.CategoryAxis, timePeriod.value, t)}
            />
            <StackedColumnsDateChart
              data={data?.[0]?.dailyDataUsage}
              series={[{
                visible: mobileSeriesVisible,
                dataKey: 'mobile',
                description: t('MOBILE_DATA', { ns: 'translation' }),
                colour: '#49a1a9'
              },
              {
                visible: wifiSeriesVisible,
                dataKey: 'wifi',
                description: t('WIFI_DATA', { ns: 'translation' }),
                colour: '#2c678c'
              }]}
              totalDescription={t('TOTAL_DATA', { ns: 'translation' })}
              days={timePeriod.value}
              cypressId="application-data-usage-chart"
              title={getTitle({ filter, t, translationKey: sharedUserId ? 'SHARED_USER_ID' : 'DATA_USAGE', version: version.value, sharedUserId })}
              onMount={addChart}
              drawYAxis={(chart, axis, enabledSeries) => drawYAxis(chart.data, axis, enabledSeries)}
              amchartsYAxisNumberFormat="#.00b"
              buttons={dataUsageSwitchButtons}
              export={{
                filename: `${formatTitle(getTitle({ filter, t, translationKey: sharedUserId ? 'SHARED_USER_ID' : 'DATA_USAGE', version: version.value, sharedUserId }))}.${moment.utc().toISOString()}.csv`,
                onExportClicked: () => onExportClicked(exportDataUsageData),
                csv: true
              }}
              useAxisTooltip={true}
              drawXAxis={(chart, xAxis) => drawXAxisLabelsToToday(chart, xAxis as am4charts.CategoryAxis, timePeriod.value, t)}
              formatTooltipDate={formatTooltipDay}
              hasLegend={true}
            />
          </Section>
        </OverviewLoading>
      </Card>
    </div>
  );
}
