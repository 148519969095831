import { ITranslationKeys } from "./keys";
import { frTranslations } from './french-fr';
import { merge, cloneDeep } from "lodash";
import { DeepPartial } from '../../lib/typeUtils';

// istanbul ignore file
const frenchCanadianTranslations: ITranslationKeys = cloneDeep(frTranslations);

const caTranslationsOverrides: DeepPartial<ITranslationKeys> = {
  translation: {
    LICENSED: "Sous licence",
    PRODUCT_SELECTION: "Sélection de produits",
    NO_PROBLEMS: "Pas de problème",
    HEALTH_WARNING: "Avertissement de santé",
    POOR_HEALTH: "Mauvaise santé",
    CYCLE_COUNT_WARNING: "Avertissement du nombre de cycles",
    HIGH_CYCLE_COUNT: "Nombre de cycles élevé",
    LAST_TIME_SEEN_STATEMENT: "{{timePeriod}}",
    CHARGE_LEVEL: "Niveau de charge",
    ELEMEZ_BATTERY_SUBTITLE: "Essentials – Suivez, Identifiez et Remplacez vos batteries avant qu'elles aient un impact sur votre entreprise.",
    PANASONIC_BATTERY_SUBTITLE: "Monitor – Suivez, Identifiez et Remplacez vos batteries avant qu'elles aient un impact sur votre entreprise.",
    PANASONIC_CORE_V2_SUBTITLE: "Smart Service - Visualisez et prendre des mesures en réponse aux indicateurs de performances clés, aux événements et aux tendances à travers le parc.",
    DEVICE_NOT_FOUND: "On n'a trouvé aucun appareil",
    CONNECTIONS_AND_ASSETS: "Connexions et composants",
    LAST_CONTACT: "Dernier contact",
    UPDATED: "Mis à jour",
    STATUS: "État",
    SERIAL: "Numéro de série",
    OPERATOR_CODE: "Code opérateur",
    OPERATING_SYSTEM: "Système d'exploitation",
    BUILD_VERSION: "Version du Système",
    FIRMWARE_VERSION: "Version du Firmware",
    TOTAL_SPACE: "Total d'Espace",
    ESTATE_ALERT_COUNT: "Problèmes détectés dans votre parc d'appareils. <1>{{totalWithAlerts, localiseNumber}}</1> de vos <3>{{total, localiseNumber}}</3> appareils est<5>en train d'alerter.</5><6>Afficher tous les appareils</6>",
    ESTATE_ALERT_COUNT_other: "Problèmes détectés dans votre parc d'appareils. <1>{{totalWithAlerts, localiseNumber}}</1> de vos <3>{{total, localiseNumber}}</3> appareils sont<5>en train d'alerter.</5><6>Afficher tous les appareils</6>",
    ESTATE_ALERT_COUNT_SIMPLE: "<0>{{totalWithAlerts, localiseNumber}}</0> de vos <2>{{total, localiseNumber}}</2> appareils est<4>en alerte.</4>",
    ESTATE_ALERT_COUNT_SIMPLE_other: "<0>{{totalWithAlerts, localiseNumber}}</0> de vos <2>{{total, localiseNumber}}</2> appareils sont<4>en alerte.</4>",
    SERIAL_NUMBER: "Numéro de série",
    ASSET_TAG: "Numéro d'inventaire",
    HOME_LOCATION: "Emplacement",
    HOME_LOCATION_other: "Emplacements",
    LAST_SEEN: "Dernier contact",
    MOBILE_DATA: "Données mobiles",
    WIFI_DATA: "Données Wi-Fi",
    ESTATE_OVERVIEW_SUBTITLE: "{{count, localiseNumber}} {{label}} au total",
    ESTATE_OVERVIEW_TABLE_SUBTITLE: `Répartition des états d’alerte de vos emplacements et de vos groupes.`,
    VIEW_IN_CORE: "Voir dans Core",
    VIEW_IN_SMART_SERVICE: "Voir dans Smart Service",
    HELP_CENTER: "Centre d'Aide",
    CLEAR_ALL: "Tout Effacer",
    NO_SELECTION_TEXT: "Aucun {{item}} sélectionné",
    DEVICE_STATUS_HEADER: "État de l'appareil",
    GROUPS_FILTER_HEADER: "Filtres groupés",
    HOMELOCATIONS_FILTER_HEADER: "Filtre d'emplacement",
    FILTER_STATUS_TEXT: "{{filterName, lowercase}} trouvé avec filtres suivants:",
    FILTER_MODAL_SUBHEADER: "Filtrer votre liste d'appareils par les catégories et caractéristiques ci-dessous",
    IN_NEED_OF: "besoin de",
    SIGN_OUT: "Déconnecter",
    NOUN_AGREEMENT_HOURS: "Dernières {{value}} heures enregistrées",
    NOUN_AGREEMENT_DAYS: "Derniers {{value}} jours enregistrés",
    NAME_REQUIRED: "Nom et prénom - <1>Obligatoire</1>",
    JOB_TITLE_REQUIRED: "Titre du poste - <1>Obligatoire</1>",
    INFORMATION: "Renseignements",
    ALL_CATEGORIES: "toutes catégories",
  },
  editRecord: {
    GROUP_VALIDATION_ERR_COMMAS: "Les groupes ne doivent pas inclure des virgules."
  },
  editEvents: {
    FILTER_BUTTON: "Filtrer les événements",
    DISPLAY_ALL: "Afficher tous",
    APPLICATIONS_GROUP: "Applications",
    USER_EX_GROUP: "Expérience utilisateur",
    EDITING: "Vous Modifiez",
    APPLICATION_INSTALLED: "Installation d'une application",
    APPLICATION_UNINSTALLED: "Désinstallation d'une application",
    DEVICE_OFF_POWER: "Pas en charge",
    MODAL_HEADER: "Marquers d'Événements Affichés",
    APPLY_ALL_BUTTON: "Appliquer à tous les Tableaux"
  },
  timeState: {
    HOURS_24: "24 heures",
    HOURS_48: "48 heures",
    DAYS_7: "7 jours"
  },
  distance: {
    MILE: 'mille',
    MILE_other: 'milles',
  },
  performance: {
    DEVICE_PERFORMANCE_DESCRIPTION: "Rapports configurables sur les événements et l'utilisation récente de cet appareil.",
    WIFI_DATA_USED: "Données Wi-Fi utilisées",
    DEVICE_MINUTES_USAGE_PER_HOUR: "Utilisation de l'appareil en minutes par heure",
    WIFI_DATA: "Données Wi-Fi",
    WIFI_DATA_LINES: "Données\n Wi-Fi",
    TOTAL_DATA: "Données totales",
    DATA_USAGE_HOUR_WIFI_MOBILE: "Utilisation des données par heure (Wi-Fi + Mobile)",
    AVG_HOURLY_DISCHARGE: "Décharge horaire moyenne",
    UNINSTALLED_TEXT: "désinstallée",
    INSTALLED_TEXT: "installée"
  },
  deviceLocation: {
    LOCATION_UNKNOWN: "Localisation inconnue"
  },
  agreements: {
    DPA_ABBREV: 'ATD',
    THE_DPA_ABBREV: `l'ATD`,
    BOTH_EULA_DPA: `le CLUF et l'ATD`,
    OPEN_IN_NEW_TAB: 'Ouvrez {{v}} dans un nouvel onglet',
    AGREE_TERMS: `J'accepte les termes et conditions du {{v}}`,
    AGREEMENTS_READ: `Assurez-vous <1> d'avoir lu attentivement</1> {{v}} avant d'accepter:`,
  },
  batteryEssentials: {
    BATTERY_STATUS_HEADER: "État de la batterie",
    GROUPS_FILTER_HEADER: "Filtre de groupes",
    VIEW_IN_DEVICES: "Voir dans Appareils"
  },
  batteryEssentialsDeviceView: {
    GROUPS_FILTER_HEADER: "Filtre de groupes",
    DEVICE_INFORMATION: "Informations sur l'Appareil",
    VIEW_IN_BATTERIES: "Voir dans Batteries",
    DEVICE_HOURLY_DISCHARGE_STATEMENT: `Décharge moyenne de l'appareil par jour`,
    DEVICE_DISCHARGE: "Décharge"
  },
  settings: {
    BATTERY_HEALTH_ROW_TITLE: "Toutes les batteries qui rapportent la santé",
    BATTERY_CYCLE_COUNT_POPOVER: "L'alerte se produit lorsque le nombre de cycles est égal ou supérieur à chaque seuil. Il ne s'applique qu'aux batteries qui ne rapportent pas «santé».",
    BATTERY_CYCLE_COUNT_WARNING_ERROR: "Le seuil d'avertissement doit se situer entre 1 et 9998 et il doit être inférieur au seuil de remplacement.",
    BATTERY_CYCLE_COUNT_REPLACE_ERROR: "Le seuil de remplacement doit se situer entre 2 et 9999 et il doit être supérieur au seuil d'avertissement."
  },
  alerts: {
    DEVICE_LOCATION_DISTANCE_STATUS_imperial: "Cet appareil se trouve à plus de <1>{{threshold}} $t(distance:MILE_other)</1> de son endroit d'origine",
    DEVICE_LOCATION_DISTANCE_STATUS_metric: "Cet appareil se trouve à plus de <1>{{threshold}} $t(distance:KILOMETRE_other)</1> de son endroit d'origine",
    DEVICE_DROP_STATUS_other: "On a <1>laissé tomber</1> cet appareil <1>{{deviceEvent}} fois</1> au cours des ",
    DEVICE_LOW_POWER_STATUS_other: "Il y a eu <1>{{deviceEvent}} événements de faible puissance</1> sur cet appareil au cours des "
  },
  filters: {
    get SELECT_GROUP() { return `${this.SELECT} l'${frTranslations.translation.GROUP.toLowerCase()}`; }
  }
};

export const caTranslations = merge(frenchCanadianTranslations, caTranslationsOverrides);
