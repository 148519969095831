import moment from "moment";

export function roundToNearest5Minutes(epoch: number): number {

  const date = moment.utc(epoch);
  const minutes = date.minute();
  const roundedMinutes = Math.round(minutes / 5) * 5;
  date.minute(roundedMinutes).second(0);

  return date.valueOf();
}
