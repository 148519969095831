import React from "react";
import { useTimeout } from "./useTimeout";

export const useDebounce = (ms: number) => {
  const { addTimeout, timeoutIdsRef, hasActiveTimeouts: isDebouncing } = useTimeout();
  return {
    addDebounceEvent: (handler: () => void) => {
      if (timeoutIdsRef.current.length > 0) {
        [...timeoutIdsRef.current].forEach(timeoutId => {
          clearTimeout(timeoutId);

          const newTimeoutIds = [...timeoutIdsRef.current];

          const timeoutIndex = newTimeoutIds.indexOf(timeoutId);
          newTimeoutIds.splice(timeoutIndex, 1);

          timeoutIdsRef.current = newTimeoutIds;
        });
      }
      addTimeout(() => {
        handler();
      }, ms);
    },
    isDebouncing
  };
};
