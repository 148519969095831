import React from 'react';

import Card from '../../../components/card/card';
import { ApplicationsList } from './compositions/applicationsList';

export function CoreApplications() {
  return (
    <div data-id="core-applications">
      <Card noPadding={true}>
        <div>
          <div>
            <ApplicationsList />
          </div>
        </div>
      </Card>
    </div>
  );
}
