import React, { useCallback, useContext } from 'react';
import { useErrorHandler } from '@lib/useErrorHandler';
import { getWorldSettings, getFeatureToggles, WorldSettings, FeatureToggles, getLanguageAndLocale, LanguageAndLocale, getBatteryTypeSettings, BatteryTypeSettings } from '../../services/config/config';
import { getCurrentWorld, CurrentWorld } from '../../services/worlds/worlds';
import { AppLoading } from '../../components/loading/appLoading';
import { RequestInitWithRetry } from '../../lib/request';
import { useRequest } from '../../lib/useRequest';
import { useCurrentUserContext } from '../../context/currentUser';
import { AgreementsIds, getAgreementIds } from '../../services/agreements/agreements';
import { LanguageAndLocaleProvider } from '../../context/languageAndLocale';
import { useParams } from 'react-router';
import { LanguageWrapper } from '../../components/i18n/languageWrapper';
import { AccessDeniedToWorld } from '../access-denied/accessDeniedToWorld';
import { FeatureTogglesWrapper } from './featureTogglesWrapper';
import { WorldSettingsWrapper } from './worldSettingsWrapper';
import { ProductsWrapper } from './productsWrapper';
import { CurrentWorldWrapper } from './currentWorldWrapper';
import { AgreementsWrapper } from './agreementsWrapper';
import { BatteryTypeSettingsWrapper } from './batteryTypeSettingsWrapper';
import { DeviceInfoContext } from '../core/device';

const ErrorComponent = (props: { error: Error }) => {
  // this components allows us to create this component conditionally and pass the error if it occurs
  // it exists solely to pass the error to the error boundary
  useErrorHandler(props.error);
  return <div />;
};

export const WorldWrapper = (props: { children: React.ReactNode }) => {
  const { id: userId } = useCurrentUserContext();
  const { worldId } = useParams<{ worldId: string }>();

  const fetchWorldData = useCallback(() => {
    return (options: RequestInitWithRetry) => Promise.all([
      getCurrentWorld()(options),
      getWorldSettings()(options),
      getBatteryTypeSettings()(options),
      getFeatureToggles(userId)(options),
      getLanguageAndLocale()(options),
      getAgreementIds()(options)
    ]);
  }, [userId]);
  const {
    data: [currentWorld, worldSettings, batteryTypeSettings, featureToggles, languageAndLocale, agreementsIds],
    error: worldDataError,
    loading
  } = useRequest<[CurrentWorld, WorldSettings, BatteryTypeSettings[], FeatureToggles, LanguageAndLocale, AgreementsIds]>(
    fetchWorldData,
    { onError: false, initialLoading: true, initialData: [undefined, undefined, [], undefined, undefined, undefined] },
    worldId
  );

  if (worldDataError && worldDataError.message.includes('403')) {
    return <AccessDeniedToWorld />;
  }

  // rethrow any other error after useEffect calls
  if (worldDataError) {
    return <ErrorComponent error={worldDataError} />;
  }

  if (loading) { return <AppLoading />; }

  return (
    <CurrentWorldWrapper currentWorld={currentWorld}>
      <ProductsWrapper features={currentWorld?.features}>
        <WorldSettingsWrapper worldSettings={worldSettings}>
          <BatteryTypeSettingsWrapper batteryTypeSettings={batteryTypeSettings}>
            <FeatureTogglesWrapper featureToggles={featureToggles}>
              <LanguageAndLocaleProvider initialState={languageAndLocale}>
                <AgreementsWrapper agreements={agreementsIds}>
                  <LanguageWrapper>
                    {props.children}
                  </LanguageWrapper>
                </AgreementsWrapper>
              </LanguageAndLocaleProvider>
            </FeatureTogglesWrapper>
          </BatteryTypeSettingsWrapper>
        </WorldSettingsWrapper>
      </ProductsWrapper>
    </CurrentWorldWrapper>
  );
};
