import React, { useContext } from 'react';
import { Feature, Product } from '../containers/app/productsWrapper';

export type ContextProductData = {
  features?: Feature[],
  title?: string,
  name?: Product,
  enabled: boolean,
  enabledFeatures: Feature[]
};

export type ContextProductsData = {
  [p in Product]: ContextProductData
};

export const ProductsContext = React.createContext<ContextProductsData>(undefined);

interface ProductsProviderProps {
  value: ContextProductsData,
  children: React.ReactNode
}

export const ProductsProvider = ({ children, value }: ProductsProviderProps) => {
  return (
    <ProductsContext.Provider value={value}>
      {children}
    </ProductsContext.Provider>
  );
};

export function useProductsContext() {
  const context = useContext(ProductsContext);
  if (context === undefined) {
    throw new Error('useProductsContext must be used within a ProductsProvider');
  }
  return context;
}
