import react from 'react';
import styled from 'styled-components';

const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 2.25rem;
  height: 1.125rem;
  vertical-align: middle;
  margin-bottom: 0;
`;

const StyledInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + .slider {
    background-color: #4ea753;
  }

  &:checked + .slider::before {
    transform: translateX(1rem);
  }

  &:focus + .slider {
    box-shadow: 0 0 0 3px rgba(0, 119, 204, 0.5);
  }
`;

const StyledSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #949494;
  transition: .2s;
  border-radius: 0.5625rem;
  
  ::before {
    position: absolute;
    content: "";
    height: 0.875rem;
    width: 0.875rem;
    left: 0;
    bottom: 0;
    background-color: #fff;
    transition: .2s;
    border-radius: 50%;
    margin: 0.125rem 0.1875rem;
    }
`;

interface Props {
  className?: string,
  change: (() => void),
  active: boolean
}

export function Toggle(props: Props) {
  const { className, active, change } = props;
  const styleClassName = `switch ${className ?? ''}`;

  return <StyledSwitch className={styleClassName} data-id="toggleSwitch">
    <StyledInput type='checkbox' onChange={change} checked={active} />
    <StyledSlider data-id="slider" className="slider" onChange={change} />
  </StyledSwitch>;
}
