import * as React from "react";
import StepRangeSlider from 'react-step-range-slider';
import { map } from 'lodash';

import './slider.css';

interface IProps {
  defaultOptionIndex?: number,
  options: { value: number, display: any, unit?: string }[],
  onSliderChangeComplete?: (value: number, display: string, unit?: string) => void,
  onSliderChange?: (value: number, display: any, unit?: string) => void,
  type?: "default" | "blue",
  disabled?: boolean
}

export class Slider extends React.Component<IProps, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      sliderValue: props.defaultOptionIndex || 0
    };
  }

  public render() {
    const optionWidth = (100 / this.props.options.length);
    const displayOptions = map(this.props.options, (item: any, index: number) => {
      let position;
      if (index === 0) {
        position = -optionWidth / 2;
      } else if (index === this.props.options.length - 1) {
        position = 100 - optionWidth / 2;
      } else {
        position = ((index * 100) / (this.props.options.length - 1)) - optionWidth / 2;
      }
      return (
        <div
          key={item.value}
          className={index === this.state.sliderValue ? 'selected' : ''}
          style={{ left: `${position}%`, width: `${optionWidth}%` }}
        >
          {item.display}
        </div>
      );
    });

    const stepRange = map(this.props.options, (_, i) => { return { value: i }; });

    let handleChange;
    if (this.props.onSliderChange) {
      handleChange = (index: number) => {
        const { value, display, unit } = this.props.options[index];
        this.setState({ sliderValue: index });
        this.props.onSliderChange(value, display, unit);
      };
    }

    let handleChangeComplete;
    if (this.props.onSliderChangeComplete) {
      handleChangeComplete = (index: number) => {
        const { value, display, unit } = this.props.options[index];
        this.setState({ sliderValue: index });
        this.props.onSliderChangeComplete(value, display, unit);
      };
    }

    return <div className={"slider " + (this.props.type || 'default')}>
      <div className="slider-inner" style={{ padding: `0 ${optionWidth / 2.6}%` }}>
        <div data-id='slider-options' className="slider-options">
          {displayOptions}
        </div>
        <div className="step-range-slider-container">
          <StepRangeSlider
            range={stepRange}
            value={this.state.sliderValue}
            children={''}
            className={"slider_style"}
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    </div>;
  }
}
