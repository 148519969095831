import * as React from 'react';
import { Switch, Route } from 'react-router-dom';

import { WorldIdRoute } from './world-id-route';
import { NoWorldIdRoute } from './no-world-id-route';
import { WorldSelectionRoute } from './world-selection-route';
import { UniverseAdmin } from '../containers/universeAdmin';
import { useIsExternalUser } from '../components/authentication/externalUser';
import { useCurrentUserContext } from '../context/currentUser';

export const useNonWorldRoutes = () => {
  return {
    landing: '/',
    worldSelection: '/world-selection',
    universeAdmin: {
      basePath: '/universe-admin',
      worlds: {
        basePath: '/universe-admin/worlds',
        add: '/universe-admin/worlds/add'
      }
    }
  };
};

export const AppRoutes = () => {
  const appRoutes = useNonWorldRoutes();
  const currentUser = useCurrentUserContext();
  const { isExternalUser } = useIsExternalUser(currentUser.email);
  return (
    <Switch>
      <Route path={'/:worldId([a-fA-F0-9]{32}|demo[^/]*)'}>
        <WorldIdRoute />
      </Route>
      {!isExternalUser && <Route path={appRoutes.universeAdmin.basePath}>
        <UniverseAdmin />
      </Route>}
      <Route path={appRoutes.worldSelection}>
        <WorldSelectionRoute />
      </Route>
      <Route path={'/'}>
        <NoWorldIdRoute />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
