import * as React from "react";

export const useCopyClick = (text: string) => React.useCallback((e: React.MouseEvent) => {
  e.stopPropagation();
  navigator.clipboard.writeText(text);
}, [text]);

export const useTableCell = (cellRef: React.MutableRefObject<HTMLTableCellElement>, children: React.ReactNode) => {
  const [tooltipShown, setTooltipShown] = React.useState(false);

  const hideTooltip = () => setTooltipShown(false);

  const onMouseOver = () => {
    const element = cellRef.current;
    const isOverflown = element.offsetHeight < element.scrollHeight ||
      element.offsetWidth < element.scrollWidth;

    if (isOverflown) {
      setTooltipShown(true);
    }
  };

  return { onMouseOver, tooltipShown, hideTooltip };
};
