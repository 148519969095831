import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import './roleTitleTooltip.css';
import { InfoPopover } from '../../../components/tooltip/infoPopover';

interface Props {
  title: string,
  portalSelector?: string
}

export function RoleTitleTooltip(props: Props) {
  const { title, portalSelector } = props;
  const { t } = useTranslation('userManagement');

  return <>
    {title}
    <InfoPopover labelText='about role' popoverPortalSelector={portalSelector}>
      <div className="role-title-tooltip__text">
        <div className="role-title-tooltip__viewer">{t('VIEWER')} - <span>{t('TOOLTIP_VIEWER_TEXT')}</span></div>
        <div className="role-title-tooltip__editor">{t('EDITOR')} - <span>{t('TOOLTIP_EDITOR_TEXT')}</span></div>
        <div className="role-title-tooltip__manager">{t('MANAGER')} - <span>{t('TOOLTIP_MANAGER_TEXT')}</span></div>
        <div className="role-title-tooltip__administrator">{t('ADMIN')} - <span>{t('TOOLTIP_ADMIN_TEXT')}</span></div>
      </div>
    </InfoPopover>
  </>;
}
