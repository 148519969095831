import { get } from 'lodash';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { CheckBox } from '../../../../components/controls/checkbox';
import { WorldSettings } from '../../../../services/config/config';
import { useFeatureTogglesContext } from '../../../../context/featureToggles';

const Block = styled.div`
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
`;

const Title = styled.div`
  background: #f3f7fb;
  border-bottom: 1px solid #d7e2eb;
  padding: 0.3rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
`;

const Body = styled.div`
  padding-left: 1.15rem;
  font-size: 0.875rem;
  font-weight: 400;
  padding-bottom: 1.4rem;
  text-align: left;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
  padding: 0.2rem;
`;

export interface IProps {
  worldSettings: WorldSettings,
  onDataChanged: (data: WorldSettings) => void
}

export function DevicesSettings(props: IProps) {
  const { t } = useTranslation('settings');
  const { worldSettings } = props;
  const featureToggles = useFeatureTogglesContext();
  const systemText = {
    android: t('LOCATION_ANDROID_ENABLED'),
    windows: t('LOCATION_WINDOWS_ENABLED')
  };

  function onDataChanged(data: boolean, key: any): void {
    props.onDataChanged({ ...worldSettings, [key]: data });
  }

  const mobileSignalReportingText = {
    android: t('MOBILE_SIGNAL_REPORTING_ANDROID'),
    windows: t('MOBILE_SIGNAL_REPORTING_WINDOWS')
  };

  return (
    <>
      <Block className="devices-configuration">
        <Title className="devices-configuration_header">{t('LOCATION_INFORMATION_TITLE')}</Title>
        <Body className="devices-configuration_body">
          {Object.keys(systemText).map(system => {
            const name = get(systemText, `${system}`);
            const id = `${(name.toLowerCase()).replace(/ /g, "_")}`;
            return (
              <Row key={id}>{name}
                <CheckBox
                  key={id}
                  name={id}
                  className="fancy-checkbox"
                  checked={get(worldSettings, `${system}LocationCollectionEnabled`)}
                  onChange={(event) => onDataChanged(event.checked, `${system}LocationCollectionEnabled`)} />
              </Row>
            );
          })}
        </Body>
      </Block>
      <Block className="devices-configuration">
        <Title className="devices-configuration_header">{t('MOBILE_SIGNAL_REPORTING_TITLE')}</Title>
        <Body className="devices-configuration_body">
          {Object.keys(mobileSignalReportingText).map(deviceTypeText => {
            const name = get(mobileSignalReportingText, `${deviceTypeText}`);
            const id = `${(name.toLowerCase()).replace(/ /g, "_")}MobileSignalReporting`;
            return (
              <Row key={id}>{name}
                <CheckBox
                  key={id}
                  name={id}
                  className="fancy-checkbox"
                  checked={get(worldSettings, `${deviceTypeText}MobileSignalLevelCollectionEnabled`)}
                  onChange={(event) => onDataChanged(event.checked, `${deviceTypeText}MobileSignalLevelCollectionEnabled`)} />
              </Row>
            );
          })}
        </Body>
      </Block>
      <Block className="devices-configuration">
        <Title className="devices-configuration_header">{t('DROPS_ENABLED_TITLE')}</Title>
        <Body className="devices-configuration_body">
          <Row>{t('DROPS_ANDROID_ENABLED')}
            <CheckBox
              name="drops_enabled"
              className="fancy-checkbox"
              checked={worldSettings.androidDropDetectionEnabled}
              onChange={(event) => onDataChanged(event.checked, 'androidDropDetectionEnabled')} />
          </Row>
        </Body>
      </Block>
    </>
  );
}
