import { request, RequestInitWithRetry } from "../../lib/request";
import { AlertStatus } from "../config/alertConstants";

export interface AnomalyCounts {
  deviceCount: number,
  alertingCount: number
}

export interface Result {
  utc: number,
  detected: boolean,
  grouping: AnomalyCounts,
  population: AnomalyCounts,
  shareOfAlerting: number,
  shareOfPopulation: number
}

export interface IAnomalyDetails {
  anomalyId: string,
  firstDetected: number,
  lastDetected: number,
  detectedCount: number,
  highestAffectedCount: number,
  totalAffectedCount: number,
  alertType: AlertStatus,
  properties: { name: string, value: string }[],
  shareOfPopulation: number,
  totalAlertingCount: number,
  lastAffected: number,
  groupAlertingCount: number,
  description: string,
  population: number,
  detected: boolean
}

export function getAnomalyDetails(params: { anomalyId: string }) {
  return async (options: RequestInitWithRetry): Promise<IAnomalyDetails> => {
    const input = {
      path: `/api/core/anomalies/details`,
      qs: { anomalyId: params.anomalyId }
    };
    const output = await request(input, options);
    return output;
  };
}
