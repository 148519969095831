import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { defaults } from 'lodash';
import { Route, Switch, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { Access } from './parts/access';
import { alignValue } from './parts/utils';
import { BatteryProductName } from '../services/shared/battery/list';
import { BatteryCentricContainer } from '../containers/battery-centric';
import { BatteryDevicesContainer } from '../containers/battery-devices';
import { decode } from '../lib/base64';
import { SelectorBarPage } from './parts/selectorBarPage';
import { StateSwitch } from './parts/stateSwitch';
import { VIEW } from '../services/shared/common';
import { useCurrentWorldContext } from '../context/currentWorld';
import { ISelectorBarTab } from '../layout/body/selectorBar';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { useProductsContext } from '../context/products';
import { Feature, Product } from '../containers/app/productsWrapper';
import { use } from 'i18next';
import { useMemo } from 'react';

const product: Product = 'batteryEssentials';

export interface PageConfig {
  link: string,
  productName: BatteryProductName,
  selectorBarEnabled: boolean,
  selectorBarValue: string,
  selectorBarTabs: ISelectorBarTab[],
  selectorBarId?: string
}

interface PageProps {
  title: string,
  config: PageConfig,
  active?: boolean
}

const batteryRouteMapping = {
  [VIEW.all]: VIEW.all,
  undefined: VIEW.all,
  "unknown": VIEW.all,
  [VIEW.red]: VIEW.red,
  "replace": VIEW.red,
  [VIEW.orange]: VIEW.orange,
  "warning": VIEW.orange,
  [VIEW.green]: VIEW.green,
  "good": VIEW.green
};

const deviceRouteMapping = {
  [VIEW.all]: VIEW.all,
  undefined: VIEW.all,
  "unknown": VIEW.all,
  [VIEW.yellow]: VIEW.yellow,
  "warning": VIEW.yellow,
  [VIEW.green]: VIEW.green,
  "good": VIEW.green
};

const selectorBarTitles = {
  devices: 'DEVICE_other',
  batteries: 'BATTERY_FIRST_CAPITAL_other'
};

export const BatteryRoutes = () => {
  const basePath = useRouteMatch().path;
  const products = useProductsContext();
  const { features } = useCurrentWorldContext();

  const routes = useWorldRoutes();

  const config: any = {
    link: routes.batteryEssentials.battery,
    productName: BatteryProductName.batteryEssentials,
    selectorBarEnabled: false
  };

  const batteryEssentials = features.includes(Feature.batteryEssentials) || features.includes(Feature.panasonicSmartBatteryMonitor);
  const batteryEssentialsDevices = features.includes(Feature.batteryEssentialsDevices) || features.includes(Feature.panasonicSmartBatteryMonitorDevices);
  const title = products[product].title;

  const selectorBarTabs = [
    { id: 'batteryBatteriesBtn', link: routes.batteryEssentials.battery, title: selectorBarTitles.batteries },
    { id: 'batteryDevicesBtn', link: routes.batteryEssentials.devices, title: selectorBarTitles.devices }
  ];
  if (batteryEssentials && batteryEssentialsDevices) {
    const devicesConfig = defaults({
      selectorBarEnabled: true,
      selectorBarValue: selectorBarTitles.devices,
      selectorBarTabs,
      link: routes.batteryEssentials.devices
    }, config);
    const batteriesConfig = defaults({
      selectorBarEnabled: true,
      selectorBarTabs,
      selectorBarValue: selectorBarTitles.batteries
    }, config);

    return (
      <Switch>
        <Route exact path={[`${basePath}/devices/:view`, `${basePath}/devices`]}>
          <StateSwitch selectedIndex={0}>
            <DevicesPage title={title} config={devicesConfig} active={true} />
            <BatteriesPage title={title} config={batteriesConfig} />
          </StateSwitch>
        </Route>
        <Route exact path={[`${basePath}/:view`, `${basePath}`]}>
          <StateSwitch selectedIndex={1}>
            <DevicesPage title={title} config={devicesConfig} />
            <BatteriesPage title={title} config={batteriesConfig} active={true} />
          </StateSwitch>
        </Route>
      </Switch>
    );
  } else if (batteryEssentialsDevices) {
    return (
      <Route exact path={[`${basePath}/:view`, `${basePath}`]}>
        <DevicesPage active={true} title={title} config={config} />
      </Route>
    );
  } else if (batteryEssentials) {
    return (
      <Route exact path={[`${basePath}/:view`, basePath]}>
        <BatteriesPage active={true} title={title} config={config} />
      </Route>
    );
  }

  return <Access product={product} />;
};

const BatteriesPage = (props: PageProps) => {
  const { active, title, config } = props;
  const { view } = useParams<{ view: string }>();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const queryId = searchParams.get('id');
  const queryTs = searchParams.get('ts');
  const selectedId = active && queryId ? decode(queryId) : undefined;
  const queryStringTimestamp = active ? Number(queryTs) : undefined;

  return (
    <Access product={product} title={title} >
      <Helmet><title>{title}</title></Helmet>
      <SelectorBarPage title={title} config={config} >
        <BatteryCentricContainer
          view={alignValue(view, batteryRouteMapping)}
          selectedId={selectedId}
          selectedTimestamp={queryStringTimestamp}
          config={config}
        />
      </SelectorBarPage>
    </Access>
  );
};

const DevicesPage = (props: PageProps) => {
  const { active, title, config } = props;
  const { view } = useParams<{ view: string }>();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const queryId = searchParams.get('id');
  const queryTs = searchParams.get('ts');
  const selectedId = active && queryId ? decode(queryId) : undefined;
  const queryStringTimestamp = active ? Number(queryTs) : undefined;

  return (
    <Access product={product} title={title} >
      <Helmet><title>{title}</title></Helmet>
      <SelectorBarPage title={title} config={config} >
        <BatteryDevicesContainer view={alignValue(view, deviceRouteMapping)} selectedId={selectedId} selectedTimestamp={queryStringTimestamp} config={config} />
      </SelectorBarPage>
    </Access>
  );
};
