import { System, systems, isSystem, DEFAULT_SYSTEM } from './systems';

export const getElemezHostname = (): string => {
  if (window.location.origin.match(/(development:|localhost:)/)) {
    return 'elemez.com';
  }
  return window.location.hostname;
};

const subdomains = ['api', 'www'] as const;

export const parseElemezHostname = (): [string, System] => {
  const nonDefaultSystems = systems.filter(s => s !== DEFAULT_SYSTEM);
  const hostname = dependencies.getElemezHostname();
  const subdomainsGroup = `(?:${subdomains.map(s => `${s}\\.`).join('|')})?`;
  const brandGroup = '(.+?)?';
  const systemsGroup = `(${nonDefaultSystems.map(s => `${s}\\.`).join('|')})?`;
  const re = new RegExp(`^${subdomainsGroup}${brandGroup}${systemsGroup}elemez\\.com`);
  const match = hostname.match(re) || [];
  const [_, brand, system] = match.map(m => m && m.replace(/\.$/, ''));
  if (isSystem(brand) && brand !== DEFAULT_SYSTEM) {
    return [undefined, brand as System];
  }
  return [brand, system as System];
};

export const dependencies = {
  getElemezHostname
};
