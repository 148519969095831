import { useTranslation } from "@lib/useTypedTranslation";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { DEFAULT_VALUE, formatDataUsage, formatDateLastUpdated, formatValue } from "../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../components/data-table/types";
import { Tooltip } from "../../../components/tooltip/tooltip";
import { useWorldRoutes } from "../../../routes/parts/allWorldRoutes";
import { ApplicationDevicesListItem } from "../../../services/core/application";
import { encode } from "../../../lib/base64";
import { useWorldSettingsContext } from '../../../context/worldSettings';
import { rearrangeList } from '../../../lib/rearrangeList';
import { useMemo } from 'react';

const TooltipText = styled.div`
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-normal);
  max-width: 14rem;
`;

const StyledTooltip = styled(Tooltip)`
  color: #7490b9;
  margin-left: 0.375rem;
`;

const formatLinkToDevice = (serialNumber: string, rowData: ApplicationDevicesListItem, path: string) => {
  if (!serialNumber) { return DEFAULT_VALUE; }
  return <Link to={`${path}?id=${encodeURIComponent(encode(rowData.deviceInfo.id))}`}>{formatValue(serialNumber)}</Link>;
};

export const useApplicationDevicesListColumns = () => {
  const { t } = useTranslation(['coreApplications', 'translation']);
  const routes = useWorldRoutes();
  const { worldSettings } = useWorldSettingsContext();
  const columns = useMemo(() => {
    const linkProcessor = (appName: string, rowData: ApplicationDevicesListItem) => {
      return formatLinkToDevice(appName, rowData, routes.core.deviceOverview);
    };

    function getHomeLocationName(value: string, rowData: ApplicationDevicesListItem) {
      return rowData.deviceInfo?.homeLocation ? formatValue(rowData.deviceInfo.homeLocation.name) : "-";
    }

    const mobileTitleWithTooltip = <>
      {t('MOBILE_DATA_FULL', { ns: 'coreApplications' })}
      <StyledTooltip key='mobile-tooltip' popoverPortalSelector={'.core-application'} placement={'bottom'} popperContent={<TooltipText>{t('MOBILE_DATA_TOOLTIP_TEXT', { ns: 'coreApplications' })}</TooltipText>}>
        <i aria-label='about app mobile data' className="fa fa-question-circle" />
      </StyledTooltip>
    </>;
    const wifiTitleWithTooltip = <>
      {t('WIFI_DATA_FULL', { ns: 'coreApplications' })}
      <StyledTooltip key='wifi-tooltip' popoverPortalSelector={'.core-application'} placement={'bottom'} popperContent={<TooltipText>{t('WIFI_DATA_TOOLTIP_TEXT', { ns: 'coreApplications' })}</TooltipText>}>
        <i aria-label='about app wifi data' className="fa fa-question-circle" />
      </StyledTooltip>
    </>;

    const columns: ITableColumn[] = [
      { id: 'serialNumber', title: t('SERIAL_NUMBER', { ns: 'translation' }), dataPath: 'deviceInfo.serialNumber', sortable: true, processor: formatValue, width: '12rem' },
      { id: 'imei', title: t('IMEI', { ns: 'translation' }), dataPath: 'deviceInfo.imei', sortable: true, processor: formatValue, width: '10rem' },
      { id: 'assetTag', title: t('ASSET_TAG', { ns: 'translation' }), dataPath: 'deviceInfo.assetTag', sortable: true, processor: formatValue, width: '7rem' },
      { id: 'groups', title: t('GROUPS', { ns: 'translation' }), dataPath: 'deviceInfo.group', sortable: false, processor: formatValue, width: '9rem' },
      { id: 'homeLocation', title: t('HOME_LOCATION', { ns: 'translation' }), dataPath: 'deviceInfo.homeLocationId', sortable: true, processor: getHomeLocationName, width: '10rem' },
      { id: 'lastUsed', title: t('APP_LAST_USED', { ns: 'coreApplications' }), dataPath: 'lastUsed', sortable: true, processor: formatDateLastUpdated, width: '9rem' },
      { id: 'mobileDataUsage', title: mobileTitleWithTooltip, dataPath: 'mobileDataUsage', sortable: true, processor: formatDataUsage, textAlign: 'right', width: '12rem' },
      { id: 'wifiDataUsage', title: wifiTitleWithTooltip, dataPath: 'wifiDataUsage', sortable: true, processor: formatDataUsage, textAlign: 'right', width: '11rem' },
      { id: 'version', title: t('APP_VERSION', { ns: 'coreApplications' }), dataPath: 'version', sortable: true, processor: formatValue, width: '8rem' }
    ];

    const deviceIdentifier = worldSettings.deviceIdentifier;

    const updatedColumn: ITableColumn[] = rearrangeList(columns, deviceIdentifier);
    updatedColumn[0].processor = linkProcessor;

    return updatedColumn;
  }, [worldSettings, routes.core.deviceOverview, t]);

  return columns;
};
