import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useWhatsNew } from '../../context/whatsNewProvider';
import styled, { css, keyframes } from 'styled-components';
import { Theme } from '../../containers/app/themes';
import { applySmallButtonStyles, DropdownMenu } from '../../components/controls/dropdownMenu';
import moment from 'moment';
import { useUserSettingsContext } from '../../context/userSettings';
import { useNonWorldAction } from '../../lib/useNonWorldAction';
import { setLastWhatsNewAction } from '../../services/config/config';
import { useTranslation } from "@lib/useTypedTranslation";

const IconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
`;

const StyledWhatsNewIcon = styled.div`
  ${({ theme }: { theme: Theme }) => `background: ${theme.headers.contrastBackground};`};
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  padding-top: 5px;
`;

const StyledWhatsNewDropdownText = styled.span`
  background: inherit;
  padding-left: 10px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 1300px) {
    max-width: 100px;
  };
  @media (max-width: 1100px) {
    display: none;
  };
`;

export const DropdownItem = styled.div`
  margin-top: -2px;

  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  text-wrap: wrap;
  min-width: 400px;

  font-weight: 400;
  border-bottom: 1px solid ${({ theme }: { theme: Theme }) => theme.sectionBorders.colours.light};
  padding: 8px 12px;

  ${applySmallButtonStyles()};

  &, &:focus {
  ${({ theme } : { theme: Theme }) => `
    background-color: ${theme.headers.dropdown.default};
    color: ${theme.headers.text};
  `}
  }

  &:hover {
    ${({ theme }: { theme: Theme }) => `
      background-color: ${theme.headers.dropdown.hover};
      color: ${theme.headers.text};
    `}
  }
  
  &:last-child {
    border-bottom: none;
  }
`;
const NotificationDot = styled.span`
  position: absolute;
  top: 0px;
  right: 1.5px;
  background-color: #FF8C00;
  border-radius: 100%;
  width: 10px;
  height: 10px;
`;

const NotificationTitle = styled.a`
  font-weight: bold;
  ${({ theme } : { theme: Theme }) => `
    color: ${theme.headers.text};
  `}
  cursor: pointer;
  font-size: 1.2em;
  margin-bottom: 5px;

  &:hover {
    text-decoration: underline;
    color: ${({ theme }: { theme: Theme }) => theme.headers.text};
  }
`;

const NotificationDate = styled.div`
  font-size: .8em;
  margin-bottom: 8px;
  ${({ theme } : { theme: Theme }) => `
    color: ${theme.headers.text};
  `}
  
`;

const NotificationText = styled.div`
  font-size: 1em;
  ${({ theme } : { theme: Theme }) => `
    color: ${theme.headers.text};
  `}
  margin-bottom: 5px;
`;


export const WhatsNew = () => {
  const { t } = useTranslation('translation');
  const [isOpen, setIsOpen] = useState(false);
  const notifications = useWhatsNew();
  const { lastWhatsNewId, updateProperties } = useUserSettingsContext();
  const setLastWhatsNewIdSetting = useNonWorldAction(setLastWhatsNewAction);

  const isLastWhatsNewChanged = useCallback(() => {
    return lastWhatsNewId !== notifications[0]?.id;
  }, [lastWhatsNewId, notifications]);

  const toggleDropdown = useCallback(() => {
    setIsOpen(prevState => !prevState);
    if (isLastWhatsNewChanged()) {
      const newLastWhatsNewId = notifications[0]?.id;
      setLastWhatsNewIdSetting({ lastWhatsNewId: newLastWhatsNewId });
      updateProperties('lastWhatsNewId', { lastWhatsNewId: newLastWhatsNewId });
    }
  }, [notifications, isLastWhatsNewChanged, setLastWhatsNewIdSetting, updateProperties]);

  return <>
    {notifications?.length > 0 && <DropdownMenu
      data-id="whatsNew-dropdown"
      onButtonClick={toggleDropdown}
      buttonContent={<>
        <IconWrapper>
          <StyledWhatsNewIcon
            className="fa fa-bell"
          >
            {!isOpen && isLastWhatsNewChanged() && <NotificationDot data-id="notification-dot"/>}
          </StyledWhatsNewIcon>
        </IconWrapper>
        <StyledWhatsNewDropdownText>{t('WHATS_NEW')}</StyledWhatsNewDropdownText>
      </>}
      right
    >
      {notifications.map((notification: any, index: any) => (
        <DropdownItem id={`index ${index}`} key={`${notification.id}+${moment.utc(parseInt(notification.date))}`}>
          <NotificationDate data-id="notification-date">
            {moment.utc(parseInt(notification.date)).format('MMMM D, YYYY')}
          </NotificationDate>
          <NotificationTitle href={notification.link} target="_blank" rel="noopener noreferrer" data-id="notification-title">
            {notification.title}
          </NotificationTitle>
          <NotificationText data-id="notification-text">{notification.text}</NotificationText>
        </DropdownItem>
      ))}
    </DropdownMenu>
    }
  </>;


};
