import * as React from 'react';
import { cloneDeep, isEqual, zip } from 'lodash';

import { IDevicesData, setAssetTagAction, IAssetTagParams, setGroupsAction, setHomeLocationAction, IHomeLocationParams, IGroupParams } from '../../../../services/core/devices';
import { IHomeLocation } from '../../../../services/config/config';
import { tuple } from '../../../../lib/typeUtils';
import { useWorldAction } from '../../../../lib/useWorldAction';

export type FieldName = 'assetTag' | 'homeLocation' | 'group';

interface BaseField {
  name: FieldName,
  value: any
}

interface AssetTagField extends BaseField {
  name: 'assetTag',
  value: IDevicesData['assetTag']
}

interface HomeLocationField extends BaseField {
  name: 'homeLocation',
  value: IHomeLocation
}

interface GroupField extends BaseField {
  name: 'group',
  value: IDevicesData['group']
}

export type Field = AssetTagField | HomeLocationField | GroupField;

type FieldToMap<F extends Field> = { [k in F['name']]: F['value'] };

export type AssetTagMap = FieldToMap<AssetTagField>;
export type HomeLocationMap = FieldToMap<HomeLocationField>;
export type GroupMap = FieldToMap<GroupField>;
export type FieldMap = AssetTagMap | HomeLocationMap | GroupMap;
export type FieldMaps = AssetTagMap & HomeLocationMap & GroupMap;

export type SaveAction = {
  type: 'onSave',
  onDeviceUpdated: (newRowData: IDevicesData) => void,
  handleClose: () => void,
  dispatch: React.Dispatch<EditDeviceAction>,
  setAssetTag: (params: IAssetTagParams) => Promise<{ success: boolean }>,
  setHomeLocation: (params: IHomeLocationParams) => Promise<{ success: boolean }>,
  setGroups: (params: IGroupParams) => Promise<{ success: boolean }>,
  invalid?: boolean // pass to prevent save action if changes are known to be invalid
};

export type EditDeviceAction =
  | { type: 'onNewDevice', device: IDevicesData }
  | { type: 'onFieldUpdate', update: FieldMap }
  | SaveAction | {
    type: 'onSaveComplete',
    onDeviceUpdated: (newRowData: IDevicesData) => void,
    handleClose: () => void,
    fieldUpdates: Field[],
    results: PromiseSettledResult<MutationResponse>[]
  };

export interface EditDeviceState extends FieldMaps {
  device: IDevicesData,
  saving: boolean,
  errors: FieldName[]
}

export type MutationResponse = { success: boolean };

export const normalizeGroups = (groups: string[]) => (groups || []).map((g) => g.trim().toLowerCase());

const fieldMutations: { [field in FieldName]: (state: EditDeviceState, action?: SaveAction) => [Field, Promise<MutationResponse>] } = {
  assetTag: (state: EditDeviceState, action: SaveAction) => {
    const { device, assetTag } = state;
    const trimmedAssetTag = assetTag.trim();
    if (trimmedAssetTag !== (device.assetTag ?? '')) {
      return [
        { name: 'assetTag', value: trimmedAssetTag },
        action.setAssetTag({ id: device.id, assetTag: trimmedAssetTag })
      ];
    }

  },
  homeLocation: (state: EditDeviceState, action: SaveAction) => {
    const { device, homeLocation: { name, id } } = state;
    if (id !== undefined && name !== (device.homeLocation?.name ?? null)) {
      return [
        { name: 'homeLocation', value: { name, id } },
        action.setHomeLocation({ id: device.id, homeLocationId: id })
      ];
    }
  },
  group: (state: EditDeviceState, action: SaveAction) => {
    const { device, group } = state;
    if (!isEqual(new Set(normalizeGroups(group)), new Set(normalizeGroups(device.group)))) {
      return [
        { name: 'group', value: group },
        action.setGroups({ id: device.id, groups: group })
      ];
    }
  },
};

const prepareMutations = (state: EditDeviceState, action: SaveAction) => {
  return Object.values(fieldMutations)
    .reduce(([fields, promises], fieldMutation) => {
      const [update, promise] = fieldMutation(state, action) || [];
      if (update && promise) {
        fields.push(update);
        promises.push(promise);
      }
      return tuple(fields, promises);
    }, [[], []] as [Field[], Promise<MutationResponse>[]]);
};

const processResults = (state: EditDeviceState, results: [Field, PromiseSettledResult<MutationResponse>][]) => {
  const device = cloneDeep(state.device);
  return results.reduce<[FieldName[], EditDeviceState['device']]>(([errors, device], [update, result]) => {
    if (result.status === 'fulfilled' && result.value.success) {
      if (update.name === 'assetTag') {
        device.assetTag = update.value || null;
      } else if (update.name === 'homeLocation') {
        // We do not know the home location distance thresholds at this time, so just set them to null
        // since location alert messages will not be updated until a page refresh anyway.
        device.homeLocation = { id: update.value.id, name: update.value.name, distanceYellow: null, distanceRed: null };
      } else {
        device[update.name] = update.value;
      }
    } else {
      errors.push(update.name);
    }
    return [errors, device];
  }, [[], device]);
};

export const handleSave = (state: EditDeviceState, action: SaveAction) => {
  const [fieldUpdates, promises] = dependencies.prepareMutations(state, action);
  Promise.allSettled(promises)
    .then((results) => {
      const { dispatch, onDeviceUpdated, handleClose } = action;
      return dispatch({
        type: 'onSaveComplete',
        onDeviceUpdated,
        handleClose,
        fieldUpdates,
        results
      });
    });
  return Boolean(promises.length);
};

export const handleSaveComplete = (state: EditDeviceState, action: Extract<EditDeviceAction, { type: 'onSaveComplete' }>) => {
  const { fieldUpdates, results, onDeviceUpdated, handleClose } = action;
  const [errors, updatedDevice] = processResults(state, zip(fieldUpdates, results));
  if (!isEqual(state.device, updatedDevice)) {
    onDeviceUpdated(updatedDevice);
  }
  if (!errors.length) {
    handleClose();
  }
  return tuple(errors, updatedDevice);
};

export function editDeviceReducerInit(device: IDevicesData): EditDeviceState {
  return {
    device,
    saving: false,
    assetTag: device?.assetTag || '',
    homeLocation: { id: undefined, name: device?.homeLocation?.name || null },
    group: normalizeGroups(device?.group),
    errors: []
  };
}

export const dependencies = {
  handleSave,
  handleSaveComplete,
  prepareMutations
};

export const editDeviceReducer = (state: EditDeviceState, action: EditDeviceAction): EditDeviceState => {
  if (action.type === 'onNewDevice' && !state.saving) {
    return editDeviceReducerInit(action.device);
  }
  if (action.type === 'onFieldUpdate' && !state.saving) {
    return { ...state, ...action.update };
  }
  if (action.type === 'onSave' && !state.saving && !action.invalid) {
    const updated = dependencies.handleSave(state, action);
    return { ...state, saving: updated, errors: [] };
  }
  if (action.type === 'onSaveComplete') {
    const [errors, device] = dependencies.handleSaveComplete(state, action);
    return { ...state, saving: false, errors, device };
  }
  return state;
};

export const useEditDeviceReducer = (device: IDevicesData) => {
  const reducer = React.useCallback(editDeviceReducer, []);
  const [data, dispatch] = React.useReducer(reducer, device, editDeviceReducerInit);
  const setAssetTag = useWorldAction(setAssetTagAction);
  const setHomeLocation = useWorldAction(setHomeLocationAction);
  const setGroups = useWorldAction(setGroupsAction);
  const dispatchers = React.useMemo(() => {
    const onNewDevice = (device: IDevicesData) => {
      return dispatch({ type: 'onNewDevice', device });
    };
    const onFieldUpdate = (update: FieldMap) => {
      return dispatch({ type: 'onFieldUpdate', update });
    };
    const onSave = (onDeviceUpdated: (newRowData: IDevicesData) => void, handleClose: () => void, invalid?: boolean) => {
      return dispatch({ type: 'onSave', onDeviceUpdated, handleClose, dispatch, setAssetTag, setHomeLocation, setGroups, invalid });
    };
    return {
      onNewDevice,
      onFieldUpdate,
      onSave,
    };
  }, [dispatch, setAssetTag, setHomeLocation, setGroups]);
  return tuple(data, dispatchers);
};
