import React, { useMemo } from 'react';
import { UserSettingsProvider } from '../../context/userSettings';
import { UserSettings } from '../../services/config/config';

interface Props {
  children: React.ReactNode,
  userSettings: UserSettings
}

export function UserSettingsWrapper(props: Props) {
  const { children, userSettings } = props;
  const value = useMemo(() => ({
    ...userSettings,
  }), [userSettings]);

  return (
    <UserSettingsProvider initialState={value}>
      {children}
    </UserSettingsProvider>
  );
}
