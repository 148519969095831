import { useTranslation } from '@lib/useTypedTranslation';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

import { WorldWrapper } from '../containers/app/worldWrapper';
import LandingContainer from '../containers/landing/index';
import { AgreementsRoutes } from './agreements-route';
import { BatteryRoutes } from './battery-route';
import { BatteryWarrantyRoutes } from './battery-warranty-route';
import { BulkActionsRoute } from './bulk-actions-route';
import { CoreRoutes } from './core-route';
import { EnrollmentRoute } from './enrollment-route';
import { HomeLocationManagementRoute } from './home-locations-route';
import { StandardPage } from './parts/standardPage';
import { SettingsRoutes } from './settings-route';
import { UserManagementRoute } from './users-route';
import { useBaseWorldRoutes, useWorldRoutes } from './parts/allWorldRoutes';
import { useAgreementsContext } from '../context/agreements';
import { getWorlds, World } from '../services/worlds/worlds';
import { useNonWorldRequest } from '../lib/useNonWorldRequest';
import { useErrorHandler } from '@lib/useErrorHandler';
import { AppLoading } from '../components/loading/appLoading';
import { WorldsProvider } from '../context/worlds';
import { productNames } from '../containers/app/productsWrapper';
import { ZoneManagementRoute } from './zones-route';
import { useCurrentUserContext } from '../context/currentUser';
import { useIsExternalUser } from '../components/authentication/externalUser';

export const WorldRoutes = () => {
  const { t } = useTranslation();
  const allWorldRoutes = useWorldRoutes();
  const allBaseWorldRoutes = useBaseWorldRoutes();
  const currentUser = useCurrentUserContext();
  const { isExternalUser } = useIsExternalUser(currentUser.email);

  const getPath = (pathWithoutWorldId: string) => `/:worldId${pathWithoutWorldId}`;

  const { agreementsToAccept } = useAgreementsContext();

  // If eula or dpa agreements are required, user may only access agreements pages
  const agreementRedirect = isExternalUser && agreementsToAccept.length > 0 && <Redirect to={allWorldRoutes.agreements.basePath} />;
  return (
    <Switch>
      <Route path={getPath(allBaseWorldRoutes.agreements.basePath)}>
        <AgreementsRoutes />
      </Route>
      {agreementRedirect}
      <Route path={getPath(allBaseWorldRoutes.batteryEssentials.battery)}>
        <BatteryRoutes />
      </Route>
      <Route path={getPath(allBaseWorldRoutes.batteryWarranty)}>
        <BatteryWarrantyRoutes />
      </Route>
      <Route path={getPath(allBaseWorldRoutes.bulkActions)}>
        <BulkActionsRoute />
      </Route>
      <Route path={getPath(allBaseWorldRoutes.core.overview)}>
        <CoreRoutes product={productNames.coreV2} />
      </Route>
      <Route path={getPath(allBaseWorldRoutes.settings.basePath)}>
        <SettingsRoutes />
      </Route>
      <Route path={getPath(allBaseWorldRoutes.userManagement)}>
        <UserManagementRoute />
      </Route>
      <Route path={getPath(allBaseWorldRoutes.homeLocationManagement)}>
        <HomeLocationManagementRoute />
      </Route>
      <Route path={getPath(allBaseWorldRoutes.zones.list)}>
        <ZoneManagementRoute />
      </Route>
      <Route path={getPath(allBaseWorldRoutes.enrollment)}>
        <EnrollmentRoute />
      </Route>
      <Route>
        <StandardPage title={t('PRODUCT_SELECTION')}><LandingContainer /></StandardPage>
      </Route>
    </Switch>
  );
};

export const WorldIdRoute = () => {
  const { state } = useLocation<{ worlds: World[] }>();
  const fetchWorlds = useCallback(() => getWorlds(), []);
  const { error: worldsError, data: worlds, loading } = useNonWorldRequest<World[]>(fetchWorlds, { onError: false, initialLoading: true });
  useErrorHandler(worldsError);

  const value = useMemo(() => state?.worlds || worlds, [state, worlds]);

  if (loading) { return <AppLoading />; }

  return (
    <WorldWrapper>
      <WorldsProvider worlds={value} >
        <WorldRoutes/>
      </WorldsProvider>
    </WorldWrapper>
  );
};
