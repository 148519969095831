
import * as React from 'react';
import { useState, useRef, useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { isEqual } from 'lodash';

import { DevicesSettings } from './devicesSettings';
import { PreventNavigation } from '../../../../components/navigation/preventNavigation';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';
import { FormControls } from '../../../../components/forms/formControls';
import { LocationInfoDialog, LocationInfoMode } from './locationInfoDialog';

interface SubmitResult {
  success: boolean,
  message: string
}

interface IProps {
  worldSettings: WorldSettings,
  saveData: (data: { worldSettings: WorldSettings }) => Promise<void>
}

export function DevicesSettingsPage(props: IProps) {
  const { t } = useTranslation('settings');
  const [worldSettings, setWorldSettings] = useState<WorldSettings>(props.worldSettings);
  const [submitResult, setSubmitResult] = useState<SubmitResult>(undefined);
  const [locationInfoDialogMode, setLocationInfoDialogMode] = useState<LocationInfoMode>(null);
  const formRef = useRef(null);

  const areButtonsDisabled = useMemo(() => {
    const hasSettingsChanged = !isEqual(worldSettings, props.worldSettings);
    const hasError = submitResult?.success === false;
    return !hasSettingsChanged || hasError;
  }, [worldSettings, props.worldSettings, submitResult?.success]);

  function onDataChanged(data: WorldSettings) {
    setWorldSettings(data);
  }

  function handleCancel() {
    setWorldSettings(props.worldSettings);
  }

  async function handleSubmit() {
    try {
      setSubmitResult(undefined);
      await props.saveData({ worldSettings: worldSettings });
      setSubmitResult({ message: t('SAVE_SUCCESS'), success: true });
    } catch (e) {
      setSubmitResult({ message: t('SAVE_FAIL'), success: false });
    }
  }

  const locationInfoChanged: LocationInfoMode = React.useMemo(() => {
    const androidIsTurningOn = (worldSettings.androidLocationCollectionEnabled === true
      && props.worldSettings.androidLocationCollectionEnabled !== true);

    const windowsIsTurningOn = (worldSettings.windowsLocationCollectionEnabled === true
      && props.worldSettings.windowsLocationCollectionEnabled !== true);

    const androidIsTurningOff = (worldSettings.androidLocationCollectionEnabled === false
      && props.worldSettings.androidLocationCollectionEnabled !== false);

    const windowsIsTurningOff = (worldSettings.windowsLocationCollectionEnabled === false
      && props.worldSettings.windowsLocationCollectionEnabled !== false);

    if (androidIsTurningOn || windowsIsTurningOn) {
      return 'turningOn';
    }
    if (androidIsTurningOff || windowsIsTurningOff) {
      return 'turningOff';
    }
    return null;
  }, [worldSettings, props.worldSettings]);

  const onSubmit = locationInfoChanged !== null ?
    () => setLocationInfoDialogMode(locationInfoChanged)
    :
    handleSubmit;

  return <>
    <div className='threshold-settings-composition devices' data-id="devices-settings">
      <form ref={formRef}>
        <PreventNavigation enabled={!areButtonsDisabled} />
        <DevicesSettings
          onDataChanged={onDataChanged}
          worldSettings={worldSettings}
        />
        <FormControls
          submitResult={{
            message: submitResult?.message,
            status: submitResult ? submitResult.success ? 'success' : 'error' : null
          }}
          cancelDisabled={areButtonsDisabled}
          submitDisabled={areButtonsDisabled}
          onCancel={handleCancel}
          onSubmit={onSubmit}
          mode='save'
        />
      </form>
    </div>
    <LocationInfoDialog
      handleCancel={() => {
        handleCancel();
        setLocationInfoDialogMode(null);
      }}
      handleSave={async () => {
        await handleSubmit();
        setLocationInfoDialogMode(null);
      }}
      mode={locationInfoDialogMode}
    />
  </>;
}
