import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';

import { CheckBox, ICheckboxValue } from '../../../components/controls/checkbox';

export interface IProps {
  checked: boolean,
  name: string,
  onChange: (checked: boolean) => void
}

const StyledAlertsActive = styled.div`
  display: flex;
`;

const StyledText = styled.div`
  padding-right: 0.5rem;
`;

export function AlertActive(props: IProps) {
  const { t } = useTranslation('settings');

  return (
    <StyledAlertsActive className={`thresholds-settings-${props.name}`} data-cy={`checkbox-${props.name}`}>
      <StyledText className={'checkbox-text'}>{t('ALERT_ACTIVE')}</StyledText>
      <CheckBox
        className="fancy-checkbox"
        name={props.name}
        checked={props.checked}
        onChange={(event: ICheckboxValue) => props.onChange(event.checked)}
      />
    </StyledAlertsActive>
  );
}
