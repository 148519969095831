import { chunk, isEqual, union } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { TTypedTFunction, useTranslation } from '@lib/useTypedTranslation';
import { getHomeLocationOptionMapper, Option } from '../../../components/controls/optionPickerLib';
import { FormControls } from '../../../components/forms/formControls';
import Spinner from '../../../components/loading/loadingSpinner';
import { SearchableList, SearchType } from '../../../components/searchableList/searchableList';
import { Tab } from '../../../components/tabs/tab';
import { TabPanel } from '../../../components/tabs/tab-panel';
import { RequestInitWithRetry } from '../../../lib/request';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { useWait } from '../../../lib/wait';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { getGroups, getHomeLocations, getZones, IGroups, IHomeLocation, IZone } from '../../../services/config/config';
import { Zone, ZoneData } from '../../../services/zones/zones';
import { componentColour } from '../../app/globalStyle';
import { DisabledOptions, useZoneReducer, TabIdWithItems } from './zoneLib';

const ZONE_SUMMARY_TABINDEX = 3;

const StyledPage = styled.div`
  width: 100%;
`;

const StyledSpinner = styled(Spinner)`
  text-align: center;
`;

const StyledHeader = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
  margin-left: 3rem;
  line-height: 3.5rem;
`;

const StyledTitle = styled.div`
  font-weight: 400;
  margin-left: 10px;
  margin-left: 3rem;
  line-height: 2rem;
`;

const StyledNameError = styled.span`
  color: #c03b3b;
  margin-left: 0.3rem;
`;

const StyledTabs = styled.div`
  font-weight: 400;
  height: 25rem;
  margin-left: 3rem;
  margin-right: 2rem;
`;

const TableRow = styled.div`
  display: table-row; 
`;

const Item = styled.div`
  display: table-cell;
  width: 11rem;
`;

const StyledTabScroll = styled.div`
  height: 15rem;
  overflow-y: auto;
`;

const StyledTabOptions = styled.div`
  height: 100%;
  padding: 10px 15px;
`;

const StyledInput = styled.input`
  border: 1px solid ${componentColour.darkGrey};
  background-color: ${componentColour.white};
  width: 9rem;
  height: 2rem;
  font-size: 0.875rem;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 3px 0 0 3px;
  line-height: 30px;
  vertical-align: top;
  color: #333;
  padding-left: 0.875rem;
  padding-right: 0;
  &::placeholder {
    color: #939495;
  }
  &:focus {
    &::placeholder {
      opacity: 0;
    }
    outline-color: #0072af;
  }
`;
StyledInput.displayName = 'StyledInput';

const StyledClearSpan = styled.span`
  color: #7b95bc;
  cursor: pointer;
  position: absolute;
  left: 12.75rem;
  top: 14.7rem;
`;

StyledClearSpan.displayName = 'StyledClearSpan';

const StyledZone = styled.div`
  height: 100%;
`;

const StyledBody = styled.div`
  margin-top: 1.125rem;
`;

const StyledZoneText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
  font-weight: 400;
  font-size: 0.875rem;
`;

const Groups1Selection = styled.div`
  margin-left: 2rem;
  line-height: 1.5rem;
  margin-top: 2rem;
`;

const Groups2Selection = styled.div`
  margin-left: 4rem;
  line-height: 1.5rem;
  margin-top: 1.125rem;
`;

const HomeLocationSelection = styled.div`
  margin-left: 6rem;
  line-height: 1.5rem;
  margin-top: 1.125rem;
`;

const FormattedSelection = styled.div`
  margin-left: 2rem;
  font-weight: 600;
`;

const StyledTab = styled(Tab)`
  font-weight: 400;
  line-height: 2rem;
`;

const TabHeader = styled.div`
  margin-bottom: 1rem;
  font-weight: 600;
`;

const CheckBoxParent = styled.div<{ disabled: boolean }>`
  height: fit-content;
  margin-left: 5px;
  position: relative;
  width: 10rem;
  box-sizing: border-box;
  display: flex;
  margin-bottom: 5px;

  &:focus-within label::before {
    outline: auto;
  }

  ${({ disabled }) => disabled ? `
    & label::before {
        border: none;
      }
  ` : `
    & label::before {
        border: auto;
      }
  `}
`;

const CheckBoxLabel = styled.label`
  margin-bottom: 0;
  display: flex;
  align-items: center;

  & span {
    width: 7rem;
    position: relative;
    bottom: 1px;
  }

  &::before {
    align-self: flex-start;
    margin-right: 15px !important;
  }
`;
CheckBoxLabel.displayName = 'CheckBoxLabel';

const CheckBoxInput = styled.input`
  align-self: center;

  ${({ disabled }) => disabled ? `
    &:hover {
        cursor: default;
      }
  ` : `
    &:hover {
        cursor: pointer;
      }
  `}
`;
CheckBoxInput.displayName = 'CheckBoxInput';

const Footer = styled.div`
  border-top: 1px solid #c2d1e0;
  font-size: 0.875rem;
  font-family: 'Open Sans', sans-serif;
  z-index: 1000;
  padding: 0.75rem 1.875rem;
`;

export type BuildZoneData = Partial<ZoneData>;

type tabProperties = {
  title: string | React.ReactElement,
  id: string,
  items: string[]
};

export interface IProps<RequestParams> {
  handleClose?: () => void,
  handleSave?: () => void,
  saveRequest: (params: RequestParams) => Promise<{ success: boolean }>,
  getRequestParams: (zone: Partial<ZoneData>) => RequestParams,
  header: string,
  canEdit?: boolean,
  initialZone?: Zone,
  getSaveDisabled?: (zone: Partial<ZoneData>) => boolean
}

export function BuildZone<RequestParams>(props: IProps<RequestParams>) {
  const { t } = useTranslation(['zones', 'translation']);
  const { wait } = useWait();
  const [zoneName, setZoneName] = useState('');
  const [groups, setGroups] = useState<string[]>([]);
  const [homeLocations, setHomeLocations] = useState<Option<IHomeLocation>[]>([]);
  const [nameValidationError, setNameValidationError] = useState<boolean>(false);
  const mapHomeLocationsToOptions = useMemo(() => getHomeLocationOptionMapper(t), [t]);
  const homeLocationNames = homeLocations.map((item: Option<IHomeLocation>) => item.name);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const history = useHistory();
  const basePath = useWorldRoutes().zones;
  const [tabIndex, setTabIndex] = useState<number>(0);

  const onDataFetched = useCallback((data: [IGroups, IHomeLocation[], IZone[]]) => {
    const groupNames = (data[0]?.groups?.nodes || []).map(g => g.name.trim().toLowerCase()); // groups should be trimmed and lowercase, but we'll double check here
    const availableHomeLocations = data[1];
    setHomeLocations(mapHomeLocationsToOptions(availableHomeLocations));
    const availableGroups = union(groupNames, ...data[2].map(item => item.groups1), ...data[2].map(item => item.groups2));
    setGroups(availableGroups);
  }, [mapHomeLocationsToOptions]);

  const dataFetcher = useCallback(() => {
    return (options: RequestInitWithRetry) => {
      return Promise.all([
        getGroups()(options),
        getHomeLocations()(options),
        getZones()(options)
      ]);
    };
  }, []);

  const {
    loading: optionsLoading,
  } = useWorldRequest(dataFetcher, { onSuccess: onDataFetched });

  const [
    { selectedOptions, disabledGroupsOptions, searchOptions },
    { onOptionsSelected, onSearch, onUpdate }
  ] = useZoneReducer({ name: zoneName, groups1: groups, groups2: groups, homeLocations: homeLocationNames });

  useEffect(() => {
    if (props.canEdit) {
      onUpdate(props.initialZone);
    }
  }, [onUpdate, props.canEdit, props.initialZone]);

  function getSelectedOptionsForTab(id: string) {
    let optionsList: string[] = [];
    Object.entries(selectedOptions[id]).forEach(([key, checked]) => {
      if (checked) {
        optionsList.push(key);
      }
    });
    return optionsList.filter(i => i);
  }

  const groups1Selection = getSelectedOptionsForTab('groups1');
  const groups2Selection = getSelectedOptionsForTab('groups2');
  const homeLocationsSelection = getSelectedOptionsForTab('homelocations');
  const totalSelection = groups1Selection.length + groups2Selection.length + homeLocationsSelection.length;

  useEffect(() => {
    if ((totalSelection) > 20) {
      setError(t('OPTION_SELECTION_ERROR', { ns: 'zones' }));
    } else {
      setError('');
    }
  }, [t, totalSelection]);

  async function handleSave() {
    setError('');
    setIsSubmitting(true);
    try {
      const homeLocationIds = homeLocations.map((homeLocation) => {
        return (homeLocationsSelection.includes(homeLocation.name)) ? homeLocation.value.id : '';
      }).filter((e) => String(e).trim());
      const zone: Omit<ZoneData, 'id'> = {
        name: zoneName,
        groups1: groups1Selection,
        groups2: groups2Selection,
        homeLocationIds: homeLocationIds
      };
      const updateZone: Omit<ZoneData, 'name'> = {
        id: props.initialZone?.id,
        groups1: groups1Selection,
        groups2: groups2Selection,
        homeLocationIds: homeLocationIds
      };
      const params = props.canEdit ? props.getRequestParams(updateZone) : props.getRequestParams(zone);
      await props.saveRequest(params);
      await wait(5000);
      setIsSubmitting(false);
      history.push(`${basePath.list}`);
    } catch (err) {
      setIsSubmitting(false);
      if (err.message.includes('Zone already exists')) {
        setError(t('NAME_EXISTS_ERROR', { ns: 'zones' }));
      } else if (err.message.includes('Zone not found')) {
        setError(t('ZONE_NOT_FOUND', { ns: 'zones' }));
      } else {
        setError(t('SAVE_FAILED_ERROR', { ns: 'zones' }));
      }
    }
  }

  function handleCancel() {
    history.push(`${basePath.list}`);
  }

  const formatSelection = (selection: string[][]) => {
    return (
      selection.map((option, index) => {
        return (<Item key={index} >
          <TableRow>{option[0]}</TableRow>
          <TableRow>{option[1]}</TableRow>
          <TableRow>{option[2]}</TableRow>
        </Item>);
      })
    );
  };

  const zoneDescription = () => {
    return (
      <StyledZone>{totalSelection === 0 ? <StyledZoneText>{t('ZONE_TAB_DESCRIPTION', { ns: 'zones' })}</StyledZoneText> : <div>
        {(groups1Selection.length !== 0) && <Groups1Selection>
          {t('ZONE_OPTIONS_TEXT_GROUPS1', { ns: 'zones' })}
          <FormattedSelection>{formatSelection(chunk(groups1Selection, 3))}</FormattedSelection>
        </Groups1Selection>}
        {(groups2Selection.length !== 0) && <Groups2Selection>
          {(groups1Selection.length !== 0) ? t('ZONE_OPTIONS_TEXT_GROUPS2', { ns: 'zones' }) : t('ZONE_OPTIONS_TEXT_GROUPS1', { ns: 'zones' })}
          <FormattedSelection>{formatSelection(chunk(groups2Selection, 3))}</FormattedSelection>
        </Groups2Selection>}
        {(homeLocationsSelection.length !== 0) && <HomeLocationSelection>
          {(groups1Selection.length !== 0) || (groups2Selection.length !== 0) ? t('ZONE_OPTIONS_TEXT_HOMELOCATIONS', { ns: 'zones' }) : t('ZONE_OPTIONS_TEXT_HOMELOCATIONS_without_groups', { ns: 'zones' })}
          <FormattedSelection>{formatSelection(chunk(homeLocationsSelection, 3))}</FormattedSelection>
        </HomeLocationSelection>}
      </div>}
      </StyledZone>
    );
  };

  const removeUncheckedOptions = disabledGroupsOptions.filter((el: any, _: any, disabledGroupsOptions: DisabledOptions[]) => {
    return disabledGroupsOptions.filter(el2 => isEqual(el2, el)).length === 1;
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>, options: TabIdWithItems) {
    // update state with the checked state using event.name and event.checked
    onOptionsSelected(event.currentTarget.name, event.currentTarget.checked, options);
  }

  function handleSearchChange(text: string, tabId: string) {
    onSearch(text, tabId);
  }

  const tabs: tabProperties[] = [
    {
      title: t('GROUP_other', { ns: 'translation' }),
      id: 'groups1',
      items: groups
    },
    {
      title: t('AND_GROUPS', { ns: 'zones' }),
      id: 'groups2',
      items: groups
    },
    {
      title: t('AND_HOMELOCATIONS', { ns: 'zones' }),
      id: 'homelocations',
      items: homeLocationNames
    }
  ];

  const getItem = (item: string, options: TabIdWithItems) => {
    const disabled = removeUncheckedOptions.some((option: DisabledOptions) => option[options.id] === item);
    return <CheckBoxParent key={`${item}`} disabled={disabled}>
      <CheckBoxInput
        id={`checbox-id-${item}`}
        className="fancy-checkbox"
        type="checkbox"
        name={`${item}`}
        onChange={(event) => handleChange(event, options)}
        checked={selectedOptions[options.id][item] === true}
        disabled={disabled}
      />
      <CheckBoxLabel htmlFor={`checbox-id-${item}`}><span>{item}</span></CheckBoxLabel>
    </CheckBoxParent>;
  };

  const getHeaderForTab = (tabId: string, t: TTypedTFunction<'zones'>): string => {
    if (tabId === 'groups1') {
      return t('GROUPS1_TAB_HEADER');
    } else if (tabId === 'groups2') {
      return t('GROUPS2_TAB_HEADER');
    } else {
      return t('HOMELOCATIONS_TAB_HEADER');
    }
  };

  const tabOptions = (options: TabIdWithItems) => {
    return (<>
      <StyledTabScroll>
        <TabHeader>{getHeaderForTab(options.id, t)}</TabHeader>
        <SearchableList
          items={options.items.map(i => ({ element: <>{getItem(i, options)}</>, text: i }))}
          searchType={SearchType.startOfText}
          onSearch={((searchText: string) => handleSearchChange(searchText, options.id))}
          initialSearch={searchOptions[options.id]}
          dataId={options.id}
        />
      </StyledTabScroll>
    </>
    );
  };

  function handleReviewZone() {
    setTabIndex(ZONE_SUMMARY_TABINDEX);
  }

  const zoneTabs = () => {
    return (
      <div className="tabs">
        <TabPanel defaultTabIndex={tabIndex} tabAlignment='start'>
          {tabs.map((tab) => {
            return (
              <StyledTab title={tab.title} id={tab.id} key={tab.id} onClick={(index: number) => { setTabIndex(index); }}>
                {optionsLoading ? <StyledSpinner /> : <StyledTabOptions>{tabOptions({ id: tab.id, items: tab.items })}</StyledTabOptions>}
              </StyledTab>
            );
          })}
          <StyledTab title={t('ZONE_SUMMARY', { ns: 'zones' })} id={'zone'} onClick={(index: number) => { setTabIndex(index); }}>
            <div>{zoneDescription()}</div>
          </StyledTab>
        </TabPanel>
      </div>
    );
  };

  const handleZoneNameChange = (event: { target: HTMLInputElement }) => {
    const { value } = event.target;
    validateZoneName(value);
    setZoneName(value);
  };

  const validateZoneName = (name: string) => {
    const regex = new RegExp('^([a-zA-Z0-9]+)$');
    const match = name.match(regex);
    if ((!match || match.length === 0) && name) {
      setNameValidationError(true);
    } else {
      setNameValidationError(false);
    }
  };

  return (<StyledPage>
    <div>
      <StyledHeader>{props.header}</StyledHeader>
      <StyledTitle className="zone-title">{`${t('ZONE_NAME', { ns: 'zones' })}: `}
        {props.canEdit ? <span data-id="zone-name-value">{props.initialZone?.name}</span> : <>
          <input
            aria-label="zone name input"
            type="text"
            className={`new-zone-title`}
            value={zoneName}
            onChange={handleZoneNameChange}
          />
          {nameValidationError && <StyledNameError>{t('NAME_VALIDATION_ERROR', { ns: 'zones' })}</StyledNameError>}
        </>}
      </StyledTitle>
    </div>
    <StyledBody>
      <StyledTabs className='tabs'>{zoneTabs()}</StyledTabs>
      <Footer>
        <FormControls
          submitDisabled={tabIndex === ZONE_SUMMARY_TABINDEX && (!(zoneName || props.initialZone?.name) || (totalSelection === 0) || nameValidationError || Boolean(error))}
          submitResult={{
            status: isSubmitting ? 'loading' : error ? 'error' : null,
            message: error || null
          }}
          onCancel={handleCancel}
          onSubmit={tabIndex === ZONE_SUMMARY_TABINDEX ? handleSave : handleReviewZone}
          mode={tabIndex === ZONE_SUMMARY_TABINDEX ? 'save' : 'review'}
        />
      </Footer>
    </StyledBody>
  </StyledPage>);
}
