import { useTranslation } from "@lib/useTypedTranslation";
import styled from "styled-components";
import { formatDateLastUpdated, formatValue, getFormatNumberToLocale } from "../../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../../components/data-table/types";
import { useLanguageAndLocaleContext } from "../../../../context/languageAndLocale";
import { Tooltip } from "../../../../components/tooltip/tooltip";
import { Link } from "react-router-dom";
import { IProcess } from "../../../../services/core/processes";
import { useWorldRoutes } from "../../../../routes/parts/allWorldRoutes";

const TooltipText = styled.div`
  font-weight: 600;
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-normal);
  max-width: 14rem;
`;

const StyledTooltip = styled(Tooltip)`
  color: #7490b9;
  margin-left: 0.375rem;
`;

const formatLinkToProcess = (linkText: string, rowData: IProcess, path: string) => {
  return <Link to={`${path}?processName=${encodeURIComponent(rowData.processName)}&productName=${encodeURIComponent(rowData.productName)}`}>{formatValue(linkText)}</Link>;
};


export const useProcessesListColumns = () => {
  const { t } = useTranslation('coreProcesses');
  const routes = useWorldRoutes();
  const { locale } = useLanguageAndLocaleContext();

  const linkProcessor = (linkText: string, rowData: IProcess) => {
    return formatLinkToProcess(linkText, rowData, routes.core.processPerformance);
  };

  const activeDevicesTooltip = <>
    {t('ACTIVE_DEVICES')}
    <StyledTooltip popoverPortalSelector={'.core-processes-list'} placement={'bottom'} popperContent={<TooltipText data-id="active-devices-tooltip">{t('ACTIVE_DEVICES_TOOLTIP_TEXT')}</TooltipText>}>
      <i aria-label='about active devices' className="fa fa-question-circle" />
    </StyledTooltip>
  </>;

  const usedCountTitleAndTooltip = <>
    {t('USED_DEVICES')}
    <StyledTooltip popoverPortalSelector={'.core-processes-list'} placement={'bottom'} popperContent={<TooltipText data-id="used-count-tooltip">{t('USED_COUNT_TOOLTIP_TEXT')}</TooltipText>}>
      <i aria-label='about used count' className="fa fa-question-circle" />
    </StyledTooltip>
  </>;
  const columns: ITableColumn[] = [
    { id: 'processName', title: t('PROCESS_NAME'), dataPath: 'processName', sortable: true, processor: linkProcessor, width: '11rem' },
    { id: 'productName', title: t('PRODUCT_NAME'), dataPath: 'productName', sortable: true, processor: linkProcessor, width: '10rem' },
    { id: 'activeCount', title: activeDevicesTooltip, dataPath: 'activeCount', sortable: true, processor: getFormatNumberToLocale(locale), width: '10rem' },
    { id: 'usedCount', title: usedCountTitleAndTooltip, dataPath: 'usedCount', sortable: true, processor: getFormatNumberToLocale(locale), width: '10rem' },
    { id: 'versionCount', title: t('VERSIONS'), dataPath: 'versionCount', sortable: true, processor: getFormatNumberToLocale(locale), width: '6rem' },
    { id: 'latestProductVersion', title: t('LATEST_PRODUCT_VERSION'), dataPath: 'latestProductVersion', sortable: true, processor: formatValue, width: '12rem' },
    { id: 'latestFileVersion', title: t('LATEST_FILE_VERSION'), dataPath: 'latestFileVersion', sortable: true, processor: formatValue, width: '10rem' },
    { id: 'lastUsed', title: t('LAST_USED'), dataPath: 'lastUsed', sortable: true, processor: formatDateLastUpdated, width: '7rem' }
  ];

  return columns;
};
