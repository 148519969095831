import React, { useContext, useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { get, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import { DetailsCard } from '../../../../components/details/detailsCard';
import { IDeviceDetailsCore, getDeviceDetails, Sim } from '../../../../services/core/devices';
import { formatValue, formatFirstCapitalValue, formatBytes, formatTitle, DEFAULT_VALUE } from '../../../../components/data-table/dataFormatters';
import { Loading } from '../../../../components/loading/loading';
import { DeviceInfoContext } from '../index';
import { PercentageBar } from '../../../../components/percentageBar/percentageBar';
import { AlertsPanel } from '../../../../components/alertsPanel/alerts-panel';
import { encode } from '../../../../lib/base64';
import { useCurrentUserContext } from '../../../../context/currentUser';
import { ProblemIcon } from '../../../../components/icons/problemIcon';
import { EnhancedWarningIcon } from '../../../../components/icons/enhancedWarningIcon';
import { GoodIcon } from '../../../../components/icons/goodIcon';
import { Theme } from '../../../app/themes';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { useWorldRoutes } from '../../../../routes/parts/allWorldRoutes';
import { useProductsContext } from '../../../../context/products';
import { productNames } from '../../../app/productsWrapper';

function SystemCard({ device }: { device: IDeviceDetailsCore }) {
  const { t } = useTranslation();
  const timeZoneName = device?.timeZone?.name;
  const osBuildVersion = device?.operatingSystem?.buildVersion;
  const firmwareVersion = device?.firmware?.version;
  const totalRAM = device?.totalPhysicalMemory;
  const cpuDescription = device?.processor?.name;
  const biosVersion = device?.bios?.version;
  const data = [
    { title: formatTitle(t('SERIAL_NUMBER')), value: formatValue(device.serialNumber) },
    { title: formatTitle(t('ASSET_TAG')), value: formatValue(device.assetTag) },
    { title: formatTitle(t('IMEI')), value: formatValue(device.imei) },
    { title: formatTitle(t('MANUFACTURER')), value: formatValue(device.manufacturer) },
    { title: formatTitle(t('MODEL')), value: formatValue(device.model) },
    { title: formatTitle(t('OPERATING_SYSTEM')), value: formatFirstCapitalValue(device.platformType) },
    { title: formatTitle(t('BUILD_VERSION')), value: formatValue(osBuildVersion) },
    { title: formatTitle(t('TIMEZONE')), value: formatValue(timeZoneName) }
  ];

  if (device.platformType === 'android') {
    data.splice(7, 0, { title: formatTitle(t('FIRMWARE_VERSION')), value: formatValue(firmwareVersion) });
  }

  if (device.platformType === 'windows') {
    data.splice(3, 0, { title: formatTitle(t('COMPUTER_NAME')), value: formatValue(device.deviceName) });
    data.splice(8, 0, { title: formatTitle(t('TOTAL_RAM')), value: formatValue(formatBytes(totalRAM, t)) });
    data.splice(9, 0, { title: formatTitle(t('CPU')), value: formatValue(cpuDescription) });
    data.splice(8, 0, { title: formatTitle(t('BIOS_VERSION')), value: formatValue(biosVersion) });
  }

  return <DetailsCard title={t('SYSTEM')} data={data} />;
}

function ElemezCard({ device, path }: { device: IDeviceDetailsCore, path: string }) {
  const { t } = useTranslation();
  const { homeLocationId } = useCurrentUserContext();
  const dataObjectElemez = [
    { title: formatTitle(t('ENROLLMENT_DATE')), value: device.firstSeen ? moment.utc(parseInt(device.firstSeen)).format('LLL') : formatValue(undefined) },
    { title: formatTitle(t('ELEMEZ_VERSION')), value: formatValue(device.clientVersion) },
    { title: formatTitle(t('EXTENSION_VERSION')), value: formatValue(device.extensionVersion) },
    { title: formatTitle(t('GROUPS')), value: dependencies.getGroupsFilteredDevicesLink(device.group, path) },
    { title: formatTitle(t('HOME_LOCATION')), value: dependencies.getHomeLocationFilteredDevicesLink(device.homeLocation, path, homeLocationId) }
  ];

  return <DetailsCard title={"Elemez"} data={dataObjectElemez} />;
}

function getWifiUpdatedTime(updated: string, timeZone?: any, offset?: number) {
  const day = moment.utc(parseInt(updated)).add(offset ?? 0, 'minutes').format('MMMM Do YYYY HH:mm:ss');
  return timeZone ? `${day} (${timeZone})` : `${day} (UTC)`;
}

function WifiCard({ device }: { device: { accessPoint: { ssid?: string, bssid?: string, updated?: string }, timeZone?: { name: string } } }) {
  const { t } = useTranslation();
  const { timeZoneOffset } = useContext(DeviceInfoContext);
  const wifi = device?.accessPoint || {};
  const time = getWifiUpdatedTime(wifi.updated, device?.timeZone?.name, timeZoneOffset);
  return <DetailsCard title={t('LAST_KNOWN_WIFI')} data={[
    { title: formatTitle(t('UPDATED')), value: wifi.updated ? time : formatValue(undefined) },
    { title: formatTitle(t('NAME_SSID')), value: formatValue(wifi.ssid) },
    { title: formatTitle(t('ADDRESS_BSSID')), value: formatValue(wifi.bssid) }
  ]} />;
}

export function getBatteryIdLink(battery: any, enabled: boolean, linkToBatteries: string) {
  if (enabled && !isEmpty(battery)) {
    return <Link data-id="link-to-battery-essentials" to={`${linkToBatteries}?id=${encodeURIComponent(encode(battery.id))}`}>{formatValue(battery.serialNumber)}</Link>;
  } else {
    return formatValue(battery.serialNumber);
  }
}

export function getGroupsFilteredDevicesLink(groups: string[] | null, path: string): JSX.Element[] | string {
  if (isEmpty(groups)) {
    return DEFAULT_VALUE;
  }
  return groups.map((group, index) => {
    return <React.Fragment key={group}>
      <Link data-id={`link-to-devices-${group}`} to={`${path}?groups=${encodeURIComponent(group)}`}>{group}</Link>
      {index !== groups.length - 1 && ', '}
    </React.Fragment>;
  });
}

export function getHomeLocationFilteredDevicesLink(homeLocation: { id: string, name: string } | null, path: string, userHomeLocationId: string): JSX.Element | string {
  if (!homeLocation) {
    return DEFAULT_VALUE;
  }
  if (userHomeLocationId) {
    return homeLocation.name;
  }
  const base64HomeLocationName = encode(homeLocation.name);
  const base64HomeLocationId = encode(homeLocation.id);
  return <Link data-id={`link-to-devices-${homeLocation.id}`} to={`${path}?homeLocations=${encodeURIComponent(`${base64HomeLocationId}|${base64HomeLocationName}`)}`}>{homeLocation.name}</Link>;
}

export const dependencies = {
  getHomeLocationFilteredDevicesLink,
  getGroupsFilteredDevicesLink
};

const WarningIcon = styled(EnhancedWarningIcon)`
  line-height: 0.75em;
`;
WarningIcon.displayName = 'WarningIcon';

export const StyledAlertsPanel = styled(AlertsPanel)`
 & .enhanced-warning-icon {
  background-size: 2rem 0.9rem;
 }
`;

StyledAlertsPanel.displayName = 'AlertsPanel';

function getBatterySerial(status: string, serialNumber: string | JSX.Element) {
  const icons: { [status: string]: JSX.Element } = {
    red: <ProblemIcon />,
    yellow: <WarningIcon />,
    green: <GoodIcon />
  };

  if (status && icons[status]) {
    return <div>{serialNumber}{icons[status]}</div>;
  }
  return <div>{serialNumber}<GoodIcon /></div>;
}

function BatteriesCard({ smartBatteries, linkToBatteries }: { smartBatteries: { id?: string, batteryStatus?: string, serialNumber?: string }[], linkToBatteries: string }) {
  const { t } = useTranslation();
  const productsData = useProductsContext();
  if (!smartBatteries?.length) {
    return <DetailsCard
      title={t('BATTERY_FIRST_CAPITAL')}
      data={[
        { title: t('NO_SMART_BATTERY_DETECTED'), value: undefined }
      ]} />;
  }

  const data = smartBatteries.map((battery, index) => {
    const title = smartBatteries.length === 1 ? t('SERIAL') : t('SERIAL', { batteryNumber: index + 1, context: 'list' });
    const enabled = productsData[productNames.batteryEssentials].enabled;
    const serialNumber = getBatteryIdLink(battery, enabled, linkToBatteries);
    return {
      title: formatTitle(title),
      value: getBatterySerial(battery.batteryStatus, serialNumber)
    };
  });

  return <DetailsCard
    title={t('BATTERY_FIRST_CAPITAL')}
    data={data} />;
}

const StoragePercentageBar = styled(PercentageBar)`
  position: relative;
  top: 2px;
  width: 85%;
`;
StoragePercentageBar.displayName = 'StoragePercentageBar';

function StorageCard({ device }: { device: IDeviceDetailsCore }) {
  const { t } = useTranslation();
  const percentageUsed = getPercentageUsed(get(device, 'storage[0].used'), get(device, 'storage[0].total'));
  const title = percentageUsed ? <StoragePercentageBar
    threshold={80}
    colourBelowThreshold="#00A774"
    colourAboveThreshold="#DD3F5B"
    percentage={percentageUsed}
  /> : null;
  return <DetailsCard title={t('STORAGE')} data={[
    {
      title,
      value: percentageUsed ? `${formatValue(percentageUsed.toString())}% ${t('USED')}` : formatValue(undefined)
    },
    {
      title: formatTitle(t('AVAILABLE')),
      value: formatValue(formatBytes(get(device, 'storage[0].available'), t))
    },
    {
      title: formatTitle(t('USED')),
      value: formatValue(formatBytes(get(device, 'storage[0].used'), t))
    },
    {
      title: formatTitle(t('TOTAL_SPACE')),
      value: formatValue(formatBytes(get(device, 'storage[0].total'), t))
    }
  ]} />;
}

function SimCard({ sims }: { sims: Sim[] }) {
  const { t } = useTranslation();
  if (!sims || !sims.length) {
    return <div><DetailsCard title={t('SIM')} data={[
      { title: t('NO_SIM_DETECTED'), value: undefined },
    ]} /></div>;
  }

  const data = sims.map((sim, index) => {
    const serialNumberTitle = sims.length === 1 ? t('SIM_SERIAL') : t('SIM_SERIAL', { simNumber: index + 1, context: 'list' });
    const operator = sim.operatorName === 'unknown' ? DEFAULT_VALUE : sim.operatorName || DEFAULT_VALUE;
    const operatorCode = sim.operatorCode === 'unknown' ? '' : sim.operatorCode ? ` (${sim.operatorCode})` : '';
    const operatorTitle = sims.length === 1 ? t('OPERATOR') : t('OPERATOR', { simNumber: index + 1, context: 'list' });
    return [
      { title: formatTitle(serialNumberTitle), value: formatValue(sim.serialNumber) },
      { title: formatTitle(operatorTitle), value: formatValue(operator + operatorCode) }
    ];
  });

  return <DetailsCard title={t('SIM')} data={data.flat()} />;
}

function getPercentageUsed(used: number, total: number): number {
  return Math.round(used / total * 100);
}

const DetailsPanel = styled.div`
  border-top: 3px solid #edf1f3;
  margin: 0 0rem 2.5rem 2rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.25rem;
  justify-content: space-evenly;

  i {
      margin-left: 0.5rem;
  }

  a {
    color:#0077CC;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 2rem;
  min-width: 20rem;

  @media (max-width: 1800px) {
    margin: 1rem;
  }
`;

// istanbul ignore next
const ColumnDivider = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-child)::after {
    content: '';
    display: block;
    ${({ theme }: { theme: Theme }) => `border-right: 2px solid ${theme.sectionBorders.colours.light};`}

    margin: 3.8rem 0.5rem 3rem 0.5rem;
    height: 80%;

    @media (max-width: 1500px) {
      content: none;
    }
  }
`;

const LoadingScreen = styled(Loading)`
  height: 40rem;
`;
LoadingScreen.displayName = 'LoadingScreen';

export function DeviceOverview() {
  const { id: deviceId } = useContext(DeviceInfoContext);
  const { batteryEssentials: batteryRoutes, core: coreRoutes } = useWorldRoutes();
  const linkToBatteries = batteryRoutes.battery;
  const linkToDevicesList = coreRoutes.devicesList;

  const deviceDetailsFetcher = useCallback(() => getDeviceDetails(deviceId), [deviceId]);
  const { data: device, loading } = useWorldRequest(deviceDetailsFetcher, { initialData: undefined, initialLoading: true });
  return (
    <LoadingScreen isLoading={loading} transparentOverlay={false}>
      <StyledAlertsPanel data={device} withTitle={true} />
      <DetailsPanel data-id="device-overview-details-panel">
        <Column>
          <div data-id="system-card">
            <SystemCard device={device} />
          </div>
        </Column>
        <ColumnDivider />
        <Column>
          <div data-id="elemez-card">
            <ElemezCard device={device} path={linkToDevicesList} />
          </div>
          <div data-id="wifi-card">
            <WifiCard device={device} />
          </div>
        </Column>
        <ColumnDivider />
        <Column>
          <div data-id="batteries-card">
            <BatteriesCard smartBatteries={device?.smartBatteries} linkToBatteries={linkToBatteries} />
          </div>
          <div data-id="storage-card">
            <StorageCard device={device} />
          </div>
          <div data-id="sim-card">
            <SimCard sims={device?.sims} />
          </div>
        </Column>
        <ColumnDivider />
      </DetailsPanel>
    </LoadingScreen>
  );
}
