import { createGlobalStyle } from 'styled-components';

/*
The code within the 'stylelint-module' comments is evaluated as a standalone JS module
in order to pass the custom property names to the linter.

N.B.
Global style variables referenced as css variables must be defined within this block,
and must not refer to imported values.
*/

export enum FontWeights {
  normal = 'normal',
  semiBold ='semiBold',
  bold = 'bold'
}

/* stylelint-module */
export const fontWeight = {
  normal: '400',
  semiBold: '600',
  bold: '800'
} as const;

export const componentColour = {
  darkText: '#3a5769',
  blue: '#0072af',
  hoverBlue: '#004c89',
  lightBlue: '#0080a9',
  hoverLightBlue: ' #006790',
  green: '#32a478',
  hoverGreen: '#198b5f',
  red: '#c03b3b',
  hoverRed: ' #a72222',
  grey: '#d7e2eb',
  darkGrey: '#c2d1e0',
  lightGrey: ' #f3f7fb',
  white: '#fff'
} as const;


// define css variables here - we use a style object so that the keys can be ingested by the stylelint plugin
export const customProperties = {
  '--font-weight-normal': fontWeight.normal,
  '--font-weight-semi-bold': fontWeight.semiBold,
  '--font-weight-bold': fontWeight.bold,
  '--color-component-text-dark': componentColour.darkText,
  '--color-component-blue': componentColour.blue,
  '--color-component-blue-hover': componentColour.hoverBlue,
  '--color-component-light-blue': componentColour.lightBlue,
  '--color-component-light-blue-hover': componentColour.hoverLightBlue,
  '--color-component-green': componentColour.green,
  '--color-component-green-hover': componentColour.hoverGreen,
  '--color-component-red': componentColour.red,
  '--color-component-red-hover': componentColour.hoverRed,
  '--color-component-grey': componentColour.grey,
  '--color-component-dark-grey': componentColour.darkGrey,
  '--color-component-light-grey': componentColour.lightGrey,
  '--color-component-white': componentColour.white,
  "--legend-stop-1": "#000",
  "--legend-stop-2": "#000",
  "--legend-stop-3": "#000",
  "--legend-stop-4": "#000",
} as const;
/* stylelint-module */

export const CssVariables = createGlobalStyle`
  :root {
    ${customProperties}
  }
`;
