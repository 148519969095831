import { FormatFunction } from 'i18next';
import { isArray, isString } from 'lodash';

import { conjunctionList } from '../../lib/conjunctionList';
import { ITranslationKeys } from './keys';
import { languagesMap } from './utils';
import * as self from './formatters';

export const localiseNumber = (value: number | string, language: string) => {
  const number = Number(value);
  if (Number.isNaN(number)) {
    return value;
  }
  const locale = languagesMap.get(language)?.locale || 'en-gb';
  return number.toLocaleString(locale);
};


export const getFormat = (resources: { [lang: string]: ITranslationKeys }): FormatFunction => {
  return (value: any, format: string, lng: string) => {
    if (format === 'lowercase') {
      if (!isString(value)) {
        throw new Error('lowercase format must receive a string');
      }
      return value.toLowerCase();
    }
    if (format === 'list') {
      if (!isArray(value)) {
        throw new Error('list format must receive an array of strings');
      }
      const and = resources[lng].translation.AND;
      return conjunctionList(value, and);
    }
    if (format === 'localiseNumber') {
      return self.localiseNumber(value, lng);
    }
    return value;
  };
};
