import React, { useMemo } from 'react';
import { CurrentWorldProvider } from '../../context/currentWorld';
import { CurrentWorld } from '../../services/worlds/worlds';

interface Props {
  currentWorld: CurrentWorld,
  children: React.ReactNode
}

export function CurrentWorldWrapper(props: Props) {
  const { children, currentWorld } = props;
  const value = useMemo(() => {
    return {
      ...currentWorld
    };
  }, [currentWorld]);

  return (
    <CurrentWorldProvider value={value}>
      {children}
    </CurrentWorldProvider>
  );
}
