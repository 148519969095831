import React, { useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import Card from '../../../components/card/card';
import { NoSelectionOverlay } from '../../../components/card/noSelectionOverlay';
import { InitialTableState, useTableReducer } from '../../../components/data-table/lib';
import { List } from '../../../components/list/list';
import { Loading } from '../../../components/loading/loading';
import { StackedOverview } from '../../../components/overview/stackedOverview';
import { useUserSettingsContext } from '../../../context/userSettings';
import { getUserTimeZone } from '../../../lib/timeZone';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { getAnomalyDetails } from '../../../services/core/anomalyDetails';
import { getAnomalyDeviceCount } from '../../../services/core/anomalyDeviceCount';
import { useAnomalyDevicesListColumns } from './compositions/useAnomalyDevicesListColumns';
import { getAnomalyDevicesList, exportAnomalyDevicesListAction, IDevicesData, IDevicesListData } from '../../../services/core/anomalyDevicesList';
import { Theme } from '../../app/themes';
import moment from 'moment';
import { useWorldAction } from '../../../lib/useWorldAction';
import { ExportButton } from '../../../components/controls/exportButton';
import useNormalizedHeaderButtons from '../../../components/controls/normalizedHeaderButtons';

interface IProps {
  anomalyId: string,
  utc: string
}

const Header = styled.div`
  padding-top: 1.5rem;
  padding-left: 2.5rem;
  padding-bottom: 0.75rem;
  font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.twelvePixels};
`;

const Title = styled.h1`
  font-weight: ${({ theme }: { theme: Theme}) => theme.font.weights.bold};
  font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.twentyPixels};
`;

const Breadcrumb = styled.div`
  margin-top: 5px;
  color: #707070;
`;

const LinkToAnomalies = styled(Link)`
  padding-right: 0.5em;
  color: ${({ theme }: { theme: Theme}) => theme.links.color};
  &:not(:first-child) {
    padding-left: 0.5rem;
  }
`;

const AnomalyName = styled.div`
  display: inline-block;
  padding-left: 0.5em;
`;

const OverviewLoading = styled(Loading)`
  height: 200px;
`;

const Section = styled.div`
  padding-bottom: 0.625rem;
  margin: 1.5rem 2.5rem 0;
  position: relative;
`;

const NoDataOverlay = styled(NoSelectionOverlay)`
  padding: 0;
`;

const Overview = styled(StackedOverview)`
  margin: 1.5rem 0;
  justify-content: flex-start;
  & div:nth-of-type(1) {
    justify-content: normal;
  }
  & dd {
    padding-left: 0.2rem;
  }
`;

const OverviewSeparator = styled.div`
  margin-top: 1.5rem;
  border-bottom: 3px solid #edf1f3;
`;

const tableName = 'anomalyDevicesList';
const defaultPageSize = 20;

export function AnomalyDevices(props: IProps) {
  const { anomalyId, utc } = props;
  const timeZone = getUserTimeZone();
  const { t } = useTranslation('anomaly');
  const routes = useWorldRoutes();
  const { tablePageSizes } = useUserSettingsContext();
  const columns = useAnomalyDevicesListColumns();

  const anomalyDetailsFetcher = useCallback(() => {
    return getAnomalyDetails({ anomalyId: anomalyId });
  }, [anomalyId]);

  const anomalyDeviceCountFetcher = useCallback(() => {
    return getAnomalyDeviceCount({ anomalyId: anomalyId, timeZone: timeZone });
  }, [anomalyId, timeZone]);

  const anomalyDevicesListTranslationItems = {
    statusText: {
      key: 'DEVICE',
      ns: 'translation'
    },
    tableTitle: {
      text: t('ANOMALY_DEVICES_TABLE_TITLE', { date: moment.utc(parseInt(utc)).format('ll') })
    }
  };

  function getDownloadTitle(description: string) {
    return t('ANOMALY_DEVICES_DOWNLOAD_FILE_NAME', { description });
  }

  const initialTableState: InitialTableState = {
    initialRows: 6,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 6, direction: 'desc', field: 'updated' }
  };

  const { data: anomaly, loading } = useWorldRequest(anomalyDetailsFetcher, { initialLoading: true });
  const { data: anomalyResults, loading: resultsLoading } = useWorldRequest(anomalyDeviceCountFetcher, { initialLoading: true });
  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<IDevicesData>(tableName, initialTableState);
  const [
    { offset, limit, sort, search }
  ] = [tableReducerProperties, tableReducerFunctions];
  const deviceListFetcher = useCallback(() => {
    const anomalyAtDate = anomalyResults.filter(a => a.date.valueOf() === utc);
    return getAnomalyDevicesList({ limit, offset, sort: { field: sort.field, order: sort.direction }, search, deviceBitSet: anomalyAtDate?.[0]?.deviceBitSet });
  }, [offset, limit, sort, search, anomalyResults, utc]);

  const exportAnomalyDevicesList = useWorldAction(exportAnomalyDevicesListAction);
  const onExportClicked = async (): Promise<string> => {
    const anomalyAtDate = anomalyResults.filter(a => a.date.valueOf() === utc);
    return exportAnomalyDevicesList({ limit: 10000, offset: 0, sort: { field: sort.field, order: sort.direction }, search, deviceBitSet: anomalyAtDate?.[0]?.deviceBitSet });
  };
  const additionalHeaderButtons = useNormalizedHeaderButtons({
    export: {
      exportFunction: onExportClicked,
      filename: `${getDownloadTitle(anomaly?.description).toLowerCase()}.csv`,
    }
  });
  return (
    <div data-id="core-anomaly-devices">
      <Card noPadding={true}>
        <Header>
          <Title>
            {t('ANOMALY_DEVICES_TITLE')}
          </Title>
          <Breadcrumb>
            <LinkToAnomalies to={routes.core.anomalies} aria-label="Link to anomalies list">{t('ANOMALIES')}</LinkToAnomalies>
            <i className="fas fa-chevron-right" />
            <LinkToAnomalies to={`${routes.core.anomalyHistory}?anomalyId=${anomalyId}`} aria-label="Link to anomaly">{t('HISTORY')}</LinkToAnomalies>
            <i className="fas fa-chevron-right" />
            <AnomalyName>{t('ANOMALY_DEVICES_LIST_TITLE')}</AnomalyName>
          </Breadcrumb>
        </Header>
        <OverviewLoading isLoading={loading || resultsLoading} transparentOverlay={false}>
          <Section>
            <NoDataOverlay show={!anomaly || !anomalyResults} noSelectionText={t('NO_DATA_AVAILABLE', { ns: 'translation' })} />
            <Overview columns={[
              [
                { value: <b>{anomaly?.description}</b> }
              ]
            ]}
            />
            <OverviewSeparator/>
          </Section>
          <Section>
            <List<IDevicesData, IDevicesListData, 'totalCount', 'list', 'id'>
              dataId='core_anomaly_devices_list'
              dataTableEnabled={true}
              customHeader={false}
              countDataPath='totalCount'
              listDataPath='list'
              hasCheckboxes={false}
              hasFilters={false}
              hasSearch
              translationItems={anomalyDevicesListTranslationItems}
              columns={columns}
              fetcher={deviceListFetcher}
              tableReducerFunctions={tableReducerFunctions}
              tableReducerProperties={tableReducerProperties}
              useRequestHook={useWorldRequest}
              options={{ rowIdDataPath: 'id' }}
              additionalHeaderButtons={additionalHeaderButtons}
            />
          </Section>
        </OverviewLoading>
      </Card>
    </div>
  );
}
