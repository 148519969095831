import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { createUser, CreateUserParameters } from '../../services/users/users';
import { nullHomeLocation, nullZone } from '../../components/controls/optionPickerLib';
import { BuildUser, BuildUserData } from './compositions/buildUser';

export interface IProps {
  showPopup: boolean,
  handleClose: () => void,
  onUserCreation: () => void
}

export function UserCreation(props: IProps) {
  const ns = 'userManagement';
  const { t } = useTranslation(ns);

  const getSaveDisabled = (state: BuildUserData) => {
    const noWorldsSelected = state.worldIds?.length === 0;
    return !state.email || noWorldsSelected;
  };

  return (
    <BuildUser<CreateUserParameters>
      showPopup={props.showPopup}
      handleClose={props.handleClose}
      getSaveDisabled={getSaveDisabled}
      handleSave={props.onUserCreation}
      canEditEmail={true}
      saveRequest={createUser}
      initialUser={{
        email: '',
        role: 'viewer',
        homeLocation: nullHomeLocation,
        group: null,
        worldIds: null,
        zone: nullZone
      }}
      getRequestParams={(user) => ({
        email: user.email,
        role: user.role,
        homeLocationId: user.homeLocationId,
        worldIds: user.worldIds,
        group: user.group,
        zoneId: user.zoneId
      })}
      header={t('ADD_USER')}
      subheader={t('ADD_USER_DESCRIPTION')}
      modalClass='users_list-user_creation'
    />
  );
}
