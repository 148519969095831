import React, { useCallback, useState } from 'react';
import { at } from 'lodash';
import { useTranslation } from '@lib/useTypedTranslation';
import { InitialTableState, useTableReducer } from '../../components/data-table/lib';
import { exportHomeLocationsListAction, getHomeLocationsList, HomeLocationData, IHomeLocationsList } from '../../services/homeLocations/homeLocations';
import { CreateHomeLocation } from './createHomeLocation';
import { DeleteHomeLocations } from './deleteHomeLocations';
import { UpdateHomeLocation } from './updateHomeLocation';
import { useWorldRequest } from '../../lib/useWorldRequest';
import { useWorldAction } from '../../lib/useWorldAction';
import { useUserSettingsContext } from '../../context/userSettings';
import { useHomeLocationsListColumns } from './useHomeLocationsListColumns';
import { List } from '../../components/list/list';
import useNormalizedHeaderButtons from '../../components/controls/normalizedHeaderButtons';

export const tableName = 'homeLocationsList';
export const defaultPageSize = 20;

export function HomeLocationsList() {
  const { tablePageSizes } = useUserSettingsContext();
  const columns = useHomeLocationsListColumns();

  const [showCreateHomeLocation, setShowCreateHomeLocation] = useState(false);
  const [showDeleteHomeLocation, setShowDeleteHomeLocation] = useState(false);
  const [showUpdateHomeLocation, setShowUpdateHomeLocation] = useState(false);

  const exportHomeLocationsList = useWorldAction(exportHomeLocationsListAction);

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 1, direction: 'desc', field: 'name' }
  };

  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<HomeLocationData>(tableName, initialTableState);
  const [
    { offset, limit, sort, search, data, checkedRows },
    { onDataChange, onLoading }
  ] = [tableReducerProperties, tableReducerFunctions];

  const homeLocationsListFetcher = useCallback(() => {
    return getHomeLocationsList({ limit, offset, sort: { field: sort.field, order: sort.direction }, search, });
  }, [limit, offset, search, sort.direction, sort.field]);

  const onExportClicked = async (): Promise<string> => {
    return exportHomeLocationsList({ limit: 10000, offset: 0, sort: { field: sort.field, order: sort.direction }, search });
  };

  const handleHomeLocationUpdate = (updated: HomeLocationData) => {
    const newData = [...data];
    newData[checkedRows[0]] = updated;
    onDataChange(newData);
  };

  const homeLocationsListTranslationItems = {
    statusText: {
      key: 'HOME_LOCATION',
      ns: 'translation'
    },
    tableTitle: {
      key: 'HOME_LOCATION_other',
      ns: 'translation'
    }
  };

  const additionalHeaderButtons = useNormalizedHeaderButtons({
    checkedRows,
    create: {
      dataId: "create-home-location-button",
      onClick: () => { setShowCreateHomeLocation(true); }
    },
    delete: {
      dataId: "delete-home-location-button",
      onClick: () => { setShowDeleteHomeLocation(true); }
    },
    edit: {
      dataId: "update-home-location-button",
      onClick: () => { setShowUpdateHomeLocation(true); }
    },
    export: {
      exportFunction: onExportClicked,
      filename: 'homeLocationsList.csv'
    }
  });

  return (
    <div>
      {showCreateHomeLocation && <CreateHomeLocation
        showPopup={showCreateHomeLocation}
        onClose={() => setShowCreateHomeLocation(false)}
        onSave={onLoading}
      />}
      {showDeleteHomeLocation && <DeleteHomeLocations
        showPopup={showDeleteHomeLocation}
        homeLocations={at(data, checkedRows)}
        onClose={() => setShowDeleteHomeLocation(false)}
        onDelete={onLoading}
      />}
      {showUpdateHomeLocation && <UpdateHomeLocation
        showPopup={showUpdateHomeLocation}
        onClose={() => setShowUpdateHomeLocation(false)}
        onSave={handleHomeLocationUpdate}
        homeLocation={data[checkedRows[0]]}
      />}
      <List<HomeLocationData, IHomeLocationsList, 'totalCount', 'list', 'id'>
        dataId='home-locations-list'
        dataTableEnabled={true}
        customHeader={false}
        countDataPath='totalCount'
        listDataPath='list'
        hasCheckboxes={true}
        hasFilters={false}
        hasSearch
        translationItems={homeLocationsListTranslationItems}
        columns={columns}
        fetcher={homeLocationsListFetcher}
        tableReducerFunctions={tableReducerFunctions}
        tableReducerProperties={tableReducerProperties}
        useRequestHook={useWorldRequest}
        options={{ rowIdDataPath: 'id' }}
        additionalHeaderButtons={additionalHeaderButtons}
      />
    </div>
  );
}
