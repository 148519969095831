import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink as Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { LanguageSelector } from '../i18n/languageSelector';
import { Theme } from '../../containers/app/themes';
import { useCurrentUserContext } from '../../context/currentUser';
import { useCurrentWorldContext } from '../../context/currentWorld';
import { useNonWorldRoutes } from '../../routes';
import { useWorldsContext } from '../../context/worlds';
import { BaseLogo } from '../logo-styles/baseLogo';
import { useIsExternalUser } from './externalUser';

import { UserDropdown } from './userDropdown';
import { applyHeaderStyles } from '../controls/dropdownMenu';
import { WhatsNew } from '../whatsNew/whatsNew';

export type Props = {
  worldAware: boolean,
  title?: string,
  children?: React.ReactNode,
  logoLink?: string
};

const StyledGreetingsDropDown = styled.span`
  display: inline-block;
  z-index: 1001;

  max-width: 850px;
  @media (max-width: 1800px) {
    max-width: 760px;
  };
  @media (max-width: 1650px) {
    max-width: 600px;
  };
  @media (max-width: 1300px) {
    max-width: 500px;
  };
`;

StyledGreetingsDropDown.displayName = 'GreetingsDropdown';

const StyledHeader = styled.div`
  ${({ theme }: { theme: Theme }) => applyHeaderStyles(theme)}
  height: 50px;
  line-height: 50px;
  text-align: center;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 84%, 0.6);
  display: flex;
  justify-content: space-between;
`;

StyledHeader.displayName = 'ElemezHeader';

const StyledLogoContainer = styled(Link)`
  z-index: 5;
  max-width: 300px;
  @media (max-width: 1300px) {
    z-index: 10;
  }
`;

StyledLogoContainer.displayName = 'LogoContainer';

const StyledLogo = styled(BaseLogo)`
  vertical-align: middle;
  width: 150px;
  height: 26px;
  @media (max-width: 400px) {
    width: 125px;
  };
`;

StyledLogo.displayName = 'Logo';

const StyledTitleContainer = styled.div`
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: 1.143em;
  font-weight: 600;
`;

const StyleLeftContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 1001;
`;

StyledTitleContainer.displayName = 'TitleContainer';

const StyledTitleText = styled.div`
  max-width: 300px;
  display: inline-block;

  @media (max-width: 650px) {
    display: none;
  };
`;

StyledTitleText.displayName = 'TitleText';

const StyledWorldNameIcon = styled.i`
  ${({ theme }: { theme: Theme }) => `background: ${theme.headers.contrastBackground};`};
  margin-right: 8px;
  line-height: 28px;
  min-width: 28px;
  border-radius: 50%;
`;

StyledWorldNameIcon.displayName = 'WorldNameIcon';

const StyledWorldName = styled.div`
  margin-right: 20px;

  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => applyHeaderStyles(theme)}
  display: inline-block;
  white-space: nowrap;

 
  @media (max-width: 1100px) {
    max-width: 155px;
    margin-right: 10px;
  };
  @media (max-width: 850px) {
    max-width: 35px;
  };
`;

StyledWorldName.displayName = 'WorldName';

const StyledWorldLink = styled(Link)`
  margin-right: 15px;
  padding-right: 15px;
  padding-left: 12px;

  display: inline-block;
  ${({ theme }: { theme: Theme }) => applyHeaderStyles(theme)}

  white-space: nowrap;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;

  text-decoration: none;
  cursor: pointer;

  &:hover {
    ${({ theme }: { theme: Theme }) => `
      text-decoration: none;
      background: ${theme.headers.contrastBackground};
      border-color: ${theme.headers.background};
      color: ${theme.headers.text}
    `}
  };
  @media (max-width: 1100px) {
    max-width: 155px;
    margin-right: 10px;
  };
  @media (max-width: 850px) {
    max-width: 49px;
  };
`;

StyledWorldLink.displayName = "WorldLink";

export const Greetings = (props: Props) => {
  const { title, children, worldAware } = props;
  const currentUser = useCurrentUserContext();
  const { worldId } = useParams<{ worldId: string }>();
  const linkToLanding = worldId ? `/${worldId}/` : `/`;
  const { isExternalUser } = useIsExternalUser(currentUser.email);
  const WorldNameContainer = () => {
    const { name } = useCurrentWorldContext();
    const routes = useNonWorldRoutes();
    const { worlds } = useWorldsContext();

    const worldTextWithIcon = <><StyledWorldNameIcon className="fas fa-globe-americas faicon" />
      {name}
    </>;

    return (worlds.length !== 1 ?
      <StyledWorldLink data-id="link-to-world-selection" to={routes.worldSelection} className="worldName oemHeaderBg">
        {worldTextWithIcon}
      </StyledWorldLink>
      : <StyledWorldName data-id="world-name" className="worldName oemHeaderBg">
        {worldTextWithIcon}
      </StyledWorldName>);
  };

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        {isExternalUser && <script defer data-domain={window.location.hostname} src="https://plausible.io/js/plausible.hash.js"></script>}
      </Helmet>
      <StyledHeader id="elemez-header" data-id='greetings-page-header'>
        <StyleLeftContainer data-id="left-container">
          <StyledLogoContainer to={props.logoLink || linkToLanding} data-id='brand-logo'>
            <StyledLogo />
          </StyledLogoContainer>
          {worldAware && <WorldNameContainer />}
        </StyleLeftContainer>
        <StyledTitleContainer data-id="title-container">
          <StyledTitleText data-id="title-text" >
            {title}
          </StyledTitleText>
        </StyledTitleContainer>
        <StyledGreetingsDropDown data-id="greetings-dropdown">
          {(currentUser.isAdministrator || currentUser.isManager) && worldAware && <WhatsNew/> }
          {worldAware && <LanguageSelector />}
          <UserDropdown worldAware={worldAware} />
        </StyledGreetingsDropDown>
      </StyledHeader>
      <div className="body">
        {children}
      </div>
    </div>
  );
};
