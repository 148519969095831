import { AnyKindOfDictionary, Many, PartialObject, flatten, pickBy } from 'lodash';

/**
 * A shallow implementation of lodash omit
 *
 * Omit is due to be removed from lodash due to performance concerns
 * see: https://github.com/lodash/lodash/issues/2930
 *
 * This function allows top level keys to be omitted,
 * but does not support the nested property path lookup of lodash omit.
 *
 * The type overloads from lodash omit have been adapted, as the typing here is
 * quite complex, but important to avoid compiler complaints.
 *
 * Example usage to get the omitted keys reflected in return type:
 *   const obj = {a: 1, b: 2, c: 3}
 *   const partialObj = omitShallow<typeof obj, 'b' | 'c'>(obj, 'b', 'c')
 *   partialObj.b -> compiler error
 *   partialObj.c -> compiler error
 */
export function omitShallow<T extends AnyKindOfDictionary>(
  object: T,
  ...keys: Array<Many<keyof T>>
): T;
export function omitShallow<T extends object, K extends keyof T>(
  object: T,
  ...keys: Array<Many<K>>
): Omit<T, K>;
export function omitShallow<T extends object>(
  object: T,
  ...keys: Array<Many<keyof T>>
): PartialObject<T> {
  const flatKeys = flatten(keys);
  return pickBy(object, (_, key) => !flatKeys.includes(key as keyof T));
}
