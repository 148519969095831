import * as React from "react";

export const useErrorHandler = (hookError?: any) => {

  React.useEffect(() => {
    if (hookError) {
      throw (hookError);
    }
  }, [hookError]);

  return React.useCallback((paramError?: any) => {
    if (paramError) {
      throw (paramError);
    }
  }, []);
};
