import * as React from "react";

import './checkbox.css';

//generic checkbox react component
//does not support disabled state
//does not support detached mode (updated and synced directly by props value)

export interface ICheckboxValue {
  name: string,
  value?: string,
  label?: React.ReactNode,
  checked: boolean
}

export type CheckboxOnChange = (state: ICheckboxValue) => void;

export interface ICheckboxProps extends ICheckboxValue {
  className?: string,
  onChange?: CheckboxOnChange,
  icon?: string
}

export class CheckBox extends React.Component<ICheckboxProps> {
  public constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  public onChange(event: any) {
    if (this.props.onChange) {
      this.props.onChange({
        name: this.props.name,
        value: this.props.value,
        checked: event.target.checked
      });
    }
  }

  public render() {
    const nameSlug = this.props.name.replace(/\s/g, '-');
    const key = `id_checkbox_${nameSlug}_${this.props.value?.replace(/\s/g, '-') || ''}`;
    return (
      <span className="checkbox" onClick={(event: React.MouseEvent<HTMLSpanElement>) => event.stopPropagation()}>
        <input
          data-cy={`checkbox-id--${nameSlug}`}
          data-id={`checkbox-id-${this.props.name}`}
          id={key}
          className={this.props.className}
          type="checkbox"
          name={this.props.name}
          aria-label={!(this.props.label || this.props.value) ? this.props.name : undefined}
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.onChange}
        />
        <label htmlFor={key}>
          <span>{this.props.label || this.props.value}{this.props.icon && <i data-id={`${this.props.icon}_icon`} className={this.props.icon} />}</span>
        </label>
      </span>
    );
  }
}
