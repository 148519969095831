import * as React from 'react';

import { MainBody } from '../../layout/body/Body';
import { Greetings } from '../../components/authentication/greetings';
import { SelectorBar, ISelectorBarTab } from '../../layout/body/selectorBar';

interface Props {
  config: {
    selectorBarId?: string,
    selectorBarEnabled: boolean,
    selectorBarValue: string,
    selectorBarTabs: ISelectorBarTab[]
  },
  title?: string,
  children?: React.ReactNode
}

export const SelectorBarPage = (props: Props) => {
  const { title, config, children } = props;
  return (
    <Greetings worldAware={true} title={title}>
      {config.selectorBarEnabled &&
        <SelectorBar id={config.selectorBarId} tabs={config.selectorBarTabs} initialSelection={config.selectorBarValue} />
      }
      <MainBody>
        {children}
      </MainBody>
    </Greetings>
  );
};
