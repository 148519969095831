import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';

import ThresholdSettings from '../thresholdSettings';
import { longDistanceToMetres, metresToLongDistance, getDistanceTranslationContext } from '../../../../lib/distanceConversion';
import { usePrevious } from '../../../../lib/usePrevious';
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export interface Props {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  timeStamp: number,
  onDataChanged: (data: WorldSettings) => void
}

const ns = 'settings' as const;

export function DistanceSettings(props: Props) {
  const { t } = useTranslation(ns);
  const { worldSettings } = useWorldSettingsContext();
  const [warningValue, setWarningValue] = useState<string>(String(metresToLongDistance(props.worldSettings.deviceLocationDistanceYellow, worldSettings.useMetricDistances)));
  const [problemValue, setProblemValue] = useState<string>(String(metresToLongDistance(props.worldSettings.deviceLocationDistanceRed, worldSettings.useMetricDistances)));
  const [alertTimeStamp, setAlertTimeStamp] = useState<number>(props.timeStamp);
  const prevTimeStamp = usePrevious(props.timeStamp);

  if (props.timeStamp > alertTimeStamp) {
    setAlertTimeStamp(props.timeStamp);
  }

  useEffect(() => {
    if (prevTimeStamp && prevTimeStamp !== props.timeStamp) {
      setWarningValue(String(metresToLongDistance(props.worldSettings.deviceLocationDistanceYellow, worldSettings.useMetricDistances)));
      setProblemValue(String(metresToLongDistance(props.worldSettings.deviceLocationDistanceRed, worldSettings.useMetricDistances)));
    }
  }, [worldSettings.useMetricDistances, props.worldSettings, prevTimeStamp, props.timeStamp]);

  const onDataChanged = (data: string, field: 'warning' | 'problem') => {
    if (field === 'warning') {
      setWarningValue(data);
      props.onDataChanged({ ...props.worldSettings, deviceLocationDistanceYellow: longDistanceToMetres(Number(data), worldSettings.useMetricDistances) });
    } else if (field === 'problem') {
      setProblemValue(data);
      props.onDataChanged({ ...props.worldSettings, deviceLocationDistanceRed: longDistanceToMetres(Number(data), worldSettings.useMetricDistances) });
    }
  };

  function onAlertToggled(data: boolean, initialSettings: { warning: string, problem: string }) {
    setAlertTimeStamp(moment.utc().valueOf());
    setWarningValue(initialSettings.warning);
    setProblemValue(initialSettings.problem);
    props.onDataChanged({
      ...props.worldSettings,
      deviceLocationDistanceEnabled: data,
      deviceLocationDistanceYellow: longDistanceToMetres(Number(initialSettings.warning), worldSettings.useMetricDistances),
      deviceLocationDistanceRed: longDistanceToMetres(Number(initialSettings.problem), worldSettings.useMetricDistances)
    });
  }

  const context = getDistanceTranslationContext(worldSettings.useMetricDistances);

  return (
    <ThresholdSettings
      key={alertTimeStamp}
      title={t('DISTANCE_TITLE', { ns })}
      message={t('DISTANCE_MESSAGE', { ns })}
      popoverText={t('DISTANCE_POPOVER', { ns })}
      unit={{ key: 'DISTANCE_UNIT', options: { context } }}
      maxValue={worldSettings.useMetricDistances ? 500 : 310.686}
      className="distance"
      warning={warningValue}
      problem={problemValue}
      dataUpdated={onDataChanged}
      disabled={props.submitInProgress}
      decimalPlaces={3}
      alertEnabled={props.worldSettings.deviceLocationDistanceEnabled}
      alertToggled={onAlertToggled}
    />
  );
}
