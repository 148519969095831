import { useTranslation } from "@lib/useTypedTranslation";

import styled from "styled-components";

import { formatDateDetected, formatDateLastUpdated, formatValue } from "../../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../../components/data-table/types";
import { Tooltip } from "../../../../components/tooltip/tooltip";

const Popover = styled.div`
  max-width: 9rem;
`;

export const useAnomaliesListColumns = () => {
  const { t } = useTranslation('anomaly');
  const getTitle = (key: 'LAST_AFFECTED' |
    'TOTAL_AFFECTED' |
    'HIGHEST_AFFECTED' |
    'OCCURRENCES' |
    'LAST_DETECTED' |
    'FIRST_DETECTED') => <>
    {t(key)}
    <Tooltip popoverPortalSelector={'.anomalies-list'} placement={key === 'LAST_DETECTED' || key === 'FIRST_DETECTED' ? 'left' : 'bottom'} popperContent={<Popover>{t(`${key}_TOOLTIP`)}</Popover>}>
      <i aria-label={t(`${key}_TOOLTIP_LABEL`)} className="info-popover-icon fa fa-question-circle" />
    </Tooltip>
  </>;

  const columns: ITableColumn[] = [
    { id: 'description', title: t('DESCRIPTION'), dataPath: 'description', sortable: false, processor: formatValue, width: '25rem' },
    { id: 'latestAffectedCount', title: getTitle('LAST_AFFECTED'), dataPath: 'latestAffectedCount', sortable: true, processor: formatValue, width: '10rem' },
    { id: 'totalAffectedCount', title: getTitle('TOTAL_AFFECTED'), dataPath: 'totalAffectedCount', sortable: true, processor: formatValue, width: '10rem' },
    { id: 'highestAffectedCount', title: getTitle('HIGHEST_AFFECTED'), dataPath: 'highestAffectedCount', sortable: true, processor: formatValue, width: '11rem' },
    { id: 'occurences', title: getTitle('OCCURRENCES'), dataPath: 'detectedCount', sortable: true, processor: formatValue, width: '10rem' },
    { id: 'lastDetected', title: getTitle('LAST_DETECTED'), dataPath: 'lastDetected', sortable: true, processor: formatDateLastUpdated, width: '12rem' },
    { id: 'firstDetected', title: getTitle('FIRST_DETECTED'), dataPath: 'firstDetected', sortable: true, processor: formatDateDetected, width: '13rem' }
  ];

  return columns;
};
