import * as React from 'react';
import { withTranslation } from 'react-i18next';

import { Popover } from '../../../components/controls/popover';
import { CustomValidatedInput } from '../../../components/customValidatedInput/customValidatedInput';
import { AlertActive } from './alertActive';

import './thresholdSettingsWithPeriod.css';
import './settings.css';
import { TTypedTFunction, WithTranslation } from '@lib/useTypedTranslation';
import { ITranslationKeys } from 'components/i18n/keys';

export interface IProps {
  popoverText: React.ReactNode,
  title: React.ReactNode,
  warning: number,
  problem: number,
  days: number,
  message: string,
  disabled: boolean,
  className: string,
  alertEnabled: boolean,
  dataUpdated: (data: number, field?: string) => void,
  alertToggled: (data: boolean, initialSettings: { warning: number, problem: number, days: number }) => void
}

export interface IState {
  period: number,
  problem: number,
  warning: number
}

const ns = 'settings';

export class ThresholdSettingsWithPeriod extends React.Component<IProps & WithTranslation, IState> {

  public constructor(props: IProps & WithTranslation) {
    super(props);
    const { t } = props;
    this.state = {
      period: props.days,
      problem: props.problem,
      warning: props.warning
    };
    this.checkPeriodValidity = this.checkPeriodValidity.bind(this);
    this.checkWarningValidity = this.checkWarningValidity.bind(this);
    this.checkProblemValidity = this.checkProblemValidity.bind(this);
    this.onAlertToggled = this.onAlertToggled.bind(this);
  }

  public render() {
    const { t } = this.props;

    const warningMessage = this.getMessage(this.props.warning, t);
    const problemMessage = this.getMessage(this.props.problem, t);

    return (
      <div className={this.props.className}>
        <div className="display-thresholds-header">
          <div>
            <h6>{this.props.title}</h6>
            <Popover
              placement="right"
              id={`popoverDisplayThresholds`}
              className="popoverThresholds"
              target={<i className="threshold-icon fa fa-question-circle" />}
              content={<div className="infoPopover">{this.props.popoverText}</div>}
              isInsideTable={false}
            />
          </div>
          <AlertActive checked={this.props.alertEnabled} name={this.props.className} onChange={this.onAlertToggled} />
        </div>
        <div className="display-thresholds-row row">
          <div className="col">
            <div className="display-thresholds-section period">
              <span className="status-header period">{t('PERIOD_IN_DAYS', { ns })}</span>
              <CustomValidatedInput
                value={this.props.days}
                validationRule={this.checkPeriodValidity}
                errorText={t('THRESHOLD_PERIOD_ERROR', { ns, min: '1', max: '30' })}
                disabled={this.props.disabled || !this.props.alertEnabled}
                onChange={(value: number) => this.props.dataUpdated(value, 'days')}
              />
            </div>
            <div className="display-thresholds-section warning">
              <span className="status-header warning"><i className="fa fa-exclamation-triangle circle_orange enhanced-warning-icon" /> {t('THRESHOLD_WARNING', { ns })}</span>
              <CustomValidatedInput
                value={this.props.warning}
                validationRule={this.checkWarningValidity}
                errorText={t('THRESHOLD_WARNING_ERROR', { ns, min: '1', max: '98' })}
                disabled={this.props.disabled || !this.props.alertEnabled}
                onChange={(value: number) => this.props.dataUpdated(value, 'warning')}
              />
              {warningMessage}
            </div>
            <div className="display-thresholds-section problem">
              <span className="status-header problem"><i className="fa fa-times-circle circle_red" /> {t('THRESHOLD_PROBLEM', { ns })}</span>
              <CustomValidatedInput
                value={this.props.problem}
                validationRule={this.checkProblemValidity}
                errorText={t('THRESHOLD_PROBLEM_ERROR', { ns, min: '2', max: '99' })}
                disabled={this.props.disabled || !this.props.alertEnabled}
                onChange={(value: number) => this.props.dataUpdated(value, 'problem')}
              />
              {problemMessage}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getMessage(count: number, t: TTypedTFunction): JSX.Element {
    return <span>{t(this.props.message as keyof ITranslationKeys['settings'], { ns, count })}</span>;
  }

  onAlertToggled(value: boolean) {
    this.props.alertToggled(value, { warning: this.state.warning, problem: this.state.problem, days: this.state.period });
  }

  checkWarningValidity(value: number): boolean {
    if (this.props.warning !== this.state.warning) {
      return (value >= 1) && (value < this.props.problem) && (value <= 98);
    } else return true;
  }

  checkProblemValidity(value: number): boolean {
    if (this.props.problem !== this.state.problem) {
      return (value > this.props.warning) && (value <= 99);
    } else return true;
  }

  checkPeriodValidity(value: number): boolean {
    if (this.props.days !== this.state.period) {
      return (value >= 1) && (value <= 30);
    } else return true;
  }
}

export default withTranslation()(ThresholdSettingsWithPeriod);
