import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { ModalPopup } from '../../components/controls/modalPopup';
import Spinner from '../../components/loading/loadingSpinner';

import './homeLocationChange.css';

interface MoveProgressProps {
  isMoving: boolean,
  handleClose: () => void,
  message: string
}

const ns = 'bulkActions';
export function MoveProgressModal(props: MoveProgressProps) {
  const { t } = useTranslation(ns);
  const statusText = (isMoving: boolean) => {
    return <div className="moving__status">
      {isMoving ? <Spinner dataId="formControlsSpinner" classes="form-controls__saving" text={t('MOVE_STATUS_TEXT', { ns })} /> : <div className='move_success-message'>{props.message}</div>}
    </div>;
  };

  return (<div className='move-progress_modal'>
    <ModalPopup
      show={true}
      handleClose={props.handleClose}
      closeDisabled={props.isMoving}
      header={''}
      children={statusText(props.isMoving)}
      classname="move-home-location__progress-modal"
    ></ModalPopup>
  </div>);
}
