import * as React from "react";
import { useTranslation } from '@lib/useTypedTranslation';

import './closeButton.css';

export function CloseButton(props: { handleClose: () => void, className?: string }): any {
  const { t } = useTranslation();
  return (
    <button type="button" className={`close close-button ${props.className || ''}`} data-id="close-button" aria-label={t('CLOSE')} onClick={props.handleClose}>
      <i aria-hidden className="fas fa-times"></i>
    </button>
  );
}
