import { useEffect, useRef } from 'react';

/** Use a ref to store a value from a previous render */
export const usePrevious = <T extends {}>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
