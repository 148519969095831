
export interface ISortResult {
  field: string,
  order: string
}

export enum VIEW {
  red = 'red',
  orange = 'orange',
  yellow = 'yellow',
  green = 'green',
  all = 'all'
}
