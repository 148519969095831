import * as React from 'react';

import { RequestInitWithRetry } from './request';
import { UseRequestOptions, UseRequestReturn, useRequest } from './useRequest';

/**
 * Makes a non world aware api request via useRequest
 */
export const useNonWorldRequest = <T = any>(
  getFetcher: () => (options: RequestInitWithRetry) => Promise<T>,
  options: UseRequestOptions<T> = {}
): UseRequestReturn<T> => {
  return useRequest(getFetcher, options, false);
};
