import React, { useState, useEffect } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { deleteDevicesAction } from '../../../../services/core/devices';
import { useWait } from '../../../../lib/wait';
import { DeleteDialogue } from '../../../../components/delete/deleteDialogue';
import { useWorldAction } from '../../../../lib/useWorldAction';

export interface IProps {
  showPopup: boolean,
  deviceIds: string[],
  handleClose: () => void,
  onDevicesDeleted: () => void
}

export function DeleteDevices(props: IProps) {
  const { t } = useTranslation('deleteDevices');
  const { showPopup, handleClose, deviceIds } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(false);
  const [checkedLength, setCheckedLength] = useState(null);
  const deleteDevices = useWorldAction(deleteDevicesAction);
  const { wait } = useWait();

  useEffect(() => {
    if (deviceIds.length) {
      setCheckedLength(deviceIds.length);
    }
  }, [deviceIds.length]);

  async function handleDelete() {
    setIsDeleting(true);
    let success = false;
    try {
      await deleteDevices({ ids: props.deviceIds });
      //Max time for deleting devices in es when deleted flag set in dynamodb is 3 seconds so adding wait time before reloading list
      await wait(3000);
      setIsDeleting(false);
      setError(false);
      success = true;
    } catch (err) {
      setIsDeleting(false);
      setError(true);
    } finally {
      props.onDevicesDeleted();
    }
    return success;
  }

  const onClose = () => {
    setIsDeleting(false);
    setError(false);
    handleClose();
  };

  const errorText = t('ERROR_CUSTOM_DELETE', { ns: 'deleteDialogue', item: t('DEVICE_other', { ns: 'translation' }) });

  return (
    <DeleteDialogue
      show={showPopup}
      subheader={t('SUBHEADER', { count: checkedLength })}
      warning={t('MESSAGE', { count: checkedLength })}
      isDeleting={isDeleting}
      error={error && errorText}
      checkboxRequired={checkedLength > 1}
      handleDelete={handleDelete}
      handleClose={onClose}
      count={checkedLength}
    />
  );
}
