import * as React from 'react';
import ETableHeader from '../../components/table/header';
import Header from './header';
import { List, IListCategory } from './list';

import { FilterSelectionControl, IFilterGroup, IFilterResult } from '../filters/filterSelectionControl';
import { find, omit, isEqual, get } from 'lodash';
import { Direction } from '../../components/table/sortableHeader';

interface IProps {
  categories: IListCategory[],
  currentCategoryId: string,
  filterGroups?: IFilterGroup[],
  filterNameI18nKey: string,
  currentFilter?: IFilterResult,
  fields: string[],
  getList: any, //(statusType: string, search: string, limit: number, offset: number, filters: IFilterResult, sort: ISortResult) => Promise<any[]>,
  exportList?: (statusType: string, search: string, limit: number, offset: number, filters: IFilterResult) => Promise<any>,
  getCount: (statusType: string, search: string, filters: IFilterResult) => Promise<number>,
  pageLimit?: number,
  rowComponent: React.ComponentClass<any, any>,
  classes: string[],
  sortableColumns?: string[],
  sortableMapping?: { [k: string]: string },
  centeredColumns: string[],
  sort?: { field: string, direction: Direction },
  enhancedWarningIcon?: boolean
}

interface IState {
  filterGroups?: IFilterGroup[],
  currentFilter: IFilterResult,
  mode: 'List' | 'Filter',
  changeFilterMode?: () => void,
  exportList: () => Promise<string>,
  reloadRequired: boolean,
  search?: string
}

export class ListCard extends React.Component<IProps, IState> {
  public static getDerivedStateFromProps(props: IProps, state: IState) {
    if (!isEqual(props.filterGroups, state.filterGroups)) {
      return Object.assign(state, { filterGroups: props.filterGroups, currentFilter: props.currentFilter });
    }
    return state;
  }

  constructor(props: IProps) {
    super(props);
    this.state = {
      currentFilter: this.props.currentFilter,
      filterGroups: this.props.filterGroups,
      mode: 'List',
      changeFilterMode: this.props.filterGroups ? this.changeFilterMode : undefined,
      exportList: this.exportList,
      reloadRequired: false,
      search: undefined
    };

    this.onFilterRemoved = this.onFilterRemoved.bind(this);
    this.onSearchClicked = this.onSearchClicked.bind(this);
  }

  public render() {
    const list = <List
      fields={this.props.fields}
      pageLimit={this.props.pageLimit}
      getList={this.props.getList}
      getCount={this.props.getCount}
      rowComponent={this.props.rowComponent}
      currentFilter={this.state.currentFilter}
      currentCategory={find(this.props.categories, { id: this.props.currentCategoryId })}
      onFilterNavigate={() => this.setMode("Filter")}
      filterGroups={this.state.filterGroups}
      filterNameI18nKey={this.props.filterNameI18nKey}
      onFilterRemoved={this.onFilterRemoved}
      classes={this.props.classes}
      iconCategories={this.getIconCategories()}
      sortableColumns={this.props.sortableColumns}
      sortableMapping={this.props.sortableMapping}
      centeredColumns={this.props.centeredColumns}
      search={this.state.search}
      sort={this.props.sort}
    />;
    const filter = <FilterSelectionControl onFilterChange={this.onFilterChange} filterGroups={this.state.filterGroups} currentFilter={this.state.currentFilter} />;

    return (
      <div className={"eTable-display-" + this.props.currentCategoryId}>
        <ETableHeader>
          <Header categories={this.props.categories} currentCategoryId={this.props.currentCategoryId} onFilterClicked={this.state.changeFilterMode} onExportClicked={this.state.exportList} onSearchClicked={this.onSearchClicked} />
        </ETableHeader>
        {this.state.mode === 'Filter' && filter}
        <div className='eTable_list' style={{ display: this.state.mode === 'List' ? 'block' : 'none' }}>{list}</div>
      </div>);
  }

  private getIconCategories() {
    return this.props.categories.reduce(
      (icons, category) => {
        icons[category.id] = get(category, 'icon');
        if (icons[category.id] === 'fa fa-exclamation-triangle circle_orange' && this.props.enhancedWarningIcon) {
          icons[category.id] = icons[category.id].concat(' enhanced-warning-icon');
        }
        return icons;
      },
      {} as { [key: string]: string }
    );
  }

  private onSearchClicked(input: string) {
    this.setState({ search: input });
  }

  private onFilterRemoved(id: string) {
    this.setState({ currentFilter: omit(this.state.currentFilter, id), mode: 'List' });
  }

  private setMode = (mode: any): void => {
    this.setState({ mode });
  };

  private changeFilterMode = (): void => {
    if (this.state.mode === 'List') {
      this.setMode('Filter');
    } else {
      this.setMode('List');
    }
  };

  private onFilterChange = (result: IFilterResult): void => {
    this.setState({ currentFilter: result, mode: 'List' });
  };

  private exportList = async () => {
    return await this.props.exportList(this.props.currentCategoryId, this.state.search, 10000, 0, this.state.currentFilter);
  };
}
