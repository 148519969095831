import React from 'react';

import Card from '../../../components/card/card';
import { ProcessesList } from './compositions/processesList';

export function CoreProcesses() {
  return (
    <div data-id="core-processes">
      <Card noPadding={true}>
        <div>
          <div>
            <ProcessesList />
          </div>
        </div>
      </Card>
    </div>
  );
}
