import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { Access } from './parts/access';
import { AccessDeniedToPage } from '../containers/access-denied/accessDeniedToPage';
import { StandardPage } from './parts/standardPage';
import Enrollment from '../containers/enrollment';
import { useFeatureTogglesContext } from '../context/featureToggles';

export function EnrollmentRoute() {
  const { t } = useTranslation();
  const featureToggles = useFeatureTogglesContext();

  if (featureToggles.hideEnrollmentPage) {
    return (
      <StandardPage title={t('ENROLLMENT')}>
        <AccessDeniedToPage />
      </StandardPage>
    );
  }

  return (
    <Access title={t('ENROLLMENT')} role="manager">
      <StandardPage title={t('ENROLLMENT')}>
        <Enrollment />
      </StandardPage>
    </Access>
  );
}
