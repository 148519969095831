import * as React from 'react';
import { toLower } from 'lodash';
import { useTranslation } from '@lib/useTypedTranslation';
import { useState } from 'react';
import moment from 'moment';

import ThresholdSettings from '../thresholdSettings';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export interface IProps {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  timeStamp: number,
  onDataChanged: (data: WorldSettings) => void
}

const ns = 'settings' as const;

export function OutOfContactSettings(props: IProps) {
  const { t } = useTranslation(ns);
  const [alertTimeStamp, setAlertTimeStamp] = useState<number>(props.timeStamp);

  if (props.timeStamp > alertTimeStamp) {
    setAlertTimeStamp(props.timeStamp);
  }

  function onDataChanged(data: number, field?: string) {
    if (field === 'warning') {
      props.onDataChanged({ ...props.worldSettings, deviceUtilisationOutOfContactYellow: data });
    } else if (field === 'problem') {
      props.onDataChanged({ ...props.worldSettings, deviceUtilisationOutOfContactRed: data });
    }
  }

  function onAlertToggled(data: boolean, initialSettings: { warning: number, problem: number }) {
    setAlertTimeStamp(moment.utc().valueOf());
    props.onDataChanged({
      ...props.worldSettings,
      deviceUtilisationOutOfContactEnabled: data,
      deviceUtilisationOutOfContactYellow: initialSettings.warning,
      deviceUtilisationOutOfContactRed: initialSettings.problem
    });
  }

  return (
    <ThresholdSettings
      key={alertTimeStamp}
      title={t('OUT_OF_CONTACT_TITLE', { ns })}
      message={t('OUT_OF_CONTACT_MESSAGE', { ns })}
      popoverText={t('OUT_OF_CONTACT_POPOVER', { ns })}
      unit={{ key: 'OUT_OF_CONTACT_UNIT' }}
      maxValue={120}
      className="outOfContact"
      warning={props.worldSettings.deviceUtilisationOutOfContactYellow}
      problem={props.worldSettings.deviceUtilisationOutOfContactRed}
      dataUpdated={onDataChanged}
      disabled={props.submitInProgress}
      alertEnabled={props.worldSettings.deviceUtilisationOutOfContactEnabled}
      alertToggled={onAlertToggled}
    />
  );
}
