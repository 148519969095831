import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import classNames from 'classnames';

import { getActiveEventFilters } from './events/edit-events';
import { DeviceEventFilters, deviceEvents } from './events/deviceEventsTypes';
import { sortDeviceEventFilters } from './events/deviceEvents';

import './events-shown.css';
import { ITranslationKeys } from 'components/i18n/keys';

interface IProps {
  eventFilters: DeviceEventFilters
}

export function EventsShown(props: IProps) {
  const { t } = useTranslation('performance');
  const ns = 'editEvents';

  const appliedEventNames = getActiveEventFilters(props.eventFilters)
    .sort(sortDeviceEventFilters)
    .map((filter) => deviceEvents[filter].displayName);
  const hasEvents = Boolean(appliedEventNames.length);

  function getEvents(eventNames: string[]) {
    if (eventNames.length === 0) {
      return t('NONE');
    } else if (eventNames.length === Object.keys(props.eventFilters).length) {
      return t('ALL_EVENTS');
    } else {
      return eventNames.map(n => t(n as keyof ITranslationKeys['editEvents'], { ns })).join(', ');
    }
  }
  const events = getEvents(appliedEventNames);

  const listBaseClass = 'events-shown__list';
  const listClasses = classNames(listBaseClass, { [`${listBaseClass}--none`]: !hasEvents });

  return (
    <div className="events-shown">
      <span className="events-shown__title">{t('EVENTS_SHOWN')}: </span>
      <span className={listClasses}>
        {events}
      </span>
    </div>
  );
}
