import React from 'react';
import { BrandProvider } from '../../context/brand';
import { getCurrentBrand } from '../../lib/brands';

interface Props {
  children: React.ReactNode
}

export function BrandWrapper(props: Props) {
  const { children } = props;
  const brand = getCurrentBrand();

  return (
    <BrandProvider brand={brand}>
      {children}
    </BrandProvider>
  );
}
