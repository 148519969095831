import { ValueAxis, AxisLabel } from "@amcharts/amcharts4/charts";
import { isNumber } from "lodash";
import { DataUsage } from "../services/core/application";
import { NumberFormatter } from "@amcharts/amcharts4/core";

export const drawYAxis = (data: DataUsage[] | null, yAxis: ValueAxis, enabledSeries?: ('wifi' | 'mobile')[]) => {
  if (!data) {
    return;
  }
  yAxis.dummyData?.ranges?.forEach((range: any) => yAxis.axisRanges.removeValue(range));
  const columnTotals: number[] = [];
  data.forEach((dataPoint: any) => {
    let columnTotal = 0;
    const allSeries = enabledSeries || ['wifi', 'mobile'];
    allSeries.forEach((series, i) => {
      const dataPointTotal = dataPoint[series] as unknown as number | null;
      if (isNumber(dataPointTotal)) {
        columnTotal += dataPointTotal;
      }
      i === allSeries.length - 1 && columnTotals.push(columnTotal);
    });
  });

  const maxValue = Math.max(...columnTotals);
  yAxis.numberFormatter.numberFormat = '#b';
  yAxis.min = 0;
  let maxYaxis;

  const oneKilobyte = 1024;
  const oneMegabyte = 1048576;
  const oneGigabyte = 1073741824;
  const oneTerabyte = 1099511627776;
  const maxes = [oneKilobyte, oneMegabyte, oneGigabyte, oneTerabyte].map((baseBytes: number) => {
    return [
      baseBytes,
      baseBytes * 2,
      baseBytes * 5,
      baseBytes * 10,
      baseBytes * 50,
      baseBytes * 100,
      baseBytes * 200,
      baseBytes * 500
    ];
  }).flat();
  for (let i = 0; i <= maxes.length; i++) {
    if (maxValue <= maxes[i]) {
      maxYaxis = maxes[i];
      yAxis.max = maxes[i];
      break;
    }
  }
  function createLabelAndGridLine(value: number) {
    const range = yAxis.axisRanges.create();
    range.value = value;
    range.label = new AxisLabel();
    range.label.dy = -18;
    range.label.text = "{value}";
    range.label.visible = true;
    range.label.numberFormatter = new NumberFormatter();
    range.label.numberFormatter.numberFormat = '#b';
    yAxis.renderer.minGridDistance = 1;
    yAxis.dummyData?.ranges ? yAxis.dummyData?.ranges.push(range) : yAxis.dummyData = {
      ranges: [range]
    };
  }

  /* if number of bytes exceeds those coded for, let amcharts do the scaling */
  if (maxYaxis) {
    yAxis.renderer.labels.template.disabled = true;
    yAxis.renderer.grid.template.disabled = true;

    createLabelAndGridLine(0);
    createLabelAndGridLine(maxYaxis * 0.25);
    createLabelAndGridLine(maxYaxis * 0.5);
    createLabelAndGridLine(maxYaxis * 0.75);
    createLabelAndGridLine(maxYaxis);
  }
};
