import { ExportButton } from '../exportButton';
import { EditButton } from './editButton';
import { DeleteButton } from './deleteButton';
import { NewButton } from './newButton';
import { AdditionalItems } from '../../list/list';

/**
 * Hook to generate normalized list header buttons for export, edit, delete, &/
 * create in a consistent order, with shared disabled logic.
 */
const useNormalizedHeaderButtons = (params: {
  checkedRows?: any[],
  export?: {
    exportFunction: () => void,
    filename: string
  },
  edit?: {
    onClick: () => void,
    className?: string,
    key?: string,
    dataId?: string
  },
  delete?: {
    onClick: () => void,
    className?: string,
    key?: string,
    dataId?: string
  },
  create?: {
    onClick: () => void,
    className?: string,
    key?: string,
    dataId?: string
  }

}) => {
  const additionalHeaderButtons: AdditionalItems = [];

  if (params.create) {
    additionalHeaderButtons.push(...[
      {
        Component: NewButton,
        props: {
          ...params.create
        }
      }
    ]);
  }
  if (params.edit) {
    const disabled = params.checkedRows?.length !== 1;

    additionalHeaderButtons.push(...[
      {
        Component: EditButton,
        props: {
          disabled,

          ...params.edit
        }
      }
    ]);
  }
  if (params.delete) {
    additionalHeaderButtons.push(...[
      {
        Component: DeleteButton,
        props: {
          disabled: params.checkedRows?.length === 0,

          ...params.delete
        }
      }
    ]);
  }
  if (params.export) {
    additionalHeaderButtons.push(...[
      {
        Component: ExportButton,
        props: {
          ...params.export
        }
      }
    ]);
  }

  return additionalHeaderButtons;
};

export default useNormalizedHeaderButtons;
