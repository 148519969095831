import * as React from 'react';
import { range, xor } from 'lodash';

import { ITableColumn, SortDirection } from './types';
import { IFilterResult } from '../filters/filterLib';
import { SameValueAsKeys, tuple } from '../../lib/typeUtils';
import { devLogger } from '../../lib/logger';
import { setTablePageSizesAction, SetTablePageSizesRequest } from '../../services/config/config';
import { UpdateProperties, useUserSettingsContext } from '../../context/userSettings';
import { useNonWorldAction } from '../../lib/useNonWorldAction';

// should be kept up to date with table names in middleware table settings
export const tableNamesArray = [
  'applicationsList',
  'applicationDevicesList',
  'batteryList',
  'batteryDevicesList',
  'devicesList',
  'groupOverview',
  'homeLocationOverview',
  'homeLocationAndGroupOverview',
  'deviceApplications',
  'deviceProcesses',
  'eventsList24Hours',
  'eventsList30Days',
  'usersList',
  'zonesList',
  'homeLocationsList',
  'anomaliesList',
  'anomalyDevicesList',
  'processesList',
  'processDeviceList'
] as const;
export type TableName = typeof tableNamesArray[number];

export const isTableName = (name: string): name is TableName => {
  return tableNamesArray.includes(name as any);
};

type TableNames = SameValueAsKeys<typeof tableNamesArray>;
export const tableNames: TableNames = tableNamesArray.reduce((acc, type) => ({ ...acc, [type]: type }), {} as TableNames);

export type TableAction<T> =
  | { type: 'onSort', column: number, direction: SortDirection, info: ITableColumn }
  | { type: 'onSearch', search: string }
  | { type: 'onFilter', filter: IFilterResult }
  | { type: 'onPageChange', offset: number }
  | { type: 'onPageSizeChange', limit: number }
  | { type: 'onLoading' }
  | { type: 'onDataLoaded', data: T[], total: number, requestId: number }
  | { type: 'onDataChange', newData: T[] }
  | { type: 'onRowSelected', selectedRow: number }
  | { type: 'onRowChecked', checkedRow: number }
  | { type: 'onCheckAll' }
  | { type: 'onClearRowSelection' };

export interface ITableSort {
  column: number,
  direction: SortDirection,
  field: string
}

export interface ITableState<T> {
  data: T[],
  offset: number,
  limit: number,
  total: number,
  isLoading: boolean,
  dataRequestId: number,
  sort?: ITableSort,
  filter?: IFilterResult,
  search?: string,
  selectedRow?: number,
  checkedRows?: number[]
}

export type InitialTableState<T = any> = {
  initialRows?: number
} & Partial<Omit<ITableState<T>, 'dataRequestId'>>;

export const saveTablePageSize = (
  newLimit: number,
  tableName: TableName,
  stateUpdateFunc: UpdateProperties,
  setTablePageSizes: SetTablePageSizesRequest
): void => {
  const path = `tablePageSizes.${tableName}`;
  const updated = { [tableName]: newLimit };
  stateUpdateFunc(path, { tablePageSizes: updated });
  // N.B. we do not wait for the chart filter request to resolve/reject
  setTablePageSizes(updated)
    .then(({ set }) => {
      devLogger.log(`setTablePageSize for ${tableName} ok, set: ${set}`);
    }).catch((err) => {
      devLogger.warn(`setTablePageSize for ${tableName} failed: ${err}`);
    });
};

export function tableReducerInit<T>({ initialRows = 0, ...rest }: InitialTableState<T>): ITableState<T> {
  return {
    data: Array(initialRows).fill(undefined),
    dataRequestId: 0,
    isLoading: true,
    total: 0,
    offset: 0,
    limit: 20,
    checkedRows: [],
    ...rest
  };
}

export const useTableReducerState = <T>() => {
  return React.useCallback((state: ITableState<T>, action: TableAction<T>): ITableState<T> => {
    const getNextRequestId = (prevId: number = 0) => prevId + 1;
    const dataRequestId = getNextRequestId(state.dataRequestId);
    const clearSelected: Partial<ITableState<T>> = { selectedRow: undefined };
    const clearChecked: Partial<ITableState<T>> = { checkedRows: [] };
    const loadingState: Partial<ITableState<T>> = {
      isLoading: true,
      offset: 0,
      dataRequestId,
      ...clearSelected,
      ...clearChecked
    };

    if (action.type === 'onSort') {
      const { column, direction: requestedDirection, info } = action;
      const direction = column === state.sort.column ? requestedDirection : 'asc';
      return { ...state, ...loadingState, sort: { column, direction, field: info.dataPath } };
    }
    if (action.type === 'onRowSelected') {
      const { selectedRow } = action;
      if (selectedRow === state.selectedRow) {
        return { ...state, ...clearSelected };
      }
      return { ...state, selectedRow };
    }
    if (action.type === 'onCheckAll') {
      const allRows = range(state.data.length);
      const checkedRows = state.checkedRows.length === state.data.length ? [] : allRows;
      return { ...state, checkedRows };
    }
    if (action.type === 'onRowChecked') {
      const { checkedRow } = action;

      const checkedRows = xor(state.checkedRows, [checkedRow]);
      return { ...state, checkedRows };
    }
    if (action.type === 'onDataChange') {
      const { newData } = action;
      return { ...state, data: newData };
    }
    if (action.type === 'onClearRowSelection') {
      return { ...state, ...clearSelected };
    }
    if (action.type === 'onSearch') {
      const { search } = action;
      return { ...state, ...loadingState, search };
    }
    if (action.type === 'onFilter') {
      const { filter } = action;
      return { ...state, ...loadingState, filter };
    }
    if (action.type === 'onPageChange') {
      const { offset } = action;
      return { ...state, ...loadingState, offset };
    }
    if (action.type === 'onPageSizeChange') {
      const { limit } = action;
      return { ...state, ...loadingState, limit };
    }
    if (action.type === 'onDataLoaded') {
      const { data, total, requestId } = action;
      if (requestId !== state.dataRequestId) {
        return state;
      }
      return { ...state, isLoading: false, data, total };
    }
    if (action.type === 'onLoading') {
      return { ...state, ...loadingState };
    }
    return state;
  }, []);
};

export type Dispatchers<T = any> = {
  onSort: (column: number, direction: SortDirection, info: ITableColumn) => void,
  onSearch: (search: string) => void,
  onFilter: (filter: IFilterResult) => void,
  onPageChange: (offset: number) => void,
  onPageSizeChange: (limit: number) => void,
  onLoading: () => void,
  onDataLoaded: (data: T[], total: number, requestId: number) => void,
  onRowSelected: (selectedRow: number) => void,
  onDataChange: (newData: T[]) => void,
  onRowChecked: (checkedRow: number) => void,
  onCheckAll: () => void,
  onClearRowSelection: () => void
};

export const useTableReducer = <T = any>(name: TableName, initialState: InitialTableState<T>) => {
  const reducer = dependencies.useTableReducerState<T>();
  const [data, dispatch] = React.useReducer(reducer, initialState, tableReducerInit);
  const { updateProperties } = useUserSettingsContext();
  const setTablePageSizes = useNonWorldAction(setTablePageSizesAction);
  const dispatchers = React.useMemo(() => {
    const onSort = (column: number, direction: SortDirection, info: ITableColumn) => {
      return dispatch({ type: 'onSort', column, direction, info });
    };
    const onSearch = (search: string) => {
      return dispatch({ type: 'onSearch', search });
    };
    const onFilter = (filter: IFilterResult) => {
      return dispatch({ type: 'onFilter', filter });
    };
    const onPageChange = (offset: number) => {
      return dispatch({ type: 'onPageChange', offset });
    };
    const onPageSizeChange = (limit: number) => {
      dependencies.saveTablePageSize(limit, name, updateProperties, setTablePageSizes);
      return dispatch({ type: 'onPageSizeChange', limit });
    };
    const onLoading = () => {
      return dispatch({ type: 'onLoading' });
    };
    const onDataLoaded = (data: T[], total: number, requestId: number) => {
      return dispatch({ type: 'onDataLoaded', data, total, requestId });
    };
    const onRowSelected = (selectedRow: number) => {
      return dispatch({ type: 'onRowSelected', selectedRow });
    };
    const onDataChange = (newData: T[]) => {
      return dispatch({ type: 'onDataChange', newData });
    };
    const onCheckAll = () => {
      return dispatch({ type: 'onCheckAll' });
    };
    const onRowChecked = (checkedRow: number) => {
      return dispatch({ type: 'onRowChecked', checkedRow });
    };
    const onClearRowSelection = () => {
      return dispatch({ type: 'onClearRowSelection' });
    };
    return {
      onSort,
      onSearch,
      onFilter,
      onPageChange,
      onPageSizeChange,
      onLoading,
      onDataLoaded,
      onRowSelected,
      onRowChecked,
      onCheckAll,
      onDataChange,
      onClearRowSelection
    };
  }, [dispatch, name, updateProperties, setTablePageSizes]);
  return tuple(data, dispatchers);
};

export const dependencies = {
  saveTablePageSize,
  useTableReducerState
};
