import * as React from 'react';
import { useParams, Route, Redirect, useRouteMatch } from 'react-router-dom';

import { Access } from './parts/access';
import { StandardPage } from './parts/standardPage';
import { VIEW } from '../services/shared/common';
import { BatteryProductName } from '../services/shared/battery/list';
import { BatteryContainer } from '../containers/battery';
import { alignValue } from './parts/utils';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { useProductsContext } from '../context/products';
import { Product } from '../containers/app/productsWrapper';
import { useBrandContext } from '../context/brand';

const product: Product = 'batteryWarranty';

export const BatteryWarrantyRoutes = () => {
  const basePath = useRouteMatch().path;
  const { name: brand } = useBrandContext();
  const productsContext = useProductsContext();
  const routes = useWorldRoutes();

  const config = {
    link: routes.batteryWarranty,
    productName: BatteryProductName.batteryWarranty
  };
  // we check only brand here but feature enabled is checked in access.tsx
  if (brand !== 'panasonic') {
    return <Redirect to={routes.landing} />;
  }

  const title = productsContext[product].title;
  return (
    <Route exact path={[`${basePath}/:view`, basePath]}>
      <BatteryWarrantyPage title={title} config={config} />
    </Route>
  );
};

interface PageProps {
  title: string,
  config: any
}

const BatteryWarrantyPage = (props: PageProps) => {
  const { title, config } = props;
  const { view } = useParams<{ view: string }>();
  const routeMapping = {
    [VIEW.all]: VIEW.all,
    [VIEW.red]: VIEW.red,
    "replace": VIEW.red,
    [VIEW.orange]: VIEW.orange,
    "warning": VIEW.orange,
    [VIEW.green]: VIEW.green,
    "good": VIEW.green
  };
  return (
    <Access product={product} title={title} >
      <StandardPage title={title} >
        <BatteryContainer config={config} view={alignValue(view, routeMapping)}/>
      </StandardPage>
    </Access>
  );
};
