import * as React from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';
import { useTranslation } from '@lib/useTypedTranslation';

import { StandardPage } from './parts/standardPage';
import { AgreementForm } from '../containers/agreements/agreement-form';
import { Agreement } from '../containers/agreements/agreement';
import { AgreementTypes, useAgreementsContext } from '../context/agreements';
import { useWorldRoutes } from './parts/allWorldRoutes';

export const AgreementsRoutes = withRouter((props: RouteChildrenProps) => {
  const { t } = useTranslation('agreements');
  const { t: tTranslation } = useTranslation();

  const { match } = props;
  const { acceptedEULA, acceptedDPA, agreementsToAccept } = useAgreementsContext();
  const basePath = match.path;
  const routes = useWorldRoutes();

  const anyAgreementRequired = agreementsToAccept.length > 0;
  const eulaAgreementRequired = agreementsToAccept.includes(AgreementTypes.eula);
  const dpaAgreementRequired = agreementsToAccept.includes(AgreementTypes.dpa);

  // If an agreement is required the user may only access the agreements form page
  // or the pages to view the text of the agreements
  let agreementForm;
  if (anyAgreementRequired) {
    let formTitle;
    if (eulaAgreementRequired && dpaAgreementRequired) {
      formTitle = `${t('EULA_ABBREV')} ${tTranslation('AND')} ${t('DPA_ABBREV')}`;
    } else if (eulaAgreementRequired) {
      formTitle = t('EULA_ABBREV');
    } else {
      formTitle = t('DPA_ABBREV');
    }
    agreementForm = (
      <Route path={basePath} exact>
        <StandardPage title={formTitle} >
          <AgreementForm />
        </StandardPage>
      </Route>
    );
  }

  // If any agreements are required, a user should be able to view the text of any required agreements.
  // If no agreements are required, a user should be able to view the text of any accepted agreements.
  let eula;
  let dpa;
  if (eulaAgreementRequired || (!anyAgreementRequired && acceptedEULA)) {
    const agreement = eulaAgreementRequired ? 'requiredEULA' : 'acceptedEULA';
    eula = (
      <Route path={`${basePath}/eula`}>
        <StandardPage title={t('EULA_ABBREV')} >
          <Agreement agreement={agreement} />
        </StandardPage>
      </Route>
    );
  }
  if (dpaAgreementRequired || (!anyAgreementRequired && acceptedDPA)) {
    const agreement = dpaAgreementRequired ? 'requiredDPA' : 'acceptedDPA';
    dpa = (
      <Route path={`${basePath}/dpa`}>
        <StandardPage title={t('DPA_ABBREV')} >
          <Agreement agreement={agreement} />
        </StandardPage>
      </Route>
    );
  }

  return (
    <Switch>
      {agreementForm}
      {eula}
      {dpa}
      <Redirect to={routes.landing} />
    </Switch>
  );
});

AgreementsRoutes.displayName = 'withRouter(AgreementsRoutes)';
