import React, { useCallback, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Card from '../../../components/card/card';
import { InvalidPage } from '../../../components/errors/invalidPage';
import { LinkTab } from '../../../components/tabs/link-tab';
import { TabPanel } from '../../../components/tabs/tab-panel';
import { TabPanelHeader } from '../../../components/tabs/tab-panel-header';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { ApplicationContext, ApplicationDevicesListItem, getApplicationInfo } from '../../../services/core/application';
import { Theme } from '../../app/themes';
import { ApplicationPerformance } from './applicationPerformance';
import { Loading } from '../../../components/loading/loading';
import { ApplicationDevices, getInitialTableState, tableName } from './applicationDevices';
import { useFilterPicker } from '../../../components/timePeriodAndFilterPicker/useFilterPicker';
import { useTimePeriodPicker } from '../../../components/timePeriodAndFilterPicker/useTimePeriodPicker';
import { Option } from '../../../components/controls/optionPickerLib';
import { useTableReducer } from '../../../components/data-table/lib';
import { useUserSettingsContext } from '../../../context/userSettings';
import { IHomeLocation } from '../../../services/config/config';

const Header = styled.div`
  padding-top: 1.5rem;
  padding-left: 2.5rem;
  padding-bottom: 0.75rem;
  font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.twelvePixels};
`;

const Breadcrumb = styled.div`
  margin-top: 5px;
  color: #707070;
`;

const Title = styled.h1`
  font-weight: ${({ theme }: { theme: Theme}) => theme.font.weights.bold};
  font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.twentyPixels};
`;

const LinkToApplications = styled(Link)`
  padding-right: 0.5em;
  color: ${({ theme }: { theme: Theme}) => theme.links.color};
`;

const AppName = styled.div`
  display: inline-block;
  padding-left: 0.5em;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
`;

interface Props {
  id: string,
  tab: string
}

export const ApplicationInfoContext = React.createContext({} as ApplicationContext);

export function CoreApplication(props: Props) {
  const { id, tab } = props;
  const routes = useWorldRoutes();
  const { tablePageSizes } = useUserSettingsContext();
  const queryString = `?id=${encodeURIComponent(id)}`;
  const { t } = useTranslation('coreApplications');
  const allVersionsOption: Option<string> = { name: t('ALL_APPLICATION_VERSIONS'), value: undefined };
  const [version, setVersion] = useState<Option<string>>(allVersionsOption);
  const {
    filter,
    optionsLoading,
    ...filterPickerProps
  } = useFilterPicker();

  const {
    timePeriod,
    ...timePeriodPickerProps
  } = useTimePeriodPicker({ timePeriods: [30, 60, 90] });

  const applicationInfoFetcher = useCallback(() => {
    return getApplicationInfo({ id });
  }, [id]);

  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<ApplicationDevicesListItem>(tableName, getInitialTableState({ tablePageSizes }));
  const { onSearch } = tableReducerFunctions;
  const { data: application, loading } = useWorldRequest(applicationInfoFetcher, { initialLoading: true });

  const performanceTab = (
    <LinkTab id="performance" key="performance" link={`performance${queryString}`} title={t('PERFORMANCE')}>
      <ApplicationPerformance
        filter={filter}
        timePeriod={timePeriod}
        optionsLoading={optionsLoading}
        version={version}
        setVersion={(selected: Option<string>) => { onSearch(''); setVersion(selected); }}
        {...filterPickerProps}
        {...timePeriodPickerProps}
        handleFilterReset={() => { onSearch(''); filterPickerProps.handleFilterReset(); }}
        handleHomeLocationSelect={(selected: Option<IHomeLocation>) => { onSearch(''); filterPickerProps.handleHomeLocationSelect(selected); }}
        handleGroupSelect={(selected: Option<string>) => { onSearch(''); filterPickerProps.handleGroupSelect(selected); }}
      />
    </LinkTab>
  );

  const devicesTab = (
    <LinkTab id="devices" key="devices" link={`devices${queryString}`} title={t('APPLICATION_DEVICES_LIST_TITLE')}>
      <ApplicationDevices version={version.value} filter={filter} timePeriod={timePeriod} tableReducerFunctions={tableReducerFunctions} tableReducerProperties={tableReducerProperties} />
    </LinkTab>
  );

  const applicationNotFound = <InvalidPage
    message={t('APPLICATION_NOT_FOUND', { ns: 'redirect' })}
    redirect={{
      message: t('REDIRECT_TO_APPLICATIONS', { ns: 'redirect' }),
      path: routes.core.applicationsList
    }}
  />;

  if (loading) {
    return <LoadingContainer><Loading isLoading={loading} transparentOverlay={false}></Loading></LoadingContainer>;
  }

  if (application === null) {
    return applicationNotFound;
  }

  const tabNames = ['performance', 'devices'];
  const tabIndex = tabNames.includes(tab) ? tabNames.indexOf(tab) : 0;
  const tabs = [performanceTab, devicesTab];

  return (
    <ApplicationInfoContext.Provider value={{ ...application, id }}>
      <div className={'core-application' /* this class is used to render the tooltip for the applicationDevicesList */} data-id="core-application">
        <Card noPadding={true}>
          <TabPanel defaultTabIndex={tabIndex} key="core-applications">
            <TabPanelHeader>
              <Header>
                <Title>
                  {`${application.name} (${application.packageName})`}
                </Title>
                <Breadcrumb>
                  <LinkToApplications to={routes.core.applicationsList}>{t('APPLICATIONS', { ns: 'translation' })}</LinkToApplications>
                  <i className="fas fa-chevron-right" />
                  <AppName>{application.name}</AppName>
                </Breadcrumb>
              </Header>
            </TabPanelHeader>
            {tabs}
          </TabPanel>
        </Card>
      </div>
    </ApplicationInfoContext.Provider>
  );
}
