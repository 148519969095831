import react, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { useInterval } from '../lib/useInterval';
import { getWhatsNew, IWhatsNew } from '../services/whatsNew/whatsNew';
import { Brand } from '../lib/brands';
import { AppLoading } from '../components/loading/appLoading';

const WhatsNewContext = createContext<IWhatsNew[]>(undefined);

interface WhatsNewProviderProps {
  children: React.ReactNode,
  brand: Brand
}

export const WhatsNewProvider = ({ children, brand }: WhatsNewProviderProps) => {
  const [whatsNew, setWhatsNew] = useState(undefined);
  const delay: number = 3600000; // 1 hour
  const notificationListFetcher = useCallback(async () => {
    try {
      const fetchWhatsNew = getWhatsNew({ branding: brand });
      const data = await fetchWhatsNew({});
      setWhatsNew(data || []);
    } catch (error) {
      console.error('Error fetching whatsNew:', error);
      setWhatsNew([]);
    }
  }, [brand]);

  useInterval(notificationListFetcher, delay);

  if (!whatsNew) {
    return <AppLoading />;
  }

  return (
    <WhatsNewContext.Provider value={whatsNew}>
      {children}
    </WhatsNewContext.Provider>
  );
};

export const useWhatsNew = () => useContext(WhatsNewContext);
