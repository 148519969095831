import React, { useEffect, useState, useCallback } from 'react';

import { CheckBox, ICheckboxValue } from '../../components/controls/checkbox';
import { Button } from '../../components/controls/button';
import { SignOut } from '../../components/authentication/signOut';
import { IAcceptAgreementsBody, acceptAgreementsAction, getAgreements } from '../../services/agreements/agreements';
import { Loading } from '../../components/loading/loading';
import { Card } from '../../components/card/card';
import { useWorldRequest } from '../../lib/useWorldRequest';
import { Link } from 'react-router-dom';
import { useWorldRoutes } from '../../routes/parts/allWorldRoutes';
import { AgreementTypes, useAgreementsContext } from '../../context/agreements';
import { useWorldAction } from '../../lib/useWorldAction';

import './agreement-form.css';
import { useTranslation } from '@lib/useTypedTranslation';
import { Trans } from '@components/i18n/typedTrans';

export const AgreementForm = () => {
  const { t } = useTranslation('agreements');
  const { dpa: dpaLink, eula: eulaLink } = useWorldRoutes().agreements;
  const { requiredDPA, requiredEULA, agreementsToAccept, updateProperties: updateAgreements } = useAgreementsContext();
  const acceptAgreements = useWorldAction(acceptAgreementsAction);

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [eulaAgreed, setEulaAgreed] = useState(false);
  const [dpaAgreed, setDpaAgreed] = useState(false);
  const [employeeName, setEmployeeName] = useState('');
  const [jobTitle, setJobTitle] = useState('');

  const setCheckBoxState = (setter: React.Dispatch<React.SetStateAction<boolean>>) => {
    return (state: ICheckboxValue) => setter(state.checked);
  };

  const eulaAgreementRequired = agreementsToAccept.includes(AgreementTypes.eula);
  const dpaAgreementRequired = agreementsToAccept.includes(AgreementTypes.dpa);

  const agreementsSet = (!eulaAgreementRequired || eulaAgreed) && (!dpaAgreementRequired || dpaAgreed);
  const employeeDetailsComplete = Boolean(employeeName.trim() && jobTitle.trim());
  const canSubmit = agreementsSet && employeeDetailsComplete;

  const submitAgreements = () => {
    if (canSubmit && !submitting) {
      setSubmitting(true);
    }
  };

  useEffect(() => {
    if (submitting && canSubmit) {
      setError(false);
      const data: IAcceptAgreementsBody = { name: employeeName, jobTitle };
      if (dpaAgreementRequired) {
        data.dpa = requiredDPA;
      }
      if (eulaAgreementRequired) {
        data.eula = requiredEULA;
      }
      acceptAgreements(data).then((agreementsIds) => {
        updateAgreements(agreementsIds);
      }).catch(() => {
        setEulaAgreed(false);
        setDpaAgreed(false);
        setSubmitting(false);
        setError(true);
      });
    }
  }, [acceptAgreements, submitting, canSubmit, dpaAgreementRequired, eulaAgreementRequired, employeeName, jobTitle, updateAgreements, requiredDPA, requiredEULA]);

  const agreementsFetcher = useCallback(() => {
    return getAgreements({
      requiredEULA: eulaAgreementRequired,
      requiredDPA: dpaAgreementRequired,
    });
  }, [dpaAgreementRequired, eulaAgreementRequired]);
  const { loading, data: agreementsData } = useWorldRequest(agreementsFetcher);

  if (loading || submitting || !agreementsData) {
    return <Loading isLoading={true} transparentOverlay={false} />;
  }

  const agreements = [];
  const checkBoxes = [];
  if (eulaAgreementRequired) {
    agreements.push(
      <div className="agreement-form__agreement" key="agreement-eula">
        <div className="agreement-form__agreement__header">
          <div className="agreement-form__agreement__title">1. {t('EULA_FULL')} ({t('EULA_ABBREV')})</div>
          <Link className="agreement-form__agreement__link" to={eulaLink} target="_blank" rel="noopener noreferrer">
            {t('OPEN_IN_NEW_TAB', { v: t('EULA_ABBREV') })}
          </Link>
        </div>
        <div className="agreement-form__agreement__text">{agreementsData.requiredEULA.text}</div>
      </div>
    );
    checkBoxes.push((
      <div className="agreement-form__checkbox agreement-form__checkbox--eula" key="checkbox-eula">
        <CheckBox
          className="agreement-form__checkbox fancy-checkbox"
          onChange={setCheckBoxState(setEulaAgreed)}
          name="eula"
          value={t('AGREE_TERMS', { v: t('THE_EULA_FULL') })}
          checked={eulaAgreed}
        />
      </div>
    ));
  }
  if (dpaAgreementRequired) {
    const count = agreements.length + 1;
    agreements.push(
      <div className="agreement-form__agreement" key="agreement-dpa">
        <div className="agreement-form__agreement__header">
          <div className="agreement-form__agreement__title">{count}. {t('DPA_FULL')} ({t('DPA_ABBREV')})</div>
          <Link className="agreement-form__agreement__link" to={dpaLink} target="_blank" rel="noopener noreferrer">
            {t('OPEN_IN_NEW_TAB', { v: t('DPA_ABBREV') })}
          </Link>
        </div>
        <div className="agreement-form__agreement__text">{agreementsData.requiredDPA.text}</div>
      </div>
    );
    checkBoxes.push((
      <div className="agreement-form__checkbox agreement-form__checkbox--dpa" key="checkbox-dpa">
        <CheckBox
          className="fancy-checkbox"
          onChange={setCheckBoxState(setDpaAgreed)}
          name="dpa"
          value={t('AGREE_TERMS', { v: t('THE_DPA_FULL') })}
          checked={dpaAgreed}
        />
      </div>
    ));
  }

  let toAgree = eulaAgreementRequired ? t('THE_EULA_ABBREV') : t('THE_DPA_ABBREV');
  let descriptionText = t('AGREEMENT_DESCRIPTION');
  if (eulaAgreementRequired && dpaAgreementRequired) {
    toAgree = t('BOTH_EULA_DPA');
    descriptionText = t('AGREEMENT_DESCRIPTION_other');
  }

  let errorMessage;
  if (error) {
    errorMessage = (
      <div className="agreement-form__error">
        <div id="blankError">{t('ERROR')}</div>
      </div>
    );
  }

  return (
    <Card className="agreement-form card--narrow card--centered card--rounded">
      {agreements}
      <div className="agreement-form__checkboxes-header">
        <Trans i18nKey='AGREEMENTS_READ' ns='agreements' values={{ v: toAgree }}>
          0<b>1</b>24
        </Trans>
      </div>
      <div className="agreement-form__checkboxes-wrapper">
        <div className="agreement-form__checkboxes">
          {checkBoxes}
        </div>
      </div>
      <div className="agreement-form__checkboxes-description">
        {descriptionText}
      </div>
      <div className="agreement-form__employee_details">
        <div className="agreement-form__employee_name">
          <Trans i18nKey='NAME_REQUIRED' ns='translation'>0<i>1</i></Trans>
          <input type="text" className="agreement-form__input" name="name" required={true} value={employeeName} minLength={1} maxLength={50} onChange={(e) => setEmployeeName(e.target.value.substring(0, 50))} />
        </div>
        <div className="agreement-form__job_title">
          <Trans i18nKey='JOB_TITLE_REQUIRED' ns='translation'>0<i>1</i></Trans>
          <input type="text" className="agreement-form__input" name="jobtitle" required={true} value={jobTitle} minLength={1} maxLength={50} onChange={(e) => setJobTitle(e.target.value.substring(0, 50))} />
        </div>
      </div>
      {errorMessage}
      <div className="agreement-form__buttons">
        <SignOut >
          <div className="agreement-form__sign-out">{t('RETURN_LOG_IN')}</div>
        </SignOut>
        <Button
          className="agreement-form__accept panel-button"
          text={t('ACCEPT')}
          onClick={submitAgreements}
          disabled={!canSubmit}
        />
      </div>
    </Card>
  );
};
