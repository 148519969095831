import React from 'react';
import './overview.css';

export interface IOverviewItem {
  value: number | string | JSX.Element | undefined,
  label: string | JSX.Element
}

interface IProps {
  items: IOverviewItem[]
}

export class OverviewDashboard extends React.Component<IProps> {
  public render() {
    const sections = this.props.items.map((item, i) => (
      <div className="col-auto" key={i}>
        <div className="overview-column_spacing overview-value_blue">{item.value}</div>
        <div className="overview-column_spacing overview-label">{item.label}</div>
      </div>));

    return <div className="overview-component row">
      {sections}
    </div>;
  }
}
