import { TTypedTFunction } from '@lib/useTypedTranslation';
import { RawFieldsConfig } from '../../components/forms/formsLib';
import { AlertIconClass } from '../../lib/alerts';
import { kilometresToMetres, metresToKilometres, metresToMiles, milesToMetres, yardsToMetres, metresToYards, metresToSavedMetres } from '../../lib/distanceConversion';
import { truncateTo3Places } from '../../lib/dataConversion';
import { tuple } from '../../lib/typeUtils';

export const ns = 'homeLocations' as const;
export const ns2 = 'translation' as const;
export const ns3 = 'error' as const;
export const ns4 = 'forms' as const;
export const ns5 = 'distance' as const;
export const nsList = tuple(ns, ns2, ns3, ns4, ns5);

export const fieldNames = [
  'name',
  'latitude',
  'longitude',
  'radius',
  'addressLine1',
  'addressLine2',
  'locality',
  'postalCode',
  'country'
] as const;
export type FieldNames = typeof fieldNames;

export enum ErrorCode {
  LATITUDE_RANGE = 'LATITUDE_RANGE',
  LONGITUDE_RANGE = 'LONGITUDE_RANGE',
  RADIUS_RANGE = 'RADIUS_RANGE'
}

export const rawUpdateFieldsConfig: RawFieldsConfig<FieldNames, ErrorCode> = {
  name: {
    name: (t) => t('NAME', { ns: ns2 }),
    readOnly: true,
  },
  latitude: {
    name: (t) => t('LATITUDE', { ns }),
    required: true,
    validate: (val) => {
      if (Number(val) < -90 || Number(val) > 90) {
        return ErrorCode.LATITUDE_RANGE;
      }
    },
    validationErrors: {
      [ErrorCode.LATITUDE_RANGE]: (t) => t('LATITUDE_VALIDATION_ERROR_RANGE', { ns })
    },
    prepareValue: Number,
    inputProps: {
      type: 'number',
      step: 'any',
      min: -90,
      max: 90
    }
  },
  longitude: {
    name: (t) => t('LONGITUDE', { ns }),
    required: true,
    validate: (val: string) => {
      if (Number(val) < -180 || Number(val) > 180) {
        return ErrorCode.LONGITUDE_RANGE;
      }
    },
    validationErrors: {
      [ErrorCode.LONGITUDE_RANGE]: (t) => t('LONGITUDE_VALIDATION_ERROR_RANGE', { ns })
    },
    prepareValue: Number,
    inputProps: {
      type: 'number',
      step: 'any',
      min: -180,
      max: 180
    }
  },
  radius: {
    name: (t) => t('RADIUS', { ns }),
    required: true,
    afterInputText: (t) => t('YARD_other', { ns: ns5 }),
    onChange: (val: string) => val.replace(/[^\d]/g, ''),
    extractValue: (val: any) => String(metresToYards(val)),
    prepareValue: (val: string) => yardsToMetres(Number(val)),
    validate: (val: string) => {
      if (Number(val) < 1) {
        return ErrorCode.RADIUS_RANGE;
      }
    },
    validationErrors: {
      [ErrorCode.RADIUS_RANGE]: (t) => t('RADIUS_VALIDATION_ERROR_RANGE', { ns }),
    },
    inputProps: {
      type: 'number',
      step: 1,
      min: 1
    }
  },
  addressLine1: { name: (t) => t('ADDRESS_LINE', { ns, number: 1 }) },
  addressLine2: { name: (t) => t('ADDRESS_LINE', { ns, number: 2 }) },
  locality: { name: (t) => t('LOCALITY', { ns }) },
  postalCode: { name: (t) => t('POSTCODE', { ns }) },
  country: { name: (t) => t('COUNTRY', { ns }) },
};

const metricRadiusOverrides = {
  afterInputText: (t: TTypedTFunction) => t('METRE_other', { ns: ns5 }),
  extractValue: String,
  prepareValue: (val: string) => metresToSavedMetres(Number(val)),
};

export const rawUpdateFieldsConfigMetric: RawFieldsConfig<FieldNames, ErrorCode> = {
  ...rawUpdateFieldsConfig,
  radius: {
    ...rawUpdateFieldsConfig.radius,
    ...metricRadiusOverrides
  }
};

export const getRawUpdateFieldsConfig = (isMetric: boolean): RawFieldsConfig<FieldNames, ErrorCode> => {
  return isMetric ? rawUpdateFieldsConfigMetric : rawUpdateFieldsConfig;
};

export const rawCreateFieldsConfig: RawFieldsConfig<FieldNames, ErrorCode> = {
  ...rawUpdateFieldsConfig,
  name: { ...rawUpdateFieldsConfig.name, readOnly: undefined, required: true },
};

export const rawCreateFieldsConfigMetric: RawFieldsConfig<FieldNames, ErrorCode> = {
  ...rawCreateFieldsConfig,
  radius: {
    ...rawCreateFieldsConfig.radius,
    ...metricRadiusOverrides
  }
};

export const getRawCreateFieldsConfig = (isMetric: boolean): RawFieldsConfig<FieldNames, ErrorCode> => {
  return isMetric ? rawCreateFieldsConfigMetric : rawCreateFieldsConfig;
};

export const thresholdFieldNames = [
  'distanceYellow',
  'distanceRed'
] as const;
export type ThresholdFieldNames = typeof thresholdFieldNames;

export enum ThresholdErrorCode {
  YELLOW_RANGE = 'YELLOW_RANGE',
  YELLOW_RELATIVE = 'YELLOW_RELATIVE',
  YELLOW_MAX = 'YELLOW_MAX',
  YELLOW_MAX_METRIC = 'YELLOW_MAX_METRIC',
  RED_RANGE = 'RED_RANGE',
  RED_RELATIVE = 'RED_RELATIVE',
  RED_MAX = 'RED_MAX',
  RED_MAX_METRIC = 'RED_MAX_METRIC'
}

const METRIC_MAX_DISTANCE = 500;
const IMPERIAL_MAX_DISTANCE = 310.686;
export const rawThresholdsConfig: RawFieldsConfig<ThresholdFieldNames, ThresholdErrorCode> = {
  distanceYellow: {
    name: (t) => <><i className={AlertIconClass.yellow} />{t('WARNING_THRESHOLD', { ns })}</>,
    afterInputText: (t) => t('MILE_other', { ns: ns5 }),
    prepareValue: (val: string) => milesToMetres(Number(val)),
    extractValue: (val: any) => String(metresToMiles(val)),
    onChange: (val: string) => truncateTo3Places(val.replace(/[^\d.]/g, '')),
    validate: (val: string, { distanceRed }) => {
      if (Number(val) < 0.001) {
        return ThresholdErrorCode.YELLOW_RANGE;
      }
      if (Number(val) > IMPERIAL_MAX_DISTANCE - 0.001) {
        return ThresholdErrorCode.YELLOW_MAX;
      }
      if (distanceRed && Number(distanceRed) <= Number(val)) {
        return ThresholdErrorCode.YELLOW_RELATIVE;
      }
    },
    validationErrors: {
      [ThresholdErrorCode.YELLOW_RANGE]: (t) => t('WARNING_THRESHOLD_VALIDATION_ERROR_RANGE', { ns }),
      [ThresholdErrorCode.YELLOW_RELATIVE]: (t) => t('WARNING_THRESHOLD_VALIDATION_ERROR_RELATIVE', { ns }),
      [ThresholdErrorCode.YELLOW_MAX]: (t) => t('WARNING_THRESHOLD_VALIDATION_ERROR_MAX', { ns, max: IMPERIAL_MAX_DISTANCE - 0.001 }),
      [ThresholdErrorCode.YELLOW_MAX_METRIC]: (t) => t('WARNING_THRESHOLD_VALIDATION_ERROR_MAX', { ns, max: METRIC_MAX_DISTANCE - 0.001 })
    },
    alsoValidate: ['distanceRed'],
    inputProps: {
      type: 'number',
      step: 0.001,
      min: 0.001
    }
  },
  distanceRed: {
    name: (t) => <><i className={AlertIconClass.red} />{t('PROBLEM_THRESHOLD', { ns })}</>,
    afterInputText: (t) => t('MILE_other', { ns: ns5 }),
    prepareValue: (val: string) => milesToMetres(Number(val)),
    extractValue: (val: any) => String(metresToMiles(val)),
    onChange: (val: string) => truncateTo3Places(val.replace(/[^\d.]/g, '')),
    validate: (val: string, { distanceYellow }) => {
      if (Number(val) < 0.002) {
        return ThresholdErrorCode.RED_RANGE;
      }
      if (Number(val) > IMPERIAL_MAX_DISTANCE) {
        return ThresholdErrorCode.RED_MAX;
      }
      if (distanceYellow && Number(distanceYellow) >= Number(val)) {
        return ThresholdErrorCode.RED_RELATIVE;
      }
    },
    validationErrors: {
      [ThresholdErrorCode.RED_RANGE]: (t) => t('PROBLEM_THRESHOLD_VALIDATION_ERROR_RANGE', { ns }),
      [ThresholdErrorCode.RED_RELATIVE]: (t) => t('PROBLEM_THRESHOLD_VALIDATION_ERROR_RELATIVE', { ns }),
      [ThresholdErrorCode.RED_MAX]: (t) => t('PROBLEM_THRESHOLD_VALIDATION_ERROR_MAX', { ns, max: IMPERIAL_MAX_DISTANCE }),
      [ThresholdErrorCode.RED_MAX_METRIC]: (t) => t('PROBLEM_THRESHOLD_VALIDATION_ERROR_MAX', { ns, max: METRIC_MAX_DISTANCE })
    },
    alsoValidate: ['distanceYellow'],
    inputProps: {
      type: 'number',
      step: 0.001,
      min: 0.001
    }
  },
};

const metricDistanceOverrides = {
  afterInputText: (t: TTypedTFunction) => t('KILOMETRE_other', { ns: ns5 }),
  prepareValue: (val: string) => kilometresToMetres(Number(val)),
  extractValue: (val: any) => String(metresToKilometres(val))
};

export const rawThresholdsConfigMetric: RawFieldsConfig<ThresholdFieldNames, ThresholdErrorCode> = {
  ...rawThresholdsConfig,
  distanceYellow: {
    ...rawThresholdsConfig.distanceYellow,
    ...metricDistanceOverrides,
    validate: (val: string, { distanceRed }) => {
      if (Number(val) < 0.001) {
        return ThresholdErrorCode.YELLOW_RANGE;
      }
      if (Number(val) > METRIC_MAX_DISTANCE - 0.001) {
        return ThresholdErrorCode.YELLOW_MAX_METRIC;
      }
      if (distanceRed && Number(distanceRed) <= Number(val)) {
        return ThresholdErrorCode.YELLOW_RELATIVE;
      }
    },
  },
  distanceRed: {
    ...rawThresholdsConfig.distanceRed,
    ...metricDistanceOverrides,
    validate: (val: string, { distanceYellow }) => {
      if (Number(val) < 0.002) {
        return ThresholdErrorCode.RED_RANGE;
      }
      if (Number(val) > METRIC_MAX_DISTANCE) {
        return ThresholdErrorCode.RED_MAX_METRIC;
      }
      if (distanceYellow && Number(distanceYellow) >= Number(val)) {
        return ThresholdErrorCode.RED_RELATIVE;
      }
    }
  }
};

export const getRawThresholdsConfig = (isMetric: boolean): RawFieldsConfig<ThresholdFieldNames, ThresholdErrorCode> => {
  return isMetric ? rawThresholdsConfigMetric : rawThresholdsConfig;
};
