import React, { useReducer, useEffect, useMemo, useCallback } from "react";
import classNames from "classnames";
import './style.css';
import Card from '../../components/card/card';
import { DetailsGraph } from '../shared/battery/detailsGraph';
import { DetailsProplist } from './detailsProplist';
import { reverse, forEach } from 'lodash';
import { BatteryProductName } from '../../services/shared/battery/list';
import moment from 'moment';
import { Button } from "../../components/controls/button";
import { List } from '../../services/shared/battery/list';
import { NoSelectionOverlay } from '../card/noSelectionOverlay';
import { VIEW } from "../../services/shared/common";
import { useWorldAction } from '../../lib/useWorldAction';

const DATE_FORMAT = 'YYYY-MM-DD';

interface IState {
  id: string,
  dataset: number[],
  allDetails: any,
  details: any,
  days: string[],
  reloadRequired: boolean,
  deviceView: VIEW | undefined,
  isLoading: boolean,
  multiple: boolean,
  active: boolean,
  batteryIndex: number
}

function reducer(state: IState, action: { type: string, payload?: any }): IState {
  const { type, payload } = action;

  switch (type) {
  case 'isLoading':
    return { ...state, isLoading: payload };
  case 'setDeviceView':
    return { ...state, deviceView: payload };
  case 'receivedDeviceDetails':
    return { ...state, ...payload, ...{ details: payload.allDetails[state.batteryIndex - 1] }, isLoading: false };
  case 'setActiveBattery':
    return { ...state, details: state.allDetails[payload - 1], batteryIndex: payload };
  }
}

function fillBlanks() {
  const dataPoints: any = [];

  for (let i = 0; i < 30; i++) {
    dataPoints.push(null);
  }

  return dataPoints;
}

function getDetails(smartBattery: any, data: any): any {
  const nA = 'n/a';

  return {
    serialNumber: data.serialNumber,
    batteryPartNo: smartBattery.partNumber ?? nA,
    batterySerialNo: smartBattery.serialNumber ?? nA,
    averageDischarge: data.averageDischarge ? `${data.averageDischarge}%` : nA,
    mfd: smartBattery.mfd ?? nA,
    capacityFactor: smartBattery.capacityFactor ?? nA,
    cycleCount: smartBattery.cycleCount ?? nA,
    underWarranty: smartBattery?.underWarranty
  };
}

function findDays(): string[] {
  const intervals = [];
  const date = moment.utc(); //from today
  let limit = 29;

  intervals.push(moment(date).format(DATE_FORMAT));

  do {
    const prevDay = date.subtract(1, 'days');
    intervals.push(moment(prevDay).format(DATE_FORMAT));
    limit--;
  } while (limit > 0);

  reverse(intervals);

  return intervals;
}

interface IProps {
  productName: BatteryProductName,
  id: string,
  deviceView: VIEW,
  batteryIndex: number,
  overlayText?: string
}

function DetailsInner(props: IProps) {
  const initialState: IState = {
    id: props.id,
    dataset: [],
    allDetails: {},
    details: {},
    days: [],
    reloadRequired: true,
    deviceView: props.deviceView,
    isLoading: false,
    multiple: false,
    active: true,
    batteryIndex: 1
  };

  const list = useMemo(() => new List(props.productName), [props.productName]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const doGetDeviceDetails = useWorldAction(list.getDeviceBatteryDischarge);

  const dataFetcher = useCallback(async () => {
    if (props.id === '-') { return; }

    dispatch({ type: 'isLoading', payload: true });

    let data: any = {};

    data = await doGetDeviceDetails({ deviceId: props.id });
    const days = findDays();
    const dataPoints = fillBlanks();

    let deviceDetails: any[] = [];
    if (data.smartBatteries && data.smartBatteries.length > 0) {
      if (data.smartBatteries.length > 1) {
        deviceDetails = [
          getDetails(data.smartBatteries[0], data),
          getDetails(data.smartBatteries[1], data)
        ];
      } else {
        deviceDetails = [getDetails(data.smartBatteries[0], data)];
      }
    } else {
      deviceDetails = [getDetails({}, data)];
    }


    dispatch({ type: 'receivedDeviceDetails', payload: {
      dataset: dataPoints,
      days,
      allDetails: deviceDetails,
      multiple: deviceDetails.length > 1
    } });

  }, [props.id, doGetDeviceDetails]);

  useEffect(() => {
    dataFetcher();
  }, [dataFetcher]);

  useEffect(() => {
    setActiveBattery(props.batteryIndex);
  }, [props.batteryIndex]);

  useEffect(() => {
    dispatch({ type: 'setDeviceView', payload: props.deviceView });
  }, [props.deviceView]);

  function setActiveBattery(batteryIndex: number) {
    dispatch({ type: 'setActiveBattery', payload: batteryIndex });
  }

  function statusMessage(): string {
    switch (state.deviceView) {
    case VIEW.red:
      return " Poor Battery Health";
    case VIEW.orange:
      return " Discharge Warning";
    case VIEW.green:
      return " No Issues Detected";
    }
    return '';
  }

  const fontAwesomeClass = classNames({
    fa: true,
    'fa-times-circle': (state.deviceView === VIEW.red),
    'fa-exclamation-triangle': (state.deviceView === VIEW.orange),
    'fa fa-check': (state.deviceView === VIEW.green)
  });

  const batteryButtons = (
    <div className="details_container" style={{ display: state.multiple === true ? "block" : "none" }}>
      <Button data-id="detailsInner-battery1Btn" className={`batteryBtn first ${state.batteryIndex === 1 && 'active'}`} text="Battery 1" onClick={() => setActiveBattery(1) } />
      <Button data-id="detailsInner-battery2Btn" className={`batteryBtn second ${state.batteryIndex === 2 && 'active'}`} text="Battery 2" onClick={() => setActiveBattery(2) } />
    </div>);

  return (
    <div className="card-container">
      <NoSelectionOverlay noSelectionText={props.overlayText} show={props.id === '-'} />
      <Card>
        <div data-id={state.isLoading ? undefined : props.id} style={{ zIndex: -1, overflow: 'auto' }}>
          <div data-id="detailsInner-statusLabel" className={`status_label ${state.deviceView}_status`} style={{ zIndex: 10000, position: 'absolute' }}>
            <i className={fontAwesomeClass} />{statusMessage()}
          </div>

          <div className="row" style={{ marginRight: '1.5em', marginLeft: 0 }}>
            <div className="col-md-8">
              <DetailsGraph {...{ dataset: state.dataset, days: state.days, className: 'battery', title: 'Average Hourly Discharge', updated: '' }} />
            </div>

            <div className="col-md-4">
              <div className="d-none d-sm-block">
                <DetailsProplist details={state.details} loading={state.isLoading} batteryButtons={batteryButtons} isWarranty={props.productName === BatteryProductName.batteryWarranty} />
              </div>
              <div className="d-block d-sm-none">
                <div className="row justify-content-center">
                  <DetailsProplist details={state.details} loading={state.isLoading} batteryButtons={batteryButtons} isWarranty={props.productName === BatteryProductName.batteryWarranty} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>);
}

export default DetailsInner;
