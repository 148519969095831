import * as React from 'react';
import './body.css';

export interface IBodyProps {
  children: any
}

export const MainBody: React.FC<IBodyProps> = (props: IBodyProps) => (
  <div>
    <div className="page">
      {props.children}
    </div>
  </div>
);
