import React from 'react';

import Card from '../../../components/card/card';
import { Option } from '../../../components/controls/optionPickerLib';
import { Filter } from '@components/timePeriodAndFilterPicker/useFilterPicker';
import { ProcessDeviceList } from './deviceList/processDeviceList';
import { getTitle } from './filterTitle';
import { useTranslation } from '@lib/useTypedTranslation';
import { getUserTimeZone } from '@lib/timeZone';
import { getProcessVersionName } from './lib';
import { IProcessVersion } from '../../../services/core/processes';

export interface Props {
  processName: string,
  productName: string,
  filter: Filter,
  processVersion?: IProcessVersion,
  timePeriod: Option<number>
}

export const ProcessDeviceListTab: React.FC<Props> = (props) => {

  const { t } = useTranslation('coreProcesses');

  const title = getTitle({
    filter: props.filter,
    t,
    translationKey: 'DEVICE_LIST_TITLE',
    version: props.processVersion && getProcessVersionName(props.processVersion),
  });

  const params = React.useMemo(() => {
    return {
      processName: props.processName,
      productName: props.productName,
      fileVersion: props.processVersion?.fileVersion,
      productVersion: props.processVersion?.productVersion,
      group: props.filter.group?.value,
      homeLocationId: props.filter.homeLocation?.value.id,
      days: props.timePeriod.value,
      timeZone: getUserTimeZone()
    };
  }, [props.processName, props.productName, props.processVersion?.fileVersion, props.processVersion?.productVersion, props.filter.group?.value, props.filter.homeLocation?.value.id, props.timePeriod?.value]);

  return (
    <div>
      <Card noPadding={true}>
        <div>
          <div>
            <ProcessDeviceList
              title={title}
              params={params}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
