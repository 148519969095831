import React, { useEffect, useState, useMemo, useContext, useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import { get, isEqual } from 'lodash';

import {
  getLast30DayDeviceDataUsage,
  getLast30DayDeviceUsage,
  getLast30DayPerformanceOverview,
  getLast30DayDeviceEventsList,
  getLast30DaysDateParams,
  getLast30DayDeviceDischarge
} from '../../../../../services/core/devicePerformance';
import { Loading } from '../../../../../components/loading/loading';
import { DataUsage30Days } from './charts/30DayDataUsage';
import { DeviceUsage30Days } from './charts/30DayDeviceUsage';
import { AverageDischarge30Days } from './charts/30DayAverageDischarge';
import { DevicePerformanceOverview } from './devicePerformanceOverview';
import { usePrevious } from '../../../../../lib/usePrevious';
import { EditEventsAction, getActiveEventFilters } from './events/edit-events';
import { IDeviceEventFilterState } from './events/deviceEventsTypes';
import { getPerformanceOverviewEvents, filterDeviceEvents, getDeviceEvents } from './events/deviceEvents';
import { PerformanceEvents, PerformanceView } from './performance-events';
import { DeviceInfoContext } from '../../index';
import { ChartName } from './charts/lib/chartNames';
import { TableName } from '../../../../../components/data-table/lib';
import { useAlignCharts } from '../../../../../lib/useAlignCharts';
import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { RequestInitWithRetry } from '../../../../../lib/request';
import { DeviceEvent } from '../../../../../services/core/eventsTypes';
import { useFeatureTogglesContext } from '../../../../../context/featureToggles';

import './reports.css';

interface IProps {
  eventFiltersDeviceUsage: IDeviceEventFilterState,
  eventFiltersDataUsage: IDeviceEventFilterState,
  eventFiltersAverageDischarge: IDeviceEventFilterState,
  eventFiltersEventsList: IDeviceEventFilterState,
  dispatchChartEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  dispatchTableEventFilters: React.Dispatch<EditEventsAction<TableName>>,
  setDate: (date: string) => void
}

interface IRequestParams {
  deviceId: string,
  to: number,
  from: number
}

function getRequestParams(deviceId: string, dates: { from: number, to: number }): IRequestParams {
  return { deviceId, ...dates };
}

export function Last30DaysReport(props: IProps) {
  const {
    eventFiltersDeviceUsage,
    eventFiltersDataUsage,
    eventFiltersAverageDischarge,
    eventFiltersEventsList,
    dispatchChartEventFilters,
    dispatchTableEventFilters,
    setDate
  } = props;
  const featureToggles = useFeatureTogglesContext();
  const { platformType, timeZoneOffset, id: deviceId } = useContext(DeviceInfoContext);

  const { t } = useTranslation('performance');

  const [allEvents, setAllEvents] = useState([]);
  const [eventsFetched, setEventsFetched] = useState(false);
  const [deviceUsageEvents, setDeviceUsageEvents] = useState([]);
  const prevEventFiltersDeviceUsage = usePrevious(eventFiltersDeviceUsage);
  const [dataUsageEvents, setDataUsageEvents] = useState([]);
  const prevEventFiltersDataUsage = usePrevious(eventFiltersDataUsage);
  const [averageDischargeEvents, setAverageDischargeEvents] = useState([]);
  const prevEventFiltersAverageDischarge = usePrevious(eventFiltersAverageDischarge);

  const { addChart, clearCharts } = useAlignCharts(3);

  const dropsAreEnabled = featureToggles.getDropsEnabledOnDevice(platformType);

  const performanceOverviewEvents = useMemo(() => {
    return getPerformanceOverviewEvents(dropsAreEnabled, true);
  }, [dropsAreEnabled]);

  const deviceEventNames = useMemo(() => {
    return Object.values(getDeviceEvents(platformType, featureToggles)).map(event => event.name);
  }, [platformType, featureToggles]);

  const dates = useMemo(() => {
    return getLast30DaysDateParams(moment.utc(), timeZoneOffset);
  }, [timeZoneOffset]);

  const dataFetcher = useCallback(() => {
    if (deviceId) {
      clearCharts();
      const params = getRequestParams(deviceId, dates);
      return (options: RequestInitWithRetry) => {
        return Promise.all([
          getLast30DayDeviceDataUsage(params)(options),
          getLast30DayDeviceUsage(params)(options),
          getLast30DayDeviceDischarge(params)(options)
        ]);
      };
    }
  }, [clearCharts, deviceId, dates]);

  const {
    loading: dataLoading,
    data: [deviceDataUsage, deviceUsageData, averageDischargeData]
  } = useWorldRequest(dataFetcher, { initialLoading: true, initialData: [[], [], []] });

  const onEventsFetched = useCallback((events: DeviceEvent[]) => {
    setAllEvents(events);
    setEventsFetched(true);
  }, []);

  const eventsDataFetcher = useCallback(() => {
    if (deviceId) {
      const params = getRequestParams(deviceId, dates);
      return getLast30DayDeviceEventsList({ ...params, events: deviceEventNames });
    }
  }, [deviceId, dates, deviceEventNames]);

  const {
    loading: eventsLoading,
  } = useWorldRequest(eventsDataFetcher, { initialLoading: false, initialData: [], onSuccess: onEventsFetched });

  const overviewDataFetcher = useCallback(() => {
    if (deviceId) {
      const params = {
        ...getRequestParams(deviceId, dates),
        events: performanceOverviewEvents
      };
      return getLast30DayPerformanceOverview(params);
    }
  }, [deviceId, dates, performanceOverviewEvents]);

  const {
    data: overviewData
  } = useWorldRequest(overviewDataFetcher, { initialData: undefined });

  const activeEventsDeviceUsage = useMemo(() => getActiveEventFilters(eventFiltersDeviceUsage.appliedEventFilters), [eventFiltersDeviceUsage.appliedEventFilters]);
  const activeEventsDataUsage = useMemo(() => getActiveEventFilters(eventFiltersDataUsage.appliedEventFilters), [eventFiltersDataUsage.appliedEventFilters]);
  const activeEventsAverageDischarge = useMemo(() => getActiveEventFilters(eventFiltersAverageDischarge.appliedEventFilters), [eventFiltersAverageDischarge.appliedEventFilters]);

  useEffect(() => {
    const [deviceUsageEvents, dataUsageEvents, averageDischargeEvents] = filterDeviceEvents(
      allEvents, activeEventsDeviceUsage, activeEventsDataUsage, activeEventsAverageDischarge
    );
    setDeviceUsageEvents(deviceUsageEvents);
    setDataUsageEvents(dataUsageEvents);
    setAverageDischargeEvents(averageDischargeEvents);
  }, [activeEventsAverageDischarge, activeEventsDataUsage, activeEventsDeviceUsage, allEvents]);

  useEffect(() => {
    const deviceUsageFiltersChanged = !isEqual(eventFiltersDeviceUsage.appliedEventFilters, get(prevEventFiltersDeviceUsage, 'appliedEventFilters'));
    const dataUsageFiltersChanged = !isEqual(eventFiltersDataUsage.appliedEventFilters, get(prevEventFiltersDataUsage, 'appliedEventFilters'));
    const averageDischargeFiltersChanged = !isEqual(eventFiltersAverageDischarge.appliedEventFilters, get(prevEventFiltersAverageDischarge, 'appliedEventFilters'));
    const anyFiltersChanged = deviceUsageFiltersChanged || dataUsageFiltersChanged || averageDischargeFiltersChanged;

    if (eventsFetched && anyFiltersChanged) {
      const [deviceUsageEvents, dataUsageEvents, averageDischargeEvents] = filterDeviceEvents(
        allEvents, activeEventsDeviceUsage, activeEventsDataUsage, activeEventsAverageDischarge
      );
      deviceUsageFiltersChanged && setDeviceUsageEvents(deviceUsageEvents);
      dataUsageFiltersChanged && setDataUsageEvents(dataUsageEvents);
      averageDischargeFiltersChanged && setAverageDischargeEvents(averageDischargeEvents);
    }
  }, [
    eventFiltersDataUsage.appliedEventFilters, eventFiltersDeviceUsage.appliedEventFilters, eventsFetched,
    prevEventFiltersDataUsage, prevEventFiltersDeviceUsage, activeEventsDeviceUsage, activeEventsDataUsage,
    eventFiltersAverageDischarge.appliedEventFilters, prevEventFiltersAverageDischarge, allEvents,
    activeEventsAverageDischarge,
  ]);

  return (
    <div className="core-device_performance-30-day-report">
      <DevicePerformanceOverview
        header={t('LAST_30DAYS_TEXT')}
        data={overviewData}
        dropsAreEnabled={dropsAreEnabled}
      />
      <div className='last30DaysCharts_container'>
        <Loading isLoading={dataLoading || eventsLoading} transparentOverlay={false}>
          <DeviceUsage30Days
            data={deviceUsageData}
            events={deviceUsageEvents}
            eventFilters={eventFiltersDeviceUsage}
            dispatchEventFilters={dispatchChartEventFilters}
            setDate={setDate}
            onMount={addChart}
          />
          <DataUsage30Days
            data={deviceDataUsage}
            events={dataUsageEvents}
            eventFilters={eventFiltersDataUsage}
            dispatchEventFilters={dispatchChartEventFilters}
            setDate={setDate}
            onMount={addChart}
          />
          <AverageDischarge30Days
            data={averageDischargeData}
            events={averageDischargeEvents}
            eventFilters={eventFiltersAverageDischarge}
            dispatchEventFilters={dispatchChartEventFilters}
            setDate={setDate}
            onMount={addChart}
          />
        </Loading>
      </div>
      <PerformanceEvents eventFilters={eventFiltersEventsList} dispatchEventFilters={dispatchTableEventFilters} {...dates} currentView={PerformanceView.last30days} />
    </div>
  );
}
