import * as React from 'react';
import { ITabComponentType, IBaseTabProps } from './tab-panel';
import { Link } from 'react-router-dom';

export interface ITabProps extends IBaseTabProps {
  id?: string,
  title: string | React.ReactElement,
  link: string
}

function isTabInitialised(index: any, selected: any): boolean {
  return !(index === undefined || selected === undefined);
}

export class LinkTab extends React.Component<ITabProps> {
  //used for component identification
  public static Tag = ITabComponentType.Tab;

  public render() {
    if (!isTabInitialised(this.props.index, this.props.selected)) { return null; }

    let linkClass = this.props.selected ? "nav-link active show" : "nav-link show";
    if (typeof this.props.title === "string") {
      linkClass += " centered";
    }

    return <li id={this.props.id + "-tab"} className="nav-item" data-type="tab">
      <Link to={this.props.link} className={linkClass} role="tab" aria-selected="true">
        {this.props.title}
      </Link>
    </li>;
  }
}
