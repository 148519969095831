import React, { useMemo } from "react";
import DetailsInner from './detailsInner';

import { useAppContext } from '../../containers/battery/index';

function DetailsOuter(props: any) {
  const context = useAppContext();

  return useMemo(() => {
    return (
      <DetailsInner {...{
        id: context.getSelectedDevice(),
        deviceView: context.getSelectedView(),
        batteryIndex: context.getSelectedBatteryIndex()
      }} productName={props.productName} />);
  }, [context, props.productName]);
}

export default DetailsOuter;
