import { TTypedTFunction } from "@lib/useTypedTranslation";
import { Filter } from "../../../components/timePeriodAndFilterPicker/useFilterPicker";
import { ITranslationKeys } from "components/i18n/keys";

export const getTitle = (
  params: {
    filter: Partial<Filter>,
    t: TTypedTFunction<"coreProcesses" | "translation">,
    translationKey: 'DEVICE_LIST_TITLE' |
      'SECTION_TITLE_UTILIZATION' |
      'SECTION_TITLE_USAGE' |
      'SECTION_TITLE_CPU_USAGE',
    version?: string,
    sharedUserId?: string
  }
) => {
  const { filter, t, translationKey, version, sharedUserId } = params;

  let filterTranslation = t('ALL_DEVICES', { context: 'lowercase', ns: 'coreProcesses' });
  if (filter.group?.value) {
    filterTranslation = t('GROUP_FILTER', { name: filter.group?.value, ns: 'coreProcesses' });
  } else if (filter.homeLocation?.value.name) {
    filterTranslation = t('HOME_LOCATION_FILTER', { name: filter.homeLocation?.value.name, ns: 'coreProcesses' });
  } else if (filter.zone?.value.name) {
    filterTranslation = t('ZONE_FILTER', { name: filter.zone?.value.name, ns: 'coreProcesses' });
  } else if (filter.group?.value === null) {
    filterTranslation = t('NO_GROUP_FILTER', { ns: 'coreProcesses' });
  } else if (filter.homeLocation?.value.name === null) {
    filterTranslation = t('NO_HOME_LOCATION_FILTER', { ns: 'coreProcesses' });
  }

  if (translationKey === 'DEVICE_LIST_TITLE') {
    filterTranslation = filterTranslation[0].toUpperCase() + filterTranslation.substring(1);
  }

  return t(
      translationKey as keyof ITranslationKeys['coreProcesses'],
      {
        filter: filterTranslation,
        version,
        context: version ?
          'withVersion'
          :
          '',
        ns: 'coreProcesses',
        sharedUserId,
        versionFilter: t('VERSION_FILTER', { "version": version, ns: 'coreProcesses' }) });
};
