import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { Placement } from '@popperjs/core';

import './tooltip.css';

export interface TooltipProps {
  children: React.ReactNode,
  popperContent: React.ReactNode,
  placement?: Placement,
  offset?: number,
  popoverPortalSelector?: string, // needs to be set if you want to use createPortal and set different location in the DOM for children, to ensure that content is rendered above other elements
  className?: string
}

export function Tooltip(props: TooltipProps) {
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowRef, setArrowRef] = useState(null);

  const popperSide = props.placement || 'top';
  const popperOffset = props.offset || 5; // avoid overlap on reference element
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popperSide,
    modifiers: [
      {
        name: 'arrow',
        options: { element: arrowRef }
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, popperOffset]
        }
      }],
    strategy: 'fixed'
  });

  useEffect(() => {
    const eventListener = () => {
      setShowPopper(false);
    };
    document.addEventListener('scroll', eventListener, true);
    return () => {
      document.removeEventListener('scroll', eventListener, true);
    };
  }, []);

  let popover = (
    <div ref={setPopperElement} className={`tooltip_popover-element ${popperSide}`} style={{ ...styles.popper }} {...attributes.popper}>
      <div ref={setArrowRef} style={styles.arrow} className="tooltip-arrow" />
      <div className='tooltip_popover-content'>{props.popperContent}</div>
    </div>
  );

  if (props.popoverPortalSelector) {
    if (document.querySelector(props.popoverPortalSelector)) {
      popover = createPortal(popover, document.querySelector(props.popoverPortalSelector));
    }
  }

  return (
    <>
      <div ref={setReferenceElement} className={`${props.className} tooltip_reference-element`} onMouseOver={() => setShowPopper(true)} onMouseOut={() => setShowPopper(false)}>
        {props.children}
      </div>
      {showPopper && popover}
    </>
  );
}
