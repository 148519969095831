import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { isNil, get, isEmpty } from 'lodash';
import { useLocation } from 'react-router';

import Card from '../../../components/card/card';
import { TabPanel } from '../../../components/tabs/tab-panel';
import { TabPanelHeader } from '../../../components/tabs/tab-panel-header';
import { Tab } from '../../../components/tabs/tab';
import { NoSelectionOverlay } from '../../../components/card/noSelectionOverlay';
import { BatteryDetailsInformation } from './batteryDetailsInformation';
import { BatteryChargeLevel } from './batteryChargeLevel';
import { RoundedLabel } from '../../../components/labels/roundedLabel';
import { useProductsContext } from '../../../context/products';
import { productNames } from '../../app/productsWrapper';

import './styles.css';
import '../../battery-devices/compositions/legacyDetailsCard.css';

export interface IProps {
  id: string,
  data: any,
  isLoading?: boolean
}

export function BatteryDetailsCard(props: IProps) {
  const { t } = useTranslation();

  const getStatusLabel = (data: any) => {
    if (!data?.batteryStatus || data.batteryStatus === 'green') {
      return <RoundedLabel title={t('NO_PROBLEMS')} icon="fas fa-check" type="green" />;
    }

    if (data.batteryStatus === 'yellow') {
      if (isNil(data.capacityFactor)) {
        return <RoundedLabel title={t('CYCLE_COUNT_WARNING')} icon="fas fa-exclamation-triangle" type="yellow" />;
      }
      return <RoundedLabel title={t('HEALTH_WARNING')} icon="fas fa-exclamation-triangle" type="yellow" />;
    }

    if (data.batteryStatus === 'red') {
      if (isNil(data.capacityFactor)) {
        return <RoundedLabel title={t('HIGH_CYCLE_COUNT')} icon="fas fa-times-circle" type="red" />;
      }
      return <RoundedLabel title={t('POOR_HEALTH')} icon="fas fa-times-circle" type="red" />;
    }
  };

  const getBatteryId = (data: any) => {
    const serial = get(data, 'serialNumber');
    return isNil(serial) ? '' : `(${serial})`;
  };

  const underWarranty = props.data?.underWarranty && <RoundedLabel title={t('UNDER_WARRANTY', { ns: 'batteryEssentials' })} icon="fas fa-shield-alt" type="blue" />;
  const statusLabel = getStatusLabel(props.data);

  const batteryId = getBatteryId(props.data);
  const product = useProductsContext();
  const productTitle = product[productNames.batteryEssentials].title;
  const header = t('NO_SELECTION_HEADER', { title: productTitle, ns: 'batteryEssentials' }) + " - " + t('BATTERY_FIRST_CAPITAL_other', { ns: 'translation' });

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const batteryNotFound = (props.data === '-' || isEmpty(props.data)) && params.has('id') && props.isLoading === false;

  return (
    <div className="details-card battery" data-id={String(get(props.data, 'id')).replace(/\s+/g, '-')}>
      <NoSelectionOverlay
        show={(props.id === '-' || batteryNotFound) && props.isLoading === false}
        icon={batteryNotFound ? null : "fas fa-battery-three-quarters"}
        header={batteryNotFound ? t('BATTERY_NOT_FOUND', { ns: 'batteryEssentials' }) : header}
        noSelectionText={batteryNotFound ? t('BATTERY_NOT_FOUND_BODY', { ns: 'batteryEssentials' }) : [t('NO_SELECTION_BODY_UPPER', { ns: 'batteryEssentials' }), t('NO_SELECTION_BODY_LOWER', { ns: 'batteryEssentials' })]}
        opaque={true}
      />
      <Card noPadding={true}>
        <TabPanel>
          <TabPanelHeader>
            <div className="details-tab-header">
              <span className="selection-header">
                {t('BATTERY_STATUS_HEADER', { ns: 'batteryEssentials' })} {batteryId}
              </span>
              {statusLabel}
              {underWarranty}
            </div>
          </TabPanelHeader>
          <Tab id="Information" title={t('INFORMATION')}>
            <BatteryDetailsInformation
              key={'details' + props.id}
              id={props.id}
              data={props.data}
            />
          </Tab>
          <Tab id="ChargeLevel" title={t('CHARGE_LEVEL')}>
            <BatteryChargeLevel
              key={'details' + props.id}
              id={props.id}
              data={props.data}
            />
          </Tab>
        </TabPanel>
      </Card>
    </div>
  );
}
