import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';

import { InvalidPage } from '../components/errors/invalidPage';
import { AnomalyDevices } from '../containers/core/anomaly/anomalyDevices';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { useMemo } from 'react';

export const CoreAnomalyDevicesRoute = () => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const anomalyId = searchParams.get('anomalyId');
  const utc = searchParams.get('utc');
  const routes = useWorldRoutes();
  const { t } = useTranslation('redirect');

  const anomalyNotFound = <InvalidPage
    message={t('ANOMALY_NOT_FOUND')}
    redirect={{
      message: t('REDIRECT_TO_ANOMALIES'),
      path: routes.core.anomalies
    }}
  />;

  if (!anomalyId) {
    return anomalyNotFound;
  }

  return (
    <AnomalyDevices anomalyId={anomalyId} utc={utc}/>
  );
};
