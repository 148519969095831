import React, { useContext, useMemo, useReducer } from 'react';
import { LanguageAndLocale } from '../services/config/config';

export interface LanguageAndLocaleContextValue extends LanguageAndLocale {
  updateProperties: (config: Partial<LanguageAndLocale>) => void
}

export const LanguageAndLocaleContext = React.createContext<LanguageAndLocaleContextValue>(undefined);

interface LanguageAndLocaleProviderProps {
  initialState: LanguageAndLocale,
  children: React.ReactNode
}

export type LanguageAndLocaleAction =
| { type: 'onLanguageAndLocaleUpdate', propertiesToUpdate: Partial<LanguageAndLocale> };

export function languageAndLocaleReducer(state: LanguageAndLocale, action: LanguageAndLocaleAction): LanguageAndLocale {
  if (action.type === 'onLanguageAndLocaleUpdate') {
    const { propertiesToUpdate } = action;
    const updatedContext = {
      language: propertiesToUpdate.language || state.language,
      locale: propertiesToUpdate.locale || state.locale
    };
    return updatedContext;
  }
  return state;
}

export const LanguageAndLocaleProvider = ({ children, initialState }: LanguageAndLocaleProviderProps) => {
  const [state, dispatch] = useReducer(languageAndLocaleReducer, initialState);

  const contextFunctions = useMemo(() => {
    const updateProperties = (propertiesToUpdate: Partial<LanguageAndLocale>) => {
      return dispatch({ type: 'onLanguageAndLocaleUpdate', propertiesToUpdate });
    };

    return {
      updateProperties
    };
  }, [dispatch]);

  const contextValue: LanguageAndLocaleContextValue = {
    ...state,
    ...contextFunctions
  };

  return (
    <LanguageAndLocaleContext.Provider value={contextValue}>
      {children}
    </LanguageAndLocaleContext.Provider>
  );
};

export function useLanguageAndLocaleContext() {
  const context = useContext(LanguageAndLocaleContext);
  if (context === undefined) {
    throw new Error('useLanguageAndLocaleContext must be used within a LanguageAndLocaleProvider');
  }
  return context;
}
