import React, { useState, useEffect, useContext, useCallback } from 'react';
import moment from 'moment';
import { isUndefined } from 'lodash';
import { useTranslation } from '@lib/useTypedTranslation';

import { IProcessesOverviewData, getProcessesOverview, getProcessesList, getProcessUsage, IProcessUsageData, IProcessData, IProcessListData } from '../../../../../services/core/deviceProcesses';
import { formatValue } from '../../../../../components/data-table/dataFormatters';
import { Spinner } from '../../../../../components/loading/loadingSpinner';
import { OverviewDashboard } from '../../../../../components/overview/overview';
import { Loading } from '../../../../../components/loading/loading';
import { useTableReducer, InitialTableState } from '../../../../../components/data-table/lib';
import { ProcessUsage30Days, Series } from './30DayProcessUsage';
import { DeviceInfoContext } from '../../index';
import { useUserSettingsContext } from '../../../../../context/userSettings';
import { valueof } from '../../../../../lib/typeUtils';
import { useWorldRequest } from '../../../../../lib/useWorldRequest';
import { useDeviceProcessesListColumns } from './useDeviceProcessesListColumns';
import { List } from '../../../../../components/list/list';
import styled from 'styled-components';
import { NoSelectionOverlay } from '../../../../../components/card/noSelectionOverlay';
import { getRelativeDailyDeviceDateParams, getRelativeHourlyDeviceDateParams } from '../../../../../lib/dateParams';
import './device-processes.css';
import { ChartTimePeriod } from '../../deviceFormat';


const StyledSubtitle = styled.div`
  font-weight: 400;
  padding-left: 1.875rem;
`;

StyledSubtitle.displayName = 'styledSubtitle';

export const tableName = 'deviceProcesses';
export const defaultPageSize = 20;

export function DeviceProcesses() {
  const { id: deviceId, timeZoneOffset: tzOffset } = useContext(DeviceInfoContext);
  const timeZoneOffset = tzOffset || 0;
  const { tablePageSizes } = useUserSettingsContext();
  const { t } = useTranslation(['deviceProcesses', 'timeState']);
  const [chartProcessId, setChartProcessId] = useState<string>();
  const [processUsageData, setProcessUsageData] = useState(undefined);
  const [chartTimePeriod, setChartTimePeriod] = useState(ChartTimePeriod.last30Days);
  const [series, setSeries] = useState(Series.usage);
  const [fetchedChartTimePeriod, setFetchedChartTimePeriod] = useState(ChartTimePeriod.last30Days);

  const overviewDataFetcher = useCallback(() => {
    return getProcessesOverview(deviceId);
  }, [deviceId]);

  const { data: overviewData } = useWorldRequest(overviewDataFetcher, { initialData: undefined });

  const initialTableState: InitialTableState<IProcessData> = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: { column: 3, direction: 'desc', field: 'lastUsed' },
  };

  const [tableReducerProperties, tableReducerFunctions] = useTableReducer<IProcessData>(tableName, initialTableState);
  const { offset, limit, sort, search, data, total, selectedRow, isLoading } = tableReducerProperties;
  const { onRowSelected, onClearRowSelection } = tableReducerFunctions;
  const processId = data[selectedRow]?.id;

  const onProcessDataFetched = useCallback((data: any) => {
    setChartProcessId(processId);
    setProcessUsageData(data);
    setFetchedChartTimePeriod(chartTimePeriod);
  }, [processId, chartTimePeriod]);

  const processDataFetcher = useCallback(() => {
    if (processId && (chartProcessId !== processId || chartTimePeriod !== fetchedChartTimePeriod)) {

      const dates = chartTimePeriod === ChartTimePeriod.last30Days ?
        getRelativeDailyDeviceDateParams(moment.utc(), timeZoneOffset, 30) :
        getRelativeHourlyDeviceDateParams(moment.utc(), timeZoneOffset, chartTimePeriod === ChartTimePeriod.last48Hours ? 2 : 7);
      return getProcessUsage({ processId, ...dates, bucketByHour: chartTimePeriod !== ChartTimePeriod.last30Days });
    }
  }, [processId, chartProcessId, timeZoneOffset, chartTimePeriod, fetchedChartTimePeriod]);


  const { loading: isDashboardLoading } = useWorldRequest(processDataFetcher, { initialLoading: false, initialData: undefined, onSuccess: onProcessDataFetched });

  const processListFetcher = useCallback(() => {
    return getProcessesList({ deviceId, limit, offset, sort: { field: sort.field, order: sort.direction }, search });
  }, [deviceId, limit, offset, search, sort.direction, sort.field]);

  useEffect(() => {
    if (chartProcessId && isUndefined(selectedRow) && !isLoading) {
      const processIndex = data.findIndex(process => process.id === chartProcessId);
      if (processIndex !== -1) {
        onRowSelected(processIndex);
      }
    }
  }, [onRowSelected, data, chartProcessId, selectedRow, isLoading]);

  const clearChartData = () => {
    setProcessUsageData(undefined);
    setChartProcessId(undefined);
    setChartTimePeriod(ChartTimePeriod.last30Days);
    setFetchedChartTimePeriod(ChartTimePeriod.last30Days);
  };

  const handleRowSelected = (rowIndex: number) => {
    if (rowIndex === selectedRow) {
      // close chart if row deselected
      clearChartData();
    }
    onRowSelected(rowIndex);
  };

  const translationItems = {
    statusText: {
      text: t('DEVICE_PROCESS_TOTAL', { ns: 'deviceProcesses', count: total })
    },
    tableTitle: {
      text: `${t('PROCESSES_ON_DEVICE', { ns: 'deviceProcesses', count: total })} (${total})`
    }
  };

  const columns = useDeviceProcessesListColumns();

  return (
    <div className="core_device_processes_composition">
      <div className="inner_card">
        <Loading isLoading={isDashboardLoading} transparentOverlay={false}>
          <Dashboard overviewData={overviewData} chartData={processUsageData} />
        </Loading>
      </div>
      <div>
        <List<IProcessData, IProcessListData, 'totalCount', 'list', 'id'>
          dataId='core-device-processes-list'
          dataTableEnabled={true}
          customHeader={false}
          countDataPath='totalCount'
          listDataPath='list'
          hasCheckboxes={false}
          hasFilters={false}
          hasSearch
          translationItems={translationItems}
          columns={columns}
          fetcher={processListFetcher}
          tableReducerFunctions={tableReducerFunctions}
          tableReducerProperties={tableReducerProperties}
          useRequestHook={useWorldRequest}
          options={{ rowIdDataPath: 'id' }}
          onRowSelected={handleRowSelected}
          selectedRow={selectedRow}
          highlight
        />
      </div>
    </div>

  );

  function Dashboard(props: { overviewData: IProcessesOverviewData, chartData: IProcessUsageData }): any {
    const { overviewData, chartData } = props;
    const { t } = useTranslation('deviceProcesses');

    const closeChart = () => {
      onClearRowSelection();
      clearChartData();
    };

    if (chartData) {

      return (
        <>
          <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns: "translation" })} show={chartData.processUsageData.length === 0} />
          <div className="core-device-content_fixture">
            <ProcessUsage30Days
              data={chartData}
              handleClose={closeChart}
              chartTimePeriod={chartTimePeriod}
              setChartTimePeriod={setChartTimePeriod}
              series={series}
              setSeries={setSeries}
            />
          </div>
        </>
      );
    }

    const formatIfLoaded = (value: valueof<IProcessData>) => {
      return overviewData ? formatValue(value) : <Spinner text='' />;
    };

    const processCount = overviewData ? overviewData.processCount : 0;
    const usedMonthCount = overviewData ? overviewData.usedMonthCount : 0;
    const used14DaysCount = overviewData ? overviewData.used14DaysCount : 0;
    const usedDayCount = overviewData ? overviewData.usedDayCount : 0;

    const labelsArray = [
      { label: t('DETECTED_ON_DEVICE', { count: processCount }), value: formatIfLoaded(overviewData?.processCount) },
      { label: t('USED_PROCESSES', { count: usedMonthCount, days: 30 }), value: formatIfLoaded(overviewData?.usedMonthCount) },
      { label: t('USED_PROCESSES', { count: used14DaysCount, days: 14 }), value: formatIfLoaded(overviewData?.used14DaysCount) },
      { label: t('USED_TODAY', { count: usedDayCount }), value: formatIfLoaded(overviewData?.usedDayCount) }
    ];

    return (
      <>
        <div className={`core-device_overview core-device_content ${overviewData ? 'data-loaded' : ''}`}>{t('PROCESSES_TITLE')}</div>
        <StyledSubtitle data-id="core-device_content--subtitle">{t('PROCESSES_SUBTITLE')}</StyledSubtitle>
        <div className="core-device-content_fixture"><OverviewDashboard items={labelsArray} /></div>
      </>
    );
  }
}
