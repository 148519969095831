import { isNumber, join, map } from 'lodash';
import { Auth } from '@aws-amplify/auth';
import fetchRetryBuilder from 'fetch-retry';
import { fetch as whatwgFetch } from 'whatwg-fetch';

import { devLogger } from './logger';
import { omitShallow } from './omitShallow';

// use native browser implementation if it supports aborting, q.v. https://www.npmjs.com/package/whatwg-fetch#aborting-requests
export const getAbortableFetch = () => {
  return ('signal' in new Request('')) ? window.fetch : whatwgFetch;
};
export const abortableFetch = getAbortableFetch();

const INTERNAL_QUERY_TOKEN = [1484253836, 30215, 17289, 40363, 61875611152917];

export type QueryStringMap = { [key: string]: string | number | boolean | null };

export interface IRequestProps {
  path: string,
  method?: string,
  qs?: QueryStringMap,
  body?: object,
  internalQuery?: boolean
}

const getInternalQueryToken = () => {
  return join(map(INTERNAL_QUERY_TOKEN, (part) => part.toString(16)), '-');
};

export const getHeaders = async (internalQuery?: boolean): Promise<{ [key: string]: string }> => {
  if (internalQuery) {
    return { token: getInternalQueryToken() };
  }

  try {
    const session = await Auth.currentSession();

    if (!session) {
      throw new Error('No current session');
    }

    const accessToken = await session.getAccessToken();

    if (!accessToken) {
      throw new Error('No access token');
    }

    const jwtToken = accessToken.getJwtToken();

    if (!jwtToken) {
      throw new Error('No jwt token');
    }

    return { authorization: `Bearer ${jwtToken}` };
  } catch (err) {
    throw new Error(`Error getting token: ${err.message || err}`);
  }
};

export const getUri = (path: string) => {
  // test: test.ng.elemez.com/essentials/30/index.html
  // test api: test.ng.elemez.com/essentials/api/get...

  // prod: elemez.com/essentials/index.html
  // prod api: elemez.com/essentials/api

  let serverName = window.location.origin + '/essentials';

  if (process.env.REACT_APP_ELEMEZ_NEXTGEN_ENDPOINT) {
    serverName = process.env.REACT_APP_ELEMEZ_NEXTGEN_ENDPOINT;
    serverName = serverName.replace('<hostname>', window.location.hostname);
  }

  return serverName + path;
};

export const stringifyQs = (qs: QueryStringMap): { [key: string]: string } => {
  return Object.entries(qs)
    .filter(([_, v]) => v !== undefined)
    .reduce((acc, [key, value]) => {
      acc[key] = value === false ? '' : String(value);
      return acc;
    }, {} as { [key: string]: string });
};

export type RetryFetch = ReturnType<typeof fetchRetryBuilder>;
export type RequestInitWithRetry = Parameters<ReturnType<typeof fetchRetryBuilder>>[1];

export const MAX_RETRIES = 3;

export const getFetchRetryOn = (maxRetriesInput?: number) => {
  return (attempt: number, _: Error | null, response: Response | null) => {
    const retries = isNumber(maxRetriesInput) ? maxRetriesInput : MAX_RETRIES;
    if (response?.status >= 500 && attempt < retries) {
      devLogger.warn(`retrying ${response.url}: status ${response.status}, attempt ${attempt + 1}`);
      return true;
    }
  };
};

export const fetchRetryDelay = (attempt: number) => {
  const baseDelay = 200;
  return Math.pow(2, attempt) * baseDelay; // 200, 400, 800
};

export const getRetryFetch = (maxRetries?: number): RetryFetch => {
  return lib.fetchRetryBuilder(abortableFetch, {
    // NB default retry options can be overridden in options param of fetchClient
    retryOn: dependencies.getFetchRetryOn(maxRetries),
    retryDelay: fetchRetryDelay
  });
};

export const request = async <T = any>(
  params: IRequestProps,
  options: RequestInitWithRetry = {},
  maxRetries?: number
): Promise<T> => {
  const { path, method, body, qs, internalQuery } = params;
  let authHeaders;
  try {
    authHeaders = await dependencies.getHeaders(internalQuery);
  } catch (err) {
    // TODO eventually this reload could be handled in the react error boundary
    window.location.reload();
    throw err;
  }
  const headers = new Headers({
    ...authHeaders,
    Accept: 'application/json',
    ...options.headers
  });
  const config = {
    method: (method && method.toUpperCase()) || (body ? 'POST' : 'GET'),
    headers,
    ...omitShallow(options, 'headers'),
  };
  if (body) {
    try {
      config.body = JSON.stringify(body);
    } catch (err) {
      throw new Error(`Invalid body: ${err.message}`);
    }
    headers.append('Content-Type', 'application/json');
  }
  const url = new URL(dependencies.getUri(path));
  if (qs) {
    const stringQs = dependencies.stringifyQs(qs);
    url.search = new URLSearchParams(stringQs).toString();
  }
  const response = await dependencies.getRetryFetch(maxRetries)(url.toString(), config);
  // whatwg-fetch Response does not implement the body property,
  // but uses a number of alternative 'private' properties, including '_bodyInit'
  const whatwgFetchBody = (response as any)._bodyInit;
  if (response.ok) {
    if (response.body || whatwgFetchBody) {
      return response.json();
    }
    return;
  } else {
    let responseMessage = '';
    if (response.body || whatwgFetchBody) {
      //cloning the response to access any of the body streams after json call
      const responseClone = response.clone();
      try {
        responseMessage = JSON.stringify(await response.json()); //TODO use custom error class to hold json response
      } catch (err) {
        try {
          responseMessage = await responseClone.text();
        } catch (err) {
          responseMessage = 'unknown error';
        }
      }
    }
    throw new Error(`${response.status}: ${response.statusText} ${responseMessage}`);
  }
};

/** external dependencies to spy on */
export const lib = {
  fetchRetryBuilder,
};

export const dependencies = {
  getHeaders,
  getUri,
  stringifyQs,
  getRetryFetch,
  getFetchRetryOn
};
