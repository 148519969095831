import * as React from "react";

import { UseFormRegisterReturn } from "react-hook-form";

import { Form } from "react-bootstrap";
import styled from "styled-components";

const StyledSelect = styled(Form.Select)`
  padding: 2px;
  font-size: inherit;
  border-radius: 3px;
  background-color: #f8f9fb;
  border: 1px solid #e5e7ed;
  color: #6e6e6e;
  margin: 0;
  box-shadow: none;

&::after {
  margin-left: 10px;
}

&:focus {
  outline: auto;
}

.dropdown-item {
  padding: 0.375rem 1rem;
  cursor: pointer;
  font-size: var(--font-size-14px);
}

.dropdown-item:hover {
  color: #1f71a8;
  background-color: #e7f2fc;
}

.dropdown-menu {
  padding: 0.1em 0;
  max-height: 300px;
  overflow: auto;
  min-width: 4rem;
}
` as typeof Form.Select;

export interface IItem<Value extends string> {
  value: Value,
  label: string
}

export const SelectInput = <Value extends string>(props: {
  label: string,
  placeholder?: string,
  disabled?: boolean,
  register?: UseFormRegisterReturn<string>,
  className?: string,


  items: IItem<Value>[],
  selectedValue?: Value,
  onItemSelected: (value: Value) => void
}) => {
  return <StyledSelect
    className={props.className}
    aria-label={props.label}
    onChange={e => props.onItemSelected(e.target.value as Value)}
    disabled={props.disabled}
    value={props.selectedValue || ''}
    {...props.register}
  >
    <option key='blankChoice' hidden value=''>-</option>
    {props.items.map(item => <option
      key={item.value}
      value={item.value}
    >
      {item.label}
    </option>)}
  </StyledSelect>;

};
