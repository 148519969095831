import { Moment } from 'moment';
import moment from 'moment';

/** Return `from` and `to` params for relative queries in device local time
 * for data showing daily data, where we are looking for x days worth of data including today
 */
export const getRelativeDailyDeviceDateParams = (now: Moment, timeZoneOffset: number | null, days: number) => {
  const localNow = moment.utc(now).add(timeZoneOffset || 0, 'minutes');
  return {
    from: moment.utc(localNow).startOf('day').subtract(days - 1, 'days').valueOf(),
    to: moment.utc(localNow).endOf('hour').valueOf()
  };
};

/** Return `from` and `to` params for relative queries in device local time
 * for data showing hourly data, where we are looking for 24 hours * x days worth of data
 */
export const getRelativeHourlyDeviceDateParams = (now: Moment, timeZoneOffset: number | null, days: number) => {
  const localNow = moment.utc(now).add(timeZoneOffset || 0, 'minutes');
  return {
    from: moment.utc(localNow).startOf('hour').subtract((24 * days) - 1, 'hours').valueOf(),
    to: moment.utc(localNow).endOf('hour').valueOf()
  };
};

/** Return `from` and `to` params for relative queries
 * for data showing hourly data, where we are looking for 24 hours * x days worth of data
 */
export const getTimePeriodParams = (days: number) => {
  const now = moment();

  return {
    from: moment.utc(now).subtract(days, 'days').valueOf(),
    to: moment.utc(now).valueOf()
  };
};
