import React, { useContext, useMemo, useReducer } from 'react';

import { BatteryTypeSettings } from '../services/config/config';

export interface BatteryTypeSettingsContextValue {
  batteryTypeSettings: BatteryTypeSettings[],
  updateProperties: (updates: BatteryTypeSettings[]) => void
}

export const BatteryTypeSettingsContext = React.createContext<BatteryTypeSettingsContextValue>(undefined);

interface BatteryTypeSettingsProviderProps {
  initialState: BatteryTypeSettings[],
  children: React.ReactNode
}

export type BatteryTypeSettingsAction =
| { type: 'onBatteryTypeSettingsUpdate', updates: BatteryTypeSettings[] };

export function batteryTypeSettingsReducer(state: BatteryTypeSettings[], action: BatteryTypeSettingsAction): BatteryTypeSettings[] {
  if (action.type === 'onBatteryTypeSettingsUpdate') {
    const { updates } = action;
    return updates;
  }
  return state;
}

export const BatteryTypeSettingsProvider = ({ children, initialState }: BatteryTypeSettingsProviderProps) => {
  const [state, dispatch] = useReducer(batteryTypeSettingsReducer, initialState);

  const contextFunctions = useMemo(() => {
    const updateProperties = (updates: BatteryTypeSettings[]) => {
      return dispatch({ type: 'onBatteryTypeSettingsUpdate', updates });
    };

    return {
      updateProperties
    };
  }, [dispatch]);

  const contextValue: BatteryTypeSettingsContextValue = {
    ...contextFunctions,
    batteryTypeSettings: state
  };

  return (
    <BatteryTypeSettingsContext.Provider value={contextValue}>
      {children}
    </BatteryTypeSettingsContext.Provider>
  );
};

export function useBatteryTypeSettingsContext() {
  const context = useContext(BatteryTypeSettingsContext);
  if (context === undefined) {
    throw new Error('useBatteryTypeSettingsContext must be used within a BatteryTypeSettingsProvider');
  }
  return context;
}
