import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { mapValues, mapKeys, isNil, map, get, isEmpty, Dictionary, isEqual, forEach, find } from 'lodash';
import { useRouteMatch, useLocation } from 'react-router-dom';

import { IListCategory } from '../../components/listCard/list';
import { BatteryCentricService, IBatteryFilters, ISmartBatteryStatus } from '../../services/shared/battery/batteryCentricService';
import { FilterSelectionControl, IFilterGroup } from '../../components/filters/filterSelectionControl';
import { ISortResult, VIEW } from '../../services/shared/common';
import { getGroups, getHomeLocations, IGroups, IHomeLocation } from '../../services/config/config';
import { DataLoading } from '../../components/loading/dataLoading';
import { Estate, IEstateCategory } from '../../components/chart/estate';
import { BatteryDetailsCard } from './compositions/batteryDetailsCard';

import { DataTable } from '../../components/data-table/dataTable';
import { PaginationBar } from '../../components/data-table/pagination';
import ETableHeader from '../../components/table/header';
import Header from '../../components/listCard/header';
import { InitialTableState, useTableReducer } from '../../components/data-table/lib';
import { useUserSettingsContext } from '../../context/userSettings';
import { formatBatteryWarrantyIcon, formatDateLastUpdated, formatPercentage, formatStatus, formatValue } from '../../components/data-table/dataFormatters';
import { IFilterResult } from '../../components/filters/filterLib';
import { StatusText } from '../../components/table/statusText';
import { ITableColumn, SortDirection } from '../../components/data-table/types';
import { FilterStatus, IFilterStatus } from '../../components/filters/filterStatus';
import { Loading } from '../../components/loading/loading';
import { omitShallow } from '../../lib/omitShallow';
import { PageConfig } from '../../routes/battery-route';
import { DeleteSmartBatteries } from '../../components/listCard/deleteSmartBatteries';
import { useCurrentUserContext } from '../../context/currentUser';
import { RequestInitWithRetry } from '../../lib/request';
import { useWorldRequest } from '../../lib/useWorldRequest';

import './index.css';
import { useWorldAction } from '../../lib/useWorldAction';

export type ListCategory = Omit<IListCategory, 'buildStatusMessage'>;

export const AppContext = React.createContext({
  setSelectedSmartBattery: null,
  getSelectedSmartBattery: null,
  getSelectedRow: null,
  batteryStatus: null
});

export const tableName = 'batteryList';
export const defaultPageSize = 20;
const service = new BatteryCentricService();

interface Props {
  view: string,
  selectedId: string,
  selectedTimestamp: number,
  config: PageConfig
}

export function getFilterMap(filters: IFilterResult, homeLocationMap: Dictionary<string>): IBatteryFilters {
  const allFilters: IBatteryFilters = { ...filters, underWarranty: false };
  if (!isEmpty(get(filters, 'underWarranty'))) {
    allFilters.underWarranty = true;
  }
  if (filters && filters.homeLocations) {
    const mappedLocations = map(filters.homeLocations, (loc) => homeLocationMap[`${loc}`]);
    allFilters.homeLocations = mappedLocations;
  }
  return allFilters;
}

export function changeFilterMode(mode: string, setMode: React.Dispatch<React.SetStateAction<string>>) {
  const filterMode = mode === 'List' ? 'Filter' : 'List';
  setMode(filterMode);
}

export function getHomeLocationName(value: string, rowData: ISmartBatteryStatus) {
  return rowData.deviceInfo?.homeLocation ? formatValue(rowData.deviceInfo.homeLocation.name) : "-";
}

const initialTableSortField: string = 'updated';
const initialTableSortColumn: number = 2;
const initialTableSortDirection: SortDirection = 'desc';
const initialSort = { column: initialTableSortColumn, direction: initialTableSortDirection, field: initialTableSortField };

export function BatteryCentricContainer(props: Props) {
  const ns = 'translation';
  const { t } = useTranslation(ns);

  const { tablePageSizes } = useUserSettingsContext();
  const { isEditor, isManager, isAdministrator } = useCurrentUserContext();

  const [selectedId, setSelectedId] = useState(props.selectedId || '-');
  const [idUpdateTimestamp, setIdUpdateTimestamp] = useState(props.selectedTimestamp || 0);
  const [listResetTimestamp, setListResetTimestamp] = useState<number>(undefined);
  const [currentFilter, setCurrentFilter] = useState(undefined);
  const [statusMessage, setStatusMessage] = useState<'BATTERY' | 'SEARCH_MATCH_STATEMENT'>('BATTERY');
  const [resultStatus, setResultStatus] = useState<boolean>(false);
  const [homeLocationMapping, setHomeLocationMapping] = useState<Dictionary<string>>(undefined);
  const [availableFilterGroups, setAvailableFilterGroups] = useState<string[]>(undefined);
  const [availableFilterHomeLocations, setAvailableFilterHomeLocations] = useState<string[]>(undefined);
  const [groupsAndHomeLocationsLoaded, setGroupsAndHomeLocationsLoaded] = useState<boolean>(false);
  const [mode, setMode] = useState('List');
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const doGetList = useWorldAction(service.get);
  const doGetBatteryEstateData = useWorldAction(service.getBatteryEstateData);
  const doGetSmartBatteryDetails = useWorldAction(service.getSmartBatteryDetails);
  const doExport = useWorldAction(service.export);

  const initialTableState: InitialTableState = {
    initialRows: 4,
    limit: tablePageSizes?.[tableName] || defaultPageSize,
    sort: initialSort
  };

  async function loadDetails(): Promise<any> {
    if (selectedId === '-') {
      return '-';
    }
    return doGetSmartBatteryDetails(selectedId);
  }

  async function loadStateCounts(): Promise<any> {
    return await doGetBatteryEstateData({ underWarranty: false });
  }

  function getEstateCategories() {
    const CATEGORIES: IEstateCategory[] = [
      { id: VIEW.red, label: t('REPLACE'), colour: "#DC7F7F", icon: 'fa fa-times-circle circle_red', path: props.config.link + "/replace" },
      { id: VIEW.orange, label: t('WARNING'), colour: "#FBCD76", icon: 'fa fa-exclamation-triangle circle_orange enhanced-warning-icon', path: props.config.link + "/warning" },
      { id: VIEW.green, label: t('GOOD'), colour: "#3FA67D", icon: 'fa fa-check circle_green', path: props.config.link + "/good" }];
    return CATEGORIES;
  }

  function getCategories() {
    const CATEGORIES: ListCategory[] = [
      {
        id: VIEW.all, title: t('ALL'), icon: '', link: `${props.config.link}/all`
      },
      {
        id: VIEW.red, title: t('REPLACE'), icon: 'fa fa-times-circle circle_red', link: `${props.config.link}/replace`
      },
      {
        id: VIEW.orange, title: t('WARNING'), icon: 'fa fa-exclamation-triangle circle_orange', link: `${props.config.link}/warning`
      },
      {
        id: VIEW.green, title: t('GOOD'), icon: 'fa fa-check circle_green', link: `${props.config.link}/good`
      }
    ];
    return CATEGORIES;
  }

  const batteriesHeaderText: { [key: string]: string } = {
    'all': t('All_BATTERIES_TITLE', { ns: 'batteryEssentials' }),
    'green': t('GOOD_BATTERIES_TITLE', { ns: 'batteryEssentials' }),
    'orange': t('WARNING_BATTERIES_TITLE', { ns: 'batteryEssentials' }),
    'red': t('REPLACE_BATTERIES_TITLE', { ns: 'batteryEssentials' })
  };

  const filterGroups: IFilterGroup[] = [
    { id: "groups", filterDisplayName: t('GROUPS_FILTER_HEADER'), valueDisplayName: t('GROUPS'), availableValues: availableFilterGroups },
    { id: "homeLocations", filterDisplayName: t('HOMELOCATIONS_FILTER_HEADER'), valueDisplayName: t('HOME_LOCATION_other'), availableValues: availableFilterHomeLocations },
    { id: "underWarranty", filterDisplayName: t('UNDER_WARRANTY_FILTER_HEADER', { ns: 'batteryEssentials' }), valueDisplayName: t('UNDER_WARRANTY', { ns: 'batteryEssentials' }), availableValues: [t('UNDER_WARRANTY', { ns: 'batteryEssentials' })], icon: "fas fa-shield-alt" }
  ];

  const [
    { offset, limit, total, sort, dataRequestId, data, isLoading, search, selectedRow, checkedRows },
    { onSort, onPageChange, onPageSizeChange, onDataLoaded, onSearch, onRowSelected, onLoading, onCheckAll, onRowChecked }
  ] = useTableReducer<ISmartBatteryStatus>(tableName, initialTableState);

  async function exportList(): Promise<any> {
    return await doExport({ statusType: props.view, limit: 10000, offset: 0, sort: { field: sort.field, order: sort.direction }, search, pinnedId: props.selectedId, filters: getFilterMap(currentFilter, homeLocationMapping) });
  }

  function getStatusFilters(): IFilterStatus[] {
    const statuses: IFilterStatus[] = [];
    forEach(currentFilter, (selectedItems: string[], groupId: string) => {
      const filterGroup = find(filterGroups, { id: groupId });
      const displayState = selectedItems.length > 1 ? `${selectedItems.length} ${filterGroup.valueDisplayName}` : selectedItems[0];
      displayState && statuses.push({ id: groupId, display: displayState });
    });
    return statuses;
  }

  const handleFilterRemoved = (removedFilterGroup: string) => {
    const newFilters = omitShallow(currentFilter, removedFilterGroup);
    setCurrentFilter(newFilters);
  };

  const handleSort = (column: number, direction: SortDirection, info: ITableColumn) => {
    onSort(!isNil(column) ? column : 0, !isNil(direction) ? direction : null, info);
  };

  const handleRowSelected = (rowIndex: number) => {
    const rowDataId = get(data[rowIndex], "id");
    if (rowIndex !== selectedRow) {
      setSelectedId(rowDataId);
      onRowSelected(rowIndex);
    }
  };

  const pinned = React.useMemo(() => {
    let pinned = undefined;
    const sortSet = (sort && !isEqual(sort, initialSort)) ? true : false;
    const filtersSet = (currentFilter && currentFilter?.groups?.length > 0) ? true : false;
    if (!isNil(props.selectedId)) {
      const isCategoryValid = (props.view === 'all' || props.view === undefined);
      pinned = (isCategoryValid && !search && !sortSet && !filtersSet) ? props.selectedId : undefined;
    }
    return pinned;
  }, [currentFilter, props.selectedId, props.view, search, sort]);

  const onGroupsAndHomeLocationsLoaded = useCallback(([groupsRaw, homeLocationsRaw]: [IGroups, IHomeLocation[]]) => {
    const groups = get(groupsRaw, 'groups.nodes', []).map((o: any) => o.name);
    let homeLocations;
    if (homeLocationsRaw) {
      homeLocations = homeLocationsRaw.map((o: any) => isNil(o.name) ? o.id : o.name);
      const homeLocationMapping = mapValues(mapKeys(homeLocationsRaw, (v) => isNil(v.name) ? v.id : v.name), (loc) => loc.id);
      setHomeLocationMapping(homeLocationMapping);
    }
    setAvailableFilterGroups(groups);
    setAvailableFilterHomeLocations(homeLocations);
    setGroupsAndHomeLocationsLoaded(true);
  }, []);

  const groupsAndHomeLocationsFetcher = useCallback(() => {
    return (options: RequestInitWithRetry) => {
      return Promise.all([
        getGroups()(options),
        getHomeLocations()(options)
      ]);
    };
  }, []);

  useWorldRequest(groupsAndHomeLocationsFetcher, { onSuccess: onGroupsAndHomeLocationsLoaded });

  useEffect(() => {
    let selectedIndex = -1;
    if (!isLoading) {
      selectedIndex = data.findIndex(battery => battery?.id === selectedId);
    }
    if (props.selectedId && isNil(selectedRow) && !isLoading) {
      setSelectedId(props.selectedId);
      selectedIndex = 0;
    }
    if (selectedIndex !== selectedRow && !isLoading) {
      onRowSelected(selectedIndex);
    }
  }, [onRowSelected, selectedRow, isLoading, data, selectedId, props.selectedId]);

  useEffect(() => {
    async function getList(pinnedId: string, statusType: string, search: string, limit: number, offset: number, filters: IFilterResult, sort: ISortResult, homeLocationMap: Dictionary<string>) {
      return doGetList({ statusType, search, limit, offset, sort, pinnedId, filters: getFilterMap(filters, homeLocationMap) });
    }

    async function getCount(statusType: string, search: string, filters: IFilterResult, homeLocationMap: Dictionary<string>): Promise<number> {
      const data = await doGetBatteryEstateData(getFilterMap(filters, homeLocationMap), search);
      if (isNil(statusType)) {
        statusType = 'all';
      }
      return data[statusType];
    }

    if (isLoading && homeLocationMapping !== undefined) {
      Promise.all([
        getList(pinned, props.view, search, limit, offset, currentFilter, { field: sort.field, order: sort.direction }, homeLocationMapping),
        getCount(props.view, search, currentFilter, homeLocationMapping)
      ]).then(([list, count]) => {
        onDataLoaded(list, count, dataRequestId);
      });
    }
  }, [onDataLoaded, currentFilter, dataRequestId, homeLocationMapping, isLoading, limit, offset, pinned, props.view, search, sort, doGetList, doGetBatteryEstateData]);

  useEffect(() => {
    if (search) {
      setStatusMessage('SEARCH_MATCH_STATEMENT');
      setResultStatus(true);
    } else {
      setStatusMessage('BATTERY');
      setResultStatus(false);
    }
  }, [search]);

  useEffect(() => {
    if (props.selectedTimestamp && idUpdateTimestamp !== props.selectedTimestamp) {
      setIdUpdateTimestamp(props.selectedTimestamp);
      setListResetTimestamp(props.selectedTimestamp);
    }
  }, [idUpdateTimestamp, props.selectedTimestamp, selectedId]);

  useEffect(() => {
    onLoading();
  }, [onLoading, props.view, props.selectedId, props.selectedTimestamp, currentFilter]);

  useEffect(() => {
    onSearch(undefined);
    setCurrentFilter(undefined);
  }, [props.selectedId, onSearch]);

  const { path } = useRouteMatch();
  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // following condition handles batteries linked to deleted devices
    if (params.has('id') && !props.selectedId && path.endsWith('battery')) {
      setSelectedId('-');
    }
  }, [props.selectedId, path, location.search]);

  const filter = <FilterSelectionControl
    onFilterChange={(result) => { setCurrentFilter(result); setMode('List'); }}
    filterGroups={filterGroups}
    currentFilter={currentFilter}
  />;

  const filterStatus = <FilterStatus
    onNavigateFilters={() => { changeFilterMode(mode, setMode); }}
    selectedFilters={getStatusFilters()}
    onFilterRemoved={handleFilterRemoved}
  />;

  const list = <DataTable
    data={data}
    columns={[
      { id: 'batterySerialNumber', title: t('BATTERY_SERIAL_NUMBER', { ns: 'batteryEssentials' }), dataPath: 'serialNumber', sortable: true, processor: formatBatteryWarrantyIcon, width: '8.5rem' },
      { id: 'batteryPartNumber', title: t('BATTERY_PART_NUMBER', { ns: 'batteryEssentials' }), dataPath: 'partNumber', sortable: true, processor: null, width: '8rem' },
      { id: 'lastSeen', title: t('LAST_SEEN'), dataPath: 'updated', sortable: true, processor: formatDateLastUpdated, width: '5.5rem' },
      { id: 'status', title: t('STATUS'), dataPath: 'batteryStatus', sortable: false, processor: formatStatus, width: '3rem', textAlign: 'center' },
      { id: 'health', title: t('HEALTH', { ns: 'batteryEssentials' }), dataPath: 'capacityFactor', sortable: true, processor: formatPercentage, width: '5rem', textAlign: 'center' },
      { id: 'cycleCount', title: t('CYCLE_COUNT', { ns: 'batteryEssentials' }), dataPath: 'cycleCount', sortable: true, processor: formatValue, width: '7rem', textAlign: 'center' },
      { id: 'manufacturer', title: t('MANUFACTURER'), dataPath: 'deviceInfo.manufacturer', sortable: true, processor: null, width: '7rem' },
      { id: 'model', title: t('MODEL'), dataPath: 'deviceInfo.model', sortable: true, processor: null, width: '5rem' },
      { id: 'serialNumber', title: t('SERIAL_NUMBER'), dataPath: 'deviceInfo.serialNumber', sortable: true, processor: null, width: '7rem' },
      { id: 'imei', title: 'IMEI', dataPath: 'deviceInfo.imei', sortable: true, processor: null, width: '7rem' },
      { id: 'assetTag', title: t('ASSET_TAG'), dataPath: 'deviceInfo.assetTag', sortable: true, processor: null, width: '7rem' },
      { id: 'groups', title: t('GROUPS'), dataPath: 'deviceInfo.group', sortable: false, processor: formatValue, width: '9rem' },
      { id: 'homeLocation', title: t('HOME_LOCATION'), dataPath: 'deviceInfo.homeLocationId', sortable: true, processor: getHomeLocationName, width: '10rem' },
    ]}
    className={isLoading ? '' : 'data-ready'}
    sortDirection={sort.direction}
    sortedColumnIndex={sort.column}
    options={{ rowIdDataPath: 'id' }}
    onSort={(column, direction, info) => handleSort(column, direction, info)}
    onRowSelected={handleRowSelected}
    selectedRow={selectedRow}
    onCheck={isEditor || isManager || isAdministrator ? onRowChecked : undefined}
    onCheckAll={isEditor || isManager || isAdministrator ? onCheckAll : undefined}
    checkedRows={checkedRows}
  />;

  const selectedIdIndex = data?.findIndex(battery => battery?.id === selectedId);
  const selectedRowSet = selectedRow === selectedIdIndex;
  const isAllDataReady = groupsAndHomeLocationsLoaded && !isLoading && selectedRowSet;
  const checkedRowIds = checkedRows.map((e) => data[e]?.id);
  const deleteButtonProps = isEditor || isManager || isAdministrator ? {
    enabled: checkedRows.length > 0,
    checkedRowIds: checkedRowIds,
    onButtonClick: () => setShowDeletePopup(true)
  } : undefined;
  const isBatteryListData = !isLoading && data?.length > 0;

  return (
    <div className={`batteryEssentialsTable_batteries_container ${isAllDataReady ? 'loaded' : ''}`}>
      <div className="row equal batteryCentric_batteries_estate">
        <div className="col-xl-3 col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <DataLoading loadData={loadStateCounts}>
            <Estate
              title={t('BATTERY_ESTATE_OVERVIEW_TITLE')}
              toolLabelSngl={t('BATTERY_FIRST_CAPITAL')}
              toolLabelPlrl={t('BATTERY_FIRST_CAPITAL_other')}
              data={null}
              categories={getEstateCategories()}
              htmlIcons={true}
            />
          </DataLoading>
        </div>
        <div className="batteryCentricDetails_container col-xl-9 col-lg-8 col-md-12 col-sm-12 mt-sm-2 mt-2 mt-lg-0">
          <DataLoading key={`${selectedId}:${idUpdateTimestamp}`} loadData={loadDetails}>
            <BatteryDetailsCard id={selectedId} data={null} />
          </DataLoading>
        </div>
      </div>
      <div className="row batteryEssentialsTable_container batteryCentric" key={listResetTimestamp}>
        <div className="col-md-12">
          <div className={"eTable-display-" + props.view}>
            <ETableHeader>
              <Header
                categories={getCategories()}
                currentCategoryId={props.view}
                onFilterClicked={() => { changeFilterMode(mode, setMode); }}
                onExportClicked={exportList}
                exportButtonDisabled={!isBatteryListData}
                onSearchClicked={(input) => onSearch(input)}
                headerTitle={batteriesHeaderText[props.view]}
                deleteButtonProps={deleteButtonProps} />
            </ETableHeader>
            {showDeletePopup && <DeleteSmartBatteries
              showPopup={showDeletePopup}
              handleClose={() => setShowDeletePopup(false)}
              batteryIds={deleteButtonProps.checkedRowIds}
              onBatteriesDeleted={onLoading}
            />}
            <div className="statusText" style={{ display: currentFilter && !isEqual(currentFilter, {}) && mode === 'List' ? 'block' : 'none' }}>
              {filterStatus}
            </div>
            {mode === 'Filter' && filter}
            <Loading isLoading={isLoading} transparentOverlay={false}>
              <div className='eTable_list' style={{ display: mode === 'List' ? 'block' : 'none' }}>
                {list}
                <PaginationBar
                  offset={offset}
                  limit={limit}
                  total={total}
                  onNewPage={(newOffset) => onPageChange(newOffset)}
                  onNewLimit={(newLimit) => onPageSizeChange(newLimit)}
                >
                  <StatusText
                    searchString={search}
                    total={total}
                    offset={offset}
                    limit={limit}
                    item={t(statusMessage, { count: total })}
                    fullStatusText={resultStatus}
                  />
                </PaginationBar>
              </div>
            </Loading>
          </div>
        </div>
      </div>
    </div>
  );
}
