import * as React from 'react';
import './style.css';

export interface IProps {
  noPadding?: boolean,
  className?: string,
  children: any
}

export const Card = (props: IProps) => {

  const { noPadding, className = '' } = props;
  return (
    <div className={`card ${className}`}>
      <div data-id="card-body" className={`card-body ${noPadding ? 'no-padding' : ''}`}>
        { props.children }
      </div>
    </div>
  );
};

export default Card;
