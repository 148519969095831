import * as React from 'react';
import styled from 'styled-components';
import { Theme } from '../../containers/app/themes';

import { BaseLogo } from '../../components/logo-styles/baseLogo';


interface Props {
  onLogoClick?: () => void
}

const StyledHeader = styled.div`
  ${({ theme }: { theme: Theme }) => `
    background: ${theme.headers.background};
    color: ${theme.headers.text};
  `};
  height: 50px;
  line-height: 50px;
  text-align: center;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 84%, 0.6);
  display: flex;
  justify-content: space-between;
`;

StyledHeader.displayName = 'HeaderContainer';

const StyledLogoContainer = styled.div`
  z-index: 5;
`;

StyledLogoContainer.displayName = 'LogoContainer';

const StyledLogo = styled(BaseLogo)`
  padding-left: 200px;
  margin-top: 14px !important;
  margin-bottom: 10px !important;
  width: 130px;
  height: 26px;
`;

StyledLogo.displayName = 'Logo';

const Header = (props: Props) => (
  <StyledHeader data-id='page-header'>
    <StyledLogoContainer>
      <StyledLogo
        onClick={props.onLogoClick} />
    </StyledLogoContainer>
  </StyledHeader>
);

export default Header;
