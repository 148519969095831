import { isString } from 'lodash';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Theme } from '../../containers/app/themes';

export interface LinkButtonProps {
  to: string | { pathname: string, state: any },
  children: React.ReactNode,
  external: boolean,
  dataId: string,
  className?: string,
  onClick?: () => any
}

const linkStyles = (theme: Theme) => `
  padding: 0.375rem 1.25rem;
  border-radius: 3px;
  color: ${theme.links.color};
  border: 1px solid #c2d1e0;
  line-height: 1.25rem;
  font-size: 0.875rem;
  transition: 0.3s;
  font-weight: 600;
  white-space: nowrap;
  align-self: flex-start;
`;

const StyledInternalLink = styled(NavLink)`
  ${({ theme }) => linkStyles(theme)}
`;
StyledInternalLink.displayName = 'StyledInternalLink';

const StyledExternalLink = styled.a`
  ${({ theme }) => linkStyles(theme)}
`;
StyledExternalLink.displayName = 'StyledExternalLink';

export function LinkButton(props: LinkButtonProps) {
  const classname = props.className || '';

  return props.external ?
    <StyledExternalLink
      data-id={props.dataId}
      className={classname}
      target="_blank"
      href={isString(props.to) ? props.to : props.to.pathname}>
      {props.children}
    </StyledExternalLink> :
    <StyledInternalLink
      data-id={props.dataId}
      className={classname}
      onClick={props.onClick}
      to={props.to}>
      {props.children}
    </StyledInternalLink>;
}
