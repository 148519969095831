import React, { useContext, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { get, groupBy, map, isEqual, pick, range } from 'lodash';
import moment from 'moment';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { useHistory } from 'react-router-dom';
import { History } from "history";
import { TTypedTFunction } from '@lib/useTypedTranslation';

import { AmChart, coreTheme } from '../../../../../../components/chart/amChart';
import { IBatteryLevel } from '../../../../../../services/core/devicePerformance';
import { DeviceEvent } from '../../../../../../services/core/eventsTypes';
import { NoSelectionOverlay } from '../../../../../../components/card/noSelectionOverlay';
import { EditEvents, EditEventsAction, useEditEventsDispatch } from '../events/edit-events';
import { deviceEventFilterGroupNames, IDeviceEventFilterState } from '../events/deviceEventsTypes';
import { getDeviceEventFilterGroups } from '../events/deviceEvents';
import { addEventOverlaysToLineChart } from './lib/eventOverlays';
import { EventsShown } from '../events-shown';
import { DeviceInfoContext } from '../../../index';
import { chartNames, ChartName } from './lib/chartNames';
import { encode } from '../../../../../../lib/base64';
import { ResetZoomButton } from './lib/resetZoomButton';
import { adjustChartAndLegendRatio } from './adjustChartAndLegendRatio';
import { useBrandContext } from '../../../../../../context/brand';
import { useFeatureTogglesContext } from '../../../../../../context/featureToggles';
import { useCurrentWorldContext } from '../../../../../../context/currentWorld';

import './resetZoomButton.css';
import './charts.css';
import '../events/deviceEvents.css';
import { Feature } from '../../../../../app/productsWrapper';
import { useWorldRoutes } from '../../../../../../routes/parts/allWorldRoutes';

export interface ChargeLevelProps {
  dateRange?: { from: number, to: number },
  classname: string,
  data: IBatteryLevel[],
  events?: DeviceEvent[],
  eventFilters: IDeviceEventFilterState,
  dispatchEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  visible: boolean,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export const dependencies = {
  setXAxisLabels,
  createSeries
};

// istanbul ignore next
function createSeries(name: string, lineColour: string, index: number, chart: any, classname: string, t: TTypedTFunction) {
  const colour = am4core.color(lineColour);

  const series = chart.series.push(new am4charts.LineSeries());
  series.data = chart.data[index];
  series.dataFields.dateX = 'local';
  series.dataFields.valueY = 'level';
  series.name = name;
  series.connect = false;
  series.autoGapCount = 4;
  series.stroke = colour;
  series.strokeWidth = 2;
  series.fill = colour;
  series.fillOpacity = 0.2;
  series.tooltipHTML = '';

  series.adapter.add('tooltipHTML', (_: any, target: any) => {
    const tooltip = target.tooltipDataItem as any;
    const date = formatDate(tooltip.dateX);
    return `<div class="tooltip-container_device-charge-level"><span class="device-charge-level-${classname}-tooltip-title">${date}</span>
    </br><span class="device-charge-level-${classname}-tooltip-text"><div class="key" style="border-color: ${lineColour}"></div>${t('CHARGE_LEVEL')} {valueY}</span>
    </br><span class="device-charge-level-${classname}-tooltip-text serialnumber">${t('BATTERY_SN')}: ${name}</span></div>`;
  });

  series.dummyData = { colour };

  return series;
}

function drawXAxisLabels(chart: am4charts.XYChart, interval: number) {
  const xAxis = chart.xAxes.values[0] as am4charts.DateAxis;
  chart.dummyData.xAxisLabelRanges.forEach((range: any) => xAxis.axisRanges.removeValue(range));

  const ranges = [];
  for (const hours of range(0, 24, interval)) {
    const range = xAxis.axisRanges.create();

    const date = moment.utc(xAxis.min).add(hours, 'hours');
    range.date = date.toDate();
    range.label = new am4charts.AxisLabel();
    range.label.dx = -20;
    range.label.text = date.format('LT');

    ranges.push(range);
  }

  return ranges;
}

/**
 * Draws x-axis labels at varying hour intervals.
 *
 * Intended to match the responsive behaviour of the CategoryAxis x-axis labels
 * on the same page, for the device/data usage charts.
 * */
/* istanbul ignore next */
function setXAxisLabels(chart: am4charts.XYChart) {
  const getListener = (interval: number) => {
    return (mql: MediaQueryListEvent | MediaQueryList) => {
      if (mql.matches) {
        chart.dummyData.xAxisLabelRanges = drawXAxisLabels(chart, interval);
      }
    };
  };

  const queries = [
    { selector: '(min-width: 862px)', interval: 2 },
    { selector: '(min-width: 639px) and (max-width: 861px)', interval: 3 },
    { selector: '(min-width: 529px) and (max-width: 638px)', interval: 4 },
    { selector: '(min-width: 462px) and (max-width: 528px)', interval: 5 },
    { selector: '(min-width: 417px) and (max-width: 461px)', interval: 6 },
    { selector: '(min-width: 386px) and (max-width: 416px)', interval: 7 },
    { selector: '(max-width: 385px)', interval: 8 },
  ];

  for (const { selector, interval } of queries) {
    const mql = window.matchMedia(selector);
    const listener = getListener(interval);
    chart.dummyData.mqls.push(mql);
    chart.dummyData.listeners.push(listener);
    mql.addListener(listener);
    listener(mql);  // call listener now to draw labels if mql matches
  }
}

export function redirectToBatteryEssentialsLink(batteryId: string, history: History, baseBatteryPath: string) {
  const base64Id = encode(batteryId);
  history.push(`${baseBatteryPath}?id=${encodeURIComponent(base64Id)}&ts=${moment().valueOf()}`);
}

export function formatDate(date: number) {
  return moment.utc(date).format('LLLL') + ' ' + moment.utc(date).format('LT');
}

export function DeviceChargeLevel(props: ChargeLevelProps) {
  const { classname, eventFilters, dispatchEventFilters, onMount } = props;
  const featureToggles = useFeatureTogglesContext();
  const { features } = useCurrentWorldContext();
  const { isElemez } = useBrandContext();
  const { platformType, timeZoneOffset } = useContext(DeviceInfoContext);
  const { t } = useTranslation(['performance', 'editEvents', 'translation']);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const [dataValidated, setDataValidated] = useState(false);
  const [chart, setChart] = useState(undefined);
  const [xAxisNeedsUpdating, setXAxisNeedsUpdating] = useState(true);
  const baseBatteryPath = useWorldRoutes().batteryEssentials.battery;

  /* this state is needed for the button outside of amcharts,
   as the chart instance + dummyData is not up to date outside of amcharts functions */
  const [zoomedIn, setZoomedIn] = useState(false);

  const history = useHistory();

  const {
    handleUpdate, handleApply, handleApplyAll, handleCancel
  } = useEditEventsDispatch(
    dispatchEventFilters, chartNames.deviceChargeLevel, setLoadingTrue
  );

  const title = t('DEVICE_CHARGE_LEVEL', { ns: 'performance' });

  function setLoadingTrue() {
    setDataValidated(false);
    setChartDataLoading(true);
  }

  function onDataValidated() {
    if (!chartDataLoading) {
      if (xAxisNeedsUpdating && props.data.length > 0) {
        dependencies.setXAxisLabels(chart);
        setXAxisNeedsUpdating(false);
      }
      setDataValidated(true);
    }
  }

  const chartClassname = `core-device_performance-${classname}-device-charge-level ${dataValidated ? 'chart-ready' : ''}`;

  return (
    <div className={chartClassname}>
      <div className="chart-header">
        <div className="chart-header-left">
          <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns: "translation" })} show={props.data.length === 0} />
          <div className="chart-title">{title}</div>
          <ResetZoomButton zoomedIn={zoomedIn} resetZoom={resetZoom} />
        </div>
        <div className="chart-edit-events">
          <EventsShown eventFilters={eventFilters.appliedEventFilters} />
          <EditEvents
            subheader={`‘${title}’`}
            eventGroupNames={deviceEventFilterGroupNames}
            eventFilterGroups={getDeviceEventFilterGroups(platformType, featureToggles)}
            eventFilterState={eventFilters.currentEventFilters}
            handleUpdate={handleUpdate}
            handleApply={handleApply}
            handleApplyAll={handleApplyAll}
            handleCancel={handleCancel}
          />
        </div>
      </div>
      <div className={`chart-and-legend-container`}>
        <div className="chart-container">
          <AmChart
            tag={`${classname}-device-level-chart`}
            chartProvider={createChart}
            onMount={(chart, ...rest) => { onMount(chart); onDataUpdated(chart, ...rest); return chart; } }
            onUpdate={onDataUpdated}
            dataProvider={() => { setChartDataLoading(false); return getData(); }}
            link={pick(props, 'data', 'events', 'visible')}
            onDataValidated={onDataValidated}
            cypressId="last24HoursOrSelectedDateChargeLevel"
          />
        </div>
      </div>
    </div>
  );

  function resetZoom() {
    setZoomedIn(false);
    const xAxis: am4charts.DateAxis = chart.xAxes.values[0];
    xAxis.zoomToDates(new Date(xAxis.min), new Date(xAxis.max));

    chart.dummyData.zoomedIn = false;
    xAxis.renderer.labels.template.disabled = true;
    dependencies.setXAxisLabels(chart);
  }

  type SelectionExtremesChangedEvent = {
    type: 'selectionextremeschanged',
    target: am4charts.DateAxis<am4charts.AxisRenderer>
  };

  function handleZoom(ev: SelectionExtremesChangedEvent, chart: am4charts.XYChart) {
    const newAxisRange = ev.target.maxZoomed - ev.target.minZoomed;
    const twentyFourHours = 86400000;

    if (!chart.dummyData.zoomedIn && newAxisRange < twentyFourHours) {
      setZoomedIn(true);
      const xAxis = chart.xAxes.values[0];

      // remove custom x axis labels
      chart.dummyData.xAxisLabelRanges.forEach((range: any) => xAxis.axisRanges.removeValue(range));

      // remove listeners so they do not redraw axis while zoomed in
      // redrawing responsibility given to amcharts while zoomed in
      chart.dummyData.listeners.forEach((listener: any, index: number) => {
        chart.dummyData.mqls[index].removeListener(listener);
      });

      chart.dummyData.mqls = [];
      chart.dummyData.listeners = [];
      chart.dummyData.xAxisLabelRanges = [];

      xAxis.renderer.labels.template.disabled = false;

      chart.dummyData.zoomedIn = true;
    }
  }

  function createChart(id: string) {
    am4core.useTheme(coreTheme);

    const chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);

    chart.height = am4core.percent(85);
    chart.paddingTop = 42;

    const xAxis = new am4charts.DateAxis();
    chart.xAxes.push(xAxis);
    /* set the offset to 0 (even if device has a timezone offset)
     so amcharts does not adjust our drawn scale when redrawing on zoom */
    xAxis.dateFormatter.timezoneOffset = 0;

    xAxis.dataFields.data = "local";
    xAxis.renderer.minGridDistance = 40;
    if (props.data.length > 0) {
      if (props.classname === '24-hour') {
        xAxis.max = moment.utc().add(timeZoneOffset, 'minutes').endOf('hour').valueOf();
        xAxis.min = moment.utc(xAxis.max).startOf('hour').subtract(23, 'hour').valueOf();
      } else {
        xAxis.max = props.dateRange.to;
        xAxis.min = props.dateRange.from;
      }
    }

    chart.dummyData = {
      zoomedIn: false,
      xAxisLabelRanges: [],
      listeners: [],
      mqls: []
    };

    xAxis.events.on('selectionextremeschanged', (ev) => handleZoom(ev, chart));

    xAxis.groupData = true;
    xAxis.groupCount = 10000;
    xAxis.cursorTooltipEnabled = false;
    xAxis.renderer.grid.template.strokeWidth = 0;
    xAxis.renderer.labels.template.disabled = true;
    xAxis.baseInterval = { timeUnit: 'minute', count: 5 };

    const yAxis = new am4charts.ValueAxis();
    chart.yAxes.push(yAxis);
    yAxis.min = 0;
    yAxis.max = 100;
    yAxis.renderer.minGridDistance = 25;
    yAxis.numberFormatter.numberFormat = "#'%'";
    yAxis.cursorTooltipEnabled = false;
    yAxis.contentAlign = 'right';

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.maxTooltipDistance = 0;

    //grid with 25 step increment
    function createGrid(value: number) {
      const range = yAxis.axisRanges.create();
      range.value = value;
      range.label.text = "{value}";
    }
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;
    createGrid(0);
    createGrid(25);
    createGrid(50);
    createGrid(75);
    createGrid(100);

    chart.zoomOutButton.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.wrap = false;
    chart.legend.labels.template.fontWeight = '400';
    chart.legend.labels.template.dy = -8;
    chart.legend.labels.template.maxWidth = 150;
    chart.legend.labels.template.truncate = false;
    chart.legend.maxColumns = 4;
    chart.legend.height = am4core.percent(15);

    chart.legend.itemContainers.template.togglable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

    chart.events.on("maxsizechanged", function (ev) {
      adjustChartAndLegendRatio(chart, 85, 75);
    });

    const batteryEssentialsDevicesFeature = isElemez ? Feature.batteryEssentialsDevices : Feature.panasonicSmartBatteryMonitorDevices;
    const legendIsClickable = features.includes(batteryEssentialsDevicesFeature);
    chart.legend.itemContainers.template.events.on("over", (event) => {
      event.target.cursorOverStyle = am4core.MouseCursorStyle.default;
      if (((event.target.dataItem.dataContext as any).data[0].smartBattery?.id) && legendIsClickable) {
        event.target.cursorOverStyle = am4core.MouseCursorStyle.pointer;
      }
    });

    if (legendIsClickable) {
      chart.legend.itemContainers.template.events.on("hit", function (ev) {
        const smartBatteryId = (ev.target.dataItem.dataContext as any).data[0].smartBattery?.id;
        if (smartBatteryId) {
          redirectToBatteryEssentialsLink(smartBatteryId, history, baseBatteryPath);
        }
      });
    }

    chart.legend.labels.template.adapter.add("stroke", function (_, target) {
      if (((target.parent.dataItem.dataContext as any).data[0].smartBattery?.id) && legendIsClickable) {
        return target.stroke = am4core.color('#07c');
      }
    });

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 13;
    markerTemplate.height = 30;
    markerTemplate.strokeWidth = 2;
    markerTemplate.dx = 1;

    markerTemplate.disposeChildren();
    const legendItem = markerTemplate.createChild(am4core.Rectangle);
    legendItem.fillOpacity = 0.2;
    legendItem.width = 13;
    legendItem.height = 13;

    // istanbul ignore next
    legendItem.adapter.add("stroke", function (_, target) {
      if (target.dataItem && target.dataItem.dataContext && (target.dataItem.dataContext as any).dummyData) {
        return (target.dataItem.dataContext as any).dummyData.colour;
      }
    });

    // istanbul ignore next
    legendItem.adapter.add("fill", function (_, target) {
      if (target.dataItem && target.dataItem.dataContext && (target.dataItem.dataContext as any).dummyData) {
        return (target.dataItem.dataContext as any).dummyData.colour;
      }
    });

    // allow access to chart outside of amchart functions
    setChart(chart);

    return chart;
  }

  function onDataUpdated(chart: am4charts.XYChart, chartData: any[], prevProps?: any, currentProps?: any) {
    // remove old series if any exist
    if (chart.series && chart.series.length > 0) {
      chart.series.clear();
    }

    chartData.forEach((battery: any, index: number) => {
      const lineColours = ['#5ECAEA', '#709BDF', '#8076E0', '#3BB09A', '#DF7097', '#EA985E', '#5FB952'];
      dependencies.createSeries(battery[0].smartBattery?.serialNumber || '-', lineColours[index], index, chart, classname, t);
    });

    const currentChartData = currentProps.link.data;
    const currentEvents = currentProps.link.events;

    const eventDataHasChanged = !isEqual(currentEvents, get(prevProps, 'link.events'));
    const chartDataHasChanged = !isEqual(currentChartData, get(prevProps, 'link.data'));

    // add overlays on mount / on update if events have changed and chart data exists
    const shouldAddOrUpdateEventOverlays = (eventDataHasChanged || chartDataHasChanged) && currentChartData.length !== 0;

    if (shouldAddOrUpdateEventOverlays) {
      addEventOverlaysToLineChart(chart, chart.xAxes.values[0], currentProps.link.events, t);
      setXAxisNeedsUpdating(true);
      if (!get(currentProps, 'link.visible')) {
        (chart as any).eventsUpdatedWhileHidden = true;
      }
    }

    // istanbul ignore next
    // If the chart has just become visible and was updated while hidden,
    // invalidate it to force a redraw, as it may have redrawn incorrectly.
    if (get(currentProps, 'link.visible') && !get(prevProps, 'link.visible') && (chart as any).eventsUpdatedWhileHidden) {
      chart.deepInvalidate();
      delete (chart as any).eventsUpdatedWhileHidden;
    }

    return chart;
  }

  function getData(): Array<any> {
    if (props.data.length > 0) {
      const dataGroupedByBattery = groupBy(props.data, 'smartBattery.serialNumber');
      const dataSortedByDate = map(dataGroupedByBattery, (batteryLevels: IBatteryLevel[]) => {
        const formattedData = batteryLevels.map(batteryData => {
          return {
            ...batteryData,
            local: parseInt(batteryData.local)
          };
        });
        return sortByDate(formattedData);
      });
      return dataSortedByDate;
    }
    return [];
  }

  function sortByDate(data: Array<any>): Array<any> {
    return data.sort((a: any, b: any) => a.local - b.local);
  }
}
