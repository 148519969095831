import React, { useMemo, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { ChipButton } from '../../../../components/controls/chipButton';
import { Loading } from '../../../../components/loading/loading';
import { AlarmCount, AlertCount, getAlarmCount, HomeLocationAndGroupData } from '../../../../services/core/overview';
import { isAlertEnabled } from '../../../../services/config/config';
import { ReactComponent as MajorAlarm } from '../../../../components/icons/majorAlarmIcon.svg';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { useFeatureTogglesContext } from '../../../../context/featureToggles';
import { AlarmStatus, alarmStatuses } from '../../../../services/config/alertConstants';
import styled from 'styled-components';

interface Props {
  data: HomeLocationAndGroupData[],
  onFilter: (filter: { status: { id: AlarmStatus, value: string }[] }) => void,
  currentFilterId: AlarmStatus,
  alerts: AlertCount,
  showAllFilters: boolean
}

const StyledFilterTitle = styled.div`
  font-weight: 600;
  margin-top: 1.25rem;
  margin-left: 3.125rem;
  margin-bottom: 0.5rem;
`;

export function AlertFilters(props: Props) {
  const { onFilter, currentFilterId, alerts, data, showAllFilters } = props;
  const [alarmCountLoading, setAlarmCountLoading] = useState(true);
  const [alarmCounts, setAlarmCounts] = useState<AlarmCount>();
  const { worldSettings } = useWorldSettingsContext();
  const featureToggles = useFeatureTogglesContext();
  const { t } = useTranslation(['translation', 'alerts', 'estateOverview', 'filters']);

  const filtersText: { [k in AlarmStatus]: string } = {
    deviceStatus: t('DEFAULT_FILTER', { ns: 'estateOverview' }),
    deviceLocationAccessPointStatus: t('ACCESS_POINT_FILTER', { ns: 'estateOverview' }),
    deviceUtilisationIdleStatus: t('DEVICE_UTILISATION_IDLE_STATUS_RED', { ns: 'filters' }),
    deviceUtilisationOutOfContactStatus: t('DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED', { ns: 'filters' }),
    deviceUserExperienceRebootStatus: t('DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED', { ns: 'filters' }),
    deviceTimeErrorStatus: t('TIME_ERROR_FILTER', { ns: 'estateOverview' }),
    averageDischargeStatus: t('AVERAGE_DISCHARGE_STATUS_YELLOW', { ns: 'filters' }),
    deviceUserExperienceLowPowerStatus: t('DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED', { ns: 'filters' }),
    deviceLocationTimeStatus: t('DEVICE_LOCATION_TIME_STATUS_RED', { ns: 'filters' }),
    deviceLocationDistanceStatus: t('DEVICE_LOCATION_DISTANCE_STATUS_RED', { ns: 'filters' }),
    deviceUserExperienceDropStatus: t('DEVICE_USER_EXPERIENCE_DROP_STATUS_RED', { ns: 'filters' }),
  };

  const [alarmCountFetcher, onSuccess] = useMemo(() => {
    return [
      getAlarmCount,
      (count: AlarmCount) => { setAlarmCounts(count); setAlarmCountLoading(false); }
    ];
  }, []);
  useWorldRequest(alarmCountFetcher, { onSuccess });

  const getCountIcon = (status: AlarmStatus) => {
    const count = alarmCounts?.[status];
    if (count?.enabled && count.count > 0) {
      return <MajorAlarm data-id="major-alarm" />;
    }
  };

  const getCount = (status: AlarmStatus) => {
    const count = alarmCounts?.[status];
    if (count?.enabled && count.count > 0) {
      return count.count;
    }
  };

  let filters;
  if (!data.length && !showAllFilters) {
    filters = <ChipButton
      dataId="device-status"
      key={'core-estate-overview__filter--deviceStatus'}
      active={true}
      icon={getCountIcon('deviceStatus')}
      text={filtersText.deviceStatus}
      count={getCount('deviceStatus')}
    />;
  } else {
    filters = alarmStatuses.map((status) => {
      let buttonDisabled = false;
      let alertEnabled = true;
      if (status !== 'deviceStatus') {
        alertEnabled = isAlertEnabled(status, worldSettings);
        buttonDisabled = Boolean(alerts && !alerts[status]?.red && !alerts[status]?.yellow);
      }

      return (
        alertEnabled &&
        <ChipButton
          dataId={status}
          key={`core-estate-overview__filter--${status}`}
          icon={getCountIcon(status)}
          active={currentFilterId === status}
          text={filtersText[status]}
          onClick={() => { onFilter({ status: [{ id: status, value: filtersText[status] }] }); }}
          disabled={buttonDisabled}
          count={getCount(status)}
        />
      );
    }).filter(f => f);
  }

  return (
    <Loading isLoading={alarmCountLoading} transparentOverlay={false}>
      {<><StyledFilterTitle data-id="core-estate-overview__filters--title">{t('FILTER_TYPES', { ns: 'estateOverview' })}:</StyledFilterTitle>
        <div className="core-estate-overview__filters--buttons" data-id="core-estate-overview__filters--buttons">
          {filters}
        </div></>}
    </Loading>
  );
}
