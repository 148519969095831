import { isEqual } from 'lodash';
import React from 'react';
import styled from 'styled-components';

import { useBatteryTypeSettingsContext } from '../../context/batteryTypeSettings';
import { useProductsContext } from '../../context/products';
import { useWorldSettingsContext } from '../../context/worldSettings';
import { useWorldAction } from '../../lib/useWorldAction';
import { BatteryTypeSettings, setBatteryTypeSettingsAction, setWorldSettingsAction, WorldSettings } from '../../services/config/config';
import { AlertsSettingsPage } from './compositions/alerts/alertsSettingsPage';
import { getBatterySettingsLicense, getEnabledSettings } from './enabledSettings';
import { settingNames } from './settingNames';

const StyledPage = styled.div`
  flex-grow: 1;
`;

export function AlertsSettings() {
  const products = useProductsContext();
  const { worldSettings, updateProperties: updateWorldSettings } = useWorldSettingsContext();
  const { batteryTypeSettings, updateProperties: updateBatteryTypeSettings } = useBatteryTypeSettingsContext();

  const enabledSettings = getEnabledSettings(products);
  const setWorldSettings = useWorldAction(setWorldSettingsAction);
  const setBatteryTypeSettings = useWorldAction(setBatteryTypeSettingsAction);

  async function saveData(data: { worldSettings: WorldSettings, batteryTypeSettings: BatteryTypeSettings[] }) {
    if (!isEqual(data.batteryTypeSettings, batteryTypeSettings)) {
      await setBatteryTypeSettings(data.batteryTypeSettings);
      updateBatteryTypeSettings(data.batteryTypeSettings);
    }
    if (!isEqual(data.worldSettings, worldSettings)) {
      await setWorldSettings(data.worldSettings);
      updateWorldSettings(data.worldSettings);
    }
  }

  const batterySettingsLicense = getBatterySettingsLicense(products);
  const locationSettingsLicense = enabledSettings.includes(settingNames.location);
  const userExperienceSettingsLicense = enabledSettings.includes(settingNames.userExperience);
  const utilisationSettingsLicense = enabledSettings.includes(settingNames.utilisation);

  return (
    <StyledPage className="alerts-page">
      <AlertsSettingsPage
        saveData={saveData}
        worldSettings={worldSettings}
        batteryTypeSettings={batteryTypeSettings}
        batterySettingsLicense={batterySettingsLicense}
        locationSettingsLicense={locationSettingsLicense}
        userExperienceSettingsLicense={userExperienceSettingsLicense}
        utilisationSettingsLicense={utilisationSettingsLicense}
      />
    </StyledPage>
  );
}
