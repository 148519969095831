import React, { useMemo } from 'react';
import { WorldSettingsProvider } from '../../context/worldSettings';
import { WorldSettings } from '../../services/config/config';

interface Props {
  worldSettings: WorldSettings,
  children: React.ReactNode
}
export function WorldSettingsWrapper(props: Props) {
  const { children, worldSettings } = props;
  const value = useMemo(() => {
    return {
      ...worldSettings
    };
  }, [worldSettings]);

  return (
    <WorldSettingsProvider initialState={value}>
      {children}
    </WorldSettingsProvider>
  );
}
