import React, { useRef, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';

import { exportDevicesUtilizationChartAction, IDevicesUtilization, IPerformanceChartParams } from '../../../../services/core/performance';
import { useWorldAction } from '../../../../lib/useWorldAction';
import { formatTooltipDate, getFormattedTitle, getTitle } from './lib';
import { drawXAxisLabelsToToday } from '../../../../components/chart/lib';
import { getUserTimeZone } from '../../../../lib/timeZone';
import { NoSelectionOverlay } from '../../../../components/card/noSelectionOverlay';
import { ExportButton } from '../../../../components/controls/exportButton';
import { AmChart, coreTheme } from '../../../../components/chart/amChart';
import { addHoverAnimation } from '../../../../components/chart/onHoverAnimation';
import { Filter } from '../../../../components/timePeriodAndFilterPicker/useFilterPicker';

import './charts.css';


interface IProps {
  data: IDevicesUtilization[],
  filter: Filter,
  days: number,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function CorePerformanceDevicesUtilization(props: IProps) {
  const { data, filter, days } = props;
  const exportChartData = useWorldAction(exportDevicesUtilizationChartAction);
  const [chartReady, setChartReady] = useState(false);
  const chartRef = useRef(null);
  const ns = 'translation';
  const { t } = useTranslation(['performance', ns, 'timeState', 'filters']);
  const title = getTitle(filter, days, t, 'DEVICES_USAGE', true);

  const formattedTitle = getFormattedTitle(title);

  const onExportClicked = async (): Promise<string> => {
    const timeZone = getUserTimeZone();
    const params: IPerformanceChartParams = {
      days,
      homeLocationId: filter.homeLocation?.value.id,
      group: filter.group?.value,
      zoneId: filter.zone?.value.id,
      timeZone
    };
    return exportChartData(params);
  };

  return (
    <div data-id={`devices-utilization${chartReady ? '--chart-ready' : ''}`} className={`core-performance-chart ${chartReady ? 'chart-ready' : ''}`}>
      <div className="chart-header">
        <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns })} show={data.length === 0} />
        <div className="chart-title">{title}</div>
        <div className="chart-buttons"><ExportButton exportFunction={onExportClicked} filename={`${formattedTitle}.${moment.utc().toISOString()}.csv`} /></div>
      </div>
      <div className="chart-and-legend-container">
        <div className="chart-container">
          <AmChart
            tag="core-performance-chart"
            chartProvider={createChart}
            dataProvider={() => data}
            onUpdate={onDataUpdated}
            onMount={onMount}
            onDataValidated={() => setChartReady(true)}
            link={data}
            cypressId="corePerformanceDevicesUtilization"
          />
        </div>
        <div id="core-performance-legend" />
      </div>
    </div>
  );

  function createChart(id: string) {
    am4core.useTheme(coreTheme);

    const chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);
    chart.paddingTop = 20;
    chart.minHeight = 220;

    function createSeries(field: string, name: string, colour: string) {
      const series = chart.series.push(new am4charts.ColumnSeries());
      series.id = field;
      series.name = name;
      series.dataFields.valueY = field;
      series.dataFields.categoryX = 'date';
      series.sequencedInterpolation = true;

      series.stacked = true;

      series.columns.template.fill = am4core.color(colour);
      series.columns.template.stroke = am4core.color(colour);
      series.columns.template.width = am4core.percent(90);
      series.columns.template.tooltipHTML = '';

      series.columns.template.adapter.add("tooltipHTML", (_, target) => {
        if (!target.tooltipDataItem) {
          return;
        }
        const data = target.tooltipDataItem.dataContext as IDevicesUtilization;
        const date = formatTooltipDate(data.date);
        const tooltip = `
          <div class="tooltip-container" data-date="${data.date}" data-seriesName="${field}">
            <span class="core-performance-chart__tooltip-title">${date}</span>
            </br>
            <span class="core-performance-tooltip-text">
              <div class="chart-key chart-key--utilisation"></div>
              &nbsp;${t('DEVICES_USED', { ns: 'performance' })}: ${data.deviceCount}
            </span>
          </div>`;
        return tooltip;
      });

      series.dummyData = {
        colour: am4core.color(colour)
      };

      addHoverAnimation(series, chart);
      return series;
    }
    const dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = 'date';
    dateAxis.cursorTooltipEnabled = false;
    dateAxis.renderer.grid.template.disabled = true;
    dateAxis.renderer.minGridDistance = 50;

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.minGridDistance = 30;
    valueAxis.calculateTotals = true;
    valueAxis.contentAlign = 'right';
    valueAxis.maxPrecision = 0;

    createSeries('deviceCount', t('DEVICES_USED', { ns: 'performance' }), '#0fa336');
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    chart.legend.labels.template.fontWeight = '400';
    chart.legend.labels.template.dy = -8;
    chart.zoomOutButton.disabled = true;

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 13;
    markerTemplate.height = 30;

    markerTemplate.disposeChildren();
    const legendItem = markerTemplate.createChild(am4core.Rectangle);
    legendItem.fill = am4core.color('#0fa336');
    legendItem.width = 13;
    legendItem.height = 13;

    /* istanbul ignore next */
    legendItem.adapter.add("fill", function (_, target) {
      if (target.dataItem && target.dataItem.dataContext && (target.dataItem.dataContext as any).dummyData) {
        return (target.dataItem.dataContext as any).dummyData.colour;
      }
    });

    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

    chartRef.current = chart;
    return chart;
  }

  function onMount(chart: am4charts.XYChart) {
    drawXAxisLabelsToToday(chart, chart.xAxes.values[0] as am4charts.CategoryAxis, props.days, t);
    props.onMount(chart);
    return chart;
  }

  function onDataUpdated(chart: am4charts.XYChart, chartData: IDevicesUtilization[], prevProps?: any, currentProps?: any) {
    let maxCount = 0;
    chartData.forEach(({ deviceCount }) => (
      maxCount = deviceCount > maxCount ? deviceCount : maxCount
    ));
    (chart.yAxes.values[0] as am4charts.ValueAxis).max = maxCount;
    return chart;
  }
}
