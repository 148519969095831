import * as React from 'react';
import { includes } from 'lodash';

export interface IHeaderProps {
  fields: string[],
  sortableFields: (string | JSX.Element)[],
  centeredFields: string[],
  onSortChange?: (field: string, direction: Direction) => void,
  sortField?: string,
  direction?: Direction
}

interface IHeaderState {
  sortField: string,
  direction: Direction
}

export enum Direction {
  undefined = 0,
  asc = 1,
  desc = 2
}

export class SortableHeader extends React.Component<IHeaderProps, IHeaderState> {

  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      sortField: props.sortField ? props.sortField : '',
      direction: props.direction ? props.direction : Direction.undefined
    };

    this.createCell = this.createCell.bind(this);
  }

  public render() {
    return (
      <thead>
        <tr>
          {
            this.props.fields &&
            this.props.fields.map((field, index) => {
              const sortable = includes(this.props.sortableFields, field);
              const centered = includes(this.props.centeredFields, field);
              const direction = field === this.state.sortField ? this.state.direction : Direction.undefined;
              return this.createCell(index, field, sortable, centered, direction);
            })
          }
        </tr>
      </thead>
    );
  }


  public createCell(index: number, field: string, sortable: boolean, centered: boolean, direction: Direction): JSX.Element {
    if (sortable) {
      return (
        <td
          key={index}
          onClick={() => this.onClick(field, direction)}
          style={{ cursor: 'pointer' }}
          className ={ centered ? 'textCenter' : undefined } >
          {field}
          <span className='sort-up-down'>
            {this.getSortIcon(direction)}
          </span>
        </td>);
    } else {
      return (<td
        key={index}
        className={ centered ? 'textCenter' : undefined }
      >{field}</td>);
    }
  }

  public onClick(field: string, direction: Direction) {
    const nextDirection = this.nextDirection(direction);
    this.setState({ sortField: field, direction: nextDirection });

    if (this.props.onSortChange) {
      this.props.onSortChange(field, nextDirection);
    }
  }

  public nextDirection(current: Direction): Direction {
    switch (current) {
    case 0: return Direction.asc;
    case 1: return Direction.desc;
    default: return Direction.undefined;
    }
  }

  private getSortIcon = (direction: Direction) => {
    switch (direction) {
    case 1:
      return <React.Fragment><i className={'fas fa-sort-up active-sort'} /><i className={'fas fa-sort-down'} /></React.Fragment>;
    case 2:
      return <React.Fragment><i className={'fas fa-sort-up'} /><i className={'fas fa-sort-down active-sort'} /></React.Fragment>;
    default:
      return <React.Fragment><i className={'fas fa-sort-up'} /><i className={'fas fa-sort-down'} /></React.Fragment>;
    }
  };
}
