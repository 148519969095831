import React from 'react';
import { Translation } from 'react-i18next';
import { isNil, startCase, isEmpty, isObjectLike, isNumber, isString, capitalize } from 'lodash';
import moment from 'moment';

import { bytesToGigabytes, bytesToMegabytes, formatBytesToGigabytesFixed, roundTo2PlacesFixed } from '../../lib/dataConversion';
import { DeviceEventName, DeviceEvent, IApplicationEvent, IBatteryEvent, isBatteryEvent, isApplicationEvent, IPowerEvent, isPowerEvent, hasPowerInfo, isRebootEvent, IRebootEvent, hasRebootInfo, isValidRebootType, hasApplicationInfo, INetworkEvent, isNetworkEvent, isSimEvent } from '../../services/core/eventsTypes';
import { deviceEvents } from '../../containers/core/device/compositions/performance/events/deviceEventsTypes';
import { UserRole } from '../authentication/userAuth';
import { ALERT } from '../alertsPanel/alertMessages';
import * as self from './dataFormatters';
import { World } from '../../services/worlds/worlds';
import { Trans } from '@components/i18n/typedTrans';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { ITranslationKeys } from '@components/i18n/keys';

export const DEFAULT_VALUE = '-';
export const DEFAULT_TEXT = 'N/A';

export function formatTitle(title: string) {
  if (isNil(title) || isEmpty(title)) {
    return DEFAULT_VALUE;
  }
  return title + ':';
}

export function formatDataUsage(value: any): string | JSX.Element {
  if (isNil(value)) {
    return DEFAULT_VALUE;
  }
  const oneGigabyte = 1073741824;
  const data = value >= oneGigabyte ? formatBytesToGigabytesFixed(value) : roundTo2PlacesFixed(bytesToMegabytes(value));
  return <span>{data} <Trans i18nKey={value >= oneGigabyte ? 'GB' : 'MB'} ns="translation">GB</Trans></span>;
}

export function formatDataUsageToGB(value: any): string | JSX.Element {
  if (isNil(value)) {
    return DEFAULT_VALUE;
  }
  const data = formatBytesToGigabytesFixed(value);
  return <span>{data} <Trans i18nKey='GB' ns="translation">GB</Trans></span>;
}

function getDaysAgo(updated: string): number {
  const milisec = parseInt(updated);
  const lastSeenDate = moment(milisec);
  const todaysDate = moment();
  return todaysDate.diff(lastSeenDate, 'days');
}

export function formatValue(value: any): string {
  if (isNil(value) || (isObjectLike(value) && isEmpty(value))) {
    return DEFAULT_VALUE;
  }
  if (Array.isArray(value)) {
    return value.join(', ');
  }
  return String(value);
}

export function getFormatNumberToLocale(locale: string) {
  return (value: any) => {
    if (!isString(value) && !isNumber(value)) {
      return self.formatValue(value);
    }
    const number = Number(value);
    return Number.isNaN(number) ? self.formatValue(value) : number.toLocaleString(locale);
  };
}

export function formatRole(role: UserRole): JSX.Element {
  switch (role) {
  case 'administrator':
    return <Trans i18nKey="ADMIN" ns="userManagement" />;
  case 'manager':
    return <Trans i18nKey="MANAGER" ns="userManagement" />;
  case 'editor':
    return <Trans i18nKey="EDITOR" ns="userManagement" />;
  case 'viewer':
    return <Trans i18nKey="VIEWER" ns="userManagement" />;
    /* istanbul ignore next */
  default:
    /* eslint-disable-next-line no-case-declarations */
    const exhaustiveCheck: never = role;
  }
}

function formatWorlds(worldNames: World['name'][]) {
  return worldNames.join(', ');
}

export function formatWorldAccess(worldIds: World['id'][] | null, worlds: World[]): JSX.Element {
  const validWorldIds = worldIds ? worldIds.filter(id => worlds.find(world => world.id === id)) : worldIds;

  const allWorldsEnabled = validWorldIds === null || validWorldIds?.length === worlds.length;
  const name = allWorldsEnabled ? <Trans i18nKey="ALL_WORLDS" ns='userManagement' /> : formatWorlds(validWorldIds.map((id) => worlds.find(world => world.id === id).name));

  return (
    <span className={allWorldsEnabled ? 'all-worlds-access' : ''}>
      {name}
    </span>
  );
}

export function formatFirstCapitalValue(value: string): string {
  if (isNil(value)) {
    return DEFAULT_VALUE;
  }

  return startCase(value);
}

export function formatDateLastUpdated(updated: string): string | JSX.Element {
  if (isNil(updated)) {
    return DEFAULT_VALUE;
  }

  const days = getDaysAgo(updated);
  switch (days) {
  case 0:
    return <Translation>
      {(t) => t('timeState:TODAY')}
    </Translation>;
  case 1:
    return <Translation>
      {(t) => t('timeState:YESTERDAY')}
    </Translation>;
  default:
    return days > 1 ? moment(parseInt(updated)).fromNow() : DEFAULT_VALUE;
  }
}

export function formatDateDetected(updated: string | null): string | JSX.Element {
  if (isNil(updated)) {
    return <Translation>
      {(t) => t('timeState:MORE_THAN_90_DAYS_AGO')}
    </Translation>;
  }

  const days = getDaysAgo(updated);
  switch (days) {
  case 0:
    return <Translation>
      {(t) => t('timeState:TODAY')}
    </Translation>;
  case 1:
    return <Translation>
      {(t) => t('timeState:YESTERDAY')}
    </Translation>;
  default:
    return moment.utc(updated).fromNow();
  }
}

export function formatDateTime(updated: string): string | JSX.Element {
  const dateFormat = moment.utc(parseInt(updated)).format('Do MMM');
  const timeFormat = moment.utc(parseInt(updated)).format('LT');
  if (isNil(updated)) {
    return DEFAULT_VALUE;
  }
  return <div className='event-time'>
    {dateFormat} <Trans i18nKey='AT' ns='performance' /> {timeFormat}
  </div>;
}

export function formatBytes(value: number, t: TTypedTFunction): string {
  if (isNil(value)) { return DEFAULT_VALUE; }
  const oneGigabyte = 1073741824;
  if (value >= oneGigabyte) {
    return `${roundTo2PlacesFixed(bytesToGigabytes(value))} ${t('GB')}`;
  }
  return `${roundTo2PlacesFixed(bytesToMegabytes(value))} ${t('MB')}`;
}

export function formatTimeLength(value: number): string | JSX.Element {
  if (isNil(value)) {
    return DEFAULT_VALUE;
  }

  const duration = moment.duration(Math.round(value / 60), 'minutes');
  const hours = duration.hours() + duration.days() * 24;
  const minutes = duration.minutes();

  if (hours && minutes) return <span>{hours} <Trans ns="timeState" i18nKey='HOUR' count={hours}>hour</Trans>, {minutes} <Trans ns="timeState" i18nKey='MINUTE' count={minutes}>minute</Trans></span>;
  if (hours && !minutes) return <span>{hours} <Trans ns="timeState" i18nKey='HOUR' count={hours}>hour</Trans></span>;
  else return <span>{minutes} <Trans ns="timeState" i18nKey='MINUTE' count={minutes}>minute</Trans></span>;
}

export function formatDeviceEvents(value: DeviceEventName): JSX.Element {
  const eventType = deviceEvents[value];
  return <span className={`events-text-and-icon ${eventType.name}`}>
    <i className={`events-list-icon ${eventType.icon}`}></i>
    <span className={`events-list-text ${eventType.name}`}>
      <Trans i18nKey={`${eventType.displayName}` as keyof ITranslationKeys['editEvents']} ns='editEvents' />
    </span>
  </span>;
}

function formatApplicationInfo(event: Required<IApplicationEvent>): string | JSX.Element {
  const name = event.applicationInfo.name ? `${event.applicationInfo.name} ` : '';
  if (isNil(event.applicationInfo?.version)) {
    return event.applicationInfo.name;
  }
  if (event.name === 'applicationUninstalled') {
    return <div>{name}version {event.applicationInfo.version} <Trans ns='performance' i18nKey='UNINSTALLED_TEXT' /></div>;
  }
  if (event.name === 'applicationInstalled') {
    return <div>{name}version {event.applicationInfo.version} <Trans ns='performance' i18nKey='INSTALLED_TEXT' /></div>;
  }
  return <div>{name}<Trans i18nKey='UPDATED_VERSION_TEXT' ns='performance' /> {event.applicationInfo.version}</div>;
}

function formatBatteryInfo(event: IBatteryEvent): JSX.Element {
  let oldSerialNumber = DEFAULT_VALUE;
  let newSerialNumber = DEFAULT_VALUE;
  if (event.oldBatteryInfo) {
    oldSerialNumber = `${event.oldBatteryInfo.serialNumber}`;
  }
  if (event.newBatteryInfo) {
    newSerialNumber = `${event.newBatteryInfo.serialNumber}`;
  }
  return <div><Trans ns='performance' i18nKey='OUT' />: {oldSerialNumber} <Trans ns='performance' i18nKey='IN' />: {newSerialNumber}</div>;
}

function formatNetworkInfo(event: INetworkEvent): JSX.Element {
  const networkTypeFormat = (networkType: string): string => {
    return networkType === 'wifi' ? 'Wi-Fi' : capitalize(networkType);
  };
  const oldNetworkType = `${networkTypeFormat(event.oldNetworkInfo?.networkType) || ''}`;
  const oldBearerType = `${event.oldNetworkInfo?.bearerType?.toUpperCase() || ''}`;
  const newNetworkType = `${networkTypeFormat(event.newNetworkInfo?.networkType) || ''}`;
  const newBearerType = `${event.newNetworkInfo?.bearerType?.toUpperCase() || ''}`;
  let networkInfoText;
  if (event.name === 'networkChanged') {
    networkInfoText = <div><Trans ns='performance' i18nKey='OLD' />: {oldNetworkType} {oldBearerType} <Trans ns='performance' i18nKey='NEW' />: {newNetworkType} {newBearerType}</div>;
  }
  if (event.name === 'networkAvailable') {
    networkInfoText = <div>{newNetworkType} {newBearerType}</div>;
  }
  if (event.name === 'networkLost') {
    networkInfoText = <div>{oldNetworkType} {oldBearerType}</div>;
  }
  if (event.name === 'bearerChanged') {
    networkInfoText = <div><Trans ns='performance' i18nKey='OLD' />: {oldBearerType} <Trans ns='performance' i18nKey='NEW' />: {newBearerType}</div>;
  }
  return networkInfoText;
}

function formatPowerInfo(event: Required<IPowerEvent>): JSX.Element {
  return <div><Trans ns='performance' i18nKey="DEVICE_CHARGE_LEVEL" />: {event.powerInfo.level}%</div>;
}

function formatRebootInfo(event: Required<IRebootEvent>): string | JSX.Element {
  const { type } = event.rebootInfo;
  if (isValidRebootType(type)) {
    if (type === 'user') {
      return <Trans ns='performance' i18nKey="REBOOT_TYPE_USER" />;
    }
    if (type === 'system') {
      return <Trans ns='performance' i18nKey="REBOOT_TYPE_SYSTEM" />;
    }
  }
  return DEFAULT_TEXT;
}

export function formatEventInfo(_: undefined, event: DeviceEvent): string | JSX.Element {
  if (isBatteryEvent(event)) {
    return formatBatteryInfo(event);
  }
  if (isNetworkEvent(event)) {
    return formatNetworkInfo(event);
  }
  if (isApplicationEvent(event) && hasApplicationInfo(event)) {
    return formatApplicationInfo(event);
  }
  if (isPowerEvent(event) && hasPowerInfo(event)) {
    return formatPowerInfo(event);
  }
  if (isRebootEvent(event) && hasRebootInfo(event)) {
    return formatRebootInfo(event);
  }
  if (isSimEvent(event)) {
    const oldSimSerialNumber = `${event.oldSimInfo?.serialNumber || '-'}`;
    const newSimSerialNumber = `${event.newSimInfo?.serialNumber || '-'}`;
    return <div><Trans ns='performance' i18nKey="OLD" />: {oldSimSerialNumber} <Trans ns='performance' i18nKey="NEW" />: {newSimSerialNumber}</div>;
  }
  return DEFAULT_TEXT;
}

export function formatStatus(status: string): string | JSX.Element {
  if (isNil(status)) {
    return '';
  }
  let icon;
  if (status === ALERT.red) {
    icon = 'fa fa-times-circle circle_red';
  }
  if (status === ALERT.yellow) {
    icon = 'fa fa-exclamation-triangle enhanced-warning-icon circle_orange';
  }
  if (status === ALERT.green) {
    icon = 'fa fa-check circle_green';
  }
  return <i className={`alert_status_icon ${icon}`} />;
}

export function formatBatteryWarrantyIcon(value: any, rowData: any): JSX.Element {
  if (rowData.underWarranty) {
    return <span>{value} <i className="fas fa-shield-alt"></i></span>;
  }
  return value;
}

export function formatPercentage(value: number): string {
  if (isNil(value)) {
    return DEFAULT_VALUE;
  }
  return `${Number(value.toFixed(2))}%`;
}
