import { useTranslation } from '@lib/useTypedTranslation';

import { Button } from '../../buttons/button';
import { ButtonColours } from '../../../containers/app/themes';

interface IProps {
  dataId?: string,
  onClick: () => void
}

export function NewButton(props: IProps) {
  const { t } = useTranslation('translation');

  return (
    <Button
      colour={ButtonColours.grey}
      dataId={props.dataId}
      onClick={props.onClick}
      text={t('NEW')}
      iconBeforeText={true}
      iconStyle="fas fa-plus"
    />
  );
}
