import React from 'react';

import Card from '../../components/card/card';
import { HomeLocationsList } from './homeLocationsList';

export function HomeLocationManagement() {

  return (
    <div className="homelocation-management-container">
      <Card noPadding={true}>
        <div className="homelocation-management">
          <div className="homelocations_list">
            <HomeLocationsList />
          </div>
        </div>
      </Card>
    </div>
  );
}
