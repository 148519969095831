import * as React from "react";
import { ModalPopup } from "../../../../components/controls/modalPopup";
import { FormControls } from "../../../../components/forms/formControls";
import { useTranslation } from "@lib/useTypedTranslation";

import { CheckBox } from "../../../../components/controls/checkbox";

import './locationInfoDialog.css';

function CheckboxDialogue(props: { checked: boolean, toggleChecked: () => void }) {
  const { checked, toggleChecked } = props;
  const { t } = useTranslation('translation');
  return (
    <div className="checkbox-with-message">
      <CheckBox
        className="fancy-checkbox"
        name="checkbox-in-location-info-dialog"
        checked={checked}
        onChange={toggleChecked}
        label={<span aria-label="checkbox dialogue text" className='checkbox_confirmation_message'>{t('CHECKBOX_MESSAGE')}</span>} />
    </div>
  );
}

export type LocationInfoMode = 'turningOn' | 'turningOff';

interface WarningDialogProps {
  handleSave: () => Promise<void>,
  handleCancel: () => void,
  mode: LocationInfoMode
}

export const LocationInfoDialog: React.FC<WarningDialogProps> = ({ mode, handleSave, handleCancel }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(false);

  const warning = mode === 'turningOn' ?
    t('LOCATION_INFO_TURNING_ON', { ns: 'settings' })
    :
    t('LOCATION_INFO_TURNING_OFF', { ns: 'settings' });

  const onCancel = () => {
    setChecked(false);
    handleCancel();
  };

  const onSubmit = async () => {
    await handleSave();
    setChecked(false);
  };

  const toggleChecked = () => setChecked(c => !c);

  return (<ModalPopup
    show={!!mode}
    handleClose={onCancel}
    closeDisabled
    header={t('LOCATION_INFO_QUESTION', { ns: 'settings' })}
    classname="location-info-dialog"
    footer={<FormControls
      onSubmit={onSubmit}
      onCancel={onCancel}
      submitDisabled={!checked}
      mode='apply'
    />}
  >
    <div className="location-info-dialog_body">
      <p className="location-info-dialog_warning"><strong>{t("WARNING", { ns: 'translation' })}: </strong> {warning}</p>
      <CheckboxDialogue
        toggleChecked={toggleChecked}
        checked={checked}
      />
    </div>
  </ModalPopup>);
};
