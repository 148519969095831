import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { useWorldRoutes } from '../../routes/parts/allWorldRoutes';
import { AccessDenied } from './index';

export function AccessDeniedToPage() {
  const { t } = useTranslation('accessDenied');
  const landingLink = useWorldRoutes().landing;
  return (
    <AccessDenied
      redirectLink={landingLink}
      redirectLinkMessage={t('RETURN_TO_PRODUCT_SELECTION')}
      translationKeySuffix='PAGE'
    />
  );
}
