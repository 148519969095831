import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { AuthState } from '@aws-amplify/ui-components';

import { dispatchAuthStateChangeEvent, getErrorMessage, validatePassword } from './utils';
import PasswordPolicy from './passwordPolicy';
import Card from '../../components/card/card';
import { devLogger } from '../../lib/logger';

import './style.css';
import { useAuthContext } from '../../context/auth';

export const ForgotPassword = () => {
  const { user = {} as any } = useAuthContext();
  const { username: initialUsername, authErrorCode } = user;
  const [error, setError] = useState('');
  const [username, setUsername] = useState(initialUsername || '');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [pending, setPending] = useState(false);
  const [codeRequested, setCodeRequested] = useState(false);
  const [usernameTrim, codeTrim, passwordTrim, passwordConfirmTrim] = [username.trim(), code.trim(), password.trim(), passwordConfirm.trim()];

  const canRequestCode = !codeRequested && usernameTrim && !pending;
  const submitFieldsSet = codeTrim && passwordTrim && passwordConfirmTrim;
  const canSubmitCode = codeRequested && submitFieldsSet && !pending;

  const handleError = (err: any) => {
    setError(getErrorMessage(err, AuthState.ForgotPassword));
    setPending(false);
  };

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };
  };

  const changeCodeRequested = (val: boolean) => {
    error && setError('');
    setCodeRequested(val);
  };

  const requestCode = async () => {
    if (canRequestCode) {
      setPending(true);
      try {
        const data = await Auth.forgotPassword(usernameTrim.toLowerCase());
        devLogger.log('forgotPassword code delivery:', data.CodeDeliveryDetails);
        changeCodeRequested(true);
        setPending(false);
      } catch (e) {
        if (e.code === 'UserNotFoundException') {
          // fail silently if email does not match a user, to avoid exposing valid email addresses
          changeCodeRequested(true);
          setPending(false);
          return;
        }
        handleError(e);
      }
    }
  };

  const submitCode = async () => {
    error && setError('');
    const passwordInvalidMessage = validatePassword(password, passwordConfirm);
    if (passwordInvalidMessage) {
      setError(passwordInvalidMessage);
      return;
    }
    if (canSubmitCode) {
      try {
        setPending(true);
        const data = await Auth.forgotPasswordSubmit(username, code, password);
        devLogger.log('forgotPassword code submit:', data);
        dispatchAuthStateChangeEvent(AuthState.SignIn);
      } catch (e) {
        handleError(e);
      }
    }
  };

  const handleRequestCodeEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      requestCode();
    }
  };

  const handleSubmitCodeEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitCode();
    }
  };

  const sendView = (
    <div id="sendView">
      <div className="alignCenter formSubHeader">Please enter your email address</div>
      <div className="row justify-content-center">
        <div className="form-group col-8">
          <label htmlFor="email">Email address:</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fas fa-user-circle" /></span>
            </div>
            <input
              id="email"
              type="email"
              className="form-control"
              name="username"
              onKeyPress={handleRequestCodeEnter}
              value={username}
              onChange={handleInputChange(setUsername)}
              required
            />
          </div>
        </div>
      </div>
      <div className="btnAlign">
        {error && <div id="authError">{error}</div>}
        <button
          id="requestCode"
          className="btn col-8"
          type="button"
          disabled={!canRequestCode}
          onClick={requestCode}
        >Send Code</button>
      </div>
    </div>
  );

  const submitView = (
    <div id="submitView">
      <div className="alignCenter formSubHeader">Please enter your code and new password</div>
      <div className="row justify-content-center">
        <div className="alignCenter formText col-8">
          <PasswordPolicy />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="form-group col-8">
          <label htmlFor="code">Code:</label>
          <div className="input-group code">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fas fa-shield-alt" /></span>
            </div>
            <input
              id="code"
              type="text"
              className="form-control"
              name="code"
              onKeyPress={handleSubmitCodeEnter}
              value={code}
              onChange={handleInputChange(setCode)}
              required
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="form-group col-8">
          <label htmlFor="password">New password:</label>
          <div className="input-group password">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fas fa-lock" /></span>
            </div>
            <input
              id="password"
              type="password"
              className="form-control"
              name="password"
              onKeyPress={handleSubmitCodeEnter}
              value={password}
              onChange={handleInputChange(setPassword)}
              required
            />
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="form-group col-8">
          <label htmlFor="confirmPassword">Confirm password:</label>
          <div className="input-group confirmPassword">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fas fa-lock" /></span>
            </div>
            <input
              id="confirmPassword"
              type="password"
              className="form-control"
              name="passwordMatch"
              onKeyPress={handleSubmitCodeEnter}
              value={passwordConfirm}
              onChange={handleInputChange(setPasswordConfirm)}
              required
            />
          </div>
        </div>
      </div>
      <div className="btnAlign">
        {error && <div id="authError">{error}</div>}
        <button
          id="submitCode"
          className="btn col-8"
          type="button"
          disabled={!canSubmitCode}
          onClick={submitCode}
        >Reset Password</button>
      </div>
      <div className="alignCenter">
        <span
          id="requestCodeAgain"
          className="redirectBtn"
          onClick={() => pending || changeCodeRequested(false)}
        >Request code again</span>
      </div>
    </div>
  );

  let message;
  if (authErrorCode === 'PasswordResetRequiredException') {
    message = <div id="authErrorMessage" className="alignCenter formSubHeader">You must reset your password to log in</div>;
  }

  return (
    <div className="row justify-content-center">
      <div className="center_container">
        <Card>
          <h5 className="alignCenter formHeader">Password Reset</h5>
          {message}
          {codeRequested ? submitView : sendView}
        </Card>
        <div className="card2">
          <Card>
            <div className="alignCenter">
              I remember my password.&nbsp;
              <span
                id="backToSignInBtn"
                className="redirectBtn"
                onClick={() => dispatchAuthStateChangeEvent(AuthState.SignIn)}
              >Return to Sign In</span>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
