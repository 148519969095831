import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import moment from 'moment';
import { dateAxisEndDuration } from '../../../../../../../components/chart/stackedColumnsDateChart';

import { deviceEvents } from '../../events/deviceEventsTypes';
import {
  DeviceEvent,
  isApplicationEvent,
  isBatteryEvent,
  isPowerEvent,
  areDeviceEvents,
  hasPowerInfo,
  isRebootEvent,
  hasRebootInfo,
  isValidRebootType,
  hasApplicationInfo,
  isNetworkEvent,
  isSimEvent
} from '../../../../../../../services/core/eventsTypes';
import { capitalize } from 'lodash';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { ITranslationKeys } from 'components/i18n/keys';

const TOOLTIP_MAX_EVENTS = 12;

export function formatDate(localDate: number) {
  return moment.utc(localDate).format('LLLL') + ' ' + moment.utc(localDate).format('LT');
}

export function formatTooltipDateTime(date: number, dateAxisEndDuration?: dateAxisEndDuration) {
  return (dateAxisEndDuration?.unit === 'hour' || dateAxisEndDuration?.unit === 'minute')
    ? moment.utc(Number(date)).format('LLLL LT') + ' - ' + moment.utc(Number(date)).add(dateAxisEndDuration.amount, dateAxisEndDuration.unit).format('LT')
    : moment.utc(Number(date)).format('ll');
}

/* istanbul ignore file */
export function addEventOverlaysToColumnChart(series: am4charts.ColumnSeries, chart: am4charts.XYChart, xAxis: am4charts.CategoryAxis | am4charts.DateAxis, t: TTypedTFunction<any>, formatTooltipDate?: (date: string) => string, dateAxisEndDuration? : dateAxisEndDuration) {
  series.bullets.clear();
  xAxis.axisRanges.clear();
  const labelBullet = series.bullets.push(new am4charts.LabelBullet());
  labelBullet.label.html = '';

  chart.maskBullets = false;

  labelBullet.adapter.add('y', function (html, target) {
    return -27;
  });

  function createEventLine(day: string) {
    const line = xAxis.axisRanges.create();
    line.label = new am4charts.AxisLabel();

    // setting category if xAxis is CategoryAxis and date if its DateAxis
    if ('category' in line) {
      line.category = day;
    } else {
      line.date = new Date(parseInt(day));
      line.endDate = new Date(moment.utc(day).add(dateAxisEndDuration.amount, dateAxisEndDuration.unit).valueOf());
      line.tick.paddingRight = 30;
    }
    line.tick.location = 0.5;

    line.grid.disabled = true;
    line.tick.disabled = false;
    line.tick.length = 130;
    line.tick.strokeWidth = 2;
    line.tick.strokeOpacity = 1;
    line.tick.strokeDasharray = '6';
    line.tick.stroke = am4core.color('#C1C1C1');
    line.tick.inside = true;
    line.label.text = '';
  }

  labelBullet.tooltipHTML = '';

  labelBullet.adapter.add('tooltipHTML', function (html, target) {
    if (!target.dataItem || !target.dataItem.dataContext) {
      return html;
    }

    const data = target.dataItem.dataContext as any;
    const { events } = data;
    if (!events || events.length === 0) {
      return html;
    }

    const typeCheckedEvents = areDeviceEvents(events) ? events : [];

    let date;
    const tooltipDate = data.tooltipDate || data.date;
    if (formatTooltipDate) {
      date = formatTooltipDate(tooltipDate);
    } else {
      date = formatTooltipDateTime(tooltipDate, dateAxisEndDuration);
    }

    const header = `<div class="chart-container__event-overlay-tooltip-header">${date}</div>`;
    const eventsHTML = getEventsHtml(typeCheckedEvents, t);
    return `<div class="chart-container_event-overlay-tooltip">${header}<div class="chart-container__event-overlay-tooltip-text-block">${eventsHTML.join("")}</div></div> ${showTooltipMessage(typeCheckedEvents.length, t)}`;
  });

  // only show columns chart event tooltips on hover when directly over label
  labelBullet.showTooltipOn = 'hit';
  labelBullet.label.events.on('over', function () {
    labelBullet.showTooltipOn = 'hover';
  });
  labelBullet.label.events.on('out', function () {
    labelBullet.showTooltipOn = 'hit';
  });

  labelBullet.label.adapter.add('html', function (html, target) {
    if (!target.dataItem || !target.dataItem.dataContext) {
      return html;
    }
    const data = target.dataItem.dataContext as any;
    if (!data.events || data.events.length === 0) {
      return html;
    }

    createEventLine(data.date);

    const { events } = data;
    const typeCheckedEvents = areDeviceEvents(events) ? events : [];

    if (typeCheckedEvents.length > 1) {
      return `<div class="total-events-overlay-number">${typeCheckedEvents.length}</div>`;
    }

    const firstEventName = typeCheckedEvents[0].name;
    const lowerClassName = ['deviceDropped', 'timeError', 'batteryChanged', 'networkChanged', 'networkAvailable', 'networkLost', 'bearerChanged', 'simChanged'].includes(firstEventName) ? ' icon-position-lower' : '';
    const iconClassName = `${deviceEvents[firstEventName].icon}${lowerClassName}`;
    return getIcon(iconClassName);
  });
}

export function addEventOverlaysToLineChart(chart: am4charts.XYChart, xAxis: any, events: DeviceEvent[], t: TTypedTFunction<any>) {
  xAxis.axisRanges.clear();
  if (events) {
    events.forEach((event) => {
      createEventLine(event.local);
    });
  }

  chart.maskBullets = false;

  function createEventLine(day: number) {
    const line = xAxis.axisRanges.create();
    line.value = day;
    line.grid.disabled = true;
    line.tick.disabled = false;
    line.tick.length = 100;
    line.tick.strokeWidth = 2;
    line.tick.strokeOpacity = 1;
    line.tick.strokeDasharray = '6';
    line.tick.stroke = am4core.color('#C1C1C1');
    line.tick.inside = true;
    line.label = new am4charts.AxisLabel();
    line.label.dy = -120;
    line.label.dx = -20;
    line.label.dummyData = {
      tooltipEvents: events.filter(event => event.local === day), // This was added to access time-specific event information when iterating through the line events. Used for testing tooltip behaviour.
      date: day
    };
    line.label.tooltip = new am4core.Tooltip();
    line.label.tooltip.pointerOrientation = 'vertical';
    line.label.html = '';
    line.label.tooltipHTML = '';
    line.label.tooltip.stroke = am4core.color('white');
    line.label.tooltip.autoTextColor = false;
    line.label.tooltip.label.fill = am4core.color('black');
    line.label.tooltip.getFillFromObject = false;
    line.label.tooltip.background.fill = am4core.color('white');
    line.label.tooltip.fontWeight = '400';
    line.label.tooltip.fontSize = 12;
    line.label.tooltip.stroke = am4core.color('#333333');
    line.label.fillOpacity = 0;
    line.label.strokeOpacity = 0;

    line.label.adapter.add('html', function (html: any, target: any) {
      if (!target.dummyData) {
        return html;
      }

      const data = target.dummyData as any;
      if (!data.tooltipEvents || data.tooltipEvents.length === 0) {
        return html;
      }

      const { tooltipEvents } = data;
      const typeCheckedEvents = areDeviceEvents(tooltipEvents) ? tooltipEvents : [];

      const labelEvent = typeCheckedEvents[0];

      return getIcon(deviceEvents[labelEvent.name].icon);
    });

    line.label.adapter.add('tooltipHTML', function (html: any, target: any) {
      if (!target.dummyData) {
        return html;
      }

      const data = target.dummyData as any;
      const { tooltipEvents } = data;

      if (!tooltipEvents || tooltipEvents.length === 0) {
        return html;
      }

      const typeCheckedEvents = areDeviceEvents(tooltipEvents) ? tooltipEvents : [];
      const labelEvents = typeCheckedEvents;

      // date is formatted to hh:mm as we currently only use line chart events for single day reports
      const date = `<div class="chart-container__event-overlay-tooltip-header">${formatDate(Number(labelEvents[0].local))}</div>`;

      const eventsHTML = getEventsHtml(labelEvents, t);
      return `<div data-cy="${day}">${date}<div class="chart-container__event-overlay-tooltip-text-block">${eventsHTML.join("")}</div></div> ${showTooltipMessage(labelEvents.length, t)}`;
    });
  }
}

function getIcon(classname: string) {
  return `<i class="chart-container__event-overlay-icon ${classname}"></i>`;
}

function showTooltipMessage(total: number, t: TTypedTFunction<any>) {
  if (total <= TOOLTIP_MAX_EVENTS) { return ''; }
  const translationMessage = `<span class="tooltip-max-events-text">${total - TOOLTIP_MAX_EVENTS} ${t('MORE', { ns: 'translation' })}</span> - ${t(`TOOLTIP_TEXT`, { ns: 'performance' })}`;
  return `<div class="event-overlay-tooltip-separator"></div><div class="chart-container__event-overlay-tooltip-message"> + ${translationMessage}</div>`;
}

function getEventsHtml(events: DeviceEvent[], t: TTypedTFunction<any>) {
  return events.slice(0, TOOLTIP_MAX_EVENTS).map((event) => {
    let line1Text = '';
    let line2Text = '';
    if (isBatteryEvent(event)) {
      let oldSerialNumber = '-';
      let newSerialNumber = '-';
      if (event.oldBatteryInfo) {
        oldSerialNumber = event.oldBatteryInfo.serialNumber;
      }
      if (event.newBatteryInfo) {
        newSerialNumber = event.newBatteryInfo.serialNumber;
      }
      line1Text = ` - ${t('OUT', { ns: 'performance' })}: ${oldSerialNumber} ${t('IN', { ns: 'performance' })}: ${newSerialNumber}`;
    } else if (isNetworkEvent(event)) {
      const networkTypeFormat = (networkType: string): string => {
        return networkType === 'wifi' ? 'Wi-Fi' : capitalize(networkType);
      };
      const oldNetworkType = `${networkTypeFormat(event.oldNetworkInfo?.networkType) || ''}`;
      const oldBearerType = `${event.oldNetworkInfo?.bearerType?.toUpperCase() || ''}`;
      const newNetworkType = `${networkTypeFormat(event.newNetworkInfo?.networkType) || ''}`;
      const newBearerType = `${event.newNetworkInfo?.bearerType?.toUpperCase() || ''}`;
      if (event.name === 'networkChanged') {
        line1Text = ` - ${t('OLD', { ns: 'performance' })}: ${oldNetworkType} ${oldBearerType} ${t('NEW', { ns: 'performance' })}: ${newNetworkType} ${newBearerType}`;
      }
      if (event.name === 'networkAvailable') {
        line1Text = ` - ${newNetworkType} ${newBearerType}`;
      }
      if (event.name === 'networkLost') {
        line1Text = ` - ${oldNetworkType} ${oldBearerType}`;
      }
      if (event.name === 'bearerChanged') {
        line1Text = ` - ${t('OLD', { ns: 'performance' })}: ${oldBearerType} ${t('NEW', { ns: 'performance' })}: ${newBearerType}`;
      }
    } else if (isApplicationEvent(event) && hasApplicationInfo(event)) {
      let appName = '';
      let appVersion = '';
      if (event.applicationInfo.name) {
        appName = ` - ${event.applicationInfo.name}`;
      }
      if (event.applicationInfo.version) {
        appVersion = ` v${event.applicationInfo.version}`;
      }
      line1Text = `${appName}${appVersion}`;
    } else if (isRebootEvent(event) && hasRebootInfo(event)) {
      const { type } = event.rebootInfo;
      if (isValidRebootType(type)) {
        if (type === 'user') {
          line1Text = ` (${t('REBOOT_TYPE_USER', { ns: 'performance' })})`;
        } else if (type === 'system') {
          line1Text = ` (${t('REBOOT_TYPE_SYSTEM', { ns: 'performance' })})`;
        }
      }
    } else if (isPowerEvent(event) && hasPowerInfo(event)) {
      line2Text = `${t('DEVICE_CHARGE_LEVEL', { ns: 'performance' })}: ${event.powerInfo.level}%`;
    } else if (isSimEvent(event)) {
      const oldSimSerialNumber = `${event.oldSimInfo?.serialNumber || '-'}`;
      const newSimSerialNumber = `${event.newSimInfo?.serialNumber || '-'}`;
      line1Text = ` - ${t('OLD', { ns: 'performance' })}: ${oldSimSerialNumber} ${t('NEW', { ns: 'performance' })}: ${newSimSerialNumber}`;
    }

    const line1 = `<span>${t(`${deviceEvents[event.name].displayName as keyof ITranslationKeys['editEvents']}`, { ns: 'editEvents' })}${line1Text} ${t('AT', { ns: 'performance' })} ${formatDate(event.local)}</span>`;
    const line2 = line2Text ? `<span>${line2Text}</span>` : '';

    const eventText = (
      `<span class="chart-container__event-overlay-tooltip-text ${event.name}">
        ${line1}
        ${line2}
      </span>`
    );
    return (
      `<span class="chart-container__event-overlay-tooltip-text-and-icon">
        ${getIcon(deviceEvents[event.name].icon)} ${eventText}
      </span>`
    );
  });
}
