import { useTranslation } from "@lib/useTypedTranslation";

import { Button } from "../../buttons/button";
import { ButtonColours } from "../../../containers/app/themes";

interface Props {
  className?: string,
  disabled?: boolean,
  dataId?: string,
  onClick: () => void
}

export function DeleteButton(props: Props) {
  const { t } = useTranslation('translation');

  return (
    <Button
      colour={ButtonColours.grey}
      className={props.className}
      dataId={props.dataId}
      disabled={props.disabled}
      onClick={props.onClick}
      text={t('DELETE')}
      iconBeforeText={true}
      iconStyle="fa fa-trash-alt"
    />
  );
}
