import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { get, debounce, isEmpty } from 'lodash';
import { Row } from 'react-bootstrap';

import { TabPanel } from '../tabs/tab-panel';
import { Tab } from '../tabs/tab';
import { FilterColumn } from './filterColumn';
import { Spinner } from '../loading/loadingSpinner';
import {
  IFilterResult,
  GroupFilterSettings,
  GroupFilterSort,
  updateFilterSelection,
  convertFilterToIds,
  FilterGroup
} from './filterLib';
import { useLanguageAndLocaleContext } from '../../context/languageAndLocale';

export interface IProps {
  countUpdateFunction?: (filters: IFilterResult) => Promise<number>,
  countDebounce?: number,
  countMessage?: string,
  filterGroups: IFilterGroup[],
  filterIcons?: GroupFilterSettings,
  filterClasses?: GroupFilterSettings,
  filterSort?: GroupFilterSort,
  currentFilter?: IFilterResult,
  availableFilters: IFilterResult,
  onFilterChange: (result: IFilterResult) => void
}
export interface TabbedProps extends Omit<IProps, 'filterGroups'> {
  tabs: { id: string, name: string, filterGroups: IFilterGroup[] }[]
}

type DefaultIFilterGroupProps = {
  id: string,
  filterDisplayName: string
};

/* search is not supported for grouped columns / columns with additional columns */
export type IFilterGroup = DefaultIFilterGroupProps &
({
  groups?: FilterGroup[],   // split filters into groups if this property exists, in the order given here, using name for group header
  // filterIds are used to populate the group, picked from availableFilters[filter-id] in the given order
  additionalCols?: { id: string, name: string }[]   // lookup values for additional cols on filter object by id in order given here, 'name' is the col header
} | { searchable: boolean });

export function FilterSelection(props: IProps): JSX.Element;
export function FilterSelection(props: TabbedProps): JSX.Element;
export function FilterSelection(props: TabbedProps | IProps) {
  const { countUpdateFunction, countDebounce } = props;
  const { t } = useTranslation('translation');
  const { locale } = useLanguageAndLocaleContext();
  //selected filters are provided by parent and changes reported back
  const selectedFilters = useMemo(() => props.currentFilter || {}, [props]);
  const debounceTimeout = useMemo(() => countDebounce !== undefined ? countDebounce : 1000, [countDebounce]);
  const [hitCount, setHitCount] = useState<number>();

  //wrap debounce and api function into useCallback to memoise between renders
  const getNewCount = useCallback(async (filters: IFilterResult) => {
    return countUpdateFunction && countUpdateFunction(filters).then(count => setHitCount(count));
  }, [countUpdateFunction]);
  const debouncedHitCountUpdate = useMemo(() => debounce(getNewCount, debounceTimeout, { leading: false }), [debounceTimeout, getNewCount]);

  //each time a change in selectedFilters is invoked, hitCount is set to undefined
  useEffect(() => {
    countUpdateFunction && setHitCount(undefined);
  }, [selectedFilters, countUpdateFunction]);

  //listens to selected filters requests hit count update
  useEffect(() => {
    countUpdateFunction && debouncedHitCountUpdate(selectedFilters);
  }, [debouncedHitCountUpdate, selectedFilters, countUpdateFunction]);

  const getFilterColumn = (filterGroup: IFilterGroup) => {
    return (
      <React.Fragment key={'filter_control_group_' + filterGroup.id}>
        <FilterColumn
          id={filterGroup.id}
          filterName={filterGroup.filterDisplayName}
          filterIcons={get(props.filterIcons, filterGroup.id, {})}
          filterSort={get(props.filterSort, filterGroup.id)}
          filterClasses={get(props.filterClasses, filterGroup.id, {})}
          groups={'groups' in filterGroup ? filterGroup.groups : undefined}
          additionalCols={'additionalCols' in filterGroup ? filterGroup.additionalCols : undefined}
          availableFilters={props.availableFilters[filterGroup.id]}
          selectedFilterIds={convertFilterToIds(selectedFilters)[filterGroup.id] || []}
          onChange={(id: string, selectedFilterIds: string[]) => {
            props.onFilterChange({
              ...selectedFilters,
              ...updateFilterSelection(props.availableFilters, { [id]: selectedFilterIds })
            });
          }}
          searchable={'searchable' in filterGroup ? filterGroup.searchable : undefined}
        />
      </React.Fragment>
    );
  };

  let filterBody;
  if ('tabs' in props) {
    const tabs = props.tabs.map(({ id, name, filterGroups }) => {
      const filterGroupCols = filterGroups.map(getFilterColumn);
      return (
        <Tab key={id} id={id} title={name}>
          <Row className="filter-selection-groups">
            {filterGroupCols}
          </Row>
        </Tab>
      );
    });
    filterBody = (
      <TabPanel tabAlignment="start">
        {tabs}
      </TabPanel>
    );
  } else {
    const filterGroupCols = props.filterGroups.map(getFilterColumn);
    filterBody = (
      <Row className="filter-selection-groups">
        {filterGroupCols}
      </Row>
    );
  }

  function getCurrentFilter() {
    if (!Object.values(selectedFilters).some(filter => filter.length)) {
      return {};
    }
    return selectedFilters;
  }

  const countMessage = () => {
    if (!props.countMessage) return null;
    if (hitCount === undefined) {
      return <Spinner />;
    }
    return isEmpty(getCurrentFilter()) ? t('FILTER_NONE_SELECTED_MESSAGE') : `${hitCount.toLocaleString(locale)} ${props.countMessage}`;
  };

  return (
    <div className="filter-selection">
      {filterBody}
      <Row>
        <div className="filter-count">
          {countMessage()}
        </div>
      </Row>
    </div>
  );
}
