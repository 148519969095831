import * as React from 'react';
import { partial } from 'lodash';
import { ITabComponentType, IBaseTabProps } from './tab-panel';

export interface ITabProps extends IBaseTabProps {
  id?: string,
  title: string | React.ReactElement
}

function isTabInitialised(index: any, selected: any, onClick: any) {
  return !(index === undefined || selected === undefined || onClick === undefined);
}

export class Tab extends React.Component<ITabProps> {
  //used for component identification
  public static Tag = ITabComponentType.Tab;

  public render() {
    if (!isTabInitialised(this.props.index, this.props.selected, this.props.onClick)) { return null; }

    let linkClass = this.props.selected ? "nav-link active show" : "nav-link show";
    if (typeof this.props.title === "string") {
      linkClass += " centered";
    }

    return <li id={this.props.id + "-tab"} className="nav-item" data-type="tab">
      <button className={linkClass} onClick={partial(this.props.onClick, this.props.index)} role="tab" aria-selected="true">
        {this.props.title}
      </button>
    </li>;
  }
}
