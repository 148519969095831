import { isEqual } from 'lodash';
import { useMemo, useState } from 'react';
import {
  Option
} from '../controls/optionPickerLib';
import { useTranslation } from '@lib/useTypedTranslation';

export type TimePeriod = number;

export interface TimePeriodPicker {
  timePeriod: Option<TimePeriod>,
  timePeriodOptions: Option<TimePeriod>[],
  handleTimePeriodSelect: (selected: Option<TimePeriod>) => void
}

export const useTimePeriodPicker = (params: { timePeriods: TimePeriod[] }): TimePeriodPicker => {
  const { t } = useTranslation(['timeState']);

  const timePeriodOptions: TimePeriodPicker['timePeriodOptions'] = useMemo(
    () => params.timePeriods.map((value) => ({
      name: value <= 2 ? t('LAST_N_HOURS', { count: value * 24, ns: 'timeState' }) : t('LAST_N_DAYS', { count: value, ns: 'timeState' }),
      value
    })),
    [t, params.timePeriods]
  );

  const [timePeriod, setTimePeriod] = useState<TimePeriodPicker['timePeriod']>(timePeriodOptions[0]);

  const handleTimePeriodSelect = (selected: TimePeriodPicker['timePeriod']) => {
    if (isEqual(selected, timePeriod)) { return; }
    setTimePeriod(selected);
  };

  return {
    timePeriod,
    timePeriodOptions,
    handleTimePeriodSelect
  };
};
