import * as React from 'react';
import * as am4charts from '@amcharts/amcharts4/charts';

import { useCollectItems } from './useCollectItems';

/** To be called on a collection of ready charts via useCollectItems */
const alignCharts = <T extends am4charts.XYChart>(chartsToSync: T[]) => {
  // https://www.amcharts.com/docs/v4/tutorials/syncing-width-of-the-vertical-axes-across-multiple-charts/
  function syncAxisWidth() {
    let maxWidth = 0;
    let allReady = true;

    chartsToSync.forEach((chart) => {
      if (chart.isReady()) {
        const axis = chart.yAxes.getIndex(0);
        maxWidth = maxWidth > axis.measuredWidth ? maxWidth : axis.measuredWidth;
      } else {
        allReady = false;
      }
    });

    if (allReady) {
      chartsToSync.forEach((chart) => {
        const axis = chart.yAxes.getIndex(0);
        axis.width = maxWidth;
      });
    }
  }

  chartsToSync.forEach((chart) => { chart.events.on("ready", syncAxisWidth); });
};

export type AlignChartsReturn<T extends am4charts.XYChart> = {
  addChart: (item: T) => T,
  clearCharts: () => void,
  charts: T[]
};

/** Aligns y axes of collected charts */
/* TODO: retest using testing-library hooks */
/* istanbul ignore next */
export const useAlignCharts = <T extends am4charts.XYChart>(chartCount: number): AlignChartsReturn<T> => {
  const { items, addItem, clearItems } = useCollectItems<T>(chartCount, (items) => dependencies.alignCharts(items));
  const addChart = React.useMemo(() => addItem, [addItem]);
  const clearCharts = React.useMemo(() => clearItems, [clearItems]);
  return { charts: items, addChart, clearCharts };
};

export const dependencies = {
  alignCharts
};
