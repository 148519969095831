import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { Toggle } from '../../../../components/controls/toggleSwitch';
import { AlertCount } from '../../../../services/core/overview';
import { CoreEstateOverview } from './core-estate-overview';

interface IProps {
  alerts: AlertCount
}

const StyledFilterToggleSubtitle = styled.span`
  font-weight: 600;
  margin-right: 0.5rem;
`;

const StyledFilterToggle = styled.span`
  margin-right: 2.5rem;
`;

export function CoreEstateOverviewTable(props: IProps) {
  const [showFilters, setShowFilters] = useState(false);
  const { t } = useTranslation();

  const onToggle = () => setShowFilters(showFilters => !showFilters);

  return (
    <>
      <div className="core-overview__titles-container"><div className="core-overview_table-container__title" data-id="core-overview_table-container__title">{t('CORE_ESTATE_OVERVIEW_TABLE_SUBTITLE')}</div>
        <div className="core-overview__filter-toggle__container" >
          <StyledFilterToggleSubtitle>{t('FILTER_TOGGLE_SUBTITLE', { ns: 'estateOverview' })}:</StyledFilterToggleSubtitle>
          <StyledFilterToggle data-id="core-overview__filter-toggle"><Toggle change={onToggle} active={showFilters} /></StyledFilterToggle>
        </div>
      </div>
      <CoreEstateOverview filtersShown={showFilters} alerts={props.alerts} />
    </>
  );
}
