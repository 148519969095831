import { useTranslation } from "@lib/useTypedTranslation";

import styled from "styled-components";

import { formatDataUsage, formatDateLastUpdated, formatTimeLength, formatValue } from "../../../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../../../components/data-table/types";
import { Tooltip } from "../../../../../components/tooltip/tooltip";

const TooltipText = styled.div`
  font-weight: 600;
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-normal);
  max-width: 14rem;
`;

const StyledTooltip = styled(Tooltip)`
  color: #7490b9;
  margin-left: 0.375rem;
`;

export const useDeviceApplicationsListColumns = () => {
  const { t } = useTranslation();

  const mobileTitleWithTooltip = <>
    {t('MOBILE_DATA')}
    <StyledTooltip popoverPortalSelector={'.core_device_applications_composition'} placement={'bottom'} popperContent={<TooltipText data-id="mobile-data-tooltip">{t('MOBILE_DATA_TOOLTIP_TEXT')}</TooltipText>}>
      <i aria-label='about mobile data' className="fa fa-question-circle" />
    </StyledTooltip>
  </>;
  const wifiTitleWithTooltip = <>
    {t('WIFI_DATA')}
    <StyledTooltip popoverPortalSelector={'.core_device_applications_composition'} placement={'bottom'} popperContent={<TooltipText data-id="wifi-data-tooltip">{t('WIFI_DATA_TOOLTIP_TEXT')}</TooltipText>}>
      <i aria-label='about wifi data' className="fa fa-question-circle" />
    </StyledTooltip>
  </>;
  const sharedIdTitleWithTooltip = <>
    {t('SHARED_ID', { ns: 'deviceApplications' })}
    <StyledTooltip popoverPortalSelector={'.core_device_applications_composition'} placement={'bottom'} popperContent={<TooltipText data-id="shared-id-tooltip">{t('SHARED_ID_TOOLTIP_TEXT', { ns: 'deviceApplications' })}</TooltipText>}>
      <i aria-label='about shared user id' className="fa fa-question-circle" />
    </StyledTooltip>
  </>;
  const sizeTitleWithTooltip = <>
    {t('SIZE')}
    <StyledTooltip popoverPortalSelector={'.core_device_applications_composition'} placement={'bottom'} popperContent={<TooltipText data-id="size-tooltip">{t('SIZE_TOOLTIP_TEXT')}</TooltipText>}>
      <i aria-label='about size' className="core-device_applications-size-icon fa fa-question-circle" />
    </StyledTooltip>
  </>;

  const columns: ITableColumn[] = [
    { id: 'name', title: t('NAME'), dataPath: 'name', sortable: true, processor: formatValue, width: '10rem' },
    { id: 'packageName', title: t('PACKAGE_NAME', { ns: 'deviceApplications' }), dataPath: 'packageName', sortable: true, processor: formatValue, width: '10rem' },
    { id: 'sharedUserId', title: sharedIdTitleWithTooltip, dataPath: 'sharedUserId', sortable: true, processor: formatValue, width: '10rem' },
    { id: 'version', title: t('VERSION'), dataPath: 'version', processor: formatValue, width: '6rem' },
    { id: 'updated', title: t('LAST_UPDATED'), dataPath: 'updated', processor: formatDateLastUpdated, sortable: true, width: '8rem' },
    { id: 'size', title: sizeTitleWithTooltip, dataPath: 'size', processor: formatDataUsage, sortable: true, textAlign: 'right', width: '7rem' },
    { id: 'lastUsed', title: t('LAST_USED'), dataPath: 'lastUsed', processor: formatDateLastUpdated, sortable: true, width: '8rem' },
    { id: 'averageUserActivity', title: t('AVERAGE_DAILY_USAGE'), dataPath: 'averageUserActivity', sortable: true, processor: formatTimeLength, width: '10rem' },
    { id: 'mobileDataUsage', title: mobileTitleWithTooltip, dataPath: 'mobileDataUsage', sortable: true, processor: formatDataUsage, width: '9rem' },
    { id: 'wifiDataUsage', title: wifiTitleWithTooltip, dataPath: 'wifiDataUsage', sortable: true, processor: formatDataUsage, width: '9rem' }
  ];

  return columns;
};
