import React, { useContext, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import * as am4charts from '@amcharts/amcharts4/charts';

import { IDeviceDischarge } from '../../../../../../services/core/devicePerformance';
import { NoSelectionOverlay } from '../../../../../../components/card/noSelectionOverlay';
import { IDeviceEventFilterState, deviceEventFilterGroupNames } from '../events/deviceEventsTypes';
import { DeviceEvent } from '../../../../../../services/core/eventsTypes';
import { getDeviceEventFilterGroups } from '../events/deviceEvents';
import { EditEvents, EditEventsAction, useEditEventsDispatch } from '../events/edit-events';
import { EventsShown } from '../events-shown';
import { DeviceInfoContext } from '../../../index';
import { chartNames, ChartName } from './lib/chartNames';
import { AverageDischargeChart } from '../../../../../../components/chart/averageDischargeChart';
import { useFeatureTogglesContext } from '../../../../../../context/featureToggles';

import './charts.css';
import '../events/deviceEvents.css';

interface IProps {
  data: IDeviceDischarge[],
  events: DeviceEvent[],
  eventFilters: IDeviceEventFilterState,
  dispatchEventFilters: React.Dispatch<EditEventsAction<ChartName>>,
  setDate: (date: string) => void,
  onMount: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function AverageDischarge30Days(props: IProps) {
  const { eventFilters, dispatchEventFilters, onMount } = props;
  const { platformType } = useContext(DeviceInfoContext);
  const { t } = useTranslation(['performance', 'editEvents', 'translation']);
  const [chartDataLoading, setChartDataLoading] = useState(false);
  const className = 'core-device_performance-30-day-average-discharge';
  const featureToggles = useFeatureTogglesContext();

  const {
    handleUpdate, handleApply, handleApplyAll, handleCancel
  } = useEditEventsDispatch(
    dispatchEventFilters, chartNames.averageDischarge30Days, setLoadingTrue
  );

  const title = t('AVERAGE_HOURLY_DISCHARGE_PAST_30DAYS', { ns: 'performance' });

  function setLoadingTrue() {
    setChartDataLoading(true);
  }

  return (
    <div className={className}>
      <div className="chart-header">
        <NoSelectionOverlay noSelectionText={t('NO_DATA_AVAILABLE', { ns: "translation" })} show={props.data.length === 0} />
        <div className="chart-title">{title}</div>
        <div className="chart-edit-events">
          <EventsShown eventFilters={eventFilters.appliedEventFilters} />
          <EditEvents
            subheader={`‘${title}’`}
            eventGroupNames={deviceEventFilterGroupNames}
            eventFilterGroups={getDeviceEventFilterGroups(platformType, featureToggles)}
            eventFilterState={eventFilters.currentEventFilters}
            handleUpdate={handleUpdate}
            handleApply={handleApply}
            handleCancel={handleCancel}
            handleApplyAll={handleApplyAll}
          />
        </div>
      </div>
      <AverageDischargeChart
        data={props.data}
        className={className}
        setLoading={setChartDataLoading}
        loading={chartDataLoading}
        setDate={props.setDate}
        events={props.events}
        onMount={onMount}
      />
    </div>
  );
}
