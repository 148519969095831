import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { usePrevious } from "./usePrevious";

export function usePathBasedParams<TParams extends { [K in keyof TParams]?: string; }>(pathRegExp: RegExp) {
  const { pathname } = useLocation();
  const params = useParams<TParams>();

  const [pathBasedParams, setPathBasedParams] = useState(params);

  if (pathname.match(pathRegExp) && pathBasedParams !== params) {
    setPathBasedParams(params);
  }

  const prevPathBasedParams = usePrevious(pathBasedParams);

  return { prevPathBasedParams, pathBasedParams };
}
