import { useTranslation } from '@lib/useTypedTranslation';

import Card from '../../components/card/card';
import { HomeLocationChange } from './homeLocationChange';

import './index.css';

export function BulkActions() {
  const { t } = useTranslation('bulkActions');

  return (
    <div className="bulk-actions-container">
      <Card noPadding={true}>
        <div className="bulk-actions">
          <div className='bulk-actions_header'>{t('BULK_ACTIONS')}</div>
          <div className="homeLocation_change">
            <HomeLocationChange />
          </div>
        </div>
      </Card>
    </div>
  );
}
