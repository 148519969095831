import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

type SignalLevelBar = {
  colour: string,
  level: number
};

interface IProps {
  bars: SignalLevelBar[],
  onChange: (level: number) => void,
  className?: string,
  disabled?: boolean
}

export const SignalLevelFilter = (props: IProps) => {
  const [level, setLevel] = useState(props.bars.length);
  const { t } = useTranslation('deviceNetwork');

  return (
    <Container className={props.className || ''}>
      {props.bars.map((bar, i) => {
        return <Bar
          disabled={props.disabled}
          aria-label={t('CHANGE_SIGNAL_LEVEL', { ns: 'deviceNetwork' })}
          key={i}
          height={`${(i + 1) * 7}px`}
          colour={!props.disabled && bar.level <= level ? bar.colour : 'inherit'}
          onClick={() => { setLevel(i + 1); props.onChange(i + 1); }}
        />;
      })}
    </Container>
  );
};

const Bar = styled.button<{ height: string, colour: string }>`
  display: inline-block;
  ${({ height, colour }) => `height: ${height}; background-color: ${colour};`}
  margin-left: -2px;
  width: 14px;
  align-self: flex-end;
  border: 2px solid black;
  cursor: pointer;

  &:disabled {
    outline: none;
    border: 2px solid lightGrey;
  }

  &:focus {
    outline: none;
    ${({ colour }) => colour === 'inherit' ? `border: 2px solid grey;` : `opacity: 0.7;`}
  }
`;

const Container = styled.div`
  display: flex;
`;
