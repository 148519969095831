import React from "react";
import { get, isNil } from "lodash";

export interface IProps {
  data: any,
  name: string,
  path: string
}

export function DetailsItem(props: IProps) {
  const value = get(props.data, props.path);

  return <div className="row details_row gutters" data-key={props.path}>
    <div className="col details_left">
      {props.name}:
    </div>
    <div className="col-auto details_right">
      {isNil(value) ? '-' : value}
    </div>
  </div>;
}
