import React, { useEffect, useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { AuthState, AUTH_CHANNEL, CognitoUserInterface, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Hub } from '@aws-amplify/core';
import { useErrorHandler } from '@lib/useErrorHandler';

import { parseElemezHostname } from '../../lib/url';
import { getConfig } from '../../services/config/config';
import { Authenticator } from './authenticator';
import { AppLoading } from '../loading/appLoading';
import { AuthProvider } from '../../context/auth';
import { AUTHENTICATOR_AUTHSTATE, handleExternalAuthEvent, checkUser } from './utils';
import { UserWrapper } from '../../containers/app/userWrapper';

export const AuthWrapper = () => {
  const [error, setError] = useState<Error>();
  const [authConfig, setAuthConfig] = useState<any>();
  const [authState, setAuthState] = useState<AuthState>(AuthState.SignIn);
  const [user, setUser] = useState<CognitoUserInterface | any>();
  // we use userChecked to ensure that we do not render anything until we have checked for an authenticated user
  const [userChecked, setUserChecked] = useState(false);

  useEffect(() => {
    // listen to messages about authState and user changes from other components, removes listener on un-mount
    // q.v. https://github.com/aws-amplify/amplify-js/blob/master/packages/amplify-ui-components/src/common/helpers.ts
    return onAuthUIStateChange((nextAuthState, authData) => {
      const authState = nextAuthState === AuthState.SignedOut ? AuthState.SignIn : nextAuthState;
      window.localStorage.setItem(AUTHENTICATOR_AUTHSTATE, authState);
      setUser(authData);
      setAuthState(authState);
      setUserChecked(true); // if authState change is triggered, user check is no longer required or was skipped via oauth
    });
  }, []);

  useEffect(() => {
    // listen to messages from Amplify core
    Hub.listen(AUTH_CHANNEL, handleExternalAuthEvent);
    return () => Hub.remove(AUTH_CHANNEL, handleExternalAuthEvent);
  }, []);

  useEffect(() => {
    const configure = async () => {
      try {
        const [brand] = parseElemezHostname();
        const url = `${window.location.origin}${window.location.pathname}`;
        const config = await getConfig(brand, url);
        Auth.configure({ Auth: config.userAuth });
        setAuthConfig(config);
      } catch (err) {
        setError(err);
      }
    };
    !authConfig && configure();
  }, [authConfig]);

  useEffect(() => {
    if (authConfig) {
      checkUser(setUserChecked);
    }
  }, [authConfig]);

  // rethrow any error after useEffect calls
  useErrorHandler(error);

  const userDataMissing = authState === AuthState.SignedIn && !user;
  if (!authConfig || !userChecked || userDataMissing) {
    return <AppLoading />;
  }

  const app = authState === AuthState.SignedIn ? <UserWrapper /> : <Authenticator />;

  return (
    <AuthProvider value={{ authConfig, authState, user }} >
      {app}
    </AuthProvider>
  );
};
