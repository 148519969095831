import { request, RequestInitWithRetry } from '../../lib/request';
export interface World {
  id: string,
  name: string
}

export const getWorlds = () => {
  return async (options: RequestInitWithRetry): Promise<World[]> => {
    const params = {
      path: '/api/worlds/worlds',
    };
    return request(params, options);
  };
};

export interface CurrentWorld {
  id: string,
  name: string,
  features: string[],
  deviceToken: string,
  hasAndroidDevices: boolean,
  hasWindowsDevices: boolean
}

export const getCurrentWorld = () => {
  return async (options: RequestInitWithRetry): Promise<CurrentWorld> => {
    const params = {
      path: '/api/worlds/currentWorld',
    };
    return request(params, options);
  };
};
