import { isUndefined } from 'lodash';
import React, { useContext, useMemo, useReducer } from 'react';
import { AgreementsIds } from '../services/agreements/agreements';
import { getAgreementsToAccept } from './agreementsLib';

export const AgreementsContext = React.createContext<AgreementsContextValue>(undefined);

export enum AgreementTypes {
  eula = 'eula',
  dpa = 'dpa'
}

export interface AgreementsContextState {
  requiredEULA: string,
  requiredDPA: string,
  acceptedEULA: string,
  acceptedDPA: string,
  agreementsToAccept: AgreementTypes[]
}

export interface AgreementsContextValue extends AgreementsContextState {
  updateProperties: (updates: Partial<AgreementsContextValue>) => void
}

interface AgreementsProviderProps {
  value: AgreementsContextState,
  children: React.ReactNode
}

export type AgreementsAction =
| { type: 'onAgreementsUpdate', updates: Partial<AgreementsContextValue> };

export function agreementsReducer(state: AgreementsContextState, action: AgreementsAction): AgreementsContextState {
  if (action.type === 'onAgreementsUpdate') {
    const { updates } = action;
    const getUpdatedAgreement = (agreement: keyof AgreementsIds) =>
      isUndefined(updates[agreement]) ? state[agreement] : updates[agreement];

    const newRequiredEULA = getUpdatedAgreement('requiredEULA');
    const newRequiredDPA = getUpdatedAgreement('requiredDPA');
    const newAcceptedEULA = getUpdatedAgreement('acceptedEULA');
    const newAcceptedDPA = getUpdatedAgreement('acceptedDPA');

    const agreementsWithUpdates: AgreementsIds = {
      requiredEULA: newRequiredEULA,
      requiredDPA: newRequiredDPA,
      acceptedEULA: newAcceptedEULA,
      acceptedDPA: newAcceptedDPA
    };

    const agreementsToAccept = getAgreementsToAccept(agreementsWithUpdates);
    const updatedState = {
      ...agreementsWithUpdates,
      agreementsToAccept
    };

    return updatedState;
  }
  return state;
}

export const AgreementsProvider = ({ children, value: initialState }: AgreementsProviderProps) => {
  const [state, dispatch] = useReducer(agreementsReducer, initialState);

  const contextFunctions = useMemo(() => {
    const updateProperties = (updates: Partial<AgreementsIds>) => {
      return dispatch({ type: 'onAgreementsUpdate', updates });
    };

    return {
      updateProperties
    };
  }, [dispatch]);

  const contextValue: AgreementsContextValue = {
    ...state,
    ...contextFunctions
  };

  return (
    <AgreementsContext.Provider value={contextValue}>
      {children}
    </AgreementsContext.Provider>
  );
};

export function useAgreementsContext() {
  const context = useContext(AgreementsContext);
  if (context === undefined) {
    throw new Error('useAgreementsContext must be used within a AgreementsProvider');
  }
  return context;
}
