import { SameValueAsKeys } from '../../../../../../../lib/typeUtils';

export const chartNamesArray = ['deviceUsage24Hours', 'dataUsage24Hours', 'deviceChargeLevel', 'deviceUsage30Days', 'dataUsage30Days', 'averageDischarge30Days'] as const;
export const networkChartNamesArray = ['deviceNetwork'] as const;

export type ChartName = typeof chartNamesArray[number];
export type NetworkChartName = typeof networkChartNamesArray[number];

export const isChartName = (name: string): name is ChartName | NetworkChartName => {
  return chartNamesArray.includes(name as any) || networkChartNamesArray.includes(name as any);
};

type ChartNames = SameValueAsKeys<typeof chartNamesArray>;
export const chartNames: ChartNames = chartNamesArray.reduce((acc, type) => ({ ...acc, [type]: type }), {} as ChartNames);

type NetworkChartNames = SameValueAsKeys<typeof networkChartNamesArray>;
export const networkChartName: NetworkChartNames = { deviceNetwork: 'deviceNetwork' } as const;
