import * as React from 'react';
import styled from 'styled-components';
import { ButtonColours } from '../../containers/app/themes';
import { Button, Variants } from './button';

export enum ProgressiveActionType {
  destructive = 'destructive',
  safe = 'safe'
}

export interface NeutralActionButton {
  text: string | JSX.Element,
  onClick: () => void,
  disabled: boolean,
  dataId: string
}

export interface ProgressiveActionButton extends NeutralActionButton {
  type: ProgressiveActionType
}

export interface FormActionButtonsProps {
  progressiveButton: ProgressiveActionButton,
  neutralButton: NeutralActionButton,
  className?: string
}

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

export const ActionButton = styled(Button)`
  margin-right: 0.625rem;
  margin-left: 0.625rem;
  &:last-child {
    margin-right: 0;
  }
`;
ActionButton.displayName = 'ActionButton';

export function FormActionButtons(props: FormActionButtonsProps) {
  const { className, progressiveButton, neutralButton } = props;
  return (
    <Row data-id="form-action-buttons" className={className}>
      <ActionButton
        ariaLabel="progressive button"
        dataId={progressiveButton.dataId}
        colour={progressiveButton.type === ProgressiveActionType.safe ? ButtonColours.blue : ButtonColours.red}
        text={progressiveButton.text}
        onClick={progressiveButton.onClick}
        disabled={progressiveButton.disabled}
      />
      <ActionButton
        ariaLabel="neutral button"
        dataId={neutralButton.dataId}
        colour={ButtonColours.grey}
        variant={Variants.ghost}
        text={neutralButton.text}
        onClick={neutralButton.onClick}
        disabled={neutralButton.disabled}
      />
    </Row>
  );
}
