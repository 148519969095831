import { ISortResult } from '../shared/common';
import { request, RequestInitWithRetry } from '../../lib/request';
import { AlarmStatus, AlertStatus } from '../config/alertConstants';

export interface AlertLevel {
  red: number,
  yellow: number
}

type AlertStatusCount = {
  [alert in AlertStatus]: AlertLevel
};

export type AlertCount = {
  all: number
} & AlertStatusCount;

type AlarmDetails = {
  enabled: true,
  count: number
} | {
  enabled: false
};

export type AlarmCount = {
  [alarm in AlarmStatus]: AlarmDetails
};

export function getAlarmCount() {
  return async (options: RequestInitWithRetry): Promise<AlarmCount> => {
    const input = {
      path: '/api/core/overview/alarmCount',
      method: 'post'
    };
    return request(input, options);
  };
}

export function getAlertCount() {
  return async (options: RequestInitWithRetry): Promise<AlertCount> => {
    const input = {
      path: '/api/core/overview/alertCount',
      method: 'post'
    };
    return request(input, options);
  };
}

export interface IDeviceCount {
  red: number,
  yellow: number,
  green: number,
  all: number
}

export function getDeviceCount() {
  return async (options: RequestInitWithRetry): Promise<IDeviceCount> => {
    const input = {
      path: '/api/core/overview/deviceCount',
      method: 'post'
    };
    return request(input, options);
  };
}

export interface IOverviewBody {
  limit: number,
  offset: number,
  sort?: ISortResult
}

export interface IOverviewData {
  alertingPercentage: number,
  problemDevices: number,
  warningDevices: number,
  goodDevices: number,
  totalDevices: number
}

export interface IHomeLocationData extends IOverviewData {
  homeLocation: string,
  homeLocationId: string
}

export interface IGroupData extends IOverviewData {
  group: string | null
}

interface BaseAlertOverviewData extends IOverviewData {
  homeLocationAndGroup: string,
  devicesAffected: number
}

export enum HomeLocationAndGroupDataType {
  HomeLocation = 'Home Location',
  Group = 'Group'
}

export interface HomeLocationAlertOverviewData extends BaseAlertOverviewData {
  homeLocationId: string,
  type: HomeLocationAndGroupDataType.HomeLocation
}

interface GroupAlertOverviewData extends BaseAlertOverviewData {
  type: HomeLocationAndGroupDataType.Group
}

export type HomeLocationAndGroupData = HomeLocationAlertOverviewData | GroupAlertOverviewData;

export interface IHomeLocationAndGroupOverviewBody extends IOverviewBody {
  filter: string,
  search?: string
}

export interface IHomeLocationListData {
  list: IHomeLocationData[],
  totalCount: number
}

export interface IGroupListData {
  list: IGroupData[],
  totalCount: number
}

export interface IHomeLocationAndGroupListData {
  list: HomeLocationAndGroupData[],
  totalCount: number
}

export function getHomeLocationAndGroupOverview(body: IHomeLocationAndGroupOverviewBody) {
  return async (options: RequestInitWithRetry): Promise<IHomeLocationAndGroupListData> => {
    const input = {
      path: '/api/core/overview/homeLocationAndGroup',
      body,
      method: 'post'
    };
    return request(input, options);
  };
}

export function getHomeLocationOverview(body: IOverviewBody) {
  return async (options: RequestInitWithRetry): Promise<IHomeLocationListData> => {
    const input = {
      path: '/api/core/overview/homeLocation',
      body,
      method: 'post'
    };
    return request(input, options);
  };
}

export function getGroupOverview(body: IOverviewBody) {
  return async (options: RequestInitWithRetry): Promise<IGroupListData> => {
    const input = {
      path: '/api/core/overview/group',
      body,
      method: 'post'
    };
    return request(input, options);
  };
}
