import React, { useCallback } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { TabPanel } from '../../../components/tabs/tab-panel';
import { LinkTab } from '../../../components/tabs/link-tab';
import { DeviceApplications } from './compositions/applications/device-applications';
import { DeviceProcesses } from './compositions/processes/device-processes';
import { DeviceLocation } from './compositions/device-location';
import { DevicePerformance } from './compositions/performance';
import { Card } from '../../../components/card/card';
import { DeviceOverview } from './compositions/device-overview';
import { getDeviceInfo, IDeviceInfo } from '../../../services/core/devices';
import { TabPanelHeader } from '../../../components/tabs/tab-panel-header';
import { Loading } from '../../../components/loading/loading';
import { RectangleLabel } from '../../../components/labels/rectangle-label';
import { Identifier } from '../../../services/config/config';
import { InvalidPage } from '../../../components/errors/invalidPage';
import { useWorldRequest } from '../../../lib/useWorldRequest';
import { useWorldSettingsContext } from '../../../context/worldSettings';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';
import { DeviceNetwork } from './compositions/network';

import './styles.css';
import { DeviceCoverage } from './compositions/coverage/deviceCoverage';
import { usePathBasedQueryString } from '@lib/usePathBasedQueryString';

interface IProps {
  tab: string,
  deviceId?: string
}

export const DeviceInfoContext = React.createContext({} as IDeviceInfo);

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: center;
`;

export function CoreDevice(props: IProps) {
  const { t } = useTranslation('translation');
  const { deviceId, tab } = props;
  const { queryString: search } = usePathBasedQueryString(/\/(core|device)\/devices\/(info|applications|processes|location|performance|signal|coverage)/);
  const queryString = search.toString();
  const { worldSettings } = useWorldSettingsContext();
  const routes = useWorldRoutes();
  const linkToDevices = routes.core.devicesList;

  const deviceInfoFetcher = useCallback(() => {
    if (deviceId) {
      return getDeviceInfo(deviceId);
    }
  }, [deviceId]);
  const { data: device, loading } = useWorldRequest(deviceInfoFetcher, { initialLoading: true });

  const deviceNotFound = <InvalidPage
    message={t('DEVICE_NOT_FOUND')}
    redirect={{
      message: t('REDIRECT_TO_DEVICES', { ns: 'redirect' }),
      path: linkToDevices
    }}
  />;

  if (!deviceId) {
    return deviceNotFound;
  }

  if (loading) {
    return <LoadingContainer data-id="core-device-loading"><Loading isLoading={true} transparentOverlay={false} /></LoadingContainer>;
  }

  if (!device) {
    return deviceNotFound;
  }

  const { platformType, manufacturer, model, serialNumber, assetTag, imei } = device;

  const infoTab = (
    <LinkTab id="overview" key="overview" link={`info${queryString}`} title={t('OVERVIEW')}>
      <DeviceOverview />
    </LinkTab>
  );

  const tabNames = ['info'];
  const tabs = [infoTab];

  if (platformType === 'android') {
    tabNames.push('applications');
    tabs.push(
      <LinkTab id="applications" key="applications" link={`applications${queryString}`} title={t('APPLICATIONS')}>
        <DeviceApplications />
      </LinkTab>
    );
  } else if (platformType === 'windows') {
    tabNames.push('processes');
    tabs.push(
      <LinkTab id="processes" key="processes" link={`processes${queryString}`} title={t('PROCESSES_TITLE', { ns: 'deviceProcesses' })}>
        <DeviceProcesses />
      </LinkTab>
    );
  }

  if (platformType) {
    tabNames.push('location');
    tabs.push(
      <LinkTab id="location" key="location" link={`location${queryString}`} title={t('DEVICE_LOCATION', { ns: 'deviceLocation' })} passSelectedToChild>
        <DeviceLocation />
      </LinkTab>
    );
    const PerformanceTab = DevicePerformance;
    tabNames.push('performance');
    tabs.push(
      <LinkTab id="performance" key="performance" link={`performance${queryString}`} title={t('PERFORMANCE')}>
        <PerformanceTab />
      </LinkTab>
    );
  }

  tabNames.push('signal');
  tabs.push(
    <LinkTab id="signal" key="signal" link={`signal${queryString}`} title={t('SIGNAL_TAB_TITLE', { ns: 'deviceNetwork' })} passSelectedToChild>
      <DeviceNetwork />
    </LinkTab>
  );

  tabNames.push('coverage');
  tabs.push(
    <LinkTab id="coverage" key="coverage" link={`coverage${queryString}`} title={t('DEVICE_COVERAGE_TAB_TITLE', { ns: 'deviceCoverage' })}>
      <DeviceCoverage />
    </LinkTab>
  );

  const tabIndex = tabNames.includes(tab) ? tabNames.indexOf(tab) : 0;

  let label: JSX.Element;
  if (platformType === 'android') {
    label = <RectangleLabel text="Android" type="green" />;
  } else if (platformType === 'windows') {
    label = <RectangleLabel text="Windows" type="blue" />;
  }

  const unavailable = 'n/a';
  let deviceIdentifier = `(SN: ${serialNumber || unavailable})`;
  if (worldSettings.deviceIdentifier === Identifier.assetTag) {
    deviceIdentifier = `(${t('ASSET_TAG')}: ${assetTag || unavailable})`;
  } else if (worldSettings.deviceIdentifier === Identifier.IMEI) {
    deviceIdentifier = `(IMEI: ${imei || unavailable})`;
  }

  return (
    <DeviceInfoContext.Provider value={device}>
      <div className="core_device_container">
        <Card noPadding={true}>
          <div className="core-device">
            <div className="core-device_tabs">
              <TabPanel defaultTabIndex={tabIndex} key="core" enableStatePreservation>
                <TabPanelHeader>
                  <div className="device-header">
                    <div className="title">
                      {manufacturer} {model} {deviceIdentifier} {label}
                    </div>
                    <div id="core-devices-breadcrumb">
                      <Link to={linkToDevices} className="devices-list_link">{t('DEVICES')}</Link>
                      <i className="fas fa-chevron-right" />
                      <span className="serialNumber-spacing">{device[worldSettings.deviceIdentifier]}</span>
                    </div>
                  </div>
                </TabPanelHeader>
                {tabs}
              </TabPanel>
            </div>
          </div>
        </Card>
      </div>
    </DeviceInfoContext.Provider>
  );
}
