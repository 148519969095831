import * as React from 'react';
import './rectangle-label.css';
interface IProps {
    text?: string,
    type: 'blue' | 'green'
  }

export function RectangleLabel(props: IProps) {
  const { text, type } = props;
  return (
    <div className={`rectangle-label rectangle-label--${type}`}>
      {text}
    </div>
  );
}
