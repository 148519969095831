import { roundTo3Places } from './dataConversion';
import * as self from './distanceConversion';

const METRES_PER_YARD = 0.9144 as const;
const YARDS_PER_METRE = 1.0936132983 as const;
const METRES_PER_MILE = 1609.344 as const;
const MILES_PER_METRE = 0.0006213712 as const;

/*
Distance conversion functions go from DB integer metre values to display values and visa versa.

We do not want to save metres values of zero, so conversions to metres enforce a minimum value of 1.

We either display imperial or metric distances.
'long' distances are miles/kilometres.
'short' distances are metres/yards.

When converting metres to long distance display values we round to 3 decimal places.
Long distance inputs should permit a max of 3 decimal places, i.e. should have a minimum value of 0.01.
N.B. Converting and storing long distance values with greater than 3 decimal places of accuracy may result in the displayed value not matching the input value,
so ensure input is properly validated and truncated at 3 decimal places.


When converting metres to short distance display values we round to the nearest integer.
N.B. This means that when working with yards, displayed saved values may not match original input values.
e.g. 999 yards (in UI input) -> 913.486m -> 913m (in DB) -> 998.468 yards -> 998 yards (in UI)
Short distances should always be displayed as integers.
Short distance inputs should permit integers only, i.e. should have a minimum value of 1.
When converting short distance display values to metres we truncate to an integer.
*/

/** use to ensure user input metres are integers greater than 0 */
export const metresToSavedMetres = (metres: number): number => {
  return Math.max(Math.trunc(metres), 1);
};

export const yardsToMetres = (yards: number): number => {
  return Math.max(Math.round(Math.trunc(yards) * METRES_PER_YARD), 1);
};

export const metresToYards = (metres: number): number => {
  return Math.round(metres * YARDS_PER_METRE);
};

export const milesToMetres = (miles: number): number => {
  return Math.max(Math.round(miles * METRES_PER_MILE), 1);
};

export const metresToMiles = (metres: number): number => {
  return roundTo3Places(metres * MILES_PER_METRE);
};

export const kilometresToMetres = (kilometres: number): number => {
  return Math.max(Math.round(kilometres * 1000), 1);
};

export const metresToKilometres = (metres: number): number => {
  return roundTo3Places(metres / 1000);
};

export const metresToLongDistance = (metres: number, metricDistanceEnabled: boolean): number => {
  return metricDistanceEnabled ? self.metresToKilometres(metres) : self.metresToMiles(metres);
};

export const longDistanceToMetres = (distance: number, metricDistanceEnabled: boolean): number => {
  return metricDistanceEnabled ? self.kilometresToMetres(distance) : self.milesToMetres(distance);
};

export const metresToShortDistance = (metres: number, metricDistanceEnabled: boolean): number => {
  return metricDistanceEnabled ? metres : self.metresToYards(metres);
};

export const shortDistanceToMetres = (distance: number, metricDistanceEnabled: boolean): number => {
  return metricDistanceEnabled ? self.metresToSavedMetres(distance) : self.yardsToMetres(distance);
};

// context value to append to translation key, e.g. FOO_metric, FOO_metric_other
export enum DistanceTranslationContext {
  METRIC = 'metric',
  IMPERIAL = 'imperial'
}

export const getDistanceTranslationContext = (metricDistanceEnabled: boolean) => {
  return metricDistanceEnabled ? DistanceTranslationContext.METRIC : DistanceTranslationContext.IMPERIAL;
};
