import * as React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';

import { InvalidPage } from '../components/errors/invalidPage';
import { CoreApplication } from '../containers/core/application/coreApplication';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { useMemo } from 'react';
import { usePathBasedQueryString } from '@lib/usePathBasedQueryString';

export const CoreApplicationRoute = () => {
  const { tab } = useParams<{ tab: string }>();
  const { queryString: search } = usePathBasedQueryString(/\/core\/applications/);
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const applicationId = searchParams.get('id');
  const routes = useWorldRoutes();
  const { t } = useTranslation('redirect');

  const applicationNotFound = <InvalidPage
    message={t('APPLICATION_NOT_FOUND', { ns: 'redirect' })}
    redirect={{
      message: t('REDIRECT_TO_APPLICATIONS', { ns: 'redirect' }),
      path: routes.core.applicationsList
    }}
  />;

  if (!applicationId) {
    return applicationNotFound;
  }

  return (
    <CoreApplication tab={tab} id={applicationId} />
  );
};
