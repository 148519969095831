
import * as React from 'react';
import { useState, useRef, useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { isEqual } from 'lodash';

import { AlarmThresholdSettings } from './alarmThresholds';
import { PreventNavigation } from '../../../../components/navigation/preventNavigation';
import { BatteryTypeSettings, WorldSettings } from '../../../../services/config/config';

import '../settings.css';
import { FormControls } from '../../../../components/forms/formControls';

export interface IProps {
  worldSettings: WorldSettings,
  saveData: (data: { worldSettings: WorldSettings, batteryTypeSettings?: BatteryTypeSettings[] }) => void
}

interface SubmitResult {
  success: boolean,
  message: string
}

export function AlarmsSettingsPage(props: IProps) {
  const { t } = useTranslation('settings');
  const [worldSettings, setWorldSettings] = useState<WorldSettings>(props.worldSettings);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [submitResult, setSubmitResult] = useState<SubmitResult>(undefined);
  const formRef = useRef(null);
  const areButtonsDisabled = useMemo(() => {
    const hasSettingsChanged = !isEqual(worldSettings, props.worldSettings);
    const hasError = submitResult?.success === false;
    return !hasSettingsChanged || hasError;
  }, [worldSettings, props.worldSettings, submitResult?.success]);

  function onDataChanged(data: WorldSettings) {
    setWorldSettings(data);
  }

  function handleCancel() {
    setWorldSettings(props.worldSettings);
  }

  async function handleSubmit() {
    if (!formRef.current.reportValidity() || submitInProgress) {
      return;
    }
    try {
      setSubmitResult(undefined);
      setSubmitInProgress(true);
      await props.saveData({ worldSettings: worldSettings });
      setSubmitResult({ message: t('SAVE_SUCCESS'), success: true });
      setSubmitInProgress(false);
    } catch (e) {
      setSubmitResult({ message: t('SAVE_FAIL'), success: false });
      setSubmitInProgress(false);
    }
  }

  return (
    <div className='threshold-settings-composition alarms'>
      <form ref={formRef}>
        <PreventNavigation enabled={!areButtonsDisabled} />
        <AlarmThresholdSettings onDataChanged={onDataChanged} worldSettings={worldSettings} submitInProgress={submitInProgress} />
        <FormControls
          submitResult={{
            status: submitInProgress ? 'loading' : submitResult ? submitResult.success ? 'success' : 'error' : undefined,
            message: submitResult?.message
          }}
          cancelDisabled={areButtonsDisabled}
          submitDisabled={areButtonsDisabled}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          mode='save'
        />
      </form>
    </div>
  );
}
