import React from "react";
import { useTimeout } from "./useTimeout";

export const useLongPress = (
  callback: () => void,
  intervalMs: number
) => {
  const { addTimeout, timeoutIdsRef } = useTimeout();

  const repeat = () => {
    callback();

    addTimeout(repeat, intervalMs);
  };

  const pointerDown: React.PointerEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();

    e.currentTarget.setPointerCapture(e.pointerId);

    repeat();
  };

  const pointerUp: React.PointerEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();

    e.currentTarget.releasePointerCapture(e.pointerId);

    timeoutIdsRef.current.forEach(clearTimeout);
    timeoutIdsRef.current = [];
  };

  return { pointerDown, pointerUp };
};
