import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import './pagination.css';
import { Button } from '../../components/buttons/button';
import { ButtonColours } from '../../containers/app/themes';
import styled from 'styled-components';

const StyledPaginationPrev = styled(Button)`
  border-radius: 5px 0 0 5px;
  margin-right: 10px;
  height: 28px;
`;

StyledPaginationPrev.displayName = 'PaginationPrev';

const StyledPaginationNext = styled(Button)`
  border-radius: 0 5px 5px 0;
  margin-left: 10px;
  margin-right: 20px;
  height: 28px;
`;

StyledPaginationNext.displayName = 'PaginationNext';

interface IPaginationProps {
  limit: number,
  offset: number,
  doNext: () => void,
  doPrev: () => void,
  currentTotal: number,
  message: any,
  customStatus?: JSX.Element
}

const StyledNavIcon = styled.i<{ disabled: boolean }>`
  ${({ disabled }) => disabled ? 'color: #d8d8d8' : 'color: inherit'}
`;

StyledNavIcon.displayName = 'NavIcon';

export const Pagination: React.FC<IPaginationProps> = (props) => {
  const { t } = useTranslation();
  const range = calculatePageRange(props.offset, props.limit, props.currentTotal);

  const statusView = props.customStatus ? props.customStatus : <span className="statusText"><b>{props.currentTotal}</b> {props.message}</span>;

  return (<div className="pagination--container" data-total={props.currentTotal}>
    <div className="row">
      <div className="p-2">
        {statusView}
      </div>
      <div className="ml-auto p-2">
        <span>
          <StyledPaginationPrev id="listPagePrevBtn" colour={ButtonColours.grey} iconStyle="fa fa-chevron-left" disabled={disablePrevButton(props.offset)} onClick={() => props.doPrev()} iconComponent={<StyledNavIcon disabled={disablePrevButton(props.offset)} className="fa fa-chevron-left"/>} dataId='pagination-pagePrev' />
        </span>
        <span className="pageText"><b>{range}</b> {t('OF')} {props.currentTotal}</span>
        <span>
          <StyledPaginationNext id="listPageNextBtn" colour={ButtonColours.grey} iconStyle="fa fa-chevron-right" disabled={disableNextButton(props.offset, props.limit, props.currentTotal)} onClick={() => props.doNext()} iconComponent={<StyledNavIcon disabled={disableNextButton(props.offset, props.limit, props.currentTotal)} className="fa fa-chevron-right"/>} dataId='pagination-pageNext' />
        </span>
      </div>
    </div>
  </div>);
};

export const disablePrevButton = (offset: number) => {
  return offset === 0;
};

export const disableNextButton = (offset: number, limit: number, total: number) => {
  return offset + limit >= total;
};

export const calculatePageRange = (offset: number, limit: number, total: number) => {
  return total === 0 ? '0' : (offset + 1) + ' - ' + Math.min(offset + limit, total);
};
