/* eslint-disable lines-between-class-members */

import { isError } from 'lodash';

import { devLogger } from './logger';

/*
The Either type gives us a generic pattern for returning success and failure values that can be reasoned about using the type system.

This is in contrast to the traditional JS pattern of throwing an expected error on failure,
where Typescript has no knowledge of the possible expected errors that a function may throw.

For example, consider handling possible error cases from a middleware call (e.g. 'User with email already exists').
In the try/catch paradigm we would pass a version of the flex-api error on to the UI as a failed request and parse the error message to identify the cause.
Here we would instead return a 200 response to the UI for both success and failure (our request to the *middleware* was successful),
and discriminate between them on the basis of the content of the response.

When using this pattern we should generally not need to catch errors in the calling location,
since the expected failure cases should be handled by the failure return type.
However, some processes are liable to unpredictable failures (e.g. api calls).
These could be wrapped to convert unexpected errors into a generic 'unexpected' failure (e.g. UnexpectedFailureInfo).
Also see EitherOrUnexpected below, which is designed to handle unexpected failures within the 'Either' pattern.

The success and failure handlers are chainable.
In the non matching case (e.g. onSuccess on a Failure instance), they are no-ops that simply return the instance.
In the matching case (e.g. onSuccess on a Success instance), they call the provided callback with the current value
and return the instance unaltered for further chaining.

Passing true as a second argument to a handler will break the chain and return the *return value* of the callback, if called, or undefined.
This allows us to use async/await semantics:
e.g.
await response.onSuccess((value) => returnsPromise(value), true);

Also see EitherFailureInfo and EitherResponse below.

e.g.
type SuccessData = {
  ok: true,
  data: { foo: string }
};

type FailureData = {
  ok: false,
  data: { bar: string }
};

const myApiCall = async (): Promise<Either<SuccessData['data'], FailureData['data']>> => {
  try {
    const response = await myRequest();  // may return SuccessData or FailureData
    if (response.ok) {
      return success(response.data);
    }
    return failure(response.data);
  catch {
    // the api call may fail unexpectedly -  a failure result from the perspective of the calling location
    return failure(null);
  }
};

const response = await myApiCall();
response
  .onSuccess((value) => console.log('success', value.foo))
  .onFailure((value) => console.log('failure', typeof value === string ? value : value.bar));
*/

export type Either<S, F> = Success<S, F> | Failure<S, F>;

abstract class Base<S, F> {
  abstract isSuccess(): boolean;

  abstract isFailure(): boolean;

  abstract onSuccess<B, R extends boolean>(cb: (value: S) => B, returnValue?: R): R extends true ? B : Either<S, F>;

  abstract onFailure<B, R extends boolean>(cb: (value: F) => B, returnValue?: R): R extends true ? B : Either<S, F>;
}

export class Success<S, F> extends Base<S, F> {
  readonly value: S;

  constructor(value: S) {
    super();
    this.value = value;
  }

  isSuccess(): this is Success<S, F> {
    return true;
  }

  isFailure(): this is Failure<S, F> {
    return false;
  }

  onSuccess<B, R extends true>(cb: (value: S) => B, returnValue: R): B;
  onSuccess<B, R extends false>(cb: (value: S) => B, returnValue: R): Either<S, F>;
  onSuccess<B>(cb: (value: S) => B): Either<S, F>;
  onSuccess<B, R extends boolean>(cb: (value: S) => B, returnValue?: R): B | Either<S, F> {
    const out = cb(this.value);
    return returnValue === true ? out : this;
  }

  onFailure<B, R extends true>(_: (value: F) => B, returnValue: R): B;
  onFailure<B, R extends false>(_: (value: F) => B, returnValue: R): Either<S, F>;
  onFailure<B>(_: (value: F) => B): Either<S, F>;
  onFailure<B, R extends boolean>(_: (value: F) => B, returnValue?: R): B | Either<S, F> {
    return returnValue === true ? undefined : this;
  }
}

export class Failure<S, F> extends Base<S, F> {
  readonly value: F;

  constructor(value: F) {
    super();
    this.value = value;
  }

  isSuccess(): this is Success<S, F> {
    return false;
  }

  isFailure(): this is Failure<S, F> {
    return true;
  }

  onSuccess<B, R extends true>(_: (value: S) => B, returnValue: R): B;
  onSuccess<B, R extends false>(_: (value: S) => B, returnValue: R): Either<S, F>;
  onSuccess<B>(_: (value: S) => B): Either<S, F>;
  onSuccess<B, R extends boolean>(_: (value: S) => B, returnValue?: R): B | Either<S, F> {
    return returnValue === true ? undefined : this;
  }

  onFailure<B, R extends true>(cb: (value: F) => B, returnValue: R): B;
  onFailure<B, R extends false>(cb: (value: F) => B, returnValue: R): Either<S, F>;
  onFailure<B>(cb: (value: F) => B): Either<S, F>;
  onFailure<B, R extends boolean>(cb: (value: F) => B, returnValue?: R): B | Either<S, F> {
    const out = cb(this.value);
    return returnValue === true ? out : this;
  }
}

/** construct a success instance */
export const success = <S, F>(value: S): Either<S, F> => {
  return new Success<S, F>(value);
};

/** construct a failure instance */
export const failure = <S, F>(value: F): Either<S, F> => {
  return new Failure<S, F>(value);
};

/*
FailureInfo supports a pattern for identifying failures by code, and passing around failure data if required.

e.g.
enum Reason {
  NOT_FOUND = 'NOT_FOUND',
  OTHER = 'OTHER'
}
type Data = { idsNotFound: string[] };
type MyFailure = FailureInfo<Reason>;
type MyFailureWithData = FailureInfo<Reason, Data>;

e.g. usage with Either:

type SuccessData = {
  ok: true,
  data: { foo: string }
};

type FailureData = {
  ok: false,
  code: string,
  data: { bar: string }
};

const myApiCall = async (): Promise<Either<SuccessData['data'], FailureInfo<Reason, FailureData['data']>> => {
  try {
    const response = await myRequest();  // may return SuccessData or FailureData
    if (response.ok) {
      return success(response.data);
    } else if (response.code === Reason.NOT_FOUND) {
      return failure({ code: Reason.NOT_FOUND, data: response.data});
    }
    return failure({ code: Reason.OTHER, data: response.data});
  } catch {
    // or see UnexpectedFailureInfo below
    return failure({ code: Reason.OTHER, data: null});
  }
};

const response = await myApiCall();
response
  .onSuccess((value) => console.log('success', value.foo))
  .onFailure((value) => console.log('failure', value.code, value.data.bar))
*/

// if the second generic param D is omitted, the resulting type does not have a data property
export type FailureInfo<C extends string, D = undefined> = {
  code: C
} & (D extends undefined ? {} : {
  data: D
});


// used to discriminate between expected and unexpected failures
export enum FailureKind {
  EXPECTED = 'EXPECTED',
  UNEXPECTED = 'UNEXPECTED',
}

export type ExpectedFailureInfo<C extends string, D = undefined> = {
  kind: FailureKind.EXPECTED
} & FailureInfo<C, D>;

// generic failure to wrap errors or other unexpected failures
export type UnexpectedFailureInfo = {
  kind: FailureKind.UNEXPECTED,
  message?: string,
  error?: Error
};

// failure info that may be expected or unexpected
export type EitherFailureInfo<C extends string, D = undefined> = ExpectedFailureInfo<C, D> | UnexpectedFailureInfo;

export const isFailureInfo = <C extends string, D = undefined>(failure: EitherFailureInfo<C, D>): failure is ExpectedFailureInfo<C, D> => {
  return failure.kind === FailureKind.EXPECTED;
};

export const isUnexpectedFailureInfo = <C extends string, D = undefined>(failure: EitherFailureInfo<C, D>): failure is UnexpectedFailureInfo => {
  return failure.kind === FailureKind.UNEXPECTED;
};

/*
EitherOrUnexpected provides a pattern for discriminating between successes, expected failures and unexpected failures,
using FailureInfo and UnexpectedFailureInfo as the failure types.

This is intended for use with out most common use case for potential failures: middleware calls.
These may always fail unexpectedly, and we may also wish to handle specific expected failures.
*/

export type EitherOrUnexpected<S, C extends string, D = undefined> = ExpectedSuccess<S, C, D> | ExpectedFailure<S, C, D> | UnexpectedFailure<S, C, D>;

abstract class BaseUnexpected<S, C extends string, D = undefined> {
  abstract isSuccess(): boolean;

  abstract isFailure(): boolean;

  abstract isExpectedFailure(): boolean;

  abstract isUnexpectedFailure(): boolean;

  abstract onSuccess<B, R extends boolean>(cb: (value: S) => B, returnValue?: R): R extends true ? B : EitherOrUnexpected<S, C, D>;

  abstract onFailure<B, R extends boolean>(cb: (value: EitherFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue?: R): R extends true ? B : EitherOrUnexpected<S, C, D>;

  abstract onExpectedFailure<B, R extends boolean>(cb: (value: ExpectedFailureInfo<C, D>) => B, returnValue?: R): R extends true ? B : EitherOrUnexpected<S, C, D>;

  abstract onUnexpectedFailure<B, R extends boolean>(cb: (value: UnexpectedFailureInfo) => B, returnValue?: R): R extends true ? B : EitherOrUnexpected<S, C, D>;
}

export class ExpectedSuccess<S, C extends string, D = undefined> extends BaseUnexpected<S, C, D> {
  readonly value: S;

  constructor(value: S) {
    super();
    this.value = value;
  }

  isSuccess(): this is ExpectedSuccess<S, C, D> {
    return true;
  }

  isFailure(): this is ExpectedFailure<S, C, D> | UnexpectedFailure<S, C, D> {
    return false;
  }

  isExpectedFailure(): this is ExpectedFailure<S, C, D> {
    return false;
  }

  isUnexpectedFailure(): this is UnexpectedFailure<S, C, D> {
    return false;
  }

  onSuccess<B, R extends true>(cb: (value: S) => B, returnValue: R): B;
  onSuccess<B, R extends false>(cb: (value: S) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onSuccess<B>(cb: (value: S) => B): EitherOrUnexpected<S, C, D>;
  onSuccess<B, R extends boolean>(cb: (value: S) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    const out = cb(this.value);
    return returnValue === true ? out : this;
  }

  onFailure<B, R extends true>(_: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue: R): B;
  onFailure<B, R extends false>(_: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onFailure<B>(_: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B): EitherOrUnexpected<S, C, D>;
  onFailure<B, R extends boolean>(_: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    return returnValue === true ? undefined : this;
  }

  onExpectedFailure<B, R extends true>(_: (value: ExpectedFailureInfo<C, D>) => B, returnValue: R): B;
  onExpectedFailure<B, R extends false>(_: (value: ExpectedFailureInfo<C, D>) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onExpectedFailure<B>(_: (value: ExpectedFailureInfo<C, D>) => B): EitherOrUnexpected<S, C, D>;
  onExpectedFailure<B, R extends boolean>(_: (value: ExpectedFailureInfo<C, D>) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    return returnValue === true ? undefined : this;
  }

  onUnexpectedFailure<B, R extends true>(_: (value: UnexpectedFailureInfo) => B, returnValue: R): B;
  onUnexpectedFailure<B, R extends false>(_: (value: UnexpectedFailureInfo) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onUnexpectedFailure<B>(_: (value: UnexpectedFailureInfo) => B): EitherOrUnexpected<S, C, D>;
  onUnexpectedFailure<B, R extends boolean>(_: (value: UnexpectedFailureInfo) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    return returnValue === true ? undefined : this;
  }
}

export class ExpectedFailure<S, C extends string, D = undefined> extends BaseUnexpected<S, C, D> {
  readonly value: ExpectedFailureInfo<C, D>;

  constructor(value: ExpectedFailureInfo<C, D>) {
    super();
    this.value = value;
  }

  isSuccess(): this is ExpectedSuccess<S, C, D> {
    return false;
  }

  isFailure(): this is ExpectedFailure<S, C, D> | UnexpectedFailure<S, C, D> {
    return true;
  }

  isExpectedFailure(): this is ExpectedFailure<S, C, D> {
    return true;
  }

  isUnexpectedFailure(): this is UnexpectedFailure<S, C, D> {
    return false;
  }

  onSuccess<B, R extends true>(_: (value: S) => B, returnValue: R): B;
  onSuccess<B, R extends false>(_: (value: S) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onSuccess<B>(_: (value: S) => B): EitherOrUnexpected<S, C, D>;
  onSuccess<B, R extends boolean>(_: (value: S) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    return returnValue === true ? undefined : this;
  }

  onFailure<B, R extends true>(cb: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue: R): B;
  onFailure<B, R extends false>(cb: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onFailure<B>(cb: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B): EitherOrUnexpected<S, C, D>;
  onFailure<B, R extends boolean>(cb: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    const out = cb(this.value);
    return returnValue === true ? out : this;
  }

  onExpectedFailure<B, R extends true>(cb: (value: ExpectedFailureInfo<C, D>) => B, returnValue: R): B;
  onExpectedFailure<B, R extends false>(cb: (value: ExpectedFailureInfo<C, D>) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onExpectedFailure<B>(cb: (value: ExpectedFailureInfo<C, D>) => B): EitherOrUnexpected<S, C, D>;
  onExpectedFailure<B, R extends boolean>(cb: (value: ExpectedFailureInfo<C, D>) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    const out = cb(this.value);
    return returnValue === true ? out : this;
  }

  onUnexpectedFailure<B, R extends true>(_: (value: UnexpectedFailureInfo) => B, returnValue: R): B;
  onUnexpectedFailure<B, R extends false>(_: (value: UnexpectedFailureInfo) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onUnexpectedFailure<B>(_: (value: UnexpectedFailureInfo) => B): EitherOrUnexpected<S, C, D>;
  onUnexpectedFailure<B, R extends boolean>(_: (value: UnexpectedFailureInfo) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    return returnValue === true ? undefined : this;
  }
}

export class UnexpectedFailure<S, C extends string, D = undefined> extends BaseUnexpected<S, C, D> {
  readonly value: UnexpectedFailureInfo;

  constructor(value: UnexpectedFailureInfo) {
    super();
    this.value = value;
  }

  isSuccess(): this is ExpectedSuccess<S, C, D> {
    return false;
  }

  isFailure(): this is ExpectedFailure<S, C, D> | UnexpectedFailure<S, C, D> {
    return true;
  }

  isExpectedFailure(): this is ExpectedFailure<S, C, D> {
    return false;
  }

  isUnexpectedFailure(): this is UnexpectedFailure<S, C, D> {
    return true;
  }

  onSuccess<B, R extends true>(_: (value: S) => B, returnValue: R): B;
  onSuccess<B, R extends false>(_: (value: S) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onSuccess<B>(_: (value: S) => B): EitherOrUnexpected<S, C, D>;
  onSuccess<B, R extends boolean>(_: (value: S) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    return returnValue === true ? undefined : this;
  }

  onFailure<B, R extends true>(cb: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue: R): B;
  onFailure<B, R extends false>(cb: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onFailure<B>(cb: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B): EitherOrUnexpected<S, C, D>;
  onFailure<B, R extends boolean>(cb: (value: ExpectedFailureInfo<C, D> | UnexpectedFailureInfo) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    const out = cb(this.value);
    return returnValue === true ? out : this;
  }

  onExpectedFailure<B, R extends true>(_: (value: ExpectedFailureInfo<C, D>) => B, returnValue: R): B;
  onExpectedFailure<B, R extends false>(_: (value: ExpectedFailureInfo<C, D>) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onExpectedFailure<B>(_: (value: ExpectedFailureInfo<C, D>) => B): EitherOrUnexpected<S, C, D>;
  onExpectedFailure<B, R extends boolean>(_: (value: ExpectedFailureInfo<C, D>) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    return returnValue === true ? undefined : this;
  }

  onUnexpectedFailure<B, R extends true>(cb: (value: UnexpectedFailureInfo) => B, returnValue: R): B;
  onUnexpectedFailure<B, R extends false>(cb: (value: UnexpectedFailureInfo) => B, returnValue: R): EitherOrUnexpected<S, C, D>;
  onUnexpectedFailure<B>(cb: (value: UnexpectedFailureInfo) => B): EitherOrUnexpected<S, C, D>;
  onUnexpectedFailure<B, R extends boolean>(cb: (value: UnexpectedFailureInfo) => B, returnValue?: R): B | EitherOrUnexpected<S, C, D> {
    const out = cb(this.value);
    return returnValue === true ? out : this;
  }
}

/** construct an 'expected' success instance */
export const expectedSuccess = <S, C extends string, D = undefined>(value: S): EitherOrUnexpected<S, C, D> => {
  return new ExpectedSuccess<S, C, D>(value);
};

/** construct an expected failure instance */
export const expectedFailure = <S, C extends string, D = undefined>(value: ExpectedFailureInfo<C, D>): EitherOrUnexpected<S, C, D> => {
  return new ExpectedFailure<S, C, D>(value);
};

/** construct an unexpected failure instance */
export const unexpectedFailure = <S, C extends string, D = undefined>(value: UnexpectedFailureInfo): EitherOrUnexpected<S, C, D> => {
  return new UnexpectedFailure<S, C, D>(value);
};

/** construct an expected failure instance from code only */
export const expectedFailureFromCode = <S, C extends string>(code: C): EitherOrUnexpected<S, C, undefined> => {
  return expectedFailure({ kind: FailureKind.EXPECTED, code });
};

/** construct an expected failure instance from code and data */
export const expectedFailureFrom = <S, C extends string, D = undefined>(code: C, data: D): EitherOrUnexpected<S, C, D> => {
  if (data === undefined) {
    return expectedFailureFromCode(code);
  }
  return expectedFailure({ kind: FailureKind.EXPECTED, code, data } as unknown as ExpectedFailureInfo<C, D>);
};

/** construct an unexpected failure from an error or message */
export const unexpectedFailureFrom = <S, C extends string, D = undefined>(value?: Error | string): EitherOrUnexpected<S, C, D> => {
  const error = isError(value) ? value : undefined;
  const message = isError(value) ? value.message : value;
  devLogger.error(`Unexpected failure: ${message || 'unknown'}`);
  return unexpectedFailure({ kind: FailureKind.UNEXPECTED, message, error });
};

/*
EitherResponse provides a pattern for discriminating between success and failure responses from the middleware.

The failure code and data generics are optional, since we may not always have failure data, and code may be determined in the UI.

.e.g.
const myRequest = async (): Promise<EitherResponse<SuccessData, FailureReason, FailureData>> => {};
*/

export type SuccessResponse<S> = {
  ok: true,
  data: S
};

export type FailureResponse<C extends string = undefined, D = undefined> = {
  ok: false
} & (C extends undefined ? {} : {
  code: C
}) & (D extends undefined ? {} : {
  data: D
});

export type EitherResponse<S, C extends string = undefined, F = undefined> = SuccessResponse<S> | FailureResponse<C, F>;

export const isSuccessResponse = <S, C extends string = undefined, F = undefined>(response: EitherResponse<S, C, F>): response is SuccessResponse<S> => {
  return response.ok === true;
};

export const isFailureResponse = <S, C extends string = undefined, F = undefined>(response: EitherResponse<S, C, F>): response is FailureResponse<C, F> => {
  return response.ok === false;
};
