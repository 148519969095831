import { isNil } from "lodash";
import { useTranslation } from "@lib/useTypedTranslation";

import { Link } from "react-router-dom";

import { encode } from "../../../../lib/base64";
import { formatDateLastUpdated, formatValue } from "../../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../../components/data-table/types";
import { useWorldRoutes } from "../../../../routes/parts/allWorldRoutes";
import { useWorldSettingsContext } from '../../../../context/worldSettings';
import { rearrangeList } from '../../../../lib/rearrangeList';
import { useMemo } from "react";

const getDeviceIdLink = (value: any, rowData: any, path: string) => {
  if (isNil(rowData)) {
    return formatValue(null);
  }
  return <Link to={`${path}?id=${encodeURIComponent(encode(rowData.id))}`}>{formatValue(value)}</Link>;
};

export function useAnomalyDevicesListColumns() {
  const ns = 'translation';
  const { t } = useTranslation(ns);
  const routes = useWorldRoutes();
  const { worldSettings } = useWorldSettingsContext();

  const columns = useMemo(() => {

    function getDeviceIdLinkProcessor(value: any, rowData: any) { // JMTODO: FIx rowData type!
      return getDeviceIdLink(value, rowData, routes.core.deviceOverview);
    }

    const columns: ITableColumn[] = [
      { id: 'serialNumber', title: t('SERIAL_NUMBER'), dataPath: 'serialNumber', sortable: true, processor: formatValue, width: '12rem' },
      { id: 'assetTag', title: t('ASSET_TAG'), dataPath: 'assetTag', sortable: true, processor: formatValue, width: '10rem', textAlign: 'left' },
      { id: 'imei', title: t('IMEI'), dataPath: 'imei', sortable: true, processor: formatValue, width: '9rem' },
      { id: 'model', title: t('MODEL'), dataPath: 'model', sortable: true, processor: formatValue, width: '9rem' },
      { id: 'group', title: t('GROUPS'), dataPath: 'group', processor: formatValue, width: '12rem' },
      { id: 'homeLocation', title: t('HOME_LOCATION'), dataPath: 'homeLocation.name', processor: formatValue, sortable: false, width: '10rem' },
      { id: 'updated', title: t('LAST_SEEN'), dataPath: 'updated', processor: formatDateLastUpdated, sortable: true, width: '9rem' },
    ];

    const deviceIdentifier = worldSettings.deviceIdentifier;

    const updatedColumn: ITableColumn[] = rearrangeList(columns, deviceIdentifier);
    updatedColumn[0].processor = getDeviceIdLinkProcessor;

    return updatedColumn;
  }, [worldSettings, routes.core.deviceOverview, t]);

  return columns;
}
