import React, { useContext, useMemo, useReducer } from 'react';

import { WorldSettings } from '../services/config/config';

export interface WorldSettingsContextValue {
  worldSettings: WorldSettings,
  updateProperties: (updates: WorldSettings) => void
}

export const WorldSettingsContext = React.createContext<WorldSettingsContextValue>(undefined);

interface WorldSettingsProviderProps {
  initialState: WorldSettings,
  children: React.ReactNode
}

export type WorldSettingsAction =
| { type: 'onWorldSettingsUpdate', updates: WorldSettings };


export function worldSettingsReducer(state: WorldSettings, action: WorldSettingsAction): WorldSettings {
  if (action.type === 'onWorldSettingsUpdate') {
    const { updates } = action;
    return updates;
  }
  return state;
}

export const WorldSettingsProvider = ({ children, initialState }: WorldSettingsProviderProps) => {
  const [state, dispatch] = useReducer(worldSettingsReducer, initialState);

  const contextFunctions = useMemo(() => {
    const updateProperties = (updates: WorldSettings) => {
      return dispatch({ type: 'onWorldSettingsUpdate', updates });
    };

    return {
      updateProperties
    };
  }, [dispatch]);

  const contextValue: WorldSettingsContextValue = {
    ...contextFunctions,
    worldSettings: state
  };

  return (
    <WorldSettingsContext.Provider value={contextValue}>
      {children}
    </WorldSettingsContext.Provider>
  );
};

export function useWorldSettingsContext() {
  const context = useContext(WorldSettingsContext);
  if (context === undefined) {
    throw new Error('useWorldSettingsContext must be used within a WorldSettingsProvider');
  }
  return context;
}
