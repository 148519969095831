import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import classNames from 'classnames';

import { ModalPopup } from '../../../../components/controls/modalPopup';
import { IDevicesData } from '../../../../services/core/devices';
import { getHomeLocations, IHomeLocation, getGroups, IGroups } from '../../../../services/config/config';
import { formatTitle, formatValue } from '../../../../components/data-table/dataFormatters';
import { EditDeviceState, FieldMap, FieldName } from './editDeviceLib';
import { OptionPicker } from '../../../../components/controls/optionPicker';
import { getHomeLocationName } from '../../../../components/controls/optionPickerLib';
import { Option, getHomeLocationOptionMapper, getHomeLocationSuggestionClasses } from '../../../../components/controls/optionPickerLib';
import { GroupTagPicker } from '../../../../components/controls/groupTagPicker';
import { useCurrentUserContext } from '../../../../context/currentUser';
import { nullHomeLocation } from '../../../../components/controls/optionPickerLib';
import { useWorldRequest } from '../../../../lib/useWorldRequest';
import { RequestInitWithRetry } from '../../../../lib/request';

import './devices-edit-record.css';
import { FormControls } from '../../../../components/forms/formControls';
import { ITranslationKeys } from 'components/i18n/keys';

interface ModalFooterProps {
  saving: boolean,
  isValid: boolean,
  handleClose: () => void,
  handleSave: () => void,
  errMessage: string
}

export function ModalFooter(props: ModalFooterProps) {
  const { t } = useTranslation(['translation', 'editEvents']);
  const { saving, isValid, errMessage, handleSave, handleClose } = props;
  return (
    <FormControls
      onCancel={handleClose}
      onSubmit={handleSave}
      submitResult={{
        message: errMessage,
        status: saving ? 'loading' : errMessage ? 'error' : null
      }}
      submitDisabled={!isValid}
      mode='save'
    />
  );
}

export interface IProps {
  showPopup: boolean,
  device: IDevicesData,
  handleClose: () => void,
  handleDeviceChange: (newDevice: IDevicesData) => void,
  onNewDevice: (device: IDevicesData) => void,
  onFieldUpdate: (update: FieldMap) => void,
  onSave: (onDeviceUpdated: (newRowData: IDevicesData) => void, handleClose: () => void, invalid?: boolean) => void,
  assetTag: EditDeviceState['assetTag'],
  homeLocation: EditDeviceState['homeLocation'],
  group: EditDeviceState['group'],
  saving: boolean,
  errors: FieldName[]
}

export const MAX_ASSET_TAG_LENGTH = 20;

const fieldNameKeys: { [field in FieldName]: string } = {
  assetTag: 'ASSET_TAG',
  homeLocation: 'HOME_LOCATION',
  group: 'GROUP_other'
};

const buildErrorMessage = (errors: FieldName[], t: TTypedTFunction) => {
  if (errors?.length) {
    const fields = errors.map((e) => t(fieldNameKeys[e] as keyof ITranslationKeys['translation'], { ns: 'translation' }).toLowerCase());
    return t('ERROR_MESSAGE', { ns: 'editRecord', count: errors.length, fields });
  }
};

export function DevicesEditRecord(props: IProps) {
  const ns = 'editRecord';
  const { t } = useTranslation(['translation', ns]);
  const { showPopup, device, handleDeviceChange, handleClose, onNewDevice, onFieldUpdate, onSave, assetTag, homeLocation, group, saving, errors } = props;
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const [assetTagValid, setAssetTagValid] = useState(true);
  const [homeLocationOptions, setHomeLocationOptions] = useState<Option<IHomeLocation>[]>([]);
  const [groupOptions, setGroupOptions] = useState<string[]>([]);
  const [groupTagError, setGroupTagError] = useState('');
  const { homeLocationId } = useCurrentUserContext();
  const mapHomeLocationsToOptions = useMemo(() => getHomeLocationOptionMapper(t), [t]);
  const errMessage = buildErrorMessage(errors, t);
  const onOptionsFetched = useCallback(([groups, homeLocations]: [IGroups, IHomeLocation[]]) => {
    const groupNames = (groups?.groups?.nodes)?.map(g => g.name.trim().toLowerCase()); // groups should be trimmed and lowercase, but we'll double check here
    setGroupOptions(groupNames);
    setOptionsLoaded(true);
    if (homeLocations.length > 0) {
      const availableHomeLocations: IHomeLocation[] = homeLocations && [nullHomeLocation, ...homeLocations];
      setHomeLocationOptions(mapHomeLocationsToOptions(availableHomeLocations));
    }
  }, [mapHomeLocationsToOptions]);

  const dataFetcher = useCallback(() => {
    onNewDevice(device);
    setOptionsLoaded(false);
    return (options: RequestInitWithRetry) => {
      return Promise.all([
        getGroups()(options),
        !homeLocationId && getHomeLocations()(options)
      ]);
    };
  }, [device, onNewDevice, homeLocationId]);

  useWorldRequest(dataFetcher, { onSuccess: onOptionsFetched });

  const handleAssetTagChange = (event: { target: HTMLInputElement }) => {
    const { value } = event.target;
    const valid = value.trim().length <= MAX_ASSET_TAG_LENGTH;
    setAssetTagValid(valid);
    onFieldUpdate({ assetTag: value });
  };

  const onHomeLocationSelect = (selected: Option<IHomeLocation>): void => {
    onFieldUpdate({ homeLocation: selected.value });
  };

  const onGroupsChange = (group: string[]): void => {
    onFieldUpdate({ group });
  };

  const onClose = (): void => {
    saving || handleClose();
  };

  const headerTitle = `${device.serialNumber ? `${device.serialNumber} - ` : ''}${t('TITLE', { ns })}`;
  const currentHomeLocation = homeLocation.name ? homeLocation : nullHomeLocation;
  const currentHomeLocationOption = mapHomeLocationsToOptions([homeLocation])[0];

  return (
    <div className="edit_record_popup">
      <ModalPopup
        show={showPopup}
        handleClose={onClose}
        header={headerTitle}
        subheader={t('SUBHEADER', { ns })}
        classname="core-devices_list-edit_record"
        footer={
          <ModalFooter
            saving={saving}
            handleClose={onClose}
            handleSave={() => onSave(handleDeviceChange, handleClose, !assetTagValid)}
            isValid={assetTagValid}
            errMessage={errMessage}
          />
        }
      >
        <div data-id={optionsLoaded ? 'devices-edit-record--ready' : ''} className={classNames('devices-edit-record', { 'devices-edit-record--ready': optionsLoaded })}>
          <div className="devices-edit-record__list">
            <div className="devices-edit-record__item devices-edit-record__item--serial-number">
              <div className="devices-edit-record__title">{formatTitle(t('SERIAL_NUMBER', { ns: 'translation' }))}</div>
              <div className="devices-edit-record__value">{formatValue(device.serialNumber)}</div>
            </div>
            <div className="devices-edit-record__item devices-edit-record__item--asset-tag">
              <div className="devices-edit-record__title">{formatTitle(t('ASSET_TAG', { ns: 'translation' }))}</div>
              <div className="devices-edit-record__value">
                <input
                  type="text"
                  aria-label="asset tag"
                  className={`devices-edit-record__input devices-edit-record__input--asset-tag devices-edit-record__input--${assetTagValid ? 'valid' : 'invalid'}`}
                  value={assetTag}
                  onChange={handleAssetTagChange}
                />
                {assetTagValid ||
                  <span className="devices-edit-record__validation-err devices-edit-record__validation-err--asset-tag">
                    {t('ASSET_TAG_VALIDATION_ERR', { ns, maxLength: MAX_ASSET_TAG_LENGTH })}
                  </span>
                }
              </div>
            </div>
            <div className="devices-edit-record__item devices-edit-record__item--model">
              <div className="devices-edit-record__title">{formatTitle(t('MODEL', { ns: 'translation' }))}</div>
              <div className="devices-edit-record__value">{formatValue(device.model)}</div>
            </div>
            <div className="devices-edit-record__item devices-edit-record__item--manufacturer">
              <div className="devices-edit-record__title">{formatTitle(t('MANUFACTURER', { ns: 'translation' }))}</div>
              <div className="devices-edit-record__value">{formatValue(device.manufacturer)}</div>
            </div>
            <div className="devices-edit-record__item devices-edit-record__item--groups">
              <div className="devices-edit-record__title">{formatTitle(t('GROUPS', { ns: 'translation' }))}</div>
              <div className="devices-edit-record__value">
                <div className={`devices-edit-record__groups-input ${groupTagError ? 'devices-edit-record__groups-input--invalid' : ''}`}>
                  <GroupTagPicker
                    groups={group}
                    suggestions={groupOptions}
                    setError={setGroupTagError}
                    onGroupsChange={onGroupsChange}
                  />
                  {groupTagError &&
                    <div className="devices-edit-record__validation-err devices-edit-record__validation-err--group">
                      {groupTagError}
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="devices-edit-record__item devices-edit-record__item--home-location">
              <div className="devices-edit-record__title">{formatTitle(t('HOME_LOCATION', { ns: 'translation' }))}</div>
              <div className="devices-edit-record__value">
                {homeLocationId ? getHomeLocationName(currentHomeLocation, t) :
                  <OptionPicker<IHomeLocation>
                    options={homeLocationOptions}
                    current={currentHomeLocationOption}
                    onSelect={onHomeLocationSelect}
                    disabled={!homeLocationOptions.length}
                    getSuggestionClasses={getHomeLocationSuggestionClasses}
                    isSearchable
                    id="homeLocationPicker"
                  />}
              </div>
            </div>
          </div>
        </div>
      </ModalPopup>
    </div>
  );
}
