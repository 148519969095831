import React, { useMemo, useState } from 'react';
import { isNull } from 'lodash';

import { useAuthContext } from '../../context/auth';
import { getUserRoleFromGroups, parseGroupsAttribute, UserAuth } from '../../components/authentication/userAuth';
import { getUserSettings, UserSettings } from '../../services/config/config';
import { AppLoading } from '../../components/loading/appLoading';
import { CurrentUser, getCurrentUser } from '../../services/users/users';
import { useNonWorldRequest } from '../../lib/useNonWorldRequest';
import { CurrentUserProvider } from '../../context/currentUser';
import { RequestInitWithRetry } from '../../lib/request';
import { AppWrapper } from './appWrapper';
import { UserSettingsWrapper } from './userSettingsWrapper';
import { useBrandContext } from '../../context/brand';
import { WhatsNewProvider } from '../../context/whatsNewProvider';

export const UserWrapper = () => {
  const { user } = useAuthContext();
  const { name: brand } = useBrandContext();
  const [initialCurrentUser, setInitialCurrentUser] = useState<UserAuth>();

  const [fetchUserData, onFetchUserDataSuccess] = useMemo(() => [
    () => (options: RequestInitWithRetry) => Promise.all([
      getCurrentUser(user?.attributes?.sub)(options),
      getUserSettings()(options)
    ]),
    ([currentUser]: [(CurrentUser | null), UserSettings]) => {
      let userAuth: UserAuth;
      if (isNull(currentUser)) {
        // if no user was returned from the flex-api, we assume this is a federated user and use their cognito attributes
        const groups = parseGroupsAttribute(user?.attributes['custom:groups']);
        const role = getUserRoleFromGroups(groups);
        userAuth = {
          id: user?.attributes.sub,
          email: user?.attributes.email,
          role,
          isAdministrator: role === 'administrator',
          isManager: role === 'manager',
          isEditor: role === 'editor',
          isViewer: role === 'viewer',
          // home locations are not supported for federated users
          homeLocationId: null,
          homeLocation: null,
          zoneId: null,
          zone: null,
          group: null
        };
      } else {
        userAuth = {
          ...currentUser,
          isAdministrator: currentUser.role === 'administrator',
          isManager: currentUser.role === 'manager',
          isEditor: currentUser.role === 'editor',
          isViewer: currentUser.role === 'viewer',
        };
      }
      setInitialCurrentUser(userAuth);
    }
  ], [user?.attributes]);
  const { data: [_, userSettings] } = useNonWorldRequest<[(CurrentUser | null), UserSettings]>(fetchUserData, { onSuccess: onFetchUserDataSuccess, initialData: [undefined, undefined] });

  if (!initialCurrentUser || !userSettings) { return <AppLoading />; }

  return (
    <CurrentUserProvider value={initialCurrentUser}>
      <UserSettingsWrapper userSettings={userSettings}>
        <WhatsNewProvider brand={brand}>
          <AppWrapper />
        </WhatsNewProvider>
      </UserSettingsWrapper>
    </CurrentUserProvider>
  );
};
