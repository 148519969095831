import * as React from "react";
import { ColorVariant, getColorFromIntensity, getDiscreteBand } from "./utils";
import { customProperties } from "containers/app/globalStyle";
export const Legend: React.FC<{ colorVariant: ColorVariant, discrete?: boolean }> = (props) => {
  if (props.discrete) {
    return (<ul className="discrete legend">
      {new Array(10).fill(null).map((_, index) => {
        return <li key={index}>
          <div className="background">
            <div className="color" style={{ backgroundColor: getColorFromIntensity(props.colorVariant, 100 - index * 10, true) }} />
          </div>
          <span className="text">{getDiscreteBand(90 - index * 10)}</span>
        </li>;
      })}
    </ul>);
  }

  const cssVariableStyles: Partial<Record<keyof typeof customProperties, any>> = {
    "--legend-stop-4": getColorFromIntensity(props.colorVariant, 0),
    "--legend-stop-3": getColorFromIntensity(props.colorVariant, 30),
    "--legend-stop-2": getColorFromIntensity(props.colorVariant, 80),
    "--legend-stop-1": getColorFromIntensity(props.colorVariant, 100),
  };

  return (<div className="continuous legend">
    <div className="lines">
      <div className="gradient" style={cssVariableStyles as any} />
    </div>
    <div className="text">
      <span>100%</span>
      <span>50%</span>
      <span>0%</span>
    </div>
  </div>);
};
