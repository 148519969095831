import * as React from 'react';
import styled from 'styled-components';
import { ButtonColour, Theme } from '../../containers/app/themes';

export enum Variants {
  solid = 'solid',
  ghost = 'ghost'
}

const variants = [
  Variants.solid,
  Variants.ghost
] as const;

type Variant = typeof variants[number];
type State = 'default' | 'disabled' | 'hover';

interface StyleProps {
  colour: ButtonColour,
  theme: Theme,
  variant: Variant,
  className: string,
  iconStyle: string
}

function getBackgroundColour(variant: Variant, theme: Theme, colour: ButtonColour, state: State) {
  let backgroundColour = theme.buttons[colour].light;

  if (variant === 'solid' && state === 'hover') {
    backgroundColour = theme.buttons[colour].dark;
  }

  if (variant === 'ghost' && state !== 'hover') {
    backgroundColour = theme.buttons.white;
  }

  return backgroundColour;
}

function getColours(variant: Variant, theme: Theme, colour: ButtonColour, state: State) {
  const themeTextColour = theme.buttons[colour].textColour;
  const backgroundColour = getBackgroundColour(variant, theme, colour, state);
  let textColour = themeTextColour || theme.buttons.white;

  if (variant === 'ghost' && state !== 'hover') {
    textColour = themeTextColour || theme.buttons[colour].light;
  }

  return `
  background-color: ${backgroundColour};
  color: ${textColour};
  `;
}

const StyledButton = styled.button<StyleProps>`
  padding: ${({ iconStyle }) => iconStyle ? '0.375rem 0.75rem' : '0.375rem 1.25rem'};
  margin-left: 0.75rem;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  line-height: 1.25rem;
  font-size: 0.875rem;
  transition: 0.3s;
  font-weight: 600;
  white-space: nowrap;
  align-self: flex-start;
  ${({ colour, theme, variant }) => { return getColours(variant, theme, colour, 'default'); }}
  ${({ colour, theme, variant }) => {
    const themeBorderColour = theme.buttons[colour].borderColour;
    return variant === 'solid' && !themeBorderColour ? `border: 1px solid ${getBackgroundColour(variant, theme, colour, 'default')};` : `border: 1px solid ${themeBorderColour || theme.buttons[colour].light};`;
  }}

  &:hover {
    cursor: pointer;
    ${({ colour, theme, variant }) => { return getColours(variant, theme, colour, 'hover'); }}
  }

  &:disabled {
    cursor: auto;
    box-shadow: none;
    opacity: 0.6;
    ${({ colour, theme, variant }) => { return getColours(variant, theme, colour, 'disabled'); }}
  }
`;

const ButtonText = styled.span<{ iconBefore: boolean }>`
  @media (max-width: 991px) {
    display: none;
  }

  ${({ iconBefore }) => iconBefore ? 'margin-left: 0.5rem' : 'margin-right: 0.5rem'};
`;
ButtonText.displayName = 'ButtonText';

const getDisplayText = (text: string | JSX.Element, iconStyle: string, iconBefore: boolean) => {
  if (iconStyle) {
    return <ButtonText data-id="button-text" iconBefore={iconBefore}><span>{text}</span></ButtonText>;
  }
  return text;
};

export interface ButtonProps {
  colour: ButtonColour,
  variant?: Variant,
  id?: string,
  value?: string,
  iconStyle?: string,
  text?: string | JSX.Element,
  iconBeforeText?: boolean,
  dataId?: string,
  type?: 'submit' | 'reset' | 'button',
  disabled?: boolean,
  autoFocus?: boolean,
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  'data-toggle'?: string,
  className?: string,
  iconComponent?: string | JSX.Element,
  ariaLabel?: string
}

export function Button(props: ButtonProps) {
  const { iconBeforeText, iconStyle, dataId, text, className, variant, iconComponent, ariaLabel } = props;
  const allButtonProps = {
    ...props,
    'data-id': dataId,
    'aria-label': ariaLabel,
    className,
    type: props.type || 'button',
    disabled: Boolean(props.disabled),
    variant: variant || Variants.solid,
    iconStyle
  };

  const icon = iconComponent ? iconComponent : <i data-id={`${dataId}_icon`} className={iconStyle} />;

  const beforeIcon = iconBeforeText && iconStyle ? icon : '';
  const afterIcon = !iconBeforeText && iconStyle ? icon : '';
  const displayText = getDisplayText(text, iconStyle, Boolean(iconBeforeText));

  return (
    <StyledButton {...allButtonProps}>
      {beforeIcon}{text && displayText}{afterIcon}
    </StyledButton>
  );
}
