import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { Popover } from '../../../../components/controls/popover';
import { AlertActive } from '../alertActive';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export interface IProps {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  timeStamp: number,
  onDataChanged: (data: WorldSettings) => void
}

const ns = 'settings';

const AccessPointSection = styled.div`
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
`;

export function AccessPointSettings(props: IProps) {
  const { t } = useTranslation(ns);

  function onAlertToggled(data: boolean) {
    props.onDataChanged({ ...props.worldSettings, deviceLocationAccessPointEnabled: data });
  }

  const tooltipContent = <div className="home-location-network-alerts_tooltip">
    <div className="tooltip-text-warning">{t('HOME_LOCATION_WARNING_ALERT_TOOLTIP_TEXT')}</div>
    <div className="tooltip-text-problem">{t('HOME_LOCATION_PROBLEM_ALERT_TOOLTIP_TEXT')}</div>
  </div>;

  return (
    <AccessPointSection key={props.timeStamp} className={'accessPoint'}>
      <div className="display-thresholds-header">
        <div>
          <h6>{t('HOME_LOCATION_NETWORK_ALERT', { ns })}</h6>
          <Popover
            placement="right"
            id={`popoverDisplayThresholds`}
            className="popoverThresholds"
            target={<i className="threshold-icon fa fa-question-circle" />}
            content={<div className="infoPopover">{tooltipContent}</div>}
            isInsideTable={false}
          />
        </div>
        <AlertActive checked={props.worldSettings.deviceLocationAccessPointEnabled} name={"accessPoint"} onChange={onAlertToggled} />
      </div>
      <div className="display-thresholds-row row">
        <div className="col">
          <div className="display-thresholds-section settings warning">
            <span className="status-header warning"><i className="fa fa-exclamation-triangle circle_orange enhanced-warning-icon" /> {t('WARNING_ALERT', { ns })}</span>
            <span className="display-settings-message warning">{t('HOME_LOCATION_NETWORK_ALERT_WARNING_TEXT', { ns })}</span>
          </div>
          <div className="display-thresholds-section settings problem">
            <span className="status-header problem"><i className="fa fa-times-circle circle_red" /> {t('PROBLEM_ALERT', { ns })}</span>
            <span className="display-settings-message problem">{t('HOME_LOCATION_NETWORK_ALERT_PROBLEM_TEXT', { ns })}</span>
          </div>
        </div>
      </div>
    </AccessPointSection>
  );
}
