import { request, RequestInitWithRetry } from "../../lib/request";


export interface AnomalyDeviceCountParams {
  anomalyId: string,
  timeZone: string
}

export interface AnomalyDeviceCount {
  date: string,
  significant: number,
  insignificant: number,
  deviceBitSet: string
}

export interface RequestParameters {
  anomalyId: string,
  timeZone: string
}

export function getAnomalyDeviceCount(params: AnomalyDeviceCountParams) {
  return async (options: RequestInitWithRetry): Promise<AnomalyDeviceCount[]> => {
    const input = {
      path: `/api/core/anomalies/details/deviceCount`,
      qs: { anomalyId: params.anomalyId, timeZone: params.timeZone }
    };
    const output = await request(input, options);
    return output;
  };
}

export function exportAnomalyDeviceChartAction(options: RequestInitWithRetry) {
  return async function (params: AnomalyDeviceCountParams) {
    const input = {
      path: `/api/core/anomalies/details/exportDeviceCount`,
      qs: { anomalyId: params.anomalyId, timeZone: params.timeZone }
    };
    const output = await request(input, options);
    return output;
  };
}
