import * as React from 'react';
import './styles.css';
import { Loading } from './loading';
import { isUndefined } from 'lodash';

interface IProps {
  transparentOverlay?: boolean,
  loadData: () => Promise<any>,
  dataPropName?: string,
  emptyData?: any
}

interface IState {
  data: any,
  isLoading: boolean
}

export class DataLoading extends React.Component<React.PropsWithChildren<IProps>, IState> {
  public static defaultProps: IProps = {
    transparentOverlay: true,
    loadData: undefined,
    dataPropName: "data",
    emptyData: {}
  };

  public constructor(props: IProps) {
    super(props);

    this.state = {
      data: this.props.emptyData,
      isLoading: true
    };
  }

  public async componentDidMount() {
    this.refreshData(this.props);
  }

  public render() {
    const childComponent = React.cloneElement(this.props.children as React.ReactElement<any>, { [this.props.dataPropName]: this.state.data, isLoading: this.state.isLoading });
    return <Loading isLoading={this.state.isLoading} transparentOverlay={this.props.transparentOverlay}>
      {childComponent}
    </Loading>;
  }

  private refreshData(props: IProps): Promise<void> {
    return props.loadData().then((data: any) => {
      this.setState({ data: isUndefined(data) ? this.props.emptyData : data, isLoading: false });
    }).catch(() => {
      this.setState({ data: this.props.emptyData, isLoading: false });
    });
  }
}
