import * as React from 'react';

import { RequestInitWithRetry } from './request';

/**
 * Hook to make an sync fetch request on an action (e.g. button click) rather than for data fetching,
 * without injecting the world id (unlike useWorldAction)
 *
 * usage e.g.
 *
 * const setUserSettingsAction = (options: RequestInitWithRetry) => async (data: IUserSettings) => {
 *   const params = {
 *     path: '/api/configuration/setUserSettings',
 *     method: 'POST',
 *     body: data
 *   };
 *   return request(params, options);
 * }
*
 * const setUserSettings = useNonWorldAction(setUserSettingsAction);
 * const onClick = () => setUserSettings(currentConfig)
 */
export const useNonWorldAction = <CB extends (...args: any[]) => any>(
  getCb: (options: RequestInitWithRetry) => CB
): CB => {
  const callback = React.useMemo(() => {
    return getCb({});
  }, [getCb]);

  return callback;
};
