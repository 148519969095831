import * as React from 'react';

import { devLogger } from './logger';

export type CollectItemsReturn<T> = {
  addItem: (item: T) => T,
  clearItems: () => void,
  items: T[]
};

/**
 * Collect items and call provided callback once all are added.
 */
export const useCollectItems = <T>(
  itemCount: number,
  onCollected: (items: T[]) => void
): CollectItemsReturn<T> => {
  const [items, setItems] = React.useState<T[]>([]);

  const addItem = React.useCallback((item: T) => {
    setItems((currentItems) => [...currentItems, item]);
    return item;
  }, []);

  const clearItems = React.useCallback(() => setItems([]), []);

  React.useEffect(() => {
    if (items.length < itemCount) {
      return;
    }
    if (items.length > itemCount) {
      // we should not exceed the expected item count, but in the interests of fault tolerance we will proceed and call the callback again
      devLogger.error(`Too many items! expected: ${itemCount}, collected: ${items.length}`);
    }
    onCollected(items);
  }, [itemCount, items, onCollected]);

  return { addItem, clearItems, items };
};
