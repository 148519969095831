import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import moment from 'moment';


export function getDays(days: number, timeZone: string, firstDetected?: number) {
  const startDateInTimeZone = firstDetected ? moment.utc(firstDetected).tz(timeZone).subtract(days, 'days').startOf('day') : moment.utc().tz(timeZone).subtract(days, 'days').startOf('day');
  const startDateWithUtcOffset = startDateInTimeZone.add(startDateInTimeZone.utcOffset(), 'minutes').valueOf();
  return startDateWithUtcOffset;
}

/* istanbul ignore next */
export function highlightBeforeFirstDetected(categoryAxis: am4charts.CategoryAxis, firstDetected: number, timeZone: string) {
  let range = categoryAxis.axisRanges.create();
  const startDays = getDays(89, timeZone);
  range.category = moment.utc(startDays).valueOf().toString();
  const daysFirstDetected = getDays(1, timeZone, firstDetected);
  range.endCategory = moment.utc(daysFirstDetected).valueOf().toString();
  range.axisFill.fill = am4core.color("#d3d3d3");
  range.label.disabled = true;
  range.axisFill.fillOpacity = 0.6;
  range.grid.strokeOpacity = 0;
}
