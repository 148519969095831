import { useTranslation } from "@lib/useTypedTranslation";

import { Button } from "../../buttons/button";
import { ButtonColours } from "../../../containers/app/themes";

interface EditButtonProps {
  className?: string,
  disabled?: boolean,
  dataId?: string,
  onClick?: () => void
}

export function EditButton(props: EditButtonProps) {
  const { t } = useTranslation('translation');

  return (
    <Button
      colour={ButtonColours.grey}
      className={props.className}
      dataId={props.dataId}
      disabled={props.disabled}
      onClick={props.onClick}
      text={t('EDIT')}
      iconBeforeText={true}
      iconStyle="fas fa-pen"
    />
  );
}
