import * as React from 'react';

import { Access } from './parts/access';
import { useTranslation } from '@lib/useTypedTranslation';
import { useFeatureTogglesContext } from '../context/featureToggles';
import { StandardPage } from './parts/standardPage';
import { UserManagement } from '../containers/users/index';
import { AccessDeniedToPage } from '../containers/access-denied/accessDeniedToPage';

export function UserManagementRoute() {
  const { t } = useTranslation('userManagement');
  const featureToggles = useFeatureTogglesContext();

  if (featureToggles.hideUserManagement) {
    return (
      <StandardPage title={t('USER_MANAGEMENT')}>
        <AccessDeniedToPage />
      </StandardPage>
    );
  }

  return (
    <Access title={t('USER_MANAGEMENT')} role="administrator">
      <StandardPage title={t('USER_MANAGEMENT')}>
        <UserManagement />
      </StandardPage>
    </Access>
  );
}
