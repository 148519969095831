import { useTranslation } from '@lib/useTypedTranslation';

import { Access } from './parts/access';
import { StandardPage } from './parts/standardPage';
import { BulkActions } from '../containers/bulkActions/index';
import { useCurrentUserContext } from '../context/currentUser';
import { AccessDeniedToPage } from '../containers/access-denied/accessDeniedToPage';
import { useProductsContext } from '../context/products';
import { productNames } from '../containers/app/productsWrapper';

export function BulkActionsRoute() {
  const { isAdministrator, isManager, isEditor, homeLocationId } = useCurrentUserContext();
  const { t } = useTranslation('bulkActions');
  const productsData = useProductsContext();

  const checkProductsEnabled = (!productsData[productNames.coreV2].enabled);
  const checkUserAccess = ((!isAdministrator && !isManager && !isEditor) || homeLocationId);

  if (checkProductsEnabled || checkUserAccess) {
    return (
      <StandardPage title={t('BULK_ACTIONS')}>
        <AccessDeniedToPage />
      </StandardPage>
    );
  }

  return (
    <Access title={t('BULK_ACTIONS')}>
      <StandardPage title={t('BULK_ACTIONS')}>
        <BulkActions />
      </StandardPage>
    </Access>
  );
}
