import { ISortResult } from "../shared/common";
import { get } from 'lodash';
import { request } from "../../lib/request";
import { AlertsData } from '../../components/alertsPanel/alertMessages';
import { RequestInitWithRetry } from '../../lib/request';

export interface IDevicesData extends AlertsData {
  id: string,
  model: string,
  serialNumber: string,
  imei: string,
  assetTag: string,
  group: string[],
  updated: string
}

export interface IListOptions {
  limit: number,
  offset: number,
  search?: string,
  sort?: ISortResult,
  deviceBitSet?: string
}

export interface IDevicesListData {
  list: IDevicesData[],
  totalCount: number
}

export interface IDevicesListCount {
  count: number
}

export interface IDeviceInfo {
  id: string,
  platformType: string,
  manufacturer: string,
  model: string,
  serialNumber: string,
  assetTag: string,
  imei: string,
  timeZoneOffset: number | null // device local offset from UTC in minutes
}

export function getAnomalyDevicesList(params: IListOptions) {
  return async (options: RequestInitWithRetry): Promise<IDevicesListData> => {
    const input = {
      path: `/api/core/anomalies/details/devicesList`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        sort: get(params, 'sort'),
        search: get(params, 'search'),
        deviceBitSet: get(params, 'deviceBitSet')
      },
      method: 'post'
    };
    return request(input, options);
  };
}

export function exportAnomalyDevicesListAction(requestOptions: RequestInitWithRetry) {
  return async (options: IListOptions): Promise<string> => {
    const input = {
      path: `/api/core/anomalies/details/exportList`,
      qs: { limit: options.limit, offset: options.offset },
      body: {
        sort: get(options, 'sort'),
        search: get(options, 'search'),
        deviceBitSet: get(options, 'deviceBitSet')
      },
      method: 'post'
    };
    return request(input, requestOptions);
  };
}
