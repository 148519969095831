import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ThemeProvider } from 'styled-components';

import { AuthWrapper } from '../../components/authentication/authWrapper';
import { ErrorFallback } from '../../components/errors/errorFallback';
import { useBrandContext } from '../../context/brand';
import { Brand, DEFAULT_BRAND } from '../../lib/brands';
import { main, themes } from './themes';

export const StyleWrapper = () => {
  const [oemBrand, setOemBrand] = useState<Brand>();
  const [styleLoaded, setStyleLoaded] = useState(false);
  const brand = useBrandContext();
  useEffect(() => {
    if (brand.name !== DEFAULT_BRAND) {
      setOemBrand(brand.name);
    }
    setStyleLoaded(true);
  }, [brand]);
  return (
    <>
      {styleLoaded && <ThemeProvider theme={themes[oemBrand] || main}><ErrorBoundary FallbackComponent={ErrorFallback}><AuthWrapper /></ErrorBoundary></ThemeProvider>}
    </>
  );
};
