import * as React from 'react';

import { ETable } from '../table/table';
import { Pagination } from '../../components/table/pagination';
import { FilterStatus, IFilterStatus } from '../../components/filters/filterStatus';
import { IFilterResult, IFilterGroup } from '../../components/filters/filterSelectionControl';
import { ISortResult } from '../../services/shared/common';

import _, { keys, forEach, find, flatMap, camelCase, isEqual } from 'lodash';
import { Direction } from '../../components/table/sortableHeader';
import { Trans } from '@components/i18n/typedTrans';
import { TTypedTFunction, useTranslation } from '@lib/useTypedTranslation';
import { ITranslationKeys } from '@components/i18n/keys';

export interface IListCategory {
  id: string,
  title: JSX.Element | string,
  link: string,
  icon: string,
  buildStatusMessage: (count: number) => string | JSX.Element
}

interface IProps {
  currentCategory: IListCategory,
  filterGroups?: IFilterGroup[],
  filterNameI18nKey: string,
  currentFilter?: IFilterResult,
  onFilterRemoved?: (id: string) => void,
  onFilterNavigate?: () => void,
  fields: string[],
  getList: (statusType: string, search: string, limit: number, offset: number, filters: IFilterResult, sort: ISortResult) => Promise<any[]>,
  getCount: (statusType: string, search: string, filters: IFilterResult) => Promise<number>,
  pageLimit?: number,
  rowComponent: React.ComponentClass<any, any>,
  classes: string[],
  iconCategories: { [key: string]: string },
  sortableColumns: string[],
  sortableMapping?: { [k: string]: string },
  centeredColumns: string[],
  search?: string,
  sort?: any
}

type BaseProps = IProps & { t: TTypedTFunction<'translation'> };

interface IState {
  isLoading: boolean,
  reloadRequired: boolean,
  items: any[],
  offset: number,
  currentTotal: number,
  sort?: { field: string, direction: Direction }
}

export class ListBase extends React.Component<BaseProps, IState> {
  public static defaultProps = {
    pageLimit: 9
  };

  constructor(props: BaseProps) {
    super(props);
    this.state = {
      isLoading: false,
      reloadRequired: false,
      items: [],
      offset: 0,
      currentTotal: 0,
      sort: this.props.sort
    };

    this.pageNext = this.pageNext.bind(this);
    this.pagePrev = this.pagePrev.bind(this);
    this.isFilterApplied = this.isFilterApplied.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.getConvertedSort = this.getConvertedSort.bind(this);
  }

  public componentDidMount() {
    this.setState({ isLoading: true }, () => { this.getList(); });
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.search !== prevProps.search || !isEqual(this.props.currentFilter, prevProps.currentFilter) || this.props.currentCategory.id !== prevProps.currentCategory.id) {
      this.setState({ isLoading: true, offset: 0 }, () => { this.getList(); });
    }
  }

  public render() {
    let customStatus: JSX.Element;
    const { currentTotal, isLoading } = this.state;

    if (!isLoading) {
      const filterStatus = this.isFilterApplied && <FilterStatus
        onNavigateFilters={this.props.onFilterNavigate}
        selectedFilters={this.getStatusFilters()}
        onFilterRemoved={this.props.onFilterRemoved ? (id: string) => {
          this.setState({ isLoading: true, items: [] }, () => { this.getList(); });
          this.props.onFilterRemoved(id);
        } : undefined}
      />;

      if (this.props.search) {
        customStatus = <div className="statusText">
          <b>{currentTotal}</b> {<Trans i18nKey="SEARCH_MATCH_STATEMENT" ns="translation" count={currentTotal}>result matching your search</Trans>} "<b>{this.props.search}</b>"
          {filterStatus}
        </div>;
      } else if (this.isFilterApplied()) {
        customStatus = <div className="statusText">
          <b>{currentTotal}</b> {this.props.t('FILTER_STATUS_TEXT', { count: currentTotal, filterName: this.props.t(this.props.filterNameI18nKey as keyof ITranslationKeys['translation'], { count: currentTotal }) })}
          {filterStatus}
        </div>;
      }
    }

    const WrappedPagination = () => (
      <Pagination key="wrappedPagination"
        limit={this.props.pageLimit}
        offset={this.state.offset}
        doNext={this.pageNext}
        doPrev={this.pagePrev}
        currentTotal={this.state.currentTotal}
        message={this.props.currentCategory.buildStatusMessage(this.state.currentTotal)}
        customStatus={customStatus} />);

    return (
      <ETable
        fields={this.props.fields}
        items={this.state.items}
        isLoading={this.state.isLoading}
        rowComponent={this.props.rowComponent}
        classes={this.props.classes}
        paginationComponent={WrappedPagination}
        iconCategories={this.props.iconCategories}
        sortableColumns={this.props.sortableColumns}
        centeredColumns={this.props.centeredColumns}
        onSortChange={this.onSortChange}
        sort={this.state.sort}
      />
    );
  }

  public pageNext(): void {
    const pageOffset = this.state.offset + this.props.pageLimit;
    const offset = pageOffset < this.state.currentTotal ? pageOffset : this.state.offset;
    this.setState({ isLoading: true, offset }, () => { this.getList(); });
  }

  public pagePrev(): void {
    const offset = Math.max(0, this.state.offset - this.props.pageLimit);
    this.setState({ isLoading: true, offset }, () => { this.getList(); });
  }

  private async getList(): Promise<void> {
    try {
      const currentCategory = { ...this.props.currentCategory };
      const items = await this.props.getList(currentCategory.id, this.props.search, this.props.pageLimit, this.state.offset, this.props.currentFilter, this.getConvertedSort());
      const count = await this.props.getCount(currentCategory.id, this.props.search, this.props.currentFilter);

      // only display if the data is still correct for the current tab
      if (currentCategory.id === this.props.currentCategory.id) {
        this.setState({ items, currentTotal: count, isLoading: false });
      }
    } catch (err) {
      // Should do something with this error...
    }
  }

  private isFilterApplied() {
    return this.props.currentFilter && keys(this.props.currentFilter).length > 0 && flatMap(this.props.currentFilter, (value) => value).length > 0;
  }

  private getStatusFilters(): IFilterStatus[] {
    const statuses: IFilterStatus[] = [];
    forEach(this.props.currentFilter, (selectedItems: string[], groupId: string) => {
      const filterGroup = find(this.props.filterGroups, { id: groupId });
      const displayState = selectedItems.length > 1 ? `${selectedItems.length} ${filterGroup.valueDisplayName}` : selectedItems[0];
      displayState && statuses.push({ id: groupId, display: displayState });
    });

    return statuses;
  }

  private onSortChange(field: string, direction: Direction) {
    this.setState({ sort: { field, direction } }, () => { this.getList(); });
  }

  private getConvertedSort() {
    if (!this.state.sort) { return undefined; }

    let sort;
    let sortField;
    const mapping = this.props.sortableMapping;

    if (mapping) {
      sortField = mapping[this.state.sort.field] ? mapping[this.state.sort.field] : camelCase(this.state.sort.field);
    } else {
      sortField = camelCase(this.state.sort.field);
    }

    switch (this.state.sort.direction) {
    case 0:
      sort = undefined;
      break;
    case 1:
      sort = { field: sortField, order: 'asc' };
      break;
    case 2:
      sort = { field: sortField, order: 'desc' };
      break;
    }
    return sort;
  }
}

export const List: React.FC<IProps> = props => {
  const { t } = useTranslation();
  return <ListBase t={t} {...props} />;
};
List.defaultProps = {
  pageLimit: 9
};
