import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from '@lib/useTypedTranslation';

import { InvalidPage } from '../components/errors/invalidPage';
import { CoreProcess } from '../containers/core/process/coreProcess';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { useMemo } from 'react';
import { usePathBasedQueryString } from '@lib/usePathBasedQueryString';

export const CoreProcessRoute = () => {
  const { t } = useTranslation('redirect');
  const routes = useWorldRoutes();

  const { tab } = useParams<{ tab: string }>();

  const { queryString: search } = usePathBasedQueryString(/\/core\/processes/);
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const processName = searchParams.get('processName');
  const productName = searchParams.get('productName');

  if (!processName || !productName) {
    return <InvalidPage
      message={t('PROCESS_NOT_FOUND', { ns: 'redirect' })}
      redirect={{
        message: t('REDIRECT_TO_PROCESSES', { ns: 'redirect' }),
        path: routes.core.processesList
      }}
    />;
  }

  return (
    <CoreProcess tab={tab} processName={processName} productName={productName} />
  );
};
