import { Tooltip, TooltipProps } from './tooltip';
import { Placement } from '@popperjs/core';

import './infoPopover.css';

export interface InfoPopoverProps extends Omit<TooltipProps, 'children' | 'popperContent'>{
  children: React.ReactNode,
  labelText: string,
  placement?: Placement,
  className?: string
}

export function InfoPopover(props: InfoPopoverProps) {
  const { children, ...tooltipProps } = props;

  const content = (
    <div className={`info-popover-content ${props.className || ''}`}>
      {children}
    </div>
  );

  return (
    <Tooltip placement={props.placement || 'bottom'} popperContent={content} {...tooltipProps}>
      <i aria-label={props.labelText} className="info-popover-icon fa fa-question-circle" />
    </Tooltip>
  );
}
