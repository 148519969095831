import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { BuildZone } from './buildZone';
import { createZonesAction, ZoneData } from '../../../services/zones/zones';
import { useWorldAction } from '../../../lib/useWorldAction';

export function CreateZone() {
  const ns = 'zones';
  const { t } = useTranslation(ns);
  const createZone = useWorldAction(createZonesAction);

  return (
    <BuildZone<Omit<ZoneData, 'id'>>
      saveRequest={createZone}
      getRequestParams={(zone) => ({
        id: zone.id,
        name: zone.name,
        groups1: zone.groups1,
        groups2: zone.groups2,
        homeLocationIds: zone.homeLocationIds
      })}
      header={t('NEW_ZONE')}
    />
  );
}
