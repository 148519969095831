import * as React from 'react';
import './style.css';
import { useTranslation } from '@lib/useTypedTranslation';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';

import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const StyledEulaAndDpaLinks = styled.div`
  border: 1px solid #d7e2eb;
  margin-left: 0.625em;
  margin-bottom: 1.5rem;
  background: #fff;
`;

const StyledEulaAndDpaLinksHeader = styled.div`
  border: 0;
  border-bottom: 1px solid #d7e2eb;
  background: #f3f7fb;
  padding: 0.3rem;
  font-weight: 600;
`;

const StyledEulaAndDpaLinksContent = styled.div`
  padding: 0.5rem 0.6rem 0.7rem 0.6rem;
`;

const StyledEulaAndDpaLinksTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  display: inline-block;
  width: 20%;
  min-width: 3em;
  padding: 0;
  margin: 0;
`;

const StyledEulaAndDpaCheckMark = styled.i`
  margin-left: 1rem;
`;

interface IEulaAndDpaLinksProps {
  acceptedEULA?: string,
  acceptedDPA?: string
}

export const EulaAndDpaLinks = (props: IEulaAndDpaLinksProps) => {
  const { t } = useTranslation('agreements');
  const { eula: linkToEula, dpa: linkToDpa } = useWorldRoutes().agreements;

  if (!(props.acceptedEULA || props.acceptedDPA)) return null;

  const eulaShortTitle = props.acceptedEULA ? t('EULA_ABBREV') : '';
  const dpaShortTitle = props.acceptedDPA ? t('DPA_ABBREV') : '';

  const eulaFullTitle = props.acceptedEULA ? `${t('EULA_FULL')} (${eulaShortTitle})` : '';
  const dpaFullTitle = props.acceptedDPA ? `${t('DPA_FULL')} (${dpaShortTitle})` : '';

  const headerTitle = (eulaShortTitle && dpaShortTitle)
    ? `${eulaShortTitle} ${t('AND', { ns: 'translation' })} ${dpaShortTitle}`
    : `${eulaShortTitle}${dpaShortTitle}`;

  const description = t('EULA_AND_DPA_DESCRIPTION', {
    v: (eulaFullTitle && dpaFullTitle) ?
      `${eulaFullTitle} ${t('AND', { ns: 'translation' })} ${dpaFullTitle}`
      :
      `${eulaFullTitle}${dpaFullTitle}`
  });

  return (
    <StyledEulaAndDpaLinks className="enrollment-container">
      <StyledEulaAndDpaLinksHeader className="enrollment-container__header">
        {headerTitle}
      </StyledEulaAndDpaLinksHeader>
      <StyledEulaAndDpaLinksContent className="enrollment-container__content">
        <div>{description}</div>
        { props.acceptedEULA &&
         <div><StyledEulaAndDpaLinksTitle className="enrollment-container__content-title">{ t('EULA_ABBREV') }:</StyledEulaAndDpaLinksTitle> <NavLink aria-label='click to view eula agreement in new tab' target="_blank" to={linkToEula} rel="noopener noreferrer">{ t('OPEN_IN_NEW_TAB', { v: t('THE_EULA_ABBREV') }) }</NavLink>{props.acceptedEULA && <StyledEulaAndDpaCheckMark data-id='eula-agreement-checked' className='fa fa-check circle_green'/>}</div>
        }
        { props.acceptedDPA &&
          <div><StyledEulaAndDpaLinksTitle className="enrollment-container__content-title">{ t('DPA_ABBREV') }:</StyledEulaAndDpaLinksTitle> <NavLink aria-label='click to view dpa agreement in new tab' target="_blank" to={linkToDpa} rel="noopener noreferrer">{ t('OPEN_IN_NEW_TAB', { v: t('THE_DPA_ABBREV') }) }</NavLink>{props.acceptedDPA && <StyledEulaAndDpaCheckMark data-id='dpa-agreement-checked' className='fa fa-check circle_green'/>}</div>
        }
      </StyledEulaAndDpaLinksContent>
    </StyledEulaAndDpaLinks>
  );
};
