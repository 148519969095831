import * as React from 'react';
import { mapValues } from 'lodash';
import { TTypedTFunction } from '@lib/useTypedTranslation';

import { thresholdFieldNames, ThresholdFieldNames, ThresholdErrorCode, getRawThresholdsConfig } from './editLib';
import { InitialFormState, translateFieldsConfig, useFormReducer, extractFieldValues } from '../../components/forms/formsLib';
import { tuple } from '../../lib/typeUtils';
import { HomeLocationData } from '../../services/homeLocations/homeLocations';
import { useWorldSettingsContext } from '../../context/worldSettings';

/** state management for ThresholdSelection */
export const useThresholdSelection = (t: TTypedTFunction, initialHomeLocation?: HomeLocationData) => {
  const { worldSettings } = useWorldSettingsContext();
  const initialThresholdsSet = Boolean(initialHomeLocation?.distanceRed && initialHomeLocation?.distanceYellow);
  const [useGlobalThresholds, setUseGlobalThresholds] = React.useState<boolean>(!initialThresholdsSet);

  const translatedFieldConfig = React.useMemo(() => translateFieldsConfig(getRawThresholdsConfig(worldSettings.useMetricDistances), t), [t, worldSettings.useMetricDistances]);
  const thresholdFieldsConfig = React.useMemo(() => {
    if (useGlobalThresholds) {
      return mapValues(translatedFieldConfig, (config) => ({ ...config, required: false, disabled: true }));
    }
    return mapValues(translatedFieldConfig, (config) => ({ ...config, required: true, disabled: false }));
  }, [translatedFieldConfig, useGlobalThresholds]);

  const initialValues = React.useMemo(() => extractFieldValues<ThresholdFieldNames, ThresholdErrorCode>(thresholdFieldNames, initialHomeLocation, thresholdFieldsConfig), [initialHomeLocation, thresholdFieldsConfig]);
  const [
    { errors: thresholdsErrors, values: thresholdsValues, isValid: thresholdsValid, isComplete: thresholdsComplete, isDirty: thresholdsDirty },
    { onChange: thresholdsOnChange, onValidate: thresholdsOnValidate, onClear: thresholdsOnClear, onNew, onReset: thresholdsOnReset, onUpdateConfig }
  ] = useFormReducer<ThresholdFieldNames, ThresholdErrorCode>({ fieldsConfig: thresholdFieldsConfig, initialValues });

  // config is also available in a ref so that it can be referenced in a useEffect without triggering an update
  const thresholdFieldsConfigRef = React.useRef(thresholdFieldsConfig);
  React.useEffect(() => {
    onUpdateConfig(thresholdFieldsConfig);
    thresholdFieldsConfigRef.current = thresholdFieldsConfig;
  }, [onUpdateConfig, thresholdFieldsConfig]);

  const thresholdsOnNew = React.useCallback(
    (newValues: InitialFormState<ThresholdFieldNames, ThresholdErrorCode>['initialValues']) => {
      const thresholdsSet = Boolean(newValues.distanceRed && newValues.distanceYellow);
      setUseGlobalThresholds(!thresholdsSet);
      onNew(newValues);
    },
    [onNew, setUseGlobalThresholds]
  );

  return tuple(
    { thresholdsErrors, thresholdsValues, thresholdsValid, thresholdsComplete, thresholdsDirty },
    { thresholdsOnChange, thresholdsOnValidate, thresholdsOnNew, thresholdsOnClear, thresholdsOnReset },
    { thresholdFieldsConfig, thresholdFieldsConfigRef },
    { useGlobalThresholds, setUseGlobalThresholds }
  );
};
