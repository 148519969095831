import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import { BuildZone } from './buildZone';
import { editZoneAction, Zone, ZoneData } from '../../../services/zones/zones';
import { useWorldAction } from '../../../lib/useWorldAction';
import { InvalidPage } from '../../../components/errors/invalidPage';
import { useWorldRoutes } from '../../../routes/parts/allWorldRoutes';

export function EditZone() {
  const ns = 'zones';
  const { t } = useTranslation(ns);
  const basePath = useWorldRoutes().zones;
  const editZone = useWorldAction(editZoneAction);
  const { state: updateZoneInfo } = useLocation<Zone>();

  if (isEmpty(updateZoneInfo)) {
    return (<InvalidPage
      message={t('EDIT_ZONE_NAVIGATION_TEXT')}
      redirect={{
        message: t('REDIRECT_TO_ZONES', { ns: 'redirect' }),
        path: `${basePath.list}`
      }}
    />);
  }

  return (
    <BuildZone<Omit<ZoneData, 'name'>>
      saveRequest={editZone}
      getRequestParams={(zone) => ({
        id: zone.id,
        name: zone.name,
        groups1: zone.groups1,
        groups2: zone.groups2,
        homeLocationIds: zone.homeLocationIds
      })}
      header={t('EDIT_ZONE')}
      initialZone={updateZoneInfo}
      canEdit={true}
    />
  );
}
