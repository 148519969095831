import React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { updateUser, UpdateUserParameters, IUserData } from '../../../services/users/users';
import { nullHomeLocation, nullZone } from '../../../components/controls/optionPickerLib';
import { Loading } from '../../../components/loading/loading';
import styled from 'styled-components';
import { BuildUser, BuildUserData } from './buildUser';
import { isEqual } from 'lodash';
import { World } from '../../../services/worlds/worlds';

export interface IProps {
  user: IUserData,
  showPopup: boolean,
  handleClose: () => void,
  handleSave: () => void
}

const ModalLoading = styled(Loading)`
  & .loading-spinner {
    margin: 100px 0;
    position: relative;
  }
`;
ModalLoading.displayName = 'ModalLoading';

export function EditUser(props: IProps) {
  const ns = 'userManagement';
  const { t } = useTranslation(ns);
  const { showPopup, user } = props;

  const getSaveDisabled = (state: BuildUserData, worlds: World[]) => {
    const roleChanged = user.role !== state.role;
    const homeLocationChanged = user.homeLocation ? state.homeLocationId !== user.homeLocation.id : state.homeLocationId !== null;
    const groupChanged = user.group ? state.group !== user.group : state.group !== null;
    const zoneChanged = user.zone ? state.zoneId !== user.zone.id : state.zoneId !== null;
    const initialWorldIds = user.worldIds === null ? worlds.map(world => world.id) : user.worldIds;
    const worldIdsChanged = !isEqual(initialWorldIds, state.worldIds);
    const noWorldsSelected = state.worldIds?.length === 0;

    return (!homeLocationChanged && !groupChanged && !zoneChanged && !roleChanged && !worldIdsChanged) || noWorldsSelected;
  };

  return (
    <BuildUser<UpdateUserParameters>
      showPopup={showPopup}
      handleClose={props.handleClose}
      getSaveDisabled={getSaveDisabled}
      handleSave={props.handleSave}
      canEditEmail={false}
      saveRequest={updateUser}
      initialUser={{
        id: user.id,
        email: user.email,
        role: user.role,
        homeLocation: user.homeLocation ?? nullHomeLocation,
        worldIds: user.worldIds,
        zone: user.zone ?? nullZone,
        group: user.group
      }}
      getRequestParams={(user) => ({
        id: user.id,
        role: user.role,
        homeLocationId: user.homeLocationId,
        worldIds: user.worldIds,
        group: user.group,
        zoneId: user.zoneId
      })}
      header={t('EDIT_USER')}
      subheader={t('EDIT_USER_DESCRIPTION')}
      modalClass={`edit-user-modal-popup`}
    />
  );
}
