import * as React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import french from '@amcharts/amcharts4/lang/fr_FR';
import spanish from '@amcharts/amcharts4/lang/es_ES';
import { isEqual } from 'lodash';
import { LanguageAndLocaleContext, LanguageAndLocaleContextValue } from '../../context/languageAndLocale';

export interface IChartProps<T extends am4core.Sprite> {
  /** External html DOM element id to host chart */
  id?: string,
  /** Used to generate id when chart is hosted inside AmChart component, ignored when id is set */
  tag?: string,
  /** Function that prepares and returns data to draw */
  dataProvider: () => any[],
  /** Function to create and setup chart */
  chartProvider: (id: string) => T,
  /** Used to update data when referenced object changes */
  link?: any,
  /** Used to expose specific charts for cypress acceptance testing */
  cypressId?: string,

  onMount?: (chart: T, data?: any[], prevProps?: IChartProps<T>, props?: IChartProps<T>) => T,

  onUpdate?: (chart: T, data?: any[], prevProps?: IChartProps<T>, props?: IChartProps<T>) => T,

  onDataValidated?: () => void,

  onVisible?: () => void
}

interface IState {
  dataValidated: boolean,
  visible: boolean
}

export function generateChartId(tag: string): string {
  return `${tag}-${Math.round(Math.random() * 1000000000)}`;
}

/* istanbul ignore next */
export function defaultTheme(target: any) {
  if (target instanceof am4charts.XYChart) {
    target.fontSize = 12;
    target.fontWeight = '400';
    target.fontFamily = 'Open Sans';
  }

  if (target instanceof am4charts.AxisLabel) {
    target.fill = am4core.color("#333333");
  }

  if (target instanceof am4charts.ColumnSeries) {
    if (target.tooltip) {
      target.tooltip.getFillFromObject = false;
      target.tooltip.background.stroke = am4core.color();
      target.tooltip.background.fill = am4core.color('black');
      target.tooltip.background.fillOpacity = .7;
    }
    target.columns.template.fillOpacity = .9;
  }

  if (target instanceof am4charts.AxisRenderer) {
    if (target.baseGrid) {
      target.baseGrid.stroke = am4core.color('#C2D1E0');
      target.baseGrid.strokeOpacity = 1;
    }
    if (target.grid) {
      target.grid.template.stroke = am4core.color('#C2D1E0');
      target.grid.template.strokeOpacity = 1;
    }
  }

  if (target instanceof am4charts.LineSeries) {
    if (target.tooltip) {
      target.tooltip.getFillFromObject = false;
      target.tooltip.background.stroke = am4core.color();
      target.tooltip.background.fill = am4core.color('black');
      target.tooltip.background.fillOpacity = .7;
    }
    target.strokeWidth = 3;
  }
}

/* istanbul ignore next */
export function coreTheme(target: any) {

  if (target.uidAttr) {
    target.uidAttr();
  }

  if (target instanceof am4charts.XYChart) {
    target.fontSize = 12;
    target.fontWeight = '400';
    target.fontFamily = 'Open Sans';
  }

  if (target instanceof am4charts.AxisLabel) {
    target.fill = am4core.color("#333333");
  }

  if (target instanceof am4charts.ColumnSeries) {
    if (target.tooltip) {
      target.tooltip.autoTextColor = false;
      target.tooltip.label.fill = am4core.color('black');
      target.tooltip.getFillFromObject = false;
      target.tooltip.background.fill = am4core.color('white');
      target.tooltip.fontWeight = '400';
      target.tooltip.fontSize = 12;
      target.tooltip.stroke = am4core.color('#333333');
    }
  }

  if (target instanceof am4charts.AxisRenderer) {
    if (target.baseGrid) {
      target.baseGrid.stroke = am4core.color('#C2D1E0');
      target.baseGrid.strokeOpacity = 1;
    }
    if (target.grid) {
      target.grid.template.stroke = am4core.color('#C2D1E0');
      target.grid.template.strokeOpacity = 1;
    }
  }

  if (target instanceof am4charts.CategoryAxis || target instanceof am4charts.DateAxis) {
    if (target.tooltip) {
      target.tooltip.autoTextColor = false;
      target.tooltip.label.fill = am4core.color('black');
      target.tooltip.label.stroke = null;
      target.tooltip.getFillFromObject = false;
      target.tooltip.background.cornerRadius = 3;
      target.tooltip.background.stroke = am4core.color('#C2D1E0');
      target.tooltip.background.fill = am4core.color('white');
      target.tooltip.fontWeight = '400';
      target.tooltip.fontSize = 12;
      target.tooltip.stroke = null;
    }
  }

  if (target instanceof am4charts.LineSeries) {
    if (target.tooltip) {
      target.tooltip.autoTextColor = false;
      target.tooltip.label.fill = am4core.color('black');
      target.tooltip.getFillFromObject = false;
      target.tooltip.background.fill = am4core.color('white');
      target.tooltip.fontWeight = '400';
      target.tooltip.fontSize = 12;
      target.tooltip.stroke = am4core.color('#333333');
    }
  }
}

/**
 * Component to hold amChart boilerplate.
 *
 * Use id to host chart in an html element outside of component or
 * tag to host it inside.
 * Use cypressId to expose specified charts for tooltip testing
 */
export class AmChart<T extends am4charts.Chart> extends React.Component<IChartProps<T>, IState> {
  cypressWindow: any = (window as any);

  chart: T;

  id: string;

  static contextType = LanguageAndLocaleContext;

  constructor(props: any) {
    super(props);
    this.state = { dataValidated: false, visible: false };
    this.id = props.id || generateChartId(props.tag || 'chart');
  }

  componentDidUpdate(prevProps: IChartProps<T>) {
    if (this.chart && !isEqual(prevProps.link, this.props.link)) {
      this.setState({ dataValidated: false });
      this.chart.data = this.props.dataProvider();
      this.chart.invalidateData();
      if (this.props.onUpdate) {
        this.chart = this.props.onUpdate(this.chart, this.chart.data, prevProps, this.props);
      }
    }
  }

  componentDidMount() {
    am4core.options.autoSetClassName = true;
    am4core.options.commercialLicense = true;
    am4core.unuseAllThemes();
    this.chart = this.props.chartProvider(this.id);
    const language = (this.context as LanguageAndLocaleContextValue).language;
    if (language === 'fr' || language === 'fr-CA') {
      this.chart.language.locale = {
        ...french,
        "_byte_suffix_KB": "KO",
        "_byte_suffix_MB": "MO",
        "_byte_suffix_GB": "GO",
        "_byte_suffix_TB": "TO",
        "_byte_suffix_PB": "PO"
      };
    }
    if (language === 'es') {
      this.chart.language.locale = spanish;
    }
    if (this.chart) {
      /** this detects if code is being run through Cypress. A cypressId (an optional prop) must also be included to create the window.cypressChart object */
      if (this.cypressWindow.Cypress && this.props.cypressId) {
        /** if cypressCharts object has been generated, skip next step, else, create cypressCharts object. */
        if (!this.cypressWindow.cypressCharts) {
          this.cypressWindow.cypressCharts = {};
        }
        /** A passed cypressId is used as a property to access the chart while testing in Cypress. Strict names ensure the correct chart is found.
         * Ordinary chart Id cannot be used as it uses a generated random number, which is unreliable.
         */
        this.cypressWindow.cypressCharts[this.props.cypressId] = this.chart;
      }
      this.chart.data = this.props.dataProvider();

      this.chart.events.on('ready', () => {
        this.setState({ visible: true });
        this.props.onVisible && this.props.onVisible();
      });

      this.chart.events.on('validated', () => {
        this.setState({ dataValidated: true });
        this.props.onDataValidated && this.props.onDataValidated();
      });

    }
    if (this.props.onMount) {
      this.chart = this.props.onMount(this.chart, this.chart.data, undefined, this.props);
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
    if (this.cypressWindow.Cypress && this.props.cypressId) {
      delete this.cypressWindow.cypressCharts[this.props.cypressId];
    }
  }

  render() {
    const classname = this.state.dataValidated && this.state.visible ? 'amchart ready' : 'amchart';
    return <div id={this.props.id || this.id} className={classname} style={{ width: '100%', height: '100%' }} />;
  }
}
