import React, { useMemo, useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { ModalPopup } from '../../components/controls/modalPopup';
import { useFormReducer, translateFieldsConfig, prepareFieldValues } from '../../components/forms/formsLib';
import { FormFieldList } from '../../components/forms/formFieldList';
import { CreateHomeLocationData, createHomeLocationAction } from '../../services/homeLocations/homeLocations';
import { fieldNames, FieldNames, ErrorCode, getRawCreateFieldsConfig, nsList, thresholdFieldNames, ThresholdFieldNames, ThresholdErrorCode } from './editLib';
import { InfoPopover } from '../../components/tooltip/infoPopover';
import { FormControls } from '../../components/forms/formControls';
import { ThresholdSelection } from './thresholdSelection';
import { useThresholdSelection } from './thresholdSelectionLib';
import { useWait } from '../../lib/wait';
import { useWorldAction } from '../../lib/useWorldAction';
import { useWorldSettingsContext } from '../../context/worldSettings';

import './editHomeLocation.css';

export interface Props {
  showPopup: boolean,
  onClose: () => void,
  onSave: () => void
}

const [ns, _, ns3, ns4] = nsList;

export function CreateHomeLocation(props: Props): JSX.Element {
  const { t } = useTranslation(nsList);
  const { worldSettings } = useWorldSettingsContext();
  const { showPopup, onClose, onSave } = props;
  const [error, setError] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const createHomeLocation = useWorldAction(createHomeLocationAction);

  const fieldsConfig = useMemo(() => translateFieldsConfig(getRawCreateFieldsConfig(worldSettings.useMetricDistances), t), [t, worldSettings.useMetricDistances]);

  const [
    { thresholdsErrors, thresholdsValues, thresholdsValid, thresholdsComplete },
    { thresholdsOnChange, thresholdsOnValidate, thresholdsOnClear },
    { thresholdFieldsConfig },
    { useGlobalThresholds, setUseGlobalThresholds }
  ] = useThresholdSelection(t);

  const [
    { errors, values, isValid, isComplete },
    { onChange, onValidate, onClear }
  ] = useFormReducer<FieldNames, ErrorCode>({ fieldsConfig });

  const { wait } = useWait();

  const disabled = !isValid || !isComplete || !thresholdsValid || !thresholdsComplete;

  async function handleSave() {
    setError(undefined);
    setIsSubmitting(true);
    try {
      const homeLocationData = prepareFieldValues<FieldNames, ErrorCode, Omit<CreateHomeLocationData, 'distanceRed' | 'distanceYellow'>>(fieldNames, values, fieldsConfig);
      const thresholdsData = prepareFieldValues<ThresholdFieldNames, ThresholdErrorCode, Pick<CreateHomeLocationData, 'distanceRed' | 'distanceYellow'>>(thresholdFieldNames, thresholdsValues, thresholdFieldsConfig);
      const createParams = {
        ...homeLocationData,
        ...thresholdsData
      };
      await createHomeLocation(createParams);
      // 5 second delay to allow replication of new home location to ES
      await wait(5000);
      onSave();
      onClear();
      thresholdsOnClear();
      onClose();
    } catch (err) {
      if (err.message.includes('Home location already exists')) {
        setError(t('ERROR_ALREADY_EXISTS', { ns }));
      } else {
        setError(t('SOMETHING_WRONG', { ns: ns3 }));
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleCancel() {
    onClear();
    onClose();
    thresholdsOnClear();
    setUseGlobalThresholds(true);
    setError(undefined);
  }

  const tooltip = (
    <InfoPopover labelText='about create'>
      {t('CREATE_TITLE_TOOLTIP', { ns })}
    </InfoPopover>
  );
  const header = <>{t('CREATE_TITLE', { ns })}{tooltip}</>;

  const footer = (
    <FormControls
      submitDisabled={disabled}
      submitResult={{
        message: error,
        status: isSubmitting ? 'loading' : error ? 'error' : null
      }}
      onCancel={handleCancel}
      onSubmit={handleSave}
      message={<span className="edit-home-location__footer-message">
        {t('REQUIRED_FIELDS', { ns: ns4 })}
      </span>}
      mode='save'
    />
  );

  return (
    <div className="edit-home-location edit-home-location--create">
      <ModalPopup
        show={showPopup}
        handleClose={handleCancel}
        header={header}
        classname="edit-home-location__modal"
        footer={footer}
      >
        <div className="edit-home-location__body edit-home-location__body--create">
          <div className="edit-home-location__header">
            {t('DETAILS_TITLE', { ns })}
          </div>
          <FormFieldList<FieldNames, ErrorCode>
            fieldNames={fieldNames}
            fieldsConfig={fieldsConfig}
            onChange={onChange}
            onValidate={onValidate}
            values={values}
            errors={errors}
            disabled={isSubmitting}
          />
          <ThresholdSelection
            global={useGlobalThresholds}
            onSelectGlobal={setUseGlobalThresholds}
            fieldsConfig={thresholdFieldsConfig}
            onChange={thresholdsOnChange}
            onValidate={thresholdsOnValidate}
            values={thresholdsValues}
            errors={thresholdsErrors}
            isSubmitting={isSubmitting}
          />
        </div>
      </ModalPopup>
    </div>
  );
}
