import { parseElemezHostname } from './url';

export const systems = ['us', 'uk', 'staging'] as const;
export type System = typeof systems[number];

export const DEFAULT_SYSTEM: System = 'us';

export const isSystem = (system: string): system is System => {
  return systems.includes(system as System);
};

/** Get current system from hostname */
export const getCurrentSystem = (): System => {
  const [_, system] = parseElemezHostname();
  return system || DEFAULT_SYSTEM;
};

export const systemIsUs = (): boolean => {
  return dependencies.getCurrentSystem() === 'us';
};

export const systemIsUk = (): boolean => {
  return dependencies.getCurrentSystem() === 'uk';
};

export const systemIsStaging = (): boolean => {
  return dependencies.getCurrentSystem() === 'staging';
};

export const dependencies = {
  getCurrentSystem,
};
