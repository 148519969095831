import { merge, cloneDeep } from 'lodash';

import { ITranslationKeys } from './keys';
import { ukTranslations } from './english-uk';
import { DeepPartial } from '../../lib/typeUtils';

// istanbul ignore file

const britishEnglishTranslations: ITranslationKeys = cloneDeep(ukTranslations);

const usTranslationsOverrides: DeepPartial<ITranslationKeys> = {
  translation: {
    UTILISATION: 'Utilization',
    ENROLLMENT: 'Enrollment',
    ENROLLMENT_DATE: 'Enrollment Date',
    ELEMEZ_CORE_V2_SUBTITLE: 'Core - Visualize and act upon key performance metrics, events and trends across the estate.',
    PANASONIC_CORE_V2_SUBTITLE: 'Smart Service - Visualize and act upon key performance metrics, events and trends across the estate.',
    MOBILE: "Cellular",
    MOBILE_AND_WIFI: "Cellular & Wi-Fi",
    MOBILE_DATA: "Cellular Data",
    MOBILE_DATA_TOOLTIP_TEXT: "The recorded $t(MOBILE_DATA) for the last 30 days.",
    MOBILE_DATA_USED: "Cellular Data Used",
  },
  enrollment: {
    SECTION_TITLE: 'Enrollment',
    SECTION_SUBTITLE: 'Enrollment',
  },
  performance: {
    DEVICE_UTILISATION_PAST_30_DAYS: 'Device Utilization over the past 30 Days',
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE: `Utilization of '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE_withFilter: `Utilization of '{{ filter }}' $t(translation:DEVICE_other, lowercase) {{ period }}`,
    MOBILE_DATA: "Cellular Data",
    MOBILE_DATA_USED: "$t(translation:MOBILE_DATA_USED)",
    MOBILE_DATA_LINES: "Cellular Data",
    DATA_USAGE_HOUR_WIFI_MOBILE: "Data Usage Per Hour (Wi-Fi + Cellular)"
  },
  coreApplications: {
    MOBILE_DATA_USED: "$t(translation:MOBILE_DATA_USED)",
    MOBILE_DATA_FULL: "App $t(translation:MOBILE_DATA)",
    MOBILE_DATA_TOOLTIP_TEXT: "The cellular data used by this application on the device in the last 30 days."
  },
  alerts: {
    NO_UTILISATION_ALERT: 'No utilization problems detected with this device'
  },
  agreements: {
    AGREEMENT_DESCRIPTION: 'By accepting this agreement you warrant that you have the authority to accept and bind your organization to its content. Clicking the Accept button is considered equivalent to your signature on this agreement.',
    AGREEMENT_DESCRIPTION_other: 'By accepting these agreements you warrant that you have the authority to accept and bind your organization to their content. Clicking the Accept button is considered equivalent to your signature on these agreements.'
  },
  settings: {
    OUT_OF_CONTACT_TITLE: 'Utilization - Out of Contact Threshold',
    USAGE_TITLE: 'Utilization - Idle Threshold',
    TAB_UTILISATION: 'Utilization',
    MOBILE_SIGNAL_REPORTING_TITLE: "Cellular Signal Levels Collection and Reporting",
  },
  editEvents: {
    TIME_GROUP: 'Time Synchronization',
    MOBILE_RADIO_ON: "Cellular Radio On",
    MOBILE_RADIO_OFF: "Cellular Radio Off"
  },
  distance: {
    METRE: 'meter',
    METRE_other: 'meters',
    KILOMETRE: 'kilometer',
    KILOMETRE_other: 'kilometers'
  }
};

export const usTranslations = merge(britishEnglishTranslations, usTranslationsOverrides);
