import React from "react";
import './roundedLabel.css';

export interface IProps {
  title: string | JSX.Element,
  icon: string,
  type: "red" | "yellow" | "green" | "blue"
}

export function RoundedLabel(props: IProps) {
  return <div className={"rounded-label " + props.type}>
    <i data-id='rounded-label-icon' className={props.icon} />
    {props.title}
  </div>;
}
