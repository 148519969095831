import { TTypedTFunction, useTranslation } from "@lib/useTypedTranslation";

import { capitalize, isNil } from 'lodash';
import { useMemo } from "react";
import { i18n } from "i18next";

import { getDistanceTranslationContext, metresToLongDistance, metresToShortDistance } from "../../lib/distanceConversion";
import { useWorldSettingsContext } from "../../context/worldSettings";
import { tuple } from "../../lib/typeUtils";
import { formatValue } from "../../components/data-table/dataFormatters";
import { ITableColumn } from "../../components/data-table/types";

export const getFormatThreshold = (t: TTypedTFunction, metricDistanceEnabled: boolean) => {
  return (value: number) => {
    if (isNil(value)) {
      return t('GLOBAL');
    }
    return metresToLongDistance(value, metricDistanceEnabled);
  };
};

export const getFormatRadius = (t: TTypedTFunction, metricDistanceEnabled: boolean) => {
  return (value: number) => {
    if (isNil(value)) {
      return formatValue(value);
    }
    return metresToShortDistance(value, metricDistanceEnabled);
  };
};

export const formatUnitTitle = (value: string, i18n: i18n) => {
  if (['en', 'en-US'].includes(i18n.language)) {
    return value.replace(/\((.*)\)/, (_, p1: string) => `(${capitalize(p1)})`);
  }
  return value;
};

export const dependencies = {
  getFormatRadius,
  getFormatThreshold,
  formatUnitTitle
};

const ns = 'homeLocations' as const;
const ns2 = 'translation' as const;
const ns3 = 'distance' as const;
const nsList = tuple(ns, ns2, ns3);

export const useHomeLocationsListColumns = () => {
  const ns = 'translation';
  const { t, i18n } = useTranslation(nsList);
  const { worldSettings } = useWorldSettingsContext();

  const context = getDistanceTranslationContext(worldSettings.useMetricDistances);

  const formatRadius = useMemo(() => dependencies.getFormatRadius(t, worldSettings.useMetricDistances), [t, worldSettings.useMetricDistances]);
  const formatThreshold = useMemo(() => dependencies.getFormatThreshold(t, worldSettings.useMetricDistances), [t, worldSettings.useMetricDistances]);

  const columns: ITableColumn[] = [
    { id: 'name', title: t('NAME', { ns: 'translation' }), dataPath: 'name', sortable: true, processor: formatValue, width: '15rem' },
    { id: 'latitude', title: t('LATITUDE', { ns: 'homeLocations' }), dataPath: 'latitude', processor: formatValue, width: '9rem' },
    { id: 'longitude', title: t('LONGITUDE', { ns: 'homeLocations' }), dataPath: 'longitude', processor: formatValue, width: '9rem' },
    { id: 'addressLine1', title: t('ADDRESS_LINE', { number: 1, ns: 'homeLocations' }), dataPath: 'addressLine1', sortable: true, processor: formatValue, width: '10rem', textAlign: 'left' },
    { id: 'addressLine2', title: t('ADDRESS_LINE', { number: 2, ns: 'homeLocations' }), dataPath: 'addressLine2', sortable: true, processor: formatValue, width: '12rem', textAlign: 'left' },
    { id: 'locality', title: t('LOCALITY', { ns: 'homeLocations' }), dataPath: 'locality', sortable: true, processor: formatValue, width: '9rem', textAlign: 'left' },
    { id: 'postalCode', title: t('POSTCODE', { ns: 'homeLocations' }), dataPath: 'postalCode', sortable: true, processor: formatValue, width: '9rem' },
    { id: 'country', title: t('COUNTRY', { ns: 'homeLocations' }), dataPath: 'country', sortable: true, processor: formatValue, width: '6rem', textAlign: 'left' },
    { id: 'radius', title: dependencies.formatUnitTitle(t('RADIUS_WITH_UNIT', { context, ns: 'homeLocations' }), i18n), dataPath: 'radius', sortable: true, processor: formatRadius, width: '10rem', textAlign: 'right' },
    { id: 'distanceYellow', title: t('WARNING_THRESHOLD_WITH_UNIT', { context, ns: 'homeLocations' }), dataPath: 'distanceYellow', sortable: true, processor: formatThreshold, width: '14rem', textAlign: 'right' },
    { id: 'distanceRed', title: t('PROBLEM_THRESHOLD_WITH_UNIT', { context, ns: 'homeLocations' }), dataPath: 'distanceRed', sortable: true, processor: formatThreshold, width: '14rem', textAlign: 'right' }
  ];

  return columns;
};
