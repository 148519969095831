import { useState } from "react";
import { useLocation } from "react-router-dom";
import { usePrevious } from "./usePrevious";

export function usePathBasedQueryString(pathRegExp: RegExp) {
  const { pathname, search } = useLocation();
  // saved querystring, not changed when query string changes while on a different tab
  const [queryString, setQueryString] = useState('');

  if (pathname.match(pathRegExp) && queryString !== search) {
    setQueryString(search);
  }

  const prevQueryString = usePrevious(queryString);

  return { prevQueryString, queryString };
}
