import { UseTranslationOptions, useTranslation as useRI18NTranslation } from "react-i18next";
import { ITranslationKeys } from "../components/i18n/keys";
import { DefaultNamespace, i18n } from "i18next";

export type TUseTranslation<
TNamespace extends keyof ITranslationKeys = DefaultNamespace
> = (ns?: TNamespace | TNamespace[]) => ({
  t: <
    TKey extends keyof ITranslationKeys[TNamespace2],
    TNamespace2 extends keyof ITranslationKeys = TNamespace
  >(
    translationKey: TKey,
    options?: UseTranslationOptions<undefined> & {
      [key: string]: any,
      ns?: TNamespace2
    }
  ) => string,
  i18n: i18n
});

export type TTypedTFunction<TNamespace extends keyof ITranslationKeys = any> = ReturnType<TUseTranslation<TNamespace>>['t'];

export const useTranslation = <T extends keyof ITranslationKeys = DefaultNamespace>(ns?: T | T[]) => useRI18NTranslation(ns) as any as ReturnType<TUseTranslation<T>>;

export type WithTranslation<TNamespace extends keyof ITranslationKeys = any> = { t: TTypedTFunction<TNamespace> };
