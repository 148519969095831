import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';

import { get, isEmpty } from 'lodash';
import { AlertsData, getMessages, ALERT } from './alertMessages';
import { VIEW } from '../../services/shared/common';
import { useWorldSettingsContext } from '../../context/worldSettings';

import './alerts-panel.css';

interface IProps {
  data: AlertsData,
  withTitle?: boolean,
  className?: string
}

export function getIcon(data: any, property: string): { icon: string, colour: string } {
  if (property === 'information') { return { icon: 'fa fa-info-circle', colour: '#5A82A8' }; }

  if (data === '-' || property === '') { return { icon: 'fa fa-question-circle', colour: '#5A82A8' }; }

  const status = get(data, property);
  switch (status) {
  case VIEW.red:
    return { icon: 'fa fa-times-circle', colour: '#DC7F7F' };
  case VIEW.yellow:
  case VIEW.orange:
    return { icon: 'fa fa-exclamation-triangle enhanced-warning-icon', colour: '#FBCD76' };
  default:
    return { icon: 'fa fa-check', colour: '#3FA67D' };
  }
}

export function AlertsPanel(props: IProps) {
  const { data, withTitle } = props;
  const { worldSettings } = useWorldSettingsContext();
  const { t } = useTranslation();

  if (isEmpty(data)) {
    return null;
  }

  const messages = getMessages(data, worldSettings, t);
  const alerts = messages.map((alert, index: number) => {
    const { status, message } = alert;
    const { icon, colour } = getIcon(data, status);
    return (
      <div key={`alertMessage${index}`} className="alerts-panel__alert">
        <div className='alerts_icon-message'>
          <i className={`alerts-panel__alert-icon ${icon}`} style={{ color: colour }} />
          <span className="alerts-panel__alert-message">{message}</span>
        </div>
      </div>
    );
  });

  const status = get(data, get(messages, '[0].status'));
  const count = status === ALERT.green || !status ? 0 : messages.length;

  return (
    <div className={`alerts-panel ${props.className}`} aria-label='device status alerts panel' >
      { withTitle && <span className="alerts-panel__header">
        <div className="alerts-panel__title">{t('ALERTS', { count })}</div>
        <div className="alerts-panel__info">
          <i className="alerts-panel__info__icon fas fa-broadcast-tower"/>
          <span> {t('LAST_CONTACT')}</span><span className="alerts-panel__info__time-ago"> - {moment(parseInt(data.updated)).fromNow()}</span>
        </div>
      </span>}
      <div className="alerts-panel__alerts">
        {alerts}
      </div>
    </div>
  );
}
