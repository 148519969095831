import React, { useContext, useMemo, useReducer } from 'react';
import { cloneDeep, get, has, isArray, set } from 'lodash';

import { UserSettings } from '../services/config/config';

export type UpdateProperties = (paths: string | string[], updates: Partial<UserSettings>) => void;

export interface UserSettingsContextValue extends UserSettings {
  updateProperties: UpdateProperties
}

export const UserSettingsContext = React.createContext<UserSettingsContextValue>(undefined);

interface UserSettingsProviderProps {
  initialState: UserSettings,
  children: React.ReactNode
}

export type UserSettingsAction =
| { type: 'onUserSettingsUpdate', paths: string | string[], updates: Partial<UserSettings> };

export function userSettingsReducer(state: UserSettings, action: UserSettingsAction): UserSettings {
  if (action.type === 'onUserSettingsUpdate') {
    const { updates, paths } = action;
    const settings = cloneDeep(state);
    for (const path of isArray(paths) ? paths : [paths]) {
      if (has(updates, path)) {
        set(settings, path, get(updates, path));
      }
    }
    return settings;
  }
  return state;
}

export const UserSettingsProvider = ({ children, initialState }: UserSettingsProviderProps) => {
  const [state, dispatch] = useReducer(userSettingsReducer, initialState);

  const contextFunctions = useMemo(() => {
    const updateProperties = (paths: string | string[], updates: Partial<UserSettings>) => {
      return dispatch({ type: 'onUserSettingsUpdate', paths, updates });
    };

    return {
      updateProperties
    };
  }, [dispatch]);

  const contextValue: UserSettingsContextValue = {
    ...state,
    ...contextFunctions
  };

  return (
    <UserSettingsContext.Provider value={contextValue}>
      {children}
    </UserSettingsContext.Provider>
  );
};

export function useUserSettingsContext() {
  const context = useContext(UserSettingsContext);
  if (context === undefined) {
    throw new Error('useUserSettingsContext must be used within a UserSettingsProvider');
  }
  return context;
}
