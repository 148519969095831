import * as React from 'react';
import { useErrorHandler } from '@lib/useErrorHandler';
import { useTranslation } from '@lib/useTypedTranslation';
import { Greetings } from '../../components/authentication/greetings';
import { AppLoading } from '../../components/loading/appLoading';
import { useNonWorldRequest } from '../../lib/useNonWorldRequest';
import { useNonWorldRoutes } from '../../routes';
import { getWorlds, World } from '../../services/worlds/worlds';
import { AccessDenied } from './index';

export function AccessDeniedToWorld() {
  const { t } = useTranslation('accessDenied');
  const fetchWorlds = React.useCallback(() => getWorlds(), []);
  const { data: worlds, error, loading } = useNonWorldRequest<World[]>(fetchWorlds);
  const routes = useNonWorldRoutes();

  useErrorHandler(error);

  if (loading) { return <AppLoading />; }

  const returnLink = worlds?.length === 1 ? `/${worlds?.[0].id}/` : routes.worldSelection;
  return (
    <>
      <Greetings worldAware={false} logoLink={returnLink}>
        <AccessDenied
          redirectLink={returnLink}
          translationKeySuffix='WORLD'
          redirectLinkMessage={worlds?.length === 1 ? t('RETURN_TO_PRODUCT_SELECTION') : t('RETURN_TO_WORLD_SELECTION')}
        />
      </Greetings>
    </>
  );
}
