import React from 'react';
import styled from 'styled-components';
import { Theme } from '../../containers/app/themes';

export interface StackedOverviewItem {
  dataId?: string,
  label?: string | JSX.Element,
  value: number | string | JSX.Element | undefined
}

interface Props {
  columns: StackedOverviewItem[][],
  className?: string
}

const Container = styled.dl`
  display: flex;
  justify-content: center;
`;

const Container2 = styled.div`
  display: flex;
  flex-basis: 95%;
  justify-content: space-between;
`;

const Column = styled.div`
  flex-grow: 1;
  &:not(:last-child) {
    ${({ theme }: { theme: Theme }) => `border-right: 3px solid ${theme.sectionBorders.colours.light};`}
    padding-right: 20px;
  }

  &:not(:first-child) {
    padding-left: 20px;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

const ItemLabel = styled.dt`
  font-weight: ${({ theme }: { theme: Theme}) => theme.font.weights.semiBold};
`;

export function StackedOverview(props: Props) {
  const { columns } = props;

  return <Container className={props.className}><Container2>
    {columns.map((column, i) => (
      <Column key={i}>
        {column.map((item, j) => (
          <Item key={i + j} data-id={item.dataId || ''}>
            {item.label && <ItemLabel>{item.label}</ItemLabel>}
            <dd>{item.value}</dd>
          </Item>
        ))}
      </Column>
    ))}
  </Container2></Container>;
}
