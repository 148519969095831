import * as React from 'react';

import { getGroups, getHomeLocations, getZones, IGroups, IHomeLocation, IZone } from '../../../services/config/config';
import { Option, getHomeLocationOptionMapper, nullHomeLocation, Group, getGroupOptionMapper, NullZone, nullZone, getZoneOptionMapper, Zone } from '../../../components/controls/optionPickerLib';
import { useCallback, useMemo, useState } from 'react';
import { useRequest } from '../../../lib/useRequest';
import { World } from '../../../services/worlds/worlds';
import { useTranslation } from '@lib/useTypedTranslation';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { RequestInitWithRetry } from '../../../lib/request';

export enum CustomViewType {
  groupHomeLocation = 'groupHomeLocation',
  zone = 'zone'
}

export type Props = {
  customViewType: CustomViewType,
  worldIds: World['id'][],
  showPopup: boolean
};

export type Options = {
  homeLocations: Option<IHomeLocation>[],
  groups: Option<Group>[],
  zones: Option<Zone>[]
};

export const useCustomViewOptions = (props: Props) => {
  const { t } = useTranslation('userManagement');
  const { showPopup, worldIds, customViewType } = props;
  const [options, setOptions] = useState<Options>({
    homeLocations: [],
    groups: [],
    zones: []
  });
  const [optionsLoaded, setOptionsLoaded] = useState(false);

  const mapHomeLocationsToOptions = useMemo(() => getHomeLocationOptionMapper(t, (t: TTypedTFunction) => t('ALL_HOME_LOCATIONS')), [t]);
  const mapGroupsToOptions = useMemo(() => getGroupOptionMapper(t, (t: TTypedTFunction) => t('ALL_GROUPS')), [t]);
  const mapZonesToOptions = useMemo(() => getZoneOptionMapper(), []);

  const onOptionsLoaded = useCallback((result: ({ homeLocations: IHomeLocation[], groups: IGroups } | { zones: IZone[] })) => {
    if ('homeLocations' in result) {
      const availableHomeLocations = [nullHomeLocation, ...result.homeLocations];
      const availableGroups = [null, ...result.groups.groups.nodes.map(g => g.name)];
      setOptions((o) => ({
        homeLocations: mapHomeLocationsToOptions(availableHomeLocations),
        groups: mapGroupsToOptions(availableGroups),
        zones: o.zones
      }));
    } else {
      const zoneFilterData = result.zones.map(({ id, name }) => ({ id, name })); // getting only id and name from zones
      const availableZones = [...zoneFilterData];
      setOptions((o) => ({
        homeLocations: o.homeLocations,
        groups: o.groups,
        zones: mapZonesToOptions(availableZones)
      }));
    }
    setOptionsLoaded(true);
  }, [mapGroupsToOptions, mapHomeLocationsToOptions, mapZonesToOptions]);

  const fetcher = useCallback(() => {
    // refetch suggestions on show to ensure values are up to date
    if (showPopup && worldIds?.length === 1) {
      return async function (options: RequestInitWithRetry) {

        setOptionsLoaded(false);
        if (customViewType === CustomViewType.groupHomeLocation) {
          const [groups, homeLocations] = await Promise.all([
            getGroups()(options), getHomeLocations()(options)
          ]);
          return { groups, homeLocations };
        }
        const zones = await getZones()(options);
        return { zones };
      };
    }
  }, [showPopup, worldIds, customViewType]);

  /* here we are using useRequest rather than useWorldRequest to fetch the home locations
      relevant to the world access set in the modal dialogue */
  useRequest(fetcher, { onSuccess: onOptionsLoaded }, worldIds?.[0]);

  return { homeLocationOptions: options.homeLocations, groupOptions: options.groups, zoneOptions: options.zones, optionsLoaded };
};
