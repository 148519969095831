import React, { useCallback } from "react";
import { useErrorHandler } from '@lib/useErrorHandler';
import { useLocation, Redirect } from "react-router-dom";
import { AppLoading } from "../components/loading/appLoading";
import { useNonWorldRequest } from "../lib/useNonWorldRequest";
import { getWorlds, World } from "../services/worlds/worlds";

export const NoWorldIdRoute = () => {
  const fetchWorlds = useCallback(() => getWorlds(), []);
  const { error: worldsError, data: worlds } = useNonWorldRequest<World[]>(fetchWorlds, { onError: false });
  const { search } = useLocation();
  useErrorHandler(worldsError);

  if (worlds && worlds.length !== 1) {
    return (
      <Redirect to={{
        pathname: '/world-selection',
        state: { worlds }
      }} />
    );
  }

  const getTo = () => {
    const hash = window.location.hash.split(`#/`)[1];
    const withoutSearch = search ? hash.split(search)[0] : hash;
    return `/${worlds?.[0]?.id}/${withoutSearch || ''}`;
  };

  if (!worlds) {
    return <AppLoading />;
  }

  const regex = new RegExp('[a-fA-F0-9]{32}|^demo.*');
  if (worlds && !regex.test(worlds?.[0]?.id)) {
    throw new Error('World id does not match expected format');
  }

  return (
    <Redirect to={{
      pathname: getTo(),
      search,
      state: { worlds }
    }} />
  );
};
