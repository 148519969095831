import * as React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import { useEffect, useState } from 'react';
import { get, groupBy, isEqual, pick } from 'lodash';
import moment from 'moment';
import { useTranslation } from '@lib/useTypedTranslation';

import { AmChart, coreTheme } from './amChart';
import { IDeviceDischarge } from '../../services/core/devicePerformance';
import { addHoverAnimation } from './onHoverAnimation';
import { addEventOverlaysToColumnChart } from '../../containers/core/device/compositions/performance/charts/lib/eventOverlays';
import { highlightWeekends } from '../../containers/core/device/compositions/performance/charts/lib/weekendsHighlight';
import { formatDateAsOrdinal } from '../../lib/ordinal';
import { DeviceEvent } from '../../services/core/eventsTypes';

import '../../containers/core/device/compositions/performance/charts/charts.css';
import '../../containers/core/device/compositions/performance/events/deviceEvents.css';

interface IProps {
  data: IDeviceDischarge[],
  events?: DeviceEvent[],
  setDate?: (date: string) => void,
  loading: boolean,
  setLoading: (loading: boolean) => void,
  className?: string,
  onMount?: (chart: am4charts.XYChart) => am4charts.XYChart
}

export function formatTooltipDate(date: string) {
  return moment.utc(parseInt(date)).format('ll');
}

export function AverageDischargeChart(props: IProps) {
  const { t } = useTranslation(['performance', 'editEvents', 'translation']);
  const [dataValidated, setDataValidated] = useState(false);
  const data = getData();

  useEffect(() => {
    if (props.loading && dataValidated) {
      setDataValidated(false);
    }
  }, [props.loading, dataValidated]);

  function onDataValidated() {
    if (!props.loading) {
      setDataValidated(true);
    }
  }

  return (
    <div className={`${props.className} chart-and-legend-container ${dataValidated ? 'chart-ready' : ''}`}>
      <div className="chart-container">
        <AmChart
          chartProvider={createChart}
          onUpdate={onDataUpdated}
          dataProvider={() => { props.setLoading(false); return data; }}
          link={props.events ? pick(props, 'data', 'events') : pick(props, 'data')}
          cypressId="last30DaysAverageDischarge"
          onDataValidated={onDataValidated}
          onMount={props.onMount}
        />
      </div>
      <div id="average-discharge-30-day-chart-legend" />
    </div>
  );

  /* istanbul ignore next */
  function createChart(id: string) {
    am4core.useTheme(coreTheme);

    const chart: am4charts.XYChart = am4core.create(id, am4charts.XYChart);
    chart.data = props.data;
    chart.paddingTop = 40;
    chart.minHeight = 240;

    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "date";
    xAxis.cursorTooltipEnabled = false;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 50;
    //format axis here instead of in getData() to prevent duplicate categories e.g(5th)
    xAxis.renderer.labels.template.adapter.add("textOutput", function (text, target) {
      if (text) {
        const dateAsNumber = parseInt(text);
        return moment(dateAsNumber).startOf('day').isSame(moment().startOf('day')) ? 'Today' : formatDateAsOrdinal(dateAsNumber);
      }
    });

    const yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;
    yAxis.max = 100;
    yAxis.renderer.minGridDistance = 25;
    yAxis.numberFormatter.numberFormat = "#'%'";
    yAxis.cursorTooltipEnabled = false;
    yAxis.contentAlign = 'right';

    const series = chart.series.push(new am4charts.ColumnSeries());
    series.columns.template.fill = am4core.color('#1aa0d1');
    series.columns.template.stroke = am4core.color('#1aa0d1');
    series.columns.template.width = am4core.percent(90);
    series.yAxis = yAxis;
    series.dataFields.valueY = "level";
    series.dataFields.categoryX = "date";
    series.columns.template.tooltipHTML = ``;

    series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
    props.setDate && series.columns.template.events.on("hit", (event: any) => {
      const date = event.target.dataItem.dataContext.date;
      props.setDate(date.toString());
    });

    series.columns.template.adapter.add("tooltipHTML", (_, target) => {
      const data: any = target.tooltipDataItem.dataContext;
      if (!data) {
        return '';
      }
      const date = formatTooltipDate(data.date);
      return `<span class="average-discharge-30-day-tooltip-title">${date}</span></br><span class="average-discharge-30-day-tooltip-text"><div class="key-blue"></div>&nbsp;${t('AVG_HOURLY_DISCHARGE', { ns: 'performance' })}: {valueY.formatNumber("#.##'%'")}</span>`;
    });

    chart.legend = new am4charts.Legend();
    chart.legend.labels.template.text = t('AVG_HOURLY_DISCHARGE_LINES', { ns: 'performance' });
    chart.legend.labels.template.fontWeight = '400';

    const markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;
    chart.legend.useDefaultMarker = true;

    markerTemplate.disposeChildren();
    const legendItem = markerTemplate.createChild(am4core.Rectangle);
    legendItem.fill = am4core.color('#1aa0d1');
    legendItem.width = 13;
    legendItem.height = 13;

    chart.legend.itemContainers.template.clickable = false;
    chart.legend.itemContainers.template.focusable = false;
    chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;

    function createGrid(value: number) {
      const range = yAxis.axisRanges.create();
      range.value = value;
      range.label.text = "{value}";
    }
    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.labels.template.disabled = true;
    createGrid(0);
    createGrid(25);
    createGrid(50);
    createGrid(75);
    createGrid(100);

    addHoverAnimation(series, chart);
    props.events && addEventOverlaysToColumnChart(series, chart, xAxis, t);

    //create axis ranges for weekends
    props.events && highlightWeekends(xAxis);

    return chart;
  }

  /* istanbul ignore next */
  function onDataUpdated(chart: any, chartData: any[], prevProps?: any, currentProps?: any) {
    // add overlays on update
    if (props.events && !isEqual(get(currentProps, 'link.events'), get(prevProps, 'link.events'))) {
      addEventOverlaysToColumnChart(chart.series.values[0], chart, chart.xAxes.values[0], t);
      highlightWeekends(chart.xAxes.values[0]);
    }

    return chart;
  }

  function getData() {
    if (!props.data.length) {
      return [];
    }
    const allDays = getLast30Days();
    const dataByDay = groupBy(props.data, (datum) => moment.utc(datum.date).startOf('day').valueOf());
    const eventsByDay = groupBy(props.events, (event) => moment.utc(event.local).startOf('day').valueOf());
    return allDays.map((date: number) => {
      return {
        date,
        level: get(dataByDay, `${date}[0].level`, 0) || 0,
        events: get(eventsByDay, date, [])
      };
    });
  }

  function getLast30Days(): number[] {
    const items = Array(30).fill(0);
    const startDate = moment.utc().subtract(29, 'day').startOf('day');

    return items.map((_, index) => {
      return moment.utc(startDate).add(index, 'day').valueOf();
    });
  }
}
