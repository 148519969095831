import React, { useContext } from 'react';

import { CurrentWorld } from '../services/worlds/worlds';

export const CurrentWorldContext = React.createContext<CurrentWorld>(undefined);

interface CurrentWorldProviderProps {
  value: CurrentWorld,
  children: React.ReactNode
}

export const CurrentWorldProvider = ({ children, value }: CurrentWorldProviderProps) => {
  return (
    <CurrentWorldContext.Provider value={value}>
      {children}
    </CurrentWorldContext.Provider>
  );
};

export function useCurrentWorldContext() {
  const context = useContext(CurrentWorldContext);
  if (context === undefined) {
    throw new Error('useCurrentWorldContext must be used within a CurrentWorldProvider');
  }
  return context;
}
