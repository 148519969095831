import * as React from 'react';
import { chain, includes, pull, union, isString, sortBy, get } from 'lodash';
import { Col } from 'react-bootstrap';

import { CheckBox, ICheckboxValue } from '../controls/checkbox';
import {
  IFilterOption,
  FilterSettings,
  FilterSort,
  defaultFilterSort,
  defaultFilterGroupSort,
  FilterGroup,
  GroupSort
} from './filterLib';
import { SearchableList, SearchType } from '../searchableList/searchableList';
import styled from 'styled-components';
import { Theme } from '../../containers/app/themes';

type DefaultProps = {
  id: string,
  filterName: string,
  filterIcons?: FilterSettings,
  filterClasses?: FilterSettings,
  filterSort?: FilterSort,
  availableFilters: IFilterOption[],
  selectedFilterIds: string[],
  onChange: (id: string, selectedFilterIds: string[]) => void
};

/* search is not supported for grouped columns / columns with additional columns */
export type IFilterGroupProps = DefaultProps & ({ groups?: FilterGroup[], groupSort?: GroupSort, additionalCols?: { id: string, name: string }[] } | { searchable: boolean });

const SearchColumn = styled(SearchableList)`
  & input {
    width: 95%;
    height: 1.8rem;
    font-size: ${({ theme }: { theme: Theme}) => theme.font.sizes.fourteenPixels};
  }

  & .fa.fa-times {
    top: 0.45rem;
  }
`;

const FilterRow = styled.ul`
  padding-left: 0;
  margin-bottom: 0;
  width: 100%;
`;

export function FilterColumn(props: IFilterGroupProps) {
  const {
    id: groupId,
    filterName,
    filterIcons = {},
    filterClasses = {},
    filterSort,
    availableFilters,
    selectedFilterIds,
    onChange
  } = props;

  function handleChange(event: ICheckboxValue) {
    handleFilterItemChange(event.name, event.checked);
  }

  function handleFilterItemChange(filterId: string, isSelected: boolean) {
    const modifiedFilterIds = isSelected
      ? union(selectedFilterIds, [filterId])
      : pull(selectedFilterIds, filterId);
    onChange(groupId, modifiedFilterIds);
  }

  const getFilterRows = (filters: IFilterOption[]) => {
    const filterBlocks = chain(filters)
      .sortBy(filterSort || defaultFilterSort)
      .map((filter: IFilterOption) => {
        const { value, id: filterId } = isString(filter) ? { value: filter, id: filter } : filter;
        const selected = includes(selectedFilterIds, filterId);
        const icon = filterIcons[filterId];
        const filterClass = filterClasses[filterId] || '';
        const key = `filter_block_${groupId}_${filterId}`;
        let additionalCols;
        if ('additionalCols' in props && props.additionalCols) {
          // create a cell per additionalCol specified, populate with matching value from filter IF set
          additionalCols = props.additionalCols.map(({ id }) => {
            return (
              <li key={id} className={`filter-cell filter-cell--additional filter-cell--id-${id}`}>
                {get(filter, `additionalCols.${id}`)}
              </li>
            );
          });
        }
        return {
          element: <FilterRow key={key} data-cy={key} aria-label={filterName} className={`filter-box filter-row ${filterClass}`}>
            <div className="filter-cell">
              <CheckBox
                className="fancy-checkbox"
                name={filterId}
                value={value}
                checked={selected}
                icon={icon}
                onChange={handleChange}
              />
            </div>
            {additionalCols}
          </FilterRow>,
          text: value
        };
      }).value();
    return filterBlocks;
  };

  let column;
  if ('groups' in props && props.groups) {
    column = sortBy<FilterGroup>(props.groups, props.groupSort || defaultFilterGroupSort).map(({ id, name, filterIds }) => {
      return [
        { element: <><div key={id} className="filter-row">
          <h4 data-id='filter-colomn__group-header' className="filter-column__group-header filter-cell" data-cy={`group-id--${id}`}>{name}</h4>
        </div>
        {getFilterRows(availableFilters.filter((f) => filterIds.includes(isString(f) ? f : f.id))).map(e => e.element)}</>, text: name }
      ];
    }).flat();
  } else {
    column = getFilterRows(availableFilters);
  }

  const filterKey = `filter-id--${props.id}`;
  const additionalColumns = 'additionalCols' in props && props.additionalCols ? props.additionalCols : [];

  return (
    <Col className={`filter-selection-column_container col ${filterKey}`} data-cy={filterKey} >
      <div className="filter-column-scroll-wrapper">
        <div className="filter-table">
          <div className="filter-header">
            <div className="filter-row">
              <h3 className="filter-cell">{filterName}</h3>
              {additionalColumns.map(({ name, id }) => <h3 className={`filter-cell filter-cell--additional filter-cell--id-${id}`} key={id}>{name}</h3>)}
            </div>
          </div>
          <div className="filter-column">
            {'searchable' in props && props.searchable ? <SearchColumn searchType={SearchType.startOfText} items={column} /> : column.map(c => <React.Fragment key={c.text}>{c.element}</React.Fragment>)}
          </div>
        </div>
      </div>
    </Col>
  );
}
