import Card from '../../components/card/card';
import { UsersList } from './users-list';

import './users-list.css';

export function UserManagement() {
  return (
    <div className="user-management-container">
      <Card noPadding={true}>
        <div className="user-management">
          <div className="users_list">
            <UsersList />
          </div>
        </div>
      </Card>
    </div>
  );
}
