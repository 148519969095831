import React, { useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { isString } from 'lodash';

import { ModalPopup } from '../controls/modalPopup';
import { CheckBox } from '../controls/checkbox';
import { FormControls } from '../forms/formControls';

import './delete-dialogue.css';

function CheckboxDialogue(props: { checked: boolean, toggleChecked: () => void }) {
  const { checked, toggleChecked } = props;
  const { t } = useTranslation('translation');
  return (
    <div className="checkbox-with-message">
      <CheckBox
        className="fancy-checkbox"
        name="checkbox-in-delete-popup"
        checked={checked}
        onChange={toggleChecked}
        label={<span aria-label="checkbox dialogue text" className='checkbox_confirmation_message'>{t('CHECKBOX_MESSAGE')}</span>} />
    </div>
  );
}

export interface IProps {
  show: boolean,
  subheader: string,
  warning: string,
  isDeleting: boolean,
  error: string | boolean,
  checkboxRequired: boolean,
  handleDelete: () => Promise<boolean>, // should resolve with true if delete is successful
  handleClose: () => void,
  count?: number
}

export function DeleteDialogue(props: IProps) {
  const { t } = useTranslation('deleteDialogue');
  const {
    show, subheader, checkboxRequired,
    error, warning, isDeleting, count,
    handleClose, handleDelete
  } = props;
  const [checked, setChecked] = useState(false);

  const onClose = () => {
    setChecked(false);
    handleClose();
  };

  const onDelete = async () => {
    const success = await handleDelete();
    if (success) {
      onClose();
    }
  };

  const toggleChecked = () => setChecked(c => !c);

  const isConfirmed = !checkboxRequired || checked;
  const footer = (
    <FormControls
      onSubmit={onDelete}
      onCancel={onClose}
      submitDisabled={!isConfirmed || Boolean(error)}
      submitResult={{
        status: isDeleting ? 'loading' : error ? 'error' : null,
        message: error ? (isString(error) ? error : t('ERROR')) : null
      }}
      mode='delete'
      count={count}
    />
  );
  return (
    <div>
      <ModalPopup
        show={show}
        handleClose={onClose}
        header={t('CONFIRMATION_QUESTION')}
        subheader={subheader}
        classname="delete-dialogue"
        closeDisabled={true}
        footer={footer}>
        <div className="delete-dialogue_body">
          <span className="delete-dialogue_warning">{warning}</span>
          {checkboxRequired &&
            <CheckboxDialogue
              toggleChecked={toggleChecked}
              checked={checked}
            />}
        </div>
      </ModalPopup>
    </div>
  );
}
