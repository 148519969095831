import React from "react";
import { useTranslation } from '@lib/useTypedTranslation';
import { Modal } from "react-bootstrap";
import './modalPopup.css';
import { CloseButton } from "./closeButton";

export interface IModalPopupProps {
  header: React.ReactNode,
  subheader?: React.ReactNode,
  show: boolean,
  handleClose: () => void,
  children?: React.ReactNode,
  classname?: string,
  footer?: React.ReactNode,
  closeDisabled?: boolean
}

export function ModalPopup(props: IModalPopupProps) {
  const { t } = useTranslation('translation');
  let footer = null;
  if (props.footer) {
    footer = (
      <Modal.Footer data-id='modal_footer'>
        <div className="modal_footer">
          {props.footer}
        </div>
      </Modal.Footer>
    );
  }
  return (
    <Modal aria-label="modal dialogue" className={`modalPopup_component ${props.classname}`} animation={false} size="lg" show={props.show} onHide={props.handleClose}>
      {!props.closeDisabled && <CloseButton handleClose={props.handleClose} className='modal-close' />}
      <Modal.Header>
        <Modal.Title>
          <div className="header_title">{props.header}</div>
          {props.subheader && <div aria-label="subheader text" className="subheader_title">{props.subheader}</div>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body data-id='modal_body'><div className="modal_body">{props.children}</div></Modal.Body>
      {footer}
    </Modal>
  );
}
