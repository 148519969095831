import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { encode } from '../../lib/base64';
import { LinkButton } from './linkButton';
import { useWorldRoutes } from '../../routes/parts/allWorldRoutes';
import { useBrandContext } from '../../context/brand';

interface IProps {
  id?: string,
  deviceId: string,
  className?: string
}

export function ViewInCoreButton(props: IProps) {
  const { t } = useTranslation();
  const base64Id = encodeURIComponent(encode(props.deviceId));
  const linkToDeviceOverview = useWorldRoutes().core.deviceOverview;
  const internalLink = `${linkToDeviceOverview}?id=${base64Id}`;
  const { isPanasonic } = useBrandContext();
  const text = isPanasonic ? t('VIEW_IN_SMART_SERVICE') : t('VIEW_IN_CORE');

  return (
    <LinkButton dataId="view-in-core" className={props.className || ''} to={internalLink} external={false}>{text}</LinkButton>
  );
}
