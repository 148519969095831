import * as React from 'react';
import classNames from "classnames";
import './styles.css';
import Spinner from './loadingSpinner';

interface IProps {
  isLoading: boolean,
  transparentOverlay: boolean,
  className?: string,
  hideChildren?: boolean
}

export class Loading extends React.Component<React.PropsWithChildren<IProps>> {
  public render() {
    const LoadingParts = () => {
      return (
        <div>
          <div className="loading-transparent-overlay" />
          <div className="loading-spinner">
            <Spinner />
          </div>
        </div>
      );
    };

    const TransparentOverlayView = () => {
      return <div className={`${this.props.className || ''} loading-container`}>
        <div className={classNames('overlay', { "loading-content": this.props.isLoading, "loading": this.props.isLoading })}>
          {this.props.children}
        </div>
        <LoadingParts />
      </div>;
    };

    const LoadingView = () => {
      if (this.props.transparentOverlay) {
        return <TransparentOverlayView />;
      }
      return (
        <div className={`${this.props.className || ''} loading-container`}>
          <div className="loading-content loading" />
          <div className="loading-overlay" />
          <div className="loading-spinner">
            <Spinner />
          </div>
        </div>
      );
    };

    if (this.props.hideChildren) {
      return (
        <>
          {this.props.isLoading && <LoadingView />}
          <div hidden={this.props.isLoading}>{this.props.children}</div>
        </>
      );
    }

    return this.props.isLoading ? <LoadingView /> : this.props.children;
  }
}
