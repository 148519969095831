import * as React from 'react';

import { StandardPage } from './standardPage';
import { useCurrentUserContext } from '../../context/currentUser';
import { AccessDeniedToPage } from '../../containers/access-denied/accessDeniedToPage';
import { AccessDeniedToProduct } from '../../containers/access-denied/accessDeniedToProduct';
import { useProductsContext } from '../../context/products';
import { UserAuth, UserRole } from '../../components/authentication/userAuth';
import { Product } from '../../containers/app/productsWrapper';

export interface IAccessProps {
  product?: Product,
  role?: UserRole,
  title?: string,
  children?: any
}

const checkUserRole = (userAuth: UserAuth, minimumRole: UserRole): boolean => {
  switch (minimumRole) {
  case 'administrator':
    return userAuth.isAdministrator;
  case 'manager':
    return userAuth.isAdministrator || userAuth.isManager;
  case 'editor':
    return userAuth.isAdministrator || userAuth.isManager || userAuth.isEditor;
  }

  return true;
};

export const Access = (props: IAccessProps) => {
  const { product, title, role } = props;
  const currentUser = useCurrentUserContext();
  const productsData = useProductsContext();
  const licenseOK = !product || productsData[product].enabled;
  const userOk = checkUserRole(currentUser, role || 'viewer');

  if (licenseOK && userOk) {
    return props.children;
  }

  return (
    <StandardPage title={title}>
      {licenseOK ? <AccessDeniedToPage /> : <AccessDeniedToProduct />}
    </StandardPage>
  );
};
