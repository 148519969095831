/**
 * Make keys K optional on type/interface T
 *
 * e.g.
 * interface Biscuits {
 *   oreo: boolean,
 *   richTea: boolean,
 *   hobnob: boolean,
 * }
 *
 * to make 'richTea' and 'nice' optional:
 * type TastyBiscuits = WithOptional<Biscuits, 'richTea' | 'nice'>;
 */
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/**
 * Make all but keys K optional on type/interface T
 *
 * e.g.
 * interface Biscuits {
 *   oreo: boolean,
 *   richTea: boolean,
 *   hobnob: boolean,
 * }
 *
 * to make only 'oreo' optional:
 * type BadBiscuits = OptionalExceptFor<Biscuits, 'richTea' | 'nice'>;
 */
export type OptionalExceptFor<T, K extends keyof T> = Partial<T> & Pick<T, K>;

/** Make all properties of an object optional, including nested properties */
export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
};

/**
 * Return args as an array with tuple type
 *
 * e.g.
 * [1, 'a'] has type (number | string)[]
 * tuple(1, 'a') has type [number, string]
 * */
export const tuple = <T extends any[]>(...elements: T) => {
  return elements;
};

/**
 * True if both types are identical, i.e. they extend each other
 * further discussion at: https://github.com/Microsoft/TypeScript/issues/27024
 *
 * e.g.
 * type a  = 'a' | 'b'
 * type b  = 'a' | 'b'
 * type c  = 'a' | 'foo'
 * type areEqual = Equals<a, b>  // true
 * type areNotEqual = Equals<a, c>  // false
 * */
export type Equals<T, S> =
  [T] extends [S] ? (
    [S] extends [T] ? true : false
  ) : false;

/**
 * Creates an object type with identical key/value pairs from a readonly string array
 *
 * e.g.
 * type keys  = ['a', 'b'] as const;
 * const same: SameValueAsKeys<typeof keys> = { a: 'a', b: 'b' };
 * */
export type SameValueAsKeys<KS extends Readonly<string[]>> = { [K in KS[number]]: K };

/**
 * Analogous to keyof, returns a union type of possible values of T
 *
 * e.g.
 * type Foo = { a: number, b: string};
 * valueof<Foo> has type (string | number)
 * */
export type valueof<T> = T[keyof T];

/**
 * Opposite of ReadOnly, makes all properties mutable
 *
 * e.g.
 * type Foo = { readonly a: number, b: string};
 * Mutable<Foo> has type { a: number, b: string}
 * */
export type Mutable<T> = { -readonly [P in keyof T ]: T[P] };

/**
 *  expects at least one item from a type to be defined
 *
 * e.g interface Hello = { itemOne: number, itemTwo: string, itemThree?: string };
 *  const a: AtLeastOne<Hello> = { itemOne: 1 }; is valid
 *  const a: AtLeastOne<Hello> = { itemOne: 1, itemTwo: 'hello' }; is valid
 * const a: AtLeastOne<Hello> = { itemThree: 'hello' }; is valid
 * const a: AtLeastOne<Hello> = {}; is invalid
* */
export type AtLeastOne<T, U = {[K in keyof T]: Pick<Required<T>, K> }> = Partial<T> & U[keyof U];
