import React, { useEffect } from 'react';
import Bowser from 'bowser';
import { kebabCase } from 'lodash';
import AppRoutes from '../../routes';
import updateDateFormats from '../../lib/dateFormats';

export const AppWrapper = () => {
  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const browserName = browser.getBrowserName(true);
    const browserClass = `browser-${kebabCase(browserName)}`;
    // e.g. browser-safari / browser-chrome / browser-edge / browser-internet-explorer
    document.body.classList.add(browserClass);
  }, []);

  useEffect(() => {
    updateDateFormats();
  }, []);

  return (
    <AppRoutes />
  );
};
