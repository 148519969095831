import React from 'react';

import Card from '../../../components/card/card';
import { AnomaliesList } from './compositions/anomaliesList';

export function CoreAnomalies() {
  return (
    <div data-id="core-anomalies">
      <Card noPadding={true}>
        <div>
          <div>
            <AnomaliesList />
          </div>
        </div>
      </Card>
    </div>
  );
}
