
import * as React from 'react';
import { useState, useRef, useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { isEqual } from 'lodash';

import { PlatformSettings } from './platformSettings';
import { PreventNavigation } from '../../../../components/navigation/preventNavigation';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';
import { FormControls } from '../../../../components/forms/formControls';

interface SubmitResult {
  success: boolean,
  message: string
}

interface IProps {
  worldSettings: WorldSettings,
  saveData: (data: { worldSettings: WorldSettings }) => Promise<void>
}

export function PlatformSettingsPage(props: IProps) {
  const { t } = useTranslation('settings');
  const [worldSettings, setWorldSettings] = useState<WorldSettings>(props.worldSettings);
  const [submitResult, setSubmitResult] = useState<SubmitResult>(undefined);
  const formRef = useRef(null);

  const areButtonsDisabled = useMemo(() => {
    const hasSettingsChanged = !isEqual(worldSettings, props.worldSettings);
    const hasError = submitResult?.success === false;
    return !hasSettingsChanged || hasError;
  }, [worldSettings, props.worldSettings, submitResult?.success]);

  function onDataChanged(data: WorldSettings) {
    setWorldSettings(data);
  }

  function handleCancel() {
    setWorldSettings(props.worldSettings);
  }

  async function handleSubmit() {
    try {
      setSubmitResult(undefined);
      await props.saveData({ worldSettings: worldSettings });
      setSubmitResult({ message: t('SAVE_SUCCESS'), success: true });
    } catch (e) {
      setSubmitResult({ message: t('SAVE_FAIL'), success: false });
    }
  }

  return (
    <div className='primary-identifier-settings-platform'>
      <form ref={formRef}>
        <PreventNavigation enabled={!areButtonsDisabled} />
        <PlatformSettings
          onDataChanged={onDataChanged}
          worldSettings={worldSettings}
        />
        <FormControls
          submitResult={{
            message: submitResult?.message,
            status: submitResult ? submitResult.success ? 'success' : 'error' : null
          }}
          cancelDisabled={areButtonsDisabled}
          submitDisabled={areButtonsDisabled}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          mode='save'
        />
      </form>
    </div>
  );
}
