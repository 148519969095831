import React from 'react';
import { Auth } from '@aws-amplify/auth';
import { AuthState } from '@aws-amplify/ui-components';
import { useErrorHandler } from '@lib/useErrorHandler';

import { dispatchAuthStateChangeEvent } from './utils';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from '../../context/auth';

export interface Props {
  children?: React.ReactNode
}

export const SignOut = ({ children }: Props) => {
  const auth = useAuthContext();
  const handleError = useErrorHandler();
  const history = useHistory();

  const signOut = async () => {
    const federatedUser = auth.authConfig.oauth;
    await Auth.signOut() // N.B. async await added for testing purposes
      .then(() => {
        dispatchAuthStateChangeEvent(AuthState.SignedOut);
        if (!federatedUser) { // federated users are already redirected separately
          history.push('/');
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  return (
    <div onClick={signOut}>
      {children}
    </div>
  );
};
