import { HashRouter as Router } from 'react-router-dom';

import { StyleWrapper } from './styleWrapper';
import { CssVariables } from './globalStyle';
import { BrandWrapper } from './brandWrapper';
import { HelmetProvider } from 'react-helmet-async';

// global font-awesome icon style overrides
import './icons.css';
// global css variables
import './variables.css';

export const App = () => (
  <HelmetProvider>
    <Router>
      <BrandWrapper>
        <CssVariables />
        <StyleWrapper />
      </BrandWrapper>
    </Router>
  </HelmetProvider>
);
