import { camelCase, without } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';

export type CheckBoxValue = {
  id: string,
  name: string
};

export interface Props {
  initialCheckedIds: CheckBoxValue['id'][],
  values: CheckBoxValue[],
  onBoxesChecked: (checkedIds: CheckBoxValue['id'][]) => void,
  disabled: boolean,
  checkAllText?: string
}

const CheckBoxParent = styled.div`
  display: flex;
  margin-bottom: 5px;

  &:focus-within label::before {
    outline: auto;
  }
`;

const CheckBoxLabel = styled.label`
  margin-bottom: 0;
  display: flex;
  align-items: center;

  &::before {
    margin-right: 15px !important;
  }
`;
CheckBoxLabel.displayName = 'CheckBoxLabel';

const AllCheckBoxLabel = styled(CheckBoxLabel)`
  font-style: italic;
`;
AllCheckBoxLabel.displayName = 'AllCheckBoxLabel';

const CheckBoxInput = styled.input`
  align-self: center;
  ${({ disabled }) => disabled ? `
    &:hover {
        cursor: default;
      }
  ` : `
    &:hover {
        cursor: pointer;
      }
  `}
`;
CheckBoxInput.displayName = 'CheckBoxInput';

const StyledCheckBoxSelection = styled.div`
  position: relative;
`;

StyledCheckBoxSelection.displayName = 'StyledCheckBoxSelection';

export function CheckBoxSelection(props: Props) {
  const { values, onBoxesChecked, checkAllText, initialCheckedIds } = props;
  const [checkedIds, setCheckedIds] = useState(initialCheckedIds);

  const onCheckAll = () => {
    const checked = checkedIds.length === values.length ? [] : values.map(value => value.id);
    setCheckedIds(checked);
    onBoxesChecked(checked);
  };

  const onChange = (value: CheckBoxValue) => {
    if (checkedIds.includes(value.id)) {
      setCheckedIds(without(checkedIds, value.id));
      onBoxesChecked(without(checkedIds, value.id));
    } else {
      const allChecked = [...checkedIds];
      allChecked.push(value.id);
      setCheckedIds(allChecked);
      onBoxesChecked(allChecked);
    }
  };

  return (
    <StyledCheckBoxSelection>
      {checkAllText && <CheckBoxParent>
        <CheckBoxInput
          className="fancy-checkbox"
          type="checkbox"
          id={camelCase(checkAllText)}
          name={camelCase(checkAllText)}
          onChange={onCheckAll}
          checked={checkedIds.length === values.length}
          disabled={props.disabled}
        />
        <AllCheckBoxLabel htmlFor={camelCase(checkAllText)}>{checkAllText}</AllCheckBoxLabel>
      </CheckBoxParent>}
      {values.map((value) => {
        return <CheckBoxParent key={value.id}>
          <CheckBoxInput
            className="fancy-checkbox"
            type="checkbox"
            id={value.id}
            name={`checkbox--${camelCase(value.name)}`}
            onChange={() => onChange(value)}
            checked={checkedIds.includes(value.id)}
            disabled={props.disabled}
          />
          <CheckBoxLabel htmlFor={value.id}>{value.name}</CheckBoxLabel>
        </CheckBoxParent>;
      })}
    </StyledCheckBoxSelection>
  );
}
