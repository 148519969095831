import { find, flatMap, isNil, lowerCase, uniq } from 'lodash';

export const userRoles = ['viewer', 'editor', 'manager', 'administrator'] as const;
export type UserRole = typeof userRoles[number];

export interface UserAuth {
  id: string,
  email: string,
  role: UserRole,
  isAdministrator: boolean,
  isManager: boolean,
  isEditor: boolean,
  isViewer: boolean,
  group: string | null,
  zoneId: string | null,
  zone: {
    id: string,
    name: string
  } | null,
  homeLocationId: string | null,
  homeLocation: {
    id: string,
    name: string
  } | null
}

export function parseGroupsAttribute(value: string): string[] {
  if (!value) {
    return [];
  }

  let groups: string[] = [value];
  if (value.startsWith('[') && value.endsWith(']')) {
    groups = value.substring(1, value.length - 1).split(/\s*,\s*/);
  }

  return uniq(
    groups
      .map(group => lowerCase(group.trim()))
      .filter(group => group.length > 0)
  );
}

function checkGroups(groups: string[], names: string[]): boolean {
  const groupParts = flatMap(groups.map((group) => group.split(' ')));
  return !isNil(find(names, (name) => groupParts.includes(name)));
}

/** Deduce the role of a federated user from their custom groups attribute */
export function getUserRoleFromGroups(groups: string[]): UserRole {
  if (checkGroups(groups ?? [], ['administrator', 'administrators'])) {
    return 'administrator';
  } else if (checkGroups(groups ?? [], ['manager', 'managers'])) {
    return 'manager';
  } else if (checkGroups(groups ?? [], ['editor', 'editors'])) {
    return 'editor';
  }
  return 'viewer';
}
