import * as React from 'react';
import { useCurrentWorldContext } from '../context/currentWorld';

import { RequestInitWithRetry } from './request';

/**
 * Hook to make an sync fetch request on an action (e.g. button click) rather than for data fetching, injecting the world id
 *
 * usage e.g.
 *
 * const setSystemConfigAction = (options: RequestInitWithRetry) => async (data: ISystemConfig) => {
 *   const params = {
 *     path: '/api/configuration/setSystemConfiguration',
 *     method: 'POST',
 *     body: data
 *   };
 *   return request(params, options);
 * }
*
 * const setSystemConfig = useWorldAction(setSystemConfigAction);
 * const onClick = () => setSystemConfig(currentConfig)
 */
export const useWorldAction = <CB extends (...args: any[]) => any>(
  getCb: (options: RequestInitWithRetry) => CB
): CB => {
  const { id } = useCurrentWorldContext();
  if (!id) {
    throw new Error('World id not set');
  }

  const callback = React.useMemo(() => {
    const headers: HeadersInit = {};
    headers.world = id;
    return getCb({ headers });
  }, [getCb, id]);

  return callback;
};
