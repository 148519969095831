export const bytesToMegabytes = (bytes: number): number => {
  return bytes / (1024 * 1024);
};

export const bytesToGigabytes = (bytes: number): number => {
  return bytes / (1024 * 1024 * 1024);
};

export const formatBytesToGigabytesFixed = (bytes: number): string => {
  /* once converted to gb, the number is so small that roundTo2Places returns NaN
    so we return 0 rather than attempting to convert */
  return bytes < 2000 ? '0.00' : roundTo2PlacesFixed(bytesToGigabytes(bytes));
};

/** round number to n places avoiding floating point errors and incorrect rounding */
export const roundToPlaces = (number: number, places: number) => {
  if (places < 0) {
    throw new Error('places must be >= 0');
  }
  if (!Number.isInteger(places)) {
    throw new Error('places must be an integer');
  }
  return Number(`${Math.round(Number(`${number}e+${places}`))}e-${places}`);
};

export const roundTo2Places = (number: number) => roundToPlaces(number, 2);

export const roundTo3Places = (number: number) => roundToPlaces(number, 3);

/** round number 2 places fixed string, for display */
export const roundTo2PlacesFixed = (value: number): string => roundTo2Places(value).toFixed(2);

/** truncate number to n places */
export const truncateToPlaces = (val: string | number, places: number): string => {
  if (places < 1) {
    throw new Error('places must be > 0');
  }
  if (!Number.isInteger(places)) {
    throw new Error('places must be an integer');
  }
  const pattern = new RegExp(String.raw`^(\d*(\.\d{0,${places}})?)(\d*)?$`);
  return String(val).replace(pattern, '$1');
};

export const truncateTo2Places = (val: string | number) => truncateToPlaces(val, 2);

export const truncateTo3Places = (val: string | number) => truncateToPlaces(val, 3);
