
import { CheckBox } from '../../../../components/controls/checkbox';
import { Tooltip } from '../../../../components/tooltip/tooltip';
import { CustomValidatedInput } from '../../../../components/customValidatedInput/customValidatedInput';
import { getAlarmStatusField, WorldSettings } from '../../../../services/config/config';
import { AlarmName, AlarmStatusEnabledFieldNames, AlarmStatusThresholdFieldNames } from '../../../../services/config/alertConstants';

import '../settings.css';
import './alarmThresholds.css';
import { useTranslation } from '@lib/useTypedTranslation';
import { Trans } from '@components/i18n/typedTrans';

export interface IProps {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  onDataChanged: (data: WorldSettings) => void
}

export function AlarmThresholdSettings(props: IProps) {
  const { t } = useTranslation('alarmThresholds');
  const alarmTypes: { [name in AlarmName]: string } = {
    deviceStatusAlarm: t('DEVICE_STATUS_ALARM'),
    deviceLocationTimeStatusAlarm: t('DEVICE_LOCATION_TIME_STATUS_ALARM'),
    deviceTimeErrorStatusAlarm: t('DEVICE_TIME_ERROR_STATUS_ALARM'),
    deviceLocationDistanceStatusAlarm: t('DEVICE_LOCATION_DISTANCE_STATUS_ALARM'),
    averageDischargeStatusAlarm: t('AVERAGE_DISCHARGE_STATUS_ALARM'),
    deviceUserExperienceDropStatusAlarm: t('DEVICE_USER_EXP_DROP_STATUS_ALARM'),
    deviceUserExperienceLowPowerStatusAlarm: t('DEVICE_USER_EXP_LOW_POWER_STATUS_ALARM'),
    deviceUserExperienceRebootStatusAlarm: t('DEVICE_USER_EXP_REBOOT_STATUS_ALARM'),
    deviceUtilisationIdleStatusAlarm: t('DEVICE_IDLE_STATUS_ALARM'),
    deviceLocationAccessPointStatusAlarm: t('DEVICE_LOCATION_ACCESS_POINT_STATUS_ALARM'),
    deviceUtilisationOutOfContactStatusAlarm: t('DEVICE_OUT_OF_CONTACT_STATUS_ALARM')
  };

  const maxValue = 100;

  const header = <>{t('HEADER')}<Tooltip placement={'right'} popperContent={<Trans i18nKey='HEADER_TOOLTIP' ns='alarmThresholds'>Alarms are triggered when the percentage of alerting devices within a Group or Home Location equals or exceeds the configured threshold.
    <p>Current alarms can be viewed on the 'Overview' page within the 'Estate Alerts Breakdown' table.</p></Trans>}>
    <i className="alarms-configuration-icon fa fa-question-circle" /></Tooltip></>;

  const majorThresholdHeader = <>{t('MAJOR_ALARM_THRESHOLD')}<Tooltip placement={'bottom'} popperContent={t('MAJOR_ALARM_THRESHOLD_TOOLTIP')}>
    <i className="alarms-configuration-icon fa fa-question-circle" /></Tooltip></>;

  const criticalThresholdHeader = <>{t('CRITICAL_ALARM_THRESHOLD')}<Tooltip placement={'bottom'} popperContent={t('CRITICAL_ALARM_THRESHOLD_TOOLTIP')}>
    <i className="alarms-configuration-icon fa fa-question-circle" /></Tooltip></>;

  function onDataChanged(data: number, key: AlarmStatusThresholdFieldNames): void;
  function onDataChanged(data: boolean, key: AlarmStatusEnabledFieldNames): void;
  function onDataChanged(data: number | boolean, key: AlarmStatusEnabledFieldNames | AlarmStatusThresholdFieldNames): void {
    props.onDataChanged({ ...props.worldSettings, [key]: data });
  }

  function checkMajorThresholdValidity(value: number, redThresholdValue: number): boolean {
    return (value >= 1) && (value < redThresholdValue) && (value <= maxValue - 1);
  }

  function checkCriticalThresholdValidity(value: number, yellowThresholdValue: number): boolean {
    return (value > yellowThresholdValue) && (value <= maxValue);
  }

  return (
    <div className='alarms-configuration'>
      <div className="alarms-configuration-header">
        <h6>{header}</h6>
      </div>
      <div className="alarms-configuration-body">
        <table className="alarms-configuration_table">
          <tbody>
            <tr key='table-header' >
              <td className='alert-types_header'>{t('ALERT_TYPE')}</td>
              <td>{t('ALARM_ACTIVE')}</td>
              <td>{majorThresholdHeader}</td>
              <td>{criticalThresholdHeader}</td>
            </tr>
            {Object.keys(alarmTypes).map(alarmType => {
              const alarmName = alarmType as keyof typeof alarmTypes;
              const alarmRedFieldName = getAlarmStatusField(alarmName, 'Red');
              const alarmYellowFieldName = getAlarmStatusField(alarmName, 'Yellow');
              const alarmEnabledFieldName = getAlarmStatusField(alarmName, 'Enabled');
              const alarmRedThresholdValue = props.worldSettings[alarmRedFieldName];
              const alarmYellowThresholdValue = props.worldSettings[alarmYellowFieldName];
              return (
                <tr key={alarmType}>
                  <td className='alert-types_column'>{alarmTypes[alarmName]}</td>
                  <td>{
                    <CheckBox
                      className="fancy-checkbox"
                      name={`${alarmName}_checkbox`}
                      checked={props.worldSettings[alarmEnabledFieldName]}
                      onChange={(event) => onDataChanged(event.checked, alarmEnabledFieldName)} />}</td>
                  <td>{
                    <CustomValidatedInput
                      disabled={props.submitInProgress}
                      value={alarmYellowThresholdValue}
                      validationRule={(value: number) => checkMajorThresholdValidity(value, alarmRedThresholdValue)}
                      errorText={t('MAJOR_THRESHOLD_VALIDATION_ERROR', { max: maxValue - 1 })}
                      onChange={(majorThreshold: number) => onDataChanged(majorThreshold, alarmYellowFieldName)}
                    />
                  } <span>%</span></td>
                  <td>{
                    <CustomValidatedInput
                      disabled={props.submitInProgress}
                      value={alarmRedThresholdValue}
                      validationRule={(value: number) => checkCriticalThresholdValidity(value, alarmYellowThresholdValue)}
                      errorText={t('CRITICAL_THRESHOLD_VALIDATION_ERROR', { max: maxValue })}
                      onChange={(criticalThreshold: number) => onDataChanged(criticalThreshold, alarmRedFieldName)}
                    />} <span>%</span></td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
