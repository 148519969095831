import React from "react";
import './miniHeader.css';

export interface IProps {
  icon: string,
  title: string
}

export function MiniHeader(props: IProps) {
  return <div className="row mini-header">
    <div className="col">
      <i className={props.icon} />
      {props.title}
    </div>
  </div>;
}
