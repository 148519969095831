import * as React from "react";
import Spinner from '../loading/loadingSpinner';
import classNames from "classnames";
import './style.css';
import { get } from 'lodash';

export class DetailsProplist extends React.Component<any, any> {

  public render() {
    const classes = classNames({
      'batteryDetails': true,
      [`_details-${this.props.details ? this.props.details.serialNumber : "_unknown_"}`]: true
    });

    let capacityFactor = null;

    if (this.props.details) {
      capacityFactor = (typeof this.props.details.capacityFactor === 'number') ?
        [(this.props.details.capacityFactor * 100), '%'].join('') : this.props.details.capacityFactor;
    }

    const showUnderWarranty = !this.props.isWarranty && get(this.props, 'details.underWarranty');

    return (
      <div className="batteryDetails_container">
        <h1>Details</h1>
        <div className={classes}>
          {this.props.loading ? <div className="row justify-content-center" style={{ marginTop: '50%' }}><Spinner/></div> :
            <div>
              {this.props.batteryButtons}
              <table><tbody>
                <tr><td className="left">Serial Number:</td><td className="right">{get(this.props, 'details.serialNumber')}</td></tr>
                <tr><td className="left">Average Discharge:</td><td className="right">{get(this.props, 'details.averageDischarge')}</td></tr>
                <tr><td className="left">Battery Part No:</td><td className="right">{get(this.props, 'details.batteryPartNo')}</td></tr>
                <tr><td className="left">Battery Serial No:</td><td className="right">{get(this.props, 'details.batterySerialNo')}</td></tr>
                <tr><td className="left">Manufacture Date:</td><td className="right">{get(this.props, 'details.mfd')}</td></tr>
                <tr><td className="left">Battery Health:</td><td className="right">{capacityFactor}</td></tr>
                <tr hidden={this.props.isWarranty}><td className="left">Cycle Count:</td><td className="right">{get(this.props, 'details.cycleCount')}</td></tr>
              </tbody></table>
              <div className="warrantyStatus" hidden={!showUnderWarranty}>This battery is under warranty</div>
            </div>}
        </div>
      </div>
    );
  }
}
