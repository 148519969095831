import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { WorldSettings, Identifier } from '../../../../services/config/config';

const Block = styled.div`
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
`;

const Title = styled.div`
  background: #f3f7fb;
  border-bottom: 1px solid #d7e2eb;
  padding: 0.3rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 600;
`;

const Body = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-left: 30px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-start;
  margin-left: 30px;
  width: 90%;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const LabelFormat = styled.label`
  display: flex;
  align-self: center;
  align-items: center;
  margin-top: 0.5rem;
  margin-left: 5px;
  gap: 2px;
  cursor: pointer;
`;

const Element = styled.div`
  display: flex;
  gap: 5px;
  margin-right: 25px;
  margin-bottom: 20px;
`;

const InputButton = styled.input`
  cursor: pointer;
  display: flex;
  align-self: center;
  align-items: center;
  margin-right: 3px;
`;

export interface IProps {
  worldSettings: WorldSettings,
  onDataChanged: (data: WorldSettings) => void
}

export function PlatformSettings(props: IProps) {
  const ns = 'translation';
  const ns1 = 'settings';
  const { t } = useTranslation([ns, ns1]);
  const { worldSettings } = props;

  const onOptionChange = (value: Identifier) => {
    props.onDataChanged({ ...worldSettings, deviceIdentifier: value });
  };

  return (
    <>
      <Block className="primary-identifier-configuration">
        <Title className="primary-identifier-configuration_header">{t('PLATFORM_DEVICE_IDENTIFIER_TITLE', { ns: ns1 })}</Title>
        <Row key ='PID'>{t('PLATFORM_DEVICE_IDENTIFIER_INFORMATION', { ns: ns1 })}</Row>
        <Body className="primary-identifier-configuration_body">
          <Element className='radiobutton-and-label'>
            <LabelFormat><InputButton
              type="radio"
              data-id="primaryIdentifiers-serialNumber"
              name="primaryIdentifiers-serialNumber"
              value={Identifier.serialNumber}
              id="radiobutton-serialNumber"
              checked={worldSettings.deviceIdentifier === Identifier.serialNumber}
              onChange={() => onOptionChange(Identifier.serialNumber)}
            />{t('SERIAL_NUMBER', { ns })}</LabelFormat>
          </Element>
          <Element className='radiobutton-and-label'>
            <LabelFormat><InputButton
              type="radio"
              data-id="primaryIdentifiers-assetTag"
              name="primaryIdentifiers-assetTag"
              value={Identifier.assetTag}
              id="radiobutton-assetTag"
              checked={worldSettings.deviceIdentifier === Identifier.assetTag}
              onChange={() => onOptionChange(Identifier.assetTag)}
            />{t('ASSET_TAG', { ns })}</LabelFormat>
          </Element>
          <Element className='radiobutton-and-label'>
            <LabelFormat><InputButton
              type="radio"
              data-id="primaryIdentifiers-imei"
              name="primaryIdentifiers-imei"
              value={Identifier.IMEI}
              id="radiobutton-imei"
              checked={worldSettings.deviceIdentifier === Identifier.IMEI}
              onChange={() => onOptionChange(Identifier.IMEI)}
            />{t('IMEI', { ns })}</LabelFormat>
          </Element>
        </Body>
      </Block>
    </>
  );
}
