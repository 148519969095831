import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { useState } from 'react';
import moment from 'moment';

import ThresholdSettings from '../thresholdSettings';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export interface IProps {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  timeStamp: number,
  onDataChanged: (data: WorldSettings) => void
}

const ns = 'settings' as const;

export function TimeSettings(props: IProps) {
  const { t } = useTranslation(ns);
  const [alertTimeStamp, setAlertTimeStamp] = useState<number>(props.timeStamp);

  if (props.timeStamp > alertTimeStamp) {
    setAlertTimeStamp(props.timeStamp);
  }

  function onDataChanged(data: number, field?: string) {
    if (field === 'warning') {
      props.onDataChanged({ ...props.worldSettings, deviceLocationTimeYellow: data });
    } else if (field === 'problem') {
      props.onDataChanged({ ...props.worldSettings, deviceLocationTimeRed: data });
    }
  }

  function onAlertToggled(data: boolean, initialSettings: { warning: number, problem: number }) {
    setAlertTimeStamp(moment.utc().valueOf());
    props.onDataChanged({
      ...props.worldSettings,
      deviceLocationTimeEnabled: data,
      deviceLocationTimeYellow: initialSettings.warning,
      deviceLocationTimeRed: initialSettings.problem
    });
  }

  return (
    <ThresholdSettings
      key={alertTimeStamp}
      title={t('TIME_TITLE', { ns })}
      message={t('TIME_MESSAGE', { ns })}
      popoverText={t('TIME_POPOVER', { ns })}
      unit={{ key: 'TIME_UNIT' }}
      maxValue={336}
      className="time"
      warning={props.worldSettings.deviceLocationTimeYellow}
      problem={props.worldSettings.deviceLocationTimeRed}
      dataUpdated={onDataChanged}
      disabled={props.submitInProgress}
      alertEnabled={props.worldSettings.deviceLocationTimeEnabled}
      alertToggled={onAlertToggled}
    />
  );
}
