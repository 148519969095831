
import { findIndex, cloneDeep } from 'lodash';
import { ITableColumn } from "../components/data-table/types";

export function rearrangeList(columns : ITableColumn[], deviceIdentifier : string): any {
  const list = cloneDeep(columns);
  let deviceIdentifierIndex = findIndex(list, (x) => x.id === deviceIdentifier);

  if (deviceIdentifierIndex !== -1 && deviceIdentifierIndex !== 0) {
    list.splice(0, 0, list.splice(deviceIdentifierIndex, 1)[0]);
  }
  return list;
}
