import { get } from 'lodash';
import { request, RequestInitWithRetry } from '../../../lib/request';
import { ISortResult } from '../../../services/shared/common';

export interface IDeviceWithBattery {
  imei: string,
  serialNumber: string,
  group?: string[],
  model: string,
  manufacturer: string,
  averageDischarge: number,
  smartBatteries?: any[]
}

export enum BatteryProductName {
  batteryEssentials = 'batteryEssentials',
  batteryWarranty = 'batteryWarranty'
}

export interface IDeviceParams {
  statusType: any,
  limit: number,
  offset: number,
  search: string,
  sort?: ISortResult,
  filters: IDeviceFilters
}

export interface IDeviceFilters {
  underWarranty: boolean,
  groups?: string[]
}

export interface IRequestParameters {
  ids: string[]
}

export interface IDeleteResponse {
  [id: string]: {
    success: boolean
  }
}

export const deleteSmartBatteries = (options: RequestInitWithRetry) => async (params: IRequestParameters): Promise<IDeleteResponse> => {
  const input = {
    path: '/api/batteryEssentials/deleteSmartBatteries',
    method: 'POST',
    body: params
  };
  return request(input, options);
};

export class List {
  private path: BatteryProductName;

  constructor(path: BatteryProductName) {
    this.path = path;
    this.get = this.get.bind(this);
    this.export = this.export.bind(this);
    this.getBatteryEstateData = this.getBatteryEstateData.bind(this);
    this.getDeviceBatteryDischarge = this.getDeviceBatteryDischarge.bind(this);
  }

  public get = (options: RequestInitWithRetry) => async (params: IDeviceParams) => {
    let result;
    const input = {
      path: `/api/${this.path}/getDevicesWithSmartBatteryStatus`,
      qs: { statusType: params.statusType, limit: params.limit, offset: params.offset },
      body: {
        groups: get(params, 'filters.groups'),
        sort: get(params, 'sort'),
        search: get(params, 'search'),
        underWarranty: get(params, 'filters.underWarranty')
      },
      method: 'post'
    };

    result = await request(input, options);

    result.devices.nodes.map((i: any) => {
      i.status = params.statusType;
      i.subRows = i.smartBatteries ? i.smartBatteries.length : 1;
      return i;
    });

    return result.devices.nodes;
  };

  public export = (options: RequestInitWithRetry) => async (params: IDeviceParams) => {
    const input = {
      path: `/api/${this.path}/exportDevicesWithSmartBatteryStatus`,
      qs: { statusType: params.statusType, limit: params.limit, offset: params.offset },
      body: {
        groups: get(params, 'filters.groups'),
        search: get(params, 'search'),
        underWarranty: get(params, 'filters.underWarranty')
      },
      method: 'post'
    };

    return await request(input, options);
  };

  public getBatteryEstateData = (options: RequestInitWithRetry) => async (filters: IDeviceFilters, search?: string) => {
    const input = {
      path: `/api/${this.path}/getDevicesWithSmartBatteryCount`,
      body: {
        groups: get(filters, 'groups'),
        search: search || undefined,
        underWarranty: get(filters, 'underWarranty')
      },
      method: 'post'
    };

    return await request(input, options);
  };

  public getDeviceBatteryDischarge = (options: RequestInitWithRetry) => async (params: { deviceId: any }) => { //JMTODO: change any to string
    const input = {
      path: `/api/${this.path}/getDeviceWithBatteryDischarge`,
      qs: { deviceId: params.deviceId },
      method: 'get'
    };
    return await request(input, options);
  };
}
