import React, { PropsWithChildren } from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { Theme } from "../../containers/app/themes";

export function applyHeaderStyles(theme: Theme) {
  return `
  background-color: ${theme.headers.background};
  color: ${theme.headers.text};
`;
}

export function applySmallButtonStyles() {
  return `
    padding: 0.375rem 1rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: 0;
  `;
}

export const StyledDropdown = styled(Dropdown)`
  vertical-align: top;

  display: inline-block;

  cursor: pointer;

  max-width: 455px;

  margin-right: 0.375rem;
  &:last-child {
    margin-right: 30px;
  }

  @media (max-width: 1800px) {
    max-width: 405px;
  };
  @media (max-width: 1650px) {
    max-width: 275px;
  };
  @media (max-width: 1300px) {
    max-width: 210px;
  };
`;

StyledDropdown.displayName = 'Dropdown';

export const StyledDropdownToggle = styled(Dropdown.Toggle)`
  margin: 0;
  outline: none;
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0 15px;

  cursor: pointer;

  max-width: 423px;
  height: 50px;
  overflow: hidden;

  display: flex;
  align-items: center;

  ${({ theme }: { theme: Theme }) => applyHeaderStyles(theme)}

  &.btn.btn-primary:not(:disabled):not(.disabled).active, 
  &.btn.btn-primary:not(:disabled):not(.disabled):active,
  .show>&.btn.btn-primary:focus {
    box-shadow: none;
    outline: none;
  }

  &.btn:hover:not(:disabled),
  .show>&.btn.btn-primary,
  &.btn.btn-primary:focus {
    ${({ theme }) => `
      background-color: ${theme.headers.contrastBackground};
      color: ${theme.headers.text};
    `}
  };

  &:after {
    border-top: 0.27em solid;
    border-right: 0.27em solid transparent;
    border-left: 0.27em solid transparent;
  }

  @media (max-width: 1800px) {
    max-width: 373px;
  };
  @media (max-width: 1650px) {
    max-width: 238px;
  };
  @media (max-width: 1300px) {
    max-width: 173px;
    padding-left: 10px;
    padding-right: 10px;
  };
`;

StyledDropdownToggle.displayName = 'DropdownToggle';

export const StyledDropdownMenu = styled(Dropdown.Menu)`
  min-width: 205px;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 4px 0 hsla(0, 0%, 84%, 0.6);
  text-align: left;

  left: 0;
  right: auto;

  &.dropdown-menu-right {
    right: 0;
    left: auto;
  }
  
  &:hover {
    border: none;
  };
  &:active {
    border: none;
  }
  & * {
    text-decoration: none;
  }

  ${({ theme } : { theme: Theme }) => `
    background-color: ${theme.headers.dropdown.default};
    color: ${theme.headers.text};
  `}
`;

StyledDropdownMenu.displayName = 'DropdownMenu';

export const DropdownItem = styled(Dropdown.Item)`
  margin-top: -2px;

  width: 100%;

  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  text-wrap: nowrap;

  font-weight: 400;

  ${applySmallButtonStyles()};

  &, &:focus {
  ${({ theme } : { theme: Theme }) => `
    background-color: ${theme.headers.dropdown.default};
    color: ${theme.headers.text};
  `}
  }

  &:hover {
    ${({ theme } : { theme: Theme }) => `
      background-color: ${theme.headers.dropdown.hover};
      color: ${theme.headers.text};
    `}
  }
`;

DropdownItem.displayName = 'DropdownItem';

interface IProps {
  buttonContent: React.ReactNode,
  disabled?: boolean,
  right?: boolean,
  ['data-id']?: string,
  onButtonClick?: () => void
}

export const DropdownMenu: React.FC<PropsWithChildren<IProps>> = ({ buttonContent, children, disabled, right, "data-id": dataId, onButtonClick }) => {
  return <StyledDropdown data-id={dataId} onClick={onButtonClick}>
    <StyledDropdownToggle
      type="button"
      data-toggle="dropdown"
      disabled={disabled}
    >
      {buttonContent}
    </StyledDropdownToggle>
    <StyledDropdownMenu renderOnMount className={right ? ' dropdown-menu-right' : ''} data-id='dropdown-menu'>
      {children}
    </StyledDropdownMenu>
  </StyledDropdown>;
};
