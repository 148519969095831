import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { Link } from 'react-router-dom';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

import Card from '../../../../components/card/card';
import { AmChart } from '../../../../components/chart/amChart';
import { IDeviceCount } from '../../../../services/core/overview';
import { coreTheme } from '../../../../components/chart/amChart';
import { NoSelectionOverlay } from '../../../../components/card/noSelectionOverlay';
import { addHoverAnimation } from '../../../../components/chart/onHoverAnimation';
import { useLanguageAndLocaleContext } from '../../../../context/languageAndLocale';
import { roundTo2PlacesFixed } from '../../../../lib/dataConversion';

import './estate.css';
import { AxisItemLocation } from '@amcharts/amcharts4/.internal/charts/axes/Axis';

interface IProps {
  linkToDevices: string,
  data: IDeviceCount,
  message: JSX.Element
}

export function getLinkToDevicesWithGivenStatus(path: string, status: string) {
  return `#${path}?status=${status}`;
}

const ns1 = 'translation' as const;
const ns2 = 'estateOverview' as const;

export function Estate(props: IProps) {
  const { t } = useTranslation([ns1, ns2]);
  const { linkToDevices } = props;
  const { locale } = useLanguageAndLocaleContext();

  return (
    <div className="horizontal-estate">
      <Card>
        <div className="details-message">
          {props.message}
        </div>
        <div className="chart-and-legend-container">
          <NoSelectionOverlay noSelectionText={t(`NO_DATA_AVAILABLE`, { ns: ns1 })} show={!props.data} />
          <ChartLegend />
          <div className="chart-container">
            <AmChart tag='estate-overview-chart'
              chartProvider={createChart}
              dataProvider={getChartData}
              link={props.data}
              cypressId="coreEstate"
            />
          </div>
        </div>
      </Card>
    </div>
  );

  /* istanbul ignore next */
  function createChart(this: any, id: string) {
    am4core.useTheme(coreTheme);

    const chart = am4core.create(id, am4charts.XYChart);

    const yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    yAxis.dataFields.category = "title";
    yAxis.renderer.labels.template.disabled = true;

    const xAxis = chart.xAxes.push(new am4charts.ValueAxis());
    xAxis.renderer.labels.template.disabled = true;
    xAxis.max = 100;
    xAxis.min = 0;
    xAxis.renderer.baseGrid.disabled = true;
    xAxis.renderer.grid.template.location = 100 as AxisItemLocation;
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.labels.template.disabled = true;

    yAxis.renderer.baseGrid.disabled = false;
    yAxis.renderer.baseGrid.dy = 79;
    yAxis.renderer.baseGrid.strokeWidth = 2;
    yAxis.renderer.baseGrid.stroke = am4core.color("#C2D1E0");
    yAxis.renderer.grid.template.location = 100 as AxisItemLocation;

    const series1 = chart.series.push(new am4charts.ColumnSeries());
    const series2 = chart.series.push(new am4charts.ColumnSeries());
    const series3 = chart.series.push(new am4charts.ColumnSeries());

    function createSeries(series: am4charts.ColumnSeries, key: string, name: string, colour: string, status: string) {
      series.name = name;
      series.dataFields.valueX = key;
      series.dataFields.categoryY = "title";
      series.sequencedInterpolation = true;
      series.stacked = true;
      series.columns.template.height = am4core.percent(60);
      series.columns.template.stroke = am4core.color('#FFFFFF');
      series.columns.template.fill = am4core.color(colour);
      series.columns.template.tooltipHTML = ``;
      series.columns.template.url = getLinkToDevicesWithGivenStatus(linkToDevices, status);
      series.tooltip.pointerOrientation = 'down';
      addHoverAnimation(series, chart);
    }

    createSeries(series1, 'problem', 'Problem', '#DD7778', 'red');
    createSeries(series2, 'warning', 'Warning', '#FBCD76', 'yellow');
    createSeries(series3, 'good', 'Good', '#3FA67D', 'green');

    series1.columns.template.adapter.add('tooltipHTML', (_, target) => {
      const data: any = target.tooltipDataItem.dataContext;
      return `
        <span class="estate-overview-tooltip-text estate-overview-tooltip-text--problem">
          <div class="key-problem"></div>
          <span class="label">${t('PROBLEM_DEVICES', { ns: ns2 })}: </span>
          <span class="counts">{valueX}% (${data.totalProblem.toLocaleString(locale)} ${t('DEVICE', { count: data.totalProblem, ns: 'translation' })})</span>
        </span>
      `;
    });

    series2.columns.template.adapter.add('tooltipHTML', (_, target) => {
      const data: any = target.tooltipDataItem.dataContext;
      return `
        <span class="estate-overview-tooltip-text estate-overview-tooltip-text--warning">
          <div class="key-warning"></div>
          <span class="label">${t('WARNING_DEVICES', { ns: ns2 })}: </span>
          <span class="counts">{valueX}% (${data.totalWarning.toLocaleString(locale)} ${t('DEVICE', { count: data.totalWarning, ns: 'translation' })})</span>
        </span>
      `;
    });

    series3.columns.template.adapter.add('tooltipHTML', (_, target) => {
      const data: any = target.tooltipDataItem.dataContext;
      return `
        <span class="estate-overview-tooltip-text estate-overview-tooltip-text--good">
          <div class="key-good"></div>
          <span class="label">${t('GOOD_DEVICES', { ns: ns2 })}: </span>
          <span class="counts">{valueX}% (${data.totalGood.toLocaleString(locale)} ${t('DEVICE', { count: data.totalGood, ns: 'translation' })})</span>
        </span>
      `;
    });

    series1.columns.template.column.cornerRadiusTopLeft = 3;
    series1.columns.template.column.cornerRadiusBottomLeft = 3;
    series1.columns.template.column.adapter.add('cornerRadiusTopRight', (radius, target) => {
      return target.dataItem && (target.dataItem.dataContext as any).good === 0 && (target.dataItem.dataContext as any).warning === 0 ? 3 : radius;
    });
    series1.columns.template.column.adapter.add('cornerRadiusBottomRight', (radius, target) => {
      return target.dataItem && (target.dataItem.dataContext as any).good === 0 && (target.dataItem.dataContext as any).warning === 0 ? 3 : radius;
    });

    series2.columns.template.column.adapter.add('cornerRadiusTopRight', (radius, target) => {
      return target.dataItem && (target.dataItem.dataContext as any).good === 0 ? 3 : radius;
    });
    series2.columns.template.column.adapter.add('cornerRadiusBottomRight', (radius, target) => {
      return target.dataItem && (target.dataItem.dataContext as any).good === 0 ? 3 : radius;
    });
    series2.columns.template.column.adapter.add('cornerRadiusBottomLeft', (radius, target) => {
      return target.dataItem && (target.dataItem.dataContext as any).problem === 0 ? 3 : radius;
    });
    series2.columns.template.column.adapter.add('cornerRadiusTopLeft', (radius, target) => {
      return target.dataItem && (target.dataItem.dataContext as any).problem === 0 ? 3 : radius;
    });

    series3.columns.template.column.cornerRadiusTopRight = 3;
    series3.columns.template.column.cornerRadiusBottomRight = 3;
    series3.columns.template.column.adapter.add('cornerRadiusTopLeft', (radius, target) => {
      return target.dataItem && (target.dataItem.dataContext as any).warning === 0 && (target.dataItem.dataContext as any).problem === 0 ? 3 : radius;
    });
    series3.columns.template.column.adapter.add('cornerRadiusBottomLeft', (radius, target) => {
      return target.dataItem && (target.dataItem.dataContext as any).warning === 0 && (target.dataItem.dataContext as any).problem === 0 ? 3 : radius;
    });

    function createRange(value: number) {
      const range = xAxis.axisRanges.create();
      range.value = value;
      range.label.text = "{value}%";
      range.grid.disabled = true;
      range.tick.dy = -10;
      range.tick.stroke = am4core.color("#C2D1E0");
      range.tick.disabled = false;
      range.tick.strokeOpacity = 1;
      range.tick.strokeWidth = 2;
      range.tick.length = 10;
    }

    createRange(0);
    createRange(25);
    createRange(50);
    createRange(75);
    createRange(100);

    return chart;
  }

  function getChartData() {
    if (props.data) {
      const { red, green, yellow } = props.data;
      const result = [{
        title: 'estate',
        problem: calculatePercentage(red),
        warning: calculatePercentage(yellow),
        good: calculatePercentage(green),
        totalProblem: red,
        totalWarning: yellow,
        totalGood: green
      }];
      return result;
    }
  }

  function calculatePercentage(value: number) {
    return roundTo2PlacesFixed((value / props.data.all) * 100);
  }

  function displayCount(count: number, status: string) {
    const total = count === 0 ? 0 : <Link to={`${linkToDevices}?status=${status}`}>{count.toLocaleString(locale)}</Link>;
    return <span className="total">{total}</span>;
  }

  function ChartLegend() {
    const problemIcon = <i className="fa fa-times-circle circle_red" />;
    const warningIcon = <i className="fa fa-exclamation-triangle circle_orange enhanced-warning-icon" />;
    const goodIcon = <i className="fa fa-check circle_green" />;
    if (!props.data) {
      return null;
    }
    return (
      <div className="estate-overview-chart-legend">
        <span className="problem">
          {problemIcon} {t('PROBLEM', { ns: 'translation' })}:
          {displayCount(props.data.red, "red")}
        </span>
        <span className="warning">
          {warningIcon} {t('WARNING', { ns: 'translation' })}:
          {displayCount(props.data.yellow, "yellow")}
        </span>
        <span className="good">
          {goodIcon} {t('GOOD', { ns: 'translation' })}:
          {displayCount(props.data.green, "green")}
        </span>
      </div>
    );
  }
}
