import React from 'react';

import './percentageBar.css';

interface Props {
  percentage: number,
  threshold: number,
  colourBelowThreshold: string,
  colourAboveThreshold: string,
  className?: string
}

export function PercentageBar(props: Props) {
  return (
    <div className={`${props.className || ''} percentage-bar`}>
      <div
        className="percentage-bar-filler"
        style={{
          width: `${props.percentage}%`,
          background: props.percentage <= props.threshold ? props.colourBelowThreshold : props.colourAboveThreshold
        }} />
    </div>
  );
}
