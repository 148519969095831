import React, { useMemo } from 'react';
import { AgreementsProvider } from '../../context/agreements';
import { getAgreementsToAccept } from '../../context/agreementsLib';
import { AgreementsIds } from '../../services/agreements/agreements';

interface Props {
  agreements: AgreementsIds,
  children: React.ReactNode
}
export function AgreementsWrapper(props: Props) {
  const { children, agreements } = props;
  const value = useMemo(() => {
    return {
      ...agreements,
      agreementsToAccept: getAgreementsToAccept(agreements)
    };
  }, [agreements]);
  return (
    <AgreementsProvider value={value}>
      {children}
    </AgreementsProvider>
  );
}
