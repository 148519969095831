import * as React from 'react';
import { HashRouter as Router, Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import { DevicesListPage } from './devices-route';
import { CoreDeviceOverviewPage } from './core-device-route';
import { CoreOverview } from '../containers/core/overview/coreOverview';
import { SelectorBarPage } from './parts/selectorBarPage';
import { StateSwitch } from './parts/stateSwitch';
import { Access } from './parts/access';
import { CorePerformance } from '../containers/core/performance';
import { useWorldRoutes } from './parts/allWorldRoutes';
import { useProductsContext } from '../context/products';
import { Product } from '../containers/app/productsWrapper';
import { ProductProvider } from '../context/product';
import { CoreApplications } from '../containers/core/applications/coreApplications';
import { CoreAnomalies } from '../containers/core/anomalies/coreAnomalies';
import { useCurrentUserContext } from '../context/currentUser';
import { CoreApplicationRoute } from './core-application-route';
import { CoreAnomalyRoute } from './core-anomaly-route';
import { useCurrentWorldContext } from '../context/currentWorld';
import { CoreAnomalyDevicesRoute } from './core-anomaly-devices-route';
import { CoreProcessRoute } from './core-process-route';
import { CoreProcesses } from '../containers/core/processes/coreProcesses';
import { CoreMapsRoute } from './core-maps-route';

const selectorBarTitles = {
  overview: 'OVERVIEW',
  devices: 'DEVICES',
  performance: 'PERFORMANCE',
  applications: 'APPLICATIONS',
  anomalies: 'ANOMALIES',
  maps: 'MAPS',
  processes: 'PROCESSES'
};

interface CoreRoutesProps {
  product: Product
}

export const CoreRoutes = (props: CoreRoutesProps) => {
  const { hasAndroidDevices, hasWindowsDevices } = useCurrentWorldContext();
  const { isAdministrator, isManager } = useCurrentUserContext();
  const { product } = props;
  const basePath = useRouteMatch().path;
  const products = useProductsContext();
  const title = products[product].title;
  const routeProps = { product, title };

  return (
    <ProductProvider product={product}>
      <Router>
        <Switch>
          <CoreRoute {...routeProps} path={`${basePath}/devices/:tab`} index={1} selectorBar={selectorBarTitles.devices} />
          <CoreRoute {...routeProps} path={`${basePath}/devices`} index={2} selectorBar={selectorBarTitles.devices} />
          <CoreRoute {...routeProps} path={`${basePath}/performance`} index={3} selectorBar={selectorBarTitles.performance} />
          {hasAndroidDevices && <CoreRoute {...routeProps} path={`${basePath}/applications/:tab`} index={4} selectorBar={selectorBarTitles.applications} /> }
          {hasAndroidDevices && <CoreRoute {...routeProps} path={`${basePath}/applications`} index={5} selectorBar={selectorBarTitles.applications} /> }
          {hasWindowsDevices && <CoreRoute {...routeProps} path={`${basePath}/processes/:tab`} index={6} selectorBar={selectorBarTitles.processes} /> }
          {hasWindowsDevices && <CoreRoute {...routeProps} path={`${basePath}/processes`} index={7} selectorBar={selectorBarTitles.processes} />}
          {(isAdministrator || isManager) && <CoreRoute {...routeProps} path={`${basePath}/anomalies/details/:tab`} index={8} selectorBar={selectorBarTitles.anomalies} />}
          {(isAdministrator || isManager) && <CoreRoute {...routeProps} path={`${basePath}/anomalies/:tab`} index={9} selectorBar={selectorBarTitles.anomalies} />}
          {(isAdministrator || isManager) && <CoreRoute {...routeProps} path={`${basePath}/anomalies`} index={10} selectorBar={selectorBarTitles.anomalies} />}
          <Redirect exact path={`${basePath}/maps`} to={`${basePath}/maps/no-service`} />
          <CoreRoute {...routeProps} path={`${basePath}/maps/:tab`} index={11} selectorBar={selectorBarTitles.maps} />
          <CoreRoute {...routeProps} path={`${basePath}`} index={0} selectorBar={selectorBarTitles.overview} />
        </Switch>
      </Router>
    </ProductProvider>
  );
};

interface CoreRouteProps extends CoreRoutesProps {
  path: string,
  index: number,
  selectorBar: string,
  title: string
}

const CoreRoute = (props: CoreRouteProps) => {
  const { path, index, selectorBar, title, product } = props;
  const { isAdministrator, isManager } = useCurrentUserContext();
  const { hasAndroidDevices, hasWindowsDevices } = useCurrentWorldContext();
  const routes = useWorldRoutes();

  const getTabs = () => {
    const tabs = [
      {
        id: 'overviewSelectorBarBtn',
        link: routes.core.overview,
        title: selectorBarTitles.overview
      },
      {
        id: 'devicesSelectorBarBtn',
        link: routes.core.devicesList,
        title: selectorBarTitles.devices
      },
      {
        id: 'performanceSelectorBarBtn',
        link: routes.core.performance,
        title: selectorBarTitles.performance
      }
    ];

    if (hasAndroidDevices) {
      tabs.push({
        id: 'applicationsSelectorBarBtn',
        link: routes.core.applicationsList,
        title: selectorBarTitles.applications
      });
    }

    if (hasWindowsDevices) {
      tabs.push({
        id: 'processesSelectorBarBtn',
        link: routes.core.processesList,
        title: selectorBarTitles.processes
      });
    }

    if ((isAdministrator || isManager)) {
      tabs.push({
        id: 'anomaliesSelectorBarBtn',
        link: routes.core.anomalies,
        title: selectorBarTitles.anomalies
      });
    }

    tabs.push({
      id: 'mapsSelectorBarBtn',
      link: routes.core.maps,
      title: selectorBarTitles.maps
    });

    return tabs;
  };

  const config = {
    selectorBarEnabled: true,
    selectorBarTabs: getTabs(),
    selectorBarValue: selectorBar
  };

  return (
    <Route path={path}>
      <Access product={product} title={title}>
        <SelectorBarPage title={title} config={config}>
          <StateSwitch selectedIndex={index}>
            <CoreOverview />
            <CoreDeviceOverviewPage />
            <DevicesListPage />
            <CorePerformance />
            {hasAndroidDevices && <CoreApplicationRoute />}
            {hasAndroidDevices && <CoreApplications />}
            {hasWindowsDevices && <CoreProcessRoute />}
            {hasWindowsDevices && <CoreProcesses />}
            {(isAdministrator || isManager) && <CoreAnomalyDevicesRoute />}
            {(isAdministrator || isManager) && <CoreAnomalyRoute />}
            {(isAdministrator || isManager) && <CoreAnomalies />}
            <CoreMapsRoute />
          </StateSwitch>
        </SelectorBarPage>
      </Access>
    </Route>
  );
};
