import * as React from 'react';

const PasswordPolicy = () => (
  <div className="password-policy">
    Password must be at least 8 characters long, contain no spaces,
    and contain at least one symbol, number, and upper and lower case letter.
  </div>
);

export default PasswordPolicy;
