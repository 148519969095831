import React from 'react';
import classNames from 'classnames';
import './chip.css';

export interface ChipProps {
  children?: React.ReactNode,
  count?: number,
  icon?: JSX.Element,
  active?: boolean,
  dropdownOpen?: boolean,
  className?: string
}

export const Chip = (props: ChipProps) => {
  const { count, icon, active, dropdownOpen, className } = props;

  const chipClass = classNames(
    'chip',
    className,
    {
      'chip-active': active,
      'chip-dropdown': dropdownOpen !== undefined,
      'chip-dropdown-open': dropdownOpen,
    }
  );

  return (
    <div className={chipClass} data-id="chip-container">
      <div className="chip__text">
        {props.children}
        {(count || icon) && (
          <div className="chip__additional-info">
            {count && <div className="chip__count-divider" />}
            {icon}
            {count && <div className="chip__count-value">{count}</div>}
          </div>
        )}
      </div>
    </div>
  );
};
