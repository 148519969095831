import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { TTypedTFunction } from '@lib/useTypedTranslation';
import { map } from 'lodash';

import LandingItem from '../../components/landing/item';
import { Brand } from '../../lib/brands';

import batteryEssentialsIcon from './assets/landing-battery-essentials.png';
import batteryWarrantyIcon from './assets/landing-battery-warranty.png';
import elemezCoreV2Icon from './assets/landing-elemez-coreV2.png';
import { AllWorldRoutes, useWorldRoutes } from '../../routes/parts/allWorldRoutes';
import { ContextProductData, ContextProductsData, useProductsContext } from '../../context/products';
import { useBrandContext } from '../../context/brand';
import { Product } from '../app/productsWrapper';

import './style.css';

export type GetTranslatedString = (t: TTypedTFunction) => string;

/** Additional product data, specific to the landing page */
export interface LandingProductData {
  img: string,
  subtitle: GetTranslatedString,
  link: string,
  alwaysVisible: boolean
}

export interface HydratedLandingProductData extends Omit<LandingProductData, 'subtitle'> {
  subtitle: string,
  link: string
}

export type LandingProductsData = {
  [p in Product]: LandingProductData | null
};

export type BrandedLandingProducts = {
  [b in Brand]: LandingProductsData
};

const getPanasonicProducts = (worldRoutes: AllWorldRoutes): LandingProductsData => {
  return {
    batteryWarranty: {
      img: batteryWarrantyIcon,
      subtitle: (t: TTypedTFunction) => t('PANASONIC_BATTERY_SUBTITLE'),
      link: worldRoutes.batteryWarranty,
      alwaysVisible: false
    },
    batteryEssentials: {
      img: batteryEssentialsIcon,
      subtitle: (t: TTypedTFunction) => t('PANASONIC_BATTERY_SUBTITLE'),
      link: worldRoutes.batteryEssentials.battery,
      alwaysVisible: true
    },
    coreV2: {
      img: elemezCoreV2Icon,
      subtitle: (t: TTypedTFunction) => t('PANASONIC_CORE_V2_SUBTITLE'),
      link: worldRoutes.core.overview,
      alwaysVisible: true
    }
  };
};

const getElemezProducts = (worldRoutes: AllWorldRoutes): LandingProductsData => {
  return {
    batteryWarranty: null,
    batteryEssentials: {
      img: batteryEssentialsIcon,
      subtitle: (t: TTypedTFunction) => t('ELEMEZ_BATTERY_SUBTITLE'),
      link: worldRoutes.batteryEssentials.battery,
      alwaysVisible: true
    },
    coreV2: {
      img: elemezCoreV2Icon,
      subtitle: (t: TTypedTFunction) => t('ELEMEZ_CORE_V2_SUBTITLE'),
      link: worldRoutes.core.overview,
      alwaysVisible: true
    }
  };
};

export const getBrandedLandingProducts = (brand: Brand, worldRoutes: AllWorldRoutes): LandingProductsData => {
  const products = {
    panasonic: getPanasonicProducts(worldRoutes),
    elemez: getElemezProducts(worldRoutes),
    peaktech: getElemezProducts(worldRoutes),
    computacenter: getElemezProducts(worldRoutes),
    '1e': getElemezProducts(worldRoutes)
  };

  return products[brand];
};

/** Returns features, title, name data for all products defined for the current brand */
const getProductsData = (products: ContextProductsData): ContextProductData[] => {
  return map(products, (product) => {
    return product;
  }).filter((productData) => productData.features);
};

export function Landing() {
  const { t } = useTranslation();
  const worldRoutes = useWorldRoutes();
  const { name: brand } = useBrandContext();
  const contextProductsData = useProductsContext();

  const hydrateLandingProductData = (data: LandingProductData): HydratedLandingProductData => {
    return {
      ...data,
      subtitle: data.subtitle(t),
      link: data.link
    };
  };

  const landingData = getBrandedLandingProducts(brand, worldRoutes);
  const productsData = getProductsData(contextProductsData);
  const mergedProductData = productsData.map((data) => {
    const landingProductData = landingData[data.name];
    return {
      ...data,
      ...hydrateLandingProductData(landingProductData)
    };
  });

  const items = mergedProductData.map((productData) => {
    const { alwaysVisible, name } = productData;
    const enabled = contextProductsData[name]?.enabled;
    if (!enabled && !alwaysVisible) { return null; }
    const props = { ...productData, enabled };
    delete props.features;
    delete props.alwaysVisible;
    return (
      <div className="col-md-4 col-sm-6 d-flex align-items-stretch" key={name} id={name}>
        <LandingItem {...props} />
      </div>
    );
  });

  return (
    <div className='landing-container'>
      <div className="row" id="elemez-landing">
        {items}
      </div>
    </div>
  );
}

export default Landing;
