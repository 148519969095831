import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import styled from 'styled-components';
import { useState } from 'react';
import moment from 'moment';
import { merge, pick } from 'lodash';

import { HealthSettings } from './health';
import { CycleCountSettings } from './cycleCount';
import { Popover } from '../../../../components/controls/popover';
import { AlertActive } from '../alertActive';
import { BatteryTypeSettings, WorldSettings } from '../../../../services/config/config';

const BatteryAlerts = styled.div`
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
`;

const Header = styled.div`
  font-size: 1.0625rem;
  font-weight: 600;
  padding-left: 0.4rem;
  display: inline;
`;

const BatteryAlertsHeader = styled.div`
  background: #f3f7fb;
  border-bottom: 1px solid #d7e2eb;
  padding: 0.3rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
`;

export interface IProps {
  batteryTypeSettings: BatteryTypeSettings[],
  worldSettings: WorldSettings,
  onDataChanged: (data: WorldSettings | BatteryTypeSettings[]) => void,
  submitInProgress: boolean,
  dataTimestamp: number,
  initialBatteryTypeSettings: BatteryTypeSettings[],
  initialWorldSettings: WorldSettings
}

export function BatteryAlertsSettings(props: IProps) {
  const { t } = useTranslation('settings');
  const [alertTimeStamp, setAlertTimeStamp] = useState<number>(props.dataTimestamp);

  if (props.dataTimestamp > alertTimeStamp) {
    setAlertTimeStamp(props.dataTimestamp);
  }

  function onAlertToggled(checked: boolean) {
    setAlertTimeStamp(moment.utc().valueOf());
    if (checked) {
      props.onDataChanged({ ...props.worldSettings, batteryStatusEnabled: checked });
    } else {
      const healthThresholdFields = pick(props.initialWorldSettings, ['batteryHealthStatusYellow', 'batteryHealthStatusRed']);
      const mergeToWorldSettings = merge(props.worldSettings, healthThresholdFields);
      const cycleCountFields = props.initialBatteryTypeSettings.map((item) => pick(item, ['batteryType', 'cycleCountYellow', 'cycleCountRed']));
      const mergeToBatteryTypeSettings = merge(props.batteryTypeSettings, cycleCountFields);
      props.onDataChanged({ ...mergeToWorldSettings, batteryStatusEnabled: checked });
      props.onDataChanged([...mergeToBatteryTypeSettings]);
    }
  }

  const tooltipContent = <div className="infoPopover">
    <div className="battery-alerts_tooltip-text-health">{t('HEALTH', { ns: 'batteryEssentials' })}: <span>{t('BATTERY_HEALTH_POPOVER')}</span></div>
    <div className="battery-alerts_tooltip-text-cycle-count">{t('CYCLE_COUNT', { ns: 'batteryEssentials' })}: <span>{t('BATTERY_CYCLE_COUNT_POPOVER')}</span></div>
  </div>;

  return (<BatteryAlerts className='battery-alerts'>
    <BatteryAlertsHeader className="battery-threshold-header">
      <div>
        <Header className="battery-alerts_header">{t('BATTERY_ALERTS')}</Header>
        <Popover
          placement="right"
          id={`popoverBatteryAlertsThreshold`}
          className="popoverThresholds"
          target={<i className="threshold-icon fa fa-question-circle" />}
          content={tooltipContent}
          isInsideTable={false}
        />
      </div>
      <AlertActive checked={props.worldSettings.batteryStatusEnabled} name={'battery-alerts'} onChange={onAlertToggled} />
    </BatteryAlertsHeader>
    <HealthSettings timeStamp={alertTimeStamp} onDataChanged={props.onDataChanged} worldSettings={props.worldSettings} submitInProgress={props.submitInProgress} alertEnabled={props.worldSettings.batteryStatusEnabled} />
    <CycleCountSettings timeStamp={alertTimeStamp} onDataChanged={props.onDataChanged} batteryTypeSettings={props.batteryTypeSettings} submitInProgress={props.submitInProgress} alertEnabled={props.worldSettings.batteryStatusEnabled} />
  </BatteryAlerts>);
}
