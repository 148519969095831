import React, { useEffect, useRef } from 'react';
import handyScroll from 'handy-scroll';
import { map, get } from 'lodash';
import { TTypedTFunction } from '@lib/useTypedTranslation';

import { ITableColumn, SortDirection } from './types';
import { DataTableHeader } from './dataTableHeader';
import { DataTableRow } from './dataTableRow';

import './styles.css';
import 'handy-scroll/dist/handy-scroll.css';

export interface IOnTableSort {
  (index: number, direction: SortDirection, column: ITableColumn): void
}
export interface IProps<Data, IdKey> {
  className?: string,
  columns: ITableColumn[],
  children?: any,
  selectedRow?: number,
  checkedRows?: number[],
  sortDirection?: SortDirection,
  sortedColumnIndex?: number,
  options?: {
    rowIdDataPath?: IdKey,
    getKey?: (data: Data) => string,
    rowNameDataPath?: string
  },
  data: Data[],
  onSort?: IOnTableSort,
  onRowSelected?: (rowIndex: number) => void,
  toggleSort?: boolean,
  t?: TTypedTFunction,
  onCheck?: (rowIndex: number) => void,
  onCheckAll?: () => void,
  highlight?: boolean,
  isLoading?: boolean
}

export function DataTable<Data, IdKey extends keyof Data | void = void>(props: IProps<Data, IdKey>) {
  let columns = props.columns;
  if (props.onCheck) {
    columns[0].hasCheckbox = true;
  }

  const tableEl = useRef(null);
  useEffect(() => {
    const el = tableEl.current;
    handyScroll.mount(el);
    return () => handyScroll.destroy(el);
  }, []);

  useEffect(() => {
    handyScroll.update(tableEl.current);
  }, [props.data.length]);

  props.children && React.Children.only(props.children);
  const row = props.children || <DataTableRow />;
  const getKey = props.options?.getKey;
  const idPath = get(props, 'options.rowIdDataPath');
  const rows = map(props.data, (item, index) => {
    // NB to prevent reconciliation errors, add a getKey function, provide a rowIdDataPath for a unique value, or add `key` to data.
    // The `key` should *not* be an iteration index, or re-generated in each render.
    // getKey is a means of generating a unique key where no single property is guaranteed unique. To generate a stable, unique value, it concatonates two properties.
    // It must be a stable unique value, e.g. be a unique id generated once when the data is fetched.
    const key = getKey ? getKey(item) : get(item, idPath || 'key', index);
    return React.cloneElement(row,
      {
        key,
        idPath,
        nameDataPath: props.options?.rowNameDataPath,
        rowIndex: index,
        selectedRowIndex: props.selectedRow,
        checkedRows: props.checkedRows,
        columns,
        data: item,
        onRowSelected: props.onRowSelected,
        t: props.t,
        onCheck: props.onCheck
      });
  });

  const hasCheckAllCheckBox = Boolean(props.onCheckAll && props.data?.length);

  return (
    <div ref={tableEl} className={`${props.className || ''} data-table-component ${props.highlight ? 'highlight' : ''} ${props.isLoading ? '' : 'data-ready'}`}>
      <table className="table table-borderless">
        <DataTableHeader
          onSort={props.onSort}
          columns={columns}
          sortedColumnIndex={props.sortedColumnIndex}
          sortDirection={props.sortDirection}
          toggleSort={props.toggleSort}
          hasCheckBox={hasCheckAllCheckBox}
          checkBoxChecked={hasCheckAllCheckBox ? props.checkedRows?.length === props.data?.length : undefined}
          checkBoxOnChange={hasCheckAllCheckBox ? props.onCheckAll : undefined}
        />
        <tbody>
          {!props.isLoading && rows}
        </tbody>
      </table>
    </div>
  );
}
