import * as am4charts from "@amcharts/amcharts4/charts";

/* istanbul ignore next */
export function animateBar(column: am4charts.Column) {
  column.animate([{
    "from": 0.2,
    "to": 1,
    "property": "opacity"
  }], 3000);
}
