import moment from "moment-timezone";
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import { formatDateAsOrdinal, formatDateAsOrdinalWithMonth } from "../../lib/ordinal";
import { getUserTimeZone } from "../../lib/timeZone";
import { TTypedTFunction } from "@lib/useTypedTranslation";
import { AxisItemLocation } from "@amcharts/amcharts4/.internal/charts/axes/Axis";

const createAxisRanges = (rangeLengthsInDays: number, chartStartDate: string, xAxis: am4charts.CategoryAxis, t: TTypedTFunction) => {
  let currentStartDate = chartStartDate;
  const timeZone = getUserTimeZone();
  const nowInTimeZone = moment.utc().tz(timeZone);
  const nowWithUtcOffset = nowInTimeZone.add(nowInTimeZone.utcOffset(), 'minutes').valueOf();
  const localNowAsUtcFormat = moment.utc(nowWithUtcOffset).valueOf();

  while (moment.utc(parseInt(currentStartDate)).isSameOrBefore(localNowAsUtcFormat)) {
    const isToday = moment.utc(parseInt(currentStartDate)).isSame(localNowAsUtcFormat, 'day');
    const range = xAxis.axisRanges.create();
    range.category = currentStartDate;
    range.endCategory = moment.utc(parseInt(currentStartDate)).add(rangeLengthsInDays, 'days').valueOf().toString();
    range.label.text = isToday ? t('TODAY', { ns: 'timeState' }) : formatDateAsOrdinalWithMonth(parseInt(currentStartDate));
    range.label.disabled = false;
    range.label.location = 0.05 as AxisItemLocation;
    range.grid.strokeOpacity = 0;

    currentStartDate = moment.utc(parseInt(currentStartDate)).add(rangeLengthsInDays, 'days').valueOf().toString();
  }
};

export function drawXAxisLabelsToToday(chart: am4charts.XYChart, xAxis: am4charts.CategoryAxis, days: number, t: TTypedTFunction) {
  if (days === 30) {
    xAxis.renderer.labels.template.adapter.add("textOutput", (text) => {
      if (text) {
        const dateAsNumber = parseInt(text);
        const timeZone = getUserTimeZone();
        const nowInTimeZone = moment.utc().tz(timeZone);
        const nowWithUtcOffset = nowInTimeZone.add(nowInTimeZone.utcOffset(), 'minutes').valueOf();
        const localNowAsUtcFormat = moment.utc(nowWithUtcOffset).valueOf();
        const isToday = moment.utc(dateAsNumber).isSame(localNowAsUtcFormat, 'day');
        return isToday ? t('TODAY', { ns: 'timeState' }) : formatDateAsOrdinal(dateAsNumber);
      }
    });
  } else {
    if (chart.data.length) {
      const startDate = chart.data[0].date;
      createAxisRanges(7, startDate, xAxis, t);
    }
    xAxis.renderer.labels.template.adapter.add("textOutput", (text) => {
      const nonFormattedDates = chart.data.map(datum => datum.date);
      if (!nonFormattedDates.includes(text)) {
        return text;
      }
    });
  }
}

export function drawSelectedDateXAxisLabels(xAxis: am4charts.CategoryAxis, selectedDate: number, categoryIntervalInMinutes: number) {
  xAxis.renderer.labels.template.disabled = true;
  const startOfDay = moment.utc(selectedDate).startOf('day').valueOf();

  let currentStartDate = startOfDay;

  while (moment.utc(currentStartDate).isSameOrBefore(moment.utc(selectedDate).endOf('day').valueOf() + 1)) {

    const range = xAxis.axisRanges.create();
    range.label = new am4charts.AxisLabel();
    range.category = moment.utc(currentStartDate).format('HH:mm');
    range.endCategory = moment.utc(currentStartDate).add(1, 'hour').format('HH:mm');
    range.label.text = moment.utc(currentStartDate).format('HH:mm');
    range.label.disabled = false;
    range.label.location = 0.035 as AxisItemLocation;
    range.label.dx = -25;
    range.grid.strokeOpacity = 0;

    /* we use a category axis, with each date set to a category. the start date is 00:00, as is the last date
     so we need to ensure the last 00:00 is set to a category at the end of the scale */
    if (moment.utc(moment.utc(selectedDate).endOf('day').valueOf() + 1).isSame(currentStartDate)) {
      range.category = moment.utc(selectedDate).endOf('day').subtract(categoryIntervalInMinutes - 1, 'minute').format('HH:mm');
      range.endCategory = moment.utc(selectedDate).endOf('day').subtract(categoryIntervalInMinutes - 1, 'minute').format('HH:mm');
    }

    currentStartDate = moment.utc(currentStartDate).add(2, 'hours').valueOf();

  }
}

/* istanbul ignore next */
export function addLink(series: am4charts.ColumnSeries, link: string) {
  series.columns.template.events.on("over", (event: any) => {
    const date = event.target.column._dataItem.categories.categoryX;
    series.columns.template.url = `${link}&utc=${date}`;
  });
  return series;
}

export function addLegend(chart: am4charts.XYChart) {
  chart.legend = new am4charts.Legend();
  chart.legend.useDefaultMarker = true;
  chart.legend.labels.template.fontWeight = '400';
  chart.legend.labels.template.dy = -8;

  const markerTemplate = chart.legend.markers.template;
  markerTemplate.width = 10;
  markerTemplate.height = 29;

  markerTemplate.disposeChildren();
  const legendItem = markerTemplate.createChild(am4core.Rectangle);
  legendItem.fill = am4core.color('#2c678c');
  legendItem.width = 10;
  legendItem.height = 10;

  /* istanbul ignore next */
  legendItem.adapter.add("fill", function (_, target) {
    if (target.dataItem && target.dataItem.dataContext && (target.dataItem.dataContext as any).dummyData) {
      return (target.dataItem.dataContext as any).dummyData.colour;
    }
  });

  chart.legend.itemContainers.template.clickable = false;
  chart.legend.itemContainers.template.focusable = false;
  chart.legend.itemContainers.template.cursorOverStyle = am4core.MouseCursorStyle.default;
}
