import { get } from "lodash";
import { request, RequestInitWithRetry } from "../../lib/request";
import { ISortResult } from "../shared/common";

export type ApplicationContext = ApplicationInfo & {
  id: string
};

export type ApplicationPerformanceParams = {
  id: string,
  days: number,
  timeZone: string,
  group?: string,
  homeLocationId?: string,
  zoneId?: string | null,
  version?: string,
  sharedUserId?: string | null
};

export function exportApplicationDeviceUsageAction(options: RequestInitWithRetry) {
  return async function (params: ApplicationPerformanceParams) {
    const input = {
      path: `/api/core/application/exportDeviceUsage`,
      qs: { id: params.id },
      body: {
        days: params.days,
        timeZone: params.timeZone,
        group: params.group,
        homeLocationId: params.homeLocationId,
        zoneId: params.zoneId,
        version: params.version
      }
    };
    return request(input, options);
  };
}

export function exportApplicationMinutesUsageAction(options: RequestInitWithRetry) {
  return async function (params: ApplicationPerformanceParams) {
    const input = {
      path: `/api/core/application/exportMinutesUsage`,
      qs: { id: params.id },
      body: {
        days: params.days,
        timeZone: params.timeZone,
        group: params.group,
        homeLocationId: params.homeLocationId,
        zoneId: params.zoneId,
        version: params.version
      }
    };
    return request(input, options);
  };
}

export function exportApplicationDataUsageAction(options: RequestInitWithRetry) {
  return async function (params: ApplicationPerformanceParams) {
    const input = {
      path: `/api/core/application/exportDataUsage`,
      qs: { id: params.id },
      body: {
        days: params.days,
        timeZone: params.timeZone,
        group: params.group,
        homeLocationId: params.homeLocationId,
        zoneId: params.zoneId,
        version: params.version,
        sharedUserId: params.sharedUserId
      }
    };
    return request(input, options);
  };
}

export type ApplicationDeviceUsage = {
  date: string,
  deviceCount: number
}[];

export function getApplicationDeviceUsage(params: ApplicationPerformanceParams) {
  return async (options: RequestInitWithRetry): Promise<ApplicationDeviceUsage> => {
    const input = {
      path: `/api/core/application/deviceUsage`,
      qs: { id: params.id },
      body: {
        days: params.days,
        timeZone: params.timeZone,
        group: params.group,
        homeLocationId: params.homeLocationId,
        zoneId: params.zoneId,
        version: params.version
      },
    };
    return request(input, options);
  };
}

export type DataUsage = {
  date: string,
  mobile: number,
  wifi: number
};

export type MinutesUsage = {
  date: string,
  userActivity: number
};

export type ApplicationPerformanceOverview = {
  mobileDataUsage: number,
  wifiDataUsage: number,
  installedCount: number | null,
  versionCount: number | null,
  totalMinutesUsage: number,
  dailyAverageMinutesUsage: number,
  dailyDataUsage: DataUsage[],
  dailyMinutesUsage: MinutesUsage[]
};

export function getApplicationPerformanceOverview(params: ApplicationPerformanceParams) {
  return async (options: RequestInitWithRetry): Promise<ApplicationPerformanceOverview | null> => {
    const input = {
      path: `/api/core/application/performanceOverview`,
      qs: { id: params.id },
      body: {
        days: params.days,
        timeZone: params.timeZone,
        group: params.group,
        homeLocationId: params.homeLocationId,
        zoneId: params.zoneId,
        version: params.version,
        sharedUserId: params.sharedUserId
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export function getApplicationVersions(params: Omit<ApplicationPerformanceParams, 'homeLocationId' | 'group'>) {
  return async (options: RequestInitWithRetry): Promise<string[]> => {
    const input = {
      path: `/api/core/application/versions`,
      qs: { id: params.id },
      body: {
        days: params.days,
        timeZone: params.timeZone
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export type ApplicationInfo = {
  packageName: string,
  name: string | null,
  sharedUserId: string | null
};

export function getApplicationInfo(params: { id: string }) {
  return async (options: RequestInitWithRetry): Promise<ApplicationInfo | null> => {
    const input = {
      path: `/api/core/application/info`,
      qs: { id: params.id }
    };
    const output = await request(input, options);
    return output;
  };
}

export type ApplicationDevicesParams = {
  id: string,
  limit: number,
  offset: number,
  group?: string,
  homeLocationId?: string,
  zoneId?: string,
  version?: string,
  search?: string,
  sort?: ISortResult
};

export type ApplicationDevicesListItem = {
  version: string,
  mobileDataUsage: number | null,
  wifiDataUsage: number | null,
  lastUsed: string | null,
  deviceInfo: {
    id: string,
    serialNumber: string | null,
    imei: string | null,
    assetTag: string | null,
    group: string[] | null,
    homeLocation: {
      id: string,
      name: string
    } | null
  }
};

export type ApplicationDevicesList = {
  list: ApplicationDevicesListItem[],
  totalCount: number
};

export function getApplicationDevicesList(params: ApplicationDevicesParams) {
  return async (options: RequestInitWithRetry): Promise<ApplicationDevicesList> => {
    const input = {
      path: `/api/core/application/devicesList`,
      qs: { id: params.id, limit: params.limit, offset: params.offset },
      body: {
        group: params.group,
        homeLocationId: params.homeLocationId,
        zoneId: params.zoneId,
        version: params.version,
        sort: get(params, 'sort'),
        search: get(params, 'search')
      },
    };
    return request(input, options);
  };
}

export type ExportApplicationsListParams = Omit<ApplicationDevicesParams, 'limit' | 'offset'>;

export function exportApplicationDevicesListAction(options: RequestInitWithRetry) {
  return async (params: ExportApplicationsListParams): Promise<string> => {
    const input = {
      path: `/api/core/application/exportDevicesList`,
      qs: { id: params.id },
      body: {
        group: params.group,
        homeLocationId: params.homeLocationId,
        zoneId: params.zoneId,
        version: params.version,
        sort: get(params, 'sort'),
        search: get(params, 'search')
      }
    };
    return request(input, options);
  };
}
