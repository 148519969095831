import { get } from "lodash";
import { request, RequestInitWithRetry } from "../../lib/request";
import { ISortResult } from "../shared/common";

export type ApplicationsListParams = {
  limit: number,
  offset: number,
  search?: string,
  sort?: ISortResult,
  systemFilter?: {
    system: boolean
  }
};

export type ExportApplicationsListParams = {
  search?: string,
  sort?: ISortResult,
  systemFilter?: {
    system: boolean
  }
};

export type Application = {
  id: string,
  name: string,
  packageName: string,
  sharedUserId: string,
  versionCount: number,
  installedCount: number,
  latestVersion: string,
  lastUsed: string,
  usedCount: number,
  firstInstalled: string
};

export type ApplicationsList = Application[];

export type ApplicationsListAndCount = {
  list: ApplicationsList,
  totalCount: number
};

export function getApplicationsList(params: ApplicationsListParams) {
  return async (options: RequestInitWithRetry): Promise<ApplicationsListAndCount> => {
    const input = {
      path: `/api/core/applications/list`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        sort: get(params, 'sort'),
        search: get(params, 'search'),
        systemFilter: params.systemFilter
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export function exportApplicationsListAction(requestOptions: RequestInitWithRetry) {
  return async (options: ExportApplicationsListParams): Promise<string> => {
    const input = {
      path: `/api/core/applications/export`,
      body: {
        sort: get(options, 'sort'),
        search: get(options, 'search'),
        systemFilter: options.systemFilter
      },
      method: 'post'
    };
    return request(input, requestOptions);
  };
}
