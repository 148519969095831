import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";

// Polyfills to allow code to run on older browsers
import 'core-js';
import 'whatwg-fetch';
import 'yet-another-abortcontroller-polyfill';
import './i18n';

import * as React from 'react';
import { App } from './containers/app/app';
import { createRoot } from 'react-dom/client';

createRoot(document.getElementById('mount'))
  .render(<App />);
