import { request, RequestInitWithRetry } from '../../lib/request';

export interface IAgreement {
  id: string,
  text: string
}

export interface IAgreements {
  requiredEULA?: IAgreement | null,
  requiredDPA?: IAgreement | null,
  acceptedEULA?: IAgreement | null,
  acceptedDPA?: IAgreement | null
}

export interface AgreementsIdsResponse {
  requiredEULA: { id: string },
  requiredDPA: { id: string },
  acceptedEULA: { id: string },
  acceptedDPA: { id: string }
}
export interface AgreementsIds {
  requiredEULA: string,
  requiredDPA: string,
  acceptedEULA: string,
  acceptedDPA: string
}

export interface IAcceptAgreementsBody {
  // the string ids of the eula/dpa to be accepted
  eula?: string,
  dpa?: string,
  name: string,
  jobTitle: string
}

export function acceptAgreementsAction(options: RequestInitWithRetry) {
  return async (data: IAcceptAgreementsBody): Promise<AgreementsIds> => {
    const params = {
      path: '/api/agreements/acceptAgreements',
      method: 'POST',
      body: data
    };

    await request(params, options);  // the middleware will return an error response if acceptance fails
    return dependencies.getAgreementIds()(options);
  };
}

export type IGetAgreementsQuery = {
  [K in keyof IAgreements]: boolean;
};

export const getAgreements = (qs: IGetAgreementsQuery) => {
  return (options: RequestInitWithRetry): Promise<IAgreements> => {
    const params = {
      path: '/api/agreements/getAgreements',
      qs,
    };
    return request(params, options);
  };
};

export const getAgreementIds = () => {
  return async (options: RequestInitWithRetry): Promise<AgreementsIds> => {
    const params = {
      path: '/api/agreements/getAgreements',
      qs: {
        requiredEULA: true,
        requiredDPA: true,
        acceptedEULA: true,
        acceptedDPA: true,
        idsOnly: true
      },
    };
    const response = await request<AgreementsIdsResponse>(params, options);
    return {
      requiredEULA: response.requiredEULA.id,
      requiredDPA: response.requiredDPA.id,
      acceptedEULA: response.acceptedEULA.id,
      acceptedDPA: response.acceptedDPA.id
    };
  };
};

export const dependencies = {
  getAgreementIds
};
