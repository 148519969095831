import { isUndefined } from "lodash";
import { useState } from "react";
import { useTranslation } from "@lib/useTypedTranslation";

import styled from "styled-components";
import { ChipButton } from "../controls/chipButton";

enum DataSeries {
  wifi = 'wifi',
  mobile = 'mobile'
}

const Button = styled(ChipButton)`
  margin-right: 0.75rem;
`;

const ns = "translation";
export function useDataUsageSeriesSwitch() {
  const [filter, setFilter] = useState<DataSeries>();
  const { t } = useTranslation(ns);

  const buttons = [
    {
      Component: Button,
      props: {
        active: isUndefined(filter),
        text: t('MOBILE_AND_WIFI'),
        onClick: () => { setFilter(undefined); }
      }
    },
    {
      Component: Button,
      props: {
        active: filter === DataSeries.mobile,
        text: t('MOBILE'),
        onClick: () => { setFilter(DataSeries.mobile); }
      }
    },
    {
      Component: Button,
      props: {
        active: filter === DataSeries.wifi,
        text: t('WIFI'),
        onClick: () => { setFilter(DataSeries.wifi); }
      }
    }
  ];

  return {
    mobileSeriesVisible: filter === DataSeries.mobile || isUndefined(filter),
    wifiSeriesVisible: filter === DataSeries.wifi || isUndefined(filter),
    buttons
  };
}
