import React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  color: #fbcd76;
  font-size: 1.2em;
  background-position: bottom;
  line-height: 0.8em;
  background-image: linear-gradient(to right, transparent 40%, #333 40% 60%, transparent 60%);
  background-repeat: no-repeat;
`;
Icon.displayName = 'EnhancedWarningIcon';

export function EnhancedWarningIcon(props: { className?: string }) {
  return <Icon
    data-id="enhanced-warning-icon"
    className={`${props.className || ''} fas fa-exclamation-triangle`}
  />;
}
