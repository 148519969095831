import * as React from 'react';
import { merge } from 'lodash';
import './stateSwitch.css';

export interface IProps {
  selectedIndex: number,
  children?: any
}

interface IState {
  loadedItems: { [key: number]: true }
}

export class StateSwitch extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loadedItems: { [props.selectedIndex]: true }
    };
  }

  static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
    if (!prevState.loadedItems[nextProps.selectedIndex]) {
      return { loadedItems: merge(prevState.loadedItems, { [nextProps.selectedIndex]: true }) };
    } else {
      return null;
    }
  }

  render() {
    let content: JSX.Element[] = [];

    React.Children.forEach(this.props.children, (child, index) => {
      const isSelected = index === this.props.selectedIndex;
      const isLoaded = this.state.loadedItems[index];

      if (isSelected || isLoaded) {
        const className = 'stateSwitch ' + (isSelected ? 'selected' : 'hidden');
        content.push(<div key={`state_switch_index_${index}`} className={className}>{child}</div>);
      }
    });
    return <>{content}</>;
  }
}
