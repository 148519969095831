import { useTranslation } from "@lib/useTypedTranslation";

import { Link } from "react-router-dom";
import styled from "styled-components";

import { DEFAULT_VALUE, formatDateLastUpdated, formatValue, getFormatNumberToLocale } from "../../../../components/data-table/dataFormatters";
import { ITableColumn } from "../../../../components/data-table/types";
import { Tooltip } from "../../../../components/tooltip/tooltip";
import { useLanguageAndLocaleContext } from "../../../../context/languageAndLocale";
import { useWorldRoutes } from "../../../../routes/parts/allWorldRoutes";
import { Application } from "../../../../services/core/applications";

const TooltipText = styled.div`
  font-weight: 600;
  font-size: var(--font-size-14px);
  font-weight: var(--font-weight-normal);
  max-width: 14rem;
`;

const StyledTooltip = styled(Tooltip)`
  color: #7490b9;
  margin-left: 0.375rem;
`;

const formatLinkToApplication = (appName: string, rowData: Application, path: string) => {
  if (!appName) { return DEFAULT_VALUE; }
  return <Link to={`${path}?id=${encodeURIComponent(rowData.id)}`}>{formatValue(appName)}</Link>;
};

export const useApplicationsListColumns = () => {
  const { t } = useTranslation('coreApplications');
  const routes = useWorldRoutes();
  const { locale } = useLanguageAndLocaleContext();
  const linkProcessor = (appName: string, rowData: Application) => {
    return formatLinkToApplication(appName, rowData, routes.core.applicationPerformance);
  };

  const sharedIdTitleAndTooltip = <>
    {t('SHARED_ID')}
    <StyledTooltip popoverPortalSelector={'.core-application-list'} placement={'bottom'} popperContent={<TooltipText data-id="shared-id-tooltip">{t('SHARED_ID_TOOLTIP_TEXT')}</TooltipText>}>
      <i aria-label='about shared user id' className="fa fa-question-circle" />
    </StyledTooltip>
  </>;

  const usedCountTitleAndTooltip = <>
    {t('USED_COUNT')}
    <StyledTooltip popoverPortalSelector={'.core-application-list'} placement={'bottom'} popperContent={<TooltipText data-id="used-count-tooltip">{t('USED_COUNT_TOOLTIP_TEXT')}</TooltipText>}>
      <i aria-label='about used count' className="fa fa-question-circle" />
    </StyledTooltip>
  </>;

  const columns: ITableColumn[] = [
    { id: 'name', title: t('NAME'), dataPath: 'name', sortable: true, processor: linkProcessor, width: '12rem' },
    { id: 'packageName', title: t('PACKAGE_NAME', { ns: 'deviceApplications' }), dataPath: 'packageName', sortable: true, processor: formatValue, width: '10rem' },
    { id: 'sharedUserId', title: sharedIdTitleAndTooltip, dataPath: 'sharedUserId', sortable: true, processor: formatValue, width: '12rem' },
    { id: 'installedCount', title: t('INSTALLED_COUNT'), dataPath: 'installedCount', sortable: true, processor: getFormatNumberToLocale(locale), width: '8rem' },
    { id: 'versionCount', title: t('VERSION_COUNT'), dataPath: 'versionCount', sortable: true, processor: getFormatNumberToLocale(locale), width: '6rem' },
    { id: 'latestVersion', title: t('LATEST_VERSION'), dataPath: 'latestVersion', sortable: true, processor: formatValue, width: '9rem' },
    { id: 'lastUsed', title: t('LAST_USED'), dataPath: 'lastUsed', sortable: true, processor: formatDateLastUpdated, width: '9rem' },
    { id: 'usedCount', title: usedCountTitleAndTooltip, dataPath: 'usedCount', sortable: true, processor: getFormatNumberToLocale(locale), width: '10rem' },
    { id: 'firstInstalled', title: t('FIRST_INSTALLED'), dataPath: 'firstInstalled', sortable: true, processor: formatDateLastUpdated, width: '9rem' }
  ];

  return columns;
};
