import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';

import { SettingsTable } from '../../../../components/settings/settings-table';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';
import { Trans } from '@components/i18n/typedTrans';

export interface IProps {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  timeStamp: number,
  alertEnabled: boolean,
  onDataChanged: (data: WorldSettings) => void
}

export function HealthSettings(props: IProps) {
  const { t } = useTranslation('settings');

  const onDataChanged = (data: any) => {
    props.onDataChanged(data[0]);
  };

  return (
    <div className="health">
      <div className='title-header'>{t('BATTERY_HEALTH_TITLE')}</div>
      <div className="settings-group">
        <SettingsTable
          key={props.timeStamp}
          data={[props.worldSettings]}
          dataUpdated={onDataChanged}
          disabled={props.submitInProgress || !props.alertEnabled}
          thresholdPrecedence='lower'
          type='settings'
          tableId='BatteryHealthThresholds'
          header={<Trans ns="translation" i18nKey='BATTERY_FIRST_CAPITAL_other' />}
          definition={{
            dataPaths: ['batteryHealthStatusYellow', 'batteryHealthStatusRed'],
            columns: [
              {
                type: 'warning',
                limit: { lower: 2, higher: 99 },
                dataSuffix: '%',
                header: <span className="threshold_header warning"><i className="fa fa-exclamation-triangle circle_orange enhanced-warning-icon" />{t('BATTERY_WARNING_HEADER')}</span>,
                errorMessage: t('BATTERY_HEALTH_WARNING_ERROR')
              },
              {
                type: 'replace',
                limit: { lower: 1, higher: 98 },
                dataSuffix: '%',
                header: <span className="threshold_header problem"><i className="fa fa-times-circle circle_red" />{t('BATTERY_REPLACE_HEADER')}</span>,
                errorMessage: t('BATTERY_HEALTH_REPLACE_ERROR')
              },
            ],
            rows: [{
              header: <span className='battery-threshold-row-title'>{t('BATTERY_HEALTH_ROW_TITLE')}</span>
            }]
          }}
        />
      </div>
    </div>
  );
}
