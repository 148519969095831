import React, { useMemo } from 'react';
import { useBrandContext } from '../../context/brand';
import { ContextProductsData, ProductsProvider } from '../../context/products';
import { SameValueAsKeys } from '../../lib/typeUtils';

// NB order here determines order on landing page
export const products = [
  'batteryWarranty',
  'batteryEssentials',
  'coreV2'
] as const;

type ProductNames = SameValueAsKeys<typeof products>;
export const productNames: ProductNames = products.reduce((acc, type) => ({ ...acc, [type]: type }), {} as ProductNames);

export type Product = typeof products[number];

export type EnabledProductsData = {
  features: Feature[],
  title: string,
  name: Product,
  enabled: true
 };

export type ProductData = {
  features?: Feature[],
  title?: string,
  name?: Product,
  enabled: false
 } | EnabledProductsData;

export type ProductsData = {
  [p in Product]: Partial<ProductData>
};

/** valid current world features values */
export enum Feature {
  batteryEssentials = 'elemez-battery-essentials',
  batteryEssentialsDevices = 'elemez-battery-essentials:devices',
  coreV2 = 'elemez-elemez-core-v2',
  panasonicSmartBatteryMonitor = 'panasonic-smart-battery-monitor',
  panasonicSmartBatteryMonitorDevices = 'panasonic-smart-battery-monitor:devices',
  panasonicSmartServiceV2 = 'panasonic-smart-service-v2',
  panasonicBatteryWarranty = 'panasonic-smart-service-lite'
}

const panasonicProducts: ProductsData = {
  batteryWarranty: {
    features: [Feature.panasonicBatteryWarranty],
    title: 'Smart Battery Warranty',
    name: 'batteryWarranty'
  },
  batteryEssentials: {
    features: [Feature.panasonicSmartBatteryMonitor, Feature.panasonicSmartBatteryMonitorDevices],
    title: 'Smart Battery Monitor',
    name: 'batteryEssentials'
  },
  coreV2: {
    features: [Feature.panasonicSmartServiceV2],
    title: 'Smart Service',
    name: 'coreV2'
  }
};

const elemezProducts: ProductsData = {
  batteryWarranty: {
    enabled: false
  },
  batteryEssentials: {
    features: [Feature.batteryEssentials, Feature.batteryEssentialsDevices],
    title: 'Battery Essentials',
    name: 'batteryEssentials'
  },
  coreV2: {
    features: [Feature.coreV2],
    title: 'Core',
    name: 'coreV2'
  }
};

const createProductsContext = (worldFeatures: string[], products: ProductsData): ContextProductsData => {
  const result: ContextProductsData = {} as ContextProductsData;
  (Object.keys(products) as Array<keyof ProductsData>).forEach((productName) => {
    const enabledFeatures = products[productName].features?.filter(feature => worldFeatures.includes(feature)) || [];
    result[productName] = {
      ...products[productName],
      enabled: enabledFeatures.length > 0,
      enabledFeatures
    };
  });
  return result;
};

interface ProductProviderProps {
  features: string[],
  children: React.ReactNode
}

export const ProductsWrapper = ({ children, features }: ProductProviderProps) => {
  const { isPanasonic } = useBrandContext();
  const contextValue: ContextProductsData = useMemo(() => {
    if (isPanasonic) {
      return createProductsContext(features, panasonicProducts);
    } else {
      return createProductsContext(features, elemezProducts);
    }
  }, [isPanasonic, features]);

  return (
    <ProductsProvider value={contextValue}>
      {children}
    </ProductsProvider>
  );
};
