
import * as React from 'react';
import { useState, useRef, useMemo } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import moment from 'moment';
import { isEqual } from 'lodash';

import { BatterySettings, BatterySettingsLicense } from '../battery/batterySettings';
import { LocationSettings } from '../location/locationSettings';
import { UserExperienceSettings } from '../userExperience/userExperienceSettings';
import { UtilisationSettings } from '../utilisation/utilisationSettings';
import { PreventNavigation } from '../../../../components/navigation/preventNavigation';
import { BatteryTypeSettings, WorldSettings } from '../../../../services/config/config';

import '../settings.css';
import { FormControls } from '../../../../components/forms/formControls';

export interface IProps {
  worldSettings: WorldSettings,
  batteryTypeSettings: BatteryTypeSettings[],
  saveData: (data: any) => void,
  batterySettingsLicense: BatterySettingsLicense,
  locationSettingsLicense: boolean,
  userExperienceSettingsLicense: boolean,
  utilisationSettingsLicense: boolean
}

interface SubmitResult {
  success: boolean,
  message: string
}

export function AlertsSettingsPage(props: IProps) {
  const { t } = useTranslation('settings');
  const [worldSettings, setWorldSettings] = useState<WorldSettings>(props.worldSettings);
  const [batteryTypeSettings, setBatteryTypeSettings] = useState<BatteryTypeSettings[]>(props.batteryTypeSettings);
  const [dataTimestamp, setDataTimestamp] = useState<number>(moment.utc().valueOf());
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [submitResult, setSubmitResult] = useState<SubmitResult>(undefined);
  const formRef = useRef(null);
  const areButtonsDisabled = useMemo(() => {
    const hasSettingsChanged = !isEqual(worldSettings, props.worldSettings) || !isEqual(batteryTypeSettings, props.batteryTypeSettings);
    const hasError = submitResult?.success === false;
    return !hasSettingsChanged || hasError;
  }, [worldSettings, batteryTypeSettings, props.batteryTypeSettings, props.worldSettings, submitResult?.success]);

  function onDataChanged(data: WorldSettings | BatteryTypeSettings[]) {
    if (Array.isArray(data)) {
      setBatteryTypeSettings(data);
    } else {
      setWorldSettings(data);
    }
  }

  function handleCancel() {
    setWorldSettings(props.worldSettings);
    setBatteryTypeSettings(props.batteryTypeSettings);
    setDataTimestamp(moment.utc().valueOf());
  }

  async function handleSubmit() {
    if (!formRef.current.reportValidity() || submitInProgress) {
      return;
    }
    try {
      setSubmitResult(undefined);
      setSubmitInProgress(true);
      await props.saveData({ worldSettings: worldSettings, batteryTypeSettings: batteryTypeSettings });
      setSubmitResult({ message: t('SAVE_SUCCESS'), success: true });
      setSubmitInProgress(false);
      setDataTimestamp(moment.utc().valueOf());
    } catch {
      setSubmitResult({ message: t('SAVE_FAIL'), success: false });
      setSubmitInProgress(false);
    }
  }

  return (
    <div className='threshold-settings-composition alerts'>
      <PreventNavigation enabled={!areButtonsDisabled}/>
      <form ref={formRef}>
        {props.batterySettingsLicense && <BatterySettings
          batteryTypeSettings={batteryTypeSettings}
          worldSettings={worldSettings}
          licensed={props.batterySettingsLicense}
          onDataChanged={onDataChanged}
          submitInProgress={submitInProgress}
          dataTimestamp={dataTimestamp}
          initialWorldSettings={props.worldSettings}
          initialBatteryTypeSettings={props.batteryTypeSettings}
        />}
        {props.locationSettingsLicense && <LocationSettings
          worldSettings={worldSettings}
          onDataChanged={onDataChanged}
          submitInProgress={submitInProgress}
          dataTimestamp={dataTimestamp}
        />}
        {props.userExperienceSettingsLicense && <UserExperienceSettings
          worldSettings={worldSettings}
          onDataChanged={onDataChanged}
          submitInProgress={submitInProgress}
          dataTimestamp={dataTimestamp}
        />}
        {props.utilisationSettingsLicense && <UtilisationSettings
          worldSettings={worldSettings}
          onDataChanged={onDataChanged}
          submitInProgress={submitInProgress}
          dataTimestamp={dataTimestamp}
        />}
        <FormControls
          submitResult={{
            message: submitResult?.message,
            status: submitInProgress ? 'loading' : submitResult ? submitResult.success ? 'success' : 'error' : undefined
          }}
          cancelDisabled={areButtonsDisabled}
          submitDisabled={areButtonsDisabled}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          mode='save'
        />
      </form>
    </div>
  );
}
