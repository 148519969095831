import React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  color: #dd7778;
  font-size: 1.2em;
`;
Icon.displayName = 'ProblemIcon';

export function ProblemIcon(props: { className?: string }) {
  return <Icon data-id="problem-icon" className={`${props.className || ''} fas fa-times-circle`} />;
}
