import { UserRole } from '../../components/authentication/userAuth';
import { request, RequestInitWithRetry } from '../../lib/request';

export interface IUserData {
  id: string,
  email: string,
  role: UserRole,
  homeLocation: {
    id: string,
    name: string
  } | null,
  worldIds: string[] | null,
  group: string | null,
  zone: {
    id: string,
    name: string
  } | null
}

export type CurrentUser = Omit<IUserData, 'worldIds'> & {
  homeLocationId: string | null,
  group: string | null,
  zoneId: string | null,
  zone: {
    id: string,
    name: string
  } | null
};

export interface IUserListResult {
  list: IUserData[],
  count: number
}

export interface IListOptions {
  limit: number,
  offset: number,
  search?: string
}

export interface IListOptionsExport {
  search?: string
}

export interface CreateUserParameters {
  email: string,
  role: UserRole,
  homeLocationId: string | null,
  worldIds: string[] | null,
  group: string | null,
  zoneId: string | null
}

export interface UpdateUserParameters {
  id: string,
  role: UserRole,
  homeLocationId: string | null,
  worldIds: string[] | null,
  group: string | null,
  zoneId: string | null
}

export interface ISuccessfulResponse {
  createUser: {
    success: true
  }
}

export interface IDeleteResponse {
  [id: string]: {
    success: boolean
  }
}

export async function deleteUsers(ids: string[]): Promise<IDeleteResponse> {
  const input = {
    path: '/api/users/delete',
    method: 'POST',
    body: {
      ids
    }
  };
  return request(input);
}

export function getUsersList(params: IListOptions) {
  return async (options: RequestInitWithRetry): Promise<IUserListResult> => {
    const input = {
      path: `/api/users/list`,
      qs: { limit: params.limit, offset: params.offset, search: params.search }
    };
    return request(input, options);
  };
}

export function exportUsersList(options: IListOptionsExport): Promise<string> {
  const input = {
    path: `/api/users/exportList`,
    method: 'POST',
    qs: { search: options.search },
  };
  return request(input);
}

export async function createUser(body: CreateUserParameters): Promise<{ success: boolean }> {
  const input = {
    path: '/api/users/createUser',
    method: 'POST',
    body
  };
  return request(input);
}

export async function updateUser(body: UpdateUserParameters): Promise<{ success: boolean }> {
  const input = {
    path: '/api/users/update',
    method: 'POST',
    body
  };
  return request(input);
}

export function getCurrentUser(id: string) {
  return async (options: RequestInitWithRetry): Promise<CurrentUser> => {
    const input = {
      path: '/api/users/current',
      qs: { id }
    };
    return request(input, options);
  };
}
