import moment, { MomentLongDateFormat } from 'moment';
import 'moment/locale/fr-ca';
import 'moment/locale/en-gb';
import 'moment/locale/fr';
import 'moment/locale/es';

const updateDateFormats = () => {
  moment.updateLocale('fr-ca', {
    longDateFormat: {
      ...(moment.localeData('fr-ca') as any)._longDateFormat as MomentLongDateFormat,
      l: "YY-M-D",
      ll: "dddd Do MMM",
      LL: "dddd Do MMMM",
      LLL: "dddd, Do MMMM YYYY",
      LLLL: "Do MMM"
    }
  });

  moment.updateLocale('en-gb', {
    longDateFormat: {
      ...(moment.localeData('en-gb') as any)._longDateFormat as MomentLongDateFormat,
      l: "D/M/YY",
      ll: "dddd Do MMM",
      LL: "dddd Do MMMM",
      LLL: "dddd, Do MMMM YYYY",
      LLLL: "Do MMM"
    }
  });

  moment.updateLocale('fr', {
    longDateFormat: {
      ...(moment.localeData('fr') as any)._longDateFormat as MomentLongDateFormat,
      l: "D/M/YY",
      ll: "dddd Do MMM",
      LL: "dddd Do MMMM",
      LLL: "dddd, Do MMMM YYYY",
      LLLL: "Do MMM"
    }
  });

  moment.updateLocale('en', {
    longDateFormat: {
      ...(moment.localeData('en') as any)._longDateFormat as MomentLongDateFormat,
      l: "M/D/YY",
      ll: "dddd Do MMM",
      LL: "dddd Do MMMM",
      LLL: "dddd, MMMM Do YYYY",
      LLLL: "Do MMM"
    }
  });

  moment.updateLocale('es', {
    longDateFormat: {
      ...(moment.localeData('es') as any)._longDateFormat as MomentLongDateFormat,
      l: "D/M/YY",
      ll: "dddd Do MMM",
      LL: "dddd Do MMMM",
      LLL: "dddd, Do MMMM YYYY",
      LLLL: "Do MMM"
    }
  });
};
export default updateDateFormats;
