import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { get, map, isEmpty, flatMap, isString } from 'lodash';
import styled from 'styled-components';

import { IFilterResult, GroupFilterSettings, getFilterOptionDisplay } from './filterLib';
import { Button } from '../buttons/button';
import { ButtonColours } from '../../containers/app/themes';

import './filterStatus.css';

export const RemoveFilterButton = styled(Button)`
  margin-left: auto;
  margin-right: 1rem;
  width: 0;
  border: none;
  padding: 0;
  &:hover {
    color: #000;
  }
  &:active {
    outline: none;
  }
`;

RemoveFilterButton.displayName = 'RemoveFilterButton';

export interface IFilterStatus {
  id: string,
  display: string,
  group?: string
}

interface IProps {
  selectedFilters: IFilterStatus[],
  filterClasses?: GroupFilterSettings,
  onFilterRemoved?: (id: string, item: IFilterStatus) => void,
  onNavigateFilters?: (id: string, item: IFilterStatus) => void,
  onEditFilters?: () => void,
  onClearFilters?: () => void
}

export const FilterStatus = (props: IProps) => {
  const { t } = useTranslation('translation');

  const filterBoxes = map(props.selectedFilters, (filter) => {
    let removeButton;
    if (props.onFilterRemoved) {
      removeButton = <RemoveFilterButton
        ariaLabel={`remove ${filter.id} filter button`}
        dataId={`${filter.id}-filter_close_button`}
        colour={ButtonColours.grey}
        iconStyle={'fas fa-times'}
        onClick={() => props.onFilterRemoved(filter.id, filter)}
      ></RemoveFilterButton>;
    }
    const filterClass = get(props.filterClasses, `${filter.group}.${filter.id}`, '');
    return (
      <div key={`filter_box_${filter.id}`} data-cy={`filter-id--${filter.id}`} className={`filter-box ${filterClass}`}>
        {removeButton}
        <span className={props.onNavigateFilters ? "navigate" : ""} onClick={() => { props.onNavigateFilters && props.onNavigateFilters(filter.id, filter); }}>{filter.display}</span>
      </div>
    );
  });

  const clearButton = props.onClearFilters && <span className="navigate clear" onClick={props.onClearFilters}>{t('CLEAR_ALL')}</span>;
  const editButton = props.onEditFilters && <span className="navigate edit" onClick={props.onEditFilters}>{t('EDIT_FILTERS')}</span>;
  const controlsSection = !isEmpty(props.selectedFilters) && (
    <div className="filter-controls">
      {editButton}
      {clearButton}
    </div>
  );

  return (
    <div className='filter-status-block'>
      <div className="filter-row">
        {filterBoxes}
        {controlsSection}
      </div>
    </div>
  );
};

export function convertFilterToStatus(filter: IFilterResult): IFilterStatus[] {
  return flatMap(filter, (filterGroup, key) => {
    return filterGroup.map((f) => {
      if (isString(f)) {
        return { id: f, display: f, group: key };
      }
      const display = f.statusValue || getFilterOptionDisplay(f);
      return { id: f.id, display, group: key };
    });
  });
}
