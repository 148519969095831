import { TTypedTFunction } from "@lib/useTypedTranslation";
import { upperCase } from "lodash";
import { AlertStatus, alertStatuses } from "../../services/config/alertConstants";
import { isAlertEnabled, WorldSettings } from "../../services/config/config";
import { FilterOption } from "./filterLib";
import { ITranslationKeys } from "components/i18n/keys";

interface UntranslatedAlertStatusFilterOption extends Omit<FilterOption, 'statusValue'> {
  statusValue: (t: TTypedTFunction) => string,
  id: AlertStatus
}

export const alertStatusFilterValues: UntranslatedAlertStatusFilterOption[] = alertStatuses.map((status) => {
  const alertKey = upperCase(`${status}`).split(' ').join('_');
  return {
    id: status,
    value: alertKey,
    statusValue: (t: TTypedTFunction) => `${t(alertKey as keyof ITranslationKeys['anomaly'], { ns: 'anomaly' })}`,
  };
}).flat();

export const getTranslatedAlertStatusFilterValues = (t: TTypedTFunction, worldSettings: WorldSettings): FilterOption[] => {
  return alertStatusFilterValues.filter(status => isAlertEnabled(status.id, worldSettings)).map(val => ({
    ...val,
    value: t(val.value as keyof ITranslationKeys['anomaly'], { ns: 'anomaly' }),
    statusValue: val.statusValue(t)
  }));
};
