import React, { useContext } from 'react';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';

export interface AuthContextValue {
  authConfig: any,
  authState: AuthState,
  // user will be CognitoUserInterface when a user is successfully signed in, otherwise any
  user: CognitoUserInterface | any
}
export const AuthContext = React.createContext<AuthContextValue>(undefined);

interface AuthProviderProps {
  value: AuthContextValue,
  children: React.ReactNode
}

export const AuthProvider = ({ children, value }: AuthProviderProps) => {
  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
}
