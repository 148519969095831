import styled from "styled-components";

import { formatRole, formatValue, formatWorldAccess } from "../../components/data-table/dataFormatters";
import { ITableColumn } from "../../components/data-table/types";
import { Tooltip } from "../../components/tooltip/tooltip";
import { useWorldsContext } from "../../context/worlds";
import { IUserData } from "../../services/users/users";
import { World } from "../../services/worlds/worlds";
import { RoleTitleTooltip } from "./compositions/roleTitleTooltip";
import { TTypedTFunction, useTranslation } from "@lib/useTypedTranslation";
import { Trans } from "@components/i18n/typedTrans";

const GenericViewTooltip = styled.div`
  max-width: 250px;
`;

const CustomView = styled.div`
  display: inline-block;
`;

const CustomViewNoRestrictions = styled(CustomView)`
  font-style: italic;
`;

export function getFormatUserCustomView(t: TTypedTFunction<'userManagement'>) {
  return (homeLocationName: string | undefined, data: IUserData): JSX.Element => {
    if (!data.zone && !homeLocationName && !data.group) {
      return <CustomViewNoRestrictions>{t('ALL_DEVICES')}</CustomViewNoRestrictions>;
    }
    if (data.zone) {
      return <CustomView>{t('CUSTOM_VIEW_LIST_ZONE', { zone: data.zone.name })}</CustomView>;
    }
    const homeLocation = homeLocationName || t('ALL_HOME_LOCATIONS');
    const group = data.group || t('ALL_GROUPS');
    return <CustomView>
      <Trans i18nKey='CUSTOM_VIEW_LIST_GROUPS_HOME_LOCATIONS' ns='userManagement' values={{ homeLocation, group }}>
        Home Location: {homeLocationName ? <span>{homeLocation}</span> : <CustomViewNoRestrictions>{homeLocation}</CustomViewNoRestrictions>}, Group: {data.group ? <span>{group}</span> : <CustomViewNoRestrictions>{group}</CustomViewNoRestrictions>}
      </Trans>
    </CustomView>;
  };
}

export const useUsersListColumns = () => {
  const { t } = useTranslation('userManagement');
  const { worlds } = useWorldsContext();

  const roleTitle = <RoleTitleTooltip title={t('ROLE')} portalSelector=".user-management-container" />;
  const customView = <>
    {t('CUSTOM_VIEW')}
    <Tooltip popoverPortalSelector={'.user-management-container'} placement={'bottom'} popperContent={<GenericViewTooltip>{t('CUSTOM_VIEW_LIST_TOOLTIP')}</GenericViewTooltip>}>
      <i aria-label='about custom view' className="info-popover-icon fa fa-question-circle" />
    </Tooltip>
  </>;
  const worldAccessTooltip = (
    <Tooltip popoverPortalSelector={'.user-management-container'} placement={'bottom'} popperContent={<GenericViewTooltip>{t('TOOLTIP_WORLD_ACCESS')}</GenericViewTooltip>}>
      <i aria-label='about world access' className="info-popover-icon fa fa-question-circle" />
    </Tooltip>
  );
  const worldAccessTitle = <>{t('WORLD_ACCESS')}{worldAccessTooltip}</>;
  const columns: ITableColumn[] = [
    { id: 'email', title: t('EMAIL_ADDRESS'), dataPath: 'email', sortable: false, processor: formatValue, width: '12rem' },
    { id: 'role', title: roleTitle, dataPath: 'role', sortable: false, processor: formatRole, width: '6rem', textAlign: 'left' },
    { id: 'customView', title: customView, dataPath: 'homeLocation.name', sortable: false, processor: getFormatUserCustomView(t), width: '10rem', textAlign: 'left' }
  ];
  worlds?.length > 1 && columns.push({
    id: 'worldAccess',
    title: worldAccessTitle,
    dataPath: 'worldIds',
    sortable: false,
    processor: (worldIds: World['id'][]) => formatWorldAccess(worldIds, worlds),
    width: '10rem',
    textAlign: 'left'
  });

  return columns;
};
