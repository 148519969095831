import * as React from 'react';
import { isFunction } from 'lodash';

import { RequestInitWithRetry } from './request';

export type UseRequestOptions<T> = {
  initialData?: T,
  initialLoading?: boolean,
  onSuccess?: (data: T) => void,
  onError?: boolean | ((error: Error) => void)
};

export type UseRequestReturn<T> = {
  loading: boolean,
  data: T | null,
  error: Error | null
};

/**
 * Makes an abortable api request, holds data, loading and error in state.
 *
 * @param getFetcher sync function which returns an async function that expects a fetch init object as its single parameter
 * @param options optional object containing any of:
 * onSuccess - callback to call with data on request success
 * onError - callback to call with error on request failure OR boolean to control error re-throwing (default true -i.e. rethrow error)
 * initialData - value for initial data state (default null)
 * initialLoading - value for initial loading state (default false)
 *
 * The onSuccess and onError options are used as the dependencies of the useEffect that calls the fetcher, so should be stable
 * between renders unless re-fetching is desired. e.g. should be wrapped in useMemo or useCallback.
 *
 * Fetching will be skipped if getFetcher returns a falsy value -this can be used to control conditional data fetching
 *
 * Any in flight request will be aborted if the rendering component un-mounts or if a new request is triggered
 *
 * An error from fetch rejection will be re-thrown synchronously unless an onError is passed as a callback or `false`.
 *
 * NB Re-throwing will cause an invariant error if useRequest is not the last hook called in the component
 * q.v. 'Unhandled Rejection (Invariant Violation): Rendered fewer hooks than expected.'
 * If this hook can't be the last, or there are multiple useRequest calls, consider returning the error(s)
 * and passing to useErrorHandler from react-error-boundary, as a final hook.
 *
 * For clarity about whether a request is world aware or not, we should generally not use useRequest directly,
 * but instead use useWorldRequest and useNonWorldRequest which wrap useRequest (and pass the world id from context if required).
 */
export const useRequest = <T = any>(
  getFetcher: () => (options: RequestInitWithRetry) => Promise<T>,
  options: UseRequestOptions<T> = {},
  worldId?: string | false // TODO make this a required parameter once use(Non)WorldRequest migration is complete, so worldId presence/absence is always explicit
): UseRequestReturn<T> => {
  const { initialData = null, initialLoading = false, onSuccess, onError = true } = options;
  const [loading, setLoading] = React.useState(initialLoading);
  const [data, setData] = React.useState<T>(initialData);
  const [error, setError] = React.useState<Error>(null);
  const errorThrown = React.useRef(false);
  React.useEffect(
    () => {
      const fetcher = getFetcher();
      if (!fetcher) { return; }
      const controller = new AbortController();
      const { signal } = controller;
      const abort = controller.abort.bind(controller);
      const headers: HeadersInit = {};
      if (worldId) {
        headers.world = worldId;
      }
      const makeRequest = async () => {
        setLoading(true);
        setError(null);
        try {
          const data = await fetcher({ signal, headers });
          onSuccess && onSuccess(data);
          setData(data);
          setLoading(false);
        } catch (err) {
          if (err.name !== 'AbortError') {
            isFunction(onError) && onError(err);
            setError(err);
            setLoading(false);
          }
        }
      };
      makeRequest();
      return () => {
        // abort request on cleanup
        abort();
      };
    },
    [getFetcher, onSuccess, onError, worldId]
  );
  if (error && onError === true && !errorThrown.current) {
    // rethrow error synchronously to trigger error boundary
    errorThrown.current = true;
    throw error;
  }
  return { loading, data, error };
};
