import * as React from 'react';
import Spinner from '../loading/loadingSpinner';
import './table.css';
import './tableLoading.css';
import { SortableHeader, Direction } from './sortableHeader';

export interface IETableProps {
  fields: string[],
  items: any[],
  rowComponent: any,
  classes: string[],
  isLoading?: boolean,
  paginationComponent: any,
  iconCategories: { [key: string]: string },
  sortableColumns: string[],
  centeredColumns: string[],
  onSortChange: (field: string, direction: Direction) => void,
  sort: { field: string, direction: Direction }
}

export class ETable extends React.Component<IETableProps, {}> {

  public render() {
    const Row = this.props.rowComponent;
    const Pagination = this.props.paginationComponent;
    const classes = ['eTable', 'table', 'table-borderless'].concat(this.props.classes).join(' ');
    const loading = this.props.isLoading || false;

    if (loading) {
      return <div className="eTable eTable-loading"><Spinner /></div>;
    }

    return <div className="eTable--container">
      <div className="table-responsive">
        <table className={classes}>
          <SortableHeader fields={this.props.fields} sortableFields={this.props.sortableColumns} centeredFields={this.props.centeredColumns} onSortChange={this.props.onSortChange} sortField={this.props.sort ? this.props.sort.field : undefined} direction={this.props.sort ? this.props.sort.direction : undefined} />
          {this.props.items.map((data: any, index: any) => {
            return (<Row key={index} {...data} subRows={data.subRows} rowNum={index} iconCategories={this.props.iconCategories} />);
          })}
        </table>
      </div>
      <Pagination />
    </div>;
  }
}
