import * as am4charts from '@amcharts/amcharts4/charts';
import { range } from 'lodash';
import { TTypedTFunction } from '@lib/useTypedTranslation';

const HOURS_TO_SECONDS = 60 * 60;
const MINUTES_TO_SECONDS = 60;

// If useOnlyMinutes is true, the maximum range will be limited to 24 hours unless limitTo60Minutes is true
// If useOnlyMinutes is false or not set, then the maximum range will be unlimited
export function getYAxisThresholds(hours: number, useOnlyMinutes?: boolean, limitTo60Minutes?: boolean): number[] {
  let threshold : number;

  if (useOnlyMinutes) {
    if (limitTo60Minutes) {
      const minutes = hours * 60;
      threshold = range(0, 60, 3).find(t => minutes <= t) || 60;
      threshold *= MINUTES_TO_SECONDS;
    } else {
      threshold = range(0, 24, 2).find(t => hours <= t) || 24;
      threshold *= HOURS_TO_SECONDS;
    }
  } else {
    if (hours <= 3) {
      threshold = [0.5, 1, 2, 3].find(t => hours <= t);
      threshold *= HOURS_TO_SECONDS;
    } else {
      threshold = Math.ceil(hours / 6) * 6;
      threshold *= HOURS_TO_SECONDS;
    }
  }

  const step = useOnlyMinutes && !limitTo60Minutes ? threshold / 6 : threshold / 3;
  return range(0, threshold + step, step);
}

export function createYAxis(yAxis: am4charts.DurationAxis, hours: number, t: TTypedTFunction, useOnlyMinutes?: boolean, limitTo60Minutes?: boolean) {
  const yAxisThresolds = getYAxisThresholds(hours, useOnlyMinutes, limitTo60Minutes);
  const yAxisMax = yAxisThresolds[yAxisThresolds.length - 1];

  yAxis.strictMinMax = false;
  yAxis.min = 0;
  yAxis.max = yAxisMax;
  yAxis.renderer.minGridDistance = 1;

  yAxis.dummyData?.ranges?.forEach((range: any) => yAxis.axisRanges.removeValue(range));
  yAxis.dummyData = {
    ranges: []
  };

  yAxisThresolds.forEach((value: number) => {
    let labelText;
    if (useOnlyMinutes) {
      // To display values without labelText, for handling 30Days/24Hours DeviceUsage Charts existing behavior.
      labelText = `${value / MINUTES_TO_SECONDS}`;
    } else {
      // If the maximum range if less than or equal to 2 hours, display the label in minutes
      // Otherwise, if the maximum range is greate than 2 hours then display the label in hours
      labelText = yAxisMax <= 2 * HOURS_TO_SECONDS
        ? `${value / MINUTES_TO_SECONDS} ${t('timeState:MINUTE', { count: (value / MINUTES_TO_SECONDS) })}`
        : `${value / HOURS_TO_SECONDS} ${t('timeState:HOUR', { count: (value / HOURS_TO_SECONDS) })}`;
    }

    const range = yAxis.axisRanges.create();
    range.value = value;
    range.label = new am4charts.AxisLabel();
    range.label.text = labelText;
    range.label.dy = -18;

    yAxis.dummyData.ranges.push(range);
  });
}
