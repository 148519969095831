import * as React from "react";
import { useTranslation } from "@lib/useTypedTranslation";


import { ModalPopup } from "../../components/controls/modalPopup";
import { useBrandContext } from "../../context/brand";

import './about.css';

const LICENSE_LINK = 'https://b2msolutions.com/open-source-licence-notices/';

export const AboutPopup: React.FC<{
  show: boolean,
  handleClose: () => void
}> = props => {
  const { isPanasonic } = useBrandContext();
  const { t } = useTranslation('aboutPopup');

  const keySuffix = isPanasonic ? '_PANASONIC' : '';
  const currentYear = new Date().getFullYear();

  const header = t(`HEADER${keySuffix}`);
  const contentHeader = t(`CONTENT_HEADER${keySuffix}`);
  const copyright = t('COPYRIGHT', { currentYear });

  return <ModalPopup
    header={header}
    show={props.show}
    handleClose={props.handleClose}
    classname="about-popup"
  >
    <p className="content-header">{contentHeader}</p>
    <p>{copyright}</p>
    <p>{t('LICENSE_HEADER')}: <a href={LICENSE_LINK} target="_blank" rel="noreferrer">{LICENSE_LINK}</a></p>
  </ModalPopup>;
};
