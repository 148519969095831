import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import moment from 'moment';

export function highlightDays(axis: am4charts.DateAxis, startDate: number, endDate: number) {
  let daysIncrement = 0;
  const ranges = [];

  while (moment.utc(startDate).startOf('day').add(daysIncrement, 'days').isSameOrBefore(moment.utc(endDate), 'day')) {
    let range = axis.axisRanges.create();
    range.date = new Date(moment.utc(startDate).startOf('day').add(daysIncrement, 'days').valueOf());
    range.endDate = new Date(moment.utc(startDate).startOf('day').add(daysIncrement, 'days').endOf('day').valueOf());
    range.axisFill.fill = daysIncrement % 2 === 1 ? am4core.color('#1e81b0') : am4core.color('#95bcd1');
    range.label = new am4charts.AxisLabel();
    range.label.disabled = true;
    range.axisFill.fillOpacity = 0.2;
    range.grid.strokeOpacity = 0;

    daysIncrement += 1;
    ranges.push(range);
  }

  return ranges;
}
