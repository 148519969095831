import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { Prompt } from 'react-router-dom';

interface Props {
  enabled: boolean
}

export function PreventNavigation(props: Props) {
  const { t } = useTranslation('translation');
  const message = t('PROMPT_MESSAGE');

  useEffect(() => {
    const beforeUnloadListener = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      return event.returnValue = message;
    };
    if (props.enabled) {
      window.addEventListener('beforeunload', beforeUnloadListener);
      return () => {
        window.removeEventListener('beforeunload', beforeUnloadListener);
      };
    }
  }, [props.enabled, message]);

  return (
    <Prompt when={props.enabled} message={message} />
  );
}
