import React, { useState } from 'react';
import styled from 'styled-components';

import { Spinner } from '../loading/loadingSpinner';
import { languages, languagesMap } from './utils';
import { useLanguageAndLocaleContext } from '../../context/languageAndLocale';
import { setLanguageAndLocaleAction } from '../../services/config/config';
import { useNonWorldAction } from '../../lib/useNonWorldAction';
import { DropdownItem, DropdownMenu } from '../controls/dropdownMenu';

const StyledSpinner = styled(Spinner)`
  display: inline-block;
`;

export const LanguageSelector = () => {
  const [loading, setLoading] = useState(false);
  const setLanguageAndLocale = useNonWorldAction(setLanguageAndLocaleAction);

  const { language: languageInUse, updateProperties: updateLanguageAndLocale } = useLanguageAndLocaleContext();
  const spinner = <StyledSpinner classes="language-spinner" text={null} />;
  const languageInUseCode = loading ? spinner : languagesMap.get(languageInUse)?.code;

  function setLanguage(language: string) {
    const locale = languagesMap.get(language)?.locale;
    return async () => {
      if (languageInUse !== language) {
        setLoading(true);
        const config = { userSettings: { language, locale } };
        try {
          await setLanguageAndLocale(config.userSettings);
          updateLanguageAndLocale({ language, locale });
          setLoading(false);
        } catch {
          setLoading(false);
        }
      }
    };
  }

  const links = languages.map(({ lng, languageName, countryName }) => {
    const key = `language-${lng}`;
    return (
      <DropdownItem
        key={key}
        data-cy={key}
        className={`dropdown-item dropdown-item--${key} btn-sm`}
        onClick={setLanguage(lng)}>
        {languageName} ({countryName})
      </DropdownItem>
    );
  });

  return (
    <DropdownMenu
      buttonContent={languageInUseCode}
      data-id="language-dropdown"
      disabled={loading}
    >
      {links}
    </DropdownMenu>
  );
};
