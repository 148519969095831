import { get } from 'lodash';

import { EitherResponse, isSuccessResponse, expectedSuccess, expectedFailureFrom, unexpectedFailureFrom, EitherOrUnexpected } from '../../lib/either';
import { request, RequestInitWithRetry } from '../../lib/request';
import { OptionalExceptFor } from '../../lib/typeUtils';

export interface HomeLocationData {
  id: string,
  name: string,
  addressLine1: string,
  addressLine2: string,
  locality: string,
  country: string,
  postalCode: string,
  position: string,
  latitude: number,
  longitude: number,
  radius: number,
  distanceYellow: number,
  distanceRed: number
}

export type CreateHomeLocationData = OptionalExceptFor<
  Omit<HomeLocationData, 'id' | 'position'>,
  'name' | 'latitude' | 'longitude' | 'radius'
>;

export type UpdateHomeLocationData = Partial<Omit<CreateHomeLocationData, 'name'>>;

export interface IListOptions {
  limit: number,
  offset: number,
  sort?: {
    field: string,
    order?: string
  },
  search?: string
}

export interface IHomeLocationsList {
  list: HomeLocationData[],
  totalCount: number
}

type MutationResponse = {
  success: boolean
};


export function getHomeLocationsList(params: IListOptions) {
  return async (options: RequestInitWithRetry): Promise<IHomeLocationsList> => {
    const input = {
      path: `/api/homeLocations/list`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        sort: get(params, 'sort'),
        search: get(params, 'search')
      },
    };
    return request(input, options);
  };
}

export function exportHomeLocationsListAction(options: RequestInitWithRetry) {
  return async (params: IListOptions): Promise<string> => {
    const input = {
      path: `/api/homeLocations/exportList`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        sort: get(params, 'sort'),
        search: get(params, 'search')
      },
      method: 'POST'
    };
    return request(input, options);
  };
}

export function createHomeLocationAction(options: RequestInitWithRetry) {
  return async (data: CreateHomeLocationData): Promise<MutationResponse> => {
    const input = {
      path: `/api/homeLocations/createHomeLocation`,
      body: { data }
    };
    return request(input, options);
  };
}

export function updateHomeLocationAction(options: RequestInitWithRetry) {
  return async (id: string, data: UpdateHomeLocationData): Promise<MutationResponse> => {
    const input = {
      path: `/api/homeLocations/updateHomeLocation`,
      body: { id, data }
    };
    return request(input, options);
  };
}

export enum HomeLocationDeleteFailureReason {
  BLOCKED = 'BLOCKED'
}

export type HomeLocationDeleteFailureData = {
  deviceCounts: { [id: string]: number },
  zoneCounts: { [id: string]: number },
  userCounts: { [id: string]: number }
};

export function deleteHomeLocationsAction(options: RequestInitWithRetry) {
  return async (ids: string[]): Promise<EitherOrUnexpected<MutationResponse, HomeLocationDeleteFailureReason, HomeLocationDeleteFailureData>> => {
    const input = {
      path: `/api/homeLocations/deleteHomeLocations`,
      body: { ids }
    };
    try {
      const response = await request<EitherResponse<MutationResponse, undefined, HomeLocationDeleteFailureData>>(input, options);
      if (isSuccessResponse(response)) {
        return expectedSuccess(response.data);
      } else {
        return expectedFailureFrom(HomeLocationDeleteFailureReason.BLOCKED, response.data);
      }
    } catch (e) {
      return unexpectedFailureFrom(e);
    }
  };
}
