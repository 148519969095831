import * as React from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { useState } from 'react';
import moment from 'moment';

import ThresholdSettingsWithPeriod from '../thresholdSettingsWithPeriod';
import { WorldSettings } from '../../../../services/config/config';

import '../settings.css';

export interface IProps {
  worldSettings: WorldSettings,
  submitInProgress: boolean,
  timeStamp: number,
  onDataChanged: (data: WorldSettings) => void,
  disabled?: boolean
}

export function RebootSettings(props: IProps) {
  const { t } = useTranslation('settings');
  const [alertTimeStamp, setAlertTimeStamp] = useState<number>(props.timeStamp);

  if (props.timeStamp > alertTimeStamp) {
    setAlertTimeStamp(props.timeStamp);
  }

  function onDataChanged(data: number, field?: string) {
    if (field === 'warning') {
      props.onDataChanged({ ...props.worldSettings, deviceUserExperienceRebootYellow: data });
    } else if (field === 'problem') {
      props.onDataChanged({ ...props.worldSettings, deviceUserExperienceRebootRed: data });
    } else {
      props.onDataChanged({ ...props.worldSettings, deviceUserExperienceRebootPeriod: data });
    }
  }

  function onAlertToggled(data: boolean, initialSettings: { warning: number, problem: number, days: number }) {
    setAlertTimeStamp(moment.utc().valueOf());
    props.onDataChanged({
      ...props.worldSettings,
      deviceUserExperienceRebootEnabled: data,
      deviceUserExperienceRebootYellow: initialSettings.warning,
      deviceUserExperienceRebootRed: initialSettings.problem,
      deviceUserExperienceRebootPeriod: initialSettings.days
    });
  }

  return (
    <ThresholdSettingsWithPeriod
      key={alertTimeStamp}
      title={t('REBOOTS_TITLE')}
      message={'REBOOTS_MESSAGE'}
      popoverText={t('REBOOTS_POPOVER')}
      className="reboots"
      days={props.worldSettings.deviceUserExperienceRebootPeriod}
      warning={props.worldSettings.deviceUserExperienceRebootYellow}
      problem={props.worldSettings.deviceUserExperienceRebootRed}
      dataUpdated={onDataChanged}
      disabled={props.submitInProgress || !props.worldSettings.deviceUserExperienceRebootEnabled}
      alertEnabled={props.worldSettings.deviceUserExperienceRebootEnabled}
      alertToggled={onAlertToggled}
    />
  );
}
