import { SameValueAsKeys } from "../../lib/typeUtils";

export const settingsNamesArray = [
  'battery',
  'alarms',
  'location',
  'userExperience',
  'utilisation',
  'anomalies',
  'devices',
  'platform'
] as const;
export type SettingName = typeof settingsNamesArray[number];
type SettingNames = SameValueAsKeys<typeof settingsNamesArray>;
export const settingNames: SettingNames = settingsNamesArray.reduce((acc, type) => ({ ...acc, [type]: type }), {} as SettingNames);
