import { filter, get } from "lodash";
import { request, RequestInitWithRetry } from "../../lib/request";
import { ISortResult } from "../shared/common";

export interface IAnomaliesListParams {
  limit: number,
  offset: number,
  sort?: ISortResult,
  filter?: IListFilter,
  alertStatuses?: string[]
}

export interface IAnomaly {
  id: string,
  firstDetected: number,
  lastDetected: number,
  detectedCount: number,
  latestAffectedCount: number,
  highestAffectedCount: number,
  totalAffectedCount: number,
  description: string
}

export type AnomalyList = IAnomaly[];

export interface IAnomalyListAndCount {
  list: AnomalyList,
  totalCount: number
}

export interface IAnomalyListCount {
  count: number
}

export interface IListFilter {
  alertStatus?: string[]
}

export function getAnomaliesList(params: IAnomaliesListParams) {
  return async (options: RequestInitWithRetry): Promise<IAnomalyListAndCount> => {
    const input = {
      path: `/api/core/anomalies/list`,
      qs: { limit: params.limit, offset: params.offset },
      body: {
        sort: get(params, 'sort'),
        alertStatuses: get(params.filter, 'alertStatus')
      },
    };
    const output = await request(input, options);
    return output;
  };
}

export function getDeviceCount() {
  return async (options: RequestInitWithRetry): Promise<number> => {
    const input = {
      path: `/api/core/devices/count`,
      body: {},
      method: 'post'
    };
    const result = await request(input, options);
    return result.count;
  };
}
