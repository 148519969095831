import React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
  color: #3fa67d;
  font-size: 1.2em;
`;
Icon.displayName = 'GoodIcon';

export function GoodIcon(props: { className?: string }) {
  return <Icon data-id="good-icon" className={`${props.className || ''} fas fa-check`} />;
}
