import React from 'react';
import styled from 'styled-components';

import { useWorldSettingsContext } from '../../context/worldSettings';
import { useWorldAction } from '../../lib/useWorldAction';
import { setWorldSettingsAction, WorldSettings } from '../../services/config/config';
import { DevicesSettingsPage } from './compositions/devices/devicesSettingsPage';

const StyledPage = styled.div`
  flex-grow: 1;
`;

export function DevicesSettings() {
  const { worldSettings, updateProperties: updateWorldSettings } = useWorldSettingsContext();
  const setWorldSettings = useWorldAction(setWorldSettingsAction);

  async function saveData(data: { worldSettings: WorldSettings}) {
    await setWorldSettings(data.worldSettings);
    updateWorldSettings(data.worldSettings);
  }
  return (
    <StyledPage className="devices-page">
      <DevicesSettingsPage
        saveData={saveData}
        worldSettings={worldSettings}
      />
    </StyledPage>
  );
}
