import { isNull, isString, reverse, sortBy } from 'lodash';
import { useTranslation } from '@lib/useTypedTranslation';
import { Link } from 'react-router-dom';
import { getAlertStatusFilterId } from '../../../../components/filters/devicesFilterLib';
import { Loading } from '../../../../components/loading/loading';
import { AlertCount, AlertLevel } from '../../../../services/core/overview';
import { isAlertEnabled } from '../../../../services/config/config';
import { ALERT } from '../../../../components/alertsPanel/alertMessages';
import { useLanguageAndLocaleContext } from '../../../../context/languageAndLocale';
import { useWorldRoutes } from '../../../../routes/parts/allWorldRoutes';
import { useWorldSettingsContext } from '../../../../context/worldSettings';

import './alerts-overview.css';
import { AlertStatus } from '../../../../services/config/alertConstants';

export interface SortedAlert {
  name: AlertStatus,
  red: number | string,
  yellow: number | string,
  sum: number,
  text: string
}

interface IProps {
  alerts: AlertCount,
  dataLoading: boolean
}

export type AlertText = {
  [alert in AlertStatus]: string
};

const ns = 'alertOverview' as const;

export function AlertsOverview(props: IProps) {
  const { alerts, dataLoading } = props;
  const { t } = useTranslation(ns);
  const { worldSettings } = useWorldSettingsContext();
  const { locale } = useLanguageAndLocaleContext();
  const linkToDevicesList = useWorldRoutes().core.devicesList;

  function getPercentage(value: number): string {
    const percentageValue = (value / alerts.all) * 100;
    return percentageValue < 1 ? `<1%` : `${percentageValue.toFixed(0)}%`;
  }

  const sortAlerts = (data: AlertCount): SortedAlert[] => {
    if (!data) { return []; }
    const sortedAlerts: SortedAlert[] = [];
    const alertsText: AlertText = {
      deviceLocationAccessPointStatus: t('LOCATION_ACCESS_POINT_STATUS'),
      deviceUtilisationIdleStatus: t('IDLE_STATUS'),
      deviceUtilisationOutOfContactStatus: t('OUT_OF_CONTACT_STATUS'),
      deviceUserExperienceRebootStatus: t('REBOOT_STATUS'),
      deviceTimeErrorStatus: t('TIME_ERR_STATUS'),
      averageDischargeStatus: t('AVR_DISCHARGE_STATUS'),
      deviceUserExperienceLowPowerStatus: t('LOW_POWER_STATUS'),
      deviceLocationTimeStatus: t('LOCATION_TIME_STATUS'),
      deviceLocationDistanceStatus: t('LOCATION_DISTANCE_STATUS'),
      deviceUserExperienceDropStatus: t('DROP_STATUS')
    };
    for (const [key, alertData] of Object.entries(data) as [AlertStatus, AlertLevel | number][]) {
      if (typeof alertData === 'number') {
        // do not attempt to create an entry for 'all'
        continue;
      }
      const { red, yellow } = alertData;
      const sum = red + yellow;
      const enabledAlert = isAlertEnabled(key, worldSettings);
      if (sum && enabledAlert) {
        const item: SortedAlert = {
          name: key,
          red: isNull(red) ? '-' : red,
          yellow: isNull(yellow) ? '-' : yellow,
          sum,
          text: alertsText[key as AlertStatus]
        };
        sortedAlerts.push(item);
      }
    }
    return reverse(sortBy(sortedAlerts, 'sum'));
  };

  const getFilterLink = (value: number | string, ...alertStatuses: string[]) => {
    if (value === 0 || isString(value)) {
      return value;
    }
    const qs = new URLSearchParams({ alertStatus: alertStatuses.join(',') });
    const to = `${linkToDevicesList}?${qs.toString()}`;
    return (
      <Link to={to} className="estate-alerts__link">
        {value.toLocaleString(locale)}
      </Link>
    );
  };

  const createAlertsContainer = (data: SortedAlert[]) => {
    const alertCount = data.length;
    if (alertCount) {
      const rows = data.map((a: SortedAlert) => {
        const redId = getAlertStatusFilterId(a.name, ALERT.red);
        const yellowId = getAlertStatusFilterId(a.name, ALERT.yellow);
        return (
          <tr key={`estate-alerts__status--${a.name}`} className={`estate-alerts__status estate-alerts__status--${a.name}`}>
            <td><b>{getPercentage(a.sum)}</b> {t('OF_DEVICES')} ({getFilterLink(a.sum, redId, yellowId)}) {a.text}</td>
            <td>{getFilterLink(a.red, redId)}</td>
            <td>{getFilterLink(a.yellow, yellowId)}</td>
          </tr>
        );
      });
      return (
        <table>
          <thead>
            <tr>
              <th className="estate-alerts__title" data-id="estate-alerts__title">{t('TITLE', { alertCount })}</th>
              <th className="estate-alerts__alert-icon-red"><i className={`fa fa-times-circle circle_red`} /></th>
              <th className="estate-alerts__alert-icon-yellow"><i className={`fa fa-exclamation-triangle circle_orange enhanced-warning-icon`} /></th>
            </tr>
          </thead>
          <tbody className="estate-alerts__status">
            {rows}
          </tbody>
        </table>
      );
    }
    return <>
      <div className="estate-alerts__title">{t('TITLE', { alertCount })}</div>
      <div className="estate-alerts__status estate-alerts__status--no-alerts">{t('NO_ALERTS')}</div>
    </>;
  };

  return (
    <div className="estate-alerts">
      <Loading isLoading={dataLoading} transparentOverlay={false}>
        <div className="estate-alerts__container">
          {createAlertsContainer(sortAlerts(alerts))}
        </div>
      </Loading>
    </div>
  );
}
