import moment from 'moment';
import { split } from 'lodash';

export function formatDateAsOrdinal(value: number): string {
  const date = moment.utc(value).format('YYYY-MM-DD');
  const day = split(date, '-', 3).pop();
  const localeData = moment.localeData();

  return localeData.ordinal(parseInt(day));
}

export function formatDateAsOrdinalWithMonth(value: number): string {
  return `${moment.utc(value).format('MMM')} ${formatDateAsOrdinal(value)}`;
}
