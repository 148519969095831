import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from '@lib/useTypedTranslation';
import { isNull } from 'lodash';
import styled from 'styled-components';
import moment from 'moment';

import { FormControls } from '../../../../components/forms/formControls';
import { PreventNavigation } from '../../../../components/navigation/preventNavigation';
import { WorldSettings } from '../../../../services/config/config';
import { CheckBox } from '../../../../components/controls/checkbox';

import '../settings.css';
import { SelectInput } from '../../../../components/controls/selectInput';

export interface IProps {
  worldSettings: WorldSettings,
  saveData: (data: { worldSettings: WorldSettings }) => Promise<void>
}

interface SubmitResult {
  success: boolean,
  message: string
}

function convert24HourTimeTo12Hour(hour: number | null) {
  if (isNull(hour)) return { hour: null, amPmPreset: null };

  if (hour === 0) {
    return { hour: 12, amPmPreset: 'AM' };
  } else if (hour === 12) {
    return { hour: 12, amPmPreset: 'PM' };
  } else if (hour < 12 && hour > 0) {
    return { hour: hour, amPmPreset: 'AM' };
  } else {
    return { hour: hour - 12, amPmPreset: 'PM' };
  }
}

function convert12HourTimeTo24Hour(hour: number, amPmValue: string) {
  if (!hour) return null;
  if (amPmValue === 'AM') {
    return hour === 12 ? 0 : hour;
  } else {
    return hour === 12 ? hour : hour + 12;
  }
}

export function AnomalySettings(props: IProps) {
  const { t } = useTranslation(['settings', 'anomaly']);

  const [submitResult, setSubmitResult] = useState<SubmitResult>(undefined);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);

  const [enabled, setEnabled] = useState(!isNull(props.worldSettings.smartGroupingHour));
  const [runTime, setRunTime] = useState(convert24HourTimeTo12Hour(props.worldSettings.smartGroupingHour).hour);
  const [amPm, setAmPm] = useState(convert24HourTimeTo12Hour(props.worldSettings.smartGroupingHour).amPmPreset || 'AM');
  const [timeZone, setTimeZone] = useState(props.worldSettings.smartGroupingTimeZone);

  async function handleSubmit() {
    const smartGroupingHour = convert12HourTimeTo24Hour(runTime, amPm);
    const newHour = enabled ? smartGroupingHour : null;
    const newTimeZone = enabled ? timeZone : null;
    try {
      setSubmitResult(undefined);
      setSubmitInProgress(true);
      await props.saveData({
        worldSettings: {
          ...props.worldSettings,
          smartGroupingHour: newHour,
          smartGroupingTimeZone: newTimeZone
        }
      });
      setSubmitResult({ message: t('SAVE_SUCCESS', { ns: 'settings' }), success: true });
      setSubmitInProgress(false);
    } catch (e) {
      setSubmitResult({ message: t('SAVE_FAIL', { ns: 'settings' }), success: false });
      setSubmitInProgress(false);
    }
  }

  const smartGroupingHour = convert12HourTimeTo24Hour(runTime, amPm);
  const getHaveSettingsChanged = () => {
    const hourChanged = props.worldSettings.smartGroupingHour !== smartGroupingHour;
    const amPmChanged = (convert24HourTimeTo12Hour(props.worldSettings.smartGroupingHour).amPmPreset || 'AM') !== amPm;
    const timeZoneChanged = props.worldSettings.smartGroupingTimeZone !== timeZone;
    const enabledChanged = isNull(props.worldSettings.smartGroupingHour) ? enabled : !enabled;

    return hourChanged || amPmChanged || timeZoneChanged || enabledChanged;
  };

  const getCancelDisabled = () => !getHaveSettingsChanged() || submitInProgress;
  const getSaveDisabled = () => {
    const enabledChanged = isNull(props.worldSettings.smartGroupingHour) ? enabled : !enabled;
    const settingsChanged = !enabled ? enabledChanged : getHaveSettingsChanged();

    const areSettingsValid = isNull(smartGroupingHour) ? !enabled && isNull(timeZone) : !isNull(timeZone);
    const hasError = submitResult?.success === false;

    return !settingsChanged || submitInProgress || !areSettingsValid || hasError;
  };

  function handleCancel() {
    setRunTime(convert24HourTimeTo12Hour(props.worldSettings.smartGroupingHour).hour);
    setAmPm(convert24HourTimeTo12Hour(props.worldSettings.smartGroupingHour).amPmPreset || 'AM');
    setTimeZone(props.worldSettings.smartGroupingTimeZone);
    setSubmitResult(undefined);
    setEnabled(!isNull(props.worldSettings.smartGroupingHour));
  }

  return (
    <div className='settings-composition anomalies'>
      <form>
        <PreventNavigation enabled={!getSaveDisabled()} />
        <StyledSettingsContainer>
          <StyledTitleContainer>
            <StyledTitle data-id='anomaly__header'>{t('ANOMALY_DETECTION', { ns: 'settings' })}</StyledTitle>
          </StyledTitleContainer>
          <StyledConfigurationBody>
            <CheckboxRow>{t('ENABLE_ANOMALY_DETECTION', { ns: 'settings' })}
              <CheckBox
                name={t('ENABLE_ANOMALY_DETECTION', { ns: 'settings' })}
                className="fancy-checkbox"
                checked={enabled}
                onChange={(event) => setEnabled(event.checked)}
              />
            </CheckboxRow>
            <Info><InfoIcon className="fa fa-info-circle" />{t('ANOMALY_DETECTION_SUPPORT_WARNING', { ns: 'anomaly' })}</Info>
            <StyledSubtitle className='anomaly-subHeader'>{t('DAILY_RUN_TIME', { ns: 'settings' })}</StyledSubtitle>
            <Row>
              <StyledCell>
                <StyledSubsectionTitle data-id='anomalies-time__subtitle'>{`${t('TIME', { ns: 'translation' })}:`}</StyledSubsectionTitle>
                <StyledDropdownContainer>
                  <SelectInput
                    selectedValue={runTime?.toString() ?? null}
                    items={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
                      .map((time) => ({ label: time, value: time }))
                    }
                    onItemSelected={value => setRunTime(parseInt(value))}
                    label={t('SELECT_HOUR_LABEL', { ns: 'settings' })}
                    disabled={!enabled}
                  />
                </StyledDropdownContainer>
                <StyledDropdownContainer>
                  <SelectInput
                    selectedValue={amPm}
                    items={['AM', 'PM'].map((value) => ({ label: value, value }))}
                    onItemSelected={value => setAmPm(value)}
                    label={t('SELECT_AM_OR_PM_LABEL', { ns: 'settings' })}
                    disabled={!enabled}
                  />
                </StyledDropdownContainer>
              </StyledCell>
              <StyledCell>
                <StyledSubsectionTitle data-id='anomalies-timeZone__subtitle'>{`${t('TIMEZONE', { ns: 'translation' })}:`}</StyledSubsectionTitle>
                <StyledDropdownContainer>
                  <SelectInput
                    selectedValue={timeZone}
                    items={moment.tz.names().map((value) => ({ label: value, value }))}
                    onItemSelected={value => setTimeZone(value)}
                    label={t('SELECT_TIME_ZONE_LABEL', { ns: 'settings' })}
                    disabled={!enabled}
                  />
                </StyledDropdownContainer>
              </StyledCell>
            </Row>
          </StyledConfigurationBody>
        </StyledSettingsContainer>
        <FormControls
          submitResult={{
            message: submitResult?.message,
            status: submitInProgress ? 'loading' : submitResult ? submitResult.success ? 'success' : 'error' : null
          }}
          cancelDisabled={getCancelDisabled()}
          submitDisabled={getSaveDisabled()}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          mode='save'
        />
      </form>
    </div>
  );
}


const StyledSettingsContainer = styled.div`
  border: 1px solid #d7e2eb;
  margin-bottom: 1.5rem;
  margin-left: 1rem;
`;

StyledSettingsContainer.displayName = 'SettingsContainer';

const StyledTitleContainer = styled.div`
  background: #f3f7fb;
  border-bottom: 1px solid #d7e2eb;
  padding: 0.3rem;
  margin-bottom: 1rem;
`;

StyledTitleContainer.displayName = 'TitleContainer';

const StyledTitle = styled.h6`
  font-size: 1rem;
  font-weight: 600;
  padding-left: 0.4rem;
  display: inline;
`;

StyledTitle.displayName = 'AnomalyTitle';

const StyledConfigurationBody = styled.div`
  padding-left: 1.15rem;
  font-size: 0.875rem;
  font-weight: 400;
  padding-bottom: 1rem;
`;

StyledConfigurationBody.displayName = 'ConfigurationBody';

const StyledSubtitle = styled.div`
  margin-top: 10px;
  text-align: left;
  font-weight: 600;
`;

StyledSubtitle.displayName = 'AnomalySubtitle';

const StyledDropdownContainer = styled.div`
  cursor: pointer;
  display: inline-block;
  &:nth-child(1) {
    margin-left: 0.5rem;
  }
`;

StyledDropdownContainer.displayName = 'DropdownContainer';

const StyledCell = styled.div`
  padding-left: 1rem;
`;

const Row = styled.div`
  margin-top: 10px;
  display: flex;
`;

StyledCell.displayName = 'AnomalyCell';

const StyledSubsectionTitle = styled.span`
  padding-right: 0.5rem;
`;

StyledSubsectionTitle.displayName = 'SubsectionTitle';

const CheckboxRow = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 300px;
`;

const InfoIcon = styled.i`
  color: #7490b9;
  margin-right: 5px;
`;

const Info = styled.div`
  margin-top: 10px;
  margin-left: 30px;
  font-weight: 600;
`;
